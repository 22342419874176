class DashboardApi {
  async getProfessions(accessToken) {
    const urlProfessions = `${process.env.REACT_APP_API_URL}/api/dashboard/professions`;

    const resp = await fetch(urlProfessions, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getOverview(accessToken) {
    const urlOverview = `${process.env.REACT_APP_API_URL}/api/dashboard/overview`;

    const resp = await fetch(urlOverview, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getSalesRevenue(accessToken) {
    const urlSalesRevenue = `${process.env.REACT_APP_API_URL}/api/dashboard/salesRevenue`;

    const resp = await fetch(urlSalesRevenue, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getInfos(accessToken, year) {
    const urlInfos = `${process.env.REACT_APP_API_URL}/api/dashboard/infos`;
    const values = {};
    values.year = year;
    const resp = await fetch(urlInfos, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCostBreakdown(finished, accessToken) {
    const urlInfos = `${process.env.REACT_APP_API_URL}/api/dashboard/costBreakdown`;

    const resp = await fetch(urlInfos, {
      method: 'POST',
      body: JSON.stringify(finished),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getStatus(accessToken) {
    const urlInfos = `${process.env.REACT_APP_API_URL}/api/dashboard/status`;

    const resp = await fetch(urlInfos, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getComments(dateSelected, accessToken) {
    const urlInfos = `${process.env.REACT_APP_API_URL}/api/dashboard/comments`;
    const values = {};
    values.dateSelected = dateSelected;

    const resp = await fetch(urlInfos, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }
  
  async getCommentsRelaunch(dateSelected, accessToken) {
    const urlInfos = `${process.env.REACT_APP_API_URL}/api/dashboard/relaunch/comments`;
    const values = {};
    values.dateSelected = dateSelected;

    const resp = await fetch(urlInfos, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getList(accessToken) {
    const urlInfos = `${process.env.REACT_APP_API_URL}/api/dashboard/list`;

    const resp = await fetch(urlInfos, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCommercialProgress(accessToken) {
    const urlInfos = `${process.env.REACT_APP_API_URL}/api/dashboard/commercialProgress`;

    const resp = await fetch(urlInfos, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getSessions(accessToken, endDate, formation, source, relance, profession, year) {
    const urlSessions = `${process.env.REACT_APP_API_URL}/api/dashboard/sessions`;
    const values = {};
    values.endDate = endDate;
    values.formation = formation;
    values.source = source;
    values.relance = relance;
    values.profession = profession;
    values.year = year;
    const resp = await fetch(urlSessions, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }

  async getSessionsStatus(accessToken, endDate, formation, source, relance, profession) {
    const urlSessions = `${process.env.REACT_APP_API_URL}/api/dashboard/sessions-status`;
    const values = {};
    values.endDate = endDate;
    values.formation = formation;
    values.source = source;
    values.relance = relance;
    values.profession = profession;
    const resp = await fetch(urlSessions, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }

  async getFinishedValidate(accessToken) {
    const urlProfessions = `${process.env.REACT_APP_API_URL}/api/dashboard/get-finished-validate`;

    const resp = await fetch(urlProfessions, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getAttribute(accessToken) {
    const urlProfessions = `${process.env.REACT_APP_API_URL}/api/dashboard/attribute`;

    const resp = await fetch(urlProfessions, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getSuperAdmin(formationId, sourceId, relanceId, profession, accessToken) {
    const urlProfessions = `${process.env.REACT_APP_API_URL}/api/dashboard/superAdmin`;
    const values = { formationId, sourceId, relanceId, profession };
    const resp = await fetch(urlProfessions, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }

  async getSaveDayLearnerSession(accessToken) {
    const urlProfessions = `${process.env.REACT_APP_API_URL}/api/dashboard/saveDayLearnerSession`;

    const resp = await fetch(urlProfessions, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getDataProfession(accessToken) {
    const urlProfessions = `${process.env.REACT_APP_API_URL}/api/dashboard/dataProfession`;

    const resp = await fetch(urlProfessions, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getDataFormationAndProfession(accessToken) {
    const urlProfessions = `${process.env.REACT_APP_API_URL}/api/dashboard/dataFormationAndProfession`;

    const resp = await fetch(urlProfessions, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getSources(accessToken) {
    const urlSources = `${process.env.REACT_APP_API_URL}/api/dashboard/getSources`;

    const resp = await fetch(urlSources, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomersProgression(date, accessToken) {
    const urlSources = `${process.env.REACT_APP_API_URL}/api/dashboard/get-customers-progression`;
    const values = {};
    values.dateSelected = date;
    const resp = await fetch(urlSources, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomersRealTimeProgression(accessToken) {
    const urlSources = `${process.env.REACT_APP_API_URL}/api/dashboard/get-customers-realtime-progression`;
    const resp = await fetch(urlSources, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async notifyIncommingSessions(accessToken) {
    const urlSources = `${process.env.REACT_APP_API_URL}/api/sessions/notifications/notify-incoming-sessions`;
    const resp = await fetch(urlSources, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }
}

export const dashboardApi = new DashboardApi();
