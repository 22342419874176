/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { Avatar, Card, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PanToolIcon from '@mui/icons-material/PanTool';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import * as moment from 'moment';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

const CustomerCommercialComment = (props) => {
  const { createdAt, comment, source, id, handleDelete, qualification, reason, recallDate, formation1, formation2, formation3, nameFormation1, nameFormation2,
    nameFormation3, offeredFormation, nameOfferedFormation, ...other } = props;
  let nameFormation1Transf = '';
  let nameFormation2Transf = '';
  let nameFormation3Transf = '';
  let nameOfferedFormationTransf = '';
  let formation1Transf = '';
  let formation2Transf = '';
  let formation3Transf = '';
  let offeredFormationTransf = '';
  if (nameFormation1 !== null && nameFormation1 !== undefined) {
    if (nameFormation1.length > 50) {
      nameFormation1Transf = `${nameFormation1.substring(0, 50)}...`;
    } else {
      nameFormation1Transf = nameFormation1;
    }
  }
  if (nameFormation2 !== null && nameFormation2 !== undefined) {
    if (nameFormation2.length > 50) {
      nameFormation2Transf = `${nameFormation2.substring(0, 50)}...`;
    } else {
      nameFormation2Transf = nameFormation2;
    }
  }
  if (nameFormation3 !== null && nameFormation3 !== undefined) {
    if (nameFormation3.length > 50) {
      nameFormation3Transf = `${nameFormation3.substring(0, 50)}...`;
    } else {
      nameFormation3Transf = nameFormation3;
    }
  }
  if (nameOfferedFormation !== null && nameOfferedFormation !== undefined) {
    if (nameOfferedFormation.length > 40) {
      nameOfferedFormationTransf = `${nameOfferedFormation.substring(0, 40)}...`;
    } else {
      nameOfferedFormationTransf = nameOfferedFormation;
    }
  }
  if (formation1 !== null && formation1 !== undefined) {
    if (formation1.name.length > 50) {
      formation1Transf = `${formation1.name.substring(0, 50)}...`;
    } else {
      formation1Transf = formation1.name;
    }
  }
  if (formation2 !== null && formation2 !== undefined) {
    if (formation2.name.length > 50) {
      formation2Transf = `${formation2.name.substring(0, 50)}...`;
    } else {
      formation2Transf = formation2.name;
    }
  }
  if (formation3 !== null && formation3 !== undefined) {
    if (formation3.name.length > 50) {
      formation3Transf = `${formation3.name.substring(0, 50)}...`;
    } else {
      formation3Transf = formation3.name;
    }
  }
  if (offeredFormation !== null && offeredFormation !== undefined && offeredFormation.name !== undefined) {
    if (offeredFormation.name.length > 40) {
      offeredFormationTransf = `${offeredFormation.name.substring(0, 40)}...`;
    } else {
      offeredFormationTransf = offeredFormation.name;
    }
  }

  return (
    <Card
      sx={{
        alignItems: 'center',
        display: 'flex',
        p: 2,
        '& + &': {
          mt: 2
        }
      }}
      {...other}
    >
      <Avatar
        sx={{
          color: 'common.white'
        }}
      >
        { (qualification === 'Vente 1 formation' || qualification === 'Vente 2 formation' || qualification === 'Vente 3 formation') && (
        <AttachMoneyIcon style={{ color: '#16B84E' }} />
        ) }
        { qualification === 'Erreur' && (
        <ErrorOutlineIcon style={{ color: 'white' }} />
        ) }
        { qualification === 'Rappel' && (
        <PhoneIcon style={{ color: 'orange' }} />
        ) }
        { qualification === 'Souhaite un email' && (
        <MailOutlineIcon style={{ color: 'purple' }} />
        ) }
        { qualification === 'Refus' && (
        <PanToolIcon style={{ color: '#ff5f52' }} />
        ) }
        { qualification === 'Attente' && (
        <HourglassEmptyIcon style={{ color: '#ffe436' }} />
        ) }
        { qualification === 'Appel entrant' && (
        <PhoneCallbackIcon style={{ color: 'blue' }} />
        ) }
        { qualification === 'Pas répondu' && (
        <PhoneMissedIcon style={{ color: 'black' }} />
        ) }
      </Avatar>
      <Typography
        color="textPrimary"
        sx={{ ml: 2 }}
        variant="body2"
      >
        <Typography
          color="textPrimary"
          variant="subtitle2"
        >
          {qualification !== 'Ancien commentaire' ? source : ''}
        </Typography>
        <Typography
          style={{ margin: 'auto', padding: 'auto' }}
          color={(qualification === 'Vente 1 formation' || qualification === 'Vente 2 formation' || qualification === 'Vente 3 formation') ? '#16B84E' : qualification === 'Refus' ? '#ff5f52' : qualification === 'Erreur' ? '#888888' : qualification === 'Rappel' ? 'orange' : qualification === 'Souhaite un email' ? '#9a68af' : qualification === 'Attente' ? '#d7b52c' : qualification === 'Appel entrant' ? '#1E90FF' : qualification === 'Pas répondu' ? '#888888' : 'black'}
          variant="subtitle2"
        >
          {`${qualification} ${reason === null ? '' : reason}${recallDate === undefined || recallDate === null ? '' : moment(recallDate).format('DD/MM/YYYY à H:mm')}`}

        </Typography>
        <Typography fontSize={14}>
          {nameFormation1 !== null && nameFormation1 !== undefined ? '1) ' : formation1 !== null && formation1 !== undefined ? '1) ' : ''}
          {nameFormation1 !== undefined ? nameFormation1Transf : formation1 !== null ? formation1Transf : ''}
        </Typography>
        <Typography fontSize={14}>
          {nameFormation2 !== null && nameFormation2 !== undefined ? '2) ' : formation2 !== null && formation2 !== undefined ? '2) ' : ''}
          {nameFormation2 !== undefined ? nameFormation2Transf : formation2 !== null ? formation2Transf : ''}
        </Typography>
        <Typography fontSize={14}>
          {nameFormation3 !== null && nameFormation3 !== undefined ? '3) ' : formation3 !== null && formation3 !== undefined ? '3) ' : ''}
          {nameFormation3 !== undefined ? nameFormation3Transf : formation3 !== null ? formation3Transf : ''}
        </Typography>
        <Typography fontSize={14}>
          {nameOfferedFormation !== null && nameOfferedFormation !== undefined ? `Offerte : ${nameOfferedFormationTransf}` : offeredFormation !== null && offeredFormation !== undefined ? `Offerte : ${offeredFormationTransf}` : ''}
        </Typography>
        <Typography
          fontSize={14}
          fontStyle="italic"
        >
          {comment}
        </Typography>
      </Typography>

      <Typography
        color="textPrimary"
        sx={{ ml: 'auto' }}
        variant="caption"
      >
        {new Date(createdAt).toLocaleDateString('fr-FR', options)}
      </Typography>
      <IconButton
        onClick={() => handleDelete(id)}
        disabled={qualification === 'Ancien commentaire'}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Card>
  );
};

CustomerCommercialComment.propTypes = {
  createdAt: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  qualification: PropTypes.string.isRequired,
  reason: PropTypes.string,
  recallDate: PropTypes.string,
  formation1: PropTypes.string,
  nameFormation1: PropTypes.string,
  formation2: PropTypes.string,
  nameFormation2: PropTypes.string,
  formation3: PropTypes.string,
  nameFormation3: PropTypes.string,
  offeredFormation: PropTypes.string,
  nameOfferedFormation: PropTypes.string
};

export default CustomerCommercialComment;
