/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import RelaunchCustomerRelaunchComment from './RelaunchCustomerRelaunchComment';
import CustomerProInfos from '../customer/CustomerProInfos';

const RelaunchCustomerProDetails = (props) => {
  const { profession, specialite, modeOfExercise, adeliRpps, numSecu, mdpMondpc, comment_commercial, comment_relance, antecedents, editing, remplacant, siret, mdpFifpl, startRelaunchDate,
    // eslint-disable-next-line react/prop-types
    errors, handleBlur, handleChange, touched, values, setFieldValue, callTrack, callTrackRelance, callTrackRelanceList, customer, formations, customerId,
    campaignId, setIsTreated, saveTime, commentRelance, setCommentRelance, ...other } = props;

  return (
    <div>
      <Card {...other}>
        <CardHeader title="Informations professionnelles" />
        <Divider />
        <CustomerProInfos
          remplacant={remplacant}
          mdpFifpl={mdpFifpl}
          mdpMondpc={mdpMondpc}
          editing={editing}
          errors={errors}
          touched={touched}
          callTrackRelanceList={callTrackRelanceList}
          callTrackRelance={callTrackRelance}
          callTrack={callTrack}
          startRelaunchDate={startRelaunchDate}
          setFieldValue={setFieldValue}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          siret={siret}
          adeliRpps={adeliRpps}
          modeOfExercise={modeOfExercise}
          specialite={specialite}
          profession={profession}
        />
      </Card>
      <RelaunchCustomerRelaunchComment
        comment_relance={comment_relance}
        customer={customer}
        formations={formations}
        customerId={customerId}
        setIsTreated={setIsTreated}
        saveTime={saveTime}
        commentRelance={commentRelance}
        setCommentRelance={setCommentRelance}
      />
      <Card
        sx="margin-top:20px;
        min-height: 195px;"
      >
        <CardHeader title="Antécédents" />
        <Divider />
        <CardContent>
          {editing ? (
            <TextareaAutosize
              maxRows={4}
              aria-label="maximum height"
              placeholder="Entrer des antécédents"
              name="antecedents"
              style={{
                width: '100%',
                height: '150px'
              }}
              defaultValue={antecedents}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          ) : antecedents}
        </CardContent>
      </Card>
    </div>
  );
};

RelaunchCustomerProDetails.propTypes = {
  profession: PropTypes.string.isRequired,
  specialite: PropTypes.string.isRequired,
  modeOfExercise: PropTypes.string.isRequired,
  adeliRpps: PropTypes.string,
  numSecu: PropTypes.string,
  mdpMondpc: PropTypes.string,
  comment_commercial: PropTypes.object,
  comment_relance: PropTypes.object,
  callTrack: PropTypes.string,
  callTrackRelance: PropTypes.string,
  callTrackRelanceList: PropTypes.array,
  antecedents: PropTypes.string,
  editing: PropTypes.bool,
  touched: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.object,
  customer: PropTypes.object,
  formations: PropTypes.array,
  customerId: PropTypes.string,
  campaignId: PropTypes.string,
  setIsTreated: PropTypes.func,
  saveTime: PropTypes.func,
  commentRelance: PropTypes.object,
  setCommentRelance: PropTypes.func,
  mdpFifpl: PropTypes.string,
  remplacant: PropTypes.bool,
  siret: PropTypes.string,
  startRelaunchDate: PropTypes.string
};

export default RelaunchCustomerProDetails;
