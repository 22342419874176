/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbarExport, frFR } from '@mui/x-data-grid';
import { Typography, Link, Tooltip, Card, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Label from '../../Label';
import * as moment from 'moment';
import { ProgressBar } from '../session';

function renderProgress(params) {
  return <ProgressBar value={Number(params.value)} />;
}

const RelaunchCustomersList = (props) => {
  const { countCustomersList, ...other } = props;

  const rows = {};
  if (Object.keys(countCustomersList).length !== 0) {
    // eslint-disable-next-line array-callback-return
    Object.keys(countCustomersList).map((el) => {
      rows[el] = [];
      // eslint-disable-next-line array-callback-return
      Object.keys(countCustomersList[el]).map((itemRow) => {
        const row = {
          id: countCustomersList[el][itemRow].id + countCustomersList[el][itemRow].id_session,
          civility: countCustomersList[el][itemRow].civility,
          nom: countCustomersList[el][itemRow].nom,
          prénom: countCustomersList[el][itemRow].prénom,
          email: countCustomersList[el][itemRow].email,
          phone: countCustomersList[el][itemRow].phone,
          status: countCustomersList[el][itemRow].status,
          progression: countCustomersList[el][itemRow].progression,
          name: countCustomersList[el][itemRow].name,
          début_formation: countCustomersList[el][itemRow].début_formation,
          fin_formation: countCustomersList[el][itemRow].fin_formation,
          // id_session: countCustomersList[el][itemRow].id_session,
          prix: countCustomersList[el][itemRow].prix,
          source: countCustomersList[el][itemRow].source,
          dernière_connexion: countCustomersList[el][itemRow].last_connection,
          durée: countCustomersList[el][itemRow].duration,
          start_relaunch_date: countCustomersList[el][itemRow].start_relaunch_date,
          sessionId: countCustomersList[el][itemRow].sessionId,
        };
        rows[el].push(row);
      });
    });
  }

  const columns = [
    { field: 'civility',
      headerName: 'Civility',
      width: 120,
      type: 'singleSelect',
      renderCell: (cellValues) => {
        let civility = '';
        if (cellValues.row.civility === 'Madame') {
          civility = (
            <Typography
              sx={{ whiteSpace: 'normal', color: '#1AA856', fontWeight: 550 }}
            >
              {cellValues.row.civility}
            </Typography>
          );
        } else if (cellValues.row.civility === 'Monsieur') {
          civility = (
            <Typography
              sx={{ whiteSpace: 'normal', color: '#036B3A', fontWeight: 550 }}
            >
              {cellValues.row.civility}
            </Typography>
          );
        }
        return (civility);
      },
      editable: true,
      valueOptions: ['Madame', 'Monsieur'],
    },
    { field: 'nom',
      headerName: 'Nom',
      width: 150,
      renderCell: (cellValues) => (
        <Typography
          sx={{ whiteSpace: 'normal', fontWeight: 550 }}
        >
          {cellValues.row.nom}
        </Typography>
      ) },
    { field: 'prénom',
      headerName: 'Prénom',
      width: 100,
      renderCell: (cellValues) => (
        <Typography
          sx={{ whiteSpace: 'normal', fontWeight: 550 }}
        >
          {cellValues.row.prénom}
        </Typography>
      ) },
    { field: 'email', headerName: 'Email', width: 220, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.email}</Typography>) },
    { field: 'phone', headerName: 'Téléphone', width: 120, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.phone}</Typography>) },
    { field: 'status',
      headerName: 'Statut',
      width: 120,
      type: 'singleSelect',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.status === 'Inscrit') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'En attente' || cellValues.row.status === 'À transférer') {
          statut = (
            <Label
              color="warning"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'InscritD') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={500}
              color="inscritD"
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  color="inscritD"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === 'Décalage') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={700}
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                  color="secondary"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === '- de 5 personnes' || cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
          statut = (
            <Label
              color="error"
            >
              {cellValues.row.status}
            </Label>
          );
        }
        return (statut);
      },
      editable: true,
      valueOptions: ['Inscrit', 'En attente', 'Désinscrit', 'Décalage', '- de 5 personnes', 'Annulation', 'À transférer'],
    },
    { field: 'progression', headerName: 'Progression', width: 150, background: 'green', renderCell: renderProgress },
    { field: 'name',
      headerName: 'Nom de formation',
      width: 390,
      renderCell: (cellValues) => (
        <Link
          sx={{ whiteSpace: 'normal', width: 310, fontWeight: 550 }}
          component={RouterLink}
          to={`/dashboard/session/${cellValues.row.sessionId}`}
        >
          {cellValues.row.name}
        </Link>
      ) },
    { field: 'début_formation', headerName: 'Début de formation', width: 150, renderCell: (cellValues) => (<Typography>{cellValues.row.début_formation !== null ? moment(cellValues.row.début_formation).format('DD/MM/YYYY') : ''}</Typography>) },
    { field: 'fin_formation', headerName: 'Fin de formation', width: 150, renderCell: (cellValues) => (<Typography>{cellValues.row.fin_formation !== null ? moment(cellValues.row.fin_formation).format('DD/MM/YYYY') : ''}</Typography>) },
    { field: 'start_relaunch_date', headerName: 'Début de relance', width: 150, renderCell: (cellValues) => (<Typography>{cellValues.row.start_relaunch_date !== null ? moment(cellValues.row.start_relaunch_date).format('DD/MM/YYYY') : ''}</Typography>) },
    // { field: 'id_session',
    //   headerName: 'Session',
    //   width: 330,
    //   renderCell: (cellValues) => (
    //     <Link
    //       sx={{ whiteSpace: 'normal', width: 310, fontWeight: 550 }}
    //     >
    //       {cellValues.row.id_session}
    //     </Link>
    //   ) },
    { field: 'prix', headerName: 'Prix', width: 150, background: 'green', renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.prix}</Typography>) },
    { field: 'source', headerName: 'Source', width: 160, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.source}</Typography>) },
    { field: 'dernière_connexion', headerName: 'Derniere connexion', width: 160, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.dernière_connexion !== null ? moment(cellValues.row.dernière_connexion).format('DD/MM/YYYY H:mm:ss') : ''}</Typography>) },
    { field: 'durée', headerName: 'Durée', width: 120, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.durée}</Typography>) },
  ];

  const gridToolbarExport = () => (
    <GridToolbarExport csvOptions={{
      delimiter: ';',
      utf8WithBom: true,
      disableExport: false,
      fields: ['civility', 'prénom', 'nom', 'email', 'phone', 'name', 'début_formation', 'fin_formation', 'start_relaunch_date', 'prix', 'source', 'status', 'dernière_connexion', 'durée', 'progression']
    }}
    />
  );
  return (
    <Card {...other}>
      {Object.keys(countCustomersList).map((el) => (
        <Box sx={{ mt: 2, ml: 1 }}>
          <Typography
            variant="h5"
            sx={{ ml: 1, p: 1 }}
          >
            {`Avancement des apprenants par date de début ${moment(el).format('DD/MM/YYYY')}`}
          </Typography>
          <DataGrid
            {...other}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rowHeight={80}
            rows={rows[el]}
            columns={columns}
            pageSize={25}
            rowsPerPage5Options={[10, 100]}
            components={{ Toolbar: gridToolbarExport }}
          />
        </Box>
      ))}
    </Card>
  );
};
RelaunchCustomersList.propTypes = {
  countCustomersList: PropTypes.array.isRequired,
};

export default RelaunchCustomersList;
