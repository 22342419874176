/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect, useRef } from 'react';
import { isSameDay, isSameWeek, isSameMonth } from 'date-fns';
import { Autocomplete, Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import CustomerRelanceComment from '../customer/CustomerRelanceComment';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import PencilAltIcon from '../../../icons/PencilAlt';
import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { customerApi } from '../../../api/customerApi';
import useMounted from '../../../hooks/useMounted';
import { useNavigate } from 'react-router-dom';

const RelaunchCustomerTabRelaunch = (props) => {
  const { customerId, user, setIsTreated, setCommentRelance, ...other } = props;
  const todayItems = [];
  const thisWeekItems = [];
  const thisMonthItems = [];
  const ancientItems = [];
  const adminList = [0, 4];

  // pour savoir si on active le bouton commenter ou pas
  let isAuthorized = false;
  if (adminList.includes(user.id_service)) {
    isAuthorized = true;
  }
  if (user.access_level === 0) {
    isAuthorized = true;
  }

  const mounted = useMounted();
  const [comments, setComments] = useState([]);
  const [commenting, setCommenting] = useState(false);
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation
  const relanceLabel = ['Pas répondu', 'À rappeler', 'Numéro incorrect', 'Va se connecter', 'Suivi - Téléphone', 'Suivi - Mail', 'Suivi - SMS', 'Décalage', 'Annulation'];
  const [choiceRelance, setChoiceRelance] = useState(null);
  const [choiceQualificationRecall, setChoiceQualificationRecall] = useState(null);
  const [displayDateRecall, setDisplayDateRecall] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(true);

  useEffect(() => {
    if ((choiceRelance !== null && (choiceRelance !== null && choiceRelance !== 'À rappeler'))) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }
    if (choiceRelance !== null && choiceRelance === 'À rappeler' && choiceQualificationRecall !== null) {
      setIsFormFilled(false);
    }
  });

  const handleChangeRelance = (value) => {
    setChoiceRelance(value);
    switch (value) {
      case 'À rappeler':
        setDisplayDateRecall(true);
        break;

      default:
        setDisplayDateRecall(false);
        break;
    }
  };

  const handleDelete = async (id) => {
    try {
      await customerApi.deleteCommentRelance(id, localStorage.getItem('accessToken'));
      window.location.reload();
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la suppression !');
    }
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const activity of comments) {
    if (isSameDay(new Date(activity.createdAt), new Date())) {
      todayItems.push(activity);
    } else
    if (isSameWeek(new Date(activity.createdAt), new Date())) {
      thisWeekItems.push(activity);
    } else
    if (isSameMonth(new Date(activity.createdAt), new Date())) {
      thisMonthItems.push(activity);
    } else {
      ancientItems.push(activity);
    }
  }

  const formikRef = useRef();
  useEffect(() => {
    setChoiceQualificationRecall(null);
    if (formikRef.current) {
      // réinitialiser la value des selects au changement du select de qualification
      formikRef.current.values.choiceQualificationRecall = null;
    }
  }, [choiceRelance]);

  const getCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getCustomer(customerId, localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setComments(data.relance_comments);
      }
    } catch (err) {
      console.error(err);
      navigate('/404', { replace: true });
    }
  }, [mounted]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  const submitCommentSpecial = async (comment, qualification) => {
    try {
      const jsonAnswer = await customerApi.addCommentRelanceSpecial(customerId, user.id, comment, qualification, localStorage.getItem('accessToken'));
      toast.success('Commentaire de relance enregistré !');
      const tempComments = [...comments];
      tempComments.unshift({ id: jsonAnswer.data.customer_id,
        comment: jsonAnswer.data.comment,
        createdAt: jsonAnswer.data.created_at,
        firstname: user.firstname,
        lastname: user.lastname,
        qualification: jsonAnswer.data.qualification,
      });
      setIsTreated(true);
      setComments(tempComments);
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de l\'enregistrement !');
    }
  };

  return (
    <div {...other}>
      <Formik
      // pour pouvoir recupérer values de formik dans le useEffect
        innerRef={formikRef}
        initialValues={{ commentRelance: null, choiceRelance: null, choiceQualificationRecall: null }}
        enableReinitialize
        validationSchema={Yup
          .object()
          .shape({
            commentRelance: Yup.string().nullable(),
            choiceRelance: Yup.string(),
            choiceQualificationRecall: Yup.string().nullable()
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          try {
            const jsonAnswer = await customerApi.addCommentRelance(customerId, user.id, values, localStorage.getItem('accessToken'));
            setStatus({ success: true });
            resetForm({});
            setSubmitting(false);
            toast.success('Commentaire de relance enregistré !');
            setCommenting(!commenting);
            const tempComments = [...comments];
            tempComments.unshift({ id: jsonAnswer.data.customer_id,
              comment: jsonAnswer.data.comment,
              createdAt: jsonAnswer.data.created_at,
              firstname: user.firstname,
              lastname: user.lastname,
              qualification: choiceRelance,
              recall_date: choiceQualificationRecall });
            setComments(tempComments);
            setCommentRelance({ id: jsonAnswer.data.customer_id,
              comment: jsonAnswer.data.comment,
              createdAt: jsonAnswer.data.created_at,
              firstname: user.firstname,
              lastname: user.lastname,
              qualification_relance: choiceRelance,
              recall_date: choiceQualificationRecall });
            setIsTreated(true);
            // pour réinitilaiser le formulaire
            setChoiceQualificationRecall(null);
            setChoiceRelance(null);
            setDisplayDateRecall(false);
          } catch (err) {
            console.error(err);
            toast.error('Il y a eu un souci lors de l\'enregistrement !');
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleSubmit, handleBlur, handleChange, touched, values, setFieldValue }) => (
          <form
            onSubmit={handleSubmit}
          >
            {commenting ? (
              <>
                <Grid
                  container
                >
                  <Grid
                    item
                    xs={2}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Laisser un commentaire
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    mt={1}
                    mb={1}
                  >
                    <Grid
                      item
                      xs={2}
                    >
                      <Autocomplete
                        options={relanceLabel}
                        onChange={(e, value) => {
                          handleChangeRelance(value);
                          setChoiceRelance(value);
                          setFieldValue(
                            'choiceRelance',
                            value !== null ? value : choiceRelance
                          );
                        }}
                        value={values.choiceRelance}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Relance"
                            name="choiceRelance"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    {displayDateRecall && (
                    <Grid
                      item
                      ml={4}
                    >
                      <TextField
                        error={Boolean(touched.choiceQualificationRecall && errors.choiceQualificationRecall)}
                        fullWidth
                        helperText={touched.choiceQualificationRecall && errors.choiceQualificationRecall}
                        label="Date et heure du rappel"
                        name="choiceQualificationRecall"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setChoiceQualificationRecall(event.target.value);
                        }}
                        type="datetime-local"
                        value={values.choiceQualificationRecall}
                        variant="outlined"
                        // pour éviter la superposition de jj/mm/aaaa et date de naissance dans le champ
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    )}
                  </Grid>
                  <TextField
                    sx={{ mt: 2, width: 700 }}
                    label="Commentaire"
                    error={Boolean(touched.commentRelance && errors.commentRelance)}
                    fullWidth
                    helperText={touched.commentRelance && errors.commentRelance}
                    multiline
                    name="commentRelance"
                    variant="filled"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.commentRelance}
                    minRows={2}
                  />
                  <Grid
                    container
                    mt={1}
                    mb={1}
                  >
                    <Grid
                      item
                      xs={2}
                    >
                      <Button
                        color="primary"
                        startIcon={<SaveAltIcon fontSize="small" />}
                        variant="contained"
                        disabled={isFormFilled}
                        onClick={handleSubmit}
                      >
                        Enregistrer
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                    >
                      <Button
                        color="primary"
                        startIcon={<CancelIcon fontSize="small" />}
                        variant="contained"
                        onClick={() => setCommenting(!commenting)}
                      >
                        Annuler
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )
              : (
                <>
                  <Button
                    color="primary"
                    startIcon={<PencilAltIcon fontSize="small" />}
                    disabled={!isAuthorized}
                    sx={{ mt: 1, mb: 3 }}
                    variant="contained"
                    onClick={() => setCommenting(!commenting)}
                  >
                    Commenter
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isAuthorized}
                    sx={{ mt: 1, mb: 3, ml: 2 }}
                    variant="contained"
                    onClick={() => submitCommentSpecial('Messagerie', 'Pas répondu')}
                  >
                    Messagerie
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isAuthorized}
                    sx={{ mt: 1, mb: 3, ml: 2 }}
                    variant="contained"
                    onClick={() => submitCommentSpecial('Connexion dans la semaine', 'Va se connecter')}
                  >
                    Connexion
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isAuthorized}
                    sx={{ mt: 1, mb: 3, ml: 2 }}
                    variant="contained"
                    onClick={() => submitCommentSpecial('Campagne email', 'Suivi - Mail')}
                  >
                    Email
                  </Button>
                  <Button
                    color="primary"
                    disabled={!isAuthorized}
                    sx={{ mt: 1, mb: 3, ml: 2 }}
                    variant="contained"
                    onClick={() => submitCommentSpecial('Campagne SMS', 'Suivi - SMS')}
                  >
                    SMS
                  </Button>
                </>
              )}
          </form>
        )}
      </Formik>
      <Typography
        color="textPrimary"
        variant="h6"
      >
        Aujourd&apos;hui
      </Typography>
      <Box sx={{ mt: 3 }}>
        {todayItems.map((comment) => (
          <CustomerRelanceComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            recallDate={comment.recall_date}
          />
        ))}
      </Box>
      <Typography
        color="textPrimary"
        sx={{ mt: 3 }}
        variant="h6"
      >
        Cette semaine
      </Typography>
      <Box sx={{ mt: 3 }}>
        {thisWeekItems.map((comment) => (
          <CustomerRelanceComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            recallDate={comment.recall_date}
          />
        ))}
      </Box>
      <Typography
        color="textPrimary"
        sx={{ mt: 3 }}
        variant="h6"
      >
        Ce mois-ci
      </Typography>
      <Box sx={{ mt: 3 }}>
        {thisMonthItems.map((comment) => (
          <CustomerRelanceComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            recallDate={comment.recall_date}
          />
        ))}
      </Box>
      <Typography
        color="textPrimary"
        sx={{ mt: 3 }}
        variant="h6"
      >
        Plus ancien
      </Typography>
      <Box sx={{ mt: 3 }}>
        {ancientItems.map((comment) => (
          <CustomerRelanceComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            recallDate={comment.recall_date}
          />
        ))}
      </Box>
    </div>
  );
};

RelaunchCustomerTabRelaunch.propTypes = {
  rComments: PropTypes.array,
  customerId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  setIsTreated: PropTypes.func,
  setCommentRelance: PropTypes.func
};

export default RelaunchCustomerTabRelaunch;
