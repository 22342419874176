/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import EditIcon from '@mui/icons-material/Edit';
import { getYearFromDateString } from '../../utils/dateFormatting';

export default function PartnerForm(props) {
  const {
    editMode,
    open,
    handleClickOpen,
    handleCancel,
    isImageDownloaded,
    isDragActive,
    getRootProps,
    getInputProps,
    files,
    handleChange,
    name,
    siret,
    startContract,
    endContract,
    setEndContract,
    handleSubmit,
  } = props;

  const [isEndDateError, setIsEndDateError] = useState(false);
  const [isStartDateError, setIsStartDateError] = useState(false);
  const [isEndBeforeBeginning, setIsEndBeforeBeginning] = useState(false);

  const invalidDateSentence = 'Date invalide !';
  const endBeforeBeginningSentence = 'La date de fin est avant la date de début !';

  useEffect(() => {
    const yearStart = getYearFromDateString(startContract);

    const regExp = new RegExp('^(20|19)[0-9][0-9]$');

    if (!regExp.test(yearStart)) {
      if (!isStartDateError) setIsStartDateError(true);
    } else {
      setIsStartDateError(false);
      if (new Date(startContract) > new Date(endContract)) {
        setEndContract(startContract);
      }
    }
  }, [startContract]);

  useEffect(() => {
    const yearEnd = getYearFromDateString(endContract);

    const regExp = new RegExp('^(20|19)[0-9][0-9]$');

    if (!regExp.test(yearEnd)) {
      if (!isEndDateError) setIsEndDateError(true);
    } else {
      setIsEndDateError(false);
    }

    if (new Date(startContract) > new Date(endContract)) {
      setIsEndBeforeBeginning(true);
    } else if (isEndBeforeBeginning) setIsEndBeforeBeginning(false);
  }, [endContract, startContract]);

  return (
    <Grid
      container
      sx={editMode ? { width: '3.8rem' } : { display: 'flex', justifyContent: 'flex-end' }}
    >
      {editMode ? (
        <Button onClick={handleClickOpen}>
          <EditIcon />
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={handleClickOpen}
        >
          Ajouter un partenaire
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleCancel}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          { !editMode ? "Nouveau partenaire" : name}
        </DialogTitle>
        <DialogContent>
          {/* <Grid container>
            {!isImageDownloaded ? (
              <Box
                {...getRootProps()}
                sx={{
                  border: 1,
                  borderRadius: 1,
                  padding: 2,
                  fontWeight: 'bold',
                  borderStyle: 'dotted',
                  backgroundColor: '#fafafa',
                  color: '#7d7d7d',
                  width: '100%',
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Glissez un logo ici...</p>
                ) : (
                  <p>
                    Glissez un logo dans ce cadre ou cliquez pour choisir un
                    logo
                  </p>
                )}
              </Box>
            ) : (
              <Box>{files}</Box>
            )}
          </Grid> */}

          <TextField
            autoFocus
            margin="dense"
            label="Nom du partenaire"
            name="name"
            type="text"
            value={name}
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Numéro de siret"
            name="siret"
            type="text"
            value={siret}
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Date de début de contrat"
            name="startContract"
            type="date"
            value={startContract}
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
          <Typography sx={{ color: 'error.main' }}>{isStartDateError && invalidDateSentence}</Typography>
          <TextField
            margin="dense"
            label="Date de fin de contrat"
            name="endContract"
            type="date"
            InputProps={{
              inputProps: {
                min: startContract,
              },
            }}
            value={endContract}
            fullWidth
            variant="standard"
            onChange={handleChange}
          />
          <Typography sx={{ color: 'error.main' }}>{isEndDateError && invalidDateSentence}</Typography>
          <Typography sx={{ color: 'error.main' }}>{isEndBeforeBeginning && !isEndDateError && endBeforeBeginningSentence}</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCancel}>Annuler</Button>
          <Button
            onClick={handleSubmit}
            disabled={isEndDateError || isStartDateError || siret.length === 0 || name.length === 0 || isEndBeforeBeginning}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
