/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Box, Collapse, Divider, Grid, IconButton, Link, Skeleton, TextField, Typography, ToggleButtonGroup, ToggleButton, Autocomplete } from '@material-ui/core';
import { useState, useCallback, useEffect } from 'react';
import { dashboardApi } from '../../../api/dashboardApi';
import { Link as RouterLink } from 'react-router-dom';
import useMounted from '../../../hooks/useMounted';
import useAuth from '../../../hooks/useAuth';
import * as moment from 'moment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FinanceStatistiqueFormationsSuperAdmin = () => {
  const { user } = useAuth();
  const mounted = useMounted();
  let choiceOptions = [];
  const [choiceEndDate, setChoiceEndDate] = useState('default');
  const [choiceFormation, setChoiceFormation] = useState('all');
  const [choiceSource, setChoiceSource] = useState('all');
  const [choiceRelance, setChoiceRelance] = useState('all');
  const [choiceProfession, setChoiceProfession] = useState('all');
  const [choiceYear, setChoiceYear] = useState(2024);
  const [sessionsInformations, setSessionsInformations] = useState([]);
  const [formationsInfos, setFormationsInfos] = useState([]);
  const [sourcesInfos, setSourcesInfos] = useState([]);
  const [relanceInfos, setRelanceInfos] = useState([]);
  const [rows, setRows] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [years, setYears] = useState([]);
  const [sessionsEndDate, setSessionsEndDate] = useState([]);
  const [clickedId, setClickedId] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [formationAutocomplete, setFormationAutocomplete] = useState({ id: 'all', programNumber: '', name: 'Toutes' });
  const detailStyles = {
    borderTop: '2px solid',
    borderTopColor: 'primary.main',
    pt: 2,
    whiteSpace: 'pre-line',
  };

  const formationsOptions = [];
  formationsOptions.push({ name: 'Toutes', id: 'all', programNumber: '' });
  formationsInfos.forEach((formation) => {
    formationsOptions.push({ name: formation.name, id: formation.id, programNumber: formation.program_number });
  });
  const sourcesOptions = [];
  sourcesOptions.push({ name: 'Tous', id: 'all' });
  sourcesInfos.forEach((source) => {
    sourcesOptions.push({ name: `${source.firstname} ${source.lastname}`, id: source.id });
  });
  const relanceOptions = [];
  relanceOptions.push({ name: 'Tous', id: 'all' });
  relanceInfos.forEach((person) => {
    relanceOptions.push({ name: `${person.firstname} ${person.lastname}`, id: person.id });
  });
  const professionsOptions = [{ name: 'Toutes', id: 'all' }];
  professions.forEach((profession) => {
    professionsOptions.push({ name: profession.profession, id: profession.profession });
  });
  const yearsOptions = [{ name: 'Toutes', id: 'all' }];
  years.forEach((year) => {
    yearsOptions.push({ name: year, id: year });
  });

  const getSessions = useCallback(async () => {
    try {
      // console.log('heeeeeere');
      const data = await dashboardApi.getSessions(localStorage.getItem('accessToken'), choiceEndDate, choiceFormation, choiceSource, choiceRelance, choiceProfession, choiceYear);
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setSessionsInformations(data.sessions);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  const getInfos = useCallback(async () => {
    try {
      const data = await dashboardApi.getInfos(localStorage.getItem('accessToken'), choiceYear);

      if (data.length === 0) {
        throw new Error('La profession n\'existe pas');
      }

      if (mounted.current) {
        setSessionsEndDate(data.sessionsEndDate);
        setFormationsInfos(data.formations);
        setSourcesInfos(data.sources);
        setRelanceInfos(data.relance);
        setProfessions(data.professions);
        setYears(data.years);
      }
    } catch (err) {
      console.error(err);
    }
  }, [choiceYear, mounted]);

  useEffect(() => {
    getSessions();
  }, [getSessions]);

  useEffect(() => {
    getInfos();
  }, [getInfos]);

  const handleInformationsSessions = async (endDate, formation, source, relance, profession, year) => {
    try {
      const data = await dashboardApi.getSessions(localStorage.getItem('accessToken'), endDate, formation, source, relance, profession, year);
      setSessionsInformations(data.sessions);
    } catch (err) {
      console.error(err);
    }
  };

  if (sessionsEndDate !== undefined && sessionsEndDate.length > 0) {
    choiceOptions = [{ label: 'Toutes', value: 'default' }];
    sessionsEndDate.forEach((item) => choiceOptions.push({ label: moment(item.end_date).format('DD/MM/YYYY'), value: item.end_date }));
  }

  const handleChangeOption = (event) => {
    setChoiceEndDate(event.target.value);
    handleInformationsSessions(event.target.value, choiceFormation, choiceSource, choiceRelance, choiceProfession, choiceYear);
  };
  // const handleChangeOptionFormation = (event) => {
  //   setChoiceFormation(event.target.value);
  //   handleInformationsSessions(choiceEndDate, event.target.value, choiceSource, choiceRelance, choiceProfession);
  // };
  const handleChangeOptionSource = (event) => {
    setChoiceSource(event.target.value);
    handleInformationsSessions(choiceEndDate, choiceFormation, event.target.value, choiceRelance, choiceProfession, choiceYear);
  };
  const handleChangeOptionRelance = (event) => {
    setChoiceRelance(event.target.value);
    handleInformationsSessions(choiceEndDate, choiceFormation, choiceSource, event.target.value, choiceProfession, choiceYear);
  };
  const handleChangeOptionProfession = (event) => {
    setChoiceProfession(event.target.value);
    handleInformationsSessions(choiceEndDate, choiceFormation, choiceSource, choiceRelance, event.target.value, choiceYear);
  };

  const handleChangeOptionYear = (event) => {
    setChoiceYear(event.target.value);
    handleInformationsSessions(choiceEndDate, choiceFormation, choiceSource, choiceRelance, choiceProfession, event.target.value);
  };

  useEffect(() => {
    let tempRows = sessionsInformations;
    if (tempRows.length > 0) {
      if (tempRows.length === 2) {
        tempRows.splice(1, 1);
      } else if (tempRows.length > 5) {
        tempRows.unshift(JSON.parse(JSON.stringify(tempRows[tempRows.length - 1]))); // essentiel pour donner une clé unique à l'objet dupliqué sans référence
        tempRows[0].id = Math.max(...tempRows.map((el) => el.id)) + 1;
      }
    } else {
      tempRows = [];
    }

    setRows(tempRows.map((item) => ({
      id: item.id,
      formation: item.name,
      session: item.number_session,
      turnoverNotBegun: item.price0,
      turnoverBegun: item.priceBetween,
      turnoverFinished: item.priceFinished,
      turnoverNotBillable: item.priceNotBillable,
      startDate: item.start_date,
      endDate: item.end_date,
      notBegun: item.percent0,
      begun: item.percentBetween,
      finished: item.percentFinished,
      notBillable: item.percentNotBillable,
      numberNotBegun: item.count0,
      numberBegun: item.countBetween,
      numberFinished: item.countFinished,
      numberNotBillable: item.countNotBillable,
      numberRegistered: item.numberTotal,
      numberTurnoverRegistered: item.priceTotal,
      status: item.status,
      programNumber: item.program_number })));
  }, [sessionsInformations]);

  const columns = [
    {
      field: '+',
      // eslint-disable-next-line no-unused-expressions
      renderCell: (cellValues) => (<IconButton onClick={() => { clickedId === cellValues.row.id ? setClickedId(-1) : setClickedId(cellValues.row.id); }}>{cellValues.row.id === clickedId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>),
      width: 60
    },
    {
      field: 'formation',
      headerName: 'Formation',
      width: 400,
      renderCell: (cellValues) => (
        (
          cellValues.row.formation !== 'Statistique globale' ? (
            <>
              {choiceFormation === 'all' && choiceEndDate === 'default' ? (
                <div>
                  <div>
                    <Link
                      sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
                      component={RouterLink}
                      to={`/dashboard/formations/${cellValues.row.id}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <Typography style={{ fontWeight: 'normal' }}>
                        {cellValues.row.formation}
                      </Typography>
                    </Link>
                  </div>
                  <Collapse in={cellValues.id === clickedId}>
                    <Box sx={detailStyles}>
                      {cellValues.row.status.map((el) => (
                        <>
                          <span style={{ fontWeight: el.status !== 'En attente' && el.status !== 'Décalage' ? 'bold' : 'normal', color: (el.status === 'Annulation' || el.status === 'Désinscrit') && el.percentByStatus > 49 ? 'red' : (el.status === 'Inscrit' || el.status === 'InscritD') && el.percentByStatus > 49 ? 'green' : null }}>{`${el.status}: ${el.priceByStatus} € (${el.countByStatus}) ${el.percentByStatus} %`}</span>
                          <br />
                        </>
                      ))}
                    </Box>
                  </Collapse>
                </div>

              ) : (
                <div>
                  <Link
                    sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
                    component={RouterLink}
                    to={`/dashboard/session/${cellValues.row.id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Typography style={{ fontWeight: 'normal' }}>
                      {cellValues.row.formation}
                    </Typography>
                  </Link>
                  <Collapse in={cellValues.id === clickedId}>
                    <Box sx={detailStyles}>
                      {cellValues.row.status.map((el) => (
                        <>
                          <span style={{ fontWeight: el.status !== 'En attente' && el.status !== 'Décalage' ? 'bold' : 'normal', color: (el.status === 'Annulation' || el.status === 'Désinscrit') && el.percentByStatus > 49 ? 'red' : (el.status === 'Inscrit' || el.status === 'InscritD') && el.percentByStatus > 49 ? 'green' : null }}>{`${el.status}: ${el.priceByStatus} € (${el.countByStatus}) ${el.percentByStatus} %`}</span>
                          <br />
                        </>
                      ))}
                    </Box>
                  </Collapse>
                </div>
              )}
            </>
          )
            : (
              <div>
                <Typography style={{ fontWeight: 'bold' }}>
                  {cellValues.row.formation}
                </Typography>
                <Collapse in={cellValues.id === clickedId}>
                  <Box sx={detailStyles}>
                    {cellValues.row.status.map((el) => (
                      <>
                        <span style={{ fontWeight: el.status !== 'En attente' && el.status !== 'Décalage' ? 'bold' : 'normal', color: (el.status === 'Annulation' || el.status === 'Désinscrit') && el.percentByStatus > 49 ? 'red' : (el.status === 'Inscrit' || el.status === 'InscritD') && el.percentByStatus > 49 ? 'green' : null }}>{`${el.status}: ${el.priceByStatus} € (${el.countByStatus}) ${el.percentByStatus} %`}</span>
                        <br />
                      </>
                    ))}
                  </Box>
                </Collapse>
              </div>
            )

        )) },
    { field: 'programNumber', headerName: 'N° Programme', width: 140, renderCell: (cellValues) => (<Typography style={{ margin: 'auto' }}>{cellValues.row.formation === 'Statistique globale' ? '-' : cellValues.row.programNumber}</Typography>) },
    { field: 'session',
      width: 110,
      headerName: choiceFormation === 'all' && choiceEndDate === 'default' ? 'Nb sessions' : 'Session n°',
      renderCell: (cellValues) => (
        <Box>
          <Typography style={{ margin: 'auto' }}>{cellValues.row.session}</Typography>
          {' '}
          <Collapse in={cellValues.id === clickedId} />
        </Box>
      ) },
    { field: 'startDate', headerName: 'Date de début', width: 100, renderCell: (cellValues) => (<Typography style={{ margin: 'auto' }}>{cellValues.row.startDate === '-' ? '-' : moment(cellValues.row.startDate).format('DD/MM/YYYY')}</Typography>) },
    { field: 'endDate', headerName: 'Date de fin', width: 100, renderCell: (cellValues) => (<Typography style={{ margin: 'auto' }}>{cellValues.row.endDate === '-' ? '-' : moment(cellValues.row.endDate).format('DD/MM/YYYY')}</Typography>) },
    { field: 'turnoverNotBegun',
      width: 120,
      headerName: 'Pas commencé',
      renderCell: (cellValues) => (
        <Typography
          style={{ margin: 'auto', fontWeight: cellValues.row.notBegun > 60 ? 'bold' : 'inherit', color: cellValues.row.notBegun > 60 ? 'red' : 'inherit' }}
          sx={{ whiteSpace: 'pre-line' }}
        >
          <span>{`${cellValues.row.notBegun} % (${cellValues.row.numberNotBegun})\n ${cellValues.row.turnoverNotBegun} €`}</span>
        </Typography>
      ),
      sortComparator: (v1, v2) => v1 - v2 },
    { field: 'turnoverNotBillable',
      width: 120,
      headerName: 'Non facturable',
      renderCell: (cellValues) => (
        <Typography
          style={{ margin: 'auto', fontWeight: cellValues.row.notBillable > 60 ? 'bold' : 'inherit', color: cellValues.row.notBillable > 60 ? 'red' : 'inherit' }}
          sx={{ whiteSpace: 'pre-line' }}
        >
          <span>{`${cellValues.row.notBillable} % (${cellValues.row.numberNotBillable})\n ${cellValues.row.turnoverNotBillable} €`}</span>
        </Typography>
      ),
      sortComparator: (v1, v2) => v1 - v2 },
    { field: 'turnoverBegun',
      width: 120,
      headerName: 'En cours',
      renderCell: (cellValues) => (
        <Typography
          style={{ margin: 'auto' }}
          sx={{ whiteSpace: 'pre-line' }}
        >
          <span>{`${cellValues.row.begun} % (${cellValues.row.numberBegun})\n ${cellValues.row.turnoverBegun} €`}</span>
        </Typography>
      ),
      sortComparator: (v1, v2) => v1 - v2 },
    { field: 'turnoverFinished',
      width: 120,
      headerName: 'Fini',
      renderCell: (cellValues) => (
        <Typography sx={{ whiteSpace: 'pre-line', margin: 'auto', fontWeight: cellValues.row.finished > 60 ? 'bold' : 'inherit', color: cellValues.row.finished > 60 ? 'green' : 'inherit' }}>
          <span>{`${cellValues.row.finished} % (${cellValues.row.numberFinished})\n ${cellValues.row.turnoverFinished} €`}</span>
        </Typography>
      ),
      sortComparator: (v1, v2) => v1 - v2 },
    { field: ('numberTurnoverRegistered'),
      headerName: 'Total',
      width: 130,
      renderCell: (cellValues) => (
        <Typography
          style={{ margin: 'auto' }}
          sx={{ whiteSpace: 'pre-line' }}
        >
          <span>{`(${cellValues.row.numberRegistered})\n ${cellValues.row.numberTurnoverRegistered} €`}</span>
        </Typography>
      ),
      sortComparator: (v1, v2) => v1 - v2 },
  ];

  const [showStats, setShowStats] = useState(true);

  const handleTabsChange = () => {
    setShowStats(!showStats);
  };

  return (
    <Grid
      container
      sx={{ ml: 1, p: 2 }}
    >
      <ToggleButtonGroup
        exclusive
      >
        <ToggleButton
          style={{ border: 'none', color: 'inherit' }}
          value="grid"
          variant="h6"
          onClick={handleTabsChange}
        >
          {showStats === true ? <KeyboardArrowRightIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
        </ToggleButton>
      </ToggleButtonGroup>
      <Typography
        color="textSecondary"
        variant="h5"
        sx={{ ml: 3, p: 1 }}
      >
        Chiffre d&apos;affaire par session et progression
      </Typography>
      {!showStats && (
        <>
          <Divider />
          <Grid container>
            <Box style={{ display: 'flex' }}>
              <Box
                sx={{
                  m: 2,
                  width: '20%'
                }}
              >
                <TextField
                  label="Afficher par année"
                  name="année"
                  onChange={handleChangeOptionYear}
                  select
                  SelectProps={{ native: true }}
                  value={choiceYear}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                >
                  {yearsOptions.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                    >
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  m: 2,
                  width: '20%'
                }}
              >
                <TextField
                  label="Afficher par date de fin"
                  name="choice"
                  onChange={handleChangeOption}
                  select
                  SelectProps={{ native: true }}
                  value={choiceEndDate}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                >
                  {choiceOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>
              {user.id_service === 0 && (
              <>
                <Box
                  sx={{
                    m: 2,
                    width: '20%'
                  }}
                >
                  {/* <TextField
                    label="Afficher par formation"
                    name="formation"
                    onChange={handleChangeOptionFormation}
                    select
                    SelectProps={{ native: true }}
                    value={choiceFormation}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  >
                    {formationsOptions.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {`${option.name} ${option.programNumber}`}
                      </option>
                    ))}
                  </TextField> */}
                  <Autocomplete
                    getOptionLabel={(option) => `${option.name} ${option.programNumber}`}
                    options={formationsOptions}
                //   disableClearable
                    onChange={(e, value) => {
                      if (value !== null) {
                        // handleChangeOptionFormation(e);
                        setChoiceFormation(value.id);
                        handleInformationsSessions(choiceEndDate, value.id, choiceSource, choiceRelance, choiceProfession);
                        setFormationAutocomplete({ id: value.id, name: value.name, programNumber: value.programNumber });
                      } else {
                        setChoiceFormation('all');
                        handleInformationsSessions(choiceEndDate, 'all', choiceSource, choiceRelance, choiceProfession);
                        // setFormationAutocomplete({ id: 'all', name: 'Toutes', programNumber: '' });
                      }
                      // setPage(0);
                    }}
                    value={formationAutocomplete}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Afficher par formation"
                        name="formation"
                        variant="outlined"
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    m: 2,
                    width: '20%'
                  }}
                >
                  <TextField
                    label="Afficher par source"
                    name="source"
                    onChange={handleChangeOptionSource}
                    select
                    SelectProps={{ native: true }}
                    value={choiceSource}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  >
                    {sourcesOptions.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box
                  sx={{
                    m: 2,
                    width: '20%'
                  }}
                >
                  <TextField
                    label="Afficher par relance"
                    name="relance"
                    onChange={handleChangeOptionRelance}
                    select
                    SelectProps={{ native: true }}
                    value={choiceRelance}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  >
                    {relanceOptions.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Box>
                <Box
                  sx={{
                    m: 2,
                    width: '20%'
                  }}
                >
                  <TextField
                    label="Afficher par profession"
                    name="profession"
                    onChange={handleChangeOptionProfession}
                    select
                    SelectProps={{ native: true }}
                    value={choiceProfession}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  >
                    {professionsOptions.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </option>
                    ))}
                  </TextField>
                </Box>
              </>
              )}
            </Box>
          </Grid>

          {!loading ? (
            <DataGrid
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'id', sort: 'asc' }],
                },
              }}
              autoHeight
              rowHeight={70}
              // eslint-disable-next-line consistent-return
              getRowHeight={({ id, densityFactor }) => {
                if (id === clickedId) {
                  return 250 * densityFactor;
                }
              }}
              rows={rows}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[15, 25, 50, 100]}
              components={{ Toolbar: GridToolbar }}
            />
          ) : (
            <>
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
            </>
          )}
        </>
      )}
    </Grid>

  );
};

export default FinanceStatistiqueFormationsSuperAdmin;
