/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import useAuth from '../../hooks/useAuth';
import { Alert, Autocomplete, Backdrop, Box, Breadcrumbs, Button, Card, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Input, InputLabel, LinearProgress, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import useMounted from '../../hooks/useMounted';
import { useState, useCallback, useEffect, useRef } from 'react';
import Scrollbar from '../../components/Scrollbar';
import { logApi } from '../../api/logApi';
import Label from '../../components/Label';
import useSettings from '../../hooks/useSettings';
import { importApi } from '../../api/importApi';
import Papa from 'papaparse';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import WarningIcon from '@mui/icons-material/Warning';
import PeopleIcon from '@mui/icons-material/People';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import ChevronRightIcon from '../../icons/ChevronRight';
import PauseIcon from '@mui/icons-material/Pause';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function Imports() {
  const { settings } = useSettings();
  const clickHandlerRef = useRef();
  const argumentRef = useRef();
  const [file, setFile] = useState(null);
  const [lines, setLines] = useState([]);
  const [objects, setObjects] = useState([]);
  const [progress, setProgress] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusMessages, setStatusMessages] = useState([]);
  const [data, setData] = useState([]); // parse CSV data & store it in the component state
  const [columnArray, setColumn] = useState([]);
  const [values, setValues] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedDbItem, setSelectedDbItem] = useState([]);
  const [csvArray, setCsv] = useState([]);
  const [btnShow, setBtnShow] = useState([]);
  const [btnIndex, setBtnIndex] = useState(0);
  const [btn_bool, setBtn_bool] = useState([]);
  const [textStatus, setTextStatus] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const [isConverted, setIsConverted] = useState(false);
  const [infoArray, setInfoArray] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cancelUpload, setCancelUpload] = useState(false);
  const [pausedIndex, setPausedIndex] = useState(0);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const columnNames = ['civilité', 'Prénom', 'Nom', 'téléphone', 'fixe', 'e-mail', 'adresse', 'code postal', 'ville', 'département', 'région', 'profession', 'adeli_rpps', 'commentaire'];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Retour à la première page lorsque le nombre d'éléments par page change
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedValues = values.slice(startIndex, endIndex);

  function ignorer(index, item) {
    btn_bool[index] = false;
    textStatus[index] = 'Le doublon a été ignoré';
    setOpen(false);
  }

  async function remplacer(index) {
    try {
      const response_update = await importApi.updateCustomers(window.localStorage.getItem('accessToken'), selectedItem[0], selectedItem[1], selectedItem[2], selectedItem[3], selectedItem[4], selectedItem[5],
        selectedItem[6], selectedItem[7], selectedItem[8], selectedItem[9], selectedItem[10], selectedItem[11], selectedItem[12], selectedItem[13], selectedFileName);
      if (response_update.status === true) {
        btn_bool[index] = false;
        textStatus[index] = 'Le doublon a été remplacé';
        setOpen(false);
      }
    } catch (error) {
      console.error('Error updating customer:', error);
      setOpen(false);
    }
  }

  async function garder(index) {
    try {
      const response_add = await importApi.addCustomers(window.localStorage.getItem('accessToken'), selectedItem[0], selectedItem[1], selectedItem[2], selectedItem[3], selectedItem[4], selectedItem[5],
        selectedItem[6], selectedItem[7], selectedItem[8], selectedItem[9], selectedItem[10], selectedItem[11], selectedItem[12], selectedItem[13], selectedFileName);
      if (response_add.status === true) {
        btn_bool[index] = false;
        textStatus[index] = 'Le doublon a été ajouté';
        setOpen(false);
      }
    } catch (error) {
      console.error('Error adding customer:', error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setDeleteIsOpen(false);
  };

  const handleOpenDelete = () => {
    setDeleteIsOpen(true);
  };

  const handleListItemClick = (index) => {
    setSelectedItem(csvArray[index]);
    setSelectedDbItem(infoArray[index]);
    setBtnShow(btn_bool[index]);
    setBtnIndex(index);
    setOpen(true);
  };

  const convertCsvToLines = async () => {
    setIsConverted(true);
    let objectArray = [];
    if (file) {
      const reader = new FileReader();
      // eslint-disable-next-line func-names
      reader.onload = async function (event) {
        const content = event.target.result;
        const linesArray = content.split('\n');
        const linesToConvert = linesArray.slice(1); // Ignore the first line
        objectArray = linesToConvert.map((line) => {
          const [
            civility,
            firstname,
            lastname,
            phone,
            phone_fix,
            email,
            adress,
            zip,
            city,
            department,
            region,
            profession,
            adeli_rpps,
            commentaire
          ] = line.split(';');
          return {
            civility,
            firstname,
            lastname,
            phone,
            phone_fix,
            email,
            adress,
            zip,
            city,
            department,
            region,
            profession,
            adeli_rpps,
            commentaire
          };
        });
        if (pausedIndex === 0) {
          let currentProgress = progress;
          let currentIndex = 0;
          for (let i = 0; i < objectArray.length; i++) {
            const object = objectArray[i];
            try {
              // Simulate slower upload
              // eslint-disable-next-line no-await-in-loop
              const pauseProgress = localStorage.getItem('progress');
              if (pauseProgress !== null && Number(pauseProgress) > 0) {
                setPausedIndex(i);
                return;
              }
              await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 1 second
              const response = await importApi.saveCustomers(
                window.localStorage.getItem('accessToken'),
                object,
                selectedFileName,
                currentProgress,
                currentIndex
              );
              currentProgress = ((i + 1) / objectArray.length) * 100;
              currentIndex = i + 1;
              setProgress(currentProgress);
              const { civility, firstname, lastname, phone, phone_fix, email, adress, zip, city, department, region, profession, adeli_rpps, antecedents } = response[0].data;
              if (response[0].isDuplicated === true) {
                csvArray.push([
                  object.civility,
                  object.firstname,
                  object.lastname,
                  object.phone,
                  object.phone_fix,
                  object.email,
                  object.adress,
                  object.zip,
                  object.city,
                  object.department,
                  object.region,
                  object.profession,
                  object.adeli_rpps,
                  object.commentaire
                ]);
                infoArray.push([
                  civility,
                  firstname,
                  lastname,
                  phone,
                  phone_fix,
                  email,
                  adress,
                  zip,
                  city,
                  department,
                  region,
                  profession,
                  adeli_rpps,
                  antecedents
                ]);
                const statusMessage = `${object.civility} ${object.firstname} ${object.lastname} existe dans BDD`;
                setStatusMessages((prevMessages) => [...prevMessages, statusMessage]);
              }
            } catch (error) {
              console.error(
                `Error uploading to database: ${object.civility} ${object.firstname} ${object.lastname} =>`,
                error
              );
            }
          }
        } else {
          let currentProgress = progress;
          let currentIndex = 0;
          for (let i = pausedIndex; i < objectArray.length; i++) {
            const object = objectArray[i];
            try {
              // Simulate slower upload
              // eslint-disable-next-line no-await-in-loop
              const pauseProgress = localStorage.getItem('progress');
              if (pauseProgress !== null && Number(pauseProgress) > 0) {
                setPausedIndex(i);
                return;
              }
              await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 1 second
              const response = await importApi.saveCustomers(
                window.localStorage.getItem('accessToken'),
                object,
                selectedFileName,
                currentProgress,
                currentIndex
              );
              currentProgress = ((i + 1) / objectArray.length) * 100;
              currentIndex = i + 1;
              setProgress(currentProgress);
              const { civility, firstname, lastname, phone, phone_fix, email, adress, zip, city, department, region, profession, adeli_rpps, antecedents } = response[0].data;
              if (response[0].isDuplicated === true) {
                csvArray.push([
                  object.civility,
                  object.firstname,
                  object.lastname,
                  object.phone,
                  object.phone_fix,
                  object.email,
                  object.adress,
                  object.zip,
                  object.city,
                  object.department,
                  object.region,
                  object.profession,
                  object.adeli_rpps,
                  object.commentaire
                ]);
                infoArray.push([
                  civility,
                  firstname,
                  lastname,
                  phone,
                  phone_fix,
                  email,
                  adress,
                  zip,
                  city,
                  department,
                  region,
                  profession,
                  adeli_rpps,
                  antecedents
                ]);
                const statusMessage = `${object.civility} ${object.firstname} ${object.lastname} existe dans BDD`;
                setStatusMessages((prevMessages) => [...prevMessages, statusMessage]);
              }
            } catch (error) {
              console.error(
                `Error uploading to database: ${object.civility} ${object.firstname} ${object.lastname} =>`,
                error
              );
            }
          }
        }
        setAlertMessage('Importation terminée');
      };
      reader.readAsText(file);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setLines([]);
    setObjects([]);
    setProgress(0);
    setAlertMessage('');
    setStatusMessages([]);
    if (event.target.files[0] !== undefined) {
      const changedFile = event.target.files[0];
      if (changedFile) {
        const fileNameWithoutExtension = changedFile.name.split('.').slice(0, -1).join('.');
        setSelectedFileName(fileNameWithoutExtension);
      }
      Papa.parse(
        changedFile, // transformer le fichier sélectionné en data
        {
          header: true, // premiere ligne du csv contient les header des info
          skipEmptyLines: true,
          encoding: 'ISO-8859-1',
          complete(result) {
            const columnArray = [];
            const valueArray = [];
            // eslint-disable-next-line array-callback-return
            result.data.map((d) => {
              columnArray.push(Object.keys(d));
              valueArray.push(Object.values(d));
            });
            setData(result.data);
            setColumn(columnArray[0]);
            setValues(valueArray);
          },
        }
      );
    }
  };

  const handlePauseClick = () => {
    localStorage.setItem('progress', progress);
    setIsPaused(true);
  };

  const handleRestartClick = async () => {
    localStorage.removeItem('progress');
    setIsPaused(false);
    convertCsvToLines();
  };

  const handleCancelUpload = async () => {
    localStorage.removeItem('progress');
    setIsConverted(false);
    setIsPaused(true);
    setDeleteIsOpen(true);
    setPausedIndex(0);
    await importApi.removeCustomers(
      window.localStorage.getItem('accessToken'),
      selectedFileName
    );
    window.location.reload();
  };

  window.addEventListener('beforeunload', () => {
    // Effacer la valeur de progress du localStorage avant le rechargement de la page
    localStorage.removeItem('progress');
  });

  return (
    <>
      <Helmet>
        <title>Imports | Apteed</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            Container
            spacing={4}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Imports des fichiers CSV
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/imports"
                  variant="subtitle2"
                >
                  Imports
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Paper
                elevation={3}
                sx={{ paddingLeft: 2, paddingTop: 2, paddingRight: 2, paddingBottom: 2, width: '98%', margin: 'auto', marginTop: '20px', marginBottom: '20px' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      id="import_b"
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="import_b">
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<CloudUploadIcon />}
                        sx={{ width: '200px', height: '50px' }}
                      >
                        Importer CSV
                      </Button>
                    </label>
                    <TextField
                      id="importFileName"
                      label="Nom de l'import"
                      variant="outlined"
                      margin="dense"
                      value={selectedFileName}
                      onChange={(event) => setSelectedFileName(event.target.value)}
                      sx={{ marginLeft: '20px' }}
                    />
                  </Box>
                  <Box>
                    {columnArray.length > 0 && (
                    <Button
                      variant="contained"
                      className="b1"
                      onClick={!isConverted ? convertCsvToLines : handleOpenDelete}
                      sx={{ width: '200px', height: '50px' }}
                    >
                      {!isConverted ? <SendIcon /> : <DeleteIcon />}
                      {' '}
                      &nbsp;
                      {' '}
                      {!isConverted ? 'Envoyer les données' : 'Supprimer l\'envoi'}
                    </Button>
                    )}
                  </Box>
                  { deleteIsOpen && (
                  <Dialog
                    fullWidth
                    maxWidth="md"
                    open={deleteIsOpen}
                    onClose={handleCloseDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle
                      id="alert-dialog-title"
                      sx={{ textAlign: 'center' }}
                    >
                      {'Annulation d\'envoi'}
                    </DialogTitle>
                    <DialogContent
                      sx={{ textAlign: 'center' }}
                    >
                      <DialogContentText
                        id="alert-dialog-description"
                      >
                        {'Êtes-vous sûr de vouloir annuler l\'importation du fichier ?'}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', padding: '16px', justifyContent: 'center' }}>
                      <Button
                        variant="outlined"
                        startIcon={<CancelIcon />}
                        onClick={handleCloseDelete}
                      >
                        Ignorer
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CheckCircleIcon />}
                        onClick={handleCancelUpload}
                        sx={{ marginLeft: 3 }}
                      >
                        Confirmer
                      </Button>
                    </DialogActions>
                  </Dialog>
                  )}
                </Box>
                <Alert
                  severity="error"
                  sx={{ marginTop: '10px', borderRadius: '20px', width: 'fit-content' }}
                >
                  <Typography paragraph>
                    Les données dans le fichier CSV doivent être séparées par des point-virgules &quot; ; &quot;.
                  </Typography>
                  <Typography paragraph>
                    {'L\'ordre des champs du fichier CSV doit être sous cette forme :'}
                  </Typography>
                  <Typography paragraph>
                    civilité;prénom;nom;téléphone;téléphone_fixe;email;adresse;code postal;ville;département;région;profession;adeli_rpps;commentaire
                  </Typography>
                </Alert>
                <br />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                  {progress >= 0 && progress < 100 ? (
                    <>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                      >
                        {parseInt(progress, 10)}
                        {' '}
                        %
                      </Typography>
                      {!isPaused ? (
                        <Tooltip
                          title="Suspendre l'importation"
                          arrow
                        >
                          <IconButton onClick={handlePauseClick}>
                            <PauseIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title="Reprendre l'importation"
                          arrow
                        >
                          <IconButton onClick={handleRestartClick}>
                            <PlayCircleFilledWhiteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ color: 'green' }}
                      fontWeight="bold"
                    >
                      {alertMessage}
                    </Typography>
                  )}
                  <LinearProgress
                    sx={{ width: progress === 100 ? '85%' : '95%', height: 20 }}
                    size="sm"
                    variant="determinate"
                    value={progress}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item>
              {statusMessages.length > 0 && alertMessage !== 'Importation annulée' && (
                <Card
                  elevation={3}
                  sx={{ width: '98%', margin: 'auto', maxHeight: 300, overflowY: 'auto', marginTop: '20px', paddingLeft: 2, paddingTop: 2, paddingRight: 2, paddingBottom: 2, marginBottom: '20px' }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ alignItems: 'center' }}
                  >
                    Liste des doublons :
                  </Typography>
                  {statusMessages.map((message, index) => (
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      display="flex"
                    >
                      <Grid
                        item
                        xs={8}
                      >
                        <Typography
                          style={{ marginLeft: '50px', height: 25 }}
                          variant="body1"
                          gutterBottom
                        >
                          {message}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                      >
                        {btn_bool[index] === false ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', height: 25, width: 'auto', marginLeft: 1, border: '1px solid #1b2445', borderRadius: '4px' }}>
                            {(() => {
                              switch (textStatus[index]) {
                                case 'Le doublon a été ignoré':
                                  return <ClearIcon sx={{ color: 'red', marginLeft: 3 }} />;
                                case 'Le doublon a été remplacé':
                                  return <ChangeCircleIcon sx={{ color: 'blue', marginLeft: 3 }} />;
                                case 'Le doublon a été ajouté':
                                  return <DoneIcon sx={{ color: 'green', marginLeft: 3 }} />;
                                default:
                                  return null;
                              }
                            })()}
                            {' '}
              &nbsp;&nbsp;&nbsp;&nbsp;
                            <span
                              style={{
                                color: (() => {
                                  switch (textStatus[index]) {
                                    case 'Le doublon a été ignoré':
                                      return 'red';
                                    case 'Le doublon a été remplacé':
                                      return 'blue';
                                    case 'Le doublon a été ajouté':
                                      return 'green';
                                    default:
                                      return 'black';
                                  }
                                })(),
                                marginRight: '8px',
                              }}
                            >
                              {textStatus[index]}
                            </span>
                          </Box>
                        ) : (
                          <Button
                            key={index}
                            sx={{ marginRight: '20px', marginLeft: '5px', height: 25 }}
                            variant="outlined"
                            onClick={() => handleListItemClick(index)}
                          >
                            {' '}
                            <PeopleIcon />
                            &nbsp;
                            {' '}
                            Gérer le doublon
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {' '}
                      {/* "Use Google's location service?" */}
                      {' '}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <TableContainer>
                          <Table
                            className="table"
                            aria-label="Comparison Table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                {columnArray.map((col, i) => (
                                  <TableCell key={i}>{col}</TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                  >
                                    Champ de la base de données
                                  </Typography>
                                </TableCell>
                                {selectedDbItem.map((value, i) => (
                                  <TableCell key={i}>{value}</TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                  >
                                    Champ du fichier CSV
                                  </Typography>
                                </TableCell>
                                {selectedItem.map((value, i) => (
                                  <TableCell key={i}>{value}</TableCell>
                                ))}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>

                      </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => ignorer(btnIndex, selectedItem)}
                      >
                        Ignorer

                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => remplacer(btnIndex)}
                      >
                        Modifier

                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => garder(btnIndex)}
                        autoFocus
                      >
                        Garder les deux
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Card>
              )}
            </Grid>
            <Grid item>
              {columnArray.length > 0 && (
              <Paper
                elevation={3}
                sx={{ paddingLeft: 2, paddingTop: 2, paddingRight: 2, width: '98%', margin: 'auto' }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                >
                  Données importées du fichier CSV :
                </Typography>
                <TableContainer>
                  <Table
                    className="table"
                    aria-label="Lines Table"
                  >
                    <TableHead>
                      <TableRow>
                        {columnNames.map((col, i) => (
                          <TableCell
                            key={i}
                            sx={{ textTransform: 'uppercase' }}
                          >
                            {col}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedValues.map((v, i) => (
                        <TableRow key={i}>
                          {v.map((value, j) => (
                            <TableCell key={j}>{value}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]} // Options pour le nombre d'éléments par page
                  component="div"
                  count={values.length} // Nombre total d'éléments
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
