/* eslint-disable no-unused-vars */
import Chart from 'react-apexcharts';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import ArrowRightIcon from '../../../icons/ArrowRight';
import { useCallback, useState, useEffect } from 'react';
import useMounted from '../../../hooks/useMounted';
import * as moment from 'moment';
import useAuth from '../../../hooks/useAuth';
import { relaunchApi } from '../../../api/relaunchApi';
import PropTypes from 'prop-types';

const RelaunchStats = (props) => {
  const { state } = props;
  const theme = useTheme();
  const mounted = useMounted();
  const { user } = useAuth();
  const [infoRelaunch, setInfoRelaunch] = useState([]);
  const correspondence = { shift: 'Décalage', turn1: 'Relance Tour 1', turn2: 'Relance Tour 2' };

  const getRelaunch = useCallback(async () => {
    try {
      const data = await relaunchApi.getRelaunch(state, localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('Aucune relance possible');
      }

      if (mounted.current) {
        setInfoRelaunch(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getRelaunch();
  }, [getRelaunch, state]);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          // p: 2
        }}
      >
        <Grid
          container
          // rowSpacing={2}
          // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {infoRelaunch.map((info, index) => {
            const chartOptions = {
              chart: {
                background: 'transparent',
                stacked: false,
                toolbar: {
                  show: false
                }
              },
              colors: [theme.palette.mode === 'light' ? '#7EB627' : 'white'],
              // eslint-disable-next-line no-nested-ternary
              labels: [`${info.progression === null ? 0 : info.progression < 100 ? info.progression : 100} %`],
              plotOptions: {
                radialBar: {
                  dataLabels: {
                    value: {
                      show: false
                    }
                  },
                  hollow: {
                    size: '60%'
                  },
                  track: {
                    background: theme.palette.background.default
                  }
                }
              },
              theme: {
                mode: theme.palette.mode
              }
            };
            const chartSeries = [info.progression === null ? 0 : info.progression];
            let borderColor = '#7EB627';
            const limitDate = new Date(info.end_date);
            if (new Date() >= limitDate.setDate(limitDate.getDate() - 20)) {
              borderColor = 'red';
            }
            return (
              ((info.nbCustomers !== '0' && user.id_service === 4) || user.access_level === 0) && (
              <Grid
                item
                sx={{
                  backgroundColor: 'background.default',
                  // p: 2
                  pt: 2,
                  pr: 2
                }}
              >
                <Card
                  sx={{ border: `2px solid ${borderColor}` }}
                >
                  <CardContent
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Box>
                      <Chart
                        height="160"
                        options={chartOptions}
                        series={chartSeries}
                        type="radialBar"
                        width="300"
                      />
                      <Typography sx={{ textAlign: 'center' }}>Semaine en cours</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: 2
                      }}
                    >
                      <Box sx={{ width: '320px' }}>
                        <Typography
                          color="primary"
                          variant="h5"
                        >
                          {`Sessions terminant le ${moment(info.end_date).format('DD/MM/YYYY')}`}
                        </Typography>
                        <Typography
                          color="primary"
                          variant="h6"
                        >
                          {correspondence[info.state]}
                        </Typography>
                      </Box>
                      <Box sx={{ flexGrow: 1 }} />
                    </Box>
                  </CardContent>
                  <Divider />
                  <CardActions
                    disableSpacing
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Button
                      component={RouterLink}
                      to={`/dashboard/relaunch/${info.state}/${info.end_date}`}
                      color="primary"
                      endIcon={<ArrowRightIcon fontSize="small" />}
                      variant="text"
                    >
                      Plus d&apos;informations
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              ));
          })}
        </Grid>
      </Box>
    </Box>
  );
};

RelaunchStats.propTypes = {
  state: PropTypes.string.isRequired
};

export default RelaunchStats;
