/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import { useState, createContext } from 'react';

export const PartnersContext = createContext();

export const PartnersProvider = ({ children }) => {
  const [partners, setPartners] = useState([]);

  return (
    <PartnersContext.Provider value={{ partners, setPartners }}>
      {children}
    </PartnersContext.Provider>
  );
};

export const DeliveriesContext = createContext();

export const DeliveriesProvider = ({ children }) => {
  const [deliveries, setDeliveries] = useState([]);

  return (
    <DeliveriesContext.Provider value={{ deliveries, setDeliveries }}>
      {children}
    </DeliveriesContext.Provider>
  );
};
