import { Autocomplete, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { campaignApi } from "../../api/campaignApi";
import toast from 'react-hot-toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import * as Yup from 'yup';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogAddCommercials = (propsData) => {
    const { setIsLoading, idCampaign, isLoading, openAddcommercials, setOpenAddcommercials, donnees, commercialsToAdd, selectedCommercials,
        selectedCommercialsEquitable, typeCampaign, commercialToAdd, setCommercialsToAdd, setDisplayEquitable, setDisplayNumberCustomers,
        setDisplayNumberCustomersNotAttributed, setDisplayReattribution, displayReattribution, commercialsCampaign, setSelectedCommercials,
        setSelectedCommercialsEquitable, displayEquitable, displayNumberCustomers, displayNumberCustomersNotAttributed, setNbCustomers,
        setNbCustomersNotAttributed, formIsEmpty } = propsData;

 const initialValuesAddCommercials = {
    type: null,
    nbCustomers: donnees.numberProspectsNotTreated !== undefined ? donnees.numberProspectsNotTreated.number : 0,
    nbCustomersNotAttributed: donnees.numberProspectsNotAttributedAttribuable !== undefined ? donnees.numberProspectsNotAttributedAttribuable.number : 0
  };

  const validationSchemaAddCommercials = Yup
    .object()
    .shape({
      type: Yup.string().required(),
      nbCustomers: Yup.number().max(donnees.numberProspectsNotTreated !== undefined ? donnees.numberProspectsNotTreated.number : 0, 'Nombre trop grand'),
      nbCustomersNotAttributed: Yup.number().max(donnees.numberProspectsNotAttributedAttribuable !== undefined ? donnees.numberProspectsNotAttributedAttribuable.number : 0, 'Nombre trop grand'),
    });


    return (
        <Dialog
        open={openAddcommercials}
        onClose={() => setOpenAddcommercials(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Ajouter un commercial / des commerciaux</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValuesAddCommercials}
            validationSchema={validationSchemaAddCommercials}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              try {
                setIsLoading(true);
                if (commercialsToAdd.length === 1) {
                  // Traitement pour commercialsToAdd égal à 1
                  await campaignApi.addCommercialToCampaign(idCampaign, commercialsToAdd, selectedCommercials, selectedCommercialsEquitable, typeCampaign, values, localStorage.getItem('accessToken'));
                } else if (commercialsToAdd.length > 1) {
                  // Traitement pour commercialsToAdd supérieur à 1
                  await campaignApi.addCommercialsToCampaign(idCampaign, commercialsToAdd, selectedCommercials, selectedCommercialsEquitable, typeCampaign, values, localStorage.getItem('accessToken'));
                }
                setStatus({ success: true });
                setSubmitting(false);
                toast.success('Ajout de commerciaux effectué avec succès');
                setOpenAddcommercials(false);
                setIsLoading(false);
                // setShowLinearProgress(false);
                window.location.reload(false);
              } catch (err) {
                console.error(err);
                toast.error('Il y a eu un souci lors de l\'ajout des commerciaux !');
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setIsLoading(false);
                // setShowLinearProgress(false);
              }
            }}
          >
            {({ errors, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form
                onSubmit={handleSubmit}
              >
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    <>
                      <Grid
                        container
                        display="flex"
                        alignItems="center"
                      >
                        <Box
                          sx={{
                            m: 1,
                            maxWidth: '100%',
                            width: 300
                          }}
                        >
                          <Autocomplete
                            multiple
                            getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                            options={commercialToAdd}
                            disableClearable
                            disableCloseOnSelect
                            onChange={(e, value) => {
                              setCommercialsToAdd(value);
                            }}
                            value={commercialsToAdd}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Commercial / Commerciaux à ajouter"
                              />
                            )}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {`${option.firstname} ${option.lastname}`}
                              </li>
                            )}
                          />
                        </Box>
                        <Box sx={{ ml: 2 }}>
                          <>

                            <FormControl>
                              <Typography variant="h6">Répartition</Typography>
                              <RadioGroup
                                row
                                aria-labelledby="radio-buttons-group-label-add-commercial"
                                defaultValue="Équitable"
                                name="type"
                                value={values.type}
                                onChange={(e, value) => {
                                  setFieldValue(
                                    'type', value
                                  );
                                }}
                              >
                                <FormControlLabel
                                  value="Équitable"
                                  control={<Radio />}
                                  label="Équitable"
                                  onClick={() => { setDisplayNumberCustomers(false); setDisplayReattribution(false); setDisplayEquitable(true); setDisplayNumberCustomersNotAttributed(false); }}
                                />
                                <FormControlLabel
                                  value="Nombre"
                                  control={<Radio />}
                                  label="Nombre à attribuer"
                                  onClick={() => { setDisplayNumberCustomers(true); setDisplayReattribution(false); setDisplayEquitable(false); setDisplayNumberCustomersNotAttributed(false); }}
                                />
                                <FormControlLabel
                                  value="Réattribution"
                                  control={<Radio />}
                                  label="Réattribution"
                                  onClick={() => { setDisplayNumberCustomers(false); setDisplayReattribution(true); setDisplayEquitable(false); setDisplayNumberCustomersNotAttributed(false); }}
                                />
                                <FormControlLabel
                                  value="Non Attribués"
                                  control={<Radio />}
                                  label="Non Attribués"
                                  onClick={() => { setDisplayNumberCustomers(false); setDisplayReattribution(false); setDisplayEquitable(false); setDisplayNumberCustomersNotAttributed(true); }}
                                />
                              </RadioGroup>
                            </FormControl>
                            <ErrorMessage
                              name="type"
                              render={(msg) => <p><font color="RED">{msg}</font></p>}
                            />
                            <ErrorMessage
                              name="nbCustomers"
                              render={(msg) => <p><font color="RED">{msg}</font></p>}
                            />
                            <ErrorMessage
                              name="nbCustomersNotAttributed"
                              render={(msg) => <p><font color="RED">{msg}</font></p>}
                            />
                            {displayReattribution && (
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-add-commercial"
                                options={commercialsCampaign}
                                value={selectedCommercials}
                                disableCloseOnSelect
                                onChange={(event, newValue) => {
                                  setSelectedCommercials(newValue);
                                }}
                                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {`${option.firstname} ${option.lastname} (non traités : ${option.nbNotTreated})`}
                                  </li>
                                )}
                                style={{ width: 400 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Commerciaux à réattribuer"
                                  />
                                )}
                              />
                            )}
                            {displayEquitable && (
                              <Autocomplete
                                multiple
                                id="checkboxes-tags-add-commercial-equitable"
                                options={commercialsCampaign}
                                value={selectedCommercialsEquitable}
                                disableCloseOnSelect
                                onChange={(event, newValue) => {
                                  setSelectedCommercialsEquitable(newValue);
                                }}
                                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {`${option.firstname} ${option.lastname} (non traités : ${option.nbNotTreated})`}
                                  </li>
                                )}
                                style={{ width: 400 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Commerciaux à ignorer"
                                  />
                                )}
                              />
                            )}
                            {displayNumberCustomers && (
                            <>
                              <TextField
                                label="Nombre d'apprenants"
                                type="number"
                                autoFocus
                                onChange={(event) => {
                                  values.nbCustomers = parseInt(event.target.value, 10);
                                  setNbCustomers(parseInt(event.target.value, 10));
                                }}
                                value={parseInt(values.nbCustomers, 10)}
                                variant="outlined"
                                InputProps={{ inputProps: { max: donnees.numberProspectsNotTreated.number } }}
                              />
                            </>

                            )}
                            {displayNumberCustomersNotAttributed && (
                            <>
                              <TextField
                                label="Nombre d'apprenants à attribuer"
                                type="number"
                                autoFocus
                                onChange={(event) => {
                                  values.nbCustomersNotAttributed = parseInt(event.target.value, 10);
                                  setNbCustomersNotAttributed(parseInt(event.target.value, 10));
                                }}
                                value={parseInt(values.nbCustomersNotAttributed, 10)}
                                variant="outlined"
                                InputProps={{ inputProps: { max: donnees.numberProspectsNotAttributedAttribuable.number } }}
                              />
                            </>

                            )}
                          </>
                        </Box>
                      </Grid>
                    </>
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
                    <Button
                      sx={{
                        width: '20%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginLeft: 1
                      }}
                      onClick={handleSubmit}
                      disabled={isSubmitting || formIsEmpty || isLoading}
                    >
                      Enregistrer
                    </Button>
                    {isLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <CircularProgress />
                    </Box>
                    )}
                  </Box>
                </>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
};

export default DialogAddCommercials;
