class ImportApi {
  async saveCustomers(accessToken, object, importName, progress, index) {
    const urlImport = `${process.env.REACT_APP_API_URL}/api/import/save`;

    const resp = await fetch(urlImport, {
      method: 'POST',
      body: JSON.stringify({ objects: [object], import_name: importName, progress, index }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async updateCustomers(accessToken, civility, firstname, lastname, phone, phoneFix, email, adress, zip, city, department, region, profession, adeliRpps, commentaire, importName) {
    const urlImport = `${process.env.REACT_APP_API_URL}/api/import/update`;

    const resp = await fetch(urlImport, {
      method: 'POST',
      body: JSON.stringify({ civility, firstname, lastname, phone, phone_fix: phoneFix, email, adress, zip, city, department, region, profession, adeli_rpps: adeliRpps, antecedents: commentaire, import_name: importName }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async addCustomers(accessToken, civility, firstname, lastname, phone, phoneFix, email, adress, zip, city, department, region, profession, adeliRpps, commentaire, importName) {
    const urlImport = `${process.env.REACT_APP_API_URL}/api/import/add`;

    const resp = await fetch(urlImport, {
      method: 'POST',
      body: JSON.stringify({ civility, firstname, lastname, phone, phone_fix: phoneFix, email, adress, zip, city, department, region, profession, adeli_rpps: adeliRpps, antecedents: commentaire, import_name: importName }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async removeCustomers(accessToken, importName) {
    const urlImport = `${process.env.REACT_APP_API_URL}/api/import/delete`;

    const resp = await fetch(urlImport, {
      method: 'POST',
      body: JSON.stringify({ import_name: importName }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }
}

export const importApi = new ImportApi();
