import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;
const Logo = (props) => (
  <LogoRoot
    height="200"
    version="1.1"
    viewBox="0 0 566.02906 191.66707"
    width="700"
    {...props}
  >
    <title>Logo Apteed</title>

    <defs>
      <linearGradient
        id="Dégradé_sans_nom_11"
        x1="446.13431"
        y1="71.29244"
        x2="446.13431"
        y2="191.66707"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.3866"
          stopColor="#a2c614"
        />
        <stop
          offset="1"
          stopColor="#169a39"
        />
      </linearGradient>
    </defs>
    <path
      d="M355.22227,155.31365,445.99826.005h30.54547l89.48533,155.30868H526.87923l-64.349-111.129a1.19728,1.19728,0,0,0-2.07621.00693L397.09682,155.31365Z"
      fill="#1c2445"
    />
    <path
      d="M451.53009,116.37893l22.694-38.18185s4.30218-6.56082,9.78746-6.77593c4.22892-.16584,20.67242-.13279,27.983-.10895a9.51778,9.51778,0,0,1,6.97437,3.03951,40.14594,40.14594,0,0,1,5.78487,9.32562s-7.18529-11.39574-12.88568-1.17807l-59.26252,101.7824s-4.40974,6.95519-10.43279,7.38541H424.53392a14.31236,14.31236,0,0,1-10.11013-6.56083l-32.59015-56.08047a6.58624,6.58624,0,0,0-2.72921-2.64049c-2.48621-1.22538-6.81885-1.29879-11.58966,7.89712a47.845,47.845,0,0,1,6.942-10.77752,13.765,13.765,0,0,1,10.30439-4.51349l26.48976.0764s5.27017.21511,9.03457,6.023l11.96815,21.00634a1.15335,1.15335,0,0,0,1.99928.0086Z"
      fill="url(#Dégradé_sans_nom_11)"
    />
    <path
      d="M0,107.91992H30.22656c2.12891,18.30567,12.5586,25.543,34.27051,25.543,15.752,0,29.58691-5.53418,29.58691-19.583C94.084,98.97949,79.61035,96,56.4082,90.67773,29.16211,84.50488,4.25781,77.26758,4.25781,45.55176,4.25781,15.53809,28.73633,0,62.58105,0c34.27051,0,56.40821,16.81543,58.749,47.46777H91.74316C90.04,32.1416,78.12012,24.47852,62.36816,24.47852c-16.60254,0-27.459,7.02441-27.459,17.88086,0,12.3457,10.64355,15.96386,33.20605,20.85937,31.291,6.81152,56.834,14.26172,56.834,47.043,0,30.86426-24.9043,48.10547-59.17481,48.10547C24.47949,158.36719,1.06445,140.06152,0,107.91992Z"
      fill="#1c2445"
    />
    <path
      d="M141.33594,167.94629h10.21777c11.49414,0,16.17676-5.96,16.17676-14.90039,0-5.96-2.12793-12.98438-8.30078-29.58692l-29.375-77.69433h30.43847l16.17774,49.1709c3.40625,10.0039,7.02441,23.83984,7.02441,23.83984h.42578s2.98047-13.83594,6.38575-23.83984L205.833,45.76465h29.16211L196.89258,157.72949c-8.30176,24.26563-16.38965,32.99317-38.52735,32.99317H141.33594Z"
      fill="#1c2445"
    />
    <path
      d="M273.0918,60.665h.63867c7.45019-11.707,17.45508-17.88086,32.35547-17.88086,22.5625,0,37.67578,17.0293,37.67578,40.86914v71.30859H314.8125V87.91113c0-11.707-6.81152-20.00879-18.94434-20.00879-12.77148,0-22.13769,10.2168-22.13769,25.11719v61.94238H244.78223V45.76465H273.0918Z"
      fill="#1c2445"
    />
  </LogoRoot>
);

export default Logo;
