/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import * as moment from 'moment';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import SmsIcon from '@mui/icons-material/Sms';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PencilAltIcon from '../../../icons/PencilAlt';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import useAuth from '../../../hooks/useAuth';
import { useEffect, useState, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { customerApi } from '../../../api/customerApi';
import toast from 'react-hot-toast';

const RelaunchCustomerRelaunchComment = (props) => {
  const { comment_relance, customer, formations, customerId, campaignId, setIsTreated, saveTime, commentRelance, setCommentRelance, ...other } = props;
  const { user } = useAuth();
  const [commenting, setCommenting] = useState(false);
  useEffect(() => {
    setCommentRelance(comment_relance);
  }, []);
  useEffect(() => {
    if (commentRelance !== null && commentRelance !== undefined) {
      // pour tester si l'objet est vide
      if (Object.keys(commentRelance).length !== 0 && commentRelance.constructor === Object) {
        setCommentRelance(commentRelance);
      }
    }
  }, [commentRelance]);

  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

  // partie poster un commentaire relance
  const qualificationLabel = [
    'Pas répondu', 'À rappeler', 'Numéro incorrect', 'Va se connecter', 'Suivi - Téléphone', 'Suivi - Mail', 'Suivi - SMS', 'Décalage', 'Annulation'
  ];

  const [choiceRelance, setChoiceRelance] = useState(null);
  const [choiceQualificationRecall, setChoiceQualificationRecall] = useState(null);
  const [displayDateRecall, setDisplayDateRecall] = useState(false);

  const handleChangeQualification = (value) => {
    setChoiceRelance(value);
    switch (value) {
      case 'À rappeler':
        setDisplayDateRecall(true);
        break;
      default:
        setDisplayDateRecall(false);
        break;
    }
  };

  const [isFormFilled, setIsFormFilled] = useState(true);

  useEffect(() => {
    if ((choiceRelance !== null && (choiceRelance !== null && choiceRelance !== 'À rappeler'))) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }
    if (choiceRelance !== null && choiceRelance === 'À rappeler' && choiceQualificationRecall !== null) {
      setIsFormFilled(false);
    }
  });

  const formikRef = useRef();
  useEffect(() => {
    setChoiceQualificationRecall(null);
    if (formikRef.current) {
      // réinitialiser la value des selects au changement du select de qualification
      formikRef.current.values.choiceQualificationRecall = null;
    }
  }, [choiceRelance]);

  return (
    <div>
      <div>
        {commenting && (
        <Formik
        // pour pouvoir recupérer values de formik dans le useEffect
          innerRef={formikRef}
          initialValues={{ commentRelance: null,
            choiceRelance: null,
            choiceQualificationRecall: null }}
          enableReinitialize
          validationSchema={Yup
            .object()
            .shape({
              commentRelance: Yup.string().nullable(),
              choiceRelance: Yup.string().nullable(),
              choiceQualificationRecall: Yup.string().nullable()
            })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
              const jsonAnswer = await customerApi.addCommentRelance(customerId, user.id, values, localStorage.getItem('accessToken'));
              setStatus({ success: true });
              resetForm({});
              setSubmitting(false);
              toast.success('Commentaire de la relance enregistré !');
              setCommenting(!commenting);
              setCommentRelance({ id: jsonAnswer.data.comment_id,
                comment: jsonAnswer.data.comment,
                createdAt: jsonAnswer.data.created_at,
                firstname: user.firstname,
                lastname: user.lastname,
                qualification_relance: choiceRelance,
                recall_date: choiceQualificationRecall,
              });
              // pour réinitialiser le formulaire
              setChoiceQualificationRecall(null);
              setChoiceRelance(null);
              setDisplayDateRecall(false);
              setIsTreated(true);
            } catch (err) {
              console.error(err);
              toast.error('Il y a eu un souci lors de l\'enregistrement !');
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, setFieldValue, touched, values }) => (
            <form
              onSubmit={handleSubmit}
            >
              {commenting && (
                <>
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      mb={2}
                      ml={2}
                      mt={2}
                    >
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Laisser un commentaire
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      ml={2}
                      mt={2}
                      // style={{ width: '250px' }}
                    >
                      <Autocomplete
                        options={qualificationLabel}
                        onChange={(e, value) => {
                          handleChangeQualification(value);
                          setFieldValue(
                            'choiceRelance',
                            value !== null ? value : choiceRelance
                          );
                        }}
                        value={values.choiceRelance}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Qualification"
                            name="choiceRelance"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    {displayDateRecall && (
                    <Grid
                      item
                      ml={4}
                      mt={2}
                    >
                      <TextField
                        error={Boolean(touched.choiceQualificationRecall && errors.choiceQualificationRecall)}
                        fullWidth
                        helperText={touched.choiceQualificationRecall && errors.choiceQualificationRecall}
                        label="Date et heure du rappel"
                        name="choiceQualificationRecall"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setChoiceQualificationRecall(event.target.value);
                        }}
                        type="datetime-local"
                        value={values.choiceQualificationRecall}
                        variant="outlined"
                    // pour éviter la superposition de jj/mm/aaaa et date de naissance dans le champ
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    )}
                  </Grid>
                  <TextField
                    sx={{ mt: 2, width: 700, ml: 2 }}
                    label="Commentaire"
                    error={Boolean(touched.commentRelance && errors.commentRelance)}
                    fullWidth
                    helperText={touched.commentRelance && errors.commentRelance}
                    multiline
                    name="commentRelance"
                    variant="filled"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.commentRelance}
                    minRows={2}
                  />
                  <Grid
                    container
                    mt={1}
                    mb={1}
                  >
                    <Grid
                      item
                      xs={2}
                      ml={2}
                    >
                      <Button
                        color="primary"
                        startIcon={<SaveAltIcon fontSize="small" />}
                        variant="contained"
                        disabled={isFormFilled}
                        onClick={() => {
                          handleSubmit(); saveTime();
                        }}
                      >
                        Enregistrer
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                    >
                      <Button
                        color="primary"
                        startIcon={<CancelIcon fontSize="small" />}
                        variant="contained"
                        onClick={() => { setCommenting(!commenting); }}
                      >
                        Annuler
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </form>
          )}
        </Formik>
        )}

      </div>
      <Card
        sx="margin-top:20px;
        min-height: 195px;"
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Dernier commentaire" />
          {!commenting && (
          <Button
            style={{ marginRight: 20, marginTop: 20 }}
            color="primary"
            startIcon={<PencilAltIcon fontSize="small" />}
            sx={{ mt: 1, mb: 3 }}
            variant="contained"
            onClick={() => { setCommenting(!commenting); }}
          >
            Qualifier l&apos;appel
          </Button>
          )}
        </Box>
        <Divider />
        {(user.access_level === 0 || user.id_service === 4) && (
        <CardContent>
          {comment_relance && (
            <>
              <Typography>
                Relance
              </Typography>
              <Divider />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...other}
              >
                <Avatar
                  sx={{
                    color: 'common.white'
                  }}
                >
                  {commentRelance.qualification_relance === 'Suivi - Mail' && (
                  <MailOutlineIcon style={{ color: '#16B84E' }} />
                  )}
                  {commentRelance.qualification_relance === 'Suivi - SMS' && (
                  <SmsIcon style={{ color: '#16B84E' }} />
                  )}
                  {commentRelance.qualification_relance === 'Suivi - Téléphone' && (
                  <PhoneAndroidIcon style={{ color: '#16B84E' }} />
                  )}
                  {commentRelance.qualification_relance === 'Décalage' && (
                  <CalendarTodayIcon style={{ color: 'white' }} />
                  )}
                  {commentRelance.qualification_relance === 'À rappeler' && (
                  <PhoneIcon style={{ color: 'orange' }} />
                  )}
                  {commentRelance.qualification_relance === 'Souhaite un email' && (
                  <MailOutlineIcon style={{ color: 'purple' }} />
                  )}
                  {commentRelance.qualification_relance === 'Annulation' && (
                  <HighlightOffIcon style={{ color: '#ff5f52' }} />
                  )}
                  {commentRelance.qualification_relance === 'Va se connecter' && (
                  <HourglassEmptyIcon style={{ color: '#ffe436' }} />
                  )}
                  {commentRelance.qualification_relance === 'Numéro incorrect' && (
                  <PhoneDisabledIcon style={{ color: 'blue' }} />
                  )}
                  {commentRelance.qualification_relance === 'Pas répondu' && (
                  <PhoneMissedIcon style={{ color: 'black' }} />
                  )}
                </Avatar>
                <Typography
                  color="textPrimary"
                  sx={{ ml: 2 }}
                  variant="body2"
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {`${commentRelance.firstname} ${commentRelance.lastname}`}
                  </Typography>
                  <Typography
                    style={{ margin: 'auto', padding: 'auto' }}
                    color={(commentRelance.qualification_relance === 'Suivi - Mail' || commentRelance.qualification_relance === 'Suivi - SMS' || commentRelance.qualification_relance === 'Suivi - Téléphone') ? '#16B84E' : commentRelance.qualification_relance === 'Annulation' ? '#ff5f52' : commentRelance.qualification_relance === 'Décalage' ? '#888888' : commentRelance.qualification_relance === 'À rappeler' ? 'orange' : commentRelance.qualification_relance === 'Souhaite un email' ? '#9a68af' : commentRelance.qualification_relance === 'Va se connecter' ? '#d7b52c' : commentRelance.qualification_relance === 'Numéro incorrect' ? '#1E90FF' : commentRelance.qualification_relance === 'Pas répondu' ? '#888888' : 'black'}
                    variant="subtitle2"
                  >
                    {`${commentRelance.qualification_relance} ${commentRelance.recall_date === undefined || commentRelance.recall_date === null ? '' : moment(commentRelance.recall_date).format('DD/MM/YYYY à H:mm')}`}
                  </Typography>
                  {commentRelance.comment}
                </Typography>
                <Typography
                  color="textPrimary"
                  sx={{ ml: 'auto' }}
                  variant="caption"
                >
                  {new Date(commentRelance.createdAt).toLocaleDateString('fr-FR', options)}
                </Typography>
              </Box>

            </>
          )}
        </CardContent>
        )}
      </Card>
    </div>
  );
};

RelaunchCustomerRelaunchComment.propTypes = {
  comment_relance: PropTypes.object,
  customer: PropTypes.object,
  formations: PropTypes.array,
  customerId: PropTypes.string,
  campaignId: PropTypes.string,
  setIsTreated: PropTypes.func,
  saveTime: PropTypes.func,
  commentRelance: PropTypes.object,
  setCommentRelance: PropTypes.func
};

export default RelaunchCustomerRelaunchComment;
