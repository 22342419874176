import { Autocomplete, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import { Formik } from "formik";
import { campaignApi } from "../../api/campaignApi";
import toast from 'react-hot-toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogRetrieve = (propsData) => {
    const { initialValues, validationSchema, setIsLoading, idCampaign, qualifications, isLoading, selectedStatusRetrieve, selectedCommercialRetrieve,
        openRetrieve, setOpenRetrieve, activeStepRetrieve, stepsRetrieve, setSelectedStatusRetrieve,handleBackRetrieve, handleNextRetrieve
 } = propsData;


    return (
        <Dialog
        open={openRetrieve}
        onClose={() => setOpenRetrieve(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Paramètres du rattrapage</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            // enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              try {
                // NOTE: Make API request
                setIsLoading(true);
                await campaignApi.retrieveCustomers(idCampaign, selectedStatusRetrieve, selectedCommercialRetrieve, localStorage.getItem('accessToken'));
                setStatus({ success: true });
                setSubmitting(false);
                toast.success('Rattrapage effectué avec succès');
                setOpenRetrieve(false);
                setIsLoading(false);
                // rechargement de la page
                window.location.reload(false);
              } catch (err) {
                console.error(err);
                toast.error('Il y a eu un souci lors du rattrapage !');
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setIsLoading(false);
              }
            }}
          >
            {({ errors, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form
                onSubmit={handleSubmit}
              >
                <Stepper
                  activeStep={activeStepRetrieve}
                  sx={{ mb: 2 }}
                >
                  {stepsRetrieve.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    {activeStepRetrieve === 0 && (
                    <>
                      <Grid container>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-statuts-retrieve"
                          options={qualifications}
                          value={selectedStatusRetrieve}
                          disableCloseOnSelect
                          onChange={(event, newValue) => {
                            setSelectedStatusRetrieve([
                              ...newValue
                            ]);
                          }}
                          getOptionLabel={(option) => option.qualification}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.qualification}
                            </li>
                          )}
                          style={{ width: 400 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Statuts"
                            />
                          )}
                        />
                      </Grid>
                    </>
                    )}
                    {activeStepRetrieve === 1 && (
                    <>
                      <Typography
                        variant="h5"
                        sx={{ mb: 2 }}
                      >
                        Récapitulatif
                      </Typography>
                      <Box>
                        <Typography
                          variant="h6"
                        >
                          Statuts
                        </Typography>
                        <Box>
                          {selectedStatusRetrieve.length > 0 ? (selectedStatusRetrieve.map((status) => (
                            <Typography
                              variant="body1"
                              sx={{ ml: 3 }}
                            >
                              {status.qualification}
                            </Typography>
                          ))) : (
                            <Typography
                              variant="body1"
                              sx={{ ml: 3 }}
                            >
                              Tous statuts
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </>
                    )}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStepRetrieve === 0}
                      onClick={handleBackRetrieve}
                      sx={{ mr: 1 }}
                    >
                      Précédent
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStepRetrieve !== 1 ? (
                      <Button onClick={handleNextRetrieve}>
                        {activeStepRetrieve === stepsRetrieve.length - 1 ? 'Passer au récapitulatif' : 'Suivant'}
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Enregistrer
                      </Button>
                    )}
                    {isLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                </>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
};

export default DialogRetrieve;
