/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Box, Breadcrumbs, Button, Card, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, Tab, Table, TableBody, TableRow, Typography, useTheme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../../hooks/useSettings';
import gtm from '../../../lib/gtm';
import ChevronRightIcon from '../../../icons/ChevronRight';
import { Link, Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { ChevronRight, FileDownloadOutlined } from '@material-ui/icons';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Trash from '../../../icons/Trash';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CustomReportDetailsTable from './components/CustomReportDetailsTable';
import CustomReportDetailsTab from './components/CustomReportDetailsTab';
import CustomReportDetailsStats from './components/CustomReportDetailsStats';
import CustomRepportDetailsOptionsBar from './components/CustomReportDetailsOptionsBar';
import { reportsApi } from '../../../api/reportsApi';
import toast from 'react-hot-toast';
import PencilAlt from '../../../icons/PencilAlt';
import moment from 'moment';
import useMounted from '../../../hooks/useMounted';

const CustomReportDetails = () => {
  const theme = useTheme();
  const { customReportId } = useParams();
  const { settings } = useSettings();
  const [report, setReport] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [dialogText, setDialogText] = useState('');
  const [dialogAction, setDialogAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState('apprenants');
  const [details, setDetails] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filters, setFilters] = useState([]);
  const navigate = useNavigate();
  const mounted = useMounted();

  const handleDeleteReport = async () => {
    try {
      setIsLoading(true);
      await reportsApi.deleteCustomReportById(customReportId, localStorage.getItem('accessToken'));
      toast.success('Rapport supprimé avec succès !');
      setIsLoading(false);
      setOpenDialogDelete(false);
      navigate('/dashboard/custom-reports', { replace: true });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      toast.error('Une erreur est survenue lors de la suppression !');
    }
  };

  const [amounts, setAmounts] = useState([]);
  const [barData, setBarData] = useState([]);
  const [linesData, setLinesData] = useState([]);
  const [timelinesData, setTimelinesData] = useState([]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(async () => {
    if (mounted.current) {
      const data = await reportsApi.getCustomReportById(customReportId, localStorage.getItem('accessToken'));
      // console.clear();
      console.log(data);

      // chargement des données du rapport
      setReport(data);
      setCustomers(data.query);
    }
  }, [mounted]);

  // mis-à-jour automatique des données passées aux composants enfants de CustomReportDetails à chaque changement sur report
  useEffect(async () => {
    // preparation des données pour CustomReportDetailsTab
    const reportDetails = [
      {
        label: 'Type de rapport',
        value: report.type
      },
      {
        label: 'Nombre d\'apprennants',
        value: report.query ? report.query.length : 0
      },
      {
        label: 'Date de création',
        value: moment(report.created_at).format('DD/MM/YYYY')
      },
      {
        label: 'Rapport créé par',
        value: `${report.u_firstname} ${report.u_lastname}`
      },
      // {
      //   label: 'Critères de sélections',
      //   value: report.criteres || '---------------'
      // }
    ];
    setDetails(reportDetails);

    console.log('criteres => ', report.criteres && JSON.parse(report.criteres));

    // preparation des données CustomerReportDetailsTap
    const _amounts = [
      { label: 'Label 1', amount: 1000 },
      { label: 'Label 22', amount: 1000 },
      { label: 'Label 3', amount: 1000 },
      { label: 'Label 4', amount: 1000 },
    ];
    setAmounts(_amounts);

    const _barData = [
      {
        name: 'New Customers',
        data: [31, 40, 28, 51, 42, 109, 100, 120, 80, 42, 90, 140]
      },
      {
        name: 'Up/Cross-Selling',
        data: [11, 32, 45, 32, 34, 52, 41, 80, 96, 140, 30, 100]
      },
      {
        name: 'New ....',
        data: [11, 32, 45, 32, 34, 109, 100, 120, 80, 42, 90, 140]
      }
    ];
    setBarData(_barData);

    const _linesData = [
      {
        name: 'New Customers',
        data: [31, 40, 28, 51, 42, 109, 100, 120, 80, 42, 90, 140]
      },
      {
        name: 'Up/Cross-Selling',
        data: [11, 32, 45, 32, 34, 52, 41, 80, 96, 140, 30, 100]
      },
      {
        name: 'New ....',
        data: [11, 32, 45, 32, 34, 109, 100, 120, 80, 42, 90, 140]
      }
    ];
    setLinesData(_linesData);

    const _timelinesData = {
      series: [
        {
          color: '#4CAF50',
          data: [
            3350,
            1840,
            2254,
            5780,
            9349,
            5241,
            2770,
            2051,
            3764,
            2385,
            5912,
            8323
          ],
          name: 'Organic'
        },
        {
          color: '#FF9800',
          data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35],
          name: 'Referral'
        },
        {
          color: '#F44336',
          data: [100, 122, 50, 300, 250, 400, 312, 200, 10, 60, 90, 400],
          name: 'Social Media'
        }
      ],
      xaxis: {
        dataPoints: [
          '01 Jan',
          '02 Jan',
          '03 Jan',
          '04 Jan',
          '05 Jan',
          '06 Jan',
          '07 Jan',
          '08 Jan',
          '09 Jan',
          '10 Jan',
          '11 Jan',
          '12 Jan'
        ]
      }
    };
    setTimelinesData(_timelinesData);

    // chargement de la liste des apprenants pour CustomReportDetailsTable
    // setCustomers(report.customers);
  }, [report]);

  const handleChange = (event, tab) => {
    setTabs(tab);
  };

  return (
    <>
      <Helmet>
        <title>
          {`${report && report.title} | Apteed`}
        </title>
      </Helmet>
      <Dialog
        open={openDialogDelete}
        onClose={() => setOpenDialogDelete(false)}
      >
        <DialogContent>
          <DialogContentText>
            { dialogText }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteReport()}
            color="primary"
            disabled={isLoading}
          >
            Supprimer
          </Button>
          <Button
            onClick={() => setOpenDialogDelete(false)}
            color="primary"
          >
            Annuler
          </Button>
          {isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </Box>
          )}
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h6"
              >
                { report && report.title }
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                {/* <Button
                  color="primary"
                  size="small"
                  // component={RouterLink}
                  startIcon={<FileCopyIcon fontSize="small" />}
                  sx={{ m: 1 }}
                  variant="outlined"
                >
                  Exporter
                </Button>
                <Button
                  color="primary"
                  size="small"
                  component={RouterLink}
                  startIcon={<FileDownloadOutlined fontSize="small" />}
                  to={`/dashboard/custom-reports/download/${report.id}`}
                  sx={{ m: 1 }}
                  variant="outlined"
                >
                  Télécharger
                </Button>
                <Button
                  color="primary"
                  size="small"
                  component={RouterLink}
                  startIcon={<PencilAlt fontSize="small" />}
                  to={`/dashboard/custom-reports/edit/${report.id}`}
                  sx={{ m: 1 }}
                  variant="outlined"
                >
                  Editer
                </Button> */}
                <Button
                  color="secondary"
                  size="small"
                  // component={RouterLink}
                  startIcon={<Trash fontSize="small" />}
                  onClick={() => {
                    setDialogText(`Vous êtes sur le point de supprimer le rapport ${report.title}. Etes-vous sûr de vouloir poursuivre ?`);
                    setOpenDialogDelete(true);
                  }}
                  sx={{ m: 1 }}
                  variant="outlined"
                >
                  Supprimer ce rapport
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            sx={{ marginTop: '0px' }}
          >
            <Box
              sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                width: '100%',
                p: 3
              }}
            >
              {/* <CustomRepportDetailsOptionsBar setFilters={setFilters} /> */}
              <Divider />
            </Box>
          </Grid>

          <Box sx={{ width: '100%' }}>
            <TabContext value={tabs}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="Sous menus"
                >
                  <Tab
                    label="Apprenants"
                    value="apprenants"
                  />
                  {/* <Tab
                    label="Statistiques"
                    value="stats"
                  /> */}
                  <Tab
                    label="Détails"
                    value="details"
                  />
                </TabList>
              </Box>
              <TabPanel
                value="apprenants"
                sx={{ p: 0 }}
              >
                <CustomReportDetailsTable customers={customers} />
              </TabPanel>

              <TabPanel
                value="stats"
                sx={{ p: 0 }}
              >
                <CustomReportDetailsStats
                  amounts={amounts}
                  barData={barData}
                  linesData={linesData}
                  timelinesData={timelinesData}
                />
              </TabPanel>

              <TabPanel
                value="details"
                sx={{ p: 0 }}
              >
                <CustomReportDetailsTab
                  details={details}
                  description={report.description}
                  criteres={report.criteres && JSON.parse(report.criteres)}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomReportDetails;
