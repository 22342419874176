/* eslint-disable no-unused-expressions */
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, frFR, GridRenderCellParams } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, Card, Avatar, Box, IconButton, Collapse, TableCell, Tooltip, TableBody, tableCellClasses} from '@material-ui/core';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Table, TableRow } from '@mui/material';
import { format } from 'date-fns';

const FormationResultTablePerformance = ({
  formations,
  currentPage,
  setCurrentPage,
  loading
}) => {
  const rows = [];
  const { user } = useAuth();
  const theme = useTheme();
  const [clickedIndex, setClickedIndex] = useState(-1);
  const CustomToolbar = () => (user.id_service === 0
    ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
    : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    ));

  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    return color;
  }
  function stringAvatar(name) {
    return {
      sx: { bgcolor: stringToColor(name) },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    };
  }
  formations.filter((el) => el.status !== 'Supprimée').forEach((item) => {
    rows.push({ 
      id: item.course_id,
      name: item.name,
      year: item.year,
      image: item.image,
      learning_progress: item.learning_progress,
      posttest_scores: item.posttest_scores,
      pretest_scores: item.pretest_scores,
      completed_learner: item.completed_learner,
      profession: item.profession,
      // performance: (Number(item.global_average) / 20).toFixed(1), sessions: item.performance_sessions.map((row) => ({completed_learner: row.completed_learner, course_session: row.course_session,  endDate: row.endDate, learning_progress: row.learning_progress, number_session: row.number_session, posttest_scores: row.posttest_scores, pretest_scores: row.pretest_scores, startDate: row.startDate} )),
      session:item.performance_sessions,
    });
  });

  const columns = [
    {field: "",  width: 10, headerName: "",sx: {alignItems: 'start',display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap'},
      renderCell: (cellValues) => {
        return (<Box>
        {cellValues.row.session.length > 0 && <Tooltip title="Voir les sessions">
        <IconButton style={{margin: '0px', padding: '0px'}} onClick={() => {clickedIndex === cellValues.id ? setClickedIndex(-1) : setClickedIndex(cellValues.id);}}>
          {cellValues.id === clickedIndex   ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }</IconButton>
          </Tooltip>}
          {cellValues.id === clickedIndex && <Box> 
       <Collapse in={cellValues.id === clickedIndex}>
        <Box>
      {cellValues.row.session.map((row) => (
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
         <TableCell align="right"> <Box sx={{ alignItems: 'center', display: 'flex',paddingLeft: '60px'}}>
          <Box sx={{alignItems: 'center', display: 'flex', }} key={row.course_session}></Box>
          </Box> </TableCell>
      <TableCell align="right" sx={{ whiteSpace: 'nowrap', opacity: '0'}}> ( A ) </TableCell>
      </TableRow>
      ))}
        </Box></Collapse></Box>}
          </Box>
          )
      },
     
    },
   

    {field: 'name', headerName: 'Nom', flex: 2, sx: {alignItems: 'center',display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap'},
      renderCell: (cellValues) => ( <Box>
        <Box sx={{ alignItems: 'center', display: 'flex',minHeight: 50, maxHeight: 50 ,  }}>
         <Avatar src={cellValues.row.image} sx={{ height: 50, width: 50,}}{...stringAvatar(`${cellValues.row.name.replace(/ /g, '')} ${cellValues.row.name.replace(/ /g, '')}`)} />
          <Box sx={{ ml: 1 }}>
          <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13, }} component={RouterLink} to={{
  pathname: `/dashboard/formations/${cellValues.row.id}`,
  search: "?origin=performances"
}}>{cellValues.row.name}</Link>
            {/* <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13, }} component={RouterLink} to={{pathname: `/dashboard/formations/${cellValues.row.id}?origin=performances`}}  variant="subtitle2">
              {cellValues.row.name}
            </Link> */}
          </Box>
        </Box> 
        {cellValues.id === clickedIndex && <Box> 
         <Collapse in={cellValues.id === clickedIndex}>
          <Box>
        {cellValues.row.session.map((row) => (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
           <TableCell align="right"> <Box sx={{ alignItems: 'center', display: 'flex',paddingLeft: '120px'}}>
         
          <Box sx={{alignItems: 'center', display: 'flex', }} key={row.sessionId}>
          <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13, }} component={RouterLink} to={{
  pathname: `/dashboard/session/${row.course_session}`,
  search: "?origin=performances"
}}> Session N° {row.number_session}</Link>
          {/* <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13 }} component={RouterLink} to={{pathname: `/dashboard/session/${row.course_session}?origin=performances`}}  variant="subtitle2">
          Session N° {row.number_session}
        </Link> */}
        </Box>
        </Box>
        </TableCell>
        <TableCell align="right"> ( Du  {format(new Date(row.startDate), "dd/MM/yyyy")} au {format(new Date(row.endDate), "dd/MM/yyyy")} )</TableCell>
          </TableRow>
        ))}
          </Box></Collapse></Box>}
 </Box>
       
    )},
    {field: 'year', headerName: "Année", width: 75, renderCell: (cellValues) => (
      <Box>
       <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
      <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.year}</Typography> </TableCell></TableRow>
      {cellValues.id === clickedIndex && <Box> 
         <Collapse in={cellValues.id === clickedIndex}>
          <Box>
        {cellValues.row.session.map((row) => (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
           <TableCell align="right"> <Box sx={{ alignItems: 'center', display: 'flex',paddingLeft: '60px'}}>
          <Box sx={{alignItems: 'center', display: 'flex', }} key={row.course_session}> </Box>
        </Box> </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap', opacity: '0'}}> ( A ) </TableCell></TableRow>
        ))}
          </Box></Collapse></Box>}
      </Box>
      )},
  
    {field: 'completed_learner', headerName: "Apprenants terminés", flex: 0.4,renderCell: (cellValues) => (
      <Box>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.completed_learner}</Typography> </TableCell></TableRow>
       { cellValues.id === clickedIndex && <Collapse style={{marginTop: '0px'}} in={cellValues.id === clickedIndex}>
        <Box>
        {cellValues.row.session.map((row) => (
           <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
           <TableCell key={row.course_session}>{row.completed_learner}</TableCell>
           </TableRow>))}
           </Box></Collapse>}
      </Box>)},

     {field: 'pretest_scores', headerName: 'Score pré-test', flex: 0.4,renderCell: (cellValues) => (
     <Box>
   <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.pretest_scores}%</Typography> </TableCell></TableRow>
      { cellValues.id === clickedIndex && <Collapse style={{marginTop: '0px'}} in={cellValues.id === clickedIndex}>
       <Box>
        {cellValues.row.session.map((row) => (
          <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
          <TableCell key={row.course_session}>{row.pretest_scores}%</TableCell>
          </TableRow>))}
          </Box></Collapse>}
     </Box>)},

    {field: 'posttest_scores', headerName: 'Score Post-test', flex: 0.4,renderCell: (cellValues) => (
     <Box>
     <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.posttest_scores}%</Typography> </TableCell></TableRow>
      { cellValues.id === clickedIndex && <Collapse style={{marginTop: '0px'}} in={cellValues.id === clickedIndex}>
       <Box>
        {cellValues.row.session.map((row) => (
          <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
          <TableCell key={row.course_session}> {row.posttest_scores}% </TableCell>
          </TableRow>))}
          </Box></Collapse>}
     </Box>)},

    {field: 'learning_progress', headerName: 'Progression globale', flex: 0.4,renderCell: (cellValues) => (
     <Box>
       <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.learning_progress}%</Typography> </TableCell></TableRow>
      { cellValues.id === clickedIndex && <Collapse style={{marginTop: '0px'}} in={cellValues.id === clickedIndex}>
       <Box>
        {cellValues.row.session.map((row) => (
          <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
          <TableCell key={row.course_session}>{row.learning_progress}%</TableCell>
          </TableRow>))}
          </Box></Collapse>}
     </Box>)},  ];

  return (
    <Card>
      <Box sx={{ height: 740, width: '100%' }}>
              <DataGrid        
                sx={{ background: '', overflowY: 'auto', 
            '& .MuiDataGrid-columnHeaderTitle': {  whiteSpace: 'pre-wrap', lineHeight: 1, margin: 'auto', display: 'flex',},
              // '& .MuiDataGrid-columnHeader': { whiteSpace: 'break-spaces', lineHeight: 1,},
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': { pl: 1,},
            "& .MuiDataGrid-renderingZone": {
              //  maxHeight: "none !important",
                minHeight: 85 },
            "& .MuiDataGrid-cell": {
              // lineHeight: "unset !important",
              maxHeight: "none !important", whiteSpace: "normal", minHeight: 85},
            "& .MuiDataGrid-row": { maxHeight: "none !important", minHeight: 85}
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{ sorting: {sortModel: [{ field: 'name', sort: 'asc' }],},
            pagination: {paginationModel: {pageSize: 5,},},
          }}
          rows={rows}
          columns={columns}
          page={currentPage}
          pageSize={10}
          rowsPerPageOptions={[30, 100]}
          components={{ Toolbar: CustomToolbar }}
          onPageChange={(newPage) => { setCurrentPage(newPage); }}
          loading={loading}
        /></Box>
    </Card>
  );
};
FormationResultTablePerformance.propTypes = {
  formations: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default FormationResultTablePerformance;
