/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import ArrowRightIcon from '../../../icons/ArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { customerApi } from '../../../api/customerApi';
import useAuth from '../../../hooks/useAuth';
import { withStyles } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/styles';

const tabs = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Médecins',
    value: 'Médecin'
  },
  {
    label: 'Infirmiers',
    value: 'Infirmier'
  },
  {
    label: 'Kinés',
    value: 'Masseur-kinésithérapeute'
  },
  {
    label: 'Podologues',
    value: 'Pédicure-podologue'
  },
  {
    label: 'Pharmaciens',
    value: 'Pharmacien'
  },
  {
    label: 'Sages-Femmes',
    value: 'Sage-Femme'
  }
];

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const sortOptions = [
  {
    label: 'Nom (A - Z)',
    value: 'lastname|asc'
  },
  {
    label: 'Nom (Z - A)',
    value: 'lastname|desc'
  },
  {
    label: 'Code postal (plus petit)',
    value: 'zip|asc'
  },
  {
    label: 'Code postal (plus grand)',
    value: 'zip|desc'
  }
];

const sourceOptions = [
  {
    label: 'Toutes',
    value: 'all'
  },
  {
    label: 'Apteed',
    value: 0
  },
  {
    label: 'Nouveaux prospects',
    value: 998
  },
  {
    label: 'Prospects qualifiers',
    value: 999
  }
];

const applyFilters = (customers, query, filters, source, campaign, callTrack, newCallTrack, callTrackRelance, avancement, relance, finSessions,
  customersEndSessions, isRelance, modeRelance) => customers
  .filter((customer) => {
    const matches = true;

    return matches;
  });

const applyPagination = (customers, page, limit) => customers;

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const handleDeleteCustomer = async (customerId) => {
  try {
    await customerApi.deleteCustomer(customerId, localStorage.getItem('accessToken'));
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
};

const CustomerNewProspectsListTable = (props) => {
  const { customers, usersCampaign, isRelance, usersRelance, endSessions, customersEndSessions, choiceTabProfession,
    choiceSort, choiceSource, choiceCampaign, choiceCallTrack, choiceNewCallTrack, choiceCallTrackRelance, choiceAvancement, choiceEndSession, query, setQuery, setCustomers,
    numberCustomers, setNumberCustomers, loading, loadingFilter, page, setPage, limit, setLimit, relance, setRelance, ...other } = props;
  const { user } = useAuth();
  const [currentTab, setCurrentTab] = useState('all');
  let tempChoiceSource = window.localStorage.getItem('choiceSource');
  tempChoiceSource = tempChoiceSource === null ? 'all' : window.localStorage.getItem('choiceSource').replaceAll('"', '');
  const [currentSource, setCurrentSource] = useState(tempChoiceSource);
  let tempChoiceCampaign = window.localStorage.getItem('choiceCampaign');
  tempChoiceCampaign = tempChoiceCampaign === null ? 'all' : window.localStorage.getItem('choiceCampaign').replaceAll('"', '');
  const [currentCampaign, setCurrentCampaign] = useState(tempChoiceCampaign);
  let tempChoiceCallTrack = window.localStorage.getItem('choiceCallTrack');
  tempChoiceCallTrack = tempChoiceCallTrack === null ? 'all' : window.localStorage.getItem('choiceCallTrack').replaceAll('"', '');
  const [currentCallTrack, setCurrentCallTrack] = useState(tempChoiceCallTrack);
  let tempChoiceNewCallTrack = window.localStorage.getItem('choiceNewCallTrack');
  tempChoiceNewCallTrack = tempChoiceNewCallTrack === null ? 'all' : window.localStorage.getItem('choiceNewCallTrack').replaceAll('"', '');
  const [currentNewCallTrack, setCurrentNewCallTrack] = useState(tempChoiceNewCallTrack);
  let tempChoiceCallTrackRelance = window.localStorage.getItem('choiceCallTrackRelance');
  tempChoiceCallTrackRelance = tempChoiceCallTrackRelance === null ? 'all' : window.localStorage.getItem('choiceCallTrackRelance').replaceAll('"', '');
  const [currentCallTrackRelance, setCurrentCallTrackRelance] = useState(tempChoiceCallTrackRelance);
  const tempChoiceEndSession = window.localStorage.getItem('choiceEndSession');
  const [currentEndSessions, setCurrentEndSessions] = useState(tempChoiceEndSession === null ? 'all' : window.localStorage.getItem('choiceEndSession').replaceAll('"', ''));
  const [currentRelance, setCurrentRelance] = useState('all');
  let tempChoiceAvancement = window.localStorage.getItem('choiceAvancement');
  tempChoiceAvancement = tempChoiceAvancement === null ? 'all' : window.localStorage.getItem('choiceAvancement').replaceAll('"', '');
  const [currentAvancement, setCurrentAvancement] = useState(tempChoiceAvancement);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [customerDelete, setCustomerDelete] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [finSessions, setFinSessions] = useState({ '2022-02-28': null });

  const [source, setSource] = useState({
    0: null,
    998: null,
    999: null
  });

  const [avancement, setAvancement] = useState({
    'Pas commencé': null,
    'En cours': null,
    Terminé: null
  });

  function initCampaign() {
    const camp = {};
    usersCampaign.forEach((element) => {
      camp[element.id] = null;
    });
    camp.empty = null;
    return camp;
  }

  const [campaign, setCampaign] = useState(initCampaign);

  const [sort, setSort] = useState(window.localStorage.getItem('choiceSort') === null ? 'lastname|asc' : window.localStorage.getItem('choiceSort').replaceAll('"', ''));
  const [callTrack, setCallTrack] = useState({
    empty: null,
    'Pas répondu': null,
    'À rappeler': null,
    'Numéro incorrect': null,
    Vente: null,
    'Non - Déjà fait': null,
    'Non - Pas intéressé': null,
    Attente: null
  });
  const [newCallTrack, setNewCallTrack] = useState({
    empty: null,
    'Vente 1 formation': null,
    'Vente 2 formation': null,
    'Vente 3 formation': null,
    'Déjà validé': null,
    'Souhaite du présentiel': null,
    'Aucun thème voulu': null,
    'Date de session trop proche': null,
    'Préfère un autre organisme': null,
    Retraite: null,
    'Numéro incorrect': null,
    'Non professionnel de santé': null,
    'Souhaite un email': null,
    Rappel: null,
    Attente: null,
    'Appel entrant': null,
    'Pas répondu': null
  });
  const [callTrackRelance, setCallTrackRelance] = useState({
    empty: null,
    'Pas répondu': null,
    'À rappeler': null,
    'Numéro incorrect': null,
    'Va se connecter': null,
    Annulation: null,
    'Suivi - Téléphone': null,
    'Suivi - Mail': null,
    'Suivi - SMS': null,
    Décalage: null,
  });
  const [filters, setFilters] = useState({
    Médecin: null,
    Infirmier: null,
    'Masseur-kinésithérapeute': null,
    'Pédicure-podologue': null,
    Pharmacien: null,
    'Sage-Femme': null
  });

  const usersCampaignList = [...usersCampaign];
  usersCampaignList.unshift({ firstname: 'Tous', lastname: '', id: 'all' });
  usersCampaignList.push({ firstname: 'Non ', lastname: 'assignés', id: 0 });

  const usersRelanceList = [...usersRelance];
  usersRelanceList.unshift({ firstname: 'Tous', lastname: '', id: 'all' });
  usersRelanceList.push({ firstname: 'Non ', lastname: 'assignés', id: 0 });

  const location = useLocation();

  const handleCustomers = (async (querySearch, sortChange, sourceChange, campaignChange, callTrackChange, newCallTrackChange, callTrackRelanceChange,
    avancementChange, finSessionsChange, relanceChange, tabProfession, limitChoice, pageChoice, modeRelanceChange) => {
    try {
      // si le spinner de la page n'est pas actif, on déclenche le spinner de la table des apprenants au chargement des données
      if (!loading) {
        setIsLoading(true);
      }
      let data = [];
      let tempChoiceProfession = window.localStorage.getItem('choiceTabProfession');
      tempChoiceProfession = tempChoiceProfession === null ? 'all' : window.localStorage.getItem('choiceTabProfession').replaceAll('"', '');
      if (location.pathname === '/dashboard/prospects') {
        data = await customerApi.getProspect(localStorage.getItem('accessToken'), querySearch, sortChange, sourceChange, campaignChange, callTrackChange,
          newCallTrackChange, callTrackRelanceChange, avancementChange, finSessionsChange, relanceChange, tabProfession, limitChoice, pageChoice, modeRelanceChange);
      } else if (location.pathname === '/dashboard/campagne') {
        data = await customerApi.getCustomersCampagne(window.localStorage.getItem('accessToken'), querySearch, sortChange, sourceChange, campaignChange, callTrackChange,
          newCallTrackChange, callTrackRelanceChange, avancementChange, finSessionsChange, relanceChange, tabProfession, limitChoice, pageChoice, modeRelanceChange);
      }
      setCustomers(data.customers);
      setNumberCustomers(data.numberCustomers);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    // Réinitialisation des sélections
    setSelectedCustomers([]);

    // Réinitialisation du filtre des sources
    const updatedSource = {
      ...source,
      0: null,
      998: null,
      999: null
    };
    window.localStorage.setItem('choiceSource', JSON.stringify('all'));
    !loading ? setCurrentSource('all') : '';

    // Réinitialisation du filtre statut d'avancement
    const updatedAvancement = {
      ...avancement,
      'Pas commencé': null,
      'En cours': null,
      Terminé: null
    };
    window.localStorage.setItem('choiceAvancement', JSON.stringify('all'));
    !loading ? setCurrentAvancement('all') : '';

    // Réinitialisation du filtre statut d'appel
    const updatedCallTrack = {
      ...callTrack,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      Vente: null,
      'Non - Déjà fait': null,
      'Non - Pas intéressé': null,
      Attente: null
    };
    window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
    !loading ? setCurrentCallTrack('all') : '';

    // Réinitialisation du filtre nouveau statut d'appel
    const updatedNewCallTrack = {
      ...newCallTrack,
      empty: null,
      'Vente 1 formation': null,
      'Vente 2 formation': null,
      'Vente 3 formation': null,
      'Déjà validé': null,
      'Souhaite du présentiel': null,
      'Aucun thème voulu': null,
      'Date de session trop proche': null,
      'Préfère un autre organisme': null,
      Retraite: null,
      'Numéro incorrect': null,
      'Non professionnel de santé': null,
      'Souhaite un email': null,
      Rappel: null,
      Attente: null,
      'Appel entrant': null,
      'Pas répondu': null
    };
    window.localStorage.setItem('choiceNewCallTrack', JSON.stringify('all'));
    !loading ? setCurrentNewCallTrack('all') : '';

    // Réinitialisation du filtre statut d'appel relance
    const updatedCallTrackRelance = {
      ...callTrackRelance,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      'Va se connecter': null,
      Annulation: null,
      'Suivi - Téléphone': null,
      'Suivi - Mail': null,
      'Suivi - SMS': null,
      Décalage: null,
    };
    window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all'));
    !loading ? setCurrentCallTrackRelance('all') : '';

    // Réinitialisation du filtre des utilisateurs commerciaux de campagnes
    const tempCampaign = {};
    usersCampaign.forEach((element) => {
      tempCampaign[element.id] = null;
    });
    const updatedCampaign = {
      ...campaign,
      ...tempCampaign,
      0: null
    };
    window.localStorage.setItem('choiceCampaign', JSON.stringify('all'));
    !loading ? setCurrentCampaign('all') : '';

    // Réinitialisation du filtre des utilisateurs à la relance
    const tempRelance = {};
    usersRelance.forEach((element) => {
      tempRelance[element.id] = null;
    });
    const updatedRelance = {
      ...relance,
      ...tempRelance,
      0: null
    };
    !loading ? setCurrentRelance('all') : '';

    // réinitialisation du filtre fin de session à la relance
    setCurrentEndSessions('all');
    window.localStorage.setItem('choiceEndDate', JSON.stringify('all'));

    // requête en bdd par rapport mode commercial/relance avec réinitialisation des valeurs des filtres
    // !loading ? handleCustomers(query, 'minSession|asc', 'all', 'all', 'all', 'all', 'all', 'all', 'all', 'all', filters, limit, page) : '';
  }, []);

  const handleSourceChange = (event, value) => {
    const updatedSource = {
      ...source,
      0: null,
      998: null,
      999: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedSource[value] = true;
      }
      setSource(updatedSource);
      setSelectedCustomers([]);
      setCurrentSource(value);
    } else {
      if (event.target.value !== 'all') {
        updatedSource[event.target.value] = true;
      }
      setSource(event.target.value);
      setSelectedCustomers([]);
      setCurrentSource(event.target.value);
      window.localStorage.setItem('choiceSource', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, event.target.value, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0);
  };

  const handleEndSessionsChange = (event) => {
    const updatedEndSessions = {
      ...finSessions,
      ...endSessions.map((es) => es.end_date).reduce((ac, a) => ({ ...ac, [a]: null }), {})
    };

    if (event.target.value !== 'all') {
      updatedEndSessions[event.target.value] = true;
    }

    setFinSessions(event.target.value);
    setSelectedCustomers([]);
    setCurrentEndSessions(event.target.value);
    window.localStorage.setItem('choiceEndSession', JSON.stringify(event.target.value));
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, event.target.value, relance, filters, limit, 0);
  };

  const handleAvancementChange = (event, value) => {
    const updatedAvancement = {
      ...avancement,
      'Pas commencé': null,
      'En cours': null,
      Terminé: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedAvancement[value] = true;
      }

      setAvancement(updatedAvancement);
      setSelectedCustomers([]);
      setCurrentAvancement(value);
    } else {
      if (event.target.value !== 'all') {
        updatedAvancement[event.target.value] = true;
      }

      setAvancement(event.target.value);
      setSelectedCustomers([]);
      setCurrentAvancement(event.target.value);
      window.localStorage.setItem('choiceAvancement', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, event.target.value, currentEndSessions, relance, filters, limit, 0);
  };

  const handleCallTrackChange = (event, value) => {
    const updatedCallTrack = {
      ...callTrack,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      Vente: null,
      'Non - Déjà fait': null,
      'Non - Pas intéressé': null,
      Attente: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCallTrack[value] = true;
      }

      setCallTrack(updatedCallTrack);
      setSelectedCustomers([]);
      setCurrentCallTrack(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCallTrack[event.target.value] = true;
      }

      setCallTrack(event.target.value);
      setSelectedCustomers([]);
      setCurrentCallTrack(event.target.value);
      window.localStorage.setItem('choiceCallTrack', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, event.target.value, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0);
  };

  // nouveau système de statut d'appel
  const handleNewCallTrackChange = (event, value) => {
    const updatedNewCallTrack = {
      ...newCallTrack,
      empty: null,
      'Vente 1 formation': null,
      'Vente 2 formation': null,
      'Vente 3 formation': null,
      'Déjà validé': null,
      'Souhaite du présentiel': null,
      'Aucun thème voulu': null,
      'Date de session trop proche': null,
      'Préfère un autre organisme': null,
      Retraite: null,
      'Numéro incorrect': null,
      'Non professionnel de santé': null,
      'Souhaite un email': null,
      Rappel: null,
      Attente: null,
      'Appel entrant': null,
      'Pas répondu': null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedNewCallTrack[value] = true;
      }

      setNewCallTrack(updatedNewCallTrack);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(value);
    } else {
      if (event.target.value !== 'all') {
        updatedNewCallTrack[event.target.value] = true;
      }

      setNewCallTrack(event.target.value);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(event.target.value);
      window.localStorage.setItem('choiceNewCallTrack', JSON.stringify(event.target.value));
    }
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, event.target.value, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0);
    setPage(0);
  };

  const handleCallTrackRelanceChange = (event, value) => {
    const updatedCallTrackRelance = {
      ...callTrackRelance,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      'Va se connecter': null,
      Annulation: null,
      'Suivi - Téléphone': null,
      'Suivi - Mail': null,
      'Suivi - SMS': null,
      Décalage: null,
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCallTrackRelance[value] = true;
      }

      setCallTrackRelance(updatedCallTrackRelance);
      setSelectedCustomers([]);
      setCurrentCallTrackRelance(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCallTrackRelance[event.target.value] = true;
      }

      setCallTrackRelance(event.target.value);
      setSelectedCustomers([]);
      setCurrentCallTrackRelance(event.target.value);
      window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, event.target.value, avancement, currentEndSessions, relance, filters, limit, 0);
  };

  const handleCampaignChange = (event, value) => {
    const tempCampaign = {};
    usersCampaign.forEach((element) => {
      tempCampaign[element.id] = null;
    });
    const updatedCampaign = {
      ...campaign,
      ...tempCampaign,
      0: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCampaign[value] = true;
      }

      setCampaign(updatedCampaign);
      setSelectedCustomers([]);
      setCurrentCampaign(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCampaign[event.target.value] = true;
      }
      setCampaign(event.target.value);
      setSelectedCustomers([]);
      setCurrentCampaign(event.target.value);
      window.localStorage.setItem('choiceCampaign', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, event.target.value, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0);
  };

  const handleRelanceChange = (event) => {
    const tempRelance = {};
    usersRelance.forEach((element) => {
      tempRelance[element.id] = null;
    });
    const updatedRelance = {
      ...relance,
      ...tempRelance,
      0: null
    };

    if (event.target.value !== 'all') {
      updatedRelance[event.target.value] = true;
    }

    setRelance(event.target.value);
    setSelectedCustomers([]);
    setCurrentRelance(event.target.value);
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, event.target.value, filters, limit, 0);
  };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      Médecin: null,
      Infirmier: null,
      'Masseur-kinésithérapeute': null,
      'Pédicure-podologue': null,
      Pharmacien: null,
      'Sage-Femme': null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }
    setFilters(value);
    setSelectedCustomers([]);
    setCurrentTab(value);
    window.localStorage.setItem('choiceTabProfession', JSON.stringify(value));
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, currentCallTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, value, limit, 0);
  };

  const handleSortChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSort(value);
    } else {
      setSort(event.target.value);
      window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, event.target.value, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0);
  };

  useEffect(() => {
    if (choiceTabProfession !== '' && choiceTabProfession !== 'undefined') {
      handleTabsChange((event) => event, choiceTabProfession);
    }
  }, [choiceTabProfession]);

  useEffect(() => {
    if (choiceSort !== '' && choiceSort !== 'undefined' && typeof choiceSort !== 'string') {
      handleSortChange((event) => event, choiceSort);
    }
    if (choiceSource !== '' && choiceSource !== 'undefined' && typeof choiceSource !== 'string') {
      handleSourceChange((event) => event, choiceSource);
    }
    if (choiceCampaign !== '' && choiceCampaign !== 'undefined' && typeof choiceCampaign !== 'string') {
      handleCampaignChange((event) => event, choiceCampaign);
    }
    if (choiceCallTrack !== '' && choiceCallTrack !== 'undefined' && typeof choiceCallTrack !== 'string') {
      handleCallTrackChange((event) => event, choiceCallTrack);
    }
    if (choiceNewCallTrack !== '' && choiceNewCallTrack !== 'undefined' && typeof choiceNewCallTrack !== 'string') {
      handleNewCallTrackChange((event) => event, choiceNewCallTrack);
    }
    if (choiceCallTrackRelance !== '' && choiceCallTrackRelance !== 'undefined' && typeof choiceCallTrackRelance !== 'string') {
      handleCallTrackRelanceChange((event) => event, choiceCallTrackRelance);
    }
    if (choiceAvancement !== '' && choiceAvancement !== 'undefined' && typeof choiceAvancement !== 'string') {
      handleAvancementChange((event) => event, choiceAvancement);
    }
    if (choiceEndSession !== '' && choiceEndSession !== 'undefined' && typeof choiceEndSession !== 'string') {
      handleEndSessionsChange((event) => event, choiceEndSession);
    }
  }, [choiceSort, choiceSource, choiceCampaign, choiceCallTrack, choiceNewCallTrack, choiceCallTrackRelance, choiceAvancement, choiceEndSession]);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    // pour remettre à la première page de résultats quand on fait une recherche
    setPage(0);
    // pour que la recherche dans le champ "chercher un apprenant" ne se fasse qu'à partir de 3 caractères tapés
    if (event.target.value.length >= 3) {
      handleCustomers(event.target.value, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0);
    }
    // pour que la recherche dans le champ "chercher un apprenant" se réinitialise à 0 caractère
    if (event.target.value.length === 0) {
      handleCustomers('', sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, parseInt(event.target.value, 10), 0);
  };

  const filteredCustomers = applyFilters(customers, query, filters, source, campaign, callTrack, newCallTrack, callTrackRelance,
    relance, finSessions, customersEndSessions, isRelance);
  const sortedCustomers = applySort(filteredCustomers, sort);
  const paginatedCustomers = applyPagination(sortedCustomers, page, limit);
  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers = selectedCustomers.length > 0
    && selectedCustomers.length < paginatedCustomers.length;
  const selectedAllCustomers = selectedCustomers.length === paginatedCustomers.length;

  // si ce n'est pas l'administrateur, on stocke les clients classés pour pouvoir naviguer ensuite avec les flèches gauche droite sur customerdetails
  if (user.access_level !== 0) {
    useEffect(() => {
      window.localStorage.setItem('sortedCustomers', JSON.stringify(sortedCustomers));
    });
  }

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(event.target.checked
      ? paginatedCustomers.map((customer) => customer.id)
      : []);
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedCustomers.includes(customerId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const dialogOpener = (index) => {
    setOpen(true);
    setCustomerDelete({ civility: paginatedCustomers[index].civility, firstname: paginatedCustomers[index].firstname, lastname: paginatedCustomers[index].lastname, id: paginatedCustomers[index].id });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // pour afficher le loader uniquement dans le tableau
  const StyledPaper = withStyles({
    root: {
      position: 'relative'
    }
  })(Paper);
  const LimitedBackdrop = withStyles({
    root: {
      position: 'absolute',
      zIndex: 1
    }
  })(Backdrop);

  return (
    <Card {...other}>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <DialogContentText>
            Souhaitez-vous vraiment supprimer l&apos;
            {customerDelete.civility === 'Madame' ? 'apprenante' : 'apprenant'}
            {' '}
            {customerDelete.firstname}
            {' '}
            {customerDelete.lastname}
            {' ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteCustomer(customerDelete.id)}
            color="primary"
          >
            Supprimer
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Chercher un prospect"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
          }}
        >
          <TextField
            label="Trier par"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      <StyledPaper>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nom et Email
                  </TableCell>
                  <TableCell>
                    Lieu
                  </TableCell>
                  <TableCell>
                    Profession
                  </TableCell>
                  <TableCell align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {paginatedCustomers.map((customer, index) => {
                  const isCustomerSelected = selectedCustomers.includes(customer.id);

                  return (
                    <TableRow
                      hover
                      key={customer.id}
                      selected={isCustomerSelected}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar
                            src={customer.avatar}
                            sx={{
                              height: 42,
                              width: 42
                            }}
                            {...stringAvatar(`${customer.firstname.replace(/ /g, '')} ${customer.lastname.replace(/ /g, '')}`)}
                          />
                          <Box sx={{ ml: 1 }}>
                            <Link
                              color={user.id === customer.first_source_id ? '#b32d00' : 'inherit'}
                              component={RouterLink}
                              to={`/dashboard/customers/${customer.id}`}
                              variant="subtitle2"
                            >
                              {customer.firstname}
                              {' '}
                              {customer.lastname}
                            </Link>
                            <Typography
                              color="textSecondary"
                              variant="body2"
                            >
                              {customer.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {customer.city}
                        {', '}
                        {customer.department}
                        {', '}
                        {customer.region}
                      </TableCell>
                      <TableCell>
                        {customer.profession}
                      </TableCell>

                      <TableCell align="right">
                        <IconButton
                          component={RouterLink}
                          to={`/dashboard/customers/${customer.id}`}
                        >
                          <ArrowRightIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          onClick={() => dialogOpener(index)}
                          disabled={user.access_level !== 0}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <LimitedBackdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                >
                  <CircularProgress color="inherit" />
                </LimitedBackdrop>
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </StyledPaper>
      <TablePagination
        component="div"
        count={numberCustomers}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        // voir si on laisse que l'option 25
        rowsPerPageOptions={[15, 25, 50]}
      />
    </Card>
  );
};

CustomerNewProspectsListTable.propTypes = {
  customers: PropTypes.array.isRequired,
  usersCampaign: PropTypes.array.isRequired,
  isRelance: PropTypes.bool.isRequired,
  usersRelance: PropTypes.array.isRequired,
  endSessions: PropTypes.array,
  customersEndSessions: PropTypes.array,
  choiceTabProfession: PropTypes.string,
  choiceSort: PropTypes.string,
  choiceSource: PropTypes.string,
  choiceCampaign: PropTypes.string,
  choiceCallTrack: PropTypes.string,
  choiceNewCallTrack: PropTypes.string,
  choiceCallTrackRelance: PropTypes.string,
  choiceAvancement: PropTypes.string,
  choiceEndSession: PropTypes.string,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  setCustomers: PropTypes.func,
  numberCustomers: PropTypes.number,
  setNumberCustomers: PropTypes.func,
  loading: PropTypes.bool,
  loadingFilter: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  relance: PropTypes.object,
  setRelance: PropTypes.func

};

export default CustomerNewProspectsListTable;
