/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint linebreak-style: ["error", "windows"] */
/* eslint-disable no-unused-vars, linebreak-style */
/* eslint-disable no-trailing-spaces  */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable  react/jsx-indent-props */
/* eslint-disable   react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-multi-spaces */
/* eslint-disable object-curly-spacing */
class TicketApi {
  async getTicketsByCreator(accessToken, creatorId, type) {
    const urlTickets = `${process.env.REACT_APP_API_URL}/api/quality/tickets/creators`;

    const resp = await fetch(urlTickets, {
      method: 'POST',
      body: JSON.stringify({
        id: creatorId,
        type,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }
  
  async getTicketsByCustomer(accessToken, customerId, type) {
    const urlTickets = `${process.env.REACT_APP_API_URL}/api/quality/tickets/customers/${customerId}/${type}`;

    const resp = await fetch(urlTickets, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getTicketsByServiceId(accessToken, serviceId) {
    const urlTickets = `${process.env.REACT_APP_API_URL}/api/quality/tickets/services/${serviceId}`;

    const resp = await fetch(urlTickets, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getTicketsByServiceName(accessToken, tab, type) {
    const urlTickets = `${process.env.REACT_APP_API_URL}/api/quality/tickets/services/name`;

    const resp = await fetch(urlTickets, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        tab,
        type
      })
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async addLearnerTicket(accessToken, ticketService,
     ticketCategory, ticketSeverity, ticketReferentId, 
     ticketConstat,ticketCause, ticketAcceptTracking,
      customer, userId, ticketReference, ticketState,
       ticketComment, type,
     ticketStartDate,ticketApproxDate,ticketEffectiveDate, ticketActionArray) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/customers/${customer}/tickets/create`;
    const values = {};
    values.ticketService = ticketService;
    values.ticketCategory = ticketCategory;
    values.ticketSeverity = ticketSeverity;
    values.ticketReferentId = ticketReferentId;
    values.ticketDescription  = ticketConstat;
    values.ticketCause = ticketCause;
    values.ticketAcceptTracking = ticketAcceptTracking;
   
    values.customer = customer;
    values.userId = userId;
    values.ticketReference = ticketReference;
    values.ticketState = ticketState;
    values.ticketComment = ticketComment;
    values.type = type;
    values.ticketStartDate = ticketStartDate;
    values.ticketApproxDate = ticketApproxDate;
    values.ticketEffectiveDate = ticketEffectiveDate;
    values.correctiveActions = ticketActionArray;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }

    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getLastTicketId(accessToken, customer) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/customers/${customer}/tickets/ladst_ID/get`;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(customer),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getServices(accessToken, customer) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/customers/${customer}/tickets/services/get`;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(customer),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCategories(accessToken, customer, serviceId, type) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/customers/${customer}/tickets/categories/get`;
    const values = {};
    values.customer = customer;
    values.serviceId = serviceId;
    values.type = type;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getServiceMembers(accessToken, customer, serviceId) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/customers/${customer}/tickets/members/get`;
    const values = {};
    values.customer = customer;
    values.serviceId = serviceId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async createMajorIncident(accessToken, serviceId, description, startDate, solution, tickets, files) {
    const ticketsId = tickets.map((ticket) => ticket.id)
    const urlCreateMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/create`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        description,
        service_id: serviceId,
        start_date: startDate,
        solution,
        tickets : ticketsId
      }),
    };

    const response = await fetch(urlCreateMajorIncident, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  async updateMajorIncident(accessToken, majorIncidentId, userId, field, value) {
    const urlUpdateMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/update`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        id: majorIncidentId,
        user_id: userId,
        [field]: value,
      }),
    };

    const response = await fetch(urlUpdateMajorIncident, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  async addTicketMajorIncident(accessToken, ticketId, majorIncidentId, userId) {
    const urlAddTicket = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets/add`;

    // console.log(majorIncidentId)
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        ticket_id: ticketId,
        major_incident_id: majorIncidentId,
        user_id: userId
      }),
    };

    const response = await fetch(urlAddTicket, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  async deleteTicketMajorIncident(accessToken, ticketId, majorIncidentId, userId) {
    const urlDeleteTicket = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets/remove`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        ticket_id: ticketId,
        major_incident_id: majorIncidentId,
        user_id: userId
      }),
    };

    const response = await fetch(urlDeleteTicket, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  async searchTicketsMajorIncident(accessToken, searchParams) {
    const urlSearchTickets = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/search-tickets`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(searchParams),
    };

    const response = await fetch(urlSearchTickets, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite : ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  async getMajorIncidentsService(accessToken, serviceName) {
    const urlGetMajorIncidents = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        service_name: serviceName
      }),
    };

    const response = await fetch(urlGetMajorIncidents, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite : ${response.status}`;
      return new Error(message);
    }
    const json = await response.json();

    return Promise.resolve(json.data);
  }

  async getMajorIncidentById(accessToken, majorIncidentId) {
    const urlGetMajorIncidentById = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/details`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        major_incident_id: majorIncidentId
      }),
    };

    const response = await fetch(urlGetMajorIncidentById, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite : ${response.status}`;
      return new Error(message);
    }
    const json = await response.json();

    return Promise.resolve(json);
  }

  async getServiceByName(accessToken, serviceName) {
    const urlServices = `${process.env.REACT_APP_API_URL}/api/services/name`;
    const resp = await fetch(urlServices, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        serviceName
      })
    });
    // console.log(serviceName);
    if (!resp.ok) {
      const message = `Une erreur s'est produite : ${resp.status}`;
      return new Error(message);
    }

    return resp.json();
  }
   // /////////////////////// rubrique qualité ////////////////////////////
  
   async addMemberTicket(accessToken, ticketCategory, ticketService, ticketSeverity, ticketReferentId, 
    ticketConstat,ticketCause, ticketAcceptTracking, userId, ticketReference, ticketState, ticketComment, type,
     ticketStartDate,ticketApproxDate,ticketEffectiveDate, ticketActionArray) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/members/quality/tickets/create`;
    const values = {};
    // values.ticketTitle = ticketTitle;
    values.ticketService = ticketService;
    values.ticketCategory = ticketCategory;
    values.ticketSeverity = ticketSeverity;
    values.ticketReferentId = ticketReferentId;
    values.ticketDescription = ticketConstat;
    values.ticketCause = ticketCause;
    values.ticketAcceptTracking = ticketAcceptTracking;
    values.userId = userId;
    values.ticketReference = ticketReference;
    values.ticketState = ticketState;
    values.ticketComment = ticketComment;
    values.type = type;
    values.ticketStartDate = ticketStartDate;
    values.ticketApproxDate = ticketApproxDate;
    values.ticketEffectiveDate = ticketEffectiveDate;
    values.correctiveActions = ticketActionArray;

    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
      
    });
    // console.log('values');
    // console.log(values);
    // console.log('resp');
    // console.log(resp);
    // console.log('json :');
    const json = await resp.json();
    
    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async getLastTicketIdMemberTicket(accessToken) { 
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/members/quality/tickets/ladst_ID/get`;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(accessToken),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    
    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async getServicesMemberTicket(accessToken) { 
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/members/quality/tickets/services/get`;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(accessToken),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    
    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async getCategoriesMemberTicket(accessToken, serviceId, type) { 
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/members/quality/tickets/categories/get`;
    const values = {};
    values.serviceId = serviceId;
    values.type = type;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
      
    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async getServiceMembersMemberTicket(accessToken, serviceId) { 
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/members/quality/tickets/members/get`;
    const values = {};
    values.serviceId = serviceId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
      
    // console.log(json.data);
    return Promise.resolve(json.data);
  }


  async getTicketInfo(accessToken, ticketId) { 
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/quality/tickets/${ticketId}/get`;
    const values = {};
    values.ticketId = ticketId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  // /////add nex comment
  async addNewComment(accessToken, ticketComment, ticketId, userId) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/comments/new`;
    const values = {};

    values.ticketComment = ticketComment;
    values.ticketId = ticketId;
    values.userId = userId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
      
    });
    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getTicketComments(accessToken, ticketId) { 
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/comments/get`;
    const values = {};
    values.ticketId = ticketId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
      
    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async updateTicketInfo(
    accessToken,
    ticketId,

    newTicketService, 
    newTicketSeverity, 
    newTicketReferentId, 
    newTicketTracking,
    newTicketSolution, 
    newTicketState, 
    newTicketMajorIncident, 
    newTicketCategory,
    newTicketAcceptTracking,
    newTicketDescription,
    newTicketDStart,
    newTicketDRA,
    newTicketDREff,
    newTicketCause,
    
    oldTicketService, 
    oldTicketSeverity, 
    oldTicketReferentId,
    oldTicketTracking, 
    oldTicketSolution, 
    oldTicketState, 
    oldTicketMajorIncident, 
    oldTicketCategory, 
    oldTicketAcceptTracking, 
    oldTicketDescription,
    oldTicketDStart,
    oldTicketDRA, 
    oldTicketDREff,
    oldTicketCause,

    userId, 
    ref,
    learnerId

  ) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/${ticketId}/update`;
    const values = {};
    values.ticketId = ticketId;
    
    //new values
    values.newTicketService = newTicketService;
    values.newTicketSeverity = newTicketSeverity;
    values.newTicketReferentId = newTicketReferentId;
    values.newTicketTracking = newTicketTracking;
    values.newTicketSolution = newTicketSolution;
    values.newTicketState = newTicketState;
    values.newTicketMajorIncident = newTicketMajorIncident;
    values.newTicketCategory = newTicketCategory;
    values.newTicketAcceptTracking = newTicketAcceptTracking;
    values.newTicketDescription = newTicketDescription;
    values.newTicketDStart = newTicketDStart;
    values.newTicketDRA = newTicketDRA;
    values.newTicketDREff = newTicketDREff;
    values.newTicketCause = newTicketCause;

    // old values 
    values.oldTicketService = oldTicketService;
    values.oldTicketSeverity = oldTicketSeverity;
    values.oldTicketReferentId = oldTicketReferentId;
    values.oldTicketTracking = oldTicketTracking;
    values.oldTicketSolution = oldTicketSolution;
    values.oldTicketState = oldTicketState;
    values.oldTicketMajorIncident = oldTicketMajorIncident;
    values.oldTicketCategory = oldTicketCategory;
    values.oldTicketAcceptTracking = oldTicketAcceptTracking;
    values.oldTicketDescription = oldTicketDescription;
    values.oldTicketDStart = oldTicketDStart;
    values.oldTicketDRA = oldTicketDRA;
    values.oldTicketDREff = oldTicketDREff;
    values.oldTicketCause = oldTicketCause;

    values.userId = userId;
    values.ref = ref;
    values.learnerId = learnerId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
      
    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async getTicketLog(accessToken, ticketId) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/quality/tickets/${ticketId}/log/get`;
    const values = {};
    values.ticketId = ticketId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async validateTicket(accessToken, ticketId, userId, oldTicketAcceptTracking, newTicketAcceptTracking, newTicketDescription) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/${ticketId}/validate`;
    const values = {};
    values.ticketId = ticketId;
    values.userId = userId;
    values.oldTicketAcceptTracking = oldTicketAcceptTracking;
    values.newTicketAcceptTracking = newTicketAcceptTracking;
    values.newTicketDescription = newTicketDescription;
    // console.log(values);
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    // console.log(json.data);
    return Promise.resolve(json.data);
  }

 async deleteTicket(accessToken, ticketId, userId) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/${ticketId}/delete`;
    const values = {};
    values.ticketId = ticketId;
    values.userId = userId;
    // console.log(values);
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async openTicket(accessToken, ticketId, userId, newTicketAcceptTracking, newTicketDescription, reference, learnerId, createdAt, ticketCreatorId, state) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/${ticketId}/open`;
    const values = {};
    values.ticketId = ticketId;
    values.userId = userId;
    values.tracking = newTicketAcceptTracking;
    values.description = newTicketDescription;
    values.reference = reference;
    values.learner_id = learnerId;
    values.created_at = createdAt;
    values.ticket_creator_id = ticketCreatorId;
    values.state = state;
    // console.log(values);
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async sendDoc(tiketId, sourceId, fileValues, ticketReference, accessToken) {
    const URL_CREATE_DOC = `${process.env.REACT_APP_API_URL}/api/tickets/add-document`;
    const data = new FormData();
    fileValues.forEach((value, index) => {
      data.append(`asset${index}`, value.file);
      data.append(`name${index}`, value.name);
    });
    data.append('sourceId', sourceId);
    data.append('ticketId', tiketId);
    data.append('ref', ticketReference);
    const resp = await fetch(URL_CREATE_DOC, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return json;
  }

 
  async getTicketDocuments(accessToken, ticketId) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/get-document`;
    const values = {};
    values.ticketId = ticketId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    // console.log(json.data);
    return Promise.resolve(json.data);
  }

 async deleteTicketDocument(accessToken, docId, ticketId) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/delete-document`;
    const values = {};
    values.docId = docId;
    values.ticketId = ticketId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    // console.log(json.data);
    return Promise.resolve(json.data);
  }

  async getTicketsByMajorIncident(accessToken, majorIncidentId) {
    const urlTicketsMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        id: majorIncidentId
      }),
    };

    const response = await fetch(urlTicketsMajorIncident, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  async getMajorIncidentLogs(accessToken, majorIncidentId) {
    const urlMajorIncidentLogs = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/logs`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        majorIncidentId
      }),
    };

    const response = await fetch(urlMajorIncidentLogs, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  // async getTicketSuggestions(accessToken, serviceId) {
  //   const urlInitialTickets = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets/suggestions`;

  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     },
  //     body: JSON.stringify({
  //       service_id: serviceId
  //     }),
  //   };

  //   const response = await fetch(urlInitialTickets, options);

  //   if (!response.ok) {
  //     const message = `Une erreur s'est produite: ${response.status}`;
  //     return new Error(message);
  //   }

  //   return response.json();
  // }

  async deleteMajorIncident(accessToken, majorIncidentId, userId) {
    const urlDeleteMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/delete`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        majorIncidentId,
        userId
      }),
    };

    const response = await fetch(urlDeleteMajorIncident, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  }

  async solveMajorIncident(accessToken, majorIncidentId, userId, solution) {
    const urlSolveMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/solve`;
    const values = {};
    values.majorIncidentId = majorIncidentId;
    values.userId = userId;
    values.solution = solution;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(values),
    };

    const response = await fetch(urlSolveMajorIncident, options);

    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }

    return response.json();
}

async reopenMajorIncident(accessToken, majorIncidentId, userId) {
  const urlReopenMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/reopen`;
  const values = {};
  values.majorIncidentId = majorIncidentId;
  values.userId = userId;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify(values),
  };

  const response = await fetch(urlReopenMajorIncident, options);

  if (!response.ok) {
    const message = `Une erreur s'est produite: ${response.status}`;
    return new Error(message);
  }

  return response.json();
}

async getMajorIncidentSolution(accessToken, majorIncidentId) {
  const urlGetSolution = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/solution/get`;
  const values = {};
  values.majorIncidentId = majorIncidentId;
  const resp = await fetch(urlGetSolution, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  const json = await resp.json();

  return Promise.resolve(json.solution);
}

async getMajorIncidentDocuments(accessToken, majorIncidentId) {
  const urlMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/get-document`;
  const values = {};
  values.majorIncidentId = majorIncidentId;
  const resp = await fetch(urlMajorIncident, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  const json = await resp.json();

  // console.log(json.data);
  return Promise.resolve(json.data);
}

async deleteMajorIncidentDocument(accessToken, docId, majorIncidentId) {
  const urlTicket = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/delete-document`;
  const values = {};
  values.docId = docId;
  values.majorIncidentId = majorIncidentId;
  const resp = await fetch(urlTicket, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  const json = await resp.json();

  // console.log(json.data);
  return Promise.resolve(json.data);
}

async fetchCorrectiveActionsByTicketId(accessToken , id ) {
 const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/get-corrective-actions-byTicket`;
 const values = {};
 values.ticket_id = id;
 const resp = await fetch(urlTicket, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  const json = await resp.json();

  // console.log(json.data);
  return Promise.resolve(json.data);
 
}

async sendDoc_corrective_action(tiketId, sourceId, fileValues, accessToken) {
  const URL_CREATE_DOC = `${process.env.REACT_APP_API_URL}/api/tickets/corrective-actions/add-multiple-actions-docs`;
  const data = new FormData();
  console.log(fileValues);
    fileValues.forEach((value, index) => {
      
    console.log(`asset${index} `, value.file);
    console.log(`name${index}`, value.name);
    console.log(`ref${index}`, value.ref);


    data.append(`asset${index}`, value.file);
    data.append(`name${index}`, value.name);
    data.append(`ref${index}`, value.ref);
     
  
  });
  data.append('sourceId', sourceId);
  data.append('ticketId', tiketId);
  // data.append('ref', ticketReference);
  const resp = await fetch(URL_CREATE_DOC, {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  const json = await resp.json();
  return json;
}

async addCorrectiveActionByTicketId(accessToken, ticketId, user, ca_desc, ca_date, ref ) {
const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/add-corrective-action`;
const values = {};
 values.ticket_id = ticketId;
 values.user_id = user;
 values.corrective_action = ca_desc;
 values.correction_date = ca_date;
 values.ref = ref;


 const resp = await fetch(urlTicket, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  const json = await resp.json();
  // console.log(json.data);
  return Promise.resolve(json.data);
}

async deleteorrectiveActionByTicketId(accessToken, Id ) {
const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/delete-corrective-action`;
const values = {};
 values.corrective_action_id = Id;



 const resp = await fetch(urlTicket, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  const json = await resp.json();
  // console.log(json.data);
  return Promise.resolve(json.data);
}

async updateCorrectiveAction(accessToken, id, correctiveAction, correctionDate) {
  const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/mod-corrective-action`;
const values = {};
 values.correction_id = id;
 values.corrective_action = correctiveAction;
 values.correction_date = correctionDate;



 const resp = await fetch(urlTicket, {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    }
  });
  const json = await resp.json();
  // console.log(json.data);
  return Promise.resolve(json.data);

}

async deleteCorrectiveActionSingleDoc(accessToken, docId, ticketId) {
  const urlTicket = `${process.env.REACT_APP_API_URL}/api/tickets/delete-corrective-action-single-doc`;
  const values = {};
   values.docId = docId;
   values.ticketId = ticketId;
    
   const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    // console.log(json.data);
    return Promise.resolve(json.data);
}

}



export const ticketApi = new TicketApi();
