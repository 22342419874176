/* eslint-disable no-unneeded-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-trailing-spaces  */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-multi-spaces */
/* eslint-disable object-curly-spacing */
/* eslint-disable brace-style */
/* eslint-disable padded-blocks */
/* eslint-disable prefer-destructuring */
/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable block-spacing */
/* eslint-disable quotes */
/* eslint-disable no-lone-blocks */
/* eslint-disable arrow-parens */
import { Grid, TextField, Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { MobileDateTimePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { ticketApi } from '../../../api/ticketApi';
import useAuth from '../../../hooks/useAuth';
import TicketsMajorIncident from './TicketsMajorIncident';
import FilesMajorIncident from './FilesMajorIncident';


const DetailsMajorIncident = (props) => {
  const { 
    show, 
    serviceId, 
    majorIncidentId, 
    majorIncidentState, 
    setMajorIncidentState, 
    isLoading, 
    setIsLoading, 
    theme, 
    updateSolution, 
    setUpdateSolution,
    majorIncident, 
    setMajorIncident,
    currentSolution, 
    setCurrentSolution
  } = props;
  const { user } = useAuth();
  // const [updateMajorIncident, setUpdateMajorIncident] = useState(false);
  // const [updateTicketList, setUpdateTicketList] = useState(false)
  // const [currentSolution, setCurrentSolution] = useState(null);
  const [tickets, setTickets] = useState([])
  const [savedAttachments, setSavedAttachments] = useState([])
  // const [changes, setChanges] = useState(false)

  const hasAdminAccess = ((user.access_level === 0 && user.id_service === 0) || user.service_manager === 1);

  const getMajorIncidentById = async () => {
    try {
      const majorIncidentData = await ticketApi.getMajorIncidentById(localStorage.getItem('accessToken'), majorIncidentId)
      setMajorIncident({
        ...majorIncident, 
        solution: majorIncidentData.solution ?? '',
        startDate: majorIncidentData.start_date,
        endDate: majorIncidentData.end_date,
        description: majorIncidentData.description,
        // state: majorIncidentData.state,
       })
      setCurrentSolution(majorIncidentData.solution ?? '');
      setMajorIncidentState(majorIncidentData.state)
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }


  // useEffect(() => {
  //   if (updateSolution) getMajorIncidentById()
  // }, [updateSolution]);

  const handleChange = e => {
    setMajorIncident({
      ...majorIncident,
      [e.target.name]: e.target.value
    })
  }

  const handleBlur = async () => {
    await ticketApi.updateMajorIncident(localStorage.getItem('accessToken'), majorIncidentId, user.id, "description", majorIncident.description);
    // setChanges(true)
  }

  const handleDate = async (date) => {
    setMajorIncident({
      ...majorIncident,
      startDate: date
    })
    await ticketApi.updateMajorIncident(localStorage.getItem('accessToken'), majorIncidentId, user.id, "start_date", date);
  }

  const getMajorIncidentDocuments = async () => {
    try {
      const filesData = await ticketApi.getMajorIncidentDocuments(localStorage.getItem('accessToken'), majorIncidentId);
      setSavedAttachments(filesData);
      
    } catch (err) { 
      console.log(err);
    }
  };

  const deleteMajorIncidentDocument = async (fileId) => {
    try {
      const updatedAttachments = savedAttachments.filter((file) => file.id !== fileId)
      setSavedAttachments(updatedAttachments);
      await ticketApi.deleteMajorIncidentDocument(localStorage.getItem('accessToken'), fileId, majorIncidentId);
      
    } catch (err) { 
      console.log(err);
    }
  };

  useEffect(() => {
    if (show) {
      getMajorIncidentById()
      getMajorIncidentDocuments()
    } 
  }, [show, updateSolution]);

  return (
    <Grid
      sx={{ height: '600px', m: 3, mb: 4 }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <CircularProgress />
        </Box>
        ) : ( 
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid sx={{ marginBottom: '1.5rem' }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              locale={moment.locale('fr')}
            >
              <MobileDateTimePicker
                ampm={false}
                label="Date de début"
                inputFormat="DD/MM/YYYY à HH:mm"
                onChange={() => null}
                onClose={() => null}
                showTodayButton
                clearable
                cancelText="Annuler"
                todayText="Aujourd'hui"
                clearText="Vider"
                onAccept={handleDate}
                name="startDate"
                value={majorIncident.startDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          {majorIncidentState === "Résolu" &&
          <Box>
            <Typography><b style={{ color: theme.palette.primary.main}}>Date de résolution :</b> {moment(majorIncident.endDate).locale("fr").format("DD/MM/YYYY à HH:mm")}</Typography>
          </Box>
          }
        </Box> 
        <Box sx={{ width: '100%', display: 'flex', gap: 2, mb: 2 }}>
          <TextField
              multiline
              rows={4}
              label="Description"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={majorIncident.description}
              sx={{ flex: 0.5 }}
          />
          <Box sx={{ flex: 0.5 }}>
          {hasAdminAccess === true ? 
            (
              <TextField label="Action corrective"
              sx={{ 
                marginBottom: !hasAdminAccess ? '1.5rem' : '0.2rem', 
                width: '100%',
                '& .MuiInputBase-root': {
                  borderColor: theme.palette.primary.main, // Couleur personnalisée pour le bord
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: theme.palette.primary.main, // Couleur personnalisée pour le bord
                  },
                  '&:hover fieldset': {
                    borderColor: theme.palette.primary.main, // Couleur personnalisée pour le bord lorsqu'il est en focus
                  },
                }
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.main }, // Couleur personnalisée pour l'étiquette
              }}
              multiline
              rows={4}
              value={majorIncident.solution}
              name="solution"
              onChange={handleChange}
              disabled={!hasAdminAccess}
              /> 
            ) : (
              <Box sx={{ mb: 4 }}>
                <Typography color="primary"> <span style={{ fontWeight: 'bold' }}>Action corrective : </span>
                {!majorIncident.solution ? 
                <>
                  {majorIncident.solution !== null && majorIncident.solution !== '' ? 
                  majorIncident.solution : 
                    <span
                        style={{ fontStyle: 'italic' }}>Aucune action corrective trouvée pour le moment.
                    </span>
                  }
                </> :
                <CircularProgress size="1.5rem" />
                }
                </Typography>
              </Box>
            )
          }
          </Box>
        </Box>
        <FilesMajorIncident 
          majorIncidentId={majorIncidentId} 
          theme={theme} 
          savedAttachments={savedAttachments}
          setSavedAttachments={setSavedAttachments}
          getMajorIncidentDocuments={getMajorIncidentDocuments} 
          deleteMajorIncidentDocument={deleteMajorIncidentDocument} 
        />
        <TicketsMajorIncident 
          tickets={tickets} 
          setTickets={setTickets} 
          majorIncidentId={majorIncidentId} 
          serviceId={serviceId} 
          update={updateSolution} 
          setUpdate={setUpdateSolution} 
          show={show} 
        />
     </>)}
    </Grid>
  );
};

export default DetailsMajorIncident;

DetailsMajorIncident.propTypes = {
  serviceId: PropTypes.number.isRequired,
  majorIncidentId: PropTypes.number.isRequired,
  majorIncidentAuthor: PropTypes.object.isRequired,
  majorIncidentDescription: PropTypes.string.isRequired,
  majorIncidentStartDate: PropTypes.string.isRequired,
  majorIncidentEndDate: PropTypes.string.isRequired,
  tickets: PropTypes.array.isRequired,
  setTickets: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  getTicketsByMajorIncident: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  setToUpdate: PropTypes.func.isRequired,
  majorIncidentState: PropTypes.string.isRequired
};
