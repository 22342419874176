/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Alert, IconButton
} from '@material-ui/core';
import * as moment from 'moment';
import CakeIcon from '@mui/icons-material/Cake';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';
import {  useState } from 'react';

const CustomerContactDetails = (props) => {
  const { birthday, civility, firstname, maidenName, lastname, adress, zip, city, email, phone, phoneFix, department, region, notCall, country, birthTown, editing,
    // eslint-disable-next-line react/prop-types
    autoZip, setAutoZip, errors, handleBlur, handleChange, touched, values, setFieldValue, ...other } = props;
  const birthdayToFr = birthday !== null ? moment(birthday).format('DD/MM/YYYY') : 'Non enregistrée';
  const civilityLabel = ['Monsieur', 'Madame', 'Autre'];
const [showCopyLastname, setShowCopyLastName] = useState(false);
const [showCopyEmail, setShowCopyEmail] = useState(false);
  return (
    <Card {...other}>
      <CardHeader title="Informations générales" />
      <Divider />
      {birthdayToFr === new Date().toLocaleDateString('fr-FR') ? (
        <Alert
          icon={<CakeIcon fontSize="inherit" />}
          severity="success"
          style={{ fontSize: 18 }}
        >
          Souhaiter un joyeux anniversaire à
          {' '}
          {civility}
          {' '}
          {lastname}
          {' '}
          {firstname}
          {'  '}
        </Alert>
      ) : ''}
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Civilité
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <Autocomplete
                    options={civilityLabel}
                    onChange={(e, value) => {
                      setFieldValue(
                        'civility',
                        value !== null ? value : civility
                      );
                    }}
                    value={values.civility}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.civility && errors.civility)}
                        fullWidth
                        helperText={touched.civility && errors.civility}
                        label="Civilité"
                        name="civility"
                        variant="outlined"
                      />
                    )}
                  />
                ) : civility}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow onMouseEnter={() => {setShowCopyLastName(true);}} onMouseLeave={() => { setShowCopyLastName(false);}}>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Nom
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Nom"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />

                ) : lastname}
              </Typography>
            </TableCell>
            <TableCell>
              <IconButton style={{margin: '0px', padding: '0px', opacity: lastname && showCopyLastname ? '1' : '0'}} onClick={() => {navigator.clipboard.writeText(lastname); toast.success('Nom a été copié avec succès');}}>
                <ContentCopyIcon fontSize="small" style={{color: 'grey'}} />
                </IconButton> </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Nom de jeune fille
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.maidenName && errors.maidenName)}
                    fullWidth
                    helperText={touched.maidenName && errors.maidenName}
                    label="Nom de jeune fille"
                    name="maidenName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.maidenName}
                    variant="outlined"
                  />

                ) : maidenName}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Prénom
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.firstname && errors.firstname)}
                    fullWidth
                    helperText={touched.firstname && errors.firstname}
                    label="Prénom"
                    name="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.firstname}
                    variant="outlined"
                  />
                ) : firstname}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow onMouseEnter={() => {setShowCopyEmail(true);}} onMouseLeave={() => { setShowCopyEmail(false);}}>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Adresse Email
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Adresse Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                ) : email}
              </Typography>
            </TableCell>
            <TableCell>
               <IconButton style={{margin: '0px', padding: '0px', opacity: email && showCopyEmail ? '1' : '0' }} onClick={() => {navigator.clipboard.writeText(email); toast.success('Adresse Email a été copié avec succès');}}>
                <ContentCopyIcon fontSize="small" style={{ color: 'grey'}} />
                </IconButton>  </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Téléphone portable
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    container
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label="Téléphone portable"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                  />
                ) : [null, ''].includes(notCall)
                  ? phone : (
                    <Alert severity="error">
                      Ne pas appeler
                      {' '}
                      {phone}
                    </Alert>
                  ) }
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Téléphone fixe
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.phoneFix && errors.phoneFix)}
                    fullWidth
                    helperText={touched.phoneFix && errors.phoneFix}
                    label="Téléphone fixe"
                    name="phoneFix"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneFix}
                    variant="outlined"
                  />
                ) : phoneFix}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Date de naissance
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.birthday && errors.birthday)}
                    fullWidth
                    helperText={touched.birthday && errors.birthday}
                    label="Date de naissance"
                    name="birthday"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    value={values.birthday}
                    variant="outlined"
                    // pour éviter la superposition de jj/mm/aaaa et date de naissance dans le champ
                    InputLabelProps={{ shrink: true }}
                  />
                ) : birthdayToFr}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Ville de naissance
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.birthTown && errors.birthTown)}
                    fullWidth
                    helperText={touched.birthTown && errors.birthTown}
                    label="Ville de naissance"
                    name="birthTown"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.birthTown}
                    variant="outlined"
                  />
                ) : birthTown}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Adresse
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.adress && errors.adress)}
                    fullWidth
                    helperText={touched.adress && errors.adress}
                    label="Adresse"
                    name="adress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adress}
                    variant="outlined"
                  />
                ) : adress}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Code postal
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.zip && errors.zip)}
                    fullWidth
                    helperText={touched.zip && errors.zip}
                    label="Code postal"
                    name="zip"
                    onBlur={handleBlur}
                    onChange={(e) => { if (autoZip === undefined || autoZip === null) { setAutoZip(true); } handleChange(e); }}
                    value={values.zip}
                    variant="outlined"
                  />
                ) : zip}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Ville
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.city && errors.city)}
                    fullWidth
                    helperText={touched.city && errors.city}
                    label="Ville"
                    name="city"
                    onBlur={handleBlur}
                    onChange={(e) => { setAutoZip(false); handleChange(e); }}
                    value={values.city}
                    variant="outlined"
                  />

                ) : city}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Département
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.department && errors.department)}
                    fullWidth
                    helperText={touched.department && errors.department}
                    label="Département"
                    name="department"
                    onBlur={handleBlur}
                    onChange={(e) => { setAutoZip(false); handleChange(e); }}
                    value={values.department}
                    variant="outlined"
                  />

                ) : department}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Région
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.region && errors.region)}
                    fullWidth
                    helperText={touched.region && errors.region}
                    label="Région"
                    name="region"
                    onBlur={handleBlur}
                    onChange={(e) => { setAutoZip(false); handleChange(e); }}
                    value={values.region}
                    variant="outlined"
                  />

                ) : region}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Pays de résidence
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.country && errors.country)}
                    fullWidth
                    helperText={touched.country && errors.country}
                    label="Pays de résidence"
                    name="country"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.country}
                    variant="outlined"
                  />
                ) : country}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
};

CustomerContactDetails.propTypes = {
  birthday: PropTypes.string.isRequired,
  civility: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  firstname: PropTypes.string.isRequired,
  maidenName: PropTypes.string.isRequired,
  birthTown: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  adress: PropTypes.string,
  zip: PropTypes.string,
  city: PropTypes.string,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  phoneFix: PropTypes.string,
  department: PropTypes.string,
  region: PropTypes.string,
  notCall: PropTypes.string,
  editing: PropTypes.bool,
  touched: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.object
};

export default CustomerContactDetails;
