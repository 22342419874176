import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Tab, Typography } from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import * as React from 'react';
// import { useTheme } from '@material-ui/core/styles';
import RelaunchStats from '../../components/dashboard/relaunch/RelaunchStats';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const Reminders = () => {
  const { settings } = useSettings();
  // const theme = useTheme();
  const [tabs, setTabs] = React.useState('all');

  const handleChange = (event, tab) => {
    setTabs(tab);
  };

  return (
    <>
      <Helmet>
        <title>Relance | APTEED</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Typography
              // color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
              // variant="h3"
                color="textPrimary"
                variant="h5"
              >
                Relances
              </Typography>
            </Grid>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Box sx={{ width: '100%', marginTop: '20px', marginLeft: '20px' }}>
                <TabContext value={tabs}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        label="Tous"
                        value="all"
                      />
                      <Tab
                        label="Tour 1"
                        value="turn1"
                      />
                      <Tab
                        label="Tour 2"
                        value="turn2"
                      />
                      <Tab
                        label="Décalage"
                        value="shift"
                      />
                    </TabList>
                  </Box>
                  <TabPanel value="shift">
                    <RelaunchStats state="shift" />
                  </TabPanel>

                  <TabPanel value="all">
                    <RelaunchStats state="all" />
                  </TabPanel>

                  <TabPanel value="turn1">
                    <RelaunchStats state="turn1" />
                  </TabPanel>

                  <TabPanel value="turn2">
                    <RelaunchStats state="turn2" />
                  </TabPanel>

                </TabContext>
              </Box>
              {/* <Grid item> */}
              {/* <Typography
                  color="textSecondary"
                  variant="h3"
                > */}
              {/* <Grid item>
                <RelaunchStats />
              </Grid> */}
              {/* </Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Reminders;
