/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Typography,
  TextField,
  Grid,
  Button,
  Box,
  Autocomplete
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomerCommercialComment from './CustomerCommercialComment';
import { customerApi } from '../../../api/customerApi';
import toast from 'react-hot-toast';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import PencilAltIcon from '../../../icons/PencilAlt';
import { isSameDay, isSameWeek, isSameMonth } from 'date-fns';
import useMounted from '../../../hooks/useMounted';
import { useNavigate } from 'react-router-dom';
import * as moment from 'moment';

const CustomerCommercialActions = (props) => {
  const { customerId, user, customer, formations, ...other } = props;
  const todayItems = [];
  const thisWeekItems = [];
  const thisMonthItems = [];
  const ancientItems = [];
  const mounted = useMounted();
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation
  // correspond à id_service, 3 c'est commercial, va activer le bouton commenter
  const adminList = [0, 3, 4];

  // pour savoir si on active le bouton commenter ou pas
  let isAuthorized = false;
  if (adminList.includes(user.id_service)) {
    isAuthorized = true;
  }
  if (user.access_level === 0) {
    isAuthorized = true;
  }

  const [comments, setComments] = useState([]);
  const [commenting, setCommenting] = useState(false);

  // eslint-disable-next-line no-restricted-syntax
  for (const activity of comments) {
    if (isSameDay(new Date(activity.createdAt), new Date())) {
      todayItems.push(activity);
    } else
    if (isSameWeek(new Date(activity.createdAt), new Date())) {
      thisWeekItems.push(activity);
    } else
    if (isSameMonth(new Date(activity.createdAt), new Date())) {
      thisMonthItems.push(activity);
    } else {
      ancientItems.push(activity);
    }
  }

  const handleDelete = async (id) => {
    try {
      await customerApi.deleteCommentCommercial(id, localStorage.getItem('accessToken'));
      window.location.reload();
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la suppression !');
    }
  };

  let qualificationLabel = [];
  if (customer.apprenant.profession === 'Masseur-kinésithérapeute' || customer.apprenant.profession === 'Infirmier' || customer.apprenant.profession === 'Pharmacien') {
    qualificationLabel = ['Vente 1 formation', 'Vente 2 formation', 'Refus', 'Erreur', 'Souhaite un email', 'Rappel', 'Attente', 'Appel entrant', 'Pas répondu'];
  } else {
    qualificationLabel = ['Vente 1 formation', 'Vente 2 formation', 'Vente 3 formation', 'Refus', 'Erreur', 'Souhaite un email', 'Rappel', 'Attente', 'Appel entrant', 'Pas répondu'];
  }
  const qualificationRejectionLabel = ['Déjà validé', 'Souhaite du présentiel', 'Aucun thème voulu', 'Date de session trop proche', 'Préfère un autre organisme'];
  const qualificationErrorLabel = ['Retraite', 'Numéro incorrect', 'Non professionnel de santé'];
  const [freeFormationsLabel, setFreeFormationsLabel] = useState([]);
  const [choiceQualification, setChoiceQualification] = useState(null);
  const [choiceQualificationError, setChoiceQualificationError] = useState(null);
  const [choiceQualificationRejection, setChoiceQualificationRejection] = useState(null);
  const [choiceQualificationRecall, setChoiceQualificationRecall] = useState(null);
  const [choiceFormation1, setChoiceFormation1] = useState(null);
  const [choiceFormation2, setChoiceFormation2] = useState(null);
  const [choiceFormation3, setChoiceFormation3] = useState(null);
  const [choiceFreeFormation, setChoiceFreeFormation] = useState(null);
  const [choiceSessionFormation1, setChoiceSessionFormation1] = useState(null);
  const [choiceSessionFormation2, setChoiceSessionFormation2] = useState(null);
  const [choiceSessionFormation3, setChoiceSessionFormation3] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const [displayRejection, setDisplayRejection] = useState(false);
  const [displayDateRecall, setDisplayDateRecall] = useState(false);
  const [displayFreeFormation, setDisplayFreeFormation] = useState(false);
  const [displayFormation1, setDisplayFormation1] = useState(false);
  const [displayFormation2, setDisplayFormation2] = useState(false);
  const [displayFormation3, setDisplayFormation3] = useState(false);
  const [displayStartRelaunchDate, setDisplayStartRelaunchDate] = useState(false);
  const [displaySessionFormation1, setDisplaySessionFormation1] = useState(false);
  const [displaySessionFormation2, setDisplaySessionFormation2] = useState(false);
  const [displaySessionFormation3, setDisplaySessionFormation3] = useState(false);
  const [disableChoiceSession1, setDisableChoiceSession1] = useState(true);
  const [disableChoiceSession2, setDisableChoiceSession2] = useState(true);
  const [disableChoiceSession3, setDisableChoiceSession3] = useState(true);
  const [indexFormation1, setIndexFormation1] = useState(0);
  const [indexFormation2, setIndexFormation2] = useState(0);
  const [indexFormation3, setIndexFormation3] = useState(0);
  const [chosenSession, setChosenSession] = useState([]);
  const [choiceStartRelaunchDate, setChoiceStartRelaunchDate] = useState(null);
  const [hasStartRelaunchDate, setHasStartRelaunchDate] = useState(false);

  const [initialValues, setInitialValues] = useState({
    commentCommercial: null,
    choiceQualification: null,
    choiceQualificationError: null,
    choiceQualificationRejection: null,
    choiceQualificationRecall: null,
    choiceFormation1: null,
    choiceFormation2: null,
    choiceFormation3: null,
    choiceFreeFormation: null,
    choiceSessionFormation1: null,
    choiceSessionFormation2: null,
    choiceSessionFormation3: null,
    choiceStartRelaunchDate: null
  });

  const getCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getCustomer(customerId, localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setComments(data.commercial_comments);
        setInitialValues({
          commentCommercial: null,
          choiceQualification: null,
          choiceQualificationError: null,
          choiceQualificationRejection: null,
          choiceQualificationRecall: null,
          choiceFormation1: null,
          choiceFormation2: null,
          choiceFormation3: null,
          choiceFreeFormation: null,
          choiceSessionFormation1: null,
          choiceSessionFormation2: null,
          choiceSessionFormation3: null,
          choiceStartRelaunchDate: data.apprenant.start_relaunch_date
        });
        setChoiceStartRelaunchDate(data.apprenant.start_relaunch_date);
        if (data.apprenant.start_relaunch_date !== null) {
          setHasStartRelaunchDate(true);
        }
      }
    } catch (err) {
      console.error(err);
      navigate('/404', { replace: true });
    }
  }, [mounted]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  // useEffect(() => {
  //   console.log(choiceStartRelaunchDate);
  // }, [choiceStartRelaunchDate]);

  // useEffect(() => {
  //   console.log(chosenSession);
  // }, [chosenSession]);

  useEffect(() => {
    const tempFreeFormations = [];
    formations.forEach((formation) => {
      const publicForFormation = formation.public.split(' ');
      publicForFormation.forEach((el) => {
        if (el === customer.apprenant.profession) {
          tempFreeFormations.push({ name: formation.name, id: formation.id, public: formation.public });
        }
      });
    });
    setFreeFormationsLabel(tempFreeFormations);
  }, [formations]);

  useEffect(() => {
    const tempChosenSession = [];
    if (choiceSessionFormation1 !== null) {
      tempChosenSession.push(choiceSessionFormation1);
    }
    if (choiceSessionFormation2 !== null) {
      tempChosenSession.push(choiceSessionFormation2);
    }
    if (choiceSessionFormation3 !== null) {
      tempChosenSession.push(choiceSessionFormation3);
    }
    setChosenSession(tempChosenSession);
  }, [choiceSessionFormation1, choiceSessionFormation2, choiceSessionFormation3]);

  const formations1 = freeFormationsLabel.filter((el) => el !== choiceFormation3 && el !== choiceFormation2 && el !== choiceFreeFormation);
  const formations2 = freeFormationsLabel.filter((el) => el !== choiceFormation3 && el !== choiceFormation1 && el !== choiceFreeFormation);
  const formations3 = freeFormationsLabel.filter((el) => el !== choiceFormation1 && el !== choiceFormation2 && el !== choiceFreeFormation);
  const formationsFree = freeFormationsLabel.filter((el) => el !== choiceFormation1 && el !== choiceFormation2 && el !== choiceFormation3);

  const handleChangeQualification = (value) => {
    setChoiceQualification(value);
    switch (value) {
      case 'Refus':
        setDisplayRejection(true);
        setDisplayError(false);
        setDisplayDateRecall(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        setDisplaySessionFormation1(false);
        setDisplaySessionFormation2(false);
        setDisplaySessionFormation3(false);
        break;
      case 'Erreur':
        setDisplayError(true);
        setDisplayRejection(false);
        setDisplayDateRecall(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        setDisplaySessionFormation1(false);
        setDisplaySessionFormation2(false);
        setDisplaySessionFormation3(false);
        break;
      case 'Rappel':
        setDisplayDateRecall(true);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        setDisplaySessionFormation1(false);
        setDisplaySessionFormation2(false);
        setDisplaySessionFormation3(false);
        break;
      case 'Vente 1 formation':
        setDisplayDateRecall(false);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(true);
        setDisplayFormation1(true);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        setDisplaySessionFormation1(true);
        setDisplaySessionFormation2(false);
        setDisplaySessionFormation3(false);
        break;
      case 'Vente 2 formation':
        setDisplayDateRecall(false);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(true);
        setDisplayFormation1(true);
        setDisplayFormation2(true);
        setDisplayFormation3(false);
        setDisplaySessionFormation1(true);
        setDisplaySessionFormation2(true);
        setDisplaySessionFormation3(false);
        break;
      case 'Vente 3 formation':
        setDisplayDateRecall(false);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(true);
        setDisplayFormation1(true);
        setDisplayFormation2(true);
        setDisplayFormation3(true);
        setDisplaySessionFormation1(true);
        setDisplaySessionFormation2(true);
        setDisplaySessionFormation3(true);
        break;
      default:
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayDateRecall(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        setDisplaySessionFormation1(false);
        setDisplaySessionFormation2(false);
        setDisplaySessionFormation3(false);
        break;
    }
  };
  const handleChangeQualificationError = (value) => {
    setChoiceQualificationError(value);
  };
  const handleChangeQualificationRejection = (value) => {
    setChoiceQualificationRejection(value);
  };

  const [isFormFilled, setIsFormFilled] = useState(true);

  useEffect(() => {
    if ((choiceQualification !== null && choiceQualification !== 'Refus')
    && (choiceQualification !== null && choiceQualification !== 'Erreur')
    && (choiceQualification !== null && choiceQualification !== 'Rappel')
    && (choiceQualification !== null && choiceQualification !== 'Vente 1 formation')
    && (choiceQualification !== null && choiceQualification !== 'Vente 2 formation')
    && (choiceQualification !== null && choiceQualification !== 'Vente 3 formation')) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }

    const longSessionArray = chosenSession.filter((session) => moment(session.end_date, 'YYYY-MM-DD').diff(moment(session.start_date, 'YYYY-MM-DD'), 'months', true) > 10);

    if ((choiceQualification === 'Vente 1 formation' || choiceQualification === 'Vente 2 formation' || choiceQualification === 'Vente 3 formation') && ((longSessionArray.length > 0 && choiceStartRelaunchDate !== null && choiceStartRelaunchDate !== '') || (longSessionArray.length === 0))) {
      // provient du système utilisé en campagne, sur la fiche apprenant le fonctionnement est différent
      // setIsFormFilled(false);
    } else if ((choiceQualification === 'Vente 1 formation' || choiceQualification === 'Vente 2 formation' || choiceQualification === 'Vente 3 formation') && longSessionArray.length > 0 && (choiceStartRelaunchDate === null || choiceStartRelaunchDate === '')) {
      setIsFormFilled(true);
    }

    if ((choiceQualification !== null && choiceQualification === 'Refus' && choiceQualificationRejection !== null)
    || (choiceQualification !== null && choiceQualification === 'Erreur' && choiceQualificationError !== null)
    || (choiceQualification !== null && choiceQualification === 'Rappel' && choiceQualificationRecall !== null)
    || (choiceQualification !== null && choiceQualification === 'Vente 1 formation' && choiceFormation1 !== null && choiceSessionFormation1 !== null && ((longSessionArray.length > 0 && choiceStartRelaunchDate !== null && choiceStartRelaunchDate !== '') || (longSessionArray.length === 0)))
    || (choiceQualification !== null && choiceQualification === 'Vente 2 formation' && choiceFormation1 !== null && choiceSessionFormation1 !== null && choiceFormation2 !== null && choiceSessionFormation2 !== null && ((longSessionArray.length > 0 && choiceStartRelaunchDate !== null && choiceStartRelaunchDate !== '') || (longSessionArray.length === 0)))
    || (choiceQualification !== null && choiceQualification === 'Vente 3 formation' && choiceFormation1 !== null && choiceSessionFormation1 !== null && choiceFormation2 !== null && choiceSessionFormation2 !== null && choiceFormation3 !== null && choiceSessionFormation3 !== null && ((longSessionArray.length > 0 && choiceStartRelaunchDate !== null && choiceStartRelaunchDate !== '') || (longSessionArray.length === 0)))) {
      setIsFormFilled(false);
    }
    if ((choiceQualification !== null && choiceQualification === 'Vente 1 formation' && choiceFormation1 !== null && choiceSessionFormation1 !== null && longSessionArray.length > 0 && (choiceStartRelaunchDate === null || choiceStartRelaunchDate === ''))
      || (choiceQualification !== null && choiceQualification === 'Vente 2 formation' && choiceFormation1 !== null && choiceSessionFormation1 !== null && choiceFormation2 !== null && choiceSessionFormation2 !== null && longSessionArray.length > 0 && (choiceStartRelaunchDate === null || choiceStartRelaunchDate === ''))
      || (choiceQualification !== null && choiceQualification === 'Vente 3 formation' && choiceFormation1 !== null && choiceSessionFormation1 !== null && choiceFormation2 !== null && choiceSessionFormation2 !== null && choiceFormation3 !== null && choiceSessionFormation3 !== null && longSessionArray.length > 0 && (choiceStartRelaunchDate === null || choiceStartRelaunchDate === ''))
    ) {
      setIsFormFilled(true);
    }
  }, [choiceQualification, choiceQualificationRejection, choiceQualificationError, choiceQualificationRecall, choiceFormation1, choiceFormation2, choiceFormation3, choiceSessionFormation1, choiceSessionFormation2, choiceSessionFormation3, chosenSession, choiceStartRelaunchDate]);
  const formikRef = useRef();
  useEffect(() => {
    setChoiceQualificationError(null);
    setChoiceQualificationRejection(null);
    setChoiceQualificationRecall(null);
    setChoiceFreeFormation(null);
    setChoiceFormation1(null);
    setChoiceFormation2(null);
    setChoiceFormation3(null);
    setChoiceSessionFormation1(null);
    setChoiceSessionFormation2(null);
    setChoiceSessionFormation3(null);
    setChoiceStartRelaunchDate(choiceStartRelaunchDate);
    if (formikRef.current) {
      // réinitialiser la value des selects au changement du select de qualification
      formikRef.current.values.choiceQualificationError = null;
      formikRef.current.values.choiceQualificationRecall = null;
      formikRef.current.values.choiceQualificationRejection = null;
      formikRef.current.values.choiceFormation1 = null;
      formikRef.current.values.choiceFormation2 = null;
      formikRef.current.values.choiceFormation3 = null;
      formikRef.current.values.choiceFreeFormation = null;
      formikRef.current.values.choiceSessionFormation1 = null;
      formikRef.current.values.choiceSessionFormation2 = null;
      formikRef.current.values.choiceSessionFormation3 = null;
      formikRef.current.values.choiceStartRelaunchDate = choiceStartRelaunchDate;
    }
  }, [choiceQualification]);

  // on active ou désactive le bouton enregistrer en fonction de la durée des sessions choisies et du remplissage ou non de la date de début de suivi
  useEffect(() => {
    const longSessionArray = chosenSession.filter((session) => moment(session.end_date, 'YYYY-MM-DD').diff(moment(session.start_date, 'YYYY-MM-DD'), 'months', true) > 10);
    if (longSessionArray.length > 0) {
      setDisplayStartRelaunchDate(true);
    } else {
      setDisplayStartRelaunchDate(false);
    }
    // if ((choiceQualification === 'Vente 1 formation' || choiceQualification === 'Vente 2 formation' || choiceQualification === 'Vente 3 formation') && ((longSessionArray.length > 0 && choiceStartRelaunchDate !== null && choiceStartRelaunchDate !== '') || (longSessionArray.length === 0))) {
    //   setIsFormFilled(false);
    // } else if ((choiceQualification === 'Vente 1 formation' || choiceQualification === 'Vente 2 formation' || choiceQualification === 'Vente 3 formation') && longSessionArray.length > 0 && (choiceStartRelaunchDate === null || choiceStartRelaunchDate === '')) {
    //   setIsFormFilled(true);
    // }
  }, [chosenSession, choiceStartRelaunchDate]);

  useEffect(() => {
    setChoiceSessionFormation1(null);
    if (formikRef.current) {
      formikRef.current.values.choiceSessionFormation1 = null;
    }
  }, [choiceFormation1]);

  useEffect(() => {
    setChoiceSessionFormation2(null);
    if (formikRef.current) {
      formikRef.current.values.choiceSessionFormation2 = null;
    }
  }, [choiceFormation2]);

  useEffect(() => {
    setChoiceSessionFormation3(null);
    if (formikRef.current) {
      formikRef.current.values.choiceSessionFormation3 = null;
    }
  }, [choiceFormation3]);

  // useEffect(() => {
  //   console.log(formations);
  // }, [formations]);

  // pour comparer la date de session à la date du jour
  const dateToday = moment();
  const dateTodayStartOfDay = dateToday.clone().startOf('day');

  return (
    <div {...other}>
      <Formik
        // pour pouvoir recupérer values de formik dans le useEffect
        innerRef={formikRef}
        // initialValues={{ commentCommercial: null,
        //   choiceQualification: null,
        //   choiceQualificationError: null,
        //   choiceQualificationRejection: null,
        //   choiceQualificationRecall: null,
        //   choiceFormation1: null,
        //   choiceFormation2: null,
        //   choiceFormation3: null,
        //   choiceFreeFormation: null,
        //   choiceSessionFormation1: null,
        //   // eslint-disable-next-line object-shorthand
        //   choiceStartRelaunchDate: choiceStartRelaunchDate, }}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={Yup
          .object()
          .shape({
            commentCommercial: Yup.string().nullable(),
            choiceQualification: Yup.string().nullable(),
            choiceQualificationError: Yup.string().nullable(),
            choiceQualificationRejection: Yup.string().nullable(),
            choiceFormation1: Yup.object().shape({
              id: Yup.number().nullable(),
              name: Yup.string().nullable()
            }).nullable(),
            choiceFormation2: Yup.object().shape({
              id: Yup.number().nullable(),
              name: Yup.string().nullable()
            }).nullable(),
            choiceFormation3: Yup.object().shape({
              id: Yup.number().nullable(),
              name: Yup.string().nullable()
            }).nullable(),
            choiceFreeFormation: Yup.object().shape({
              id: Yup.number().nullable(),
              name: Yup.string().nullable()
            }).nullable(),
            choiceSessionFormation1: Yup.object().shape({
              id: Yup.number().nullable(),
              start_date: Yup.string().nullable(),
              end_date: Yup.string().nullable()
            }).nullable(),
            choiceSessionFormation2: Yup.object().shape({
              id: Yup.number().nullable(),
              start_date: Yup.string().nullable(),
              end_date: Yup.string().nullable()
            }).nullable(),
            choiceSessionFormation3: Yup.object().shape({
              id: Yup.number().nullable(),
              start_date: Yup.string().nullable(),
              end_date: Yup.string().nullable()
            }).nullable(),
            choiceQualificationRecall: Yup.date().min(Date(), 'Vous ne pouvez pas sélectionner une date passée').nullable(),
            choiceStartRelaunchDate: Yup.date().nullable()
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          try {
            const jsonAnswer = await customerApi.addCommentCommercial(customerId, user.id, values, localStorage.getItem('accessToken'));
            setStatus({ success: true });
            resetForm({});
            setSubmitting(false);
            toast.success('Commentaire du commercial enregistré !');
            setCommenting(!commenting);
            const tempComments = [...comments];
            tempComments.unshift({ id: jsonAnswer.data.comment_id,
              comment: jsonAnswer.data.comment,
              createdAt: jsonAnswer.data.created_at,
              firstname: user.firstname,
              lastname: user.lastname,
              qualification: jsonAnswer.data.qualification,
              reason: jsonAnswer.data.reason,
              recall_date: choiceQualificationRecall,
              offered_formation: choiceFreeFormation,
              formation1: choiceFormation1,
              formation2: choiceFormation2,
              formation3: choiceFormation3
            });
            setComments(tempComments);
            // pour réinitialiser le formulaire
            setChoiceQualificationError(null);
            setChoiceQualificationRejection(null);
            setChoiceQualificationRecall(null);
            setChoiceQualification(null);
            setChoiceFreeFormation(null);
            setChoiceFormation1(null);
            setChoiceFormation2(null);
            setChoiceFormation3(null);
            setChoiceSessionFormation1(null);
            setChoiceSessionFormation2(null);
            setChoiceSessionFormation3(null);
            // setChoiceStartRelaunchDate(null);
            setDisplayDateRecall(false);
            setDisplayError(false);
            setDisplayRejection(false);
            setDisplayFreeFormation(false);
            setDisplayFormation1(false);
            setDisplayFormation2(false);
            setDisplayFormation3(false);
            setDisplaySessionFormation1(false);
            setDisplaySessionFormation2(false);
            setDisplaySessionFormation3(false);
            // const tempChosenSession = [...chosenSession];
            // if (values.choiceSessionFormation1 !== null) {
            //   tempChosenSession.push(values.choiceSessionFormation1);
            // }
            // setChosenSession(tempChosenSession);
          } catch (err) {
            console.error(err);
            toast.error('Il y a eu un souci lors de l\'enregistrement !');
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleSubmit, handleBlur, handleChange, setFieldValue, touched, values }) => (
          <form
            onSubmit={handleSubmit}
          >
            {commenting ? (
              <>
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    mb={2}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h6"
                    >
                      Laisser un commentaire
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    mt={1}
                  >
                    <Autocomplete
                      options={qualificationLabel}
                      onChange={(e, value) => {
                        handleChangeQualification(value);
                        setFieldValue(
                          'choiceQualification',
                          value !== null ? value : choiceQualification
                        );
                        if (value === null) {
                          values.choiceQualification = null;
                        }
                        setDisableChoiceSession1(true);
                        setDisableChoiceSession2(true);
                        setDisableChoiceSession3(true);
                      }}
                      value={values.choiceQualification}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Qualification"
                          name="choiceQualification"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  {displayError && (
                    <Grid
                      item
                      xs={2}
                      ml={2}
                      mt={1}
                    >
                      <Autocomplete
                        options={qualificationErrorLabel}
                        onChange={(e, value) => {
                          handleChangeQualificationError(value);
                          setFieldValue(
                            'choiceQualificationError',
                            value !== null ? value : choiceQualificationError
                          );
                        }}
                        value={values.choiceQualificationError}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Raison"
                            name="choiceQualificationError"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {displayRejection && (
                  <Grid
                    item
                    xs={2}
                    ml={2}
                    mt={1}
                  >
                    <Autocomplete
                      options={qualificationRejectionLabel}
                      onChange={(e, value) => {
                        handleChangeQualificationRejection(value);
                        setFieldValue(
                          'choiceQualificationRejection',
                          value !== null ? value : choiceQualificationRejection
                        );
                      }}
                      value={values.choiceQualificationRejection}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Raison"
                          name="choiceQualificationRejection"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  )}
                  {displayFormation1 && (
                    <Grid
                      item
                      xs={2}
                      ml={2}
                      mt={1}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={formations1}
                        onChange={(e, value) => {
                          setChoiceFormation1(value);
                          setFieldValue(
                            'choiceFormation1',
                            value !== null ? value : choiceFormation1
                          );
                          if (value !== null) {
                            setDisableChoiceSession1(false);
                            // console.log(value);
                            // setIndexFormation1(formations.indexOf(value));
                            setIndexFormation1(formations.findIndex((item) => item.id === value.id));
                          } else {
                            setDisableChoiceSession1(true);
                            setIndexFormation1(0);
                          }
                          if (value === null) {
                            values.choiceFormation1 = null;
                          }
                        }}
                        value={values.choiceFormation1}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Formation1"
                            name="choiceFormation1"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {displaySessionFormation1 && (
                    <Grid
                      item
                      xs={4}
                      ml={2}
                      mt={1}
                      fullWidth
                      maxWidth={300}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => `${option.number_session}. Début: ${moment(option.start_date).format('DD/MM/YYYY')}, Fin: ${moment(option.end_date).format('DD/MM/YYYY')} ${dateTodayStartOfDay.isSame(moment(option.first_turn_end_date_interval_15_days).clone().startOf('days')) ? 'Dernier jour pour cette session' : dateTodayStartOfDay > moment(option.first_turn_end_date_interval_15_days).clone().startOf('days') ? 'Date limite de vente dépassée' : ''}`}
                        // options={formationsFree}
                        // isOptionEqualToValue={(option, value) => option.id === value.id}
                        // getOptionLabel={(option) => (
                        //   <Box>
                        //     {option.number_session}
                        //     . Début:
                        //     {' '}
                        //     {moment(option.start_date).format('DD/MM/YYYY')}
                        //     , Fin:
                        //     {' '}
                        //     {moment(option.end_date).format('DD/MM/YYYY')}
                        //     {' '}
                        //     <Typography color="orange">{dateTodayStartOfDay.isSame(moment(option.first_turn_end_date_interval_15_days).clone().startOf('days')) ? 'Dernier jour pour cette session' : ''}</Typography>
                        //     <Typography color="red">{dateTodayStartOfDay > moment(option.first_turn_end_date_interval_15_days).clone().startOf('days') ? 'Date limite de vente dépassée' : ''}</Typography>
                        //   </Box>
                        // )}
                        options={formations[indexFormation1].sessions}
                        onChange={(e, value) => {
                          setChoiceSessionFormation1(value);
                          // console.log(value);
                          setFieldValue(
                            'choiceSessionFormation1',
                            value !== null ? value : choiceSessionFormation1
                          );
                          // const tempChosenSession = [...chosenSession];
                          // if (values.choiceSessionFormation1 !== null) {
                          //   tempChosenSession.push(values.choiceSessionFormation1);
                          // }
                          // setChosenSession(tempChosenSession);
                          if (value === null) {
                            values.choiceSessionFormation1 = null;
                          }
                        }}
                        value={values.choiceSessionFormation1}
                        disabled={disableChoiceSession1}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Session formation 1"
                            name="choiceSessionFormation1"
                            variant="outlined"
                            disabled={disableChoiceSession1}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {displayFormation2 && (
                    <Grid
                      item
                      xs={2}
                      ml={2}
                      mt={1}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={formations2}
                        onChange={(e, value) => {
                          setChoiceFormation2(value);
                          setFieldValue(
                            'choiceFormation2',
                            value !== null ? value : choiceFormation2
                          );
                          if (value !== null) {
                            setDisableChoiceSession2(false);
                            setIndexFormation2(formations.findIndex((item) => item.id === value.id));
                          } else {
                            setDisableChoiceSession2(true);
                            setIndexFormation2(0);
                          }
                          if (value === null) {
                            values.choiceFormation2 = null;
                          }
                        }}
                        value={values.choiceFormation2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Formation2"
                            name="choiceFormation2"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {displaySessionFormation2 && (
                    <Grid
                      item
                      xs={4}
                      ml={2}
                      mt={1}
                      fullWidth
                      maxWidth={300}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => `${option.number_session}. Début: ${moment(option.start_date).format('DD/MM/YYYY')}, Fin: ${moment(option.end_date).format('DD/MM/YYYY')} ${dateTodayStartOfDay.isSame(moment(option.first_turn_end_date_interval_15_days).clone().startOf('days')) ? 'Dernier jour pour cette session' : dateTodayStartOfDay > moment(option.first_turn_end_date_interval_15_days).clone().startOf('days') ? 'Date limite de vente dépassée' : ''}`}
                        // options={formationsFree}
                        // isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={formations[indexFormation2].sessions}
                        onChange={(e, value) => {
                          setChoiceSessionFormation2(value);
                          // console.log(value);
                          setFieldValue(
                            'choiceSessionFormation2',
                            value !== null ? value : choiceSessionFormation2
                          );
                          if (value === null) {
                            values.choiceSessionFormation2 = null;
                          }
                        }}
                        value={values.choiceSessionFormation2}
                        disabled={disableChoiceSession2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Session formation 2"
                            name="choiceSessionFormation2"
                            variant="outlined"
                            disabled={disableChoiceSession2}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {displayFormation3 && (
                    <Grid
                      item
                      xs={2}
                      mt={1}
                      // ml={4}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={formations3}
                        onChange={(e, value) => {
                          setChoiceFormation3(value);
                          setFieldValue(
                            'choiceFormation3',
                            value !== null ? value : choiceFormation3
                          );
                          if (value !== null) {
                            setDisableChoiceSession3(false);
                            setIndexFormation3(formations.findIndex((item) => item.id === value.id));
                          } else {
                            setDisableChoiceSession3(true);
                            setIndexFormation3(0);
                          }
                          if (value === null) {
                            values.choiceFormation3 = null;
                          }
                        }}
                        value={values.choiceFormation3}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Formation3"
                            name="choiceFormation3"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {displaySessionFormation3 && (
                    <Grid
                      item
                      xs={4}
                      ml={2}
                      mt={1}
                      fullWidth
                      maxWidth={300}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => `${option.number_session}. Début: ${moment(option.start_date).format('DD/MM/YYYY')}, Fin: ${moment(option.end_date).format('DD/MM/YYYY')} ${dateTodayStartOfDay.isSame(moment(option.first_turn_end_date_interval_15_days).clone().startOf('days')) ? 'Dernier jour pour cette session' : dateTodayStartOfDay > moment(option.first_turn_end_date_interval_15_days).clone().startOf('days') ? 'Date limite de vente dépassée' : ''}`}
                        // options={formationsFree}
                        // isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={formations[indexFormation3].sessions}
                        onChange={(e, value) => {
                          setChoiceSessionFormation3(value);
                          // console.log(value);
                          setFieldValue(
                            'choiceSessionFormation3',
                            value !== null ? value : choiceSessionFormation3
                          );
                          if (value === null) {
                            values.choiceSessionFormation3 = null;
                          }
                        }}
                        value={values.choiceSessionFormation3}
                        disabled={disableChoiceSession3}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Session formation 3"
                            name="choiceSessionFormation3"
                            variant="outlined"
                            disabled={disableChoiceSession3}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {displayStartRelaunchDate && (
                    <Grid
                      item
                      // xs={2}
                      ml={2}
                      mt={1}
                    >
                      <TextField
                        // error={Boolean(touched.startRelaunchDate && errors.startRelaunchDate)}
                        // fullWidth
                        // helperText={touched.startRelaunchDate && errors.startRelaunchDate}
                        label="Début de relance"
                        name="choiceStartRelaunchDate"
                        type="date"
                        // onBlur={handleBlur}
                        onChange={(e, value) => {
                          // handleChange();
                          // console.log(e.target.value);
                          if (e.target.value !== '') {
                            setChoiceStartRelaunchDate(moment(new Date(e.target.value)).format('YYYY-MM-DD'));
                          } else {
                            setChoiceStartRelaunchDate('');
                          }
                          setFieldValue(
                            'choiceStartRelaunchDate',
                            value !== null ? moment(new Date(e.target.value)).format('YYYY-MM-DD') : choiceStartRelaunchDate
                          );
                        }}
                        value={values.choiceStartRelaunchDate}
                        variant="outlined"
                        // pour éviter la superposition de jj/mm/aaaa et début de relance dans le champ
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                  {displayDateRecall && (
                  <Grid
                    item
                    // xs={2}
                    ml={2}
                    mt={1}
                  >
                    <TextField
                      error={Boolean(touched.choiceQualificationRecall && errors.choiceQualificationRecall)}
                      fullWidth
                      helperText={touched.choiceQualificationRecall && errors.choiceQualificationRecall}
                      label="Date et heure du rappel"
                      name="choiceQualificationRecall"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        handleChange(event);
                        setChoiceQualificationRecall(event.target.value);
                      }}
                      type="datetime-local"
                      value={values.choiceQualificationRecall}
                      variant="outlined"
                    // pour éviter la superposition de jj/mm/aaaa et date de naissance dans le champ
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  )}
                </Grid>
                {displayFreeFormation && (
                  <Box
                    xs={2}
                    // ml={2}
                    mt={1}
                    maxWidth={260}
                  >
                    <Autocomplete
                      getOptionLabel={(option) => option.name}
                      options={formationsFree}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(e, value) => {
                        setChoiceFreeFormation(value);
                        setFieldValue(
                          'choiceFreeFormation',
                          value !== null ? value : choiceFreeFormation
                        );
                      }}
                      value={values.choiceFreeFormation}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Formation offerte"
                          name="choiceFreeFormation"
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                )}
                {displayStartRelaunchDate && hasStartRelaunchDate && (
                  <Box mt={1}>
                    <Typography>
                      Attention, une date de début de suivi de relance est déjà saisie. Vous devriez peut-être la modifier.
                    </Typography>
                  </Box>
                )}
                <TextField
                  sx={{ mt: 2, width: 700 }}
                  label="Commentaire"
                  error={Boolean(touched.commentCommercial && errors.commentCommercial)}
                  fullWidth
                  helperText={touched.commentCommercial && errors.commentCommercial}
                  multiline
                  name="commentCommercial"
                  variant="filled"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.commentCommercial}
                  minRows={2}
                />
                <Grid
                  container
                  mt={1}
                  mb={1}
                >
                  <Grid
                    item
                    xs={2}
                  >
                    <Button
                      color="primary"
                      startIcon={<SaveAltIcon fontSize="small" />}
                      variant="contained"
                      disabled={isFormFilled}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Enregistrer
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <Button
                      color="primary"
                      startIcon={<CancelIcon fontSize="small" />}
                      variant="contained"
                      onClick={() => { setCommenting(!commenting); }}
                    >
                      Annuler
                    </Button>
                  </Grid>
                </Grid>
              </>
            )
              : (
                <Button
                  color="primary"
                  startIcon={<PencilAltIcon fontSize="small" />}
                  disabled={!isAuthorized}
                  sx={{ mt: 1, mb: 3 }}
                  variant="contained"
                  onClick={() => { setCommenting(!commenting); }}
                >
                  Commenter
                </Button>
              )}
          </form>
        )}
      </Formik>
      <Typography
        color="textPrimary"
        variant="h6"
      >
        Aujourd&apos;hui
      </Typography>
      <Box sx={{ mt: 3 }}>
        {todayItems.map((comment) => (
          <CustomerCommercialComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            reason={comment.reason}
            recallDate={comment.recall_date}
            formation1={comment.formation1}
            nameFormation1={comment.nameFormation1}
            formation2={comment.formation2}
            nameFormation2={comment.nameFormation2}
            formation3={comment.formation3}
            nameFormation3={comment.nameFormation3}
            offeredFormation={comment.offered_formation}
            nameOfferedFormation={comment.nameOfferedFormation}
          />
        ))}
      </Box>
      <Typography
        color="textPrimary"
        sx={{ mt: 3 }}
        variant="h6"
      >
        Cette semaine
      </Typography>
      <Box sx={{ mt: 3 }}>
        {thisWeekItems.map((comment) => (
          <CustomerCommercialComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            reason={comment.reason}
            recallDate={comment.recall_date}
            formation1={comment.formation1}
            nameFormation1={comment.nameFormation1}
            formation2={comment.formation2}
            nameFormation2={comment.nameFormation2}
            formation3={comment.formation3}
            nameFormation3={comment.nameFormation3}
            offeredFormation={comment.offered_formation}
            nameOfferedFormation={comment.nameOfferedFormation}
          />
        ))}
      </Box>
      <Typography
        color="textPrimary"
        sx={{ mt: 3 }}
        variant="h6"
      >
        Ce mois-ci
      </Typography>
      <Box sx={{ mt: 3 }}>
        {thisMonthItems.map((comment) => (
          <CustomerCommercialComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            reason={comment.reason}
            recallDate={comment.recall_date}
            formation1={comment.formation1}
            nameFormation1={comment.nameFormation1}
            formation2={comment.formation2}
            nameFormation2={comment.nameFormation2}
            formation3={comment.formation3}
            nameFormation3={comment.nameFormation3}
            offeredFormation={comment.offered_formation}
            nameOfferedFormation={comment.nameOfferedFormation}
          />
        ))}
      </Box>
      <Typography
        color="textPrimary"
        sx={{ mt: 3 }}
        variant="h6"
      >
        Plus ancien
      </Typography>
      <Box sx={{ mt: 3 }}>
        {ancientItems.map((comment) => (
          <CustomerCommercialComment
            id={comment.id}
            createdAt={comment.createdAt}
            source={`${comment.firstname} ${comment.lastname}`}
            comment={comment.comment}
            handleDelete={handleDelete}
            qualification={comment.qualification}
            reason={comment.reason}
            recallDate={comment.recall_date}
            formation1={comment.formation1}
            nameFormation1={comment.nameFormation1}
            formation2={comment.formation2}
            nameFormation2={comment.nameFormation2}
            formation3={comment.formation3}
            nameFormation3={comment.nameFormation3}
            offeredFormation={comment.offered_formation}
            nameOfferedFormation={comment.nameOfferedFormation}
          />
        ))}
      </Box>
    </div>
  );
};

CustomerCommercialActions.propTypes = {
  customerId: PropTypes.number.isRequired,
  user: PropTypes.array.isRequired,
  formations: PropTypes.array.isRequired,
  customer: PropTypes.array.isRequired
};

export default CustomerCommercialActions;
