import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Card, Typography, Grid,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as moment from 'moment';

const CampaignTime = (props) => {
  const theme = useTheme();
  const { timeToPass, averageTime, ...other } = props;
  const items = [
    {
      color: '#4CAF50',
      label: 'Total de temps passé',
      value: moment.utc(timeToPass * 1000).format('HH:mm:ss')
    },
    {
      color: '#FF9800',
      label: 'Moyenne de temps passé',
      // pour enlever les millisecondes
      value: Number.isNaN(averageTime) ? '00:00:00' : moment.utc(averageTime * 1000).format('HH:mm:ss')
    },
  ];

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    labels: ['TEMPS'],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
            color: theme.palette.mode === 'light' ? '#7EB627' : 'white',
            fontSize: '12px',
            fontWeight: 400,
            offsetY: 20
          },
          value: {
            color: theme.palette.text.primary,
            fontSize: '18px',
            fontWeight: 600,
            offsetY: -20,
            formatter(value) {
              return value;
            },
          },
        },
        hollow: {
          size: '60%'
        },
        track: {
          background: theme.palette.background.default
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  return (
    <Card {...other}>
      <Grid
        container
        spacing={3}
        sx={{ p: 3 }}
      >
        {items.map((item) => (
          <Grid
            item
            key={item.label}
            md={6}
            xs={12}
          >
            <Card
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                p: 2
              }}
              variant="outlined"
            >
              <Typography
                sx={{ color: item.color }}
                variant="h6"
              >
                {item.label}
              </Typography>
              <Chart
                height={230}
                options={{
                  ...chartOptions,
                  colors: [item.color],
                  legend: {
                    display: false,
                  }
                }}
                series={[item.value]}
                type="radialBar"
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
CampaignTime.propTypes = {
  timeToPass: PropTypes.number.isRequired,
  averageTime: PropTypes.number.isRequired,
};

export default CampaignTime;
