/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  Card,
  Divider,
  TablePagination,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  Link,
  Avatar,
  CircularProgress
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import { campaignApi } from '../../api/campaignApi';
import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Scrollbar from '../Scrollbar';
import useMounted from '../../hooks/useMounted';
import SearchIcon from '../../icons/Search';
import * as moment from 'moment';

const tabs = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Médecins',
    value: 'Médecin'
  },
  {
    label: 'Infirmiers',
    value: 'Infirmier'
  },
  {
    label: 'Kinés',
    value: 'Masseur-kinésithérapeute'
  },
  {
    label: 'Podologues',
    value: 'Pédicure-podologue'
  },
  {
    label: 'Pharmaciens',
    value: 'Pharmacien'
  },
  {
    label: 'Sages-Femmes',
    value: 'Sage-Femme'
  }
];

const newCallTrackOptions = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Sans statut',
    value: 'empty'
  },
  {
    label: 'Vente 1 formation',
    value: 'Vente 1 formation'
  },
  {
    label: 'Vente 2 formation',
    value: 'Vente 2 formation'
  },
  {
    label: 'Vente 3 formation',
    value: 'Vente 3 formation'
  },
  {
    label: 'Déjà validé',
    value: 'Déjà validé'
  },
  {
    label: 'Souhaite du présentiel',
    value: 'Souhaite du présentiel'
  },
  {
    label: 'Aucun thème voulu',
    value: 'Aucun thème voulu'
  },
  {
    label: 'Date de session trop proche',
    value: 'Date de session trop proche'
  },
  {
    label: 'Préfère un autre organisme',
    value: 'Préfère un autre organisme'
  },
  {
    label: 'Retraite',
    value: 'Retraite'
  },
  {
    label: 'Numéro incorrect',
    value: 'Numéro incorrect'
  },
  {
    label: 'Non professionnel de santé',
    value: 'Non professionnel de santé'
  },
  {
    label: 'Souhaite un email',
    value: 'Souhaite un email'
  },
  {
    label: 'Rappel',
    value: 'Rappel'
  },
  {
    label: 'Attente',
    value: 'Attente'
  },
  {
    label: 'Appel entrant',
    value: 'Appel entrant'
  },
  {
    label: 'Pas répondu',
    value: 'Pas répondu'
  },
];

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const applyFilters = (customers, query, filters, campaign, newCallTrack) => customers
  .filter((customer) => {
    const matches = true;

    return matches;
  });

const sortOptions = [
  {
    label: 'Nom (A - Z)',
    value: 'lastname|asc'
  },
  {
    label: 'Nom (Z - A)',
    value: 'lastname|desc'
  },
  {
    label: 'Code postal (A - Z)',
    value: 'zip|asc'
  },
  {
    label: 'Code postal (Z - A)',
    value: 'zip|desc'
  }
];

const CampaignLearner = () => {
  const mounted = useMounted();
  const { idCampaign } = useParams();
  const [page, setPage] = useState(0);
  const [choiceTabProfession, setChoiceTabProfession] = useState('');
  const [choiceSort, setChoiceSort] = useState('');
  const { user } = useAuth();
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(25);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [learnerCampaign, setLearnerCampaign] = useState([]);
  const [currentTab, setCurrentTab] = useState('all');
  const [usersCampaign, setUsersCampaign] = useState([]);
  const [numberProspects, setNumberProspects] = useState(0);
  const [numberProspectsTreated, setNumberProspectsTreated] = useState(0);
  const [numberProspectsForDatagridCommercial, setNumberProspectsForDatagridCommercial] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getCampaignInfo = useCallback(async () => {
    try {
      const data = await campaignApi.getCampaignInfo(idCampaign, localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }
      if (mounted.current) {
        setLearnerCampaign(data.learnerCampaign);
        setUsersCampaign(data.usersCampaign);
        setNumberProspects(data.numberProspects === null || data.numberProspects === undefined ? 0 : data.numberProspects.number);
        setNumberProspectsTreated(data.numberProspectsTreated.number);
        setNumberProspectsForDatagridCommercial(data.numberProspectsForDatagridCommercial === undefined || data.numberProspectsForDatagridCommercial === null ? 0 : data.numberProspectsForDatagridCommercial.number);
        let tempChoiceTabProfession = window.localStorage.getItem('choiceTabProfession');
        tempChoiceTabProfession = tempChoiceTabProfession === null ? '' : window.localStorage.getItem('choiceTabProfession').replaceAll('"', '');
        setChoiceTabProfession(tempChoiceTabProfession);
        let tempChoiceSort = window.localStorage.getItem('choiceSort');
        tempChoiceSort = tempChoiceSort === null ? '' : window.localStorage.getItem('choiceSort').replaceAll('"', '');
        setChoiceSort(tempChoiceSort);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCampaignInfo();
  }, [getCampaignInfo]);

  function initCampaign() {
    const camp = {};
    usersCampaign.forEach((element) => {
      camp[element.id] = null;
    });
    camp.empty = null;
    return camp;
  }

  const [campaign, setCampaign] = useState(initCampaign);
  const [newCallTrack, setNewCallTrack] = useState({
    empty: null,
    'Vente 1 formation': null,
    'Vente 2 formation': null,
    'Vente 3 formation': null,
    'Déjà validé': null,
    'Souhaite du présentiel': null,
    'Aucun thème voulu': null,
    'Date de session trop proche': null,
    'Préfère un autre organisme': null,
    Retraite: null,
    'Numéro incorrect': null,
    'Non professionnel de santé': null,
    'Souhaite un email': null,
    Rappel: null,
    Attente: null,
    'Appel entrant': null,
    'Pas répondu': null
  });
  const [currentNewCallTrack, setCurrentNewCallTrack] = useState('all');
  const [currentCampaign, setCurrentCampaign] = useState('all');

  const usersCampaignList = [...usersCampaign];
  usersCampaignList.unshift({ firstname: 'Tous', lastname: '', id: 'all' });

  const [filters, setFilters] = useState({
    Médecin: null,
    Infirmier: null,
    'Masseur-kinésithérapeute': null,
    'Pédicure-podologue': null,
    Pharmacien: null,
    'Sage-Femme': null
  });

  const handleCustomers = (async (querySearch, campaignChange, newCallTrackChange, tabProfession, pageChoice, limitChoice) => {
    try {
      setIsLoading(true);
      const data = await campaignApi.getCampaignInfo(idCampaign, localStorage.getItem('accessToken'), querySearch, campaignChange, newCallTrackChange, tabProfession, pageChoice, limitChoice);
      setLearnerCampaign(data.learnerCampaign);
      setNumberProspects(data.numberProspects === null || data.numberProspects === undefined ? 0 : data.numberProspects.number);
      setNumberProspectsTreated(data.numberProspectsTreated === null ? 0 : data.numberProspectsTreated.number);
      setNumberProspectsForDatagridCommercial(data.numberProspectsForDatagridCommercial === null || data.numberProspectsForDatagridCommercial === undefined ? 0 : data.numberProspectsForDatagridCommercial.number);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  });

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    // pour que la recherche dans le champ "chercher un apprenant" ne se fasse qu'à partir de 3 caractères tapés
    if (event.target.value.length >= 3) {
      handleCustomers(event.target.value, campaign, newCallTrack, filters, page, limit);
    }
    // pour que la recherche dans le champ "chercher un apprenant" se réinitialise à 0 caractère
    if (event.target.value.length === 0) {
      handleCustomers('', campaign, newCallTrack, filters, page, limit);
    }
    // pour remettre à la première page de résultats quand on fait une recherche
    setPage(0);
  };

  const handleNewCallTrackChange = (event, value) => {
    const updatedNewCallTrack = {
      ...newCallTrack,
      empty: null,
      'Vente 1 formation': null,
      'Vente 2 formation': null,
      'Vente 3 formation': null,
      'Déjà validé': null,
      'Souhaite du présentiel': null,
      'Aucun thème voulu': null,
      'Date de session trop proche': null,
      'Préfère un autre organisme': null,
      Retraite: null,
      'Numéro incorrect': null,
      'Non professionnel de santé': null,
      'Souhaite un email': null,
      Rappel: null,
      Attente: null,
      'Appel entrant': null,
      'Pas répondu': null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedNewCallTrack[value] = true;
      }

      setNewCallTrack(updatedNewCallTrack);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(value);
    } else {
      if (event.target.value !== 'all') {
        updatedNewCallTrack[event.target.value] = true;
      }
      setNewCallTrack(event.target.value);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(event.target.value);
    }
    handleCustomers(query, campaign, event.target.value, filters, page, limit);
    setPage(0);
  };

  const handleCampaignChange = (event, value) => {
    const tempCampaign = {};
    usersCampaign.forEach((element) => {
      tempCampaign[element.id] = null;
    });
    const updatedCampaign = {
      ...campaign,
      ...tempCampaign,
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCampaign[value] = true;
      }

      setCampaign(updatedCampaign);
      setSelectedCustomers([]);
      setCurrentCampaign(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCampaign[event.target.value] = true;
      }
      setCampaign(event.target.value);
      setSelectedCustomers([]);
      setCurrentCampaign(event.target.value);
    }
    setPage(0);
    handleCustomers(query, event.target.value, newCallTrack, filters, page, limit);
  };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      Médecin: null,
      Infirmier: null,
      'Masseur-kinésithérapeute': null,
      'Pédicure-podologue': null,
      Pharmacien: null,
      'Sage-Femme': null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }
    setFilters(value);
    setSelectedCustomers([]);
    setCurrentTab(value);
    setPage(0);
    handleCustomers(query, campaign, newCallTrack, value, page, limit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleCustomers(query, campaign, newCallTrack, filters, newPage, limit);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    handleCustomers(query, campaign, newCallTrack, filters, page, parseInt(event.target.value, 10));
  };

  const filteredCustomers = applyFilters(learnerCampaign, query, filters, campaign, newCallTrack);
  const sortedCustomers = applySort(filteredCustomers, sort);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',

      }}
    >
      <Card>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            m: -1,
            p: 2
          }}
        >
          <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 500
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder="Chercher un apprenant"
              value={query}
              variant="outlined"
            />
          </Box>
          {user.access_level === 0 && (
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              label="Campagne"
              name="campaign"
              onChange={handleCampaignChange}
              select
              SelectProps={{ native: true }}
              value={currentCampaign}
              variant="outlined"
            >
              {usersCampaignList.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {`${option.firstname} ${option.lastname}`}
                </option>
              ))}
            </TextField>
          </Box>
          )}
          <Box
            sx={{
              m: 1,
              width: 240
            }}
          >
            <TextField
              label="Nouveau statut d'appel"
              name="newCallTrack"
              onChange={handleNewCallTrackChange}
              select
              SelectProps={{ native: true }}
              value={currentNewCallTrack}
              variant="outlined"
            >
              {newCallTrackOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
        <Scrollbar>
          <Box sx={{ minWidth: 900 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nom Prénom
                  </TableCell>
                  <TableCell>
                    Profession
                  </TableCell>
                  {user.access_level === 0 && (
                  <TableCell>
                    Campagne
                  </TableCell>
                  )}
                  <TableCell>
                    Dernier statut
                  </TableCell>
                  <TableCell>
                    Dernier commentaire
                  </TableCell>
                  <TableCell>
                    Temps
                  </TableCell>
                </TableRow>
              </TableHead>
              {isLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <TableBody>
                  {learnerCampaign.map((link) => (
                    <TableRow
                      key={link.id}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Avatar
                            src={link.avatar}
                            sx={{
                              height: 42,
                              width: 42
                            }}
                            {...stringAvatar(`${link.firstname.replace(/ /g, '')} ${link.lastname.replace(/ /g, '')}`)}
                          />
                          <Box sx={{ ml: 1 }}>
                            <Link
                              color={link.treated === 1 ? '#7EB627' : 'inherit'}
                              component={RouterLink}
                              to={`/dashboard/customers/${link.id}`}
                              variant="subtitle2"
                            >
                              {link.firstname}
                              {' '}
                              {link.lastname}
                            </Link>
                            <Typography
                              color="textSecondary"
                              variant="body2"
                            >
                              {link.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex'
                          }}
                        >
                          <Typography>
                            {link.profession}
                          </Typography>
                        </Box>
                      </TableCell>
                      {user.access_level === 0 && (
                      <TableCell>
                        <Grid
                          item
                          sx={{ m: 1 }}
                        >
                          {`${link.nom} ${link.prenom}`}
                        </Grid>
                      </TableCell>
                      )}
                      <TableCell>
                        {link.last_status}
                      </TableCell>
                      <TableCell>
                        {link.last_comment}
                      </TableCell>
                      <TableCell>
                        {moment.utc(link.spent_time * 1000).format('HH:mm:ss')}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={user.access_level === 0 ? numberProspects : numberProspectsForDatagridCommercial}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[15, 25, 50]}
          SelectProps={{
            disabled: isLoading
          }}
          backIconButtonProps={
            isLoading
              ? { disabled: isLoading } : undefined
          }
          nextIconButtonProps={
            isLoading
              ? { disabled: isLoading } : undefined
          }
        />
      </Card>
    </Box>
  );
};

export default CampaignLearner;
