/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import partnerSVG from '../../assets/partner.svg';
import React, { useCallback, useState, useEffect, useContext } from 'react';
import DeliveriesDialog from './DeliveriesDialog';
import { getCurrentYear, getDateToLocalStringFR } from '../../utils/dateFormatting';
import PartnerFormDialog from './PartnerFormDialog';
import DeletePartnerDialog from './DeletePartnerDialog';

import { deliveryApi } from '../../api/partnerApi';

import { DeliveriesContext } from '../../contexts/PartnerContext';
import { Box } from '@mui/material';

export default function PartnerCard(props) {
  const { partner } = props;
  const startContract = getDateToLocalStringFR(partner.start_contract);
  const endContract = getDateToLocalStringFR(partner.end_contract);

  const [currentTotalPartner, setCurrentTotalPartner] = useState(0);
  const [currentTotalApteed, setCurrentTotalApteed] = useState(0);

  const { deliveries } = useContext(DeliveriesContext);

  const getCurrentYearTotals = useCallback(async (partnerId) => {
    try {
      const prices = await deliveryApi.getCurrentYearTotals(window.localStorage.getItem('accessToken'), partnerId);
      setCurrentTotalPartner(Math.round(prices.total_partner * 100) / 100);
      setCurrentTotalApteed(Math.round(prices.total_apteed * 100) / 100);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getCurrentYearTotals(partner.id);
  }, [deliveries]);

  return (
    <Grid
      item
      xs={12}
      md={6}
    >
      <CardActionArea
        component="a"
        href="#"
      >
        <Card sx={{ display: 'flex', p: 0.7, minHeight: '240px' }}>
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: 'block' }, borderBottomLeftRadius: '12px', borderTopLeftRadius: '12px' }}
            image={partnerSVG}
            alt="Image du partenaire"
          />
          <CardContent sx={{ flex: 1 }}>
            <Typography
              component="h2"
              variant="h5"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {partner.name}
              <Grid sx={{ display: 'flex' }}>
                <PartnerFormDialog
                  editMode
                  partnerId={partner.id}
                />
                <DeletePartnerDialog
                  partnerId={partner.id}
                  partnerName={partner.name}
                />
              </Grid>
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ fontSize: 14 }}
            >
              {partner.siret}
            </Typography>
            <Typography
              variant="subtitle1"
              color="dark"
            >
              Du
              {' '}
              <b>{startContract}</b>
              {' '}
              au
              {' '}
              <b>{endContract}</b>
            </Typography>
            <Box 
              sx={{ 
                display: "flex", 
                flexDirection: "column",
                gap: 2,
                mt: 2,
                justifyContent: !((currentTotalApteed !== null && currentTotalApteed !== 0) || (currentTotalPartner !== null && currentTotalPartner !== 0)) && "center",
                minHeight: "110px"
              }}>
              <Box>
                <DeliveriesDialog partner={partner} />
              </Box>
              {((currentTotalApteed !== null && currentTotalApteed !== 0)
                  || (currentTotalPartner !== null && currentTotalPartner !== 0)) && (
                  <Grid
                    container
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      Total des prestations en
                      {' '}
                      <b>{getCurrentYear()}</b>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      {currentTotalPartner !== null
                        && currentTotalPartner !== 0 && (
                          <Grid
                            item
                            xs={6}
                            sx={{ textAlign: 'start' }}
                          >
                            vers
                            {' '}
                            <b>{partner.name}</b>
                            {' '}
                            :
                            {' '}
                            {`${currentTotalPartner}€`}
                          </Grid>
                      )}
                      {currentTotalApteed !== null && currentTotalApteed !== 0 && (
                        <Grid
                          item
                          xs={6}
                          sx={{ textAlign: 'end' }}
                        >
                          vers
                          {' '}
                          <b>Apteed</b>
                          {' '}
                          :
                          {' '}
                          {`${currentTotalApteed}€`}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
              )}
            </Box>
          </CardContent>
        </Card>
      </CardActionArea>
    </Grid>
  );
}
