/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as moment from 'moment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PanToolIcon from '@mui/icons-material/PanTool';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import SmsIcon from '@mui/icons-material/Sms';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CustomerProInfos from './CustomerProInfos';

const CustomerContactProDetails = (props) => {
  const { profession, specialite, modeOfExercise, adeliRpps, numSecu, mdpMondpc, mdpFifpl, siret, comment_commercial, comment_relance, antecedents, remplacant, notCall, startRelaunchDate, editing,
    // eslint-disable-next-line react/prop-types
    errors, handleBlur, handleChange, touched, values, setFieldValue, callTrack, callTrackRelance, callTrackRelanceList, ...other } = props;
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  let qualification = '';
  let reason = '';
  let recallDate = '';
  let createdAt = '';
  let comment = '';
  let nameFormation1 = '';
  let nameFormation2 = '';
  let nameFormation3 = '';
  let nameOfferedFormation = '';
  let nameFormation1Transf = '';
  let nameFormation2Transf = '';
  let nameFormation3Transf = '';
  let nameOfferedFormationTransf = '';
  if (comment_commercial) {
    qualification = comment_commercial.qualification;
    reason = comment_commercial.reason;
    recallDate = comment_commercial.recallDate;
    createdAt = comment_commercial.createdAt;
    comment = comment_commercial.comment;
    nameFormation1 = comment_commercial.nameFormation1;
    nameFormation2 = comment_commercial.nameFormation2;
    nameFormation3 = comment_commercial.nameFormation3;
    nameOfferedFormation = comment_commercial.nameOfferedFormation;
    nameFormation1Transf = '';
    nameFormation2Transf = '';
    nameFormation3Transf = '';
    nameOfferedFormationTransf = '';
    if (nameFormation1 !== null && nameFormation1 !== undefined) {
      if (nameFormation1.length > 50) {
        nameFormation1Transf = `${nameFormation1.substring(0, 50)}...`;
      } else {
        nameFormation1Transf = nameFormation1;
      }
    }
    if (nameFormation2 !== null && nameFormation2 !== undefined) {
      if (nameFormation2.length > 50) {
        nameFormation2Transf = `${nameFormation2.substring(0, 50)}...`;
      } else {
        nameFormation2Transf = nameFormation2;
      }
    }
    if (nameFormation3 !== null && nameFormation3 !== undefined) {
      if (nameFormation3.length > 50) {
        nameFormation3Transf = `${nameFormation3.substring(0, 50)}...`;
      } else {
        nameFormation3Transf = nameFormation3;
      }
    }
    if (nameOfferedFormation !== null && nameOfferedFormation !== undefined) {
      if (nameOfferedFormation.length > 40) {
        nameOfferedFormationTransf = `${nameOfferedFormation.substring(0, 40)}...`;
      } else {
        nameOfferedFormationTransf = nameOfferedFormation;
      }
    }
  }

  return (
    <div>
      <Card {...other}>
        <CardHeader title="Informations professionnelles" />
        <Divider />
        <CustomerProInfos
          remplacant={remplacant}
          notCall={notCall}
          mdpFifpl={mdpFifpl}
          mdpMondpc={mdpMondpc}
          editing={editing}
          errors={errors}
          touched={touched}
          callTrackRelanceList={callTrackRelanceList}
          callTrackRelance={callTrackRelance}
          callTrack={callTrack}
          setFieldValue={setFieldValue}
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          siret={siret}
          adeliRpps={adeliRpps}
          modeOfExercise={modeOfExercise}
          specialite={specialite}
          profession={profession}
          startRelaunchDate={startRelaunchDate}
        />
      </Card>
      <Card
        sx="margin-top:20px;
        min-height: 195px;"
      >
        <CardHeader title="Dernier commentaire" />
        <Divider />
        <CardContent>
          {comment_commercial && (
            <>
              <Typography>
                Commercial
              </Typography>
              <Divider />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...other}
              >
                <Avatar
                  sx={{
                    color: 'common.white'
                  }}
                >
                  {(qualification === 'Vente 1 formation' || qualification === 'Vente 2 formation' || qualification === 'Vente 3 formation') && (
                  <AttachMoneyIcon style={{ color: '#16B84E' }} />
                  )}
                  {qualification === 'Erreur' && (
                  <ErrorOutlineIcon style={{ color: 'white' }} />
                  )}
                  {qualification === 'Rappel' && (
                  <PhoneIcon style={{ color: 'orange' }} />
                  )}
                  {qualification === 'Souhaite un email' && (
                  <MailOutlineIcon style={{ color: 'purple' }} />
                  )}
                  {qualification === 'Refus' && (
                  <PanToolIcon style={{ color: '#ff5f52' }} />
                  )}
                  {qualification === 'Attente' && (
                  <HourglassEmptyIcon style={{ color: '#ffe436' }} />
                  )}
                  {qualification === 'Appel entrant' && (
                  <PhoneCallbackIcon style={{ color: 'blue' }} />
                  )}
                  {qualification === 'Pas répondu' && (
                  <PhoneMissedIcon style={{ color: 'black' }} />
                  )}
                </Avatar>
                <Typography
                  color="textPrimary"
                  sx={{ ml: 2 }}
                  variant="body2"
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {qualification !== 'Ancien commentaire' ? `${comment_commercial.firstname} ${comment_commercial.lastname}` : ''}
                  </Typography>
                  <Typography
                    style={{ margin: 'auto', padding: 'auto' }}
                    color={(qualification === 'Vente 1 formation' || qualification === 'Vente 2 formation' || qualification === 'Vente 3 formation') ? '#16B84E' : qualification === 'Refus' ? '#ff5f52' : qualification === 'Erreur' ? '#888888' : qualification === 'Rappel' ? 'orange' : qualification === 'Souhaite un email' ? '#9a68af' : qualification === 'Attente' ? '#d7b52c' : qualification === 'Appel entrant' ? '#1E90FF' : qualification === 'Pas répondu' ? '#888888' : 'black'}
                    variant="subtitle2"
                  >
                    {`${qualification} ${reason === null ? '' : reason}${recallDate === undefined || recallDate === null ? '' : moment(recallDate).format('DD/MM/YYYY à H:mm')}`}

                  </Typography>
                  <Typography fontSize={14}>
                    {nameFormation1 !== null && nameFormation1 !== undefined ? '1) ' : ''}
                    {nameFormation1 !== undefined ? nameFormation1Transf : ''}
                  </Typography>
                  <Typography fontSize={14}>
                    {nameFormation2 !== null && nameFormation2 !== undefined ? '2) ' : ''}
                    {nameFormation2 !== undefined ? nameFormation2Transf : ''}
                  </Typography>
                  <Typography fontSize={14}>
                    {nameFormation3 !== null && nameFormation3 !== undefined ? '3) ' : ''}
                    {nameFormation3 !== undefined ? nameFormation3Transf : ''}
                  </Typography>
                  <Typography fontSize={14}>
                    {nameOfferedFormation !== null && nameOfferedFormation !== undefined ? `Offerte : ${nameOfferedFormationTransf}` : ''}
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontStyle="italic"
                  >
                    {comment}
                  </Typography>
                </Typography>

                <Typography
                  color="textPrimary"
                  sx={{ ml: 'auto' }}
                  variant="caption"
                >
                  {new Date(createdAt).toLocaleDateString('fr-FR', options)}
                </Typography>
              </Box>

            </>
          )}
        </CardContent>
        <CardContent>
          {comment_relance && (
            <>
              <Typography>
                Relance
              </Typography>
              <Divider />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...other}
              >
                <Avatar
                  sx={{
                    color: 'common.white'
                  }}
                >
                  {comment_relance.qualification_relance === 'Suivi - Mail' && (
                  <MailOutlineIcon style={{ color: '#16B84E' }} />
                  )}
                  {comment_relance.qualification_relance === 'Suivi - SMS' && (
                  <SmsIcon style={{ color: '#16B84E' }} />
                  )}
                  {comment_relance.qualification_relance === 'Suivi - Téléphone' && (
                  <PhoneAndroidIcon style={{ color: '#16B84E' }} />
                  )}
                  {comment_relance.qualification_relance === 'Décalage' && (
                  <CalendarTodayIcon style={{ color: 'white' }} />
                  )}
                  {comment_relance.qualification_relance === 'À rappeler' && (
                  <PhoneIcon style={{ color: 'orange' }} />
                  )}
                  {comment_relance.qualification_relance === 'Souhaite un email' && (
                  <MailOutlineIcon style={{ color: 'purple' }} />
                  )}
                  {comment_relance.qualification_relance === 'Annulation' && (
                  <HighlightOffIcon style={{ color: '#ff5f52' }} />
                  )}
                  {comment_relance.qualification_relance === 'Va se connecter' && (
                  <HourglassEmptyIcon style={{ color: '#ffe436' }} />
                  )}
                  {comment_relance.qualification_relance === 'Numéro incorrect' && (
                  <PhoneDisabledIcon style={{ color: 'blue' }} />
                  )}
                  {comment_relance.qualification_relance === 'Pas répondu' && (
                  <PhoneMissedIcon style={{ color: 'black' }} />
                  )}
                </Avatar>
                <Typography
                  color="textPrimary"
                  sx={{ ml: 2 }}
                  variant="body2"
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {`${comment_relance.firstname} ${comment_relance.lastname}`}
                  </Typography>
                  <Typography
                    style={{ margin: 'auto', padding: 'auto' }}
                    color={(comment_relance.qualification_relance === 'Suivi - Mail' || comment_relance.qualification_relance === 'Suivi - SMS' || comment_relance.qualification_relance === 'Suivi - Téléphone') ? '#16B84E' : comment_relance.qualification_relance === 'Annulation' ? '#ff5f52' : comment_relance.qualification_relance === 'Décalage' ? '#888888' : comment_relance.qualification_relance === 'À rappeler' ? 'orange' : comment_relance.qualification_relance === 'Souhaite un email' ? '#9a68af' : comment_relance.qualification_relance === 'Va se connecter' ? '#d7b52c' : comment_relance.qualification_relance === 'Numéro incorrect' ? '#1E90FF' : comment_relance.qualification_relance === 'Pas répondu' ? '#888888' : 'black'}
                    variant="subtitle2"
                  >
                    {`${comment_relance.qualification_relance} ${comment_relance.recall_date === undefined || comment_relance.recall_date === null ? '' : moment(comment_relance.recall_date).format('DD/MM/YYYY à H:mm')}`}
                  </Typography>
                  {comment_relance.comment}
                </Typography>
                <Typography
                  color="textPrimary"
                  sx={{ ml: 'auto' }}
                  variant="caption"
                >
                  {new Date(comment_relance.createdAt).toLocaleDateString('fr-FR', options)}
                </Typography>
              </Box>

            </>
          )}
        </CardContent>
      </Card>
      <Card
        sx="margin-top:20px;
        min-height: 195px;"
      >
        <CardHeader title="Antécédents" />
        <Divider />
        <CardContent>
          {editing ? (
            <TextareaAutosize
              maxRows={4}
              aria-label="maximum height"
              placeholder="Entrer des antécédents"
              name="antecedents"
              style={{
                width: '100%',
                height: '150px'
              }}
              defaultValue={antecedents}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          ) : antecedents}
        </CardContent>
      </Card>
    </div>
  );
};

CustomerContactProDetails.propTypes = {
  profession: PropTypes.string.isRequired,
  specialite: PropTypes.string.isRequired,
  modeOfExercise: PropTypes.string.isRequired,
  adeliRpps: PropTypes.string,
  numSecu: PropTypes.string,
  mdpMondpc: PropTypes.string,
  mdpFifpl: PropTypes.string,
  siret: PropTypes.string,
  comment_commercial: PropTypes.object,
  comment_relance: PropTypes.object,
  callTrack: PropTypes.string,
  callTrackRelance: PropTypes.string,
  callTrackRelanceList: PropTypes.array,
  antecedents: PropTypes.string,
  remplacant: PropTypes.bool,
  notCall: PropTypes.string,
  startRelaunchDate: PropTypes.string,
  editing: PropTypes.bool,
  touched: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.object
};

export default CustomerContactProDetails;
