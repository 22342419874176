/* eslint-disable radix */
/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Skeleton } from '@material-ui/core';

// eslint-disable-next-line no-sequences
const getPrimeValue = (sales, percent) => (sales[0] * (percent / 100));

// eslint-disable-next-line react/prop-types
const PrimeWidget = ({ type, loading, title, salesAmount, lastSalesAmount, palierPercentage, lastMonthPalierPercentage, palier }) => (
  <>
    {type === 'primes'
      ? (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
        >
          <Box gridColumn="span 12">
            {!loading ? (
              <Typography
                color="textSecondary"
                variant="overline"
              >
                {title}
              </Typography>
            ) : (
              <Skeleton
                variant="text"
                animation="wave"
                width={100}
              />
            )}
          </Box>
          {palier.step && (
            <Box gridColumn="span 12">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                {!loading ? (
                  <>
                    <Typography
                      color="textSecondary"
                      variant="overline"
                      xs={{ display: 'flex' }}
                    >
                      Palier
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography
                      color="textPrimary"
                      variant="overline"
                      xs={{ display: 'flex' }}
                    >
                      {palier.step}
                    </Typography>
                  </>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </Grid>
            </Box>
          )}
          <Box gridColumn="span 12">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {!loading ? (
                <>
                  <Typography
                    color="textPrimary"
                    variant="h6"
                    xs={{ display: 'flex' }}
                  >
                    {(salesAmount && palierPercentage) ? `${getPrimeValue(salesAmount, palierPercentage)}` : 'Pas de primes comptabilisées'}
                  </Typography>
                </>
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                />
              )}
            </Grid>
          </Box>
          <Box gridColumn="span 12">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {!loading ? (
                <>
                  <Typography
                    color="textPrimary"
                    variant="textSeconday"
                    xs={{ display: 'flex' }}
                  >
                    {palier ? palier.step : 'Pas de primes comptabilisées'}
                  </Typography>
                </>
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                />
              )}
            </Grid>
          </Box>
        </Box>
      )
      : (
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
        >
          <Box gridColumn="span 12">
            {!loading ? (
              <Typography
                color="textSecondary"
                variant="overline"
              >
                {title}
              </Typography>
            ) : (
              <Skeleton
                variant="text"
                animation="wave"
                width={100}
              />
            )}
          </Box>
          <Box gridColumn="span 12">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {!loading ? (
                <>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                    xs={{ display: 'flex' }}
                  >
                    Objectif financier
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography
                    color="textPrimary"
                    variant="overline"
                    xs={{ display: 'flex' }}
                  >
                    25 000 €
                  </Typography>
                </>
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                />
              )}
            </Grid>
          </Box>
          <Box gridColumn="span 12">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {!loading ? (
                <>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                    xs={{ display: 'flex' }}
                  >
                    Valeur de prime
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography
                    color="textPrimary"
                    variant="overline"
                    xs={{ display: 'flex' }}
                  >
                    2.5 %
                  </Typography>
                </>
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                />
              )}
            </Grid>
          </Box>
          <Box gridColumn="span 12">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {!loading ? (
                <>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                    xs={{ display: 'flex' }}
                  >
                    Reste
                  </Typography>
                  &nbsp;&nbsp;
                  <Typography
                    color="textPrimary"
                    variant="overline"
                    xs={{ display: 'flex' }}
                  >
                    6520 €
                  </Typography>
                </>
              ) : (
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={100}
                />
              )}
            </Grid>
          </Box>
        </Box>
      )}
  </>
);

export default PrimeWidget;
