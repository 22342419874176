import { React, useEffect, useState } from 'react';
import { MobileDateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { ticketApi } from '../../../api/ticketApi';

import { Warning } from '@material-ui/icons';
import useSettings from '../../../hooks/useSettings';
import { createCustomTheme } from '../../../theme';
import TicketsMajorIncident from './TicketsMajorIncident';
import Scrollbar from '../../Scrollbar';
import FilesMajorIncident from './FilesMajorIncident';
import { majorIncidentApi } from '../../../api/majorIncidentApi';


const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const initialMajorIncident = {
  startDate: null,
  description: '',
  solution: ''
}

const FormMajorIncident = (props) => {
  const [show, setShow] = useState(false);
  const { user, update, setUpdate, serviceTab, isListTickets, listToggle, setCurrentTab } = props;
  const [service, setService] = useState(null);
  const [serviceChoice, setServiceChoice] = useState(serviceTab);  
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const listServices = [
    'Direction',
    'Informatique', 
    'Commercial',
    'Administratif'
  ];
  const [majorIncident, setMajorIncident] = useState(initialMajorIncident)
  const { settings } = useSettings();
  const theme = createCustomTheme({
    ...settings
  });
  const [savedAttachments, setSavedAttachments] = useState([])

  initialMajorIncident.startDate = new Date();
  
    
  const getServiceByName = async (serviceName) => {
    try {
      const serviceData = await ticketApi.getServiceByName(localStorage.getItem('accessToken'), serviceName);
      setService(serviceData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleText = (event) => {
    setMajorIncident({ ...majorIncident, [event.target.name]: event.target.value});
  };
  

  const handleClose = () => {
    setMajorIncident(initialMajorIncident);
    setIsLoading(false)
    setTickets([]);
    setSavedAttachments([]);
    setShow(false);
    setUpdate(!update); 
  };

  const handleChange = (e) => {
    setServiceChoice(e.target.value);
    getServiceByName(e.target.value);
  };

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === 'backdropClick') { 
      handleClose()
    }
  };

  const createMajorIncident = async () => {
    try {
      setIsLoading(true)
      const newMajorIncident = await majorIncidentApi.createMajorIncident(localStorage.getItem('accessToken'), service.id, majorIncident.description, majorIncident.startDate, majorIncident.solution, tickets);
      if (savedAttachments.length > 0 && newMajorIncident.id) {
        await majorIncidentApi.sendDocMajorIncident(newMajorIncident.id, user.id, savedAttachments, localStorage.getItem('accessToken'))
        .catch(error => console.log("Erreur lors de l'insertion de fichiers"))
      }
      if (isListTickets) listToggle();
      if (user.id_service === 0) setCurrentTab(serviceChoice);
      handleClose()
    } catch (err) {
      console.log(err);
    }
  };

  const deleteMajorIncidentDocument = (fileIndex) => {
      const updatedAttachments = savedAttachments.filter((file, index) => index !== fileIndex)
      setSavedAttachments(updatedAttachments);
  };


  useEffect(() => {
    setServiceChoice(serviceTab);
    getServiceByName(serviceTab);
    setMajorIncident(initialMajorIncident)
  }, [serviceTab, show]);

  return (
    <>
    <Button
        color="primary"
        startIcon={<Warning fontSize="small" />}
        variant="contained"
        sx={{ mr: 1 }}
        onClick={() => setShow(true)}
    >
        Déclarer un incident majeur
    </Button>
      {show && (
         <Dialog
            open={show}
            fullWidth={true}
            maxWidth="md"
            onClose={handleCloseDialog}
         >
              <DialogTitle disableTypography sx={{color: theme.palette.primary.main, fontWeight: 'bold', fontSize: 18, textAlign: 'center'}}>
                Déclarer un incident majeur
              </DialogTitle>
              <Scrollbar>
              <DialogContent>
                <DialogContentText>
                <Grid
                  sx={{ display: 'flex', flexDirection: 'column'  }}
                >
                  <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.main, mb: 4 }}>Informations générales</Typography>
                  <Grid
                    item
                    sx={{ mb: 2 }}
                  >
                    <FormControl variant="outlined">
                      <InputLabel shrink>Service *</InputLabel>
                      <Select
                          id="demo-simple-select-autowidth"
                          value={serviceChoice}
                          onChange={handleChange}
                          autoWidth
                          labelId="select-label"
                          label="Service *"
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          sx={{ mb: 3}}
                      >
                          {(user.access_level === 0 && user.id_service === 0) ? listServices.map((serviceName) => (
                            <MenuItem value={serviceName}>{capitalizeFirstLetter(serviceName)}</MenuItem>
                          )) : listServices.filter((serv) => serv === user.service).map((serviceName) => (
                            <MenuItem value={serviceName}>{capitalizeFirstLetter(serviceName)}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  <Grid>
                  <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale={moment.locale('fr')}
                  >
                    <MobileDateTimePicker
                      ampm={false}
                      label="Date de début *"
                      inputFormat="DD/MM/YYYY à HH:mm"
                      onChange={() => null}
                      onClose={() => null}
                      showTodayButton
                      clearable
                      cancelText="Annuler"
                      todayText="Aujourd'hui"
                      clearText="Vider"
                      onAccept={(date) => { 
                        setMajorIncident({
                          ...majorIncident,
                          startDate: date
                        }) 
                      }}
                      value={majorIncident.startDate}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  </Grid>
                  </Grid>
                  <Box sx={{ width: '100%', display: 'flex', gap: 2, mb: 2 }}>
                    <TextField
                      multiline
                      rows={4}
                      label="Description *"
                      name="description"
                      fullWidth
                      onChange={handleText}
                      value={majorIncident.description}
                      sx={{ flex: 0.5 }}
                    />
                    <TextField label="Action corrective"
                      sx={{ 
                        marginBottom: '1.5rem', 
                        width: '100%', 
                        flex: 0.5,
                        // '& .MuiInputBase-root': {
                        //   borderColor: theme.palette.primary.main, // Couleur personnalisée pour le bord
                        // },
                        // '& .MuiOutlinedInput-root': {
                        //   '& fieldset': {
                        //     borderColor: theme.palette.primary.main, // Couleur personnalisée pour le bord
                        //   },
                        //   '&:hover fieldset': {
                        //     borderColor: theme.palette.primary.main, // Couleur personnalisée pour le bord lorsqu'il est en focus
                        //   },
                        // }
                      }}
                      // InputLabelProps={{
                      //   style: { color: theme.palette.primary.main }, // Couleur personnalisée pour l'étiquette
                      // }} 
                      multiline
                      rows={4}
                      value={majorIncident.solution}
                      name="solution"
                      onChange={handleText}
                    /> 
                  </Box>
                  <FilesMajorIncident 
                    theme={theme} 
                    savedAttachments={savedAttachments} 
                    setSavedAttachments={setSavedAttachments} 
                    deleteMajorIncidentDocument={deleteMajorIncidentDocument} 
                    majorIncidentId={null}
                  />
                  <TicketsMajorIncident tickets={tickets} setTickets={setTickets} serviceId={service.id} show={show} creationProcess={true} />
                  </Grid>
                </DialogContentText>
              </DialogContent>
              </Scrollbar>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 3, mb: 2 }}>
        <Button
            size="large"
            onClick={handleClose}
            disabled={isLoading}
        >
            Annuler
        </Button>
        <Box sx={{ display: 'flex', gap: 2 }}>
        {isLoading && (
        <Box
          sx={{
            display: 'flex',
            gap: 1
          }}
        >
          <Typography sx={{ color: theme.palette.text.primary, fontStyle: 'italic', mb: 2 }}>Enregistrement...</Typography>
          <CircularProgress />
        </Box>)}
          <Button
            size="large"
            onClick={createMajorIncident}
            disabled={!majorIncident.description || !majorIncident.startDate || isLoading}
          >
            Déclarer l&apos;incident
          </Button>
        </Box>
      </Box>
    </Dialog>)}
    </>
  );
};
export default FormMajorIncident;

FormMajorIncident.propTypes = {
  user: PropTypes.object.isRequired,
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
  serviceTab: PropTypes.string.isRequired,
  isListTickets: PropTypes.bool.isRequired,
  listToggle: PropTypes.func.isRequired,
  setCurrentTab: PropTypes.func.isRequired
};
