/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */

export const getTodayDate = () => {
  const date = new Date();

  const currentDay = String(date.getDate()).padStart(2, '0');

  const currentMonth = String(date.getMonth() + 1).padStart(2, '0');

  const currentYear = date.getFullYear();

  const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

  return currentDate;
};

export const getDateToLocalStringFR = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('fr', options);
};

// Format of dateString : YYYY-MM-DD
export const getYearFromDateString = (dateString) => dateString.slice(0, 4);

export const getListOfYearsDistinct = (listDateString) => {
  const firstYear = getYearFromDateString(listDateString[0]);
  const listOfYearsDistinct = [firstYear];

  for (let i = 1; i < listDateString.length; i++) {
    const year = getYearFromDateString(listDateString[i]);
    if (!listOfYearsDistinct.includes(year)) {
      listOfYearsDistinct.push(year);
    }
  }

  return listOfYearsDistinct;
};

export const getCurrentYear = () => {
  const today = getTodayDate();

  return getYearFromDateString(today);
};
