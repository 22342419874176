/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  Tooltip
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { sessionApi } from '../../../api/sessionApi';
import * as moment from 'moment';
import toast from 'react-hot-toast';
import useMounted from '../../../hooks/useMounted';

const SessionGap = (props) => {
  const { sessionId, mode, ...other } = props;
  const [rows, setRows] = useState([]);
  const mounted = useMounted();
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation

  const getUnsubscribed = useCallback(async () => {
    try {
      const data = await sessionApi.getUnsubscribed(sessionId, localStorage.getItem('accessToken'));

      if (mounted.current) {
        setRows(data.data.unsubscribedGap.map((customer) => ({ id: customer.id, firstname: customer.customerFirstname, lastname: customer.customerLastname, source: customer.source, name: customer.name, number_session: customer.number_session })));
      }
    } catch (err) {
      console.error(err);
      navigate('/404', { replace: true });
    }
  }, [mounted]);

  useEffect(() => {
    getUnsubscribed();
  }, [getUnsubscribed]);

  const handleChange = useCallback(
    async (params) => {
      try {
        await sessionApi.editCustomer(3, params.id, params.field === 'unsubscribed_at' ? moment(params.value).format('YYYY-MM-DD') : params.value, localStorage.getItem('accessToken'), params.field);
      } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de la modification !');
      }
    }
  );

  const columns = [
    {
      field: 'firstname',
      headerName: 'Prénom',
      width: 300,
    },
    {
      field: 'lastname',
      headerName: 'Nom',
      width: 300,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 300,
    },
    {
      field: 'name',
      headerName: 'Nom de formation',
      width: 300,
    },
    {
      field: 'number_session',
      headerName: 'N° session',
      width: 300,
    },
  ];

  return (
    <Card {...other}>
      <Box height={700}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          onCellEditCommit={handleChange}
        />
      </Box>
    </Card>
  );
};

SessionGap.propTypes = {
  sessionId: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired
};

export default SessionGap;
