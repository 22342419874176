/* eslint-disable no-undef */
class ReportsApi {
  async getCustomReportsList(accessToken) {
    const urlReports = `${process.env.REACT_APP_API_URL}/api/customReports`;

    const resp = await fetch(urlReports, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCustomReportById(customReportId, accessToken) {
    const urlReports = `${process.env.REACT_APP_API_URL}/api/getCustomReport/${customReportId}`;

    const resp = await fetch(urlReports, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async deleteCustomReportById(id, accessToken) {
    const urlReports = `${process.env.REACT_APP_API_URL}/api/deleteCustomReport`;

    const resp = await fetch(urlReports, {
      method: 'POST',
      body: JSON.stringify({ customReportId: id }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async createCustomReport(data, accessToken) {
    const urlReports = `${process.env.REACT_APP_API_URL}/api/createCustomReport`;

    const resp = await fetch(urlReports, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async loadData(accessToken) {
    const loadDataUrl = `${process.env.REACT_APP_API_URL}/api/load-custom-report-data`;

    const resp = await fetch(loadDataUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async loadSessionsByFormationId(formationId, accessToken) {
    const URL = `${process.env.REACT_APP_API_URL}/api/load-custom-report-selected-formation-data/${formationId}`;

    const resp = await fetch(URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
}

export const reportsApi = new ReportsApi();

export const ITEM_HEIGHT = 45;

export const ITEM_PADDING_TOP = 2;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const orderOptions = [
  { label: 'Croissant', value: 'ASC' },
  { label: 'Descandant', value: 'DESC' },
];

export const agregateOptions = [
  { label: 'Somme', value: 'SUM' },
  { label: 'Moyenne', value: 'AVG' },
  { label: 'Valeur Unique', value: 'DISTINCT' },
  { label: 'Minimum', value: 'MIN' },
  { label: 'Maximum', value: 'MAX' },
  { label: 'Arrondi', value: 'ROUND' },
];

export const dbTables = [
  { label: 'Apprenants', table: 'customers', alias: 'cs' },
  { label: 'Apprenants du jour', table: 'learner_session_day', alias: 'lsd' },
  { label: 'Apprenants en session', table: 'learner_session', alias: 'ls' },
  { label: 'Campagnes', table: 'campaign', alias: 'cp' },
  { label: 'Commerciaux', table: 'utilisateurs', alias: 'u' },
  { label: 'Etablissements', table: 'etablissements', alias: 'e' },
  { label: 'Facturation', table: 'factu_sessions', alias: 'fs' },
  { label: 'Formateurs', table: 'formateurs', alias: 'ft' },
  { label: 'Formations', table: 'formations', alias: 'fm' },
  { label: 'Prospects en campagnes', table: 'learner_campaign', alias: 'lc' },
  { label: 'Orientations DPC', table: 'orientations_dpc', alias: 'od' },
  { label: 'Prestataires', table: 'prestataires', alias: 'p' },
  { label: 'Rappels', table: 'recalls', alias: 'r' },
  { label: 'Relances', table: 'recalls_relance', alias: 'rr' },
  { label: 'Sessions', table: 'sessions', alias: 's' },
];

export const getLimits = [10, 25, 50, 100, 500];
