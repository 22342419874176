/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Typography, Box, Link, Skeleton, ToggleButton, Grid, ToggleButtonGroup } from '@material-ui/core';
import { useState, useCallback, useEffect } from 'react';
import { dashboardApi } from '../../../api/dashboardApi';
import useMounted from '../../../hooks/useMounted';
import { Link as RouterLink } from 'react-router-dom';
import Scrollbar from '../../Scrollbar';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FinanceStatistiqueProfessionsAndFormations = () => {
  const mounted = useMounted();
  const [formationsInfos, setFormationsInfos] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [showStats, setShowStats] = useState(true);

  const handleTabsChange = () => {
    setShowStats(!showStats);
  };

  const getDataProfession = useCallback(async () => {
    try {
      // on récupère les données de tous les apprenants inscrits que l'on va traiter en front end
      const data = await dashboardApi.getDataFormationAndProfession(localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setDatas(data);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getDataProfession();
  }, [getDataProfession]);

  const getInfos = useCallback(async () => {
    try {
      const data = await dashboardApi.getInfos(localStorage.getItem('accessToken'), 'all');

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setFormationsInfos(data.formations);
        setProfessions(data.professions);
        setLoading1(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  useEffect(() => {
    getInfos();
  }, [getInfos]);

  const rows = [];
  // on crée les lignes pour chaque profession par date de fin de session
  formationsInfos.forEach((item) => {
    const row = { id: item.id,
      name: item.name };
    professions.forEach((profession) => {
      let countCustomers = 0;
      let sumRealPrice = 0;
      datas.forEach((data) => {
        if (item.id === data.formations_id && data.profession === profession.profession) {
          countCustomers++;
          sumRealPrice += data.real_price;
        }
        row[`nb${profession.profession.replaceAll('-', '').replaceAll(' ', '').replaceAll('é', 'e')}`] = countCustomers;
        row[`real${profession.profession.replaceAll('-', '').replaceAll(' ', '').replaceAll('é', 'e')}`] = sumRealPrice;
      });
    });
    // calcul pour la colonne total
    let countCustomersTotal = 0;
    let sumRealPriceTotal = 0;
    datas.forEach((data) => {
      if (item.id === data.formations_id) {
        countCustomersTotal++;
        sumRealPriceTotal += data.real_price;
      }

      row.nbTotal = countCustomersTotal;
      row.realTotal = sumRealPriceTotal.toFixed(2);
      row.percent = ((countCustomersTotal / datas.length) * 100).toFixed(2);
    });

    rows.push(row);
  });
  // on crée la ligne total du calcul des sommes par profession
  const row = { id: 'total',
    name: 'Total'
  };
  professions.forEach((profession) => {
    let countCustomers = 0;
    let sumRealPrice = 0;
    datas.forEach((data) => {
      if (data.profession === profession.profession) {
        countCustomers++;
        sumRealPrice += data.real_price;
      }
      row[`nb${profession.profession.replaceAll('-', '').replaceAll(' ', '').replaceAll('é', 'e')}`] = countCustomers;
      row[`real${profession.profession.replaceAll('-', '').replaceAll(' ', '').replaceAll('é', 'e')}`] = sumRealPrice;
    });
  });
  // calcul pour la colonne total
  let countCustomersTotal = 0;
  let sumRealPriceTotal = 0;
  datas.forEach((data) => {
    countCustomersTotal++;
    sumRealPriceTotal += data.real_price;
    row.nbTotal = countCustomersTotal;
    row.realTotal = sumRealPriceTotal.toFixed(2);
    row.percent = ((countCustomersTotal / datas.length) * 100).toFixed(2);
  });

  rows.push(row);
  // pour un id différent pour une 2e ligne total
  row.id = 'total 2';
  rows.unshift(row);

  const columns = [
    { field: 'name',
      headerName: 'Thème de la formation',
      width: 320,
      renderCell: (cellValues) => (

        cellValues.row.name !== 'Total' ? (
          <Link
            sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
            component={RouterLink}
            to={`/dashboard/formations/${cellValues.row.id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Typography style={{ fontWeight: cellValues.row.name === 'Total' ? 'bold' : 'normal' }}>
              {cellValues.row.name}
            </Typography>
          </Link>
        )
          : (
            <Typography style={{ fontWeight: cellValues.row.name === 'Total' ? 'bold' : 'normal' }}>
              {cellValues.row.name}
            </Typography>
          )

      ) },
  ];
  // on crée les colonnes des professions dynamiquement
  professions.forEach((item) => {
    columns.push({ field: `nb${item.profession.replaceAll(' ', '').replaceAll('-', '').replaceAll('é', 'e')}`,
      width: 110,
      headerAlign: 'center',
      headerName: item.profession,
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row[`nb${item.profession.replaceAll(' ', '').replaceAll('-', '').replaceAll('é', 'e')}`]}</Typography>),
      // le filtre par défaut ne fonctionnant pas sur un renderCell, on précise un filtre personnalisé
      sortComparator: (v1, v2) => v1 - v2 });
  });
  columns.push({ field: 'nbTotal', width: 120, headerAlign: 'center', headerName: 'Total', renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row.nbTotal}</Typography>), sortComparator: (v1, v2) => v1 - v2 });
  columns.push({ field: 'percent', width: 120, headerAlign: 'center', headerName: 'En %', renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{`${cellValues.row.percent} %`}</Typography>), sortComparator: (v1, v2) => v1 - v2 });
  columns.push({ field: 'realTotal', width: 120, headerAlign: 'center', headerName: 'CAF', renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{`${cellValues.row.realTotal} €`}</Typography>), sortComparator: (v1, v2) => v1 - v2 });

  return (
    <Box>
      <Grid
        container
        sx={{ ml: 1, p: 2 }}
      >
        <ToggleButtonGroup
          exclusive

        >
          <ToggleButton
            style={{ border: 'none', color: 'inherit' }}
            value="grid"
            variant="h6"
            onClick={handleTabsChange}
          >
            {showStats === true ? <KeyboardArrowRightIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography
          variant="h6"
          sx={{ p: 1 }}
        >
          Chiffre d&apos;affaire par profession et formation
        </Typography>
      </Grid>
      {showStats !== true && (
        <Grid>
          {!loading && !loading1 ? (
            <Scrollbar>
              <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'total', sort: 'asc' }],
                  },
                }}
                autoHeight
                rowHeight={90}
                rows={rows}
                columns={columns}
                pageSize={25}
                rowsPerPageOptions={[15, 25, 50]}
                components={{ Toolbar: GridToolbar }}
                disableSelectionOnClick
                disableColumnMenu
                disableColumnSelector
              />
            </Scrollbar>
          ) : (
            <>
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
              <Skeleton
                variant="text"
                sx={{ ml: 4 }}
                animation="wave"
                width={1500}
                height={40}
              />
            </>
          )}
        </Grid>
      )}
      {showStats === true && ('')}
    </Box>

  );
};

export default FinanceStatistiqueProfessionsAndFormations;
