import Docxtemplater from "docxtemplater";
import FileSaver from "file-saver";
import PizZip from "pizzip";

/* eslint-disable consistent-return */
class SessionApi {
  async getSessions(accessToken) {
    const urlSessions = `${process.env.REACT_APP_API_URL}/api/sessions`;

    const resp = await fetch(urlSessions, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getSession(idSession, accessToken) {
    const urlSession = `${process.env.REACT_APP_API_URL}/api/sessions/${idSession}`;
    const resp = await fetch(urlSession, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async addCustomer(sessionId, userId, values, accessToken) {
    const URL_ADD_CUSTOMER = `${process.env.REACT_APP_API_URL}/api/sessions/add-customer`;
    values.sessionId = sessionId;
    values.sourceId = userId;
    const resp = await fetch(URL_ADD_CUSTOMER, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async editCustomer(type, id, value, accessToken, key = "") {
    const URL_EDIT_CUSTOMER = `${process.env.REACT_APP_API_URL}/api/sessions/edit-customer`;
    const values = {};
    values.value = value;
    values.id = id;
    values.key = key;
    values.type = type;
    const resp = await fetch(URL_EDIT_CUSTOMER, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async deleteCustomer(learnerSessionId, accessToken) {
    const URL_DELETE_CUSTOMER = `${process.env.REACT_APP_API_URL}/api/sessions/delete-customer`;
    const resp = await fetch(URL_DELETE_CUSTOMER, {
      method: "POST",
      body: JSON.stringify(learnerSessionId),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async updateSession(sessionID, values, accessToken) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/sessions/update-session`;
    values.id = sessionID;
    const resp = await fetch(URL_UPDATE, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async subscribeLearners(sessionID, accessToken) {
    const URL_SUBSCRIBE = `${process.env.REACT_APP_API_URL}/api/sessions/subscribe-learners`;
    const values = {};
    values.sessionId = sessionID;
    const resp = await fetch(URL_SUBSCRIBE, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async createSession(values, accessToken) {
    const URL_CREATE = `${process.env.REACT_APP_API_URL}/api/sessions/create-session`;
    const resp = await fetch(URL_CREATE, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async getUnsubscribed(idSession, accessToken) {
    const URL_GET = `${process.env.REACT_APP_API_URL}/api/get-unsubscribed/${idSession}`;
    const resp = await fetch(URL_GET, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async getInfoFactu(idSession, accessToken) {
    const URL_GET = `${process.env.REACT_APP_API_URL}/api/sessions/get-info-factu`;
    const resp = await fetch(URL_GET, {
      method: "POST",
      body: JSON.stringify(idSession),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async changeAttesCustomer(values, accessToken) {
    const URL_GET = `${process.env.REACT_APP_API_URL}/api/sessions/change-attes-customer`;
    const resp = await fetch(URL_GET, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async changeFactuSession(values, accessToken) {
    const URL_GET = `${process.env.REACT_APP_API_URL}/api/sessions/change-factu-session`;
    const resp = await fetch(URL_GET, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async getAttestationsDPC(idSession, accessToken) {
    const URL_GET = `${process.env.REACT_APP_API_URL}/api/sessions/get-attestations-dpc`;
    const resp = await fetch(URL_GET, {
      method: "POST",
      body: JSON.stringify(idSession),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async getAttestationLogs(id, accessToken) {
    const URL_GET = `${process.env.REACT_APP_API_URL}/api/get-attestation-logs/${id}`;
    const resp = await fetch(URL_GET, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async getTraca(id, accessToken) {
    const URL_GET = `${process.env.REACT_APP_API_URL}/api/sessions/get-traca/${id}`;
    const resp = await fetch(URL_GET, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json.data;
  }

  async decalage(currentIdDec, sessionDec, accessToken) {
    const URL_DEC = `${process.env.REACT_APP_API_URL}/api/sessions/decalage`;
    const resp = await fetch(URL_DEC, {
      method: "POST",
      body: JSON.stringify({ currentIdDec, sessionDec }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async addCustomerFormation(customerId, sessionId, accessToken) {
    const URL_ADD_CUSTOMER = `${process.env.REACT_APP_API_URL}/api/sessions/addCustomerFormation`;
    const resp = await fetch(URL_ADD_CUSTOMER, {
      method: "POST",
      body: JSON.stringify({ customerId, sessionId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async editSession(field, value, sessionId, accessToken) {
    const URL_EDIT = `${process.env.REACT_APP_API_URL}/api/sessions/edit_session`;
    const resp = await fetch(URL_EDIT, {
      method: "POST",
      body: JSON.stringify({ field, value, sessionId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async getSessionFormations(accessToken) {
    const urlSession = `${process.env.REACT_APP_API_URL}/api/session-formations`;

    const resp = await fetch(urlSession, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getSessionLogs(
    sessionId,
    sort = null,
    user,
    category,
    type,
    customer,
    limit = 25,
    page = 0,
    accessToken
  ) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/session/logs`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.customer = customer;
    values.sessionId = sessionId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getSessionLogsInfos(sessionId, accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/session/logs/infos/${sessionId}`;

    const resp = await fetch(urlCustomer, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getLogs(
    accessToken,
    sessionId,
    sort = null,
    user,
    category,
    type,
    customer,
    limit = 25,
    page = 0
  ) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/session/logs/filtered`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.customer = customer;
    values.sessionId = sessionId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async sendDocBySession(sessionId, sourceId, values, accessToken) {
    const URL_CREATE_DOC = `${process.env.REACT_APP_API_URL}/api/sessions/create-document-by-session`;
    const data = new FormData();
    values.forEach((value, index) => {
      data.append(`asset${index}`, value.file);
      data.append(`name${index}`, value.name);
      data.append(`type${index}`, value.type);
    });
    data.append("source_id", sourceId);
    data.append("session_id", sessionId);
    // console.log(data);
    const resp = await fetch(URL_CREATE_DOC, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!resp.ok) {
      console.error("Échec de la requête:", resp.status, resp.statusText);
      throw new Error("Échec de la requête");
    }

    const json = await resp.json();
    return json.data;
  }

  async getSessionDocuments(sessionId, accessToken) {
    const urlSession = `${process.env.REACT_APP_API_URL}/api/sessions/getDocuments/${sessionId}`;

    const resp = await fetch(urlSession, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async deleteDoc(data, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/customers/delete-document`;
    const resp = await fetch(URL_DELETE, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async deleteAllDoc(data, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/sessions/delete-all-documents`;
    const resp = await fetch(URL_DELETE, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async downloadSignedFileByTokenRequester(token_requester, accessToken) {
    // const token = "@B59y-4_L92@7ru"
    const URL_DOWNLOAD = `${process.env.REACT_APP_API_URL}/api/signatures/download-signed-file-by-token-requester/${token_requester}`;
    const response = await fetch(URL_DOWNLOAD, {
      method: "GET",
      // body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    
    console.clear();
    const data = await response.json();
    console.log(data);
    if (!response.ok) {
      return data;
    }

    if (data.file) {
      const linkSource = `data:application/pdf;base64,${data.file}`;
      const downloadLink = document.createElement("a");
      const fileName = "convention-signee.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

    }
    return data;

  }

  async handleUpdateAttestationsAndConventionsCompletionState(accessToken) {
    const token = "@B59y-4_L92@7ru"
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    // Liaison learner_session et agreement
    await fetch(`${process.env.REACT_APP_API_URL}/api/signatures/update-learner-sessions-all-signatures-requests-status/${token}`,
      {
        method: "GET",
        headers,
      }
    );
    
    // Liaison learner_session et honor
    await fetch(`${process.env.REACT_APP_API_URL}/api/signatures/update-learner-sessions-all-signatures-requests-status/${token}`,
      {
        method: "GET",
        headers,
      }
    );

    // Fetch the second data after the first one is completed
    await fetch(`${process.env.REACT_APP_API_URL}/api/signatures/update-learner-sessions-all-signatures-requests-status/${token}`,
      {
        method: "GET",
        headers,
      }
    );

    // Fetch the second data after the first one is completed
    const resp = await fetch(`${process.env.REACT_APP_API_URL}/api/signatures/update-learner-sessions-all-signatures-requests-status/${token}`,
      {
        method: "GET",
        headers,
      }
    );
    console.log(resp);
    const json = await resp.json();
    return Promise.resolve(json.data);
  }

}

export const sessionApi = new SessionApi();
