/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Grid, TextField, Typography, Divider, FormControlLabel, RadioGroup, Radio, useTheme, FormControl, MenuItem, Autocomplete, Alert, Tooltip } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { reportsApi } from '../../../../api/reportsApi';
import useAuth from '../../../../hooks/useAuth';
import useMounted from '../../../../hooks/useMounted';
import SelectField from './SelectField';
import { split } from 'lodash';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { DatePicker } from '@material-ui/lab';
import RenderAdditionalFields from './RenderAdditionalFields';
import { DeleteOutlineOutlined } from '@material-ui/icons';
// import { sessionsList } from '../../../../api/customReportApi';

const formSchema = Yup.object().shape({
  reportTitle: Yup.string()
    .required('Ce champ est requis')
    .min(5, 'Cinq caractères minimum requis !')
    .max(255, '255 caractères maximum !'),
  // reportType: Yup.string()
  //   .required('Veuillez sélectionner un type pour ce rapport !'),
  description: Yup.string()
    .min(10, 'Dix caractères minimum requis !')
    .max(1000, 'Nombre de caractères maximum !'),
});

const initialValues = {
  reportTitle: '',
  reportType: '',
  description: '',
};

const CustomReportForm = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  // list des options utilisés pour le formulaire
  const [data, setData] = useState({
    specialites: [],
    formations: [],
    professions: [],
    regions: [],
    campagnes: [],
    sources: [],
    sessions: [],
    statusList: [],
    criteresList: [],
    reportTypes: [],
    renderFields: [],
  });
  // const [isLoading, setIsLoading] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [criteres, setCriteres] = useState([{}]);
  const [reportType, setReportType] = useState('');
  const [description, setDescription] = useState('');
  const [renderFields, setRenderFields] = useState([]);
  const [renderFieldsValues, setRenderFieldsValues] = useState([]);
  const mounted = useMounted();

  const getData = useCallback(async () => {
    try {
      const _data = await reportsApi.loadData(localStorage.getItem('accessToken'));
      console.log('initial data', _data);
      if (mounted.current) {
        setData(_data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getData();
    console.clear();
  }, [getData]);

  const handleChangeType = (event) => {
    setReportType(event.target.value);
  };

  const customResetForm = () => {
    // setReportTitle('');
    // setReportType('');
    // setCriteres([]);
    // setDescription('');
    // setRenderFields([]);
  };

  const handleSubmitForm = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    try {
      const formData = values;

      // processing reportType
      formData.reportType = data.reportTypes.filter((item) => item.value === reportType).map((item) => ({
        label: item.label,
        table: item.table,
        alias: item.alias
      }));
      formData.reportType = formData.reportType[0];

      // processing renderFields
      formData.renderFields = renderFieldsValues;

      // processing renderFields
      formData.createdBy = user.id;

      // processing criteres
      formData.criteres = criteres;
      console.log('formData', formData);

      const request = await reportsApi.createCustomReport(formData, localStorage.getItem('accessToken'))
        .then((response) => {
          resetForm();
          customResetForm();
          setStatus({ success: true });
          setSubmitting(false);
          toast.success('Nouveau rapport créé avec succès !');
          navigate('/dashboard/custom-reports', { replace: true });
        })
        .catch((error) => console.error(error));
    } catch (err) {
      console.error(err);
      toast.error('Une erreur est survenue lors de la création du rapport !');
      setStatus({ success: false });
      setErrors({ submit: err.message });
      setSubmitting(false);
    }
  };

  const handleChangeCritere = (e, index, o) => {
    const temp = [...criteres];
    temp[index] = o;
    setCriteres(temp);
  };

  const handleChangeRenderFields = (event) => {
    const {
      target: { value },
    } = event;
    setRenderFields(typeof value === 'string' ? value.split(', ') : value);
    const res = [];
    value.forEach((item) => {
      const temp = data.renderFields.filter((obj) => obj.name === item);
      res.push(...temp);
    });
    setRenderFieldsValues(res);
  };

  useEffect(() => {
    console.log(criteres);
  }, [criteres]);

  const handleCritereRemove = (index) => {
    const list = [...criteres];
    list.splice(index, 1);
    setCriteres(list);
  };

  const handleCritereAdd = () => {
    setCriteres([...criteres, { critere: '' }]);
  };

  return (
    <>
      <Divider />
      <Grid
        px={10}
        mx={10}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={handleSubmitForm}
        >

          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Box
                mt={3}
                mb={4}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                  >
                    <Typography
                      variant="subtitle"
                      gutterBottom
                    >
                      Entrez un titre pour ce rapport
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      sx={{ m: 1 }}
                      required
                      type="text"
                      name="reportTitle"
                      id="reportTitle"
                      placeholder="Exemple : Liste des apprenants inscrits ce mois"
                      value={values.reportTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.reportTitle && errors.reportTitle)}
                      helperText={touched.reportTitle && errors.reportTitle}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    lg={12}
                  >
                    <Typography
                      variant="subtitle"
                      gutterBottom
                    >
                      Sélectionnez le type de ce rapport
                      <Tooltip
                        arrow
                        title="Le type de rapport est étroitement liés aux critères et aux données que vous souhaitez réccupérer dans le rapport."
                        placement="top"
                      >
                        <InfoIcon sx={{ width: 15, ml: 1, pt: 1 }} />
                      </Tooltip>
                    </Typography>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="reportType"
                      id="reportType"
                      value={reportType}
                      onChange={handleChangeType}
                      error={Boolean(touched.reportType && errors.reportType)}
                      helperText={touched.reportType && errors.reportType}
                      onBlur={handleBlur}
                    >
                      {data
                        && data.reportTypes.map(({ label, value }) => (
                          <FormControlLabel
                            value={value}
                            control={<Radio />}
                            required
                            label={label}
                          />
                        ))}
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Box>

              <Typography
                variant="h6"
                mb={1}
                gutterBottom
              >
                Critères de sélection

                <Tooltip
                  arrow
                  title="Assurez vous que les critères sélectionnés correspondent au type de rapport sélectionné."
                  placement="top"
                >
                  <InfoIcon sx={{ width: 15, ml: 1, pt: 1 }} />
                </Tooltip>
              </Typography>

              <Divider />

              {Object.keys(criteres[0]) && criteres.map((c, index) => (
                <>
                  <Box
                    mt={3}
                    mb={4}
                  >
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={12}
                        lg={4}
                      >
                        <Autocomplete
                          id="disable-clearable"
                          disableClearable
                          key={index}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          options={data.criteresList}
                          onChange={(e, option) => handleChangeCritere(e, index, option)}
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              name="criteres"
                              variant="outlined"
                              label=""
                            >
                              {params.label}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={7}
                      >
                        {/* {criteres[index].type} */}
                        <RenderAdditionalFields
                          type={criteres[index].type}
                          index={index}
                          data={data}
                          values={values}
                          setFieldValue={setFieldValue}
                          currentState={criteres}
                          setCurrentState={setCriteres}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={1}
                      >
                        {criteres.length > 1 && (
                          <DeleteOutlineOutlined
                            fontSize="small"
                            sx={{ mt: 1 }}
                            onClick={() => handleCritereRemove(index)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    mt={3}
                    mb={4}
                  >
                    {criteres.length - 1 === index && criteres.length < 5 && (
                      <Button
                        onClick={handleCritereAdd}
                        variant="outlined"
                        endIcon={(
                          <AddIcon fontSize="small" />
                          )}
                      >
                        Ajouter un nouveau critère
                      </Button>
                    )}
                  </Box>
                </>
              ))}

              <Typography
                variant="h6"
                mb={1}
                gutterBottom
              >
                Sélectionnez les informations pour le rendu
              </Typography>

              <Divider />

              <Box
                mt={3}
                mb={4}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                  >
                    <SelectField
                      name="renderFields"
                      id="renderFields"
                      list={data && data.renderFields.map((f) => f.name)}
                      handleChange={handleChangeRenderFields}
                      _state={renderFields}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                mt={3}
                mb={4}
              >
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    lg={12}
                  >
                    <Typography
                      variant="subtitle"
                      gutterBottom
                    >
                      Ajouter un commentaire à ce rapport
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ m: 1 }}
                      multiline
                      rows={4}
                      id="description"
                      name="description"
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Divider />

              <Box
                mt={3}
                mb={4}
                textAlign="right"
              >
                <Button
                  size="sm"
                  type="reset"
                  sx={{ marginRight: '10px' }}
                >
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || reportType === ''}
                >
                  Créer
                </Button>
              </Box>
            </form>
          )}

        </Formik>
      </Grid>
    </>
  );
};

export default CustomReportForm;
