import { Breadcrumbs, Typography, Grid, Box, Link, Container, Card } from '@material-ui/core';
import ChevronRightIcon from '../../icons/ChevronRight';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import useMounted from '../../hooks/useMounted';
import { useState, useCallback, useEffect } from 'react';
import { usersApi } from '../../api/usersApi';
import UsersListTable from '../../components/dashboard/users/UsersListTable';
import useAuth from '../../hooks/useAuth';

const UsersList = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [users, setUsers] = useState([]);
  const user = useAuth();

  const getUsers = useCallback(async () => {
    try {
      const data = await usersApi.getUsers(window.localStorage.getItem('accessToken'));
      if (mounted.current) {
        setUsers(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <>
      <Helmet>
        <title>Tableau de bord: Liste des utilisateurs | Apteed</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Liste des utilisateurs
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Gestion
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Utilisateurs
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          {user.user.id_service === 0
          && (
          <Card sx={{ mt: 4 }}>
            <UsersListTable users={users} />
          </Card>
          )}
          {user.user.id_service !== 0 && 'Vous n\'avez pas le droit de voir les utilisateurs'}
        </Container>
      </Box>

    </>
  );
};

export default UsersList;
