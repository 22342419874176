/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  TableCell,
  TableHead,
  Typography
} from '@material-ui/core';
import * as moment from 'moment';
import { Paper, Table, TableBody, TableContainer, TableFooter, TablePagination, TableRow } from '@mui/material';
import useMounted from '../../../../hooks/useMounted';

const CustomReportDetailsTable = ({ customers }) => {
  const [rows, setRows] = useState([]);
  const [keys, setKeys] = useState([]);
  const mounted = useMounted();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fields = [
    {
      name: 'Numéro de session',
      field: 's.number_session'
    },
    {
      name: 'Date de début de session',
      field: 's.start_date'
    },
    {
      name: 'Date de fin de session',
      field: 's.end_date'
    },
    {
      name: 'Date de fin du premier tour',
      field: 's.first_turn_end_date'
    },
    {
      name: 'Formation',
      field: 'f.name'
    },
    {
      name: 'Type de formation',
      field: 'f.action_type'
    },
    {
      name: 'Durée de la formation',
      field: 'f.duration'
    },
    {
      name: 'Nombre de participants',
      field: 'f.number_of_participants'
    },
    {
      name: 'Numero du programme',
      field: 'f.program_number'
    },
    {
      name: 'Date de première connexion',
      field: 'ls.primary_connection'
    },
    {
      name: 'Date de derinère connexion',
      field: 'ls.last_connection'
    },
    {
      name: 'Durée totale de connexion',
      field: 'ls.duration'
    },
    {
      name: 'Progression',
      field: 'ls.progression'
    },
    {
      name: 'Status',
      field: 'ls.status'
    },
    {
      name: 'Coût de la formation',
      field: 'ls.real_price'
    },
    {
      name: 'Nom',
      field: 'c.lastname'
    },
    {
      name: 'Nom de Jeune fille',
      field: 'c.maiden_name'
    },
    {
      name: 'Prénom',
      field: 'c.firstname'
    },
    {
      name: 'Pays',
      field: 'c.country'
    },
    {
      name: 'Adresse',
      field: 'c.adress'
    },
    {
      name: 'Code postal',
      field: 'c.zip'
    },
    {
      name: 'Ville',
      field: 'c.city'
    },
    {
      name: 'Département',
      field: 'c.department'
    },
    {
      name: 'Région',
      field: 'c.region'
    },
    {
      name: 'Profession',
      field: 'c.profession'
    },
    {
      name: 'Spécialité',
      field: 'c.specialite'
    },
    {
      name: 'Email',
      field: 'c.email'
    },
    {
      name: 'Téléphone',
      field: 'c.phone'
    },
    {
      name: 'Téléphone fixe',
      field: 'c.phone_fix'
    },
    {
      name: 'Adeli RPPS',
      field: 'c.adeli_rpps'
    },
    {
      name: 'Commercial',
      field: 'ls.source_id, u_firstname, u_lastname'
    }
  ];

  // Les keys sont affichées avec les labels en bdd : cette fonction permet d'adapter l'affichage en utilisant des labels intuitifs
  const displayLabel = (l) => {
    const r = fields.find((f) => f.field.includes(l));
    return r.name;
  };

  useEffect(() => {
    if (mounted.current && customers) {
      // console.clear();
      console.log('params data => ', customers);
      const row = customers.length ? customers[0] : [];
      console.log('keys ', Object.keys(row));
      setRows(customers);
      setKeys(Object.keys(row));
    }
  }, [customers]);

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - rows.length);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ mt: 2 }}
      >
        <Table
          sx={{ minWidth: 500 }}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              {keys && keys.map((k) => (
                <TableCell
                  component="th"
                  scope="row"
                >
                  {displayLabel(k)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rows.length
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.id}>
                {keys && keys.map((k) => (
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ minWidth: k === 'name' ? 380 : 160 }}
                  >
                    {k === 'name' ? (
                      // Modification du style pour les titre de formation
                      <Typography variant="subtitle2">
                        {row[k]}
                      </Typography>
                    ) : row[k] }
                  </TableCell>
                ))}
              </TableRow>
            ))}
            {emptyRows > 0 && (
            <TableRow style={{ height: 20 * emptyRows }}>
              <TableCell colSpan={6}>
                <Typography
                  variant="h6"
                  gutterBottom
                  textAlign="center"
                >
                  Aucune donnée trouvée  correspondant aux critères !
                </Typography>
              </TableCell>
            </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'Toutes', value: -1 }]}
                // colSpan={keys && keys.length}
                count={rows && rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'lignes par page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomReportDetailsTable;
