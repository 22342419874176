class AuthApi {
  async login({ email, password }) {
    const URL_LOGIN = `${process.env.REACT_APP_API_URL}/api/user-auth`;
    const data = { email, password };

    const resp = await fetch(URL_LOGIN, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const json = await resp.json();
    return new Promise((resolve, reject) => {
      // Find the user
      if (!json.data.token) {
        reject(new Error('Veuillez vérifier votre email et votre mot de passe.'));
        return;
      }
      try {
        resolve(json.data.token);
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Erreur de serveur interne'));
      }
    });
  }

  async updateUser(idUser, idService, values, accessToken) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/update-user`;
    let data2 = {};

    switch (idService) {
      default: data2 = {
        region: values.region,
        birthday: values.birthday,
        personal_email: values.personal_email
      };
        break;
      case 2: data2 = {
        website: values.website,
        region: values.region,
        email_pro: values.email_pro,
        country: values.country
      };
        break;
      case 3: data2 = {
        civility: values.civility
      };
        break;
    }

    const valuesSeperate = [
      {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        adress: values.adress,
        zip: values.zip,
        city: values.city,
        region: values.region,
        personal_email: values.personal_email,
        avatar: values.avatar
      },
      data2,
      {
        id: idUser,
        id_service: idService
      }];

    const resp = await fetch(URL_UPDATE, {
      method: 'POST',
      body: JSON.stringify(valuesSeperate),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async createPresence(values, period, accessToken) {
    const URL_CREATE_PRESENCE = `${process.env.REACT_APP_API_URL}/api/create-presence`;
    const data = { values, period };
    const resp = await fetch(URL_CREATE_PRESENCE, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();

    return json;
  }

  async me(accessToken) {
    const urlLoadUser = `${process.env.REACT_APP_API_URL}/api/load-user`;
    const resp = await fetch(urlLoadUser, {
      method: 'POST',
      body: JSON.stringify(accessToken),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return new Promise((resolve, reject) => {
      try {
        resolve({
          id: json.data.id,
          firstname: json.data.firstname,
          lastname: json.data.lastname,
          email: json.data.email,
          phone: json.data.phone,
          functions: json.data.functions,
          adress: json.data.adress,
          zip: json.data.zip,
          city: json.data.city,
          id_service: json.data.id_service,
          access_level: json.data.access_level,
          avatar: json.data.avatar,
          birthday: json.data.birthday,
          personal_email: json.data.personal_email,
          civility: json.data.civility,
          website: json.data.website,
          email_pro: json.data.email_pro,
          country: json.data.country,
          region: json.data.region,
          updated_at: json.data.updated_at,
          service: json.data.service,
          service_manager: json.data.service_manager
        });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async logout(userId, accessToken) {
    const URL_ASSIGN = `${process.env.REACT_APP_API_URL}/api/logout`;
    const values = {};
    values.userId = userId;
    const resp = await fetch(URL_ASSIGN, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }
}

export const authApi = new AuthApi();
