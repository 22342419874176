/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import {
  Box,
  Breadcrumbs,
  Button,
  TextField,
  Typography,
  Grid,
  Link,
  Divider,
  CircularProgress,
  Backdrop,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMounted from "../../hooks/useMounted";
import { useCallback, useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { campaignApi } from "../../api/campaignApi";
import useAuth from "../../hooks/useAuth";
import ChevronRightIcon from "../../icons/ChevronRight";
import * as moment from "moment";
import CampaignDetails from "../../pages/dashboard/CampaignDetails";
import CampaignLearner from "./CampaignLearner";
import AttributionIcon from "@mui/icons-material/Attribution";
import AddIcon from "@mui/icons-material/Add";
import DateAdapter from "@mui/lab/AdapterMoment";
import * as React from "react";
import * as Yup from "yup";
import CampaignStatsTab from "./CampaignStatsTab";
import CampaignLogs from "./CampaignLogs";
import DialogReinjection from "./DialogReinjection";
import DialogRetrieve from "./DialogRetrieve";
import DialogAddCommercials from "./DialogAddCommercials";
import DialogManageCommercials from "./DialogManageCommercials";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const tabs = [
  { label: "Statistiques", value: "statistiques" },
  { label: "Apprenants", value: "apprenants" },
];

const tabs2 = [
  { label: "Statistiques", value: "statistiques" },
  { label: "Apprenants", value: "apprenants" },
  { label: "Activités", value: "logs" },
];

const CampaignInfo = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const mounted = useMounted();
  const { idCampaign } = useParams();
  const [donnees, setDonnees] = useState({});
  const [openAddCommercial, setOpenAddCommercial] = useState(false);
  const [openAddcommercials, setOpenAddcommercials] = useState(false);
  const [openReinjection, setOpenReinjection] = useState(false);
  const [openRetrieve, setOpenRetrieve] = useState(false);
  const [campaignValues, setCampaignValues] = useState({
    name: "",
    start_date: "",
    end_date: "",
  });
  const [isEditing, setIsEditing] = useState({
    name: false,
    start_date: false,
    end_date: false,
  });
  const [progressionProspects, setProgressionProspects] = useState(0);
  const steps = [
    "Informations de la réinjection",
    "Assignation des commerciaux",
    "Récapitulatif",
  ];
  const stepsRetrieve = ["Informations du rattrapage", "Récapitulatif"];
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepRetrieve, setActiveStepRetrieve] = useState(0);
  const [qualifications, setQualifications] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([
    { qualification: "Attente" },
    { qualification: "Ancien commentaire" },
    { qualification: "Erreur" },
    { qualification: "Non - Déjà fait" },
    { qualification: "Non - Pas intéressé" },
    { qualification: "Numéro incorrect" },
    { qualification: "Pas répondu" },
    { qualification: "Refus" },
    { qualification: "Sans statut" },
    { qualification: "Souhaite un email" },
    { qualification: "Appel entrant" },
    { qualification: "À rappeler" },
    { qualification: "Rappel" },
  ]);
  const [selectedStatusRetrieve, setSelectedStatusRetrieve] = useState([
    { qualification: "Attente" },
    { qualification: "Ancien commentaire" },
    { qualification: "Erreur" },
    { qualification: "Non - Déjà fait" },
    { qualification: "Non - Pas intéressé" },
    { qualification: "Numéro incorrect" },
    { qualification: "Pas répondu" },
    { qualification: "Refus" },
    { qualification: "Sans statut" },
    { qualification: "Souhaite un email" },
    { qualification: "Appel entrant" },
    { qualification: "À rappeler" },
    { qualification: "Rappel" },
  ]);
  const [selectedCommercials, setSelectedCommercials] = useState([]);
  const [selectedCommercialsEquitable, setSelectedCommercialsEquitable] =
    useState([]);
  const [commercialToAdd, setCommercialToAdd] = useState(null);
  const [commercialsToAdd, setCommercialsToAdd] = useState([]);
  const [commercialsCampaign, setCommercialsCampaign] = useState([]);
  const [commercialsCampaignManage, setCommercialsCampaignManage] = useState(
    []
  );
  const [
    commercialsCampaignManageNotTreated,
    setCommercialsCampaignManageNotTreated,
  ] = useState([]);
  const [selectedCommercialToAdd, setSelectedCommercialToAdd] = useState({});
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [leftChecked, setLeftChecked] = useState([]);
  const [rightChecked, setRightChecked] = useState([]);
  const [displayCustom, setDisplayCustom] = useState(false);
  const [displayNumberCustomers, setDisplayNumberCustomers] = useState(false);
  const [
    displayNumberCustomersNotAttributed,
    setDisplayNumberCustomersNotAttributed,
  ] = useState(false);
  const [displayReattribution, setDisplayReattribution] = useState(false);
  const [displayEquitable, setDisplayEquitable] = useState(false);
  const [selectedCommercial, setSelectedCommercial] = useState(null);
  const [typeCampaign, setTypeCampaign] = useState("");
  const [nbCustomers, setNbCustomers] = useState(0);
  const [nbCustomersNotAttributed, setNbCustomersNotAttributed] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [selectedCommercialsReinjection, setSelectedCommercialsReinjection] =
    useState([]);
  const [selectedCommercialReinjection, setSelectedCommercialReinjection] =
    useState(null);
  const [selectedCommercialRetrieve, setSelectedCommercialRetrieve] =
    useState(null);
  const [currentTab, setCurrentTab] = useState("statistiques");
  const [formIsEmpty, setFormIsEmpty] = useState(true);

  const getCampaignInfo = useCallback(async () => {
    try {
      let data = {};
      // on récupère les commerciaux non affectées à la campagne
      const dataCommercialsToAdd = await campaignApi.getCommercialsForAdding(
        idCampaign,
        localStorage.getItem("accessToken")
      );
      const dataCommercialsCampaign = await campaignApi.getCommercialsCampaign(
        idCampaign,
        localStorage.getItem("accessToken")
      );
      const dataCommercialsCampaignManage =
        await campaignApi.getCommercialsCampaignManage(
          idCampaign,
          localStorage.getItem("accessToken")
        );
      data = await campaignApi.getCampaignInfo(
        idCampaign,
        localStorage.getItem("accessToken")
      );
      const dataQualifications = await campaignApi.getQualificationsReinj(
        localStorage.getItem("accessToken")
      );
      const tempLeft = {};
      const tempRight = {};
      const tempChecked = {};
      const tempLeftChecked = {};
      const tempRightChecked = {};
      if (data.length === 0) {
        throw new Error("La campagne n'existe pas");
      }

      if (mounted.current) {
        setDonnees(data);
        setProgressionProspects(data.progression);
        setCampaignValues({
          name: data.campaign.name,
          start_date: data.campaign.start_date,
          end_date: data.campaign.end_date,
        });
        setQualifications(dataQualifications.data);
        setCommercialToAdd(dataCommercialsToAdd.data);
        setCommercialsCampaign(dataCommercialsCampaign.data);
        setCommercialsCampaignManageNotTreated(dataCommercialsCampaign.data);
        setCommercialsCampaignManage(dataCommercialsCampaignManage.data);
        // initialisation des array d'array
        data.commercial.forEach((item) => {
          tempLeft[item.caller_id] = data.commercials;
          tempRight[item.caller_id] = [];
          tempChecked[item.caller_id] = [];
          tempLeftChecked[item.caller_id] = [];
          tempRightChecked[item.caller_id] = [];
        });
        setLeft(tempLeft);
        setRight(tempRight);
        setChecked(tempChecked);
        setLeftChecked(tempLeftChecked);
        setRightChecked(tempRightChecked);
        setIsLoadingPage(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    if (
      commercialsToAdd.length === 0 ||
      (displayNumberCustomers === false &&
        displayReattribution === false &&
        displayEquitable === false &&
        displayNumberCustomersNotAttributed === false)
    ) {
      setFormIsEmpty(true);
    } else {
      setFormIsEmpty(false);
    }
  }, [
    commercialsToAdd,
    displayNumberCustomers,
    displayReattribution,
    displayEquitable,
    displayNumberCustomersNotAttributed,
  ]);

  useEffect(() => {
    getCampaignInfo();
  }, [getCampaignInfo]);

  useEffect(() => {
    if (selectedCommercial !== null) {
      const tempLeftChecked = { ...leftChecked };
      tempLeftChecked[selectedCommercial] = intersection(
        checked[selectedCommercial],
        left[selectedCommercial]
      );
      setLeftChecked(tempLeftChecked);
      const tempRightChecked = { ...rightChecked };
      tempRightChecked[selectedCommercial] = intersection(
        checked[selectedCommercial],
        right[selectedCommercial]
      );
      setRightChecked(tempRightChecked);
    }
  }, [selectedCommercial, checked]);

  const handleToggle = (field) => {
    const tempIsEditing = { ...isEditing };
    tempIsEditing[field] = !tempIsEditing[field];
    setIsEditing(tempIsEditing);
  };

  const handleToggleList = (items, value) => () => {
    const currentIndex = checked[selectedCommercial]
      .map((e) => e.id)
      .indexOf(items[value].id);

    const newChecked = { ...checked };

    if (currentIndex === -1) {
      newChecked[selectedCommercial].push(items[value]);
    } else {
      newChecked[selectedCommercial].splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleChangeData = async (field, value) => {
    try {
      await campaignApi.editCampaign(
        field,
        value,
        donnees.campaign.id,
        localStorage.getItem("accessToken")
      );
      handleToggle(field);
    } catch (err) {
      console.error(err);
    }
  };

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleOpenAddcommercials = () => {
    setOpenAddcommercials(true);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackRetrieve = () => {
    setActiveStepRetrieve((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextRetrieve = () => {
    setActiveStepRetrieve((prevActiveStep) => prevActiveStep + 1);
  };

  const initialValues = {
    assignment: "Même assignation",
  };

  const validationSchema = Yup.object().shape({
    assignment: Yup.string().required(),
  });

  const handleLeavingDialog = () => {
    if (!openReinjection) {
      const tempRight = {};
      const tempChecked = {};
      const tempLeftChecked = {};
      const tempRightChecked = {};
      if (Object.keys(donnees).length !== 0 && donnees.constructor === Object) {
        donnees.commercial.forEach((item) => {
          tempRight[item.caller_id] = [];
          tempChecked[item.caller_id] = [];
          tempLeftChecked[item.caller_id] = [];
          tempRightChecked[item.caller_id] = [];
        });
        setRight(tempRight);
        setChecked(tempChecked);
        setLeftChecked(tempLeftChecked);
        setRightChecked(tempRightChecked);
        setSelectedCommercial(null);
        setDisplayCustom(false);
        setActiveStep(0);
        setSelectedStatus([]);
        setSelectedCommercialsReinjection([]);
      }
    }
  };

  const handleLeavingDialogRetrieve = () => {
    if (!openRetrieve) {
      if (Object.keys(donnees).length !== 0 && donnees.constructor === Object) {
        setActiveStepRetrieve(0);
        setSelectedStatusRetrieve([]);
      }
    }
  };

  const handleLeavingDialogAddCommercial = () => {
    if (!openAddCommercial) {
      setDisplayNumberCustomers(false);
      setDisplayReattribution(false);
      setDisplayEquitable(false);
      setSelectedCommercialToAdd({});
      setSelectedCommercials([]);
      setSelectedCommercialsEquitable([]);
      setDisplayNumberCustomersNotAttributed(false);
    }
  };

  const handleLeavingDialogAddcommercials = () => {
    if (!openAddcommercials) {
      setDisplayNumberCustomers(false);
      setDisplayReattribution(false);
      setDisplayEquitable(false);
      setSelectedCommercialToAdd({});
      setSelectedCommercials([]);
      setSelectedCommercialsEquitable([]);
      setCommercialsToAdd([]);
      setDisplayNumberCustomersNotAttributed(false);
    }
  };

  const handleSelectedCommercialsReinjection = (commercialId) => {
    const tempSelectedCommercialsReinjection = [
      ...selectedCommercialsReinjection,
    ];
    const index = tempSelectedCommercialsReinjection.indexOf(commercialId);
    if (index === -1) {
      tempSelectedCommercialsReinjection.push(commercialId);
    } else {
      tempSelectedCommercialsReinjection.splice(index, 1);
    }
    setSelectedCommercialsReinjection(tempSelectedCommercialsReinjection);
  };

  const [openManageCommercials, setOpenManageCommercials] = useState(false);

  const handleOpenManageCommercials = () => {
    setOpenManageCommercials(true);
  };

  useEffect(() => {
    handleLeavingDialog();
  }, [openReinjection]);

  useEffect(() => {
    handleLeavingDialogRetrieve();
  }, [openRetrieve]);

  useEffect(() => {
    handleLeavingDialogAddCommercial();
  }, [openAddCommercial]);

  useEffect(() => {
    handleLeavingDialogAddcommercials();
  }, [openAddcommercials]);

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        p: 2,
      }}
    >
      <DialogReinjection
        openReinjection={openReinjection}
        setOpenReinjection={setOpenReinjection}
        initialValues={initialValues}
        validationSchema={validationSchema}
        setIsLoading={setIsLoading}
        idCampaign={idCampaign}
        typeCampaign={typeCampaign}
        selectedStatus={selectedStatus}
        right={right}
        donnees={donnees}
        selectedCommercialsReinjection={selectedCommercialsReinjection}
        selectedCommercialReinjection={selectedCommercialReinjection}
        activeStep={activeStep}
        steps={steps}
        qualifications={qualifications}
        setSelectedStatus={setSelectedStatus}
        setDisplayCustom={setDisplayCustom}
        displayCustom={displayCustom}
        handleSelectedCommercialsReinjection={
          handleSelectedCommercialsReinjection
        }
        selectedCommercial={selectedCommercial}
        handleBack={handleBack}
        handleNext={handleNext}
        isLoading={isLoading}
      />
      <DialogRetrieve
        initialValues={initialValues}
        validationSchema={validationSchema}
        setIsLoading={setIsLoading}
        idCampaign={idCampaign}
        qualifications={qualifications}
        isLoading={isLoading}
        selectedStatusRetrieve={selectedStatusRetrieve}
        selectedCommercialRetrieve={selectedCommercialRetrieve}
        openRetrieve={openRetrieve}
        setOpenRetrieve={setOpenRetrieve}
        activeStepRetrieve={activeStepRetrieve}
        stepsRetrieve={stepsRetrieve}
        setSelectedStatusRetrieve={setSelectedStatusRetrieve}
        handleBackRetrieve={handleBackRetrieve}
        handleNextRetrieve={handleNextRetrieve}
      />

      <DialogAddCommercials
        setIsLoading={setIsLoading}
        idCampaign={idCampaign}
        isLoading={isLoading}
        openAddcommercials={openAddcommercials}
        setOpenAddcommercials={setOpenAddcommercials}
        donnees={donnees}
        commercialsToAdd={commercialsToAdd}
        selectedCommercials={selectedCommercials}
        selectedCommercialsEquitable={selectedCommercialsEquitable}
        typeCampaign={typeCampaign}
        commercialToAdd={commercialToAdd}
        setCommercialsToAdd={setCommercialsToAdd}
        setDisplayEquitable={setDisplayEquitable}
        setDisplayNumberCustomers={setDisplayNumberCustomers}
        setDisplayNumberCustomersNotAttributed={
          setDisplayNumberCustomersNotAttributed
        }
        setDisplayReattribution={setDisplayReattribution}
        displayReattribution={displayReattribution}
        commercialsCampaign={commercialsCampaign}
        setSelectedCommercials={setSelectedCommercials}
        setSelectedCommercialsEquitable={setSelectedCommercialsEquitable}
        displayEquitable={displayEquitable}
        displayNumberCustomers={displayNumberCustomers}
        displayNumberCustomersNotAttributed={
          displayNumberCustomersNotAttributed
        }
        setNbCustomers={setNbCustomers}
        setNbCustomersNotAttributed={setNbCustomersNotAttributed}
        formIsEmpty={formIsEmpty}
      />

      <DialogManageCommercials
        setIsLoading={setIsLoading}
        idCampaign={idCampaign}
        isLoading={isLoading}
        openManageCommercials={openManageCommercials}
        commercialsCampaignManageNotTreated={
          commercialsCampaignManageNotTreated
        }
        commercialsCampaignManage={commercialsCampaignManage}
        setOpenManageCommercials={setOpenManageCommercials}
      />

      <Grid>
        <Grid container>
          <Grid
            item
            md={10}
            xs={12}
            sx={{
              pt: 2,
              pl: 1,
            }}
          >
            <Grid container justifyContent="space-between">
              <Typography
                color={
                  theme.palette.mode === "light" ? "TextSecondary" : "white"
                }
                variant="h4"
              >
                Statistiques de la campagne
              </Typography>

              <Grid
                sx={{
                  pt: 0,
                  pl: 1,
                }}
              >
                {user.access_level === 0 && (
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ m: 1 }}
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={handleOpenAddcommercials}
                  >
                    Ajouter un commercial / des commerciaux
                  </Button>
                )}
                {user.access_level === 0 && (
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ m: 1 }}
                    startIcon={<AttributionIcon fontSize="small" />}
                    onClick={handleOpenManageCommercials}
                  >
                    Gérer les commerciaux
                  </Button>
                )}
              </Grid>
            </Grid>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
              sx={{ m: 0 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard"
                variant="subtitle2"
              >
                Tableau de bord
              </Link>
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard/campaign"
                variant="subtitle2"
              >
                Campagne
              </Link>
              <Typography color="textSecondary" variant="subtitle2">
                Informations
              </Typography>
            </Breadcrumbs>
          </Grid>
          {user.id_service !== 0 && (
            <Grid
              item
              sx={{
                pt: 3,
                pl: 3,
              }}
            >
              <CampaignDetails />
            </Grid>
          )}
        </Grid>
        <Grid>
          {isEditing.name ? (
            <TextField
              sx={{ width: 950 }}
              label="Nom"
              type="text"
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleChangeData("name", campaignValues.name);
                }
              }}
              onChange={(event) => {
                const tempCampaignValues = { ...campaignValues };
                tempCampaignValues.name = event.target.value;
                setCampaignValues(tempCampaignValues);
              }}
              value={campaignValues.name}
              variant="outlined"
            />
          ) : (
            <Typography
              sx={{ m: 0, ml: 2 }}
              color="textPrimary"
              variant="h3"
              onDoubleClick={() =>
                user.access_level === 0 ? handleToggle("name") : null
              }
            >
              {campaignValues.name}
            </Typography>
          )}
        </Grid>
        <Grid sx={{ display: "flex" }}>
          <Grid>
            {isEditing.start_date ? (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr"
              >
                <DatePicker
                  label="Date de début"
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    const formattedDate = dayjs(date).format("YYYY/MM/DD");
                    setCampaignValues((prev) => ({
                      ...prev,
                      start_date: formattedDate,
                    }));
                    handleChangeData("start_date", formattedDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  value={dayjs(campaignValues.start_date)}
                />
              </LocalizationProvider>
            ) : (
              <Typography
                sx={{ m: 2 }}
                color="textPrimary"
                variant="h6"
                onDoubleClick={() => handleToggle("start_date")}
              >
                Date de début:{" "}
                {dayjs(campaignValues.start_date).format("DD/MM/YYYY")}
              </Typography>
            )}
          </Grid>
          <Grid>
            {isEditing.end_date ? (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr"
              >
                <DatePicker
                  label="Date de fin"
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                    const formattedDate = dayjs(date).format("YYYY/MM/DD");
                    setCampaignValues((prev) => ({
                      ...prev,
                      end_date: formattedDate,
                    }));
                    handleChangeData("end_date", formattedDate);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  value={dayjs(campaignValues.end_date)}
                />
              </LocalizationProvider>
            ) : (
              <Typography
                sx={{ m: 2 }}
                color="textPrimary"
                variant="h6"
                onDoubleClick={() => handleToggle("end_date")}
              >
                Date de fin:{" "}
                {dayjs(campaignValues.end_date).format("DD/MM/YYYY")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {user.access_level > 0 &&
            tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          {user.access_level === 0 &&
            tabs2.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
        </Tabs>
      </Box>
      <Divider />
      {currentTab === "statistiques" && (
        <CampaignStatsTab
          setOpenReinjection={setOpenReinjection}
          setOpenRetrieve={setOpenRetrieve}
          setSelectedCommercialReinjection={setSelectedCommercialReinjection}
          setSelectedCommercialRetrieve={setSelectedCommercialRetrieve}
          idCampaign={idCampaign}
          donnees={donnees}
          progressionProspects={progressionProspects}
        />
      )}
      {currentTab === "apprenants" && (
        <>
          {(user.access_level === 0 || user.id_service === 3) && (
            <Grid
              sx={{
                backgroundColor: "background.default",
                p: 1,
              }}
            >
              <CampaignLearner />
            </Grid>
          )}
        </>
      )}
      {currentTab === "logs" && (
        <>
          {user.access_level === 0 && (
            <Grid
              sx={{
                backgroundColor: "background.default",
                p: 1,
              }}
            >
              <CampaignLogs idCampaign={idCampaign} />
            </Grid>
          )}
        </>
      )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (themeLoader) => themeLoader.zIndex.drawer + 1,
        }}
        open={isLoadingPage}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default CampaignInfo;
