/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const CustomRepportDetailsLineChart = ({ timelinesData }) => {
  const theme = useTheme();
  const [selectedSeries, setSelectedSeries] = useState([
    'Organic',
    'Referral',
    'Social Media'
  ]);

  const handleChange = (event, name) => {
    if (!event.target.checked) {
      setSelectedSeries(selectedSeries.filter((item) => item !== name));
    } else {
      setSelectedSeries([...selectedSeries, name]);
    }
  };

  const chartSeries = timelinesData.series.filter((item) => selectedSeries.includes(item.name));

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: chartSeries.map((item) => item.color),
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    legend: {
      show: false
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2
      },
      radius: 2,
      shape: 'circle',
      size: 2,
      strokeWidth: 0
    },
    stroke: {
      curve: 'straight',
      lineCap: 'butt',
      width: 1
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: timelinesData.xaxis.dataPoints,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: [
      {
        axisBorder: {
          color: theme.palette.divider,
          show: true
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary
          }
        }
      },
      {
        axisTicks: {
          color: theme.palette.divider,
          show: true
        },
        axisBorder: {
          color: theme.palette.divider,
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary
          }
        },
        opposite: true
      }
    ]
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <Card>
        <CardHeader
          title="Variation mensuelle des ventes sur la période"
          style={{ textAlign: 'left' }}
        />
        <CardContent>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {timelinesData.series.map((item) => (
              <Box
                key={item.name}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  mr: 2
                }}
              >
                <Checkbox
                  checked={selectedSeries.some((visibleItem) => visibleItem === item.name)}
                  color="primary"
                  onChange={(event) => handleChange(event, item.name)}
                />
                <Box
                  sx={{
                    backgroundColor: item.color,
                    borderRadius: '50%',
                    height: 8,
                    ml: 1,
                    mr: 2,
                    width: 8
                  }}
                />
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <Chart
            height="400"
            options={chartOptions}
            series={chartSeries}
            type="area"
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomRepportDetailsLineChart;
