class EtablissementApi {
  async getEtablissements(accessToken) {
    const urlEtablissements = `${process.env.REACT_APP_API_URL}/api/etablissements`;

    const resp = await fetch(urlEtablissements, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getEtablissement(idEtablissement, accessToken) {
    const urlEtablissement = `${process.env.REACT_APP_API_URL}/api/etablissements/${idEtablissement}`;
    const resp = await fetch(urlEtablissement, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getEtablissementProfessionnel(etablissementId, accessToken) {
    const urlEtablissement = `${process.env.REACT_APP_API_URL}/api/etablissements/professionnel`;
    const valeus = {};
    valeus.etablissementId = etablissementId;
    // valeus.customerId = customerId;
    const resp = await fetch(urlEtablissement, {
      method: 'POST',
      body: JSON.stringify(valeus),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomersEtablissementProfessionnel(customerId, accessToken) {
    const urlEtablissement = `${process.env.REACT_APP_API_URL}/api/etablissements/customers-professionnel`;
    const valeus = {};
    valeus.customerId = customerId;
    // valeus.etablissementId = etablissementId;
    const resp = await fetch(urlEtablissement, {
      method: 'POST',
      body: JSON.stringify(valeus),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getGroupementsName(accessToken) {
    const urlGroupements = `${process.env.REACT_APP_API_URL}/api/groupements-name`;
    const resp = await fetch(urlGroupements, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async updateEtablissement(etablissementID, values, accessToken) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/etablissements/update-etablissement`;
    values.id = etablissementID;
    const resp = await fetch(URL_UPDATE, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async createEtablissement(values, accessToken) {
    const URL_CREATE = `${process.env.REACT_APP_API_URL}/api/etablissements/create-etablissement`;
    const resp = await fetch(URL_CREATE, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async addCustomer(values, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/etablissements/add-customer`;
    const resp = await fetch(URL_ADD, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }
}

export const etablissementApi = new EtablissementApi();
