/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Card, CardHeader, Container, Divider, Grid, Link, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import useMounted from '../../../hooks/useMounted';
import ChevronRight from '../../../icons/ChevronRight';
import PlusIcon from '../../../icons/Plus';
import useSettings from '../../../hooks/useSettings';
import gtm from '../../../lib/gtm';
import useAuth from '../../../hooks/useAuth';
import CustomReportList from './components/CustomReportList';

const CustomRepports = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const [customers, setCustomers] = useState([]);
  const [sales, setSales] = useState(0);
  const [salesAmount, setSalesAmount] = useState(0);
  const [progession, setProgession] = useState(0);
  const [status, setStatus] = useState('');
  const [year, setYear] = useState();
  const [agreementSent, setAgreementSent] = useState();
  const [honorSent, setHonorSent] = useState();
  const [certificateSent, setCertificateSent] = useState();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const location = useLocation();

  return (
    <>
      <Helmet>
        <title>Rapports personnalisés | Apteed</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4,
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Rapports personnalisés
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/custom-reports"
                  variant="subtitle2"
                >
                  Rapports
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Rapports personnalisés
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  component={RouterLink}
                  startIcon={<PlusIcon fontSize="small" />}
                  to="/dashboard/custom-reports/create"
                  sx={{ m: 1 }}
                  variant="outlined"
                >
                  Créer un rapport
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={4}
            sx={{ marginTop: '0px' }}
          >
            <Box
              sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                width: '100%',
                p: 3
              }}
            >
              <CustomReportList />
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CustomRepports;
