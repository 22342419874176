/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Card,
  Collapse,
  createFilterOptions,
  IconButton,
  Link,
  TableCell,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DataGrid, frFR, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import * as moment from 'moment';
import toast from 'react-hot-toast';
import useAuth from '../../../../hooks/useAuth';
import { reportsApi } from '../../../../api/reportsApi';
import { sessionApi } from '../../../../api/sessionApi';
import Label from '../../../../components/Label';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FileDownloadOutlined } from '@material-ui/icons';
import { Chip } from '@mui/material';

const detailStyles = {
  // borderTop: '2px solid',
  borderTopColor: 'primary.main',
  pt: 1,
  pb: 1,
  whiteSpace: 'pre-line',
};

const CustomReportList = () => {
  const [reports, setReports] = useState([]);
  const [rows, setRows] = useState([]);
  const [exporting, setExporting] = useState(false);
  const { user } = useAuth();
  const [clickedId, setClickedId] = useState(-1);
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const filterOptions = createFilterOptions({
    limit: 100,
    stringify: (option) => `${option.title}`,
  });

  // initialisation de la liste d'apprenants
  useEffect(async () => {
    const data = await reportsApi.getCustomReportsList(localStorage.getItem('accessToken'));
    setRows(data.map((item) => ({
      id: item.id,
      title: item.title,
      type: item.type,
      description: item.description,
      uFirstname: item.u_firstname,
      uLastname: item.u_lastname,
      startDate: item.startDate !== null ? moment(item.startDate).format('DD/MM/YYYY') : '',
      endDate: item.endDate !== null ? moment(item.endDate).format('DD/MM/YYYY') : '',
      createdAt: item.created_at !== null ? moment(item.created_at).format('DD/MM/YYYY') : '',
    })));

    console.clear();
    console.log(data);
  }, []);

  const columns = [
    {
      field: 'title',
      headerName: 'Titre du rapport',
      renderCell: (cellValues) => (
        <>
          <Typography
            variant="subtitle2"
          >
            <Link
              to={`/dashboard/custom-reports/${cellValues.row.id}`}
              component={RouterLink}
            >
              <span style={{ whiteSpace: 'pre-line' }}>
                {`${cellValues.row.title}`}
              </span>
            </Link>
          </Typography>
        </>
      ),
      width: 380,
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      renderCell: (cellValues) => {
        const statut = (
          <>
            <Chip
              color="success"
              size="small"
              variant="outlined"
              label={cellValues.row.type}
            />
          </>
        );
        return (statut);
      },
      editable: true,
      width: 150,
    },
    {
      field: 'description',
      headerName: 'Commentaire',
      type: 'singleSelect',
      renderCell: (cellValues) => (
        (
          <>
            <Box sx={detailStyles}>
              <span style={{ whiteSpace: 'pre' }}>
                { cellValues.row.description ? cellValues.row.description.split(' ').slice(0, 22).join(' ') : '---------------------------------------------------------------------'}
              </span>
            </Box>
          </>
        )
      ),
      width: 500,
    },
    {
      field: 'createdAt',
      headerName: 'Créé le',
      type: 'dateTime',
      renderCell: (cellValues) => {
        const statut = (
          <>
            {cellValues.row.createdAt}
          </>
        );
        return (statut);
      },
      width: 160,
    },
    {
      field: 'uLastname',
      headerName: 'Par',
      type: 'text',
      renderCell: (cellValues) => (
        <>
          <Typography variant="subtitle2">
            {cellValues.row.uFirstname}
            {' '}
            {cellValues.row.uLastname}
            {' '}
          </Typography>
        </>
      ),
      width: 220,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 120,
      cellClassName: 'actions',
      getActions: (params) => [
        <TableCell align="right">
          {/* <IconButton
            sx={{ mr: 2 }}
            variant="outlined"
            component={RouterLink}
            to={`/dashboard/custom-reports/${params.row.id}`}
          >
            <FileCopyIcon fontSize="small" />
          </IconButton> */}
          {/* <IconButton
            sx={{ mr: 2 }}
            variant="outlined"
            component={RouterLink}
            to={`/dashboard/custom-reports/download/${params.row.id}`}
          >
            <FileDownloadOutlined fontSize="small" />
          </IconButton> */}
          <IconButton
            variant="outlined"
            component={RouterLink}
            to={`/dashboard/custom-reports/${params.row.id}`}
          >
            <ArrowRightAltIcon fontSize="small" />
          </IconButton>
        </TableCell>
      ],
    },
  ];

  return (
    <>
      <Card>
        <Box sx={{ mx: 1 }}>
          <DataGrid
            density="confortable"
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            initialState={{
              sorting: {
                sortModel: [{ field: 'report', sort: 'asc' }],
              },
            }}
            autoHeight
            rowHeight={70}
            // eslint-disable-next-line consistent-return
            getRowHeight={({ id, densityFactor }) => {
              if (id === clickedId) {
                return 250 * densityFactor;
              }
            }}
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            components={{ Toolbar: CustomToolbar }}
          />
        </Box>
      </Card>
    </>
  );
};

export default CustomReportList;
