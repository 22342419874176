import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Card, Grid, TextField } from '@material-ui/core';
import { sessionApi } from '../../../api/sessionApi';

const SessionEditForm = (props) => {
  const { session, ...other } = props;
  const { sessionId } = useParams();
  return (
    <Formik
      initialValues={{
        numberSession: session.number_session,
        status: session.status,
        startDate: session.start_date,
        endDate: session.end_date,
        submit: ''
      }}
      enableReinitialize
      validationSchema={Yup
        .object()
        .shape({
          numberSession: Yup
            .string()
            .max(255)
            .required('Le N° de session est requis'),
          status: Yup
            .string()
            .max(255)
            .required('Le status est requis'),
          startDate: Yup.string().max(100),
          endDate: Yup.string().max(100),
        },)}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await sessionApi.updateSession(sessionId, values, localStorage.getItem('accessToken'));
          setStatus({ success: true });
          setSubmitting(false);
          toast.success('Apprenant mis à jour !');
        } catch (err) {
          console.error(err);
          toast.error('Il y a eu un souci lors de la mise à jour !');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          onSubmit={handleSubmit}
          {...other}
        >
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.numberSession && errors.numberSession)}
                    fullWidth
                    helperText={touched.numberSession && errors.numberSession}
                    label="N° de session"
                    name="numberSession"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.number_session}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.status && errors.status)}
                    fullWidth
                    helperText={touched.status && errors.status}
                    label="status"
                    name="status"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.status}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.startDate && errors.startDate)}
                    fullWidth
                    helperText={touched.startDate && errors.startDate}
                    label="Date de début"
                    name="startDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.start_date}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.endDate && errors.endDate)}
                    fullWidth
                    helperText={touched.endDate && errors.endDate}
                    label="Date de fin"
                    name="endDate"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.end_date}
                    variant="outlined"
                  />
                </Grid>
                <Grid item />
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Enregistrer les modifications
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

SessionEditForm.propTypes = {
  session: PropTypes.object.isRequired
};

export default SessionEditForm;
