import { Button } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { campaignApi } from '../../api/campaignApi';
import useMounted from '../../hooks/useMounted';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import * as moment from 'moment';

const CampaignDetails = () => {
  const mounted = useMounted();
  const { user } = useAuth();
  const [campaignInfos, setCampaignInfos] = useState([]);
  const [campaignDates, setCampaignDates] = useState({});
  const { idCampaign } = useParams();
  const [isTreated, setIsTreated] = useState(false);
  const [isFinished, setIsFinished] = useState(true);
  const [nextCustomer, setNextCustomer] = useState({});
  const [nextCustomerTreatedBegun, setNextCustomerTreatedBegun] = useState({});
  const [idNextCustomer, setIdNextCustomer] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [isCommercialDeleted, setIsCommercialDeleted] = useState(true);

  const getCampaignInfos = useCallback(async () => {
    try {
      const data = await campaignApi.getCampaignInfos(idCampaign, localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('La campagne n\'existe pas');
      }

      if (mounted.current) {
        setCampaignInfos(data.campaigns);
        setNextCustomer(data.nextCustomer);
        setNextCustomerTreatedBegun(data.nextCustomerTreatedBegun);
        setCampaignDates(data.campaignDates);
        setCampaignType(data.type);
        setIsCommercialDeleted(data.isCommercialDeleted);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCampaignInfos();
  }, [getCampaignInfos]);

  useEffect(() => {
    if (campaignInfos.length > 0) {
      // parcourir les apprenants pour voir s'il y en a qui ont déjà été traités ou pas
      campaignInfos.forEach((info) => {
        if (info.treated) {
          setIsTreated(true);
        } else {
          setIsFinished(false);
        }
      });
    }
  }, [campaignInfos]);

  useEffect(() => {
    // si l'apprenant(s) a/ont déjà un temps attribué, on continuera la campagne par lui/eux en premier
    if (nextCustomerTreatedBegun !== null) {
      setIdNextCustomer(nextCustomerTreatedBegun.customers_id);
    } else if (nextCustomer !== null) {
      // sinon on prend l'apprenant suivant non traité
      setIdNextCustomer(nextCustomer.customers_id);
    }
  }, [nextCustomerTreatedBegun, nextCustomer]);

  return (
    <>
      {!isFinished && !isCommercialDeleted && campaignType === 'campagne' && moment().format('YYYY-MM-DD') >= campaignDates.start_date && moment().format('YYYY-MM-DD') <= campaignDates.end_date && user.access_level !== 0 && (
      <Button
        component={RouterLink}
        to={`/dashboard/campaign/${idCampaign}/customer/${idNextCustomer}`}
        color="primary"
        variant="contained"
        sx={{ mt: 1, mb: 3 }}
      >
        {
              isTreated ? 'Continuer la campagne' : 'Commencer la campagne'
            }
      </Button>
      )}
      {!isFinished && campaignType === 'réinjection' && moment().format('YYYY-MM-DD') >= campaignDates.start_date && moment().format('YYYY-MM-DD') <= campaignDates.end_date && user.access_level !== 0 && (
      <Button
        component={RouterLink}
        to={`/dashboard/campaign/${idCampaign}/customer/${idNextCustomer}/status/all`}
        color="primary"
        variant="contained"
        sx={{ mt: 1, mb: 3 }}
      >
        {
              isTreated ? 'Continuer la campagne' : 'Commencer la campagne'
            }
      </Button>
      )}
    </>

  );
};

export default CampaignDetails;
