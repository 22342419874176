/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
import { Typography, Card, TableRow, TableCell, Table, TableHead, TableBody, TableContainer, Paper, Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CampaignStatusList = (props) => {
  const { statusAndSource, qualification, commercial } = props;
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState([]);

  useEffect(() => {
    if (qualification !== undefined) {
      if (qualification.length > 0) {
        const tempRows = [];
        const total = { id: 800, name: 'Total' };
        qualification.forEach((qual) => {
          total[qual] = { number: 0 };
        });
        let subTotalHRow = 0;
        // eslint-disable-next-line array-callback-return
        Object.keys(statusAndSource).map((source) => {
          let row = { id: source, name: source };
          const totalHRow = { number: 0 };
          qualification.forEach((status) => {
            row[status] = statusAndSource[source][status];
            total[status].number += statusAndSource[source][status].number;
            totalHRow.number += statusAndSource[source][status].number;
          });
          subTotalHRow += totalHRow.number;

          // partie historique
          const historic = {};
          Object.keys(statusAndSource[source].historique).forEach((date) => {
            historic[date] = statusAndSource[source]['historique'][date];
            let totalNumber = 0;
            Object.keys(statusAndSource[source]['historique'][date]).forEach((data) => {
              totalNumber += statusAndSource[source]['historique'][date][data].number;
            });
            historic[date].total = { number: totalNumber };
          });

          row = { ...row, totalH: totalHRow, historic };
          tempRows.push(row);
        });
        total.totalH = { number: subTotalHRow };
        tempRows.push(total);

        setRows(tempRows);
        const tempOpen = [];
        Object.keys(statusAndSource).forEach(() => tempOpen.push(false));
        tempOpen.push(false);
        setOpen(tempOpen);
      }
    }
  }, [qualification]);

  const handleOpen = (index) => {
    const tempOpen = [...open];
    tempOpen[index] = !tempOpen[index];
    setOpen(tempOpen);
  };

  return (
    <Card>
      <Typography
        variant="h6"
        sx={{ p: 2 }}
      >
        Nombre de prospects par source et par statut
      </Typography>
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nom</TableCell>
              {qualification.map((status) => (
                <TableCell
                  align="right"
                >
                  {status}
                </TableCell>
              ))}
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <>
                <TableRow
                  key={row.name}
                  sx={{ '& > *': { borderBottom: 'unset' }, borderBottom: 'unset' }}
                >
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpen(index)}
                    >
                      {open[index] && row.name !== 'Total' ? <KeyboardArrowUpIcon /> : !open[index] && row.name !== 'Total' ? <KeyboardArrowDownIcon /> : ''}
                    </IconButton>
                  </TableCell>
                  {open[index] ? (
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <Typography
                        fontSize={24}
                        fontWeight="bold"
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <Typography>{row.name}</Typography>
                    </TableCell>
                  )}
                  {qualification.map((status) => <TableCell align="right">{row[status].number === null ? 0 : row[status].number}</TableCell>)}
                  <TableCell align="right">{row.totalH.number === undefined ? 0 : row.totalH.number}</TableCell>
                </TableRow>
                <TableRow>
                  {row.name !== 'Total' && (
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={15}
                  >
                    <Collapse
                      in={open[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Historique
                        </Typography>
                        <Table
                          aria-label="purchases"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Niveau de réinjection</TableCell>
                              {qualification.map((status) => <TableCell align="right">{status}</TableCell>)}
                              <TableCell align="right">Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.historic !== undefined && (
                              Object.keys(row.historic).map((histoData) => (
                                <TableRow>
                                  <TableCell align="right">{histoData}</TableCell>
                                  {Object.keys(row.historic[histoData]).map((status) => (
                                    <TableCell align="right">{row.historic[histoData][status] === undefined && row.historic[histoData][status].number === null ? 0 : row.historic[histoData][status].number}</TableCell>
                                  ))}
                                </TableRow>
                              )))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                  )}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
CampaignStatusList.propTypes = {
  statusAndSource: PropTypes.array.isRequired,
  qualification: PropTypes.array.isRequired,
  commercial: PropTypes.array.isRequired,
};

export default CampaignStatusList;
