import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useLocation } from 'react-router';
import useMounted from '../../../hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { usersApi } from '../../../api/usersApi';

const UserFunctionSettings = (props) => {
  const path = useLocation();
  const userId = path.pathname.split('/')[3];
  const [userToUpdate, setUserToUpdate] = useState({});
  const mounted = useMounted();

  const getUserInfos = useCallback(async () => {
    try {
      const data = await usersApi.getUserInfos(window.localStorage.getItem('accessToken'), userId);
      if (mounted.current) {
        setUserToUpdate(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getUserInfos();
  }, [getUserInfos]);

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{ mt: 1 }}
        {...props}
      >
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <Card>
            <CardContent>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    border: (theme) => `1px dashed ${theme.palette.divider}`,
                    borderRadius: '50%'
                  }}
                >
                  <Avatar
                    src={userToUpdate.avatar}
                    sx={{
                      height: 140,
                      width: 140
                    }}
                  />
                </Box>
                <Typography
                  color="textPrimary"
                  sx={{ mt: 1 }}
                  variant="subtitle2"
                >
                  {userToUpdate.firstname}
                  {' '}
                  {userToUpdate.lastname}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {userToUpdate.functions}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                fullWidth
                variant="text"
              >
                Changer de photo
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid
          item
          lg={8}
          md={6}
          xl={9}
          xs={12}
        >
          <Formik
            enableReinitialize
            initialValues={{
              functions: userToUpdate.functions || '',
              id_service: userToUpdate.id_service || '',
              access_level: userToUpdate.access_level || '',
              submit: null
            }}
            validationSchema={Yup
              .object()
              .shape({
                functions: Yup.string().max(255),
                id_service: Yup.number(),
                access_level: Yup.number(),

              })}
            onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
              try {
                await usersApi.updateUserFunction(window.localStorage.getItem('accessToken'), values, userId);
                // mettre à jour après le submit les données des champs
                const data = await usersApi.getUserInfos(window.localStorage.getItem('accessToken'), userId);
                setUserToUpdate(data.data);
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                toast.success('Profil mis à jour !');
              } catch (err) {
                console.error(err);
                toast.error('Erreur lors de la mise à jour');
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({ errors, handleChange, handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardHeader title="Fonction" />
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      spacing={4}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Autocomplete
                          getOptionLabel={(option) => option.text}
                          options={[{ id: 0, text: '0', value: '0' }, { id: 1, text: '1', value: '1' }]}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              label="Access level"
                              name="access_level"
                              onChange={handleChange}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              value={values.access_level}
                              defaultValue={userToUpdate.access_level}
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>
                        {errors.submit}
                      </FormHelperText>
                    </Box>
                    )}
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Enregistrer les modifications
                    </Button>
                  </Box>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default UserFunctionSettings;
