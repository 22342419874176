/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Box, Button, Grid, Switch, TextField, Typography } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useEffect, useState } from 'react';

import { getYearFromDateString } from '../../utils/dateFormatting';

export const DeliveryGrid = (props) => {
  const {
    deliveryId,
    name,
    startContract,
    endContract,
    price,
    startDelivery,
    endDelivery,
    partnerName,
    destination,
    handleModify,
    handleDelete,
    updateDeliveriesFormValues,
  } = props;

  const noEndDelivery = endDelivery === null || endDelivery === startDelivery;
  const [isPeriod, setIsPeriod] = useState(!noEndDelivery);
  const [start, setStart] = useState(startDelivery);
  const [nameForm, setNameForm] = useState(name);
  const [priceForm, setPriceForm] = useState(price);
  const [endDeliveryForm, setEndDeliveryForm] = useState(endDelivery);
  const [isStartDateError, setIsStartDateError] = useState(false);
  const [isEndDateError, setIsEndDateError] = useState(false);
  const [isEndBeforeBeginning, setIsEndBeforeBeginning] = useState(false);

  useEffect(() => {
    if (startDelivery !== start || nameForm !== name || priceForm !== price || endDeliveryForm !== endDelivery) {
      setStart(startDelivery);
      setNameForm(name);
      setPriceForm(price);
      setEndDeliveryForm(endDelivery);
      setIsPeriod(!noEndDelivery);
    }
  }, [updateDeliveriesFormValues]);

  const invalidDateSentence = 'Non enregistré : date invalide !';
  const endBeforeBeginningSentence = 'Non enregistré : la date de fin est avant la date de début !';

  const handlePeriod = () => {
    setIsPeriod(!isPeriod);
  };

  const handleStart = (e) => {
    setStart(e.target.value);
  };

  useEffect(() => {
    if (isPeriod === false && !noEndDelivery) {
      setEndDeliveryForm(start);
      handleModify('end_delivery', deliveryId, start);
    }
  }, [isPeriod]);

  const handleNameBlur = () => {
    handleModify('name', deliveryId, nameForm);
  };

  const handlePriceBlur = () => {
    handleModify('price', deliveryId, priceForm);
  };

  const handleStartDateBlur = () => {
    const yearStart = getYearFromDateString(start);

    const regExp = new RegExp('^(20|19)[0-9][0-9]$');

    if (regExp.test(yearStart)) {
      if (isStartDateError) setIsStartDateError(false);
      if (start !== startDelivery) {
        handleModify('start_delivery', deliveryId, start);
        if (!isPeriod) handleModify('end_delivery', deliveryId, start);
      }
      if (new Date(start) > new Date(endDeliveryForm)) {
        setEndDeliveryForm(start);
        handleModify('end_delivery', deliveryId, start);
      }
    } else {
      setIsStartDateError(true);
    }
  };

  const handleEndDateBlur = () => {
    const yearEnd = getYearFromDateString(endDeliveryForm);

    const regExp = new RegExp('^(20|19)[0-9][0-9]$');

    if (!regExp.test(yearEnd)) {
      setIsEndDateError(true);
    } else if (new Date(start) > new Date(endDeliveryForm)) {
      if (isEndDateError) setIsEndDateError(false);
      setIsEndBeforeBeginning(true);
    } else {
      if (isEndBeforeBeginning) setIsEndBeforeBeginning(false);
      if (isEndDateError) setIsEndDateError(false);
      if (endDeliveryForm !== endDelivery) {
        handleModify('end_delivery', deliveryId, endDeliveryForm);
      }
    }
  };

  return (
    <Grid
      container
      spacing={2}
      marginTop={3}
      marginBottom={3}
      key={deliveryId}
    >
      <Grid
        item
        xs={3}
        display="inline"
      >
        <TextField
          onBlur={handleNameBlur}
          label="Nom de la prestation"
          fullWidth
          value={nameForm}
          onChange={(e) => setNameForm(e.target.value)}
        />
        <TextField
          onBlur={handlePriceBlur}
          margin="dense"
          label="Coût de la prestation (en euro)"
          name="price"
          type="number"
          value={priceForm}
          fullWidth
          variant="standard"
          onChange={(e) => setPriceForm(e.target.value)}
        />
      </Grid>
      <Grid
        item
        xs={6}
        display="inline"
        sx={{ display: 'flex', justifyContent: 'space-around' }}
      >
        <Grid
          item
          display="inline"
          xs={4}
        >
          <span>Jour précis</span>
          <Switch
            checked={isPeriod}
            onChange={handlePeriod}
          />
          <span>Période</span>
        </Grid>
        <Grid
          item
          display="inline"
          xs={8}
          sx={{ display: 'flex', justifyContent: 'space-around' }}
        >
          <Box>
            <TextField
              label={
              isPeriod
                ? 'Date du début de la prestation'
                : 'Date de la prestation'
            }
              type="date"
              InputProps={{
                inputProps: {
                  min: startContract,
                  max: endContract,
                },
              }}
              value={start}
              onChange={handleStart}
              onBlur={handleStartDateBlur}
            />
            {isStartDateError && <Typography sx={{ textAlign: 'center', fontSize: 15, fontWeight: 'bold', color: 'error' }}>{invalidDateSentence}</Typography>}
          </Box>
          {isPeriod && (
            <Box>
              <TextField
                label="Date de fin de prestation"
                InputProps={{
                  inputProps: {
                    min: startDelivery,
                    max: endContract,
                  },
                }}
                type="date"
                value={endDeliveryForm}
                onChange={(event) => setEndDeliveryForm(event.target.value)}
                onBlur={handleEndDateBlur}
              />
              {isEndDateError && <Typography sx={{ textAlign: 'center', fontSize: 15, color: 'error.main' }}>{invalidDateSentence}</Typography>}
              {isEndBeforeBeginning && !isEndDateError && <Typography sx={{ textAlign: 'center', fontSize: 15, color: 'error.main', width: '10rem' }}>{endBeforeBeginningSentence}</Typography>}
            </Box>
          // </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        display="block"
        xs={2}
      >
        <Grid
          item
          sx={{ textAlign: 'center' }}
        >
          Destination :
        </Grid>
        <Grid
          item
          xs={12}
          display="inline"
        >
          <span>{partnerName}</span>

          <Button
            onClick={() => {
              handleModify('destination', deliveryId);
            }}
          >
            {destination ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </Button>

          <span>Apteed</span>
        </Grid>
      </Grid>
      <Grid
        item
        xs={1}
        display="inline"
      >
        <Button onClick={() => handleDelete(deliveryId)}>
          <DeleteIcon />
        </Button>
      </Grid>
    </Grid>
  );
};
