/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable global-require */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import Docxtemplater from "docxtemplater";
import FileSaver from "file-saver";
import JSZip from "jszip";
import PizZip from "pizzip";
import { read, utils, write, writeFileXLSX } from "xlsx";

/* eslint-disable no-undef */
class CustomerApi {
  async getCustomers(
    accessToken,
    query = "",
    sort = null,
    source = "all",
    campaign = "all",
    callTrack = "all",
    newCallTrack = "all",
    callTrackRelaunch = "all",
    progress = "all",
    endSessions = "all",
    relaunch = "all",
    profession = "all",
    limit = 25,
    page = 0,
    relaunchMode = false,
    isSearching = true
  ) {
    const urlCustomers = `${process.env.REACT_APP_API_URL}/api/customers`;
    const values = {};
    values.query = query;
    values.sort = sort;
    values.source = source;
    values.campaign = campaign;
    values.callTrack = callTrack;
    values.newCallTrack = newCallTrack;
    values.callTrackRelaunch = callTrackRelaunch;
    values.progress = progress;
    values.endSessions = endSessions;
    values.relaunch = relaunch;
    values.profession = profession;
    values.limit = limit;
    values.page = page;
    values.relaunchMode = relaunchMode;
    values.isSearching = isSearching;
    const resp = await fetch(urlCustomers, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomersInfos(
    accessToken,
    query = "",
    sort = null,
    source = "all",
    campaign = "all",
    callTrack = "all",
    newCallTrack = "all",
    callTrackRelaunch = "all",
    progress = "all",
    endSessions = "all",
    relaunch = "all",
    profession = "all",
    limit = 25,
    page = 0,
    relaunchMode = false,
    isSearching = true
  ) {
    const urlCustomers = `${process.env.REACT_APP_API_URL}/api/customersInfos`;
    const values = {};
    values.query = query;
    values.sort = sort;
    values.source = source;
    values.campaign = campaign;
    values.callTrack = callTrack;
    values.newCallTrack = newCallTrack;
    values.callTrackRelaunch = callTrackRelaunch;
    values.progress = progress;
    values.endSessions = endSessions;
    values.relaunch = relaunch;
    values.profession = profession;
    values.limit = limit;
    values.page = page;
    values.relaunchMode = relaunchMode;
    values.isSearching = isSearching;
    const resp = await fetch(urlCustomers, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomer(idCustomer, accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/customers/${idCustomer}`;

    const resp = await fetch(urlCustomer, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getDisplayPasswordsCustomer(accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/customers/password/getDisplayPasswordsCustomer`;

    const resp = await fetch(urlCustomer, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  // async getCustomerLogs(idCustomer, accessToken) {
  //   const urlCustomer = `${process.env.REACT_APP_API_URL}/api/customers/logs/${idCustomer}`;

  //   const resp = await fetch(urlCustomer, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${accessToken}`
  //     }
  //   });

  //   const json = await resp.json();

  //   return Promise.resolve(json.data);
  // }

  async getCustomerLogs(
    customerId,
    sort = null,
    user,
    category,
    type,
    limit = 25,
    page = 0,
    accessToken
  ) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/customers/logs`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.customerId = customerId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomerLogsInfos(idCustomer, accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/customers/logs/infos/${idCustomer}`;

    const resp = await fetch(urlCustomer, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCommentsCommercialUser(idCustomer, userId, accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/customers/get-comments-commercial-user`;
    const values = {};
    values.customerId = idCustomer;
    values.userId = userId;
    const resp = await fetch(urlCustomer, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomerCampagne(idCustomer, accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/campagne/${idCustomer}`;

    const resp = await fetch(urlCustomer, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomersCampagne(
    accessToken,
    query = null,
    sort = null,
    source = "all",
    campaign = "all",
    callTrack = "all",
    newCallTrack = "all",
    callTrackRelaunch = "all",
    progress = "all",
    endSessions = "all",
    relaunch = "all",
    profession = "all",
    limit = 25,
    page = 0,
    relaunchMode = false,
    isSearching = true
  ) {
    const urlCampagne = `${process.env.REACT_APP_API_URL}/api/campagne`;
    const values = {};
    values.query = query;
    values.sort = sort;
    values.source = source;
    values.campaign = campaign;
    values.callTrack = callTrack;
    values.newCallTrack = newCallTrack;
    values.callTrackRelaunch = callTrackRelaunch;
    values.progress = progress;
    values.endSessions = endSessions;
    values.relaunch = relaunch;
    values.profession = profession;
    values.limit = limit;
    values.page = page;
    values.relaunchMode = relaunchMode;
    values.isSearching = isSearching;

    const resp = await fetch(urlCampagne, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async updateCustomer(customerId, values, accessToken) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/customers/update-customer`;
    values.id = customerId;
    const resp = await fetch(URL_UPDATE, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async updateByRpps(customerId, rpps, accessToken) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/customers/update-by-rpps`;
    const values = { id: customerId, adeliRpps: rpps };
    const resp = await fetch(URL_UPDATE, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async createCustomer(values, accessToken) {
    const URL_CREATE = `${process.env.REACT_APP_API_URL}/api/customers/create-customer`;
    const resp = await fetch(URL_CREATE, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async addCommentRelance(customerId, userId, values, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/customers/add-comment-relance`;
    values.customerId = customerId;
    values.userId = userId;
    const resp = await fetch(URL_ADD, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async addCommentRelanceSpecial(
    customerId,
    userId,
    comment,
    qualification,
    accessToken
  ) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/customers/add-comment-relance-special`;
    const values = {};
    values.customerId = customerId;
    values.userId = userId;
    values.qualification = qualification;
    values.comment = comment;
    const resp = await fetch(URL_ADD, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async addCommentCommercial(customerId, userId, values, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/customers/add-comment-commercial`;
    values.customerId = customerId;
    values.userId = userId;
    console.log(values);
    const resp = await fetch(URL_ADD, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async saveCommentCommercialId(
    commentId,
    customerId,
    campaignId,
    userId,
    accessToken
  ) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/customers/save-comment-commercial-id`;
    const values = {};
    values.customerId = customerId;
    values.userId = userId;
    values.commentId = commentId;
    values.campaignId = campaignId;
    const resp = await fetch(URL_ADD, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async setCustomerTreated(customerId, campaignId, userId, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/customers/set-customer-treated`;
    const values = {};
    values.customerId = customerId;
    values.userId = userId;
    values.campaignId = campaignId;
    const resp = await fetch(URL_ADD, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async setCustomerUntreated(customerId, campaignId, userId, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/customers/set-customer-untreated`;
    const values = {};
    values.customerId = customerId;
    values.userId = userId;
    values.campaignId = campaignId;
    const resp = await fetch(URL_ADD, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async saveCommentCommercialIdReinj(
    commentId,
    customerId,
    campaignId,
    userId,
    accessToken
  ) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/customers/save-comment-commercial-id-reinj`;
    const values = {};
    values.customerId = customerId;
    values.userId = userId;
    values.commentId = commentId;
    values.campaignId = campaignId;
    const resp = await fetch(URL_ADD, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async deleteCustomerFormation(id, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/customers/delete-customer-formation`;
    const resp = await fetch(URL_DELETE, {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async deleteCommentRelance(id, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/customers/delete-comment-relance`;
    const resp = await fetch(URL_DELETE, {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async deleteCommentCommercial(id, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/customers/delete-comment-commercial`;
    const resp = await fetch(URL_DELETE, {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async deleteCustomer(customerId, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/customers/delete-customer`;
    const resp = await fetch(URL_DELETE, {
      method: "POST",
      body: JSON.stringify({ id: customerId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async sendDoc(customerId, sourceId, values, accessToken) {
    const URL_CREATE_DOC = `${process.env.REACT_APP_API_URL}/api/customers/create-document`;
    const data = new FormData();
    values.forEach((value, index) => {
      data.append(`asset${index}`, value.file);
      data.append(`name${index}`, value.name);
      data.append(`type${index}`, value.type);
    });
    data.append("sourceId", sourceId);
    data.append("customerId", customerId);
    const resp = await fetch(URL_CREATE_DOC, {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async deleteDoc(data, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/customers/delete-document`;
    const resp = await fetch(URL_DELETE, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async assignCampaign(userAssigned, selectedCustomers, accessToken) {
    const URL_ASSIGN = `${process.env.REACT_APP_API_URL}/api/customers/assign-campaign`;
    const values = {};
    values.userAssigned = userAssigned;
    values.selectedCustomers = selectedCustomers;
    const resp = await fetch(URL_ASSIGN, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async assignRelance(userAssigned, selectedCustomers, accessToken) {
    const URL_ASSIGN = `${process.env.REACT_APP_API_URL}/api/customers/assign-relance`;
    const values = {};
    values.userAssigned = userAssigned;
    values.selectedCustomers = selectedCustomers;
    const resp = await fetch(URL_ASSIGN, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return json;
  }

  async getProspect(
    accessToken,
    query = null,
    sort = null,
    source = null,
    campaign = null,
    callTrack = null,
    newCallTrack = null,
    callTrackRelaunch = null,
    progress = null,
    endSessions = null,
    relaunch = null,
    profession = null,
    limit = 25,
    page = 0,
    relaunchMode = false
  ) {
    const urlCustomers = `${process.env.REACT_APP_API_URL}/api/customers/prospect`;
    const values = {};
    values.query = query;
    values.sort = sort;
    values.source = source;
    values.campaign = campaign;
    values.callTrack = callTrack;
    values.newCallTrack = newCallTrack;
    values.callTrackRelaunch = callTrackRelaunch;
    values.progress = progress;
    values.endSessions = endSessions;
    values.relaunch = relaunch;
    values.profession = profession;
    values.limit = limit;
    values.page = page;
    values.relaunchMode = relaunchMode;
    const resp = await fetch(urlCustomers, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getLogs(
    accessToken,
    customerId,
    sort = null,
    user,
    category,
    type,
    limit = 25,
    page = 0
  ) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/customers/logs/filtered`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.customerId = customerId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async sendAgreement(id, accessToken) {
    const URL = `${process.env.REACT_APP_API_URL}/api/agreements/build`;
    const resp = await fetch(URL, {
      method: "POST",
      body: JSON.stringify({ learnerSessionId: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    console.clear();
    console.log(json);
    return json;
  }

  async downloadAgrement(id, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/agreements/download`;
    const token = accessToken;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ learnerSessionId: id }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      if (!response.ok) {
        return data;
      }

      const binaryString = atob(data.file);

      // Conversion Base64 en octets (ArrayBuffer)
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Décompression du contenu Base64 avec PizZip
      const zip = new PizZip(bytes);

      // Récupération du contenu du document Word
      const content = zip.file("word/document.xml").asText();

      // Utilisation de docxtemplater pour générer le fichier .docx
      const doc = new Docxtemplater();
      doc.loadZip(zip);
      doc.setData({
        /* Vos données à remplir dans le document */
      });
      try {
        doc.render();
      } catch (error) {
        const e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
          properties: error.properties,
        };
        console.log(JSON.stringify({ error: e }));
        // Gestion des erreurs ici
        throw error;
      }

      // Génération du document final
      const output = doc.getZip().generate({ type: "uint8array" });

      // Création d'un objet Blob à partir du contenu généré
      const blob = new Blob([output], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Utilisation de FileSaver.js pour télécharger le fichier
      FileSaver.saveAs(blob, data.path);

      console.clear();
      return data;
    } catch (error) {
      // Gérer les erreurs ici
      console.error(error);
      throw error;
    }
  }

  async downloadMultipleAgreements(ids, sessionId, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/agreements/download`;
    const zip = new JSZip();
    const results = [];

    try {
      for (const id of ids) {
        try {
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({ learnerSessionId: id }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to download agreement for ID: ${id}`);
          }

          const data = await response.json();
          const binaryString = atob(data.file);

          // Conversion Base64 en octets (ArrayBuffer)
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Décompression du contenu Base64 avec PizZip
          const docZip = new PizZip(bytes);

          // Utilisation de docxtemplater pour générer le fichier .docx
          const doc = new Docxtemplater();
          doc.loadZip(docZip);
          doc.setData({
            /* Vos données à remplir dans le document */
          });
          doc.render();

          // Génération du document final
          const output = doc.getZip().generate({ type: "uint8array" });

          // Ajout du fichier .docx au ZIP avec un nom unique
          zip.file(`${data.path}`, output);

          // Enregistrement du succès
          results.push({ id, status: "success" });
        } catch (error) {
          console.error(`Error processing ID: ${id}`, error);
          // Enregistrement de l'échec
          results.push({ id, status: "error", message: error.message });
        }
      }

      // Génération du fichier ZIP et téléchargement s'il y a des fichiers réussis
      if (results.some((result) => result.status === "success")) {
        const zipBlob = await zip.generateAsync({ type: "blob" });
        FileSaver.saveAs(zipBlob, `conventions-session-${sessionId}.zip`);
      }

      console.clear();
    } catch (error) {
      console.error("Error during the batch download process:", error);
      results.push({ globalStatus: "error", message: error.message });
    }

    return results;
  }

  async downloadCertificate(id, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/dpc-certificate/download`;
    const token = accessToken;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ learnerSessionId: id }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      if (!response.ok) {
        return data;
      }

      const binaryString = atob(data.file);

      // Conversion Base64 en octets (ArrayBuffer)
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Décompression du contenu Base64 avec PizZip
      const zip = new PizZip(bytes);

      // Récupération du contenu du document Word
      const content = zip.file("word/document.xml").asText();

      // Utilisation de docxtemplater pour générer le fichier .docx
      const doc = new Docxtemplater();
      doc.loadZip(zip);
      doc.setData({
        /* Vos données à remplir dans le document */
      });
      try {
        doc.render();
      } catch (error) {
        const e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
          properties: error.properties,
        };
        console.log(JSON.stringify({ error: e }));
        // Gestion des erreurs ici
        throw error;
      }

      // Génération du document final
      const output = doc.getZip().generate({ type: "uint8array" });

      // Création d'un objet Blob à partir du contenu généré
      const blob = new Blob([output], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Utilisation de FileSaver.js pour télécharger le fichier
      FileSaver.saveAs(blob, data.path);

      console.clear();
      return data;
    } catch (error) {
      // Gérer les erreurs ici
      console.error(error);
      throw error;
    }
  }

  async downloadMultipleCertificates(ids, sessionId, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/dpc-certificate/download`;
    const zip = new JSZip();
    const results = [];

    try {
      for (const id of ids) {
        try {
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({ learnerSessionId: id }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to download certificate for ID: ${id}`);
          }

          const data = await response.json();
          const binaryString = atob(data.file);

          // Conversion Base64 en octets (ArrayBuffer)
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Décompression du contenu Base64 avec PizZip
          const docZip = new PizZip(bytes);

          // Utilisation de docxtemplater pour générer le fichier .docx
          const doc = new Docxtemplater();
          doc.loadZip(docZip);
          doc.setData({
            /* Vos données à remplir dans le document */
          });
          doc.render();

          // Génération du document final
          const output = doc.getZip().generate({ type: "uint8array" });

          // Ajout du fichier .docx au ZIP avec un nom unique
          zip.file(`${data.path}`, output);

          // Enregistrement du succès
          results.push({ id, status: "success" });
        } catch (error) {
          console.error(`Erreur de traitement du certificat: " ${id}"`, error);
          // Enregistrement de l'échec
          results.push({ id, status: "error", message: error.message });
        }
      }

      // Génération du fichier ZIP et téléchargement s'il y a des fichiers réussis
      if (results.some((result) => result.status === "success")) {
        const zipBlob = await zip.generateAsync({ type: "blob" });
        FileSaver.saveAs(zipBlob, `certificats-session-${sessionId}.zip`);
      }

      console.clear();
    } catch (error) {
      console.error("Error during the batch download process:", error);
      results.push({ globalStatus: "error", message: error.message });
    }

    return results;
  }

  async downloadSwarnStatement(id, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/swarn-statements/download`;
    const token = accessToken;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ learnerSessionId: id }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      if (!response.ok) {
        return data;
      }

      const binaryString = atob(data.file);

      // Conversion Base64 en octets (ArrayBuffer)
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Décompression du contenu Base64 avec PizZip
      const zip = new PizZip(bytes);

      // Récupération du contenu du document Word
      const content = zip.file("word/document.xml").asText();

      // Utilisation de docxtemplater pour générer le fichier .docx
      const doc = new Docxtemplater();
      doc.loadZip(zip);
      doc.setData({
        /* Vos données à remplir dans le document */
      });
      try {
        doc.render();
      } catch (error) {
        const e = {
          message: error.message,
          name: error.name,
          stack: error.stack,
          properties: error.properties,
        };
        console.log(JSON.stringify({ error: e }));
        // Gestion des erreurs ici
        throw error;
      }

      // Génération du document final
      const output = doc.getZip().generate({ type: "uint8array" });

      // Création d'un objet Blob à partir du contenu généré
      const blob = new Blob([output], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Utilisation de FileSaver.js pour télécharger le fichier
      FileSaver.saveAs(blob, data.path);

      console.clear();
      return data;
    } catch (error) {
      // Gérer les erreurs ici
      console.error(error);
      throw error;
    }
  }

  async downloadMultipleSwarnStatements(ids, sessionId, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/swarn-statements/download`;
    const zip = new JSZip();
    const results = [];

    try {
      for (const id of ids) {
        try {
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({ learnerSessionId: id }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to download document for ID: ${id}`);
          }

          const data = await response.json();
          const binaryString = atob(data.file);

          // Conversion Base64 en octets (ArrayBuffer)
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Décompression du contenu Base64 avec PizZip
          const docZip = new PizZip(bytes);

          // Utilisation de docxtemplater pour générer le fichier .docx
          const doc = new Docxtemplater();
          doc.loadZip(docZip);
          doc.setData({
            /* Vos données à remplir dans le document */
          });
          doc.render();

          // Génération du document final
          const output = doc.getZip().generate({ type: "uint8array" });

          // Ajout du fichier .docx au ZIP avec un nom unique
          zip.file(`${data.path}`, output);

          // Enregistrement du succès
          results.push({ id, status: "success" });
        } catch (error) {
          console.error(`Error processing ID: ${id}`, error);
          // Enregistrement de l'échec
          results.push({ id, status: "error", message: error.message });
        }
      }

      // Génération du fichier ZIP et téléchargement s'il y a des fichiers réussis
      if (results.some((result) => result.status === "success")) {
        const zipBlob = await zip.generateAsync({ type: "blob" });
        FileSaver.saveAs(zipBlob, `attestations-session-${sessionId}.zip`);
      }

      console.clear();
    } catch (error) {
      console.error("Error during the batch download process:", error);
      results.push({ globalStatus: "error", message: error.message });
    }

    return results;
  }

  async handleDowloadAttendanceList(id, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/dpc-attendance/download`;
    const token = accessToken;
    await fetch(url, {
      method: "POST",
      body: JSON.stringify({ sessionID: id }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        try {
          const binaryString = atob(data.file);

          // Conversion Base64 en octets (ArrayBuffer)
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Lecture du classeur Excel à partir des octets
          const workbook = read(bytes, { type: "array" });

          // Sélection de la première feuille du classeur (index 0)
          const sheetIndex = 0;
          const sheetName = workbook.SheetNames[sheetIndex];
          const worksheet = workbook.Sheets[sheetName];

          // Conversion de la feuille en JSON (optionnel)
          const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

          // Création d'un nouveau classeur Excel pour téléchargement
          const newWorkbook = utils.book_new();
          const newWorksheet = utils.aoa_to_sheet(jsonData);
          utils.book_append_sheet(newWorkbook, newWorksheet, "Feuille1");

          // Création d'un objet Blob contenant le nouveau classeur Excel
          const blob = new Blob(
            [write(newWorkbook, { bookType: "xlsx", type: "array" })],
            {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }
          );

          // Création d'un objet URL pour le Blob
          const url = URL.createObjectURL(blob);

          // Création d'un lien de téléchargement
          const link = document.createElement("a");
          link.href = url;
          link.download = "Emargements.xlsx";
          link.textContent = "Télécharger le fichier Excel";

          // Ajout du lien de téléchargement à la page et déclenchement du téléchargement
          document.body.appendChild(link);
          link.click();

          // Nettoyage de l'objet URL
          URL.revokeObjectURL(url);

          console.clear();
          return data;
        } catch (error) {
          const e = {
            message: error.message,
            name: error.name,
            stack: error.stack,
            properties: error.properties,
          };
          console.log(JSON.stringify({ error: e }));
          // Gestion des erreurs ici
          throw error;
        }
      })
      .catch((error) => console.error(error));
  }

  async revomeGeneratedFile(name, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/agreements/remove?name=`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
    await fetch(url + name, { headers })
      .then((response) => console.log(response))
      .catch((error) => console.error(error));
  }

  async sendCertificate(id, accessToken) {
    const URL = `${process.env.REACT_APP_API_URL}/api/dpc-certificate/send`;
    const resp = await fetch(URL, {
      method: "POST",
      body: JSON.stringify({ learnerSessionId: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    console.clear();
    console.log(json);
    return json;
  }

  async sendSwarnStatement(id, accessToken) {
    const URL = `${process.env.REACT_APP_API_URL}/api/swarn-statements/build`;
    const resp = await fetch(URL, {
      method: "POST",
      body: JSON.stringify({ learnerSessionId: id }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    console.clear();
    console.log(json);
    return json;
  }

  async sendMultipleSwarnStatements(selectedIds, accessToken) {
    const token = "@B59y-4_L92@7ru";
    const URL = `${process.env.REACT_APP_API_URL}/api/sessions/send-bulk-swarn-statements`;
    const resp = await fetch(URL, {
      method: "POST",
      body: JSON.stringify({ selectedIds, token }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    console.clear();
    console.log(json);
    return json;
  }

  async sendMultipleAgreements(selectedIds, accessToken) {
    const token = "@B59y-4_L92@7ru";
    const URL = `${process.env.REACT_APP_API_URL}/api/sessions/send-bulk-conventions`;
    const resp = await fetch(URL, {
      method: "POST",
      body: JSON.stringify({ selectedIds, token }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const json = await resp.json();

    console.clear();
    console.log(json);
    return json;
  }

  async sendMultipleCertificates(ids, accessToken) {
    const URL = `${process.env.REACT_APP_API_URL}/api/dpc-certificate/send`;
    const results = [];
  
    try {
      for (const id of ids) {
        try {
          const resp = await fetch(URL, {
            method: "POST",
            body: JSON.stringify({ learnerSessionId: id }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });
  
          if (!resp.ok) {
            throw new Error(`Failed to send certificate request for ID: ${id}`);
          }
  
          const json = await resp.json();
          results.push({ id, status: 'success', response: json });
  
        } catch (error) {
          // console.error(`Error processing ID: ${id}`, error);
          results.push({ id, status: 'error', message: error.message });
        }
      }
  
      console.clear();
    } catch (error) {
      // console.error("Error during the batch request process:", error);
      results.push({ globalStatus: 'error', message: error.message });
    }
  
    return results;
  }
  
  async downloadCertificateToPdf(id, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/dpc-certificate/download`;
    
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ learnerSessionId: id }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to download PDF for ID: ${id}`);
      }

      const data = await response.json();
      const binaryString = atob(data.file);

      // Conversion Base64 en octets (ArrayBuffer)
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Création d'un objet Blob pour le PDF
      const blob = new Blob([bytes], { type: "application/pdf" });

      // Utilisation de FileSaver.js pour télécharger le fichier
      FileSaver.saveAs(blob, `${data.path.slice(0, -5)}.pdf`);

      console.clear();
      return { id, status: 'success' };
    } catch (error) {
      console.error(`Error processing ID: ${id}`, error);
      return { id, status: 'error', message: error.message };
    }
  }

  async downloadMultipleCertificatesToPdf(ids, sessionId, accessToken) {
    const url = `${process.env.REACT_APP_API_URL}/api/dpc-certificate/download`;
    const zip = new JSZip();
    const results = [];

    try {
      for (const id of ids) {
        try {
          const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify({ learnerSessionId: id }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            throw new Error(`Failed to download PDF for ID: ${id}`);
          }

          const data = await response.json();
          const binaryString = atob(data.file);

          // Conversion Base64 en octets (ArrayBuffer)
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Ajout du fichier PDF au ZIP avec un nom unique
          zip.file(`${data.path.slice(0, -5)}.pdf`, bytes);

          // Enregistrement du succès
          results.push({ id, status: "success" });
        } catch (error) {
          console.error(`Error processing ID: ${id}`, error);
          // Enregistrement de l'échec
          results.push({ id, status: "error", message: error.message });
        }
      }

      // Génération du fichier ZIP et téléchargement s'il y a des fichiers réussis
      if (results.some((result) => result.status === "success")) {
        const zipBlob = await zip.generateAsync({ type: "blob" });
        FileSaver.saveAs(zipBlob, `certificats-session-${sessionId}.zip`);
      }

      console.clear();
    } catch (error) {
      console.error("Error during the batch download process:", error);
      results.push({ globalStatus: "error", message: error.message });
    }

    return results;
  }

}

export const customerApi = new CustomerApi();
