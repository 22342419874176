import React, { useEffect, useState } from 'react'
import { ticketApi } from '../../../api/ticketApi';
import TicketSearchSection from './TicketSearchSection';
import TicketsCarousel from './TicketsCarousel';
import { Box, CircularProgress, Collapse, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import { createCustomTheme } from '../../../theme';
import { ThemeProvider } from '@mui/material';

function TicketsMajorIncident(props) {
    const { majorIncidentId, serviceId, update, setUpdate, show, creationProcess, tickets, setTickets } = props
    const { user } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [ticketSearchSection, setTicketSearchSection] = useState(false)
    const [ticketsFetched, setTicketsFetched] = useState([]);

    const { settings } = useSettings();
    const theme = createCustomTheme({
      ...settings
    });

    const getTicketsByMajorIncident = async (id) => {
        try {
            const ticketsData = await ticketApi.getTicketsByMajorIncident(localStorage.getItem('accessToken'), id);
            setTickets(ticketsData.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async (ticketId) => {
        const newTicketsList = tickets.filter((ticket) => ticket.id !== ticketId);
        // setUpdateTicketList(!updateTicketList)
        setTickets(newTicketsList)
        await ticketApi
          .deleteTicketMajorIncident(localStorage.getItem('accessToken'), ticketId, majorIncidentId, user.id)
          // .then(() => setTickets(newTicketsList))
          .catch((e) => console.log(e));
      };

    const handleClick = async (ticket) => {
      setTickets([...tickets, ticket])

      if (creationProcess) {
        const updatedTicketsFetched = ticketsFetched.filter(
          (mem) => mem.id !== ticket.id
        );
        setTicketsFetched(updatedTicketsFetched);
      } else {
        await ticketApi
        .addTicketMajorIncident(localStorage.getItem('accessToken'), ticket.id, majorIncidentId, user.id)
        .then(() => {
          const updatedTicketsFetched = ticketsFetched.filter(
            (mem) => mem.id !== ticket.id
          );
          setTicketsFetched(updatedTicketsFetched);
        })
        .catch((e) => console.log(e));
      }
    };

    const handleTicketSearchSection = () => {
        setTicketSearchSection(!ticketSearchSection)
    }

    useEffect(() => {
      if (!creationProcess) {
        if (show) {
          setIsLoading(true)
          getTicketsByMajorIncident(majorIncidentId)
          if (update) setUpdate(false)
        }
      }
    }, [majorIncidentId, update])

  return (<>
    {
        isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50%'
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
      <ThemeProvider theme={theme}>
        <Box>
          <Box sx={{ display: "flex", gap: 2, mb: 1 }}>
            <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.main}}>Tickets</Typography>
            <IconButton onClick={handleTicketSearchSection} sx={{ mt: -1.4}}>
              {
                !ticketSearchSection ? <AddIcon /> : <RemoveIcon />
              }
            </IconButton>
          </Box>
          <Collapse in={ticketSearchSection}>
            <TicketSearchSection 
              serviceId={serviceId} 
              majorIncidentId={majorIncidentId} 
              tickets={tickets} 
              setTickets={setTickets}
              handleClick={handleClick}
              ticketsFetched={ticketsFetched}
              setTicketsFetched={setTicketsFetched}
              // updateTicketList={updateTicketList}
              // setUpdateTicketList={setUpdateTicketList} 
            />
          </Collapse>
          {tickets.length === 0 ?
          <Box sx={{ display:'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
            <Typography sx={{ fontStyle: 'italic', fontSize: 18 }}>
              Aucun ticket n&lsquo;a été lié à cet incident majeur pour l&lsquo;instant.
            </Typography>
          </Box> 
          : 
          <TicketsCarousel 
            tickets={tickets} 
            // update={updateTicketList} 
            // setUpdate={setUpdateTicketList} 
            handleDelete={handleDelete} 
          />}
        </Box>
      </ThemeProvider>
          )
    }
    </>
  )
}

export default TicketsMajorIncident;
