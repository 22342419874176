import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  Link,
  Typography,
  Breadcrumbs,
  Container,
  Tabs,
  Tab
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import { useLocation } from 'react-router';
import useMounted from '../../hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { usersApi } from '../../api/usersApi';
import UserGeneralSettings from '../../components/dashboard/users/UserGeneralSettings';
import UserFunctionSettings from '../../components/dashboard/users/UserFunctionSettings';

const AccountAdmin = () => {
  const path = useLocation();
  const userId = path.pathname.split('/')[3];
  const [userToUpdate, setUserToUpdate] = useState({});
  const { user } = useAuth();
  const { settings } = useSettings();
  const mounted = useMounted();
  const [currentTab, setCurrentTab] = useState('general');

  const tabs = [
    { label: 'Général', value: 'general' },
    { label: 'Fonction', value: 'functionTab' },
  ];

  const getUserInfos = useCallback(async () => {
    try {
      const data = await usersApi.getUserInfos(window.localStorage.getItem('accessToken'), userId);
      if (mounted.current) {
        setUserToUpdate(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getUserInfos();
  }, [getUserInfos]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Helmet>
        <title>Tableau de bord: Profil de l&apos;utilisateur | Apteed</title>
      </Helmet>
      {user.id_service === 0 && (
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {`Profil de l'utilisateur ${userToUpdate.firstname} ${userToUpdate.lastname}`}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Gestion
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Utilisateurs
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3 }}>
            {currentTab === 'general' && <UserGeneralSettings />}
            {currentTab === 'functionTab' && <UserFunctionSettings />}
          </Box>
        </Container>
      </Box>
      )}
      <Box sx={{ mt: 2 }}>
        {user.id_service !== 0 && ('Vous n\'avez pas le droit de voir cet utilisateur')}
      </Box>
    </>
  );
};

export default AccountAdmin;
