/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Typography, Box, Skeleton, ToggleButton, ToggleButtonGroup, Grid } from '@material-ui/core';
import { useState, useCallback, useEffect } from 'react';
import { dashboardApi } from '../../../api/dashboardApi';
import useMounted from '../../../hooks/useMounted';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FinanceStatistiqueProfessions = () => {
  const mounted = useMounted();
  const [professions, setProfessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(true);
  const [dataByProfession, setDataByProfession] = useState([]);
  const [showStats, setShowStats] = useState(true);
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  const handleTabsChanges = () => {
    setShowStats(!showStats);
  };

  const getDataProfession = useCallback(async () => {
    try {
      // on récupère les données de tous les apprenants inscrits que l'on va traiter en front end
      const data = await dashboardApi.getDataProfession(localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setDataByProfession(data);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getDataProfession();
  }, [getDataProfession]);

  const getInfos = useCallback(async () => {
    try {
      const data = await dashboardApi.getInfos(localStorage.getItem('accessToken'), 'all');

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setProfessions(data.professions);
        setLoading1(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  useEffect(() => {
    getInfos();
  }, [getInfos]);

  const rows = [];
  // on crée les lignes pour chaque profession par date de fin de session
  professions.forEach((item, index) => {
    const row = { id: `${item.profession}-${index}`,
      profession: item.profession,
    };
    // initialisation de countCustomers et sumRealPrice par mois
    const countCustomers = {};
    const sumRealPrice = {};
    months.forEach((month) => {
      countCustomers[month] = 0;
      sumRealPrice[month] = 0;
    });

    dataByProfession.forEach((data) => {
      const dataDateFormatted = new Date(data.end_date);
      months.forEach((month, indexMonth) => {
        if (item.profession === data.profession && indexMonth === dataDateFormatted.getMonth()) {
          countCustomers[month]++;
          sumRealPrice[month] += data.real_price;
        }
        row[`nb${month}`] = countCustomers[month];
        row[`real${month}`] = sumRealPrice[month].toFixed(2);
      });
    });
    // calcul pour la colonne total
    let countCustomersTotal = 0;
    let sumRealPriceTotal = 0;
    dataByProfession.forEach((data) => {
      if (data.profession === item.profession) {
        countCustomersTotal++;
        sumRealPriceTotal += data.real_price;
      }
    });
    // on ajoute à la ligne le calcul de la somme de chaque end_session par profession des apprenants et le real_price
    row.nbTotal = countCustomersTotal;
    row.realTotal = sumRealPriceTotal.toFixed(2);
    rows.push(row);
  });

  const row = { id: 'total',
    profession: 'Total',
  };
  // initialisation de countCustomers et sumRealPrice par mois
  const countCustomers = {};
  const sumRealPrice = {};
  months.forEach((month) => {
    countCustomers[month] = 0;
    sumRealPrice[month] = 0;
  });
  dataByProfession.forEach((data) => {
    const dataDateFormatted = new Date(data.end_date);
    months.forEach((month, index) => {
      if (index === dataDateFormatted.getMonth()) {
        countCustomers[month]++;
        sumRealPrice[month] += data.real_price;
      }
      row[`nb${month}`] = countCustomers[month];
      row[`real${month}`] = sumRealPrice[month].toFixed(2);
    });
  });

  // calcul pour la colonne total
  let countCustomersTotal = 0;
  let sumRealPriceTotal = 0;
  dataByProfession.forEach((data) => {
    countCustomersTotal++;
    sumRealPriceTotal += data.real_price;
  });
  // on ajoute à la ligne total le calcul de la somme de chaque total de end_session (toutes professions confondues) des apprenants et le real_price
  row.nbTotal = countCustomersTotal;
  row.realTotal = sumRealPriceTotal.toFixed(2);
  rows.push(row);

  const columns = [
    { field: 'profession', headerName: 'Profession', width: 150 },
  ];
  months.forEach((month) => {
    columns.push({ field: `real${month}`,
      width: 105,
      headerName: month,
      renderCell: (cellValues) => (
        // eslint-disable-next-line no-nested-ternary
        <span style={{ whiteSpace: 'pre-line', color: cellValues.row[`real${month}`] < 100 ? 'grey' : cellValues.row[`real${month}`] > 10000 ? 'green' : 'inherit' }}>
          {`${cellValues.row[`real${month}`]} €\n(${cellValues.row[`nb${month}`]})`}
        </span>
      ),
      // le filtre par défaut ne fonctionnant pas sur un renderCell, on précise un filtre personnalisé
      sortComparator: (v1, v2) => v1 - v2 });
  });

  columns.push({ field: 'realTotal',
    width: 140,
    headerAlign: 'center',
    headerName: 'Total',
    renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{`${cellValues.row.realTotal} € (${cellValues.row.nbTotal})`}</Typography>),
    sortComparator: (v1, v2) => v1 - v2
  });

  return (
    <Box>
      <Grid
        container
        sx={{ ml: 1, p: 2 }}
      >
        <ToggleButtonGroup
          exclusive

        >
          <ToggleButton
            style={{ border: 'none', color: 'inherit' }}
            value="grid"
            variant="h6"
            onClick={handleTabsChanges}
          >
            {showStats === true ? <KeyboardArrowRightIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
          </ToggleButton>
        </ToggleButtonGroup>
        <Typography
          variant="h6"
          sx={{ p: 1 }}
        >
          Chiffre d&apos;affaire par profession et fin de session
        </Typography>
      </Grid>
      {!loading && !loading1 ? (
        <>
          {showStats !== true && (
            <DataGrid
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'id', sort: 'asc' }],
                },
              }}
              autoHeight
              rowHeight={40}
              rows={rows}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[50, 100]}
              components={{ Toolbar: GridToolbar }}
            />
          )}
        </>
      ) : (
        <>
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
        </>
      )}
    </Box>

  );
};

export default FinanceStatistiqueProfessions;
