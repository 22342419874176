/* eslint-disable no-unused-vars */
import {
  Box,
  Grid,
  Card,
  Divider,
  TablePagination,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  Link,
  Avatar
} from '@material-ui/core';
import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Scrollbar from '../../Scrollbar';
import useMounted from '../../../hooks/useMounted';
import SearchIcon from '../../../icons/Search';
import * as moment from 'moment';
import { relaunchApi } from '../../../api/relaunchApi';

const tabs = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Médecins',
    value: 'Médecin'
  },
  {
    label: 'Infirmiers',
    value: 'Infirmier'
  },
  {
    label: 'Kinés',
    value: 'Masseur-kinésithérapeute'
  },
  {
    label: 'Podologues',
    value: 'Pédicure-podologue'
  },
  {
    label: 'Pharmaciens',
    value: 'Pharmacien'
  },
  {
    label: 'Sages-Femmes',
    value: 'Sage-Femme'
  }
];

const callTrackRelanceOptions = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Sans statut',
    value: 'empty'
  },
  {
    label: 'Pas répondu',
    value: 'Pas répondu'
  },
  {
    label: 'À rappeler',
    value: 'À rappeler'
  },
  {
    label: 'Numéro incorrect',
    value: 'Numéro incorrect'
  },
  {
    label: 'Va se connecter',
    value: 'Va se connecter'
  },
  {
    label: 'Suivi - Téléphone',
    value: 'Suivi - Téléphone'
  },
  {
    label: 'Suivi - Mail',
    value: 'Suivi - Mail'
  },
  {
    label: 'Suivi - SMS',
    value: 'Suivi - SMS'
  },
  {
    label: 'Décalage',
    value: 'Décalage'
  },
  {
    label: 'Annulation',
    value: 'Annulation'
  }
];

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const applyFilters = (customers, query, filters, callTrackRelance, newCallTrack) => customers
  .filter((customer) => {
    const matches = true;

    return matches;
  });

const sortOptions = [
  {
    label: 'Nom (A - Z)',
    value: 'lastname|asc'
  },
  {
    label: 'Nom (Z - A)',
    value: 'lastname|desc'
  },
  {
    label: 'Code postal (A - Z)',
    value: 'zip|asc'
  },
  {
    label: 'Code postal (Z - A)',
    value: 'zip|desc'
  }
];

const RelaunchLearners = () => {
  const mounted = useMounted();
  const { endDateRelaunch, state } = useParams();
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(25);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [learnerRelaunch, setLearnerRelaunch] = useState([]);
  const [currentTab, setCurrentTab] = useState('all');
  const [numberProspects, setNumberProspects] = useState(0);
  const [currentCallTrackRelance, setCurrentCallTrackRelance] = useState('all');
  const [choiceStartDate, setChoiceStartDate] = useState('all');
  const [choiceFormation, setChoiceFormation] = useState('all');
  const [choiceRelance, setChoiceRelance] = useState('all');
  const [formationsInfos, setFormationsInfos] = useState([]);
  const [relanceInfos, setRelanceInfos] = useState([]);
  const formationsOptions = [];
  formationsOptions.push({ name: 'Toutes', id: 'all', programNumber: '' });
  formationsInfos.forEach((formation) => {
    formationsOptions.push({ name: formation.name, id: formation.id, programNumber: formation.program_number });
  });
  let choiceStartDateOptions = [];
  const [sessionsStartDate, setSessionsStartDate] = useState([]);

  const relanceOptions = [];
  relanceOptions.push({ name: 'Tous', id: 'all' });
  relanceInfos.forEach((person) => {
    relanceOptions.push({ name: `${person.firstname} ${person.lastname}`, id: person.id });
  });

  const getRelaunchInfo = useCallback(async () => {
    try {
      const data = await relaunchApi.getRelaunchLearner(endDateRelaunch, state, localStorage.getItem('accessToken'), '', 'all', 'all', 'all', 'all', 'all', page, limit);

      if (mounted.current) {
        setLearnerRelaunch(data.customers);
        setNumberProspects(data.numberCustomers.number);
        setFormationsInfos(data.formations);
        setSessionsStartDate(data.startDates);
        setRelanceInfos(data.relance);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getRelaunchInfo();
  }, [getRelaunchInfo]);

  choiceStartDateOptions = [{ label: 'Toutes', value: 'all' }];

  sessionsStartDate.forEach((item) => choiceStartDateOptions.push({ label: moment(item.start_date).format('DD/MM/YYYY'), value: item.start_date }));

  const [callTrackRelance, setCallTrackRelance] = useState({
    empty: null,
    'Pas répondu': null,
    'À rappeler': null,
    'Numéro incorrect': null,
    'Va se connecter': null,
    Annulation: null,
    'Suivi - Téléphone': null,
    'Suivi - Mail': null,
    'Suivi - SMS': null,
    Décalage: null,
  });

  const [filters, setFilters] = useState({
    Médecin: null,
    Infirmier: null,
    'Masseur-kinésithérapeute': null,
    'Pédicure-podologue': null,
    Pharmacien: null,
    'Sage-Femme': null
  });

  const handleCustomers = (async (querySearch, callTrackRelaunchChange, startSessionsChange, formationChange, relaunchChange, tabProfession, pageChoice, limitChoice) => {
    try {
      const data = await relaunchApi.getRelaunchLearner(endDateRelaunch, state, localStorage.getItem('accessToken'), querySearch, callTrackRelaunchChange, startSessionsChange, formationChange, relaunchChange, tabProfession, pageChoice, limitChoice);

      setLearnerRelaunch(data.customers);
      setNumberProspects(data.numberCustomers.number);
    } catch (err) {
      console.error(err);
    }
  });

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    // pour que la recherche dans le champ "chercher un apprenant" ne se fasse qu'à partir de 3 caractères tapés
    if (event.target.value.length >= 3) {
      handleCustomers(event.target.value, callTrackRelance, choiceStartDate, choiceFormation, choiceRelance, filters, page, limit);
    }
    // pour que la recherche dans le champ "chercher un apprenant" se réinitialise à 0 caractère
    if (event.target.value.length === 0) {
      handleCustomers('', callTrackRelance, choiceStartDate, choiceFormation, choiceRelance, filters, page, limit);
    }
    // pour remettre à la première page de résultats quand on fait une recherche
    setPage(0);
  };

  const handleChangeStartDate = (event) => {
    setChoiceStartDate(event.target.value);
    handleCustomers(query, callTrackRelance, event.target.value, choiceFormation, choiceRelance, filters, page, limit);
    setPage(0);
  };

  const handleChangeOptionFormation = (event) => {
    setChoiceFormation(event.target.value);
    handleCustomers(query, callTrackRelance, choiceStartDate, event.target.value, choiceRelance, filters, page, limit);
    setPage(0);
  };

  const handleChangeOptionRelance = (event) => {
    setChoiceRelance(event.target.value);
    handleCustomers(query, callTrackRelance, choiceStartDate, choiceFormation, event.target.value, filters, page, limit);
    setPage(0);
  };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      Médecin: null,
      Infirmier: null,
      'Masseur-kinésithérapeute': null,
      'Pédicure-podologue': null,
      Pharmacien: null,
      'Sage-Femme': null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(value);
    setSelectedCustomers([]);
    setCurrentTab(value);
    setPage(0);
    handleCustomers(query, callTrackRelance, choiceStartDate, choiceFormation, choiceRelance, value, page, limit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleCustomers(query, callTrackRelance, choiceStartDate, choiceFormation, choiceRelance, filters, newPage, limit);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    handleCustomers(query, callTrackRelance, choiceStartDate, choiceFormation, choiceRelance, filters, page, parseInt(event.target.value, 10));
  };

  const filteredCustomers = applyFilters(learnerRelaunch, query, filters, callTrackRelance);
  const sortedCustomers = applySort(filteredCustomers, sort);

  const handleCallTrackRelanceChange = (event, value) => {
    const updatedCallTrackRelance = {
      ...callTrackRelance,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      'Va se connecter': null,
      Annulation: null,
      'Suivi - Téléphone': null,
      'Suivi - Mail': null,
      'Suivi - SMS': null,
      Décalage: null,
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCallTrackRelance[value] = true;
      }

      setCallTrackRelance(updatedCallTrackRelance);
      setSelectedCustomers([]);
      setCurrentCallTrackRelance(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCallTrackRelance[event.target.value] = true;
      }

      setCallTrackRelance(event.target.value);
      setSelectedCustomers([]);
      setCurrentCallTrackRelance(event.target.value);
      window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, event.target.value, choiceStartDate, choiceFormation, choiceRelance, filters, page, limit);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',

      }}
    >
      <Card>
        <Tabs
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="primary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Divider />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            m: -1,
            p: 2
          }}
        >
          <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 500
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder="Chercher un apprenant"
              value={query}
              variant="outlined"
            />
          </Box>
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              label="Statut d'appel (Relance)"
              name="callTrackRelance"
              onChange={handleCallTrackRelanceChange}
              select
              SelectProps={{ native: true }}
              value={currentCallTrackRelance}
              variant="outlined"
            >
              {callTrackRelanceOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              m: 3,
              width: 240
            }}
          >
            <TextField
              label="Afficher par date de début"
              name="choice"
              onChange={handleChangeStartDate}
              select
              SelectProps={{ native: true }}
              value={choiceStartDate}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            >
              {choiceStartDateOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              m: 3,
              width: 240
            }}
          >
            <TextField
              label="Afficher par formation"
              name="formation"
              onChange={handleChangeOptionFormation}
              select
              SelectProps={{ native: true }}
              value={choiceFormation}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            >
              {formationsOptions.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {`${option.name} ${option.programNumber}`}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              m: 3,
              width: 240
            }}
          >
            <TextField
              label="Afficher par relance"
              name="relance"
              onChange={handleChangeOptionRelance}
              select
              SelectProps={{ native: true }}
              value={choiceRelance}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              fullWidth
            >
              {relanceOptions.map((option) => (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </option>
              ))}
            </TextField>
          </Box>
        </Box>
        <Scrollbar>
          <Box sx={{ minWidth: 900 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Nom Prénom
                  </TableCell>
                  <TableCell>
                    Profession
                  </TableCell>
                  <TableCell>
                    Statut
                  </TableCell>
                  <TableCell sx={{ width: 500 }}>
                    N° de session, date de début de session, formation
                  </TableCell>
                  <TableCell>
                    Dernier commentaire
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>
                    Relance
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {learnerRelaunch.map((link) => (
                  <TableRow
                    key={link.id}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={link.avatar}
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          {...stringAvatar(`${link.firstname.replace(/ /g, '')} ${link.lastname.replace(/ /g, '')}`)}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color={link.treated === 1 ? '#7EB627' : 'inherit'}
                            component={RouterLink}
                            to={`/dashboard/customers/${link.id}`}
                            variant="subtitle2"
                          >
                            {link.firstname}
                            {' '}
                            {link.lastname}
                          </Link>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {link.profession}
                    </TableCell>
                    <TableCell>
                      {link.qualification}
                    </TableCell>
                    <TableCell>
                      <Grid
                        item
                      >
                        {/* {link.name} */}
                        <Typography style={{ whiteSpace: 'pre-line', fontSize: 14 }}>
                          {link.formations.map((formation) => (
                            <Link
                              color="textPrimary"
                              component={RouterLink}
                              to={`/dashboard/session/${formation.session_id}`}
                              variant="subtitle2"
                              style={{ fontWeight: 'normal' }}
                            >
                              {`Session ${formation.number_session} ${moment(formation.start_date).format('DD/MM/YYYY')} ${formation.name.length > 45 ? `${formation.name.slice(0, 45)}...` : formation.name}\n`}
                            </Link>
                          ))}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      {link.comment}
                    </TableCell>
                    <TableCell>
                      {`${link.firstnameRelauncher} ${link.lastnameRelauncher}`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
              // count={filteredCustomers.length}
          count={numberProspects}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[15, 25, 50]}
        />
      </Card>
    </Box>
  );
};

export default RelaunchLearners;
