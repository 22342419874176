import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography} from '@material-ui/core';
import { formationApi } from '../../../api/formationApi';
import FormationResultPerformance from './FormationResultPerformance';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../icons/ChevronRight';
import gtm from '../../../lib/gtm';
import useAuth from '../../../hooks/useAuth';
import { useTheme } from '@material-ui/core/styles';
import FormationsFilterSatisfactionPerformance from '../../../components/dashboard/formation/FormationsFilterSatisfactionPerformance';
// import {  } from '@mui/styles';

const FormationsPerformance = () => {
  const mounted = useMounted();
  const auth = useAuth();
  const { settings } = useSettings();
  const [filters, setFilters] = useState([]);
  const [formations, setFormations] = useState([]);
  const [filteredFormations, setFilteredFormations] = useState([]);
  
  // remet filtres à zéro de src/components/dashboard/customer/CustomerListTable.js
  window.localStorage.setItem('choiceTabProfession', JSON.stringify('all'));
  window.localStorage.setItem('choiceSort', JSON.stringify('lastname|asc'));
  window.localStorage.setItem('choiceSource', JSON.stringify('all'));
  window.localStorage.setItem('choiceCampaign', JSON.stringify('all'));
  window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
  window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all'));
  window.localStorage.setItem('choiceAvancement', JSON.stringify('all'));

  useEffect(() => { gtm.push({ event: 'page_view' });}, []);

  const [loading, setLoading] = useState(false); 

  const getFormationsPerformances = useCallback(async () => {
    setLoading(true);
    try {
      const data = await formationApi.getFormationsForPerformances(localStorage.getItem('accessToken'), auth?.user?.id);
      if (mounted.current) { 
        setFormations(data); 
        setFilteredFormations(data);
        setLoading(false); 
      }
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  }, [mounted]);

  useEffect(() => { getFormationsPerformances();}, [getFormationsPerformances]);

  useEffect(() => {
    if (filters.length > 0) {
      const tmpFormations = formations;
      const tmpFilteredFormations = tmpFormations.filter((f) => filters.includes(f?.year?.toString()));
      setFilteredFormations(tmpFilteredFormations);
    } else {
      setFilteredFormations(formations);
    }
  }, [filters]);

 
  return (
    <>
      <Helmet><title>Performances | Apteed</title></Helmet>
    
      <Box
        sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 8}}>
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid alignItems="center" container justifyContent="space-between" spacing={4}>
            <Grid item>
              <Typography color="textPrimary" variant="h5"> Liste des Performances par formation</Typography>
              <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                <Link color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2"> Tableau de bord </Link>
                <Link color="textPrimary" component={RouterLink} to="/dashboard/performances" variant="subtitle2"> Performances</Link>
                <Typography color="textSecondary" variant="subtitle2"> Liste</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ my: 3 }}>
            <FormationsFilterSatisfactionPerformance setFilters={setFilters} />
          </Box>
          <Box>
          <FormationResultPerformance
            formations={filteredFormations}
            setFormations={setFormations}
            filters={filters}
            loading={loading}
          />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default FormationsPerformance;
