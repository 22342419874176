/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react'
import useAuth from '../../../hooks/useAuth';
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField, Typography } from '@material-ui/core';
import FileDropzone from '../../FileDropzone';
import Scrollbar from '../../Scrollbar';
import { Close as CloseIcon, UploadFileRounded as UploadFileIcon } from '@material-ui/icons';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { majorIncidentApi } from '../../../api/majorIncidentApi';
import useSettings from '../../../hooks/useSettings';


/* Modèle d'un objet File :
{
  path: "Capture 4.PNG",
  lastModified: 1699452642678,
  lastModifiedDate: 'Wed Nov 08 2023 15:10:42 GMT+0100 (heure normale d’Europe centrale)',
  name: "Capture 4.PNG",
  size: 27488,
  type: "image/png",
  webkitRelativePath: ""
}
*/

const fileTypes = [
  {
    type: "image",
    icon: <ImageRoundedIcon />,
    extensions: ['png', 'jpg', 'jpeg']
  },
  {
    type: "application",
    icon: <DescriptionOutlinedIcon />,
    // icon: <PictureAsPdfIcon />,
    extensions: ['pdf', 'pptx', 'ppt', 'docx', 'doc', 'xlsx', 'xls', 'csv']
  },
  {
    type: "text",
    icon: <DescriptionOutlinedIcon />,
    extensions: ['csv', 'plain']
  },
  {
    type: "video",
    icon: <PlayArrowRoundedIcon />,
    extensions: ['mp4', 'webm', 'mkv']
  },

]

const handleIcon = (extension) => {
  const fileType = fileTypes?.find((type) => type.extensions.includes(extension?.toLowerCase()))
  return fileType?.icon
}

const shortFileName = (fileName, extension) => {
    const titleEnd = `.${extension}`
    console.log(titleEnd)
    if (fileName?.length > 17) {
      const shortTitle = fileName?.substring(0, 12)
      return `${shortTitle}..${titleEnd}`
    }
    return `${fileName}${titleEnd}`
  }

  function formatLink(link) {
    // Vérifier si la chaîne commence par '/public'
    if (link?.startsWith('/public')) {
        // Supprimer la partie '/public'
        return link?.substring('/public'.length);
    } 
        // Retourner la chaîne inchangée si elle ne commence pas par '/public'
        return link;
    
}

function FilesMajorIncident(props) {
    const { majorIncidentId, theme, savedAttachments, setSavedAttachments, deleteMajorIncidentDocument, getMajorIncidentDocuments } = props
    const { user } = useAuth()
    const [openFileDialog, setOpenFileDialog] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [nameExists, setNameExists] = useState(false);
    const [fileShowed, setFileShowed] = useState(null);
    const [showFile, setShowFile] = useState(false);
    const { settings } = useSettings()

    const handleCloseFile = () => {
      setShowFile(false)
    }

    const handleCloseFileDialog = () => {
      setOpenFileDialog(false)
      setAttachments([])
      setIsLoading(false)
    }
    
    const handleDeleteFile = async (index) => {
      const fileId = savedAttachments[index].id

      const newFilesArray = [...savedAttachments]
      newFilesArray.splice(index, 1)
      setSavedAttachments(newFilesArray)
      await majorIncidentApi.deleteMajorIncidentDocument(localStorage.getItem('accessToken'), fileId, majorIncidentId);
    }
  
    const handleDrop = async (acceptedFile) => {
      const arrayTmp = [];
      acceptedFile.forEach((file) => {
        const parts = file.path.split('.')
        const fileName = parts[0]
        const extension = parts[parts.length - 1]

        const typeParts = file.type.split('/')
        const type = typeParts[0]
        arrayTmp.push({ 
          name: fileName,
          extension: extension.toLowerCase(),
          type,
          file,
          isTooBig: file.size > 2000000,
          exists: savedAttachments.some(savedFile => savedFile.name.concat(`.${extension}`).toLowerCase() === file.path.toLowerCase())
        });
      });
      setAttachments(arrayTmp);
      if (arrayTmp.length > 0) {
        setOpenFileDialog(true);
      }
    };

    const checkIfNameExists = () => {
      setNameExists(attachments.some(file => file.error === true))
    }
  
    const handleChangeFiles = (index) => (event) => {
      event.preventDefault();
      const newArr = [...attachments];
      newArr[index] = {
        ...newArr[index],
        [event.target.name]: event.target.value,
      };
      setAttachments(newArr);
    };
  
    const handleSubmitUploading = async (e) => {
      e.preventDefault();
      const newAttachments = [...attachments]
      const attachmentsToSave = newAttachments.filter((file) => (!file.isTooBig && !file.exists))
      if (majorIncidentId) {
        setIsLoading(true)
        await majorIncidentApi.sendDocMajorIncident(majorIncidentId, user.id, attachmentsToSave, localStorage.getItem('accessToken'))
        .then((files) => {
          const updatedAttachments = savedAttachments.concat(files.files)
          setSavedAttachments(updatedAttachments)
          handleCloseFileDialog() 
        })
        .catch(error => console.log(error))
      }
      else {
        const updatedAttachments = savedAttachments.concat(attachmentsToSave)
        setSavedAttachments(updatedAttachments)
        handleCloseFileDialog() 
      }
    };

    return (
      <>
        <Box sx={{ mb: 5 }}>
          <Typography sx={{ fontWeight: "bold", mb: 2, color: theme.palette.primary.main }}>Pièces jointes</Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Box sx={{ flex: 0.4 }}>
              <FileDropzone onDrop={handleDrop} maxFiles={5} />
            </Box>
            <Box sx={{ flex: 0.6 }}>
              <Scrollbar>
                <div style={{  
                      height: savedAttachments.length >= 15 ? '20rem' : 'fit-content' 
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    columnGap: '10px',
                    flexWrap: 'wrap'
                  }}>
                     {savedAttachments.map((file, index) => (
                  <Card 
                    style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'space-between', 
                      height: '40px', 
                      width: '230px', 
                      cursor: majorIncidentId !== null && 'pointer',
                      margin: 5, 
                      boxShadow: 5
                      // border: `1px solid ${theme.palette.text.primary}`
                    }} 
                    onClick={() => {
                      if (majorIncidentId) {
                       setFileShowed(file)
                       setShowFile(true) 
                      }
                    }}>
                    <Box sx={{mx: 1, mt: 0.8}}>
                      {majorIncidentId !== null ? 
                        handleIcon(file?.extension) : 
                        <UploadFileIcon />
                      }
                    </Box>
                    {shortFileName(file?.name, file?.extension)}
                    <IconButton 
                      onClick={(e) => {
                        e.stopPropagation();
                        if (majorIncidentId !== null) {
                          handleDeleteFile(index)
                        } else {
                          deleteMajorIncidentDocument(index)
                        }
                      }}
                    >
                      <CloseIcon />
                    </IconButton>            
                  </Card>
                ))}
                  </Box>
              </div>
            </Scrollbar>
          </Box>
          </Box>
        </Box>
        <Dialog open={openFileDialog} onClose={handleCloseFileDialog}>
          <DialogTitle>Téléchargement de documents</DialogTitle>
          <Scrollbar>
            <DialogContent>
              <DialogContentText sx={{ color: theme.palette.text.primary }}>
                Confirmer le nom et le type de chaque fichier à importer.
              </DialogContentText>
              {attachments.map((value, index) => (
                <>
                  <Box sx={{display: 'flex', gap: 2}}>
                    <Box sx={{ mt: 4 }}>
                      {index + 1}
                      {". "}
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex' }}>
                        <TextField
                          variant="outlined"
                          sx={{ m: "20px" }}
                          name="name"
                          label="Nom du fichier"
                          onChange={handleChangeFiles(index)}
                          value={value.name}
                          disabled={value.isTooBig || value.exists}
                        />
                        <Typography sx={{ mt: 4.5 }}>{`.${value.extension}`}</Typography>
                      </Box>
                      {value.isTooBig && <Typography sx={{ fontStyle: 'italic' }}>Un fichier ne doit pas dépasser 2 Mo.</Typography>}
                      {value.exists && <Typography sx={{ fontStyle: 'italic' }}>Ce fichier est déjà enregistré.</Typography>}
                    </Box>
                  </Box>
                  <br />
                </>
              ))}
            </DialogContent>
          </Scrollbar>
          <DialogActions>
            
            {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                gap: 1
              }}
            >
              <Typography sx={{ color: theme.palette.text.primary, fontStyle: 'italic', fontSize: 14, mt:-0.6 }}>Enregistrement...</Typography>
              <CircularProgress size={12} />
            </Box>
            ) : (
            <>
              <Button onClick={handleSubmitUploading} 
                disabled={nameExists || attachments.filter((file) => (!file.isTooBig && !file.exists)).length === 0} 
                color="primary">
                Enregistrer
              </Button>
              <Button onClick={handleCloseFileDialog} color="primary">
                Annuler
              </Button>
            </>
            )}
          </DialogActions>
        </Dialog>
        <Dialog onClose={handleCloseFile} open={showFile} maxWidth="xl">
          <DialogTitle disableTypography sx={{display: "flex", justifyContent: 'space-between'}}>
            <Typography variant="h6" sx={{ textAlign: 'center' }}>{fileShowed?.name}</Typography>
            <Box>
              <a download href={formatLink(fileShowed?.link)}>
                <DownloadRoundedIcon color={theme.palette.primary.main} htmlColor={theme.palette.primary.main} />
              </a>
            </Box>
          </DialogTitle>
          <Scrollbar>
            <DialogContent sx={{ mt: 3}}>
              {fileShowed?.type === 'image' ?
              (<img src={formatLink(fileShowed?.link)} alt={fileShowed.name} style={{borderRadius: '6px'}} />)
              : 
              fileShowed?.type === 'video' ?
              (
                <video controls width="700" height="600">
                  <source src={formatLink(fileShowed?.link)} alt={fileShowed.name} type="video/mp4" />
                </video>
              )
              :
              fileShowed?.extension === 'pdf' ?
              (
                <object data={formatLink(fileShowed?.link)} type="application/pdf" width="600" height="600">
                  <p>La lecture de ce document PDF n&lsquo;est pas disponible. Vous pouvez le télécharger <a download href={formatLink(fileShowed?.link)}>ici</a>.</p>
                </object>
              )
              :
              (
                <p>La lecture de ce fichier n&lsquo;est pas disponible. Vous pouvez le télécharger <a download href={formatLink(fileShowed?.link)} style={{ textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 'bold' }}>ici</a>.</p>
              )
              }
            </DialogContent>
          </Scrollbar>
          <DialogActions>
            <Button color="primary"
              onClick={handleCloseFile}>
              Fermer
            </Button>
        </DialogActions>
          </Dialog>
      </>
    )
}

export default FilesMajorIncident
