/* eslint-disable no-unused-vars */
import {
  React, } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core';
import SessionFormationResultTable from './SessionFormationResultTable';

const applyFilters = (sessionsFormation, query) => sessionsFormation
  .filter((formation) => {
    if (formation.name !== null) {
      let matches = true;

      if (query && query.length) {
        const properties = ['name', 'status', 'start_date', 'end_date'];
        let containsQuery = true;

        query.forEach((chip) => {
          let containsChip = false;
          properties.forEach((property) => {
            if (formation[property].toLowerCase().includes(chip.toLowerCase())) {
              containsChip = true;
            }
          });
          if (!containsChip) {
            containsQuery = false;
          }
        });

        if (!containsQuery) {
          matches = false;
        }
      }

      return matches;
    } return false;
  });

const SessionFormationsResults = (props) => {
  const { sessionsFormation, filters, setSessionsFormation, ...other } = props;

  const filteredFormations = applyFilters(sessionsFormation, filters);

  return (
    <div>
      <Box
        {...other}
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Typography
          color="textPrimary"
          sx={{
            position: 'relative',
            '&:after': {
              backgroundColor: 'primary.main',
              bottom: '-8px',
              content: '" "',
              height: '3px',
              left: 0,
              position: 'absolute',
              width: '48px'
            }
          }}
          variant="h5"
        >
          Il y a
          {' '}
          {filteredFormations.filter((ff) => ff.name !== null).length}
          {' '}
          sessions
        </Typography>
      </Box>
      <Grid>
        <SessionFormationResultTable
          sessionsFormation={filteredFormations}
          setSessionsFormation={setSessionsFormation}
        />
      </Grid>
    </div>
  );
};

SessionFormationsResults.propTypes = {
  sessionsFormation: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  setSessionsFormation: PropTypes.func.isRequired
};

export default SessionFormationsResults;
