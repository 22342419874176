/* eslint-disable no-nested-ternary */
import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Typography } from '@material-ui/core';
import GroupsIcon from '@mui/icons-material/Groups';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import CampaignTime from './CampaignTime';
import CampaignCard from './CampaignCard';
import CampaignSource from './CampaignSource';
import CampaignTotal from './CampaignTotal';
import CampaignStatusList from './CampaignStatusList';

const CampaignStatsTab = (props) => {
  const { donnees, progressionProspects, setOpenReinjection, setOpenRetrieve, setSelectedCommercialReinjection, setSelectedCommercialRetrieve, idCampaign } = props;
  const theme = useTheme();
  const { user } = useAuth();
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        sx={{ p: 1 }}
      >
        <Grid
          item
          md={6.5}
          xs={12}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{ pb: 2 }}
            >
              <Card
                sx={{ p: 3, height: 130, }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        width: 60,
                        height: 60
                      }}
                    >
                      <GroupsIcon
                        fontSize="small"
                        sx={{
                          width: 30,
                          height: 50
                        }}
                      />
                    </Avatar>
                    <Box sx={{ ml: 3 }}>
                      <Typography
                        color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                        noWrap
                        variant="body1"
                      >
                        Nombre de prospects
                      </Typography>
                      <Typography
                        color="textPrimary"
                        sx={{ fontWeight: 'fontWeightBold' }}
                        variant="h4"
                      >
                        {donnees.numberProspects !== undefined ? donnees.numberProspects.number : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              {user.id_service === 3 && (
              <Card>
                <Typography
                  sx={{ whiteSpace: 'normal', width: 450, p: 1.5 }}
                  color="#7EB627"
                  variant="h6"
                >
                  Départements :
                  &nbsp;
                  {donnees.campaign !== undefined ? donnees.campaign.department : ''}
                </Typography>
                <Divider />
                <CardContent>
                  <Typography
                    sx={{ whiteSpace: 'normal', width: 400, fontSize: 17 }}
                    color="textPrimary"
                    variant="subtitle2"
                  >
                  &nbsp;
                    {donnees.campaign !== undefined ? donnees.campaign.public : ''}
                  </Typography>
                  {/* </Grid> */}
                </CardContent>
              </Card>
              )}
              {user.access_level === 0 && (
              <Card
                sx={{ p: 3, height: 160 }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap'
                  }}
                >
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                        width: 60,
                        height: 60
                      }}
                    >
                      <SafetyDividerIcon
                        fontSize="small"
                        sx={{
                          width: 30,
                          height: 50
                        }}
                      />
                    </Avatar>
                    <Box sx={{ ml: 3 }}>
                      <Typography
                        color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                        noWrap
                        variant="body1"
                      >
                        Nombre de prospects non attribués
                      </Typography>
                      <Typography
                        color="textPrimary"
                        sx={{ fontWeight: 'fontWeightBold' }}
                        variant="h4"
                      >
                        {donnees.numberProspectsNotAttributed !== undefined ? donnees.numberProspectsNotAttributed.number : ''}
                      </Typography>
                      <Typography
                        color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                        noWrap
                        variant="body1"
                      >
                        Nombre de prospects attribuables
                      </Typography>
                      <Typography
                        color="textPrimary"
                        sx={{ fontWeight: 'fontWeightBold' }}
                        variant="h4"
                      >
                        {donnees.numberProspectsNotAttributed !== undefined ? donnees.numberProspectsNotAttributedAttribuable.number : ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Card>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            mt={1}
          >
            <Grid
              item
              md={6}
              xs={12}
              sx={{ pb: 2 }}
            >
              <Card sx={{ p: 1 }}>
                <CardHeader title="Progression" />
                <Divider />
                <CardContent>
                  <LinearProgress
                    value={progressionProspects}
                    variant="determinate"
                  />
                  <Box sx={{ mt: 3.5 }}>
                    <Typography
                      color={theme.palette.mode === 'light' ? '#7EB627' : 'white'}
                      variant="subtitle2"
                    >
                      {progressionProspects.toFixed(2)}
                      %
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              {user.access_level === 0 && (
              <Card>
                <Typography
                  sx={{ whiteSpace: 'normal', width: 450, p: 1.5 }}
                  color="#7EB627"
                  variant="h6"
                >
                  Départements :
                  &nbsp;
                  {donnees.campaign !== undefined ? donnees.campaign.department : ''}
                </Typography>
                <Divider />
                <CardContent>
                  <Typography
                    sx={{ whiteSpace: 'normal', width: 400, fontSize: 17 }}
                    color="textPrimary"
                    variant="subtitle2"
                  >
                  &nbsp;
                    {donnees.campaign !== undefined ? donnees.campaign.public : ''}
                  </Typography>
                </CardContent>
              </Card>
              )}
            </Grid>
          </Grid>
        </Grid>
        {user.access_level === 0 && (
        <Grid
          sx={{
            backgroundColor: 'background.default',
          }}
          item
          md={5.5}
          xs={12}
        >
          <CampaignTime
            timeToPass={donnees.timeToPass !== undefined ? donnees.timeToPass.time : ''}
            averageTime={donnees.timeToPass !== undefined && donnees.totalTreatedReinjOrNotCustomers !== undefined ? donnees.timeToPass.time / donnees.totalTreatedReinjOrNotCustomers.number : ''}
          />
        </Grid>
        )}
        {user.access_level === 0 && (
        <Grid
          sx={{
            backgroundColor: 'background.default',
            p: 1.5,
          }}
        >
          <CampaignCard
            infoCommercial={donnees.infoCommercial}
            commercial={donnees.commercial}
            setOpenReinjection={setOpenReinjection}
            setOpenRetrieve={setOpenRetrieve}
            setSelectedCommercialReinjection={setSelectedCommercialReinjection}
            setSelectedCommercialRetrieve={setSelectedCommercialRetrieve}
            idCampaign={idCampaign}
            numberProspectsAttribuable={donnees.numberProspectsNotAttributedAttribuable === undefined ? 0 : donnees.numberProspectsNotAttributedAttribuable.number}
          />
        </Grid>
        )}
      </Grid>
      {/* {user.id_service === 3 && typeCampaign === 'réinjection' && (
      <Grid
        sx={{
          backgroundColor: 'background.default', p: 1
        }}
      >
        <CampaignStatusReinjection campaignId={idCampaign} />

      </Grid>
      )} */}
      {user.access_level !== 0 && (
      <Grid>
        <Grid
          sx={{
            backgroundColor: 'background.default', p: 1
          }}
        >
          <CampaignTime
            timeToPass={donnees.timeToPass !== undefined ? donnees.timeToPass.time : ''}
            averageTime={donnees.timeToPass !== undefined && donnees.totalTreatedReinjOrNotCustomers !== undefined ? donnees.totalTreatedReinjOrNotCustomers.number !== 0 ? donnees.timeToPass.time / donnees.totalTreatedReinjOrNotCustomers.number : donnees.timeToPass.time : ''}
          />
        </Grid>
        <Grid
          sx={{
            backgroundColor: 'background.default',
            p: 1,
          }}
        >
          <CampaignSource
            sourceProfession={donnees.sourceProfession}
            professions={donnees.professions === undefined ? [] : donnees.professions}
          />
        </Grid>
      </Grid>
      )}
      {user.access_level === 0 && (
      <Grid
        sx={{
          backgroundColor: 'background.default',
          p: 1
        }}
      >
        <CampaignTotal
          occupationAndSource={donnees.occupationAndSource === undefined ? [] : donnees.occupationAndSource}
          professions={donnees.professions === undefined ? [] : donnees.professions}
          occupationAndSourceTreated={donnees.occupationAndSourceTreated}
        />
      </Grid>
      )}
      {user.access_level === 0 && (
      <Grid
        sx={{
          backgroundColor: 'background.default',
          p: 1
        }}
      >
        <CampaignStatusList
          statusAndSource={donnees.statusAndSource === undefined ? [] : donnees.statusAndSource}
          qualification={donnees.qualification === undefined ? [] : donnees.qualification}
          commercial={donnees.commercial}
        />
      </Grid>
      )}
    </>
  );
};
CampaignStatsTab.propTypes = {
  setOpenReinjection: PropTypes.func,
  setOpenRetrieve: PropTypes.func,
  setSelectedCommercialReinjection: PropTypes.func,
  setSelectedCommercialRetrieve: PropTypes.func,
  idCampaign: PropTypes.string,
  donnees: PropTypes.object.isRequired,
  progressionProspects: PropTypes.number.isRequired,
};
export default CampaignStatsTab;
