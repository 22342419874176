import { Autocomplete, Avatar, Box, Button, Card, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { campaignApi } from "../../api/campaignApi";
import toast from 'react-hot-toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTheme } from '@material-ui/core/styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogReinjection = (propsData) => {
    const { openReinjection, setOpenReinjection, initialValues, validationSchema, setIsLoading, idCampaign, typeCampaign, selectedStatus,
    right, donnees, selectedCommercialsReinjection, selectedCommercialReinjection, activeStep, steps, qualifications, setSelectedStatus,
    setDisplayCustom, displayCustom,handleSelectedCommercialsReinjection, selectedCommercial, handleBack, handleNext, isLoading
 } = propsData;

    const theme = useTheme();

    return (
        <Dialog
        open={openReinjection}
        onClose={() => setOpenReinjection(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Paramètres de la réinjection</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            // enableReinitialize
            validationSchema={validationSchema}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              try {
                // NOTE: Make API request
                setIsLoading(true);
                const campaignId = await campaignApi.createReinjection(idCampaign, typeCampaign, selectedStatus, right, donnees.commercial, selectedCommercialsReinjection, selectedCommercialReinjection, values, localStorage.getItem('accessToken'));
                setStatus({ success: true });
                setSubmitting(false);
                toast.success('Réinjection créée avec succès');
                setOpenReinjection(false);
                setIsLoading(false);
                // rechargement de la page
                window.location.reload(false);
              } catch (err) {
                console.error(err);
                toast.error('Il y a eu un souci lors de la création de la réinjection !');
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setIsLoading(false);
              }
            }}
          >
            {({ errors, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form
                onSubmit={handleSubmit}
              >
                <Stepper
                  activeStep={activeStep}
                  sx={{ mb: 2 }}
                >
                  {steps.map((label) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step
                        key={label}
                        {...stepProps}
                      >
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    {activeStep === 0 && (
                    <>
                      <Grid container>
                        <Autocomplete
                          multiple
                          id="checkboxes-tags-demo"
                          options={qualifications}
                          value={selectedStatus}
                          disableCloseOnSelect
                          onChange={(event, newValue) => {
                            setSelectedStatus([
                              ...newValue
                            ]);
                          }}
                          getOptionLabel={(option) => option.qualification}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.qualification}
                            </li>
                          )}
                          style={{ width: 400 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Statuts"
                            />
                          )}
                        />
                      </Grid>
                    </>
                    )}
                    {activeStep === 1 && (
                    <>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="same"
                          name="assignment"
                          value={values.assignment}
                          onChange={(e, value) => {
                            setFieldValue(
                              'assignment', value
                            );
                          }}
                        >
                          <FormControlLabel
                            value="Même assignation"
                            control={<Radio />}
                            label="Même assignation"
                            onClick={() => setDisplayCustom(false)}
                            checked
                          />
                        </RadioGroup>
                      </FormControl>
                      {displayCustom && (
                      <>
                        <Grid
                          container
                          sx={{ mb: 1 }}
                        >
                          {donnees.commercial.map((item) => (
                            <Card
                              sx={{ mr: 2, mt: 1, p: 1, cursor: 'pointer', backgroundColor: selectedCommercialsReinjection.indexOf(item.caller_id) === -1 ? 'inherit' : theme.palette.mode === 'light' ? '#daff92' : '#7eb627', border: item.caller_id === selectedCommercial ? theme.palette.mode === 'light' ? '2px solid #1c2445' : '2px solid white' : '' }}
                              onClick={() => { handleSelectedCommercialsReinjection(item.caller_id); }}
                            >
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Avatar
                                  src={item.avatar}
                                />
                                <Typography
                                  variant="h6"
                                >
                                  {' '}
                        &nbsp;
                        &nbsp;
                                  {`${item.firstname} ${item.lastname}`}
                                </Typography>
                              </Box>
                            </Card>
                          ))}
                        </Grid>
                      </>
                      )}
                    </>
                    )}
                    {activeStep === 2 && (
                    <>
                      <Typography
                        variant="h5"
                        sx={{ mb: 2 }}
                      >
                        Récapitulatif
                      </Typography>
                      <Box>
                        <Typography
                          variant="h6"
                        >
                          Statuts
                        </Typography>
                        <Box>
                          {selectedStatus.length > 0 ? (selectedStatus.map((status) => (
                            <Typography
                              variant="body1"
                              sx={{ ml: 3 }}
                            >
                              {status.qualification}
                            </Typography>
                          ))) : (
                            <Typography
                              variant="body1"
                              sx={{ ml: 3 }}
                            >
                              Tous statuts
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <Grid container>
                          <Typography
                            variant="h6"
                          >
                            Assignation :
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ ml: 1, mt: 0.4 }}
                          >
                            {values.assignment}
                          </Typography>
                        </Grid>
                        {values.assignment === 'Personnalisée' && (
                        <Box>
                          <Typography>
                            {`Commerciaux dont on réinjecte les prospects pour : ${donnees.commercial[donnees.commercial.map((call) => call.caller_id).indexOf(parseInt(selectedCommercialReinjection, 10))].firstname} ${donnees.commercial[donnees.commercial.map((call) => call.caller_id).indexOf(parseInt(selectedCommercialReinjection, 10))].lastname}`}
                          </Typography>
                          {selectedCommercialsReinjection.map((commercialId) => (
                            <>
                              <Typography
                                variant="body1"
                                color="#7eb627"
                                sx={{ ml: 3 }}
                              >
                                {`${donnees.commercial[donnees.commercial.map((call) => call.caller_id).indexOf(parseInt(commercialId, 10))].firstname} ${donnees.commercial[donnees.commercial.map((call) => call.caller_id).indexOf(parseInt(commercialId, 10))].lastname}`}
                              </Typography>
                            </>
                          ))}
                        </Box>
                        )}
                      </Box>
                      <ErrorMessage
                        name="startDate"
                        render={(msg) => <p><font color="RED">{msg}</font></p>}
                      />
                      <ErrorMessage
                        name="endDate"
                        render={(msg) => <p><font color="RED">{msg}</font></p>}
                      />
                      <ErrorMessage
                        name="assignment"
                        render={(msg) => <p><font color="RED">{msg}</font></p>}
                      />
                    </>
                    )}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Précédent
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {activeStep !== 2 ? (
                      <Button onClick={handleNext}>
                        {activeStep === steps.length - 2 ? 'Passer au récapitulatif' : 'Suivant'}
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        Enregistrer
                      </Button>
                    )}
                    {isLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                </>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
};

export default DialogReinjection;
