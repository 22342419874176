import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Logo from '../Logo';
import SessionIncomingAlert from './session/SessionIncomingAlert';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme, padding }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: padding
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [padding, setPadding] = useState('');

  useEffect(() => {
    setPadding(drawerOpen ? '14%' : '3%');
  }, [drawerOpen]);

  return (
    <DashboardLayoutRoot>
      {/* Logo rajouté mais affiché en tout petit pour que le svg logo dans le dashboardsidebar s'affiche totalement (vert inclus) */}
      <Logo
        sx={{
          height: 10,
          width: 10
        }}
      />
      <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
      />
      <DashboardLayoutWrapper padding={padding}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <SessionIncomingAlert />
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
