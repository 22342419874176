/* eslint-disable no-confusing-arrow */
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import { campaignApi } from "../../api/campaignApi";
import toast from 'react-hot-toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect, useState } from "react";
import * as Yup from 'yup';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DialogManageCommercials = (propsData) => {
const { setIsLoading, idCampaign, isLoading, openManageCommercials, commercialsCampaignManageNotTreated, commercialsCampaignManage,
    setOpenManageCommercials } = propsData;

 const [displayCommercialToManage, setDisplayCommercialToManage] = useState(false);
 const [displayCommercialToTakeCustomers, setDisplayCommercialToTakeCustomers] = useState(false);
 const [displayNumberCustomersManage, setDisplayNumberCustomersManage] = useState(false);
 const [selectEquitableManage, setSelectEquitableManage] = useState(false);
 const [selectedCommercialToTakeCustomers, setSelectedCommercialToTakeCustomers] = useState({ firstname: '', lastname: '', id: 0 });
 const [commercialsCampaignToTakeCustomers, setCommercialsCampaignToTakeCustomers] = useState([]);
 const [commercialsCampaignToManage, setCommercialsCampaignToManage] = useState([]);
 const [selectedCommercialToManage, setSelectedCommercialToManage] = useState({ firstname: '', lastname: '', id: 0 });
 const [nbCustomersManage, setNbCustomersManage] = useState(0);

 useEffect(() => {
    setCommercialsCampaignToTakeCustomers(commercialsCampaignManageNotTreated.filter((el) => selectedCommercialToManage === null ? el : el.id !== selectedCommercialToManage.id));
    setCommercialsCampaignToManage(commercialsCampaignManage.filter((el) => selectedCommercialToTakeCustomers === null ? el : el.id !== selectedCommercialToTakeCustomers.id));
  }, [commercialsCampaignManage, commercialsCampaignManageNotTreated, selectedCommercialToManage, selectedCommercialToTakeCustomers]);

  const handleLeavingDialogManageCommercial = () => {
    if (!openManageCommercials) {
      setDisplayCommercialToManage(false);
      setDisplayCommercialToTakeCustomers(false);
      setDisplayNumberCustomersManage(false);
      setSelectEquitableManage(false);
      setSelectedCommercialToTakeCustomers({ firstname: '', lastname: '', id: 0 });
      setSelectedCommercialToManage({ firstname: '', lastname: '', id: 0 });
    }
  };

  const initialValuesManageCommercials = {
    choiceCommercialToAddCustomers: { firstname: '', lastname: '' },
    choiceCommercialToTakeCustomers: { firstname: '', lastname: '' },
    typeManage: null,
    nbCustomersManage: 0
  };

  const validationSchemaManageCommercials = Yup
    .object()
    .shape({
      choiceCommercialToAddCustomers: Yup.object(),
      choiceCommercialToTakeCustomers: Yup.object(),
      typeManage: Yup.string().required('Un type de remaniement est requis'),
      // pour mettre à jour la valeur max du champ en fonction du choix dans choiceCommercialToTakeCustomers
      nbCustomersManage: Yup.number().when('choiceCommercialToTakeCustomers', (commercial) => commercial === null || Object.keys(commercial).length === 0 ? Yup.number() : Yup.number().max(commercial.nbNotTreated, 'Nombre trop grand')),
    });

  useEffect(() => {
    handleLeavingDialogManageCommercial();
  }, [openManageCommercials]);


    return (
        <Dialog
        open={openManageCommercials}
        onClose={() => setOpenManageCommercials(false)}
        fullWidth
        maxWidth="md"
      >
        {/* pour centrer dialogtitle */}
        <DialogTitle sx={{ textAlign: 'center' }}>Gérer les commerciaux</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValuesManageCommercials}
            // enableReinitialize
            validationSchema={validationSchemaManageCommercials}
            onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
              try {
                console.log(values);
                setIsLoading(true);
                await campaignApi.manageCommercials(idCampaign, values, localStorage.getItem('accessToken'));
                setStatus({ success: true });
                setSubmitting(false);
                toast.success('Remaniement des commerciaux effectué avec succès');
                setOpenManageCommercials(false);
                setIsLoading(false);
                window.location.reload(false);
              } catch (err) {
                console.error(err);
                toast.error('Il y a eu un souci lors du remaniement des commerciaux !');
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setIsLoading(false);
              }
            }}
          >
            {({ errors, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
              <form
                onSubmit={handleSubmit}
              >
                <>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    <>
                      <Grid
                        container
                        display="flex"
                        alignItems="center"
                      >
                        
                        <Box>
                          <>
                            <Box sx={{ mb: 2 }}>
                              <FormControl>
                                <Typography variant="h6">Type de remaniement</Typography>
                                <RadioGroup
                                  row
                                  aria-labelledby="radio-buttons-group-label-manage-commercials"
                                  defaultValue="Équitable"
                                  name="typeManage"
                                  value={values.typeManage}
                                  onChange={(e, value) => {
                                    setFieldValue(
                                      'typeManage', value
                                    );
                                  }}
                                >
                                  <FormControlLabel
                                    value="Équitable"
                                    control={<Radio />}
                                    label="Équitable"
                                    onClick={() => {
                                      setDisplayCommercialToManage(false);
                                      setDisplayCommercialToTakeCustomers(false);
                                      setDisplayNumberCustomersManage(false);
                                      setSelectEquitableManage(true);
                                      // setSelectReattributionManage(false);
                                      setFieldValue(
                                        'choiceCommercialToAddCustomers', {}
                                      );
                                      setFieldValue(
                                        'choiceCommercialToTakeCustomers', {}
                                      );
                                    }}
                                  />
                                  <FormControlLabel
                                    value="Réattribution"
                                    control={<Radio />}
                                    label="Réattribution"
                                    onClick={() => {
                                      setDisplayCommercialToManage(true);
                                      setDisplayCommercialToTakeCustomers(true);
                                      setDisplayNumberCustomersManage(true);
                                      setSelectEquitableManage(false);
                                      // setSelectReattributionManage(true);
                                      setFieldValue(
                                        'choiceCommercialToAddCustomers', { firstname: '', lastname: '' }
                                      );
                                      setFieldValue(
                                        'choiceCommercialToTakeCustomers', { firstname: '', lastname: '' }
                                      );
                                    }}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            <ErrorMessage
                              name="typeManage"
                              render={(msg) => <p><font color="RED">{msg}</font></p>}
                            />
                            <ErrorMessage
                              name="nbCustomersManage"
                              render={(msg) => <p><font color="RED">{msg}</font></p>}
                            />
                            {/* <ErrorMessage
                              name="nbCustomersNotAttributed"
                              render={(msg) => <p><font color="RED">{msg}</font></p>}
                            /> */}
                            <Grid container>
                              {displayCommercialToTakeCustomers && (
                              <Autocomplete
                                id="checkboxes-tags-manage-commercial-to-take"
                                options={commercialsCampaignToTakeCustomers}
                                value={values.choiceCommercialToTakeCustomers}
                                // disableCloseOnSelect
                                // onChange={(event, newValue) => {
                                //   setSelectedCommercialToTakeCustomers(newValue);
                                // }}
                                onChange={(e, value) => {
                                  setFieldValue(
                                    'choiceCommercialToTakeCustomers', value
                                  );
                                  setSelectedCommercialToTakeCustomers(value);
                                  // pour changer dynamiquement la valeur d'apprenants maximum à transférer
                                  if (value !== null) {
                                    setFieldValue(
                                      'nbCustomersManage', value.nbNotTreated
                                    );
                                  } else {
                                    setFieldValue(
                                      'nbCustomersManage', 0
                                    );
                                  }
                                }}
                                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {`${option.firstname} ${option.lastname} (non traités : ${option.nbNotTreated})`}
                                  </li>
                                )}
                                style={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Commercial auquel on prend"
                                  />
                                )}
                              />
                              )}
                              {displayNumberCustomersManage && (
                              <>
                                <TextField
                                  label="Nombre d'apprenants à transférer"
                                  type="number"
                                  autoFocus
                                  onChange={(event) => {
                                    values.nbCustomersManage = parseInt(event.target.value, 10);
                                    setNbCustomersManage(parseInt(event.target.value, 10));
                                  }}
                                  value={parseInt(values.nbCustomersManage, 10)}
                                  style={{ marginLeft: 10 }}
                                  variant="outlined"
                                  InputProps={{ inputProps: { max: nbCustomersManage } }}
                                />
                              </>

                              )}
                              {displayCommercialToManage && (
                              <Autocomplete
                                id="checkboxes-tags-manage-commercial-to-take"
                                options={commercialsCampaignToManage}
                                value={values.choiceCommercialToAddCustomers}
                                // disableCloseOnSelect
                                // onChange={(event, newValue) => {
                                //   setSelectedCommercialToManage(newValue);
                                // }}
                                onChange={(_, value) => {
                                  setFieldValue(
                                    'choiceCommercialToAddCustomers', value
                                  );
                                  setSelectedCommercialToManage(value);
                                }}
                                getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      checked={selected}
                                    />
                                    {`${option.firstname} ${option.lastname}`}
                                  </li>
                                )}
                                style={{ width: 300, marginLeft: 10 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Commercial auquel on attribue"
                                  />
                                )}
                              />
                              )}
                            </Grid>
                          </>
                        </Box>
                      </Grid>
                    </>
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
                    <Button
                      onClick={handleSubmit}
                      // disabled={isSubmitting || (!selectEquitableManage && (selectReattributionManage || selectReattributionManage === null)) || (selectedCommercialToManage == null && !selectEquitableManage) || (selectedCommercialToTakeCustomers === null && !selectEquitableManage) || (selectedCommercialToManage.firstname === '' && selectedCommercialToManage.lastname === '' && !selectEquitableManage) || (selectedCommercialToTakeCustomers.firstname === '' && selectedCommercialToTakeCustomers.lastname === '' && !selectEquitableManage)}
                      disabled={isSubmitting || (selectedCommercialToManage == null && !selectEquitableManage) || (selectedCommercialToTakeCustomers === null && !selectEquitableManage) || (selectedCommercialToManage.firstname === '' && selectedCommercialToManage.lastname === '' && !selectEquitableManage) || (selectedCommercialToTakeCustomers.firstname === '' && selectedCommercialToTakeCustomers.lastname === '' && !selectEquitableManage)}
                    >
                      Enregistrer
                    </Button>
                    {isLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                </>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
};

export default DialogManageCommercials;
