/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { Label } from '@material-ui/icons';
import React, { useState } from 'react';
import CustomReportDetailsLineChart from './CustomReportDetailsLineChart';
import CustomReportDetailsBarChart from './CustomReportDetailsBarChart';
import CustomReportDetailsTimeLineChart from './CustomReportDetailsTimeLineChart';
import CustomReportDetailsDonutChart from './CustomReportDetailsDonutChart';

const CustomReportDetailsTab = ({ amounts, barData, linesData, timelinesData }) => (
  <>
    <Box
      sx={{
        my: 0
      }}
    >
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
      >
        {amounts && (
          <Grid
            item
            xs={12}
            sx={{
              py: 2,
              textAlign: 'center'
            }}
          >
            <Card>
              <Grid
                alignItems="center"
                container
                justifyContent="space-between"
              >
                {amounts.map((item) => (
                  <Grid
                    item
                    md={3}
                    sm={6}
                    xs={12}
                    sx={{
                      p: 2,
                      textAlign: 'center'
                    }}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      {item.amount}
                      {' '}
                      €
                    </Typography>
                    <Typography
                      color="textSecondary"
                      sx={{ mt: 1 }}
                      variant="h6"
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Card>
          </Grid>
        )}

        {/* <Grid
            item
            md={4}
            sm={12}
            xs={12}
            sx={{
              p: 2,
              textAlign: 'center'
            }}
          >
            <CustomReportDetailsDonutChart />
          </Grid> */}

        <Grid
          item
          md={6}
          xs={12}
          sx={{
            pr: 1,
            py: 3,
            textAlign: 'center'
          }}
        >
          <CustomReportDetailsBarChart chartSeries={barData} />
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
          sx={{
            py: 3,
            pl: 1,
            textAlign: 'center'
          }}
        >
          <CustomReportDetailsTimeLineChart chartSeries={linesData} />
        </Grid>

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          sx={{
            py: 3,
            textAlign: 'center'
          }}
        >
          <CustomReportDetailsLineChart timelinesData={timelinesData} />
        </Grid>

      </Grid>
    </Box>
  </>
);

export default CustomReportDetailsTab;
