/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import useAuth from '../../hooks/useAuth';
import { Autocomplete, Backdrop, Box, Button, Card, CircularProgress, Container, Paper, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Typography } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import * as moment from 'moment';
import useMounted from '../../hooks/useMounted';
import { useState, useCallback, useEffect } from 'react';
import Scrollbar from '../../components/Scrollbar';
import { logApi } from '../../api/logApi';
import Label from '../../components/Label';
import useSettings from '../../hooks/useSettings';

const sortOptions = [
  {
    label: 'Plus récent',
    value: 'created_at|desc'
  },
  {
    label: 'Plus ancien',
    value: 'created_at|asc'
  },
];

const Logs = () => {
  // const { settings } = useSettings();
  const { user } = useAuth();
  const theme = useTheme();
  const mounted = useMounted();
  const { settings } = useSettings();
  const [logs, setLogs] = useState([]);
  const [logsInfos, setLogsInfos] = useState({ categories: [], types: [], users: [], customers: [] });
  // const [filteredLogs, setFilteredLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [numberLogs, setNumberLogs] = useState(0);
  const [limit, setLimit] = useState(25);
  // const [isSearching, setIsSearching] = useState(false);
  const [sort, setSort] = useState('created_at|desc');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [userSyna, setUserSyna] = useState('all');
  const [category, setCategory] = useState('all');
  const [type, setType] = useState('all');
  const [customer, setCustomer] = useState('all');
  const [customerAutocomplete, setCustomerAutocomplete] = useState({ value: 'all', label: 'Tous' });
  const [choiceCustomer, setChoiceCustomer] = useState('');
  const [usersOptions, setUsersOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [customersOptions, setCustomersOptions] = useState([]);

  useEffect(() => {
    const usersOptionsTemp = logsInfos.users.map((userInfo) => ({ value: userInfo.userId, label: `${userInfo.firstname} ${userInfo.lastname}` }));
    usersOptionsTemp.unshift({
      value: 'all',
      label: 'Tous'
    });
    setUsersOptions(usersOptionsTemp);

    const categoriesOptionsTemp = logsInfos.categories.map((categoryInfo) => ({ value: categoryInfo.category, label: categoryInfo.category.charAt(0).toUpperCase() + categoryInfo.category.slice(1) }));
    categoriesOptionsTemp.unshift({
      value: 'all',
      label: 'Tous'
    });
    setCategoriesOptions(categoriesOptionsTemp);

    const typesOptionsTemp = logsInfos.types.map((typeInfo) => ({ value: typeInfo.type, label: typeInfo.type.charAt(0).toUpperCase() + typeInfo.type.slice(1) }));
    typesOptionsTemp.unshift({
      value: 'all',
      label: 'Tous'
    });
    setTypesOptions(typesOptionsTemp);

    const customersOptionsTemp = logsInfos.customers.map((customerInfo) => ({ value: customerInfo.id, label: `${customerInfo.firstname} ${customerInfo.lastname}` }));
    customersOptionsTemp.unshift({
      value: 'all',
      label: 'Tous'
    });
    setCustomersOptions(customersOptionsTemp);
  }, [logsInfos]);

  const handleValuesFilters = (async (userChange, categoryChange, typeChange, customerChange, startDateChange, endDateChange) => {
    try {
      // si le spinner de la page n'est pas actif (loading), on déclenche le spinner de la table des apprenants au chargement des données
      setIsLoading(true);
      // setIsSearching(true);
      const data = await logApi.getValuesFilters(window.localStorage.getItem('accessToken'), userChange, categoryChange, typeChange,
        customerChange, startDateChange, endDateChange);
      // setNumberLogs(data.numberLogs.numberLogs);
      // setLogs(data.logs);
      // setUsersOptions(data.users);
      const usersOptionsTemp = data.users.map((userInfo) => ({ value: userInfo.userId, label: `${userInfo.firstname} ${userInfo.lastname}` }));
      usersOptionsTemp.unshift({
        value: 'all',
        label: 'Tous'
      });
      setUsersOptions(usersOptionsTemp);
      const categoriesOptionsTemp = data.categories.map((categoryInfo) => ({ value: categoryInfo.category, label: categoryInfo.category.charAt(0).toUpperCase() + categoryInfo.category.slice(1) }));
      categoriesOptionsTemp.unshift({
        value: 'all',
        label: 'Tous'
      });
      setCategoriesOptions(categoriesOptionsTemp);

      const typesOptionsTemp = data.types.map((typeInfo) => ({ value: typeInfo.type, label: typeInfo.type.charAt(0).toUpperCase() + typeInfo.type.slice(1) }));
      typesOptionsTemp.unshift({
        value: 'all',
        label: 'Tous'
      });
      setTypesOptions(typesOptionsTemp);

      const customersOptionsTemp = data.customers.map((customerInfo) => ({ value: customerInfo.id, label: `${customerInfo.firstname} ${customerInfo.lastname}` }));
      customersOptionsTemp.unshift({
        value: 'all',
        label: 'Tous'
      });
      setCustomersOptions(customersOptionsTemp);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    handleValuesFilters(userSyna, category, type, customer, startDate, endDate);
  }, [userSyna, category, type, customer, startDate, endDate]);

  const getLogs = useCallback(async () => {
    try {
      const data = await logApi.getAllLogs(sort, userSyna, category, type, customer,
        limit, page, localStorage.getItem('accessToken'));
      const dataInfos = await logApi.getLogsInfos(localStorage.getItem('accessToken'));
      //   console.log(data);
      //   console.log(dataInfos);
      if (mounted.current) {
        setLogs(data.logs);
        setLogsInfos(dataInfos);
        setNumberLogs(data.numberLogs.numberLogs);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getLogs();
  }, [getLogs]);
  // pour afficher le loader uniquement dans le tableau
  const StyledPaper = withStyles({
    root: {
      position: 'relative'
    }
  })(Paper);
  const LimitedBackdrop = withStyles({
    root: {
      position: 'absolute',
      zIndex: 1
    }
  })(Backdrop);

  const handleLogs = (async (sortChange, startDateChange, endDateChange, userChange, categoryChange, typeChange, customerChange, limitChoice, pageChoice, displaySpinnerTable = false) => {
    try {
      // si le spinner de la page n'est pas actif (loading), on déclenche le spinner de la table des apprenants au chargement des données
      if (!isLoading && displaySpinnerTable) {
        setIsLoading(true);
      }
      // setIsSearching(true);
      const data = await logApi.getLogs(window.localStorage.getItem('accessToken'), sortChange, startDateChange, endDateChange, userChange, categoryChange, typeChange,
        customerChange, limitChoice, pageChoice);
      setNumberLogs(data.numberLogs.numberLogs);
      setLogs(data.logs);
      // setOptionsSearch(data.customers);
      setIsLoading(false);
      // setIsSearching(false);
      // setLoadingAutocomplete(false);
    } catch (err) {
      console.error(err);
    }
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleLogs(sort, startDate, endDate, userSyna, category, type, customer, limit, newPage, true);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);// handleLogs(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, parseInt(event.target.value, 10), 0, modeRelance, true, true);
    handleLogs(sort, startDate, endDate, userSyna, category, type, customer, event.target.value, 0, true);
  };

  const handleSortChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSort(value);
    } else {
      setSort(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleLogs(event.target.value, startDate, endDate, userSyna, category, type, customer, limit, 0, true);
  };

  const handleStartDateChange = (event, value) => {
    if (event.target.value !== '') {
      setStartDate(moment(new Date(event.target.value)).format('YYYY-MM-DD'));
    } else {
      setStartDate('');
    }
    setPage(0);
    handleLogs(sort, moment(new Date(event.target.value)).format('YYYY-MM-DD'), endDate, userSyna, category, type, customer, limit, 0, true);
  };

  const handleEndDateChange = (event, value) => {
    if (event.target.value !== '') {
      setEndDate(moment(new Date(event.target.value)).format('YYYY-MM-DD'));
    } else {
      setEndDate('');
    }
    setPage(0);
    handleLogs(sort, startDate, moment(new Date(event.target.value)).format('YYYY-MM-DD'), userSyna, category, type, customer, limit, 0, true);
  };

  const handleUserChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setUserSyna(value);
    } else {
      // console.log(event.target);
      setUserSyna(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleLogs(sort, startDate, endDate, event.target.value, category, type, customer, limit, 0, true);
  };

  const handleCategoryChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setCategory(value);
    } else {
      // console.log(event.target);
      setCategory(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleLogs(sort, startDate, endDate, userSyna, event.target.value, type, customer, limit, 0, true);
  };

  const handleTypeChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setType(value);
    } else {
      // console.log(event.target);
      setType(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleLogs(sort, startDate, endDate, userSyna, category, event.target.value, customer, limit, 0, true);
  };

  const handleCustomerChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setCustomer(value);
    } else {
      // console.log(event.target);
      setCustomer(event.target.value);
      // window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleLogs(sort, startDate, endDate, userSyna, category, type, event.target.value, limit, 0, true);
  };

  const reinitializeFilters = () => {
    // on met à jour le localstorage
    // window.localStorage.setItem('choiceTabProfession', JSON.stringify('all'));
    // window.localStorage.setItem('choiceRelance', JSON.stringify('all'));
    // window.localStorage.setItem('choiceCampaign', JSON.stringify('all'));
    // window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all'));
    // window.localStorage.setItem('choiceNewCallTrack', JSON.stringify('all'));
    // window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
    // window.localStorage.setItem('choiceAvancement', JSON.stringify('all'));
    // window.localStorage.setItem('choiceEndSession', JSON.stringify('all'));
    // window.localStorage.setItem('choiceSource', JSON.stringify('all'));
    // on réinitialise les filtres
    setSort('created_at|desc');
    setStartDate('');
    setEndDate('');
    setType('all');
    setCategory('all');
    setUserSyna('all');
    setCustomer('all');
    setCustomerAutocomplete({ value: 'all', label: 'Tous' });
    setPage(0);
    // // on affiche les résultats réinitialisés
    handleLogs('created_at|desc', '', '', 'all', 'all', 'all', 'all', limit, page, true);
  };
  return (
    <>
      <Helmet>
        <title>Dashboard: Activités | Apteed</title>
      </Helmet>
      <Box
      // sx={{ mt: 2 }}
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          {user.access_level === 0 && (
          <>
            <Typography
              // color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
              // variant="h3"
              color="textPrimary"
              variant="h5"
            >
              Activités
            </Typography>
            <Box
              sx={{
                mt: 2
              }}
            >
              <Card>
                <Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexWrap: 'wrap',
                      m: -1,
                      p: 2
                    }}
                  >
                    <Box
                      sx={{
                        m: 1
                      }}
                    >
                      <TextField
                        key="sort"
                        label="Trier par date"
                        name="sort"
                        onChange={handleSortChange}
                        select
                        SelectProps={{ native: true }}
                        value={sort}
                        variant="outlined"
                        disabled={isLoading}
                      >
                        {sortOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                    <Box
                      sx={{
                        m: 1
                      }}
                    >
                      <TextField
                        // error={Boolean(touched.startRelaunchDate && errors.startRelaunchDate)}
                        // fullWidth
                        // helperText={touched.startRelaunchDate && errors.startRelaunchDate}
                        label="Date de début"
                        name="startDate"
                        type="date"
                        // onBlur={handleBlur}
                        onChange={handleStartDateChange}
                        value={startDate}
                        variant="outlined"
                        // pour éviter la superposition de jj/mm/aaaa et début de relance dans le champ
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                    <Box
                      sx={{
                        m: 1
                      }}
                    >
                      <TextField
                        // error={Boolean(touched.startRelaunchDate && errors.startRelaunchDate)}
                        // fullWidth
                        // helperText={touched.startRelaunchDate && errors.startRelaunchDate}
                        label="Date de fin"
                        name="endDate"
                        type="date"
                        // onBlur={handleBlur}
                        onChange={handleEndDateChange}
                        value={endDate}
                        variant="outlined"
                        // pour éviter la superposition de jj/mm/aaaa et début de relance dans le champ
                        InputLabelProps={{ shrink: true }}
                      />
                    </Box>
                    <Box
                      sx={{
                        m: 1
                      }}
                    >
                      <TextField
                        key="user"
                        label="Utilisateur"
                        name="user"
                        onChange={handleUserChange}
                        select
                        SelectProps={{ native: true }}
                        value={userSyna}
                        variant="outlined"
                        disabled={isLoading}
                  // pour éviter la superposition dans le champ
                        InputLabelProps={{ shrink: true }}
                      >
                        {usersOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                    <Box
                      sx={{
                        m: 1
                      }}
                    >
                      <TextField
                        key="category"
                        label="Catégorie"
                        name="category"
                        onChange={handleCategoryChange}
                        select
                        SelectProps={{ native: true }}
                        value={category}
                        variant="outlined"
                        disabled={isLoading}
                  // pour éviter la superposition dans le champ
                        InputLabelProps={{ shrink: true }}
                      >
                        {categoriesOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                    <Box
                      sx={{
                        m: 1
                      }}
                    >
                      <TextField
                        key="type"
                        label="Type"
                        name="type"
                        onChange={handleTypeChange}
                        select
                        SelectProps={{ native: true }}
                        value={type}
                        variant="outlined"
                        disabled={isLoading}
                  // pour éviter la superposition dans le champ
                        InputLabelProps={{ shrink: true }}
                      >
                        {typesOptions.map((option) => (
                          <option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Box>
                    <Box
                      sx={{
                        m: 1,
                        maxWidth: '100%',
                        width: 250
                      }}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.label}
                        options={customersOptions}
                //   disableClearable
                        onChange={(e, value) => {
                          if (value !== null) {
                            setCustomer(value.value);
                            handleLogs(sort, startDate, endDate, userSyna, category, type, value.value, limit, 0, true);
                            setCustomerAutocomplete({ value: value.value, label: value.label });
                          } else {
                            setCustomer('all');
                            handleLogs(sort, startDate, endDate, userSyna, category, type, 'all', limit, 0, true);
                          // setCustomerAutocomplete({ value: 'all', label: 'Tous' });
                          }
                          setPage(0);
                          // setSelectedCommercialToAdd(value);
                          // setFieldValue(
                          //   'choiceCommercialToAdd',
                          //   value !== null ? value : selectedCommercialToAdd
                          // );
                        }}
                        value={customerAutocomplete}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Apprenant"
                            name="customer"
                            variant="outlined"
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Button
                    onClick={reinitializeFilters}
                    color="primary"
                    sx={{ ml: 2, mb: 1 }}
                    variant="outlined"
                  >
                    Réinitialiser les filtres
                  </Button>
                </Box>

                <StyledPaper>
                  <Scrollbar>

                    <Box sx={{ minWidth: 700 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              Utilisateur
                            </TableCell>
                            <TableCell>
                              Catégorie
                            </TableCell>
                            <TableCell>
                              Type
                            </TableCell>
                            <TableCell>
                              Description
                            </TableCell>
                            <TableCell>
                              Date
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {logs.map((log, index) =>
                          // const isCustomerSelected = selectedCustomers.includes(log.id);
                          // eslint-disable-next-line implicit-arrow-linebreak
                            (
                              <TableRow
                                hover
                                key={log.id}
                              >
                                <TableCell>
                                  {log.userInfos.firstname}
                                  {' '}
                                  {log.userInfos.lastname}
                                </TableCell>
                                <TableCell>
                                  {log.category === 'utilisateur' && (
                                  <Label
                                    color="success"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'Qualité' && (
                                  <Label
                                    color="error"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'notification' && (
                                  <Label
                                    color="error"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'apprenant' && (
                                  <Label
                                    color="warning"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'campagne' && (
                                  <Label
                                    color="secondary"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'session' && (
                                  <Label
                                    color="primary"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'formation' && (
                                  <Label
                                    color="formation"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'partenaires' && (
                                  <Label
                                    color="partenaires"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                  {log.category === 'prestations' && (
                                  <Label
                                    color="prestations"
                                  >
                                    {log.category}
                                  </Label>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {/* {log.type === 'relance email' && (
                                  <Label
                                    color="warning"
                                  >
                                    {log.type}
                                  </Label>
                                  )}
                                  {log.type === 'suite email' && (
                                  <Label
                                    color="success"
                                  >
                                    {log.type}
                                  </Label>
                                  )} */}
                                  {log.type.charAt(0).toUpperCase() + log.type.slice(1)}
                                </TableCell>
                                <TableCell>
                                  {/* permet d'afficher le texte de la description avec le lien cliquable arrivant de la bdd */}
                                  <div dangerouslySetInnerHTML={{ __html: log.description }} />
                                </TableCell>
                                <TableCell>
                                  {moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                </TableCell>
                              </TableRow>
                            ))}
                          <LimitedBackdrop
                            sx={{ color: '#fff', zIndex: (them) => them.zIndex.drawer + 1 }}
                            open={isLoading}
                          >
                            <CircularProgress color="inherit" />
                          </LimitedBackdrop>
                        </TableBody>
                      </Table>
                    </Box>
                  </Scrollbar>
                </StyledPaper>
                <TablePagination
                  component="div"
                  count={numberLogs}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[15, 25, 50]}
                  SelectProps={{
                    disabled: isLoading
                  }}
                  backIconButtonProps={
                isLoading
                  ? { disabled: isLoading } : undefined
              }
                  nextIconButtonProps={
                isLoading
                  ? { disabled: isLoading } : undefined
              }
                />
              </Card>
            </Box>
          </>
          )}
          {user.access_level !== 0 && (
            'Vous n\'avez pas les droits pour voir cette page'
          )}
        </Container>
      </Box>
    </>
  );
};

export default Logs;
