import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import { formationApi } from '../../../api/formationApi';
import FormationsFilterSatisfactionPerformance from '../../../components/dashboard/formation/FormationsFilterSatisfactionPerformance';
import FormationsResultsSatisfaction from '../../../components/dashboard/formation/FormationsResultsSatisfaction';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../icons/ChevronRight';
import gtm from '../../../lib/gtm';
import useAuth from '../../../hooks/useAuth';

const FormationsSatisfaction = () => {
  const mounted = useMounted();
  const auth = useAuth();
  const { settings } = useSettings();
  const [filters, setFilters] = useState([]);
  const [formations, setFormations] = useState([]);
  const [filteredFormations, setFilteredFormations] = useState([]);
  const [loading, setLoading] = useState(false);

  // remet filtres à zéro de src/components/dashboard/customer/CustomerListTable.js
  window.localStorage.setItem('choiceTabProfession', JSON.stringify('all'));
  window.localStorage.setItem('choiceSort', JSON.stringify('lastname|asc'));
  window.localStorage.setItem('choiceSource', JSON.stringify('all'));
  window.localStorage.setItem('choiceCampaign', JSON.stringify('all'));
  window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
  window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all'));
  window.localStorage.setItem('choiceAvancement', JSON.stringify('all'));

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getFormationsSatisfactions = useCallback(async () => {
    setLoading(true);
    try {
      const data = await formationApi.getFormationsForSatisfactions(localStorage.getItem('accessToken'), auth?.user?.id);
        if (mounted.current) {
          setFormations(data);
          setFilteredFormations(data);
          setLoading(false);
        }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }, [mounted]);


  // useEffect(() => {
  //   getFormations();
  // }, [getFormations]);

  
  useEffect(() => {
    getFormationsSatisfactions();
  }, [getFormationsSatisfactions]);

  useEffect(() => {
    if (filters.length > 0) {
      const tmpFormations = formations;
      // const tmpFilteredFormations = [];
      // for (let i = 0; i < tmpFormations.length; i++) {
      //   if (filters.includes(tmpFormations[i]?.year.toString())) {
      //     tmpFilteredFormations.push(tmpFormations[i]);
      //   }
      // }
      const tmpFilteredFormations = tmpFormations.filter((f) => filters.includes(f?.year?.toString()));
      setFilteredFormations(tmpFilteredFormations);
    } else {
      setFilteredFormations(formations);
    }
  }, [filters]);

  return (
    <>
      <Helmet>
        <title>Satisfactions | Apteed</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>

          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={4}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Liste des satisfactions par formation
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/satisfactions"
                  variant="subtitle2"
                >
                  Satisfactions
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Liste
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Box sx={{ my: 3 }}>
            <FormationsFilterSatisfactionPerformance setFilters={setFilters} />
          </Box>
            <FormationsResultsSatisfaction
              formations={filteredFormations}
              setFormations={setFormations}
              filters={filters}
              loading={loading}
            />
        </Container>
      </Box>
    </>
  );
};

export default FormationsSatisfaction;
