/* eslint-disable object-shorthand */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDateTimePicker, LocalizationProvider } from '@material-ui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ticketApi } from '../../../../api/ticketApi';
import toast from 'react-hot-toast';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import "moment/locale/fr";
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { createCustomTheme } from '../../../../theme';
import useSettings from '../../../../hooks/useSettings';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { THEMES } from '../../../../constants';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  TextField,
  Switch,
  Avatar,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  Typography,
  Autocomplete,
  Box,
  Tabs,Tooltip,
  Tab, Card, IconButton, Accordion, AccordionSummary, AccordionDetails,  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import CustomerQualityComment from './QualityTicketComments';
import TicketFileDropzone from '../TicketFileDropZone';
import { display } from '@material-ui/system';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, frFR } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import SaveIcon from '@mui/icons-material/Save';
import TicketCorrectiveActionsFileDropZone from '../TicketCorrectiveActionsFileDropZone';

const tabs = [
  { label: 'Commentaires', value: 'comments' },
  { label: 'Historique', value: 'Log' },

];



const ShowTicketReferent = (props) => {
  const {
    show,type,
    handleClose,
    infoTicket,
    commentList,
    user,
    ticketComment,
    handleTicketCommentChange,
    handleKeyUp,
    setCommentList,
    setInfoTicket,
    setShow,
    setListServices,
    listServices,
    listCategories,
    listMembers,
    getCategories,
    getServiceMembers,
    setTicketService,
    setTicketCategory,
    setTicketReferentId,
    setTicketReferentName,
    setTicketSeverity,
    setTicketSolution,
    handleShow,
    ticketService,
    ticketSeverity,
    ticketDescription,
    ticketLog,
    ticketFiles,
    setTicketFiles,getTicketDocuments,
    setOldTicketCorrectiveActions,
    ///for modifying
    setNewTicketService,
    setNewTicketSeverity,
    setNewTicketReferentId,
    setNewTicketTracking,
    setNewTicketSolution,
    setNewTicketState,
    setNewTicketMajorIncident,
    setNewTicketCategory,
    setNewTicketDRA,
    newTicketService,
    newTicketSeverity,
    newTicketReferentId,
    newTicketTracking,
    newTicketSolution,
    newTicketState,
    newTicketMajorIncident,
    newTicketCategory,
    newTicketDRA,
    setNewTicketAcceptTracking,
    newTicketAcceptTracking,
    newTicketDescription,
    setNewTicketDescription,
    newTicketCause,
    newTicketDStart,
    newTicketDREff,
    setNewTicketCause,
    setNewTicketDStart,
    setNewTicketDREff,
    setOldTicketDStart,
    setOldTicketDREff,
    oldTicketDStart,
    oldTicketDREff,
    oldTicketCorrectiveActions,newTicketCorrectiveActions,setNewTicketCorrectiveActions,
    
    //to compare
    setOldTicketService,
    setOldTicketSeverity,
    setOldTicketReferentId,
    setOldTicketTracking,
    setOldTicketSolution,
    setOldTicketState,
    setOldTicketMajorIncident,
    setOldTicketCategory,
    setOldTicketDRA,
    setOldTicketAcceptTracking,
    oldTicketDescription,
    setOldTicketDescription,
    oldTicketService,
    oldTicketSeverity,
    oldTicketReferentId,
    oldTicketTracking,
    oldTicketSolution,
    oldTicketState,
    oldTicketMajorIncident,
    oldTicketCategory,
    oldTicketDRA,
    oldTicketAcceptTracking,
    
    ///
    updateTicketInfo,
    update,
    setUpdate,
    validateTicket,
    openTicket,
    deleteTicket,
    majorIncident,
    getTickets,
    deleteTicketDocument,
    getTicketCorrectiveActions
  } = props;
  const tabs2 = [
    { label: 'Vue rapide', value: 'general' },
    { label: 'Actions correctives', value: 'actions' },
    { label: 'Commentaires', value: 'comments' },
    { label: 'Historique', value: 'log' },
  
  ];
const [selectedTab, setSelectedTab] = useState('general');
const handleTabsChange = (event, value) => {
    setSelectedTab(value);
  };
const severityList = [
    {
      code: '0',
      value: 'Mineur'
    },
    {
      code: '1',
      value: 'Modéré'
    },
    {
      code: '2',
      value: 'Grave'
    },
    {
      code: '3',
      value: 'Critique '
    },
  ];
  const { settings } = useSettings();
  const theme = createCustomTheme({
    ...settings
  });


  const [currentTab, setCurrentTab] = useState('comments');
  const handleTabsChange2 = (event, value) => {
    setSelectedTab(value);
  };
  const [selectedActionId, setSelectedActionId] = useState(null);
  // *** les actions correctives ***
  const [new_CA_Desc, setNew_CA_Desc] = useState(null);
  const [new_CA_Date, setNew_CA_Date] = useState(null);
  const [new_CA_Temp_files, setNew_CA_Temp_files] = useState([]);
  const [arrayTmp_CA_Temp_files, setArrayTmp_CA_Temp_files] = useState([]);

  const [arrayTmp_CA_file_ref, setArrayTmp_CA_file_ref] = useState(null);
  const [new_CA_Temp_Desc, setNew_CA_Temp_Desc] = useState(null);
  const [new_CA_Temp_Date, setNew_CA_Temp_Date] = useState(null);
  const [ticketActionArray, setticketActionArray]  = useState([
      // {id:1, ref: uuidv4(),  corrective_action : 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: [] },
      // {id:2, ref:uuidv4(),  corrective_action : 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
      // {id:3, ref:uuidv4(),  corrective_action : 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
      // {id:4, ref:uuidv4(),  corrective_action : 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
      // {id:5, ref:uuidv4(),  corrective_action : 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
    ]);
  // const [ticketAction, setTicketAction] = useState( { id:null, corrective_action : '', action_ref :'', correction_date : '', user_id : user.id, user_name : user.name, files: [],},)
  const [actionsDetails, setActionsDetails] = useState(null);
  const [newActionDesc, setNewActionDesc] = useState(null);
  const [newActionDate, setNewActionDate] = useState(null);

  const fetchCorrectiveActions = async () => {
    const correctiveActions = await ticketApi.fetchCorrectiveActionsByTicketId(localStorage.getItem('accessToken'), infoTicket.ticketId)
    console.log(correctiveActions);
  
        setOldTicketCorrectiveActions(correctiveActions)
  }
  const updateCorrectiveAction = async (id,action, date) => {
    try {
      const update_action =  await ticketApi.updateCorrectiveAction(localStorage.getItem('accessToken'),
     id, action, date);
     fetchCorrectiveActions();
    } catch (error) {
      toast.error('erreur lors de la modification')
    }
    
   }
  const deleteCorrectiveAction = async (id) => {
    if (confirm(" Vous risquez de perdre les informations de cette action corrective, voulez-vous vraiment la supprimer?")) {
       setActionsDetails(null);
  setSelectedActionId(null)
  await ticketApi.deleteorrectiveActionByTicketId(localStorage.getItem('accessToken'),id);
     } fetchCorrectiveActions();
 }
  const columns = [
    // {
    //   field: 'id',
    //   headerName: 'id',
    //   flex: 0.3
    // },
    {
      field: 'corrective_action',
      headerName: 'Description',
      flex:0.8
    },
    {
      field: 'correction_date',
      headerName: "Date",
      flex: 0.4
    },{
      field: 'user_name',
      headerName: "Intervenant",
      flex: 0.4
    },
    { width: 40,
      renderCell: (cellValues) => (
        <IconButton
          onClick={() => {
            deleteCorrectiveAction(cellValues.row.id); 
            
           }}
          disabled={user.id_service !== 0 && user.access_level !== 0}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      ) },
  ];

  const [load, setLoad] = useState(null);
  const [aiNotSolvedButtonColor, setAiNotSolvedButtonColor] = useState('primary');
  const [open, setOpen] = useState(false);
  const [fileValues, setFileValues] = useState([]);
  const [arrayTmp, setArrayTmp] = useState([]);
  const [AIOptions, setAIOptions] = useState([]); 
  const [problemId, setProblemId] = useState(null);
  const [suggestionId, setSuggestionId] = useState(null);
  const [suggestion, setSuggestion] = useState(null);

 
const [catIndex, setCatIndex] = useState(infoTicket.category_index);
const [memberIndex, setMemberIndex] = useState(infoTicket.referent_id);

const [showPJ, setShowPJ] = useState(false);
const [PJFile, setPJFile] = useState(null);
const handleclosePJ = () => {
  setShowPJ(false);
  setPJFile(null);
};

const handleAddActionButton = () => {
    setArrayTmp_CA_file_ref(uuidv4());
    setActionsDetails('new');
  };
const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <Tooltip title="Ajouter une action corrective">
        <Button size="small" onClick={handleAddActionButton}><AddIcon color="primary" />  Ajouter une action</Button>
      </Tooltip>

    </GridToolbarContainer>
 );
 const handleDropNewActionFiles = async (acceptedFile) => {
    if (arrayTmp_CA_Temp_files.length + acceptedFile.length <= 5 && acceptedFile.length > 0) { acceptedFile.forEach((file) => {
     
     arrayTmp_CA_Temp_files.push({ name: file.name, type: '', file, ref:arrayTmp_CA_file_ref });
     });
     setNew_CA_Temp_files(arrayTmp_CA_Temp_files);
     
     } 
   };
   const handleFileDeleteNewActionFiles = (indexToDelete) => {
    setArrayTmp_CA_Temp_files((prevArrayTmp_CA_Temp_files) => {
      const updatedArrayTmp_CA_Temp_files = [...prevArrayTmp_CA_Temp_files];
      updatedArrayTmp_CA_Temp_files.splice(indexToDelete, 1);
      return updatedArrayTmp_CA_Temp_files;
    });
    setNew_CA_Temp_files((prevFileValues_CA_Temp_files) => {
      const updatedFileValues_CA_Temp_files = [...prevFileValues_CA_Temp_files];
      updatedFileValues_CA_Temp_files.splice(indexToDelete, 1);
      return updatedFileValues_CA_Temp_files;
    });
    };
function deleteDocAlert(fileId,fileName) {

 if (type === 'ticket') { if (window.confirm(`Voulez-vous vraiment supprimer le document : ${fileName}  de ce ticket?`)) {
  deleteTicketDocument(fileId, infoTicket.ticketId);
    console.log("Supprimer");
    const index = ticketFiles.findIndex((pj) => pj.file_id === fileId);
    // ticketFiles.splice(index, 1);
  } } else {
    if (window.confirm(`Voulez-vous vraiment supprimer le document : ${fileName}  de cette réclamation?`)) {
    deleteTicketDocument(fileId, infoTicket.ticketId);
    console.log("Supprimer");
    const index = ticketFiles.findIndex((pj) => pj.file_id === fileId);
    // ticketFiles.splice(index, 1);
  }
  }
}
  const sendFeedback = async (prob, solution) => {
    try {
     const urlTicket = `${process.env.REACT_APP_API_IA}/api/updatepert`;
         const values = {};
         values.suggestion_id = prob;
         values.solution_id = solution;
         const resp = await fetch(urlTicket, {
           method: 'POST',
           body: JSON.stringify(values),
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
           }
         });
         const json = await resp.json();
   } catch {
     setLoad(null);
   }
 };

  const sendSolutionToAI = async (solution) => {
    try {
     const urlTicket = `${process.env.REACT_APP_API_IA}/api/addsolution`;
         const values = {};
         values.suggestion_id = problemId;
         values.suggestion_solution = solution;
         const resp = await fetch(urlTicket, {
           method: 'POST',
           body: JSON.stringify(values),
           headers: {
             'Content-Type': 'application/json',
             Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
           }
         });
         const json = await resp.json();
   } catch {
     setLoad(null);
   }
 };
 const [spaceCounter, setSpaceCounter] = useState(0);
 const getSuggestions = async () => {
  if (ticketService === 2 && newTicketDescription) {
    setAIOptions(null);
setLoad(true);
    const timer = setTimeout(async () => {
try {
      const urlTicket = `${process.env.REACT_APP_API_IA}/api/suggestion`;
          const values = {};
          values.text = newTicketDescription;

          const resp = await fetch(urlTicket, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
            }
          });
          const json = await resp.json();
          setLoad(false);
          if (json.id) {
            setProblemId(json.id);
            setAIOptions(json.solutions);
          }
          else setLoad(null);
    } catch {
      setLoad(null);
    }
    }, 100);
  }
 };
 
  const handleCloseImportDialog = () => {
    setOpen(false);
    setFileValues([]);
    setArrayTmp([]);
  };
  
  const SaveFiles = () => {
    setOpen(false);
   
  };
  const handleSubmit = async (tiketId) => {
      await ticketApi.sendDoc(tiketId, user.id, fileValues, infoTicket.ref,  localStorage.getItem('accessToken'));
  };
 const handleChange = (index) => (event) => {
  event.preventDefault();
  const newArr = [...fileValues];
  newArr[index] = { ...newArr[index], [event.target.name]: event.target.value };
  setFileValues(newArr);
};

const handleFileDelete = (indexToDelete) => {
  setArrayTmp((prevArrayTmp) => {
    const updatedArrayTmp = [...prevArrayTmp];
    updatedArrayTmp.splice(indexToDelete, 1);
    return updatedArrayTmp;
  });
  setFileValues((prevFileValues) => {
    const updatedFileValues = [...prevFileValues];
    updatedFileValues.splice(indexToDelete, 1);
    return updatedFileValues;
  });
};

const handleDrop = async (acceptedFile) => {
// console.log(acceptedFile);
// console.log(arrayTmp.length + acceptedFile.length);
 if (arrayTmp.length + acceptedFile.length <= 5 && acceptedFile.length > 0) { acceptedFile.forEach((file) => {
  
    arrayTmp.push({ name: file.name, type: '', file });
  });
    setFileValues(arrayTmp);
   setOpen(true);
  } else {setOpen(false); toast.error("impossible d'importer plus que 5 fichiers !")}
};

const add_new_corrective_action = async () => {
    
  console.log(ticketActionArray);
await ticketApi.addCorrectiveActionByTicketId(localStorage.getItem('accessToken'),
infoTicket.ticketId, 
user.id, 
    new_CA_Desc,
     new_CA_Date,
    user.id,
    arrayTmp_CA_file_ref,
     );
     const correctiveActions = await ticketApi.fetchCorrectiveActionsByTicketId(localStorage.getItem('accessToken'), infoTicket.ticketId)
     setOldTicketCorrectiveActions(correctiveActions);
if ((new_CA_Desc && new_CA_Desc !== '') || (new_CA_Date) ) {
    setNew_CA_Date(null);
    setArrayTmp_CA_Temp_files([]);
    setNew_CA_Desc('');
    setNew_CA_Temp_files([]);
    setArrayTmp_CA_file_ref(uuidv4());
} 
else {
toast.error("Veuillez entrer une description et une date de l'action");
}
    
   };
   const deleteActionFile = (index) => {
    // console.log(ticketActionArray.find((e) => e.id === selectedActionId).files);
    setticketActionArray((prevArrayTmp_CA_files) => {
      const updatedArrayTmp_CA_files = [...prevArrayTmp_CA_files];
      updatedArrayTmp_CA_files.find((e) => e.id === selectedActionId).files.splice(index, 1);
      return updatedArrayTmp_CA_files;
    });    
  };    

  const serviceprops = {
    options: listServices,
    getOptionLabel: (option) => option.service_name
  };
  const [catprops, setCatProps] = useState({
    options: listCategories,
    getOptionLabel: (option) => option.category_title
  });
  const [memberprops, setMembreProps] = useState({
    options: listMembers,
    getOptionLabel: (option) => option.name
  });
  const severityprops = {
    options: severityList,
    getOptionLabel: (option) => option.value
  };
  const showActionDocs = (id) => {
    if (actionsDetails === id) {
     setActionsDetails(null); 
    }
    else  {
      setActionsDetails(id);
    }
    // console.log(actionsDetails);
  };
  const handleDropSelectedActionFiles = async (acceptedFile) => {
    const acceptedFileArray = [];
    acceptedFile.forEach((file) => {
      acceptedFileArray.push({ 
        name: file.name,
        file: file,
        ref: oldTicketCorrectiveActions.find((e) => e.id === selectedActionId).ref
      })
    })
       try {
      const sendDoc =  await ticketApi.sendDoc_corrective_action(infoTicket.ticketId, user.id, acceptedFileArray,  localStorage.getItem('accessToken'));
      fetchCorrectiveActions();
    
    } 
      catch (err) {console.log(err);}} 
  const deleteCorrectiveActionSingleDoc = async (docId,ticketId) => {

    try {
    await ticketApi.deleteCorrectiveActionSingleDoc(localStorage.getItem('accessToken'), docId, ticketId)
    fetchCorrectiveActions();
    } catch (err) {
      console.log(err);
    }
    };
   
  const [category, setCategory] = useState(catprops.options[catprops.options.findIndex(cat => cat.category_id === catIndex) + 1]);
  const [member, setMember] = useState(memberprops.options[memberprops.options.findIndex(m => m.member_id === newTicketReferentId)]);
  const [oldcategory, setOldCategory] = useState(catprops.options[catprops.options.findIndex(cat => cat.category_id === catIndex) + 1]);
  const [oldmember, setoldMember] = useState(memberprops.options[memberprops.options.findIndex(m => m.member_id === oldTicketReferentId)]);
return (
<ThemeProvider theme={theme}>
        <Dialog onClose={handleclosePJ} open={showPJ} maxWidth="xl">
          <DialogTitle style={{display: "flex"}}>
            <row style={{display: 'flex'}}>
            <p style={{paddingRight: '60px', margin: '0px'}}>{PJFile?.file_name}</p>
       <div style={{marginTop: '5px', position: 'absolute', right: '30px', marginLeft: '10%'}}><a download href={PJFile?.link}><DownloadIcon color={theme.palette.primary.main} htmlColor={theme.palette.primary.main} /></a></div>
          </row></DialogTitle>
          <DialogContent>
            {PJFile?.extension === 'jpg' || PJFile?.extension === 'jfif' || PJFile?.extension === 'jpeg' || PJFile?.extension === 'png' || PJFile?.extension === 'gif' ? (<img src={PJFile?.link} height="700px" alt="test" style={{borderRadius: '6px'}} />) : null}
            {PJFile?.extension === 'mp4' || PJFile?.extension === 'webm' || PJFile?.extension === 'mkv' || PJFile?.extension === 'flv' || PJFile?.extension === 'vob' || PJFile?.extension === 'ogg' || PJFile?.extension === 'ogv' || PJFile?.extension === 'avi' || PJFile?.extension === 'gifv' || PJFile?.extension === 'mpeg' ? (<video controls width="600" height="600"><source src={PJFile?.link} type="video/mp4" /><source src={PJFile?.link} type="video/webm" /><source src={PJFile?.link} type="video/mkv" /><source src={PJFile?.link} type="video/flv" /><source src={PJFile?.link} type="video/vob" /><source src={PJFile?.link} type="video/ogg" /><source src={PJFile?.link} type="video/ogv" /><source src={PJFile?.link} type="video/gif" /><source src={PJFile?.link} type="video/gifv" /><source src={PJFile?.link} type="video/avi" /><source src={PJFile?.link} type="video/mpeg" />Error</video>) : null}
            {PJFile?.extension === 'pdf' ? (<object style={{width: '1000px'}} data={PJFile?.link} type="application/pdf" width="100%" height="500px">
              <p>Unable to display PDF file. <a href={PJFile?.link}>Download</a> instead.</p>
            </object>) : null}
          </DialogContent>
          <DialogActions>
         
          <Button color="primary"
onClick={handleclosePJ}>
            Fermer
            </Button>
        </DialogActions>
          </Dialog>
      <Dialog
        open={open}
        onClose={handleCloseImportDialog}
      >
        <DialogTitle>Téléchargement de documents</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {fileValues.length <= 5 ? "Confirmer le nom et le type de chaque fichier à importer." : "Impossible d'importer plus que 5 fichiers"}
            
          </DialogContentText>
          {fileValues.map((value, index) => (
    <div style={{alignContent: 'center', alignItems: 'center', display: 'flex'}}>
      {index + 1}
      {'. '}
      <TextField
        variant="outlined"
        sx={{ m: '20px' }}
        name="name"
        label="Nom du fichier"
        onChange={handleChange(index)}
        value={value.name}
      />
      <IconButton onClick={() => handleFileDelete(index)}>
        <CloseIcon />
      </IconButton>
      <br />
    </div>
  ))
}
    
       </DialogContent>
        <DialogActions>
          {fileValues.length <= 5 ? 
        (<> <Button
            onClick={SaveFiles}
            color="primary"
          >
            Enregistrer
          </Button>
          <Button
            onClick={handleCloseImportDialog}
            color="primary"
          >
            Annuler
          </Button></>
        )  : (
          <Button color="primary"
onClick={handleCloseImportDialog}>
            Fermer
          </Button>
        )
        }
        </DialogActions>
      </Dialog>

            <Dialog
                onClose={handleShow}
                open={show}
                fullWidth="lg"
                maxWidth="lg"
            >
              <DialogTitle style={{overflowX: 'auto'}}>

              <Grid
                    container
                    spacing={1}
                  >
                    <Grid
                      item
                      xs={11}
spacing={1}
                    >
                <row style={{ display: 'flex', padding: '0px' }}>           
                <div style={{ float: 'right', marginRight: '3%' }}>
                       {infoTicket.state === 'Ouvert' ? (
                          <p style={{fontSize: '15px', margin: '0px', color: 'white', backgroundColor: 'grey', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : infoTicket.state === 'Suspendu' ? (
                          <p style={{fontSize: '15px', margin: '0px', backgroundColor: '#f44336', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : infoTicket.state === 'Résolu' ? (
                          <p style={{fontSize: '15px', margin: '0px', backgroundColor: 'green', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : infoTicket.state === 'En cours' ? (
                          <p style={{fontSize: '15px', margin: '0px', backgroundColor: 'orange', color: 'white', borderRadius: '10px', paddingRight: '10px', paddingLeft: '10px', whiteSpace: 'nowrap' }}>{infoTicket.state}</p>
                       ) : null}
                      </div>       
                        <p style={{fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Réf. :&#160;</p>
                        <p style={{fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.ref}</p>
                        {infoTicket.creator_id !== null ? (
                          <>
                          {type === 'ticket' ? <p style={{fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Créateur du ticket :&#160;</p>
                          : <p style={{fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Créateur de la réclamation :&#160;</p>  }
                          <p style={{fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.creator_name}</p>
                          </>
                          ) : null}
                        
                        

                        <p style={{ fontSize: '15px', margin: '0px', marginLeft: '3%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Date de création :&#160;</p> 
                        <p style={{ fontSize: '15px', margin: '0%', marginLeft: '5px', paddingTop: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>
                          {format(new Date(infoTicket.date_creation), "dd/MM/yyyy 'à' HH:mm")}
                         </p>
                         {infoTicket.learner !== null ? (
                          <>
                          <p style={{ fontSize: '15px', margin: '0px', marginLeft: '1.5%', whiteSpace: 'nowrap', color: theme.palette.primary.main, fontWeight: 'bold' }}>Apprenant :&#160;</p> 
                          <p style={{ fontSize: '15px', margin: '0px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, fontWeight: 'bold' }}>{infoTicket.learner}</p>
                        </>) : null }
{/* supprimer le ticket */}
                        {(infoTicket.state === 'Suspendu' && user.id === infoTicket.creator_id) || (user.id_service === 0 && user.access_level === 0) || (user.service_manager === 1) ? (
                          <div style={{ margin: '0px', padding: '0px', position: 'absolute', right: '20px'}}
title="Supprimer le ticket">
                        <IconButton aria-label="delete"
size="large"
style={{padding: '0px', paddingBottom: '5px'}}
onClick={() => {
                          handleShow();
                          Swal.fire({
                            title: type === 'ticket' ? "<h5>Vous risquez de perdre les informations de ce ticket, Voulez-vous vraiment supprimer ce ticket de façon permanente?" :
                             "<h5>Vous risquez de perdre les informations de cette réclamation, Voulez-vous vraiment supprimer cette réclamation de façon permanente?",
                            icon: "warning",
                            iconHtml: "!",
                            confirmButtonText: "Supprimer le ticket",
                            cancelButtonText: "Annuler",
                            confirmButtonColor: theme.palette.primary.main,
                            // denyButtonText: 'Annuler le ticket',
                            showCancelButton: true,
                            showCloseButton: true
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                            await  deleteTicket(infoTicket.ticketId);
                              getTickets();
                              setUpdate(!update);
                              handleClose();
                            } else {
                              handleClose();
                            }
                          });
                       
                          }}>
                            <DeleteIcon color="error" />
                        </IconButton>
                                 </div> 
                        ) : null }
                          
                      </row>
                        </Grid>
                        
                        </Grid>
                     
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  
                    {/* service, catégorie, référent et gravité */}
                      <row style={{ display: 'flex' }}>
                      {type === 'ticket' &&  <row style={{ display: 'flex', margin: '0%' }}> 
                  <h4 style={{paddingTop: '0px', whiteSpace: 'nowrap', margin: '0%', verticalAlign: 'middle', color: theme.palette.primary.main}}>Service :&#160; </h4>
                  <h4 style={{paddingTop: '0px', marginLeft: '5%', margin: '0%', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{serviceprops.options[infoTicket.service - 1].service_name}</h4>
                </row>}
                {/* catégorie du ticket */}
                <row style={{ display: 'flex', margin: '0%', marginLeft: type === 'ticket' ? '3%' : '0%' }}> 
                  <h4 style={{paddingTop: '0px', whiteSpace: 'nowrap', margin: '0%', verticalAlign: 'middle', color: theme.palette.primary.main}}>Catégorie :&#160; </h4>
                  <h4 style={{paddingTop: '0px', marginLeft: '3%', whiteSpace: 'nowrap', margin: '0%', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{catprops.options[infoTicket.category_index - 1].category_title}</h4>
                </row>
                {/* gravité */}
                <row style={{ display: 'flex', margin: '0%', marginLeft: '3%' }}>
                  <h4 style={{paddingTop: '0px', whiteSpace: 'nowrap', margin: '0%', marginTop: '0px', verticalAlign: 'middle', color: theme.palette.primary.main}}>Gravité :&#160; </h4>
                  <h4 style={{paddingTop: '0px', marginLeft: '3%', whiteSpace: 'nowrap', margin: '0%', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{severityprops.options[parseInt(infoTicket.severity, 10)].value}</h4>
                </row>
                 {/* référent */}
              <row style={{ display: 'flex', margin: '0%',  marginLeft: '3%',padding: '0px'}}>
                <h4 style={{whiteSpace: 'nowrap',margin: '0%',  marginLeft: '0%', verticalAlign: 'middle', color: theme.palette.primary.main}}>Référent : </h4>
                <h4 style={{paddingTop: '0px',margin: '0%',  marginLeft: '0.5%', whiteSpace: 'nowrap', color: theme.palette.dialogText.main, verticalAlign: 'middle'}}>{infoTicket.member_index !== null ? (memberprops.options[infoTicket.member_index - 1].name) : "Aucun"}</h4>
              </row>
                      </row>
                      {/* les date de prise en charge, résomution approx et effective */}
                      <row style={{display: 'flex', marginTop: '20px', marginBottom: '20px'}}>
                         <div style={{ width: '20%' }}>
                              <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}>
                            <MobileDateTimePicker 
                             ampm={false}
                             cancelText="Annuler"
                             todayText="Aujourd'hui"
                             showTodayButton
                            locale={fr}
                            sx={{ padding: 0, margin: 0 }}
                            //  fullWidth

                            inputFormat="DD/MM/YYYY à HH:mm"
                            onChange={(e) => {
                              infoTicket.date_take_charge = format(new Date(e), "yyyy-MM-dd HH:mm");
                              // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                            }}
                             label="Date de prise en charge : "
                            name="dateprisecharge"
                            renderInput={(inputProps) => (
                            <TextField
                            style={{ padding: '0px', margin: '0px' }}
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                            )}
                            value={infoTicket.date_take_charge}
                          /></LocalizationProvider></div>
<div style={{ width: '20%', marginLeft: '20px' }}>
                                <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                 inputFormat="DD/MM/YYYY à HH:mm"
                                cancelText="Annuler"
                                showTodayButton
                                okText="OK"
                                
                                onChange={(e) => {
                                  setNewTicketDRA(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                }}
                                 label="Date de résolution approximative "
                                name="dateprisecharge"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={newTicketDRA}
                              /></LocalizationProvider>
                            </div>

 <div style={{ width: '20%', marginLeft: '20px', }}> 
                            <MobileDateTimePicker 
                             ampm={false}
                             cancelText="Annuler"
                             todayText="Aujourd'hui"
                             showTodayButton
                            sx={{ padding: 0, margin: 0 }}
                            //  fullWidth
                            inputFormat="dd/MM/yyyy à HH:mm"
                            onChange={(e) => {
                              setNewTicketDREff(format(new Date(e), "yyyy-MM-dd HH:mm"));
                              if (oldTicketDREff == null && Date(e) !== null) {
                                setNewTicketState('Résolu');
                              }
                              if (newTicketDStart == null) {
                                  setNewTicketDStart(format(new Date(e), "yyyy-MM-dd HH:mm"));
                              }
                              if (newTicketDRA == null ) {
setNewTicketDRA(format(new Date(e), "yyyy-MM-dd HH:mm"));
                              }
                              // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                            }}
                             
                             label="Date de résolution effective "
                            name="dateprisecharge"
                            renderInput={(inputProps) => (
                            <TextField
                            style={{ padding: '0px', margin: '0px' }}
                              fullWidth
                              variant="outlined"
                              {...inputProps}
                            />
                            )}
                            value={infoTicket.date_res_eff}
                          />
                        </div></row>

                        <div  style={{width:'100%',
  // border:"1px solid"
  }}>
  {/**** tabs ****/}
  <Tabs 
  // orientation="vertical"
               sx={{alignItems: 'start',minWidth:"15%"}}
                        indicatorColor="primary"
                        onChange={handleTabsChange2}
                        scrollButtons="auto"
                        textColor="primary"
                        value={selectedTab}
                        variant="scrollable">
                       { tabs2.map((tab) => (
                <Tab justifyContent="stretch" textAlign="left" _selected={{ color: 'red', textAlign: 'center', justifyContent: "center"}}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              )) }
                        </Tabs>
                        <div style={{width:"100% ", paddingLeft: '20px',minWidth: '100%',maxWidth: '100%'}}>
                        {(() => {
                  switch (selectedTab) {
                    // *** vue générale ***
                    case 'general':
                      return <div style={{ width:'100%',maxHeight:'44vh', minHeight:'44vh', marginTop: '10px'}}>
                        <Grid container spacing={1}> 
                          <Grid item xs={8} style={{overflowY:'auto',maxHeight:'44vh', minHeight:'44vh'}}>
                            {/* CONSTAT */}
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                <row style={{ width: '100%', display: 'flex', marginBottom: '0px', paddingBottom: '0px' }}>
                        <div style={{ width: '100px'}}> <h4 style={{ whiteSpace: 'nowrap', marginBottom: '0px', color: theme.palette.primary.main }}> Constat * :</h4>  </div> 
                          { (user.id === infoTicket.creator_id && infoTicket.state === 'Suspendu') || (user.id_service === 0 && user.access_level === 0) || (user.service_manager === 1) ? (
                            <TextField multiline
                                rows={2}
                                placeholder="Constat"
                                style={{ width: '72%', marginTop: '22px', marginLeft: '1%' }}
                                value={newTicketDescription}
                                onChange={(e) => {setNewTicketDescription(e.target.value); 
                                  const numberOfSpaces = e.target.value.split(' ').length - 1;
                                  setSpaceCounter(numberOfSpaces); 
                                if (e.nativeEvent.data === ' ') {
                                // console.log(e.nativeEvent.data);
                                if (spaceCounter >= 2) {getSuggestions();}} 
                                if (e.target.value === '' || e.target.value === null) {setSpaceCounter(0);}}} /> /// add on change
                          ) : (
                            <Typography style={{marginTop: '22px', marginLeft: '1%', marginBottom: '0px', maxHeight: '200px', overflowX: 'auto', color: theme.palette.dialogText.main }}>{infoTicket.description}</Typography>
                          )
                          }
                        </row>
                                </div>
                            {/* CAUSES*/}
                                <div style={{ marginTop: '10px', display: 'flex', marginBottom: '10px' }}>
                                <row style={{ width: '100%', display: 'flex', marginBottom: '0px', paddingBottom: '0px' }}>
                        <div style={{width:'100px'}}>  <h4 style={{ whiteSpace: 'nowrap', marginBottom: '0px', color: theme.palette.primary.main }}> Causes :</h4>  </div>
                          { (user.id === infoTicket.creator_id && infoTicket.state === 'Suspendu') || (user.id_service === 0 && user.access_level === 0) || (user.service_manager === 1) ? (
                            <TextField multiline
rows={2}
placeholder="Couses"
style={{ width: '72%', marginTop: '22px', marginLeft: '1%' }}
value={newTicketCause}
onChange={(e) => {setNewTicketCause(e.target.value); }} /> /// add on change
                          ) : (
                            <Typography style={{marginTop: '22px', marginLeft: '1%', marginBottom: '0px', maxHeight: '200px', overflowX: 'auto', color: theme.palette.dialogText.main }}>{infoTicket.description}</Typography>
                          )
                          }
                        </row>
                                </div>
                            {/* l'ajout de pièces jointes des tickets*/} 
                             { ticketFiles.length !== 0 && <h4 style={{paddingTop: '6px', color: theme.palette.primary.main}}> Pièces jointes :</h4>}
                              
                                <row style={{ display: 'flex', flexWrap: 'wrap', marginTop: '0px', paddingTop: '15px' }}>
                     
                         <div style={{maxHeight: '90px', overflowY: 'auto', width: '80%', flexWrap: 'wrap', display: 'flex'}}>
                          {ticketFiles?.map((file) => (
                            <>
                            {file.extension === 'jpg' || file.extension === 'jfif' || file.extension === 'jpeg' || file.extension === 'png' 
                            || file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' 
                            || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv' || file.extension ===  'gif' 
                            || file.extension === 'avi' || file.extension === 'gifv' || file.extension === 'mpeg' || file.extension === 'pdf' ? 
                            (
                               <Card 
                              sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }}

                              style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}

                              title={`Ajouté par ${file.user_fullname} le ${file.createdAt} `}>

                              <Box style={{display: 'flex'}} onClick={() => { setShowPJ(true); setPJFile(file);}}>

                              {file.extension === 'pdf' ? (
                              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png" 
                              
                              height="40px" width="40px" alt="test"
                              
                              style={{borderRadius: '6px', marginRight: '10px', margin: 'auto'}} />)

                              : file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' 
                              || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv'  || file.extension === 'avi' 
                              || file.extension === 'gifv' || file.extension === 'mpeg' ? (
                                <PlayArrowIcon style={{ marginRight: '10px', margin: 'auto'}} />
                              ) : (file.extension ===  'gif' || file.extension === 'jpg' ||  file.extension === 'jfif' || file.extension === 'jpeg' 
                              || file.extension === 'png' ? (<img src={file.link} height="40px" width="40px" alt="test" style={{borderRadius: '6px', marginRight: '10px', margin: 'auto'}} />) : null)}
                              &nbsp;
                              <p style={{textOverflow: 'ellipsis'}}>{file.file_name}</p></Box>
                              <IconButton onClick={() => {deleteDocAlert(file.file_id,file.file_name) }}>
                              <DeleteIcon style={{color: theme.palette.primary.main}} />
                              </IconButton>
                               </Card>
                            ) : (
                           
                            <Card sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }} 
                            style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}
                            title={`Ajouté par ${file.user_fullname} le ${file.createdAt} `}>
                           <a href={file.link} download rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>     <Box style={{display: 'flex', margin: 'auto'}} onClick={() => { setShowPJ(true); setPJFile(file);}}>
                            
                              <InsertDriveFileIcon fontSize="large" style={{margin: 'auto'}} />
                              &nbsp;
                              <p style={{textOverflow: 'ellipsis'}}>{file.file_name}</p>
                              </Box></a>
                              <IconButton onClick={() => {deleteDocAlert(file.file_id,file.file_name) }}>
                              <DeleteIcon style={{color: theme.palette.primary.main}} />
                              </IconButton>
                               </Card>) }
                         </> 
                         
                         ))}</div> 
                        </row>
                       
                       
                            {/* le suivi de status de tickets */}
                            <div style={{marginTop: '10px', display: 'flex', marginBottom: '0px'}}>
                            {infoTicket.learnerId &&     <row style={{ display: 'flex', margin: '0px', marginTop: '0px' }}>
                      {infoTicket.type === 'ticket' ?  <p style={{ margin: '0px', marginLeft: '0px', paddingTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold' }}>Suivi du ticket :&#160;</p>
                      : <p style={{ margin: '0px', marginLeft: '0px', paddingTop: '5px', color: theme.palette.primary.main, fontWeight: 'bold' }}>Suivi de la réclamation :&#160;</p>}
                      <Switch checked={newTicketAcceptTracking} onChange={() => { if (infoTicket.state === "Ouvert" || infoTicket.state === "Suspendu") {
                        if ((user.id === infoTicket.creator_id) || (user.id_service === 0 && user.access_level === 0) || (user.service_manager === 1)) {setNewTicketAcceptTracking(!newTicketAcceptTracking);
                         }}}} />
                      </row>
                          }</div>
                          {/*  Le dernier commentaire */}
                         {commentList.length !== 0 && <h4 style={{ color: theme.palette.primary.main}}> Le dernier commentaire sur ce ticket :</h4>}
                          { commentList.length !== 0 && <div style={{ width: '100%', overflowY: 'auto', marginTop: '10px' }}>
                              <div style={{marginBottom: '10px'}}> <CustomerQualityComment comment={commentList[commentList.length - 1]} /></div>
                              </div>}
                          {/* La dernière action corrective */}
                          {ticketActionArray.length !== 0 && <h4 style={{ color: theme.palette.primary.main}}> La dernière action corrective sur ce ticket  :</h4>}
                          {
                            ticketActionArray.length !== 0 &&

                            <div style={{ display: 'flex', padding: '0px', margin: '0px', justifyContent:'space-around' }}>
                            <div style={{ marginRight: '2%'}}>
                            <h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', color: theme.palette.dialogText.main,  whiteSpace: 'nowrap'}}> {format(new Date(ticketActionArray[ticketActionArray.length - 1].correction_date), "dd/MM/yyyy 'à' HH:mm:ss")}</h5>
                            </div>
                            <div style={{marginRight: '1%'}}> 
                            <a style={{textDecoration: 'none', decoration: 'none'}} href={`${process.env.REACT_APP_API}/dashboard/user/${ticketActionArray[ticketActionArray.length - 1].user_id}`} target="_blank>"><h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main }}>{ticketActionArray[ticketActionArray.length - 1].user_name}</h5></a>
                            </div>
                            <div style={{ width: '70%', marginRight: '1%', marginTop: '2px',display: 'flex', justifyContent: 'center'}}>
                              <p style={{ marginRight: '10px', padding: '0px', margin: '0px', color: theme.palette.dialogText.main }}>{ticketActionArray[ticketActionArray.length - 1].action}</p>
                            </div>
                            
                          </div>
                          }
                          {/*  fin du grid */}
                          </Grid>
                         
                              <Grid item xs={4}  style={{ borderLeft: '2px solid',height:'100%',overflowY:'auto',maxHeight:'45vh', minHeight: '45vh' }}>
                             {/* La dernière action corrective */}
                          {oldTicketCorrectiveActions.length !== 0 && 
                          <Typography style={{ textAlign: 'center', fontSize: '18px' ,marginBottom: '20px' }}> La dernière action corrective :</Typography>}
                          {
                            oldTicketCorrectiveActions.length !== 0 &&

                            <div style={{  padding: '0px', margin: '0px', justifyContent:'space-around' }}>
                            <div style={{ display: 'flex', marginRight: '2%'}}>
                              <Typography style={{whiteSpace: 'nowrap'}}> Date de l&apos;action :</Typography>
                            <h5 style={{ padding: '0px', margin: '0px', marginTop: '5px',marginLeft:'5px', color: theme.palette.dialogText.main,  whiteSpace: 'nowrap'}}> {format(new Date(oldTicketCorrectiveActions[oldTicketCorrectiveActions.length - 1].correction_date), "dd/MM/yyyy 'à' HH:mm:ss")}</h5>
                            </div>
                            <div style={{display: 'flex',marginRight: '1%', marginTop: '10px'}}> 
                            <Typography style={{whiteSpace: 'nowrap'}}> Date de l&apos;action :</Typography>
                            <a style={{textDecoration: 'none', decoration: 'none',marginLeft:'5px'}} href={`${process.env.REACT_APP_API}/dashboard/user/${oldTicketCorrectiveActions[oldTicketCorrectiveActions.length - 1].user_id}`} target="_blank>"><h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main }}>{oldTicketCorrectiveActions[oldTicketCorrectiveActions.length - 1].user_name}</h5></a>
                            </div>
                            <div style={{ marginTop: '10px',}}>
                            <row style={{ width: '95%', marginRight:oldTicketCorrectiveActions[oldTicketCorrectiveActions.length - 1].corrective_action.split(' ').length > 3 ? '0px' : '1%', 
                              display: oldTicketCorrectiveActions[oldTicketCorrectiveActions.length - 1].corrective_action.split(' ').length > 3 ? null : 'flex' }}>
                             
                             
                                  <Typography style={{whiteSpace: 'nowrap'}}> Description de l&apos;action :</Typography> 
                                  <p style={{
                                     marginRight: '10px', padding: '0px', margin: '0px',
                                color: theme.palette.dialogText.main,marginLeft:'5px', wordBreak: 'normal' }}> 
                                {oldTicketCorrectiveActions[oldTicketCorrectiveActions.length - 1].corrective_action} 
                                {/* {oldTicketCorrectiveActions[oldTicketCorrectiveActions.length - 1].corrective_action.split(' ').length } */}
                                </p>
                                
                            </row>
                            </div>
                          </div>
                          }
                            <div style={{width:"98%"}}>
                              {/* Suggestions IA */}
                              {infoTicket.state === "Suspendu" && (<div>
                                <Divider style={{marginTop: '20px', marginBottom: '20px'}}></Divider>
                       <row style={{display: 'flex', justifyContent: 'center'}}>
                      <Typography style={{ textAlign: 'center', fontSize: '18px' }}>  Suggestions </Typography>
                    </row>
                   {load === false ? (<div style={{ maxHeight: '250px', height: '55%', overflowY: 'auto', width: '100%', marginBottom: '0px', marginLeft: '5px'}}>
                          {AIOptions?.sort((a, b) => b.pertinence - a.pertinence).map((options, index) => {
                          let buttonContent;
                          if (newTicketSolution === options.solution) {
                            buttonContent = <span style={{ color: 'green', margin: '0px', padding: '0px' }}>problème résolu</span>;
                          } else {
                            buttonContent = <p style={{ margin: '0px', padding: '0px' }}>problème résolu</p>;
                          }
                          return (
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '70%' }}>
                                <p style={{ fontSize: '12px' }}>{options.solution}</p>
                              </div>
                              <Button
                                style={{ marginLeft: '20px', fontSize: '12px' }}
                                onClick={() => { ticketActionArray.push(
                                  {id:ticketActionArray.length, ref: uuidv4(),  corrective_action : options.solution, correction_date : format(new Date(), "yyyy-MM-dd HH:mm"), user_name : `${user.fierstname} ${user.lastname}`,  user_id: user.id,files: [] }
                                )
                                   setSuggestionId(options.id); setSuggestion(options.solution); setAiNotSolvedButtonColor('primary'); setNewTicketState('Résolu'); }}
                              >
                                {buttonContent}
                              </Button>
                            </div>
                          );
                        })}
                      </div>) : (
                          load === true ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45%' }}>
                              <CircularProgress />
                            </div>
                          ) : (
                              <div style={{height: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <p>Aucune suggestion</p>
                              </div>
                          )
                         ) }
                    
                    </div>) 
                }
                            
                            </div>
               </Grid> 
               
            </Grid>
            
                      </div>;
                    // *** actions ***
                    case 'actions':
                      return <div style={{width:'100%',textAlign:'center',display:'flex',height:'100%',verticalAlign:'top',maxHeight:'44vh', minHeight:'44vh' }}>
                       <Grid container spacing={0} style={{}}> 
                          <Grid item xs={8} style={{}}>
                            <div style={{minWidth:'100%',minHeight:'100%',maxHeight:'100%'}}>
                            <DataGrid
                             localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                rows={oldTicketCorrectiveActions}
                                columns={columns}
                                components={{ Toolbar: CustomToolbar }}
                                initialState={{
                                  pagination: {
                                    paginationModel: { pageSize:1, },
                                  },
                                }}
                                rowsPerPageOptions={[4]}
                                // getRowHeight={() => 'auto'}
                                autoHeight
                                pageSize={4}
                                onCellClick={async (params) => {
console.log(params)
if (params.field !== '') { 
           showActionDocs(params.row.id)
                                 setSelectedActionId(params.row.id)
                                 setNewActionDesc(oldTicketCorrectiveActions.find((e) => e.id === params.row.id ).corrective_action)
                                 setNewActionDate(oldTicketCorrectiveActions.find((e) => e.id === params.row.id ).correction_date)
}
                                }}
                          
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4}   style={{  }}>
                            <div style={{minWidth:'100%',minHeight:'100%',maxHeight:'44vh',
                              //  border:'1px solid',
                                overflowY:'auto'}}>
                             {(actionsDetails !==  null && actionsDetails !== "new") ? 
                                <div>
                                  <h3 style={{color: theme.palette.primary.main}}>Pièces jointes de l&lsquo;action</h3>
                                  <div style={{width:'100%', marginBottom: '20px'}}>
                                    <Button size="small" onClick={
                                    () => { if ( newActionDesc && newActionDate !== '') {
                                      updateCorrectiveAction(selectedActionId, newActionDesc,newActionDate );
                                     } else {
                                       toast.error('La nouvelle description ne peut pas être vide')
                                     }}
                                    }><SaveIcon color="primary" />  Enregistrer les modifications</Button>
                                  </div>
                                  <div>
                                    <TextField multiline rows={2} label="Description " placeholder="Description d'action"  style={{ width: '80%' }} 
                                    // ***** 
                                      onChange={(e) => setNew_CA_Temp_Desc(e.target.value)}
                                      value={oldTicketCorrectiveActions.length > 0 && oldTicketCorrectiveActions.find((e) => e.id === selectedActionId).corrective_action} 
                                      

                                       
                                       />
                                  </div>
                                  <div style={{marginTop: '20px',}}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs} locale={moment.locale('fr')}>
                                    <MobileDateTimePicker 
                                      ampm={false}
                                      todayText="Aujourd'hui"
                                      inputFormat="DD/MM/YYYY à HH:mm "
                                      cancelText="Annuler"
                                      showTodayButton
                                      okText="OK"
                                      readOnly
                                      onChange={(e) => {setNew_CA_Temp_Date(format(new Date(e), "yyyy-MM-dd HH:mm"))
                                      }}
                                      label="Date de l'action"
                                      name="dateAction"
                                      renderInput={(inputProps) => (
                                      <TextField style={{ padding: '0px', margin: '0px', width: '80%' }} 
                                      fullWidth variant="outlined" {...inputProps}
                                      />
                                      )}
                                      value={oldTicketCorrectiveActions.length > 0 && oldTicketCorrectiveActions.find((e) => e.id === selectedActionId).correction_date}
                                    />
                                  </LocalizationProvider>
                                  </div>
                                  <div style={{ width: '100%', AlignItems: 'center',marginTop:'20px' }}>
                                    <div style={{ width: '80%',margin:'0 auto'}}>
                                       <TicketCorrectiveActionsFileDropZone onDrop={handleDropSelectedActionFiles} maxFiles={5} />
                                    </div>
                                  </div>
                                  <div style={{ width: '100%' ,marginTop:'20px',}}>
                                    <div style={{ width: '80%'  ,margin: '0 auto'}}>
                                       {oldTicketCorrectiveActions.length > 0 &&  oldTicketCorrectiveActions.find((e) => e.id === selectedActionId).docs.map((file, index) => (
                                        <>
                                        {file.extension === 'jpg' || file.extension === 'jfif' || file.extension === 'jpeg' || file.extension === 'png' 
                                        || file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' 
                                        || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv' || file.extension ===  'gif' 
                                        || file.extension === 'avi' || file.extension === 'gifv' || file.extension === 'mpeg' || file.extension === 'pdf' ? 
                                        (
                                           <Card 
                                          sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }}
            
                                          style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}
            
                                          title={`Ajouté par ${file.user_name} le ${file.createdAt} `}>
            
                                          <Box style={{display: 'flex'}} onClick={() => { setShowPJ(true); setPJFile(file);}}>
            
                                          {file.extension === 'pdf' ? (
                                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/391px-PDF_file_icon.svg.png" 
                                          
                                          height="40px" width="40px" alt="test"
                                          
                                          style={{borderRadius: '6px', marginRight: '10px', margin: 'auto'}} />)
            
                                          : file.extension === 'mp4' || file.extension === 'webm' || file.extension === 'mkv' || file.extension === 'flv' 
                                          || file.extension === 'vob' || file.extension === 'ogg' || file.extension === 'ogv'  || file.extension === 'avi' 
                                          || file.extension === 'gifv' || file.extension === 'mpeg' ? (
                                            <PlayArrowIcon style={{ marginRight: '10px', margin: 'auto'}} />
                                          ) : (file.extension ===  'gif' || file.extension === 'jpg' ||  file.extension === 'jfif' || file.extension === 'jpeg' 
                                          || file.extension === 'png' ? (<img src={file.link} height="40px" width="40px" alt="test" style={{borderRadius: '6px', marginRight: '10px', margin: 'auto'}} />) : null)}
                                          &nbsp;
                                          <p style={{textOverflow: 'ellipsis',wordBreak: 'break-all',maxWidth: '90%'}}>{file.file_name}</p></Box>
                                          {/* <IconButton onClick={() => {deleteDocAlert(file.file_id,file.file_name) }}> */}
                                          {/* <DeleteIcon style={{color: theme.palette.primary.main}} /> */}
                                          {/* </IconButton> */}
                                           </Card>
                                        ) : (
                                       
                                        <Card sx={{ '&:hover': { borderColor: theme.palette.dialogText.main, border: '1px solid' } }} 
                                        style={{ margin: '10px', display: 'flex', alignItems: 'center', minHeight: '60px', paddingLeft: '6px', paddingRight: '6px' }}
                                        title={`Ajouté par ${file.user_name} le ${file.createdAt} `}>
                                       <a href={file.link} download rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit'}}>     <Box style={{display: 'flex', margin: 'auto'}} onClick={() => { setShowPJ(true); setPJFile(file);}}>
                                        
                                          <InsertDriveFileIcon fontSize="large" style={{margin: 'auto'}} />
                                          &nbsp;
                                          <p style={{textOverflow: 'ellipsis'}}>{file.file_name}</p>
                                          </Box></a>
                                          {/* <IconButton onClick={() => {deleteDocAlert(file.file_id,file.file_name) }}> */}
                                          {/* <DeleteIcon style={{color: theme.palette.primary.main}} /> */}
                                          {/* </IconButton> */}
                                           </Card>) }
                                     </> 
                                        // <Card style={{ margin: '10px', display: 'flex', alignItems: 'center', width: '95%' , height: '40px'}}>
                                        //   <UploadFileIcon /> {file.file_name}
                                        //     <IconButton onClick={() =>  deleteActionFile(index)}><CloseIcon /></IconButton> 
                                        // </Card>
                                      ))}
                                    </div>
                                  </div>
                                </div> : <div></div> }
                                 {(actionsDetails ===  null) ? 
                                <div>
                                   </div> : <div></div> }
                                {(actionsDetails === "new") ? 
                                <div>
                                  <h3  style={{color: theme.palette.primary.main}}>Nouvelle action corrective</h3>
                               {/* Description */}
                               <div style={{ marginTop: '20px',  marginBottom: '10px' }}>
                                  <div>
                                    <TextField multiline rows={2} label="Description " placeholder="Description d'action" style={{ width: '80%' }} 
                                    // ***** modifier on change
                                      onChange={(e) => { setNew_CA_Desc(e.target.value) }}
                                       value={new_CA_Desc} 
                                      //  defaultValue={new_CA_Desc}
                                       />
                                  </div>
                                  <div style={{marginTop: '20px',}}>
                                  <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                inputFormat="DD/MM/YYYY à HH:mm "
                                cancelText="Annuler"
                                showTodayButton
                                okText="OK"
                                //  disabled={infoTicket.date_res_eff !== null}
                                onChange={(e) => {
                                  setNew_CA_Date(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                }}
                                 label="Date de l'action"
                                name="dateAction"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px', width: '80%' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={new_CA_Date}
                              /></LocalizationProvider>
                                  </div>
                                  {/* ****pièces jointes */}
                                  <div style={{ width: '100%', AlignItems: 'center',marginTop:'20px' }}>
                                    <div style={{ width: '80%',margin:'0 auto'}}>
                                       <TicketCorrectiveActionsFileDropZone onDrop={handleDropNewActionFiles} maxFiles={5} />
                                    </div>
                                  </div>

                                  <div style={{ width: '100%' ,marginTop:'20px',}}>
                                    <div style={{ width: '80%'  ,margin: '0 auto'}}>
                                      {new_CA_Temp_files.map((file, index) => (
                                        <Card style={{ margin: '10px', display: 'flex', alignItems: 'center', width: '95%' }}>
                                          <UploadFileIcon /> {file.name} <IconButton onClick={() =>  handleFileDeleteNewActionFiles(index)}><CloseIcon /></IconButton>
                                        </Card>
                                      ))}
                                    </div>
                                  </div>
                                  <div style={{width:'100%'}}>
                                    <Button size="small" onClick={add_new_corrective_action}><AddIcon color="primary" />  Ajouter l&apos;action</Button>
                                  </div>
                                </div> </div> : <div></div> }
                            </div>
                          </Grid> 
                        </Grid>
                    </div>;
                      // *** commentaires ***
                      case 'comments':
                        return <div style={{width:'100%',maxHeight:'44vh', minHeight:'44vh'}}>
                                  <div style={{width: '95%', paddingTop: '10px', minHeight: commentList.length === 0 ? "30px" : "120px", maxHeight: commentList.length === 0 ? "40px" : "120px", marginBottom: '40px' }}>
                              <Box sx={{marginBottom: '20px', alignItems: 'center', display: 'flex' }}>
                                <Avatar sx={{ mr: 2 }}
src={user.avatar} />  
                                <TextField 
                                  style={{ width: '90%' }} 
                                  fullWidth
onChange={handleTicketCommentChange}
placeholder="Ajouter un commentaire..."
size="small"
value={ticketComment}
variant="outlined"
multiline
                                  onKeyUp={handleKeyUp}
                                />
                              </Box>
                              {commentList.length !== 0 ? (
                                <div style={{ width: '100%', maxHeight: commentList.length === 0 ? "90px" : "40vh", overflowY: 'auto', marginTop: '10px' }}>
                                {commentList.slice().reverse().map((comment) => (<div style={{marginBottom: '20px'}}> <CustomerQualityComment comment={comment} /></div> ))}
                              </div>
                              ) : ( 
                                <div style={{ width: '100%', maxHeight: commentList.length === 0 ? "80px" : "106px", overflowY: 'auto', marginTop: '0px' }}>
                               <p style={{ textAlign: 'center', width: '100%'}}> Aucun commentaire pour le moment </p>
                              </div>
                              )}
                              
                            </div>
                                </div>;
                    // *** historique ***
                      case 'log':
                        return ticketLog.length === 0 ? <div style={{width:'100%',textAlign:'center',display:'flex',height:'100%',border:'1px solid',justifyContent:'center',alignItems:'center',maxHeight:'44vh', minHeight:'44vh' }}>
                        Aucun élément dans l&apos;historique pour le moment
                      </div> : 
                     <div style={{ width: '100%', overflowY: 'auto',maxHeight:'44vh', minHeight:'44vh' }}>
                     {ticketLog.map((event) => (
                       <div style={{ display: 'flex', padding: '0px', margin: '0px', justifyContent:'space-around' }}>
                         <div style={{ marginRight: '2%'}}>
                         <h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', color: theme.palette.dialogText.main,  whiteSpace: 'nowrap'}}> {format(new Date(event.time), "dd/MM/yyyy 'à' HH:mm:ss")}</h5>
                         </div>
                         <div style={{marginRight: '1%'}}> 
                         <a style={{textDecoration: 'none', decoration: 'none'}} href={`${process.env.REACT_APP_API}/dashboard/user/${event.user_id}`} target="_blank>"><h5 style={{ padding: '0px', margin: '0px', marginTop: '5px', whiteSpace: 'nowrap', color: theme.palette.dialogText.main }}>{event.user_name}</h5></a>
                         </div>
                         <div style={{ width: '70%', marginRight: '1%', marginTop: '2px',display: 'flex', justifyContent: 'center'}}>
                           <p style={{ marginRight: '10px', padding: '0px', margin: '0px', color: theme.palette.dialogText.main }}>{event.description}</p>
                         </div>
                         
                       </div>
                     ))}
                   </div>;
                    default:
                      return null;
                  }
            })()}  </div> 
                        </div>

                 
                  
                </DialogContentText>
              </DialogContent>
              <DialogActions>
              <p style={{margin: '0px', padding: '0px', marginTop: '10px', position: 'absolute', left: '10px', color: theme.palette.primary.main, fontWeight: 'bold'}}>(*) Champ obligatoire </p>
              <Button
                  onClick={() => {
                    handleClose(); handleShow();
                  }}
                  color="primary"
              >
                  Fermer
              </Button>
              {  newTicketMajorIncident !== oldTicketMajorIncident ||  newTicketSeverity !== oldTicketSeverity ||  newTicketReferentId !== oldTicketReferentId || newTicketState !== oldTicketState ||  newTicketDRA !== oldTicketDRA ||  newTicketCategory !== oldTicketCategory ||  newTicketAcceptTracking !== oldTicketAcceptTracking || newTicketSolution !== oldTicketSolution || newTicketDescription !== oldTicketDescription || fileValues.length !== 0  ? (
                    <Button
                      onClick={() => {
                        if (suggestionId !== null) {
                          sendFeedback(problemId, suggestionId);
                        }
                        if (suggestion === null && newTicketSolution !== null) {
                          sendSolutionToAI(newTicketSolution);
                        }
                        handleSubmit(infoTicket.ticketId);
                         updateTicketInfo(user.id, infoTicket.ticketId);
                         handleShow();
                        handleClose();
                        getTickets();
                        setUpdate(!update);               
                      }}
                      color="primary"
                      // disabled={!(newTicketService !== null && newTicketService !== '' && newTicketSeverity !== null && newTicketSeverity !== '' && newTicketSeverity !== null && newTicketSeverity !== '')}
                    >
                      Enregistrer les modifications
                    </Button>
                  ) : (
                    ((infoTicket.state === 'Ouvert' && infoTicket.referent_id === user.id) || (infoTicket.state === 'Ouvert' && user.id_service === 0 && user.access_level === 0) || (infoTicket.state === 'Ouvert' && user.service_manager === 1)) ? (
                      <Button
                        onClick={() => {
                          getTickets();
                          setUpdate(!update);
                          handleClose();
                          handleShow();
                          handleSubmit(infoTicket.ticketId);
                          if (newTicketMajorIncident !== oldTicketMajorIncident ||  newTicketSeverity !== oldTicketSeverity ||  newTicketReferentId !== oldTicketReferentId ||
                            newTicketState !== oldTicketState ||  newTicketDRA !== oldTicketDRA ||  newTicketCategory !== oldTicketCategory ||
                            newTicketAcceptTracking !== oldTicketAcceptTracking || newTicketSolution !== oldTicketSolution || newTicketDescription !== oldTicketDescription 
                        ) {
                         updateTicketInfo(user.id, infoTicket.ticketId);
                      }
                          validateTicket(infoTicket.ticketId);
                        }}
                        color="primary"
                      >
                        Valider
                      </Button>
                    )  : null
                  )
                } 
                 {((infoTicket.state === 'Suspendu' && infoTicket.creator_id === user.id) || (infoTicket.state === 'Suspendu' && user.id_service === 0 && user.access_level === 0) || (infoTicket.state === 'Suspendu' && user.service_manager === 1)) ? (
                      <Button
                        onClick={() => {
                          getTickets();
                          setUpdate(!update);
                          handleClose();
                          handleShow();
                          handleSubmit(infoTicket.ticketId);
                          if (newTicketMajorIncident !== oldTicketMajorIncident ||  newTicketSeverity !== oldTicketSeverity ||  newTicketReferentId !== oldTicketReferentId ||
                              newTicketState !== oldTicketState ||  newTicketDRA !== oldTicketDRA ||  newTicketCategory !== oldTicketCategory ||
                              newTicketAcceptTracking !== oldTicketAcceptTracking || newTicketSolution !== oldTicketSolution || newTicketDescription !== oldTicketDescription 
                          ) {
                           updateTicketInfo(user.id, infoTicket.ticketId);
                        }
                          openTicket(infoTicket.ticketId);
                        }}
                        color="primary"
                      >
                        {type === 'ticket' ? 'Ouvrir le ticket' : 'Ouvrir la réclamation'}
                      </Button>
                    ) : null}         
              </DialogActions>
            </Dialog>
</ThemeProvider>
); };
ShowTicketReferent.propTypes = {
    show: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    infoTicket: PropTypes.object.isRequired,
    commentList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    ticketComment: PropTypes.string,
    handleTicketCommentChange: PropTypes.func.isRequired,
    handleKeyUp: PropTypes.func.isRequired,
    setCommentList: PropTypes.func.isRequired,
    setInfoTicket: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    setListServices: PropTypes.func.isRequired,
    listServices: PropTypes.array.isRequired,
    listCategories: PropTypes.array.isRequired,
    listMembers: PropTypes.array.isRequired,
    getCategories: PropTypes.func.isRequired,
    getServiceMembers: PropTypes.func.isRequired,
    setTicketService: PropTypes.func.isRequired,
    setTicketCategory: PropTypes.func.isRequired,
    setTicketReferentId: PropTypes.func.isRequired,
    setTicketReferentName: PropTypes.func.isRequired,
    setTicketSeverity: PropTypes.func.isRequired,
    setTicketSolution: PropTypes.func.isRequired,
    handleShow: PropTypes.func.isRequired,
    ticketService: PropTypes.number.isRequired,
    ticketSeverity: PropTypes.number.isRequired,
    ticketDescription: PropTypes.string.isRequired,
    ticketLog: PropTypes.array.isRequired,
    ///for modifying
    setNewTicketService: PropTypes.func.isRequired,
    setNewTicketSeverity: PropTypes.func.isRequired,
    setNewTicketReferentId: PropTypes.func.isRequired,
    setNewTicketTracking: PropTypes.func.isRequired,
    setNewTicketSolution: PropTypes.func.isRequired,
    setNewTicketState: PropTypes.func.isRequired,
    setNewTicketMajorIncident: PropTypes.func.isRequired,
    setNewTicketCategory: PropTypes.func.isRequired,
    setNewTicketDRA: PropTypes.func.isRequired,
    newTicketService: PropTypes.number.isRequired,
    newTicketSeverity: PropTypes.number.isRequired,
    newTicketReferentId: PropTypes.number.isRequired,
    newTicketTracking: PropTypes.number.isRequired,
    newTicketSolution: PropTypes.string.isRequired,
    newTicketState: PropTypes.string.isRequired,
    newTicketMajorIncident: PropTypes.number.isRequired,
    newTicketCategory: PropTypes.number.isRequired,
    newTicketDRA: PropTypes.instanceOf(Date).isRequired,
    setNewTicketAcceptTracking: PropTypes.func.isRequired,
    newTicketAcceptTracking: PropTypes.bool.isRequired,
    newTicketDescription: PropTypes.string.isRequired,
    setNewTicketDescription: PropTypes.func.isRequired,
    setOldTicketCorrectiveActions: PropTypes.func.isRequired,
    ////////////////to compare
    setOldTicketService: PropTypes.func.isRequired,
    setOldTicketSeverity: PropTypes.func.isRequired,
    setOldTicketReferentId: PropTypes.func.isRequired,
    setOldTicketTracking: PropTypes.func.isRequired,
    setOldTicketSolution: PropTypes.func.isRequired,
    setOldTicketState: PropTypes.func.isRequired,
    setOldTicketMajorIncident: PropTypes.func.isRequired,
    setOldTicketCategory: PropTypes.func.isRequired,
    setOldTicketDRA: PropTypes.func.isRequired,
    setOldTicketAcceptTracking: PropTypes.func.isRequired,
    oldTicketService: PropTypes.number.isRequired,
    oldTicketSeverity: PropTypes.number.isRequired,
    oldTicketReferentId: PropTypes.number.isRequired,
    oldTicketTracking: PropTypes.number.isRequired,
    oldTicketSolution: PropTypes.string.isRequired,
    oldTicketState: PropTypes.string.isRequired,
    oldTicketMajorIncident: PropTypes.number.isRequired,
    oldTicketCategory: PropTypes.number.isRequired,
    oldTicketDRA: PropTypes.instanceOf(Date).isRequired,
    oldTicketAcceptTracking: PropTypes.bool.isRequired,
    oldTicketDescription: PropTypes.string.isRequired,
    setOldTicketDescription: PropTypes.func.isRequired,
    updateTicketInfo: PropTypes.func.isRequired,
    update: PropTypes.bool.isRequired,
    setUpdate: PropTypes.func.isRequired,
    ticketFiles: PropTypes.array.isRequired,
    setTicketFiles: PropTypes.func.isRequired,
    validateTicket: PropTypes.func.isRequired,
    openTicket: PropTypes.func.isRequired,
    deleteTicket: PropTypes.func.isRequired,
    majorIncident: PropTypes.object.isRequired,
    getTickets: PropTypes.func.isRequired,
    deleteTicketDocument: PropTypes.func.isRequired,
    getTicketDocuments: PropTypes.func.isRequired,
    getTicketCorrectiveActions: PropTypes.func.isRequired,
  };
  
  export default ShowTicketReferent;
