import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, frFR } from '@mui/x-data-grid';
import { ClickAwayListener } from '@mui/base';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth';
import { useState } from 'react';
import { ticketApi } from '../../../api/ticketApi';
import 'moment/locale/fr';
import Label from '../../Label';
import DialogDetailsMajorIncident from './DialogDetailsMajorIncident';
import LogMajorIncident from './LogMajorIncident';
import DeleteMajorIncident from './DeleteMajorIncident';
import moment from 'moment';

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export default function MajorIncidentList(props) {
  const { majorIncidents, setMajorIncidents, isLoading, currentTab, updateList, setUpdateList } = props;
  const [selectionModel, setSelectionModel] = useState([]);
  const [show, setShow] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const { user } = useAuth();

  const columnsScheme = [
    {
      field: 'description',
      headerName: 'Description',
      flex: 4
    },
    {
      field: 'start_date',
      headerName: 'Date de début',
      valueGetter: (params) => moment(params.row.start_date).format('DD/MM/YYYY HH:mm'),
      type: 'dateTime',
      preProcessEditCellProps: (params) => {
        const nullDate = new Date('1970-01-01 01:00:00');
        const hasError = (params.props.value === (null || undefined || '-' || 'Invalid Date')) || new Date(params.props.value).getTime() === nullDate.getTime();
        return { ...params.props, error: hasError };
      },
      flex: 1
    },
    {
      field: 'creator',
      headerName: 'Déclaré par',
      flex: 1,
      valueGetter: (params) => `${capitalizeFirstLetter(params.row.creator.firstname)} ${capitalizeFirstLetter(params.row.creator.lastname)}`
    },
    {
      field: 'state',
      headerName: 'Etat',
      renderCell: (params) => (
        <Label
          color={params.row.state === 'En cours' ? 'error' : 'success'}
        >
          {params.row.state}
        </Label>
      ),
      type: 'singleSelect',
      valueOptions: ['En cours', 'Résolu'],
      flex: 0.6
    },
    {
      field: 'end_date',
      headerName: 'Date de fin',
      valueGetter: (params) => {
        if (params.row.end_date === null || params.row.end_date === undefined || params.row.end_date === 'Invalid Date' || params.row.end_date === '-') {
          return '-';
        }
        return moment(params.row.end_date).format('DD/MM/YYYY HH:mm');
      },
      preProcessEditCellProps: (params) => {
        const nullDate = new Date('1970-01-01 01:00:00');
        const hasError = (params.props.value === (null || undefined || '-' || 'Invalid Date')) || new Date(params.props.value).getTime() === nullDate.getTime();
        return { ...params.props, error: hasError };
      },
      type: 'dateTime',
      flex: 1
    },
    {
      field: 'details',
      headerName: 'Historique',
      renderCell: (params) => (
        <Box sx={{ display: 'flex' }}>
          <LogMajorIncident
            majorIncidentId={params.row.id}
          />
          {user.access_level === 0 && user.id_service === 0 && (
          <DeleteMajorIncident
            majorIncidentId={params.row.id}
            userId={user.id}
            majorIncidentDescription={params.row.description}
            majorIncidents={majorIncidents}
            setMajorIncidents={setMajorIncidents}
          />
          )}
        </Box>
      ),
      flex: 0.9,
      disableClickEventBubbling: true,
    },
  ];

  const CustomToolbar = () => (user.id_service === 0
    ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
    : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    ));

  const handleDialog = (params) => {
    setShow(true)
    setSelectedRow(params.row)
  }
  
  return (
    <Box sx={{ height: 650, width: '100%' }}>
      {/* <ClickAwayListener onClickAway={() => setSelectionModel([])}> */}
      <DataGrid
        rows={majorIncidents}
        columns={columnsScheme}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal'
          },
          '& .MuiDataGrid-columnHeader': {
            // '!important' passe outre le style en ligne (inline style)
            height: 'unset !important'
          },
          '& .MuiDataGrid-columnHeaders': {
            // '!important' passe outre le style en ligne (inline style)
            maxHeight: '450px !important'
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important'
          },
          '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
            // backgroundColor: 'green',
            // color: 'white'
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
            },
        }}
        getRowHeight={() => 'auto'}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: CustomToolbar }}
        density="comfortable"
        loading={isLoading}
        selectionModel={selectionModel}
        onSelectionModelChange={(selectMod) => setSelectionModel(selectMod)}
        onRowClick={handleDialog}
      />
      {/* </ClickAwayListener> */}
      <DialogDetailsMajorIncident
        majorIncidentId={selectedRow?.id}
        majorIncidentAuthor={selectedRow?.creator}
        serviceName={currentTab}
        setUpdateList={setUpdateList}
        updateList={updateList}
        show={show}
        setShow={setShow}
      />
    </Box>
  );
}

MajorIncidentList.propTypes = {
  majorIncidents: PropTypes.array.isRequired,
  setMajorIncidents: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentTab: PropTypes.string.isRequired,
  updateList: PropTypes.bool.isRequired,
  setUpdateList: PropTypes.func.isRequired
};
