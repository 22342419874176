import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import DetailsMajorIncident from './DetailsMajorIncident';
import { ticketApi } from '../../../api/ticketApi';
import Scrollbar from '../../Scrollbar';
import Label from '../../Label';
import useSettings from '../../../hooks/useSettings';
import { createCustomTheme } from '../../../theme';
import { ThemeProvider } from '@mui/material';
import useAuth from '../../../hooks/useAuth';

const DialogDetailsMajorIncident = (props) => {
  const { majorIncidentId, majorIncidentAuthor, serviceName, updateList, setUpdateList, show, setShow } = props;
  const [tickets, setTickets] = useState([]);
  const [service, setService] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [majorIncidentState, setMajorIncidentState] = useState('')
  const [majorIncident, setMajorIncident] = useState({
    id: majorIncidentId,
    solution: '',
    startDate: null,
    endDate: null,
    description: '',
    author: majorIncidentAuthor,
    state: null,
  });
  const [updateSolution, setUpdateSolution] = useState('')
  const [currentSolution, setCurrentSolution] = useState('');
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const { user } = useAuth()

  const { settings } = useSettings();
  const theme = createCustomTheme({
    ...settings
  });

  const getServiceByName = async (name) => {
    try {
      const serviceData = await ticketApi.getServiceByName(localStorage.getItem('accessToken'), name);
      setService(serviceData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleShow = () => {
    setShow(false);
    setUpdateList(!updateList)
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      if (currentSolution.trim() !== majorIncident.solution.trim()) {
        setShowConfirmationDialog(true)
      } else {
        setShow(false);
        setUpdateList(!updateList)
      }
    }
  };

  const handleCancel = () => {
    setMajorIncident({
      ...majorIncident,
      solution: currentSolution
    })
  }

  const solveMajorIncident = async () => {
    try {
      await ticketApi.solveMajorIncident(localStorage.getItem('accessToken'), majorIncidentId, user.id, majorIncident.solution);
      setUpdateSolution(true)
    } catch (err) {
      console.log(err);
    }
  };

  const reopenMajorIncident = async () => {
    try {
      setMajorIncident({
        ...majorIncident,
        solution: ''
      })
      await ticketApi.reopenMajorIncident(localStorage.getItem('accessToken'), majorIncidentId, user.id);
      setUpdateSolution(true)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getServiceByName(serviceName);
    setIsLoading(true);
  }, [serviceName, majorIncidentId, show]);

  // console.log('Solution : ', majorIncident.solution)
  // console.log('Current solution : ', currentSolution)

  console.log(show)

  return (
    <>
    <ThemeProvider theme={theme}>
      {show && (
      
        <Dialog
          open={show}
          fullWidth={true}
          maxWidth="md"
          onClose={handleClose}
          // sx={{ width: 500 }}
        >
          <DialogTitle disableTypography sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{color: theme.palette.primary.main, fontWeight: 'bold', mt: -0.25 }}>Détails de l&apos;incident majeur</Typography>
            <Typography sx={{fontWeight: 'bold' }}><span style={{color: theme.palette.primary.main }}>Service :</span> {service?.name}</Typography>
            <Typography sx={{fontWeight: 'bold' }}><span style={{color: theme.palette.primary.main }}>Déclaré par :</span> {majorIncidentAuthor?.firstname} {majorIncidentAuthor?.lastname}</Typography>
            {majorIncidentState !== '' && 
            <Label
              color={majorIncidentState === 'En cours' ? 'error' : 'success'}
            >
              {majorIncidentState}
            </Label>
            }
          </DialogTitle>
          <Scrollbar options={{ suppressScrollX: true }}>
            <DialogContent sx={{ overflowY: "visible"}}>
                <DetailsMajorIncident
                  majorIncidentId={majorIncidentId}
                  majorIncidentState={majorIncidentState}
                  setMajorIncidentState={setMajorIncidentState}
                  serviceId={service.id}
                  tickets={tickets}
                  setTickets={setTickets}
                  placeholder="Ajouter un ticket concerné par l'incident majeur"
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  show={show}
                  theme={theme}
                  majorIncident={majorIncident}
                  setMajorIncident={setMajorIncident}
                  updateSolution={updateSolution}
                  setUpdateSolution={setUpdateSolution}
                  currentSolution={currentSolution}
                  setCurrentSolution={setCurrentSolution}
                />
            </DialogContent> 
          </Scrollbar>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mx: 3, mb: 2 }}>
            { currentSolution.trim() !== majorIncident.solution.trim() ? 
              (
              <>
                <Button size="large" onClick={handleCancel}>Annuler</Button>
                <Button 
                  size="large" 
                  onClick={
                    majorIncident.solution.trim() === '' ?
                      reopenMajorIncident : solveMajorIncident
                  }
                >
                  Enregistrer l&lsquo;action corrective
                </Button>
              </>
              ) : (
                <Button
                  size="large"
                  onClick={handleShow}
                >
                  Fermer
                </Button>
              )
            }
          </Box>
        </Dialog>
      )}
      {showConfirmationDialog && 
        <Dialog
          open={showConfirmationDialog}
          fullWidth={true}
          maxWidth="xs"
          // onClose={handleCloseConfirmationDialog}
          // sx={{ width: 500 }}
        >
          <DialogTitle disableTypography sx={{ textAlign: "center", fontWeight: 'bold' }}>Confirmation</DialogTitle>
          <DialogContent sx={{mb: 2, textAlign: 'center'}}>
            <Typography>Vous avez effectué une modification quant à l&lsquo;action corrective. Souhaitez-vous l&lsquo;enregistrer ?</Typography>
          </DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 3, mb: 2 }}>
            <Button 
              size="large" 
              onClick={() => {
                handleCancel()
                setShowConfirmationDialog(false);
                setShow(false);
                setUpdateList(!updateList)
            }}
            >
              Continuer sans enregistrer
            </Button>
            <Button 
              size="large" 
              onClick={() => {
                setShowConfirmationDialog(false);
            }}
            >
              Annuler
            </Button>
            <Button 
              size="large" 
              onClick={
                () => {
                  if (majorIncident.solution.trim() === '') {
                    reopenMajorIncident()
                  } else {
                    solveMajorIncident()
                  } 
                  setShowConfirmationDialog(false);
                  setShow(false);
                  setUpdateList(!updateList)
                }
              }
            >
              Enregistrer
            </Button>
          </Box>
        </Dialog>
      }
      </ThemeProvider>
    </>
  );
};

export default DialogDetailsMajorIncident;

DialogDetailsMajorIncident.propTypes = {
  majorIncidentId: PropTypes.number.isRequired,
  majorIncidentAuthor: PropTypes.object.isRequired,
  serviceName: PropTypes.string.isRequired,
};
