/* eslint-disable no-unused-vars */
import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  IconButton,
} from '@material-ui/core';
import { customerApi } from '../../api/customerApi';
import { CustomerContactDetails, } from '../../components/dashboard/customer';
import useMounted from '../../hooks/useMounted';
import ChevronRightIcon from '../../icons/ChevronRight';
import ChevronLeftIcon from '../../icons/ChevronLeft';
import PencilAltIcon from '../../icons/PencilAlt';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import useSettings from '../../hooks/useSettings';
import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import useAuth from '../../hooks/useAuth';
import * as moment from 'moment';
import { campaignApi } from '../../api/campaignApi';
import CampaignCustomerProDetailsReinj from '../../components/dashboard/campaign/CampaignCustomerProDetailsReinj';

const CampaignReinjectionCustomerDetails = () => {
  const mounted = useMounted();
  const { user } = useAuth();
  const { settings } = useSettings();
  const { status } = useParams();
  const [customer, setCustomer] = useState(null);
  const [customersCampaignIds, setCustomersCampaignIds] = useState(null);
  const [before, setBefore] = useState(null);
  const [after, setAfter] = useState(null);
  const [isTreated, setIsTreated] = useState(false);

  const [initialValues, setInitialValues] = useState({
    civility: '',
    firstname: '',
    maidenName: '',
    lastname: '',
    birthday: '',
    email: '',
    adress: null,
    zip: null,
    city: '',
    department: '',
    region: '',
    profession: '',
    specialite: null,
    modeOfExercise: null,
    phone: '',
    phoneFix: '',
    adeliRpps: '',
    numSecu: '',
    mdpMondpc: '',
    mdpFifpl: '',
    remplacant: false,
    siret: '',
    birthTown: '',
    country: '',
    callTrack: null,
    callTrackRelance: null,
    antecedents: '',
    institution: '',
    groupment: '',
    notCall: '',
    submit: ''
  });
  const { customerId, campaignId } = useParams();
  const [editing, setEditing] = useState(false);
  const [formations, setFormations] = useState([]);
  const [autoZip, setAutoZip] = useState(null);
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation
  const searchPlace = async (value) => {
    const urlPlace = `https://geo.api.gouv.fr/communes?codePostal=${value}&fields=nom,departement,region&format=json&geometry=centre`;
    try {
      return (await fetch(urlPlace)).json();
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const update = (values) => {
    const tempCustomer = { ...customer };
    tempCustomer.apprenant.civility = values.civility;
    tempCustomer.apprenant.firstname = values.firstname;
    tempCustomer.apprenant.maiden_name = values.maidenName;
    tempCustomer.apprenant.lastname = values.lastname;
    tempCustomer.apprenant.birthday = values.birthday;
    tempCustomer.apprenant.email = values.email;
    tempCustomer.apprenant.adress = values.adress;
    tempCustomer.apprenant.zip = values.zip;
    tempCustomer.apprenant.city = values.city;
    tempCustomer.apprenant.department = values.department;
    tempCustomer.apprenant.region = values.region;
    tempCustomer.apprenant.profession = values.profession;
    tempCustomer.apprenant.specialite = values.specialite;
    tempCustomer.apprenant.mode_of_exercise = values.modeOfExercise;
    tempCustomer.apprenant.phone = values.phone;
    tempCustomer.apprenant.phone_fix = values.phoneFix;
    tempCustomer.apprenant.adeli_rpps = values.adeliRpps;
    tempCustomer.apprenant.num_secu = values.numSecu;
    tempCustomer.apprenant.mdp_mondpc = values.mdpMondpc;
    tempCustomer.apprenant.call_track = values.callTrack;
    tempCustomer.apprenant.call_track_relance = values.callTrackRelance;
    tempCustomer.apprenant.antecedents = values.antecedents;
    tempCustomer.apprenant.remplacant = values.remplacant;
    tempCustomer.apprenant.mdp_fifpl = values.mdpFifpl;
    tempCustomer.apprenant.siret = values.siret;
    tempCustomer.apprenant.not_call = values.notCall;
    tempCustomer.apprenant.birth_town = values.birthTown;
    tempCustomer.apprenant.country = values.country;
    setCustomer(tempCustomer);
  };

  const [time, setTime] = useState(0);

  const saveTimeReinjection = async () => {
    try {
      await campaignApi.saveTimeReinjection(customerId, campaignId, time, localStorage.getItem('accessToken'));
    } catch (err) {
      console.error(err);
    // navigate('/404', { replace: true });
    }
  };

  useEffect(() => {
    if (time <= 1200) {
      const interval = setInterval(() => {
        if (time < 1200) {
          setTime(time + 1);
        }
        if (time % 5 === 0) {
          saveTimeReinjection();
        }
        if (time === 1200) {
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => false;
  }, [time]);

  const getCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getCustomer(customerId, localStorage.getItem('accessToken'));
      const dataCustomersCampaign = await campaignApi.getCustomersCampaignReinjectionByStatus(campaignId, status, localStorage.getItem('accessToken'));
      const dataCustomerTime = await campaignApi.getCustomerTimeReinjection(customerId, campaignId, localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setCustomer(data);
        setFormations(data.formations_informations);
        setTime(dataCustomerTime.spent_time);
        if (dataCustomersCampaign.customers_campaign.length > 0) {
          setCustomersCampaignIds(dataCustomersCampaign.customers_campaign);
        } else {
          navigate(`/dashboard/campaign/${campaignId}`, { replace: true });
        }

        setInitialValues({
          civility: data.apprenant.civility,
          firstname: data.apprenant.firstname,
          maidenName: data.apprenant.maiden_name,
          lastname: data.apprenant.lastname,
          birthday: data.apprenant.birthday,
          email: data.apprenant.email,
          adress: data.apprenant.adress,
          zip: data.apprenant.zip,
          city: data.apprenant.city,
          department: data.apprenant.department,
          region: data.apprenant.region,
          profession: data.apprenant.profession,
          specialite: data.apprenant.specialite,
          modeOfExercise: data.apprenant.mode_of_exercise,
          phone: data.apprenant.phone,
          phoneFix: data.apprenant.phone_fix,
          adeliRpps: data.apprenant.adeli_rpps,
          numSecu: data.apprenant.num_secu,
          mdpMondpc: data.apprenant.mdp_mondpc,
          callTrack: data.apprenant.call_track,
          callTrackRelance: data.apprenant.call_track_relance,
          antecedents: data.apprenant.antecedents,
          institution: '',
          groupment: '',
          submit: '',
          mdpFifpl: data.apprenant.mdp_fifpl,
          siret: data.apprenant.siret,
          remplacant: data.apprenant.remplacant,
          notCall: data.apprenant.not_call,
          birthTown: data.apprenant.birth_town,
          country: data.apprenant.country,
        });
      }
    } catch (err) {
      console.error(err);
      // navigate('/404', { replace: true });
    }
  }, [mounted]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  useEffect(() => {
    if (customersCampaignIds !== null) {
      if (customersCampaignIds.findIndex((el) => el.customers_id.toString() === customerId) === -1) {
        // navigate('/404', { replace: true });
      }
      if (customersCampaignIds[1] !== undefined) {
        setAfter(customersCampaignIds[1].customers_id);
      }
      // pour gérer le clic sur précédent alors qu'on vient de traiter l'apprenant (le if commenté)
      if (customersCampaignIds.findIndex((el) => el.customers_id.toString() === customerId) !== -1) {
        // eslint-disable-next-line no-unused-expressions
        customersCampaignIds.findIndex((el) => el.customers_id.toString() === customerId) - 1 !== -1 ? setBefore(customersCampaignIds[customersCampaignIds.findIndex((el) => el.customers_id.toString() === customerId) - 1].customers_id) : setBefore(customerId);
      }
    }
  }, [customersCampaignIds]);

  const handleClickEdit = () => {
    if (editing === false) {
      setEditing(true);
    }
  };

  const stopEditing = () => {
    setEditing(false);
  };

  const validationSchema = Yup
    .object()
    .shape({
      adress: Yup.string().max(255).nullable(),
      birthday: Yup.string().max(10).nullable(),
      profession: Yup.string().max(255),
      specialite: Yup.string().max(255).nullable(),
      modeOfExercise: Yup.string().max(255).nullable(),
      adeliRpps: Yup.string().max(11).nullable(),
      numSecu: Yup.string().max(13).nullable(),
      callTrack: Yup.string().max(20).nullable(),
      callTrackRelance: Yup.string().max(20).nullable(),
      mdpMondpc: Yup.string().max(255).nullable(),
      civility: Yup.string().max(8).nullable(),
      siret: Yup.string().max(14).nullable(),
      mdpFifpl: Yup.string().max(255).nullable(),
      remplacant: Yup.boolean(),
      lastname: Yup
        .string()
        .max(255)
        .required('Le prénom est requis'),
      maidenName: Yup
        .string()
        .max(255)
        .nullable(),
      firstname: Yup
        .string()
        .max(255)
        .required('Le nom est requis'),
      zip: Yup.string().max(5).min(5).nullable(),
      city: Yup.string().max(255).nullable(),
      email: Yup
        .string()
        .email('Doit être une adresse email')
        .max(255),
      phone: Yup.string().max(20, 'Il y a trop de numéros.').nullable()
        .when('phoneFix', {
          is: (phoneFix) => !phoneFix || phoneFix.length === 0,
          then: Yup.string()
            .required('Un des deux champs de téléphone est requis.'),
        }),
      phoneFix: Yup.string().max(20, 'Il y a trop de numéros.').nullable()
        .when('phone', {
          is: (phone) => !phone || phone.length === 0,
          then: Yup.string()
            .required('Un des deux champs de téléphone est requis.'),
        }),
      birthTown: Yup
        .string()
        .max(255)
        .nullable(),
      country: Yup
        .string()
        .max(255)
        .nullable(),
      department: Yup.string().max(255).nullable(),
      region: Yup.string().max(255).nullable(),
      antecedents: Yup.string().nullable(),
      institution: Yup.string().max(10).nullable(),
      groupment: Yup.string().max(10).nullable(),
      notCall: Yup.string().max(45).nullable(),
    }, [['phone', 'phoneFix']]);

  if (!customer) {
    return null;
  }

  return (
    <>
      {user.id_service === 3 && (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              if (values.zip !== null && autoZip) {
                await searchPlace(values.zip).then((value) => {
                  if (value.length > 0) {
                    if (typeof value[0].nom !== 'undefined' && typeof value[0].departement !== 'undefined' && typeof value[0].region !== 'undefined') {
                      values.city = value[0].nom;
                      values.department = value[0].departement.nom;
                      values.region = value[0].region.nom;
                    }
                  }
                });
              }
              await customerApi.updateCustomer(customerId, values, localStorage.getItem('accessToken'));
              setStatus({ success: true });
              setSubmitting(false);
              setEditing(false);
              toast.success('Apprenant mis à jour !');
              update(values);
              setAutoZip(false);
            } catch (err) {
              console.error(err);
              toast.error('Il y a eu un souci lors de la mise à jour !');
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
            <form
              onSubmit={handleSubmit}
            >
              <Helmet>
                <title>Tableau de bord: Apprenant | Apteed</title>
              </Helmet>
              <Box
                sx={{
                  backgroundColor: 'background.default',
                  minHeight: '100%',
                  py: 8
                }}
              >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <Grid item>
                      <Typography
                        color="textPrimary"
                        variant="h5"
                      >
                        {customer.apprenant.firstname}
                        {' '}
                        {customer.apprenant.lastname}
                      </Typography>
                      <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<ChevronRightIcon fontSize="small" />}
                        sx={{ mt: 1 }}
                      >
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to="/dashboard"
                          variant="subtitle2"
                        >
                          Tableau de bord
                        </Link>
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to="/dashboard"
                          variant="subtitle2"
                        >
                          Campagne
                        </Link>
                        <Typography
                          color="textSecondary"
                          variant="subtitle2"
                        >
                          Apprenant
                        </Typography>
                      </Breadcrumbs>
                      {customersCampaignIds !== null && customersCampaignIds !== undefined && (
                        <>
                          <IconButton
                            color="primary"
                            style={{ backgroundColor: !isTreated || customerId === customersCampaignIds[customersCampaignIds.length - 1].customers_id.toString() ? '' : 'lightgreen' }}
                            sx={{ m: 1 }}
                            onClick={() => window.location.href = `${process.env.REACT_APP_API}/dashboard/campaign/${campaignId}/customer/${after}/status/${status}`}
                            variant="contained"
                            disabled={!isTreated || customerId === customersCampaignIds[customersCampaignIds.length - 1].customers_id.toString()}
                          >
                            <ChevronRightIcon fontSize="medium" />
                          </IconButton>
                          <Typography sx={{ display: 'inline' }}>
                            {`Client ${customersCampaignIds.findIndex((el) => el.customers_id === parseInt(customerId, 10)) + 1}/${customersCampaignIds.length}`}
                          </Typography>
                          <Button
                            color="primary"
                            sx={{ m: 1 }}
                            onClick={() => navigate(`/dashboard/campaign/${campaignId}`, { replace: true })}
                            variant="contained"
                          >
                            Quitter la campagne
                          </Button>
                          {customersCampaignIds.length === 1 && isTreated && (
                          <Button
                            color="primary"
                            sx={{ m: 1 }}
                            onClick={() => navigate(`/dashboard/campaign/${campaignId}`, { replace: true })}
                            variant="contained"
                          >
                            Finir campagne
                          </Button>
                          )}
                        </>
                      )}
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        sx={{ mt: 1 }}
                      >
                        {/* convertit le temps en secondes en minutes et secondes */}
                        {`Temps passé : ${moment.utc(time * 1000).format('mm:ss')}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Box sx={{ m: -1 }}>
                        {editing ? (
                          <>
                            <Button
                              color="primary"
                              startIcon={<CancelIcon fontSize="small" />}
                              onClick={stopEditing}
                              sx={{ m: 1 }}
                              variant="contained"
                            >
                              Annuler
                            </Button>
                            <Button
                              color="primary"
                              startIcon={<SendIcon fontSize="small" />}
                              sx={{ m: 1 }}
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                            >
                              Enregistrer
                            </Button>
                          </>
                        )
                          : (
                            <>
                              <Button
                                color="primary"
                                startIcon={<PencilAltIcon fontSize="small" />}
                                sx={{ m: 1 }}
                                onClick={handleClickEdit}
                                variant="contained"
                              >
                                Modifier
                              </Button>
                            </>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 3 }}>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        lg={settings.compact ? 6 : 4}
                        md={6}
                        xl={settings.compact ? 6 : 3}
                        xs={12}
                      >
                        <Grid>
                          <CustomerContactDetails
                            adress={customer.apprenant.adress}
                            birthday={customer.apprenant.birthday}
                            city={customer.apprenant.city}
                            email={customer.apprenant.email}
                            isVerified={customer.apprenant.isVerified}
                            phone={customer.apprenant.phone}
                            phoneFix={customer.apprenant.phone_fix}
                            region={customer.apprenant.region}
                            civility={customer.apprenant.civility}
                            firstname={customer.apprenant.firstname}
                            maidenName={customer.apprenant.maiden_name}
                            lastname={customer.apprenant.lastname}
                            zip={customer.apprenant.zip}
                            department={customer.apprenant.department}
                            notCall={customer.apprenant.not_call}
                            birthTown={customer.apprenant.birth_town}
                            country={customer.apprenant.country}
                            autoZip={autoZip}
                            setAutoZip={setAutoZip}
                            editing={editing}
                            errors={errors}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            touched={touched}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={settings.compact ? 6 : 4}
                        md={6}
                        xl={settings.compact ? 6 : 3}
                        xs={12}
                      >
                        <CampaignCustomerProDetailsReinj
                          profession={customer.apprenant.profession}
                          modeOfExercise={customer.apprenant.mode_of_exercise}
                          adeliRpps={customer.apprenant.adeli_rpps}
                          notCall={customer.apprenant.not_call}
                          numSecu={customer.apprenant.num_secu}
                          mdpMondpc={customer.apprenant.mdp_mondpc}
                          comment_commercial={customer.last_commercial_comment}
                          comment_relance={customer.last_relance_comment}
                          antecedents={customer.apprenant.antecedents}
                          specialite={customer.apprenant.specialite}
                          callTrack={customer.apprenant.call_track}
                          callTrackRelance={customer.apprenant.call_track_relance}
                          callTrackRelanceList={customer.call_track_relance}
                          editing={editing}
                          errors={errors}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched}
                          values={values}
                          setFieldValue={setFieldValue}
                          customer={customer}
                          formations={formations}
                          customerId={customerId}
                          campaignId={campaignId}
                          setIsTreated={setIsTreated}
                          saveTimeReinjection={saveTimeReinjection}
                          siret={customer.apprenant.siret}
                          mdpFifpl={customer.apprenant.mdp_fifpl}
                          remplacant={customer.apprenant.remplacant}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </form>
          )}
        </Formik>
      )}
      {user.id_service !== 3 && (
        <Box sx={{ mt: 1 }}>
          Vous n&apos;avez pas le droit de voir cette page
        </Box>
      )}
    </>
  );
};

export default CampaignReinjectionCustomerDetails;
