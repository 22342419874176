/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@material-ui/core';
import { partnerApi } from '../../api/partnerApi';
import { useContext } from 'react';
import { PartnersContext } from '../../contexts/PartnerContext';

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
));

export default function DeletePartnerDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { partnerId, partnerName } = props;
  const { partners, setPartners } = useContext(PartnersContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await partnerApi.deletePartner(window.localStorage.getItem('accessToken'), partnerId).catch((e) => console.log(e));
    const newPartners = partners.filter((partner) => partner.id !== partnerId);
    setPartners(newPartners);
    handleClose();
  };

  return (
    <Grid
      sx={{ width: '3.8rem' }}
      container
    >
      <Button
        sx={{ color: '#c50606' }}
        onClick={handleClickOpen}
      >
        <DeleteIcon />
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          {`Voulez-vous vraiment supprimer ${partnerName}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Ce partenaire et toutes ses prestations seront définitivement
            supprimés.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleDelete}>Supprimer</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
