/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { Box, Chip, MenuItem, OutlinedInput, Select, useTheme } from '@mui/material';
import React from 'react';
import { MenuProps } from '../../../../api/customReportApi';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const SelectField = ({ name, list, handleChange, _state }) => {
  const theme = useTheme();

  return (
    <Select
      name={name}
      fullWidth
      size="small"
      labelId="demo-multiple-chip-label"
      id={name}
      multiple
      value={_state}
      onChange={handleChange}
      input={<OutlinedInput id="select-multiple-chip" />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value, i) => (
            <Chip
              key={i}
              label={value}
            />
          ))}
        </Box>
      )}
      MenuProps={MenuProps}
    >
      {list.map((name, key) => (
        <MenuItem
          key={key}
          value={name}
          style={getStyles(name, list, theme)}
        >
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectField;
