/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Card, Typography, ToggleButton, ToggleButtonGroup, Grid } from '@material-ui/core';
import { useState, useCallback, useEffect } from 'react';
import { dashboardApi } from '../../../api/dashboardApi';
import useMounted from '../../../hooks/useMounted';
import _ from 'lodash';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FinanceFinishedValidate = () => {
  const mounted = useMounted();
  const [columns, setColumns] = useState([{ field: '', headerName: '', renderCell: '' }]);
  const [rows, setRows] = useState([]);
  const [columnsFinished, setColumnsFinished] = useState([{ field: '', headerName: '', renderCell: '' }]);
  const [rowsFinished, setRowsFinished] = useState([]);
  const [showStats, setShowStats] = useState(true);
  const [showStat, setShowStat] = useState(true);
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  const handleTabsChange = () => {
    setShowStats(!showStats);
  };
  const handleTabsChanges = () => {
    setShowStat(!showStat);
  };

  const getFinishedValidate = useCallback(async () => {
    try {
      const data = await dashboardApi.getFinishedValidate(localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('Il n\'y a pas de données.');
      }

      if (mounted.current) {
        const tempRows = [];

        const donnees1 = _.cloneDeep(data.donnees);
        donnees1.forEach((donnee) => {
          const sess = donnee.session;
          let totalRowCA = 0;
          let totalRowNumber = 0;
          // eslint-disable-next-line array-callback-return
          Object.keys(sess).map((key) => {
            sess[key] = sess[key].validate;
            totalRowCA += sess[key].ca;
            totalRowNumber += sess[key].nombre;
          });
          tempRows.push({
            id: donnee.user.id,
            firstname: donnee.user.firstname,
            lastname: donnee.user.lastname,
            totalCA: totalRowCA,
            totalNumber: totalRowNumber,
            ...sess
          });
        });

        // Création de la ligne sous-total
        const subTotal = { id: 785, firstname: 'Sous-Total', lastname: '' };

        let caSession = 0;
        let numberSession = 0;
        let subTotalCA = 0;
        let subTotalNombre = 0;
        data.sessions.forEach((sess) => {
          // eslint-disable-next-line array-callback-return
          tempRows.map((ro) => { caSession += ro[sess].ca; numberSession += ro[sess].nombre; });
          subTotal[sess] = { ca: caSession, nombre: numberSession };
          subTotalCA += caSession;
          subTotalNombre += numberSession;
          caSession = 0;
          numberSession = 0;
        });

        subTotal.totalCA = subTotalCA;
        subTotal.totalNumber = subTotalNombre;
        tempRows.push(subTotal);

        setRows(tempRows);

        const tempColumns = [
          { field: 'source',
            headerName: 'Période',
            renderCell: (cellValues) => `${cellValues.row.firstname} ${cellValues.row.lastname}`,
            width: 170
          },
        ];
        months.forEach((month) => {
          tempColumns.push({
            field: month,
            headerName: month,
            renderCell: (cellValues) => (
              // eslint-disable-next-line no-nested-ternary
              <span style={{ whiteSpace: 'pre-line', color: cellValues.row[month].ca < 100 ? 'grey' : cellValues.row[month].ca > 10000 ? 'green' : 'inherit' }}>
                {`${cellValues.row[month].ca !== null ? cellValues.row[month].ca.toFixed(2) : 0} €\n(${cellValues.row[month].nombre})`}
              </span>
            ),
            width: 105
          });
        });

        tempColumns.push({ field: 'total',
          headerName: 'Total',
          renderCell: (cellValues) => <span style={{ whiteSpace: 'pre-line' }}>{`${cellValues.row.totalCA !== null ? cellValues.row.totalCA.toFixed(2) : 0} €\n(${cellValues.row.totalNumber})`}</span>,
          width: 120
        });
        setColumns(tempColumns);

        // FIN DU TABLEAU VALIDÉS, DÉBUT DU TERMINÉ

        const tempRowsFinished = [];

        const donnees2 = _.cloneDeep(data.donnees);

        donnees2.forEach((donnee) => {
          const sess = donnee.session;
          let totalRowValidateCA = 0;
          let totalRowCA = 0;
          let totalRowNumber = 0;
          // eslint-disable-next-line array-callback-return
          Object.keys(sess).map((key) => {
            totalRowValidateCA += sess[key].validate.ca;
            sess[key] = sess[key].finished;
            totalRowCA += sess[key].ca;
            totalRowNumber += sess[key].nombre;
          });
          if (totalRowValidateCA === 0) {
            totalRowValidateCA = 1;
          }
          const tt = ((totalRowCA / totalRowValidateCA) * 100).toFixed(2);
          tempRowsFinished.push({
            id: donnee.user.id,
            firstname: donnee.user.firstname,
            lastname: donnee.user.lastname,
            totalCA: totalRowCA,
            totalNumber: totalRowNumber,
            transformation: tt,
            ...sess
          });
        });

        // Création de la ligne sous-total
        const subTotalFinished = { id: 785, firstname: 'Sous-Total', lastname: '' };

        let caSessionFinished = 0;
        let numberSessionFinished = 0;
        let subTotalCAFinished = 0;
        let subTotalNombreFinished = 0;
        data.sessions.forEach((sess) => {
          // eslint-disable-next-line array-callback-return
          tempRowsFinished.map((ro) => { caSessionFinished += ro[sess].ca; numberSessionFinished += ro[sess].nombre; });
          subTotalFinished[sess] = { ca: caSessionFinished, nombre: numberSessionFinished };
          subTotalCAFinished += caSessionFinished;
          subTotalNombreFinished += numberSessionFinished;
          caSessionFinished = 0;
          numberSessionFinished = 0;
        });

        subTotalFinished.totalCA = subTotalCAFinished;
        subTotalFinished.totalNumber = subTotalNombreFinished;
        if (subTotalCA === 0) {
          subTotalCA = 1;
        }
        subTotalFinished.transformation = ((subTotalCAFinished / subTotalCA) * 100).toFixed(2);

        tempRowsFinished.push(subTotalFinished);

        setRowsFinished(tempRowsFinished);

        const tempColumnsFinished = [
          { field: 'source',
            headerName: 'Période',
            renderCell: (cellValues) => `${cellValues.row.firstname} ${cellValues.row.lastname}`,
            width: 160
          },
        ];
        data.sessions.forEach((session) => {
          tempColumnsFinished.push({
            field: session,
            headerName: session,
            renderCell: (cellValues) => (
              // eslint-disable-next-line no-nested-ternary
              <span style={{ whiteSpace: 'pre-line', color: cellValues.row[session].ca < 100 ? 'grey' : cellValues.row[session].ca > 10000 ? 'green' : 'inherit' }}>
                {`${cellValues.row[session].ca !== null ? cellValues.row[session].ca.toFixed(2) : 0} €\n(${cellValues.row[session].nombre})`}
              </span>
            ),
            width: 100
          });
        });
        tempColumnsFinished.push({ field: 'total',
          headerName: 'Total',
          renderCell: (cellValues) => <span style={{ whiteSpace: 'pre-line' }}>{`${cellValues.row.totalCA !== null ? cellValues.row.totalCA.toFixed(2) : 0} €\n(${cellValues.row.totalNumber})`}</span>,
          width: 100
        });
        tempColumnsFinished.push({ field: 'transformation',
          headerName: 'Transformation',
          renderCell: (cellValues) => `${cellValues.row.transformation} %`,
          width: 70
        });
        setColumnsFinished(tempColumnsFinished);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getFinishedValidate();
  }, [getFinishedValidate]);

  return (
    <>
      <Card>
        <Grid
          container
          sx={{ ml: 1, p: 2 }}
        >
          <ToggleButtonGroup
            exclusive
          >
            <ToggleButton
              style={{ border: 'none', color: 'inherit' }}
              value="grid"
              variant="h6"
              onClick={handleTabsChange}
            >
              {showStats === true ? <KeyboardArrowRightIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography
            variant="h6"
            sx={{ p: 1 }}
          >
            Statistiques par source sur les inscriptions
          </Typography>
        </Grid>
        {showStats !== true && (
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          autoHeight
          rowHeight={50}
          rows={rows}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[50, 100]}
          components={{ Toolbar: GridToolbar }}
        />
        )}
      </Card>
      {showStats === true && ('')}
      <Card sx={{ mt: 4 }}>
        <Grid
          container
          sx={{ ml: 1, p: 2 }}
        >
          <ToggleButtonGroup
            exclusive
          >
            <ToggleButton
              style={{ border: 'none', color: 'inherit' }}
              value="grid"
              variant="h6"
              onClick={handleTabsChanges}
            >
              {showStat === true ? <KeyboardArrowRightIcon fontSize="small" /> : <KeyboardArrowDownIcon fontSize="small" />}
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography
            variant="h6"
            sx={{ p: 1 }}
          >
            Statistiques par source sur les terminés
          </Typography>
        </Grid>
        {showStat !== true && (
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          autoHeight
          rowHeight={50}
          rows={rowsFinished}
          columns={columnsFinished}
          pageSize={50}
          rowsPerPageOptions={[50, 100]}
          components={{ Toolbar: GridToolbar }}
        />
        )}
        {showStat === true && ('')}
      </Card>
    </>
  );
};

export default FinanceFinishedValidate;
