/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React from 'react';
import { partnerApi } from '../../api/partnerApi';
import partnerSVG from '../../assets/partner.svg';
import PartnerForm from './PartnerForm';

export default function EditPartnerForm(props) {
  const {
    open,
    handleClickOpen,
    handleCancel,
    isImageDownloaded,
    isDragActive,
    getRootProps,
    getInputProps,
    files,
    handleChange,
    name,
    siret,
    startContract,
    setStartContract,
    endContract,
    setEndContract,
    partnerId,
    partners,
    setPartners,
  } = props;

  const handleSubmit = async () => {
    const updatedPartner = {
      id: partnerId,
      name,
      siret,
      start_contract: startContract,
      end_contract: endContract,
      img_address: partnerSVG,
    };
    const newPartners = partners.map((partner) => (partner.id !== partnerId ? partner : updatedPartner));
    await partnerApi
      .updatePartner(window.localStorage.getItem('accessToken'), updatedPartner)
      .then(() => setPartners(newPartners))
      .catch((e) => console.log(e));

    handleCancel();
  };

  return (
    <PartnerForm
      editMode
      open={open}
      handleClickOpen={handleClickOpen}
      handleCancel={handleCancel}
      isImageDownloaded={isImageDownloaded}
      isDragActive={isDragActive}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      files={files}
      handleChange={handleChange}
      name={name}
      siret={siret}
      startContract={startContract}
      setStartContract={setStartContract}
      endContract={endContract}
      setEndContract={setEndContract}
      handleSubmit={handleSubmit}
    />
  );
}
