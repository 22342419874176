/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
/* eslint linebreak-style: ["error", "windows"] */
/* eslint-disable no-unused-vars, linebreak-style */
/* eslint-disable no-trailing-spaces  */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable  react/jsx-indent-props */
/* eslint-disable   react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-multi-spaces */
/* eslint-disable object-curly-spacing */
class MajorIncidentApi {

  async sendDocMajorIncident(majorIncidentId, sourceId, fileValues, accessToken) {
    const URL_CREATE_DOC = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/add-document`;
    const data = new FormData();
    fileValues.forEach((value, index) => {
      data.append(`asset${index}`, value.file);
      data.append(`name${index}`, value.name);
      data.append(`type${index}`, value.type);
      data.append(`extension${index}`, value.extension);
    });
    data.append('sourceId', sourceId);
    data.append('majorIncidentId', majorIncidentId);
    
    const resp = await fetch(URL_CREATE_DOC, {
      method: 'POST',
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return json;
  }
  
    async createMajorIncident(accessToken, serviceId, description, startDate, solution, tickets) {
      const ticketsId = tickets.map((ticket) => ticket.id)
      const urlCreateMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/create`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          description,
          service_id: serviceId,
          start_date: startDate,
          solution,
          tickets : ticketsId
        }),
      };
  
      const response = await fetch(urlCreateMajorIncident, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    async updateMajorIncident(accessToken, majorIncidentId, userId, field, value) {
      const urlUpdateMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/update`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          id: majorIncidentId,
          user_id: userId,
          [field]: value,
        }),
      };
  
      const response = await fetch(urlUpdateMajorIncident, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    async addTicketMajorIncident(accessToken, ticketId, majorIncidentId, userId) {
      const urlAddTicket = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets/add`;
  
      // console.log(majorIncidentId)
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          ticket_id: ticketId,
          major_incident_id: majorIncidentId,
          user_id: userId
        }),
      };
  
      const response = await fetch(urlAddTicket, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    async deleteTicketMajorIncident(accessToken, ticketId, majorIncidentId, userId) {
      const urlDeleteTicket = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets/remove`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          ticket_id: ticketId,
          major_incident_id: majorIncidentId,
          user_id: userId
        }),
      };
  
      const response = await fetch(urlDeleteTicket, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    async searchTicketsMajorIncident(accessToken, searchParams) {
      const urlSearchTickets = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/search-tickets`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(searchParams),
      };
  
      const response = await fetch(urlSearchTickets, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite : ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    async getMajorIncidentsService(accessToken, serviceName) {
      const urlGetMajorIncidents = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          service_name: serviceName
        }),
      };
  
      const response = await fetch(urlGetMajorIncidents, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite : ${response.status}`;
        return new Error(message);
      }
      const json = await response.json();
  
      return Promise.resolve(json.data);
    }
  
    async getMajorIncidentById(accessToken, majorIncidentId) {
      const urlGetMajorIncidentById = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/details`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          major_incident_id: majorIncidentId
        }),
      };
  
      const response = await fetch(urlGetMajorIncidentById, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite : ${response.status}`;
        return new Error(message);
      }
      const json = await response.json();
  
      return Promise.resolve(json);
    }

  
    async getTicketsByMajorIncident(accessToken, majorIncidentId) {
      const urlTicketsMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          id: majorIncidentId
        }),
      };
  
      const response = await fetch(urlTicketsMajorIncident, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    async getMajorIncidentLogs(accessToken, majorIncidentId) {
      const urlMajorIncidentLogs = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/logs`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          majorIncidentId
        }),
      };
  
      const response = await fetch(urlMajorIncidentLogs, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    // async getTicketSuggestions(accessToken, serviceId) {
    //   const urlInitialTickets = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/tickets/suggestions`;
  
    //   const options = {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       Authorization: `Bearer ${accessToken}`
    //     },
    //     body: JSON.stringify({
    //       service_id: serviceId
    //     }),
    //   };
  
    //   const response = await fetch(urlInitialTickets, options);
  
    //   if (!response.ok) {
    //     const message = `Une erreur s'est produite: ${response.status}`;
    //     return new Error(message);
    //   }
  
    //   return response.json();
    // }
  
    async deleteMajorIncident(accessToken, majorIncidentId, userId) {
      const urlDeleteMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/delete`;
  
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          majorIncidentId,
          userId
        }),
      };
  
      const response = await fetch(urlDeleteMajorIncident, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
    }
  
    async solveMajorIncident(accessToken, majorIncidentId, userId, solution) {
      const urlSolveMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/solve`;
      const values = {};
      values.majorIncidentId = majorIncidentId;
      values.userId = userId;
      values.solution = solution;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(values),
      };
  
      const response = await fetch(urlSolveMajorIncident, options);
  
      if (!response.ok) {
        const message = `Une erreur s'est produite: ${response.status}`;
        return new Error(message);
      }
  
      return response.json();
  }
  
  async reopenMajorIncident(accessToken, majorIncidentId, userId) {
    const urlReopenMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/reopen`;
    const values = {};
    values.majorIncidentId = majorIncidentId;
    values.userId = userId;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(values),
    };
  
    const response = await fetch(urlReopenMajorIncident, options);
  
    if (!response.ok) {
      const message = `Une erreur s'est produite: ${response.status}`;
      return new Error(message);
    }
  
    return response.json();
  }
  
  async getMajorIncidentSolution(accessToken, majorIncidentId) {
    const urlGetSolution = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/solution/get`;
    const values = {};
    values.majorIncidentId = majorIncidentId;
    const resp = await fetch(urlGetSolution, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
  
    return Promise.resolve(json.solution);
  }
  
  async getMajorIncidentDocuments(accessToken, majorIncidentId) {
    const urlMajorIncident = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/get-document`;
    const values = {};
    values.majorIncidentId = majorIncidentId;
    const resp = await fetch(urlMajorIncident, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
  
    // console.log(json.data);
    return Promise.resolve(json.data);
  }
  
  async deleteMajorIncidentDocument(accessToken, docId, majorIncidentId) {
    const urlTicket = `${process.env.REACT_APP_API_URL}/api/quality/major-incidents/delete-document`;
    const values = {};
    values.docId = docId;
    values.majorIncidentId = majorIncidentId;
    const resp = await fetch(urlTicket, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });
    const json = await resp.json();
  
    // console.log(json.data);
    return Promise.resolve(json.data);
  }
  
  }
  
  
  
export const majorIncidentApi = new MajorIncidentApi();
