/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-body-style */
/* eslint-disable no-lonely-if */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-arrow-callback */
import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@material-ui/core/Slide';
import PartnerDeliveries from './PartnerDeliveries';
import { useCallback, useState, useEffect, useContext } from 'react';
import { deliveryApi } from '../../api/partnerApi';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { getListOfYearsDistinct, getYearFromDateString } from '../../utils/dateFormatting';

import { DeliveriesContext } from '../../contexts/PartnerContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function DeliveriesDialog(props) {
  const [open, setOpen] = useState(false);

  const { deliveries, setDeliveries } = useContext(DeliveriesContext);

  const [filteredDeliveries, setFilteredDeliveries] = useState([]);

  const [totalPartner, setTotalPartner] = useState(0);

  const [totalApteed, setTotalApteed] = useState(0);

  const [updateDeliveriesFormValues, setUpdateDeliveriesFormValues] = useState(false);

  const { partner } = props;

  const [yearFilter, setYearFilter] = useState('all');

  const [dates, setDates] = useState([]);

  const handleYear = (event) => {
    setYearFilter(event.target.value);
  };

  const getDeliveries = useCallback(async (partnerId) => {
    try {
      const deliveriesData = await deliveryApi.getDeliveriesByPartner(window.localStorage.getItem('accessToken'), partnerId);
      setDeliveries(deliveriesData.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getTotalPrices = () => {
    let totalP = 0;
    let totalA = 0;
    setTotalPartner(totalP);
    setTotalApteed(totalA);
    for (let i = 0; i < filteredDeliveries.length; i++) {
      if (filteredDeliveries[i].destination == true) {
        if (filteredDeliveries[i].price == '') { filteredDeliveries[i].price = 0; }
        totalP += parseFloat(filteredDeliveries[i].price);

        setTotalPartner(totalP);
      } else {
        if (filteredDeliveries[i].destination == false) {
          if (filteredDeliveries[i].price == '') { filteredDeliveries[i].price = 0; }
          totalA += parseFloat(filteredDeliveries[i].price);
          setTotalApteed(totalA);
        }
      }
    }
  };

  useEffect(() => {
    getDeliveries(partner.id);
  }, [partner.id]);

  useEffect(() => {
    if (deliveries?.length > 0) {
      const deliveriesDates = deliveries.map((delivery) => {
        return delivery.start_delivery;
      });
      const years = getListOfYearsDistinct(deliveriesDates);
      setDates(years.sort());
      if (yearFilter === 'all') {
        setFilteredDeliveries(deliveries);
        setUpdateDeliveriesFormValues(!updateDeliveriesFormValues);
      } else {
        const filtered = deliveries.filter((delivery) => {
          return getYearFromDateString(delivery.start_delivery) == yearFilter;
        });
        setFilteredDeliveries(filtered);
        setUpdateDeliveriesFormValues(!updateDeliveriesFormValues);
      }
    } else {
      setFilteredDeliveries([]);
    }
  }, [deliveries, yearFilter]);

  useEffect(() => {
    if (filteredDeliveries.length > 0) {
      getTotalPrices();
    } else {
      setTotalPartner(0);
      setTotalApteed(0);
      setYearFilter('all');
      setDates([]);
    }
  }, [filteredDeliveries]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
      >
        Voir les prestations
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            >
              Les prestations avec {partner.name}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid
          container
          sx={{ mt: 6, pl: 6 }}
        >
          <Grid
            item
            xs={6}
            display="inline"
            sx={{ mb: 2 }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Année</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={yearFilter}
                label="Année"
                onChange={handleYear}
              >
                <MenuItem value="all">Toutes les années</MenuItem>
                {dates.map((date) => (
                  <MenuItem value={date}>{date}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={6}
            display="inline"
          >
            {(totalApteed !== 0 || totalPartner !== 0) && (
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
              >
                Total des prestations{' '}
                {totalPartner !== 0 && (
                  <span>
                    <b>vers {partner.name} :</b> {`${totalPartner}€`}
                    {totalPartner !== 0 && totalApteed !== 0 && ' /'}
                  </span>
                )}{' '}
                {totalApteed !== 0 && (
                  <span>
                    <b>vers Apteed :</b> {`${totalApteed}€`}
                  </span>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
        <PartnerDeliveries
          partnerId={partner.id}
          partnerName={partner.name}
          startContract={partner.start_contract}
          endContract={partner.end_contract}
          deliveries={deliveries}
          setDeliveries={setDeliveries}
          filteredDeliveries={filteredDeliveries}
          setFilteredDeliveries={setFilteredDeliveries}
          yearFilter={yearFilter}
          setYearFilter={setYearFilter}
          dates={dates}
          updateDeliveriesFormValues={updateDeliveriesFormValues}
        />
      </Dialog>
    </>
  );
}
