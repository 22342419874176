/* eslint-disable react/no-danger */
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { React, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ticketApi } from '../../../api/ticketApi';
import PropTypes from 'prop-types';
import useSettings from '../../../hooks/useSettings';
import { createCustomTheme } from '../../../theme';

function DeleteMajorIncident(props) {
  const { majorIncidentId, userId, majorIncidentDescription, majorIncidents, setMajorIncidents } = props;
  const [show, setShow] = useState(false);
  const { settings } = useSettings();
  const theme = createCustomTheme({
    ...settings
  });

  const handleShow = () => {
    setShow(false);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { setShow(false); }
  };

  const deleteMajorIncident = async () => {
    try {
      const updatedMajorIncidents = majorIncidents.filter((majorIncident) => majorIncident.id !== majorIncidentId);
      setMajorIncidents(updatedMajorIncidents);
      setShow(false);
      await ticketApi.deleteMajorIncident(localStorage.getItem('accessToken'), majorIncidentId, userId);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          setShow(true)
        }}
      >
        <DeleteIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
      </IconButton>
      {show && (
        <Dialog
          open={show}
          fullWidth="md"
          onClose={handleClose}
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>
            Confirmation
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'center', justifyContent: 'center' }}>
              <Typography>
                Voulez-vous vraiment supprimer l&lsquo;incident majeur:
                {' '}
                <span style={{ fontWeight: 'bold' }}>{majorIncidentDescription}</span>
                {' '}
                ?
              </Typography>
            </Box>
          </DialogContent>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mx: 3, mb: 2 }}>
            <Button
              size="large"
              onClick={handleShow}
            >
              Annuler
            </Button>
            <Button
              size="large"
              onClick={deleteMajorIncident}
            >
              Supprimer
            </Button>
          </Box>
        </Dialog>
      )}
    </>
  );
}

export default DeleteMajorIncident;

DeleteMajorIncident.propTypes = {
  majorIncidentId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  majorIncidentDescription: PropTypes.string.isRequired,
  majorIncidents: PropTypes.array.isRequired,
  setMajorIncidents: PropTypes.func.isRequired
};
