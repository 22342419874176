/* eslint-disable dot-notation */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import { Card, Typography, Divider, TableRow, TableCell, TableContainer, Paper, Table, TableHead, TableBody, IconButton, Box } from '@material-ui/core';
import { useState, useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CampaignSource = (props) => {
  const { sourceProfession, professions } = props;

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState([]);

  useEffect(() => {
    if (professions !== undefined) {
      if (professions.length > 0) {
        const tempRows = [];
        const total = { id: 1000, name: 'Total' };
        professions.forEach((prof) => {
          total[prof.profession] = { general: 0 };
        });
        let subTotalHRowGeneral = 0;
        // eslint-disable-next-line array-callback-return
        Object.keys(sourceProfession).map((source) => {
          let row = { id: source, name: source };
          const totalHRow = { general: 0 };
          professions.forEach((profession) => {
            row[profession.profession] = sourceProfession[source][profession.profession] === undefined ? { general: 0 } : sourceProfession[source][profession.profession];
            total[profession.profession].general += sourceProfession[source][profession.profession] === undefined ? 0 : sourceProfession[source][profession.profession].general;
            totalHRow.general += sourceProfession[source][profession.profession] === undefined ? 0 : sourceProfession[source][profession.profession].general;
          });
          subTotalHRowGeneral += totalHRow.general;

          // partie historique
          const historic = {};
          Object.keys(sourceProfession[source].historique).forEach((date) => {
            historic[date] = sourceProfession[source]['historique'][date];
            let totalNumber = 0;
            Object.keys(sourceProfession[source]['historique'][date]).forEach((data) => {
              totalNumber += sourceProfession[source]['historique'][date][data].general;
            });
            historic[date].total = { general: totalNumber };
          });

          row = { ...row, totalH: totalHRow, historic };
          tempRows.push(row);
        });
        total.totalH = { general: subTotalHRowGeneral };
        tempRows.push(total);

        setRows(tempRows);
      }
    }
  }, [professions]);

  const handleOpen = (index) => {
    const tempOpen = [...open];
    tempOpen[index] = !tempOpen[index];
    setOpen(tempOpen);
  };

  return (
    <Card>
      <Typography
        variant="h6"
        sx={{ p: 2 }}
      >
        Nombre de prospects par profession et par statut
      </Typography>
      <TableContainer component={Paper}>
        <Table
          // sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Statut</TableCell>
              {professions.map((profession) => (
                <TableCell
                  align="right"
                >
                  {profession.profession}
                </TableCell>
              ))}
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <>
                <TableRow
                  key={row.name}
                  sx={{ '& > *': { borderBottom: 'unset' }, borderBottom: 'unset' }}
                >
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpen(index)}
                    >
                      {open[index] && row.name !== 'Total' ? <KeyboardArrowUpIcon /> : !open[index] && row.name !== 'Total' ? <KeyboardArrowDownIcon /> : ''}
                    </IconButton>
                  </TableCell>
                  {open[index] ? (
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <Typography
                        fontSize={24}
                        fontWeight="bold"
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <Typography>{row.name}</Typography>
                    </TableCell>
                  )}
                  {professions.map((profession) => <TableCell align="right">{row[profession.profession] === null ? 0 : row[profession.profession].general}</TableCell>)}
                  <TableCell align="right">{row.totalH.general === undefined ? 0 : row.totalH.general}</TableCell>
                </TableRow>
                <TableRow>
                  {row.name !== 'Total' && (
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={15}
                  >
                    <Collapse
                      in={open[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Historique
                        </Typography>
                        <Table
                          aria-label="purchases"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Niveau de réinjection</TableCell>
                              {professions.map((profession) => <TableCell align="right">{profession.profession}</TableCell>)}
                              <TableCell align="right">Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.historic !== undefined && (
                              Object.keys(row.historic).map((histoData) => (
                                <TableRow>
                                  <TableCell align="right">{histoData}</TableCell>
                                  {Object.keys(row.historic[histoData]).map((status) => (
                                    <TableCell align="right">{row.historic[histoData][status] === undefined && row.historic[histoData][status].general === null ? 0 : row.historic[histoData][status].general}</TableCell>
                                  ))}
                                </TableRow>
                              )))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                  )}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

CampaignSource.propTypes = {
  sourceProfession: PropTypes.array.isRequired,
  professions: PropTypes.object.isRequired,
};

export default CampaignSource;
