import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import * as React from 'react';
// import { useTheme } from '@material-ui/core/styles';
import ShiftStatistiqueFinished from '../../components/dashboard/shift/ShiftStatistiqueFinished';
import useAuth from '../../hooks/useAuth';
import ShiftStatistiqueNotFinished from '../../components/dashboard/shift/ShiftStatistiqueNotFinished';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import ShiftedLearnersTable from './ShiftedLearnersTable';
import CustomersFollowUpTable from '../../components/dashboard/customer/CustomersFollowUpTable';

const Shift = () => {
  const { settings } = useSettings();
  // const theme = useTheme();
  const { user } = useAuth();

  const [tabs, setTabs] = React.useState('apprenants');

  const handleChange = (event, tab) => {
    setTabs(tab);
  };

  return (
    <>
      <Helmet>
        <title>Suivi des apprenants | APTEED</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Typography
              // color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                color="textPrimary"
                variant="h5"
              >
                Suivi des apprenants
              </Typography>
            </Grid>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              {user.access_level === 0 || user.id_service === 4 ? (
                <>
                  <Box sx={{ width: '100%', marginTop: '20px', marginLeft: '20px' }}>
                    <TabContext value={tabs}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab
                            label="Apprenants"
                            value="apprenants"
                          />
                          <Tab
                            label="À décaler"
                            value="shift"
                          />
                          <Tab
                            label="Statistiques"
                            value="stats"
                          />
                        </TabList>
                      </Box>
                      <TabPanel value="shift">
                        <ShiftedLearnersTable />
                      </TabPanel>

                      <TabPanel value="apprenants">
                        <CustomersFollowUpTable />
                      </TabPanel>

                      <TabPanel value="stats">
                        <Grid
                          item
                          xs={12}
                        >
                          <ShiftStatistiqueFinished />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <ShiftStatistiqueNotFinished />
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </>
              ) : (
                <Typography
                  sx={{ m: 2 }}
                >
                  Vous n&apos;avez pas le droit de voir cette page
                </Typography>
              )}

            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Shift;
