/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { getTodayDate } from '../../utils/dateFormatting';
import { useDropzone } from 'react-dropzone';
import AddPartnerForm from './AddPartnerForm';
import EditPartnerForm from './EditPartnerForm';
import { PartnersContext } from '../../contexts/PartnerContext';

export default function PartnerFormDialog(props) {
  const date = getTodayDate();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [siret, setSiret] = useState('');
  const [startContract, setStartContract] = useState(date);
  const [endContract, setEndContract] = useState(date);
  const [isImageDownloaded, setImageDownloaded] = useState(false);
  const { partners, setPartners } = useContext(PartnersContext);
  const { partnerId, editMode } = props;

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();

  const handleDeleteImage = () => {
    acceptedFiles.length = 0;
    setImageDownloaded(false);
  };

  const files = acceptedFiles.map((file) => (
    <p key={file.path}>
      <b>Logo: </b>
      {file.path}
      <Button onClick={handleDeleteImage}>
        <DeleteIcon />
      </Button>
    </p>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    acceptedFiles.length = 0;
    setImageDownloaded(false);
    setName('');
    setSiret('');
    setOpen(false);
    if (!editMode) {
      setStartContract(date);
      setEndContract(date);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'name') {
      setName(e.target.value);
    } else if (e.target.name === 'siret') {
      setSiret(e.target.value);
    } else if (e.target.name === 'startContract') {
      setStartContract(e.target.value);
    } else if (e.target.name === 'endContract') {
      setEndContract(e.target.value);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setImageDownloaded(true);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (startContract > endContract) {
      setEndContract(startContract);
    }
  }, [startContract]);

  const handleEditClickOpen = () => {
    const partnerFound = partners.find((partner) => partnerId === partner.id);
    setName(partnerFound.name);
    setSiret(partnerFound.siret);
    setStartContract(partnerFound.start_contract);
    setEndContract(partnerFound.end_contract);
    setOpen(true);
  };

  return (
    <>
      {!editMode ? (
        <AddPartnerForm
          open={open}
          handleClickOpen={handleClickOpen}
          handleCancel={handleCancel}
          isImageDownloaded={isImageDownloaded}
          isDragActive={isDragActive}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          files={files}
          handleChange={handleChange}
          name={name}
          siret={siret}
          startContract={startContract}
          setStartContract={setStartContract}
          endContract={endContract}
          setEndContract={setEndContract}
          partners={partners}
          setPartners={setPartners}
        />
      ) : (
        <EditPartnerForm
          open={open}
          handleClickOpen={handleEditClickOpen}
          handleCancel={handleCancel}
          isImageDownloaded={isImageDownloaded}
          isDragActive={isDragActive}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          files={files}
          handleChange={handleChange}
          name={name}
          siret={siret}
          startContract={startContract}
          setStartContract={setStartContract}
          endContract={endContract}
          setEndContract={setEndContract}
          partnerId={partnerId}
          partners={partners}
          setPartners={setPartners}
        />
      )}
    </>
  );
}
