/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { Avatar, Card, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SmsIcon from '@mui/icons-material/Sms';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import * as moment from 'moment';

const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

const CustomerRelanceComment = (props) => {
  const { createdAt, comment, source, id, handleDelete, qualification, recallDate, ...other } = props;

  return (
    <Card
      sx={{
        alignItems: 'center',
        display: 'flex',
        p: 2,
        '& + &': {
          mt: 2
        }
      }}
      {...other}
    >
      <Avatar
        sx={{
          color: 'common.white'
        }}
      >
        { qualification === 'Suivi - Mail' && (
        <MailOutlineIcon style={{ color: '#16B84E' }} />
        ) }
        { qualification === 'Suivi - SMS' && (
        <SmsIcon style={{ color: '#16B84E' }} />
        ) }
        { qualification === 'Suivi - Téléphone' && (
        <PhoneAndroidIcon style={{ color: '#16B84E' }} />
        ) }
        { qualification === 'Décalage' && (
        <CalendarTodayIcon style={{ color: 'white' }} />
        ) }
        { qualification === 'À rappeler' && (
        <PhoneIcon style={{ color: 'orange' }} />
        ) }
        { qualification === 'Souhaite un email' && (
        <MailOutlineIcon style={{ color: 'purple' }} />
        ) }
        { qualification === 'Annulation' && (
        <HighlightOffIcon style={{ color: '#ff5f52' }} />
        ) }
        { qualification === 'Va se connecter' && (
        <HourglassEmptyIcon style={{ color: '#ffe436' }} />
        ) }
        { qualification === 'Numéro incorrect' && (
        <PhoneDisabledIcon style={{ color: 'blue' }} />
        ) }
        { qualification === 'Pas répondu' && (
        <PhoneMissedIcon style={{ color: 'black' }} />
        ) }
      </Avatar>
      <Typography
        color="textPrimary"
        sx={{ ml: 2 }}
        variant="body2"
      >
        <Typography
          color="textPrimary"
          variant="subtitle2"
        >
          {source}
        </Typography>
        <Typography
          style={{ margin: 'auto', padding: 'auto' }}
          color={(qualification === 'Suivi - Mail' || qualification === 'Suivi - SMS' || qualification === 'Suivi - Téléphone') ? '#16B84E' : qualification === 'Annulation' ? '#ff5f52' : qualification === 'Décalage' ? '#888888' : qualification === 'À rappeler' ? 'orange' : qualification === 'Souhaite un email' ? '#9a68af' : qualification === 'Va se connecter' ? '#d7b52c' : qualification === 'Numéro incorrect' ? '#1E90FF' : qualification === 'Pas répondu' ? '#888888' : 'black'}
          variant="subtitle2"
        >
          {`${qualification} ${recallDate === undefined || recallDate === null ? '' : moment(recallDate).format('DD/MM/YYYY à H:mm')}`}
        </Typography>
        {comment}
      </Typography>
      <Typography
        color="textPrimary"
        sx={{ ml: 'auto' }}
        variant="caption"
      >
        {new Date(createdAt).toLocaleDateString('fr-FR', options)}
      </Typography>
      <IconButton
        onClick={() => handleDelete(id)}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </Card>
  );
};

CustomerRelanceComment.propTypes = {
  createdAt: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  qualification: PropTypes.string,
  recallDate: PropTypes.string
};

export default CustomerRelanceComment;
