/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import {
  Typography,
  Link,
  CircularProgress,
} from '@material-ui/core';
// import useAuth from '../../hooks/useAuth';
import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useMounted from '../../../hooks/useMounted';
import * as moment from 'moment';
import { relaunchApi } from '../../../api/relaunchApi';
import { ProgressBar } from '../session';
import Label from '../../Label';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, frFR } from '@mui/x-data-grid';

function renderProgress(params) {
  return <ProgressBar value={Number(params.value)} />;
}

const CustomersFollowUpFullScreenTable = (props) => {
  const { rows } = props;
  const mounted = useMounted();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  // const [customers, setCustomers] = useState([]);
  const [numberCustomers, setNumberCustomers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const [rows, setRows] = useState([]);
  const [clickedId, setClickedId] = useState(-1);

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  // const getCustomersForFollowUp = useCallback(async () => {
  //   try {
  //     const data = await relaunchApi.getCustomersForFollowUp(localStorage.getItem('accessToken'));
  //     if (data.length === 0) {
  //       throw new Error('Aucun apprenant dans le suivi');
  //     }
  //     if (mounted.current) {
  //       setCustomers(data.customers);
  //       setNumberCustomers(data.numberCustomers.number);
  //       setIsLoading(false);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [mounted]);

  // useEffect(() => {
  //   getCustomersForFollowUp();
  // }, [getCustomersForFollowUp]);

  // useEffect(async () => {
  //   setRows(customers.map((item) => ({
  //     id: item.email + item.name,
  //     customerId: item.id,
  //     formationId: item.formation_id,
  //     email: item.email,
  //     phone: item.phone,
  //     profession: item.profession,
  //     firstname: item.firstname,
  //     lastname: item.lastname,
  //     source: item.source,
  //     status: item.status,
  //     last_connection: item.last_connection !== null ? moment(item.last_connection).format('DD/MM/YYYY HH:mm') : '',
  //     start_date: item.start_date !== null ? moment(item.start_date).format('DD/MM/YYYY') : '',
  //     end_date: item.end_date !== null ? moment(item.end_date).format('DD/MM/YYYY') : '',
  //     start_relaunch_date: item.start_relaunch_date !== null ? moment(item.start_relaunch_date).format('DD/MM/YYYY') : '',
  //     first_turn_end_date: item.first_turn_end_date !== null ? moment(item.first_turn_end_date).format('DD/MM/YYYY') : '',
  //     progression: Math.round(item.progression),
  //     formation_name: item.name,
  //     real_price: item.real_price,
  //     civility: item.civility,
  //     action: item.action,
  //     step: item.step,
  //     duration: item.duration,
  //     sessionId: item.session_id,
  //     numberSession: item.number_session,
  //   })));
  // }, [customers]);

  const columns = [
    {
      field: 'civility',
      headerName: 'Civilité'
    },
    {
      field: 'firstname',
      headerName: 'Prénom',
      renderCell: (cellValues) => (
        <Link
          component={RouterLink}
          to={`/dashboard/customers/${cellValues.row.customerId}`}
          variant="subtitle2"
        >
          {cellValues.row.firstname}
        </Link>
      ),
      width: 300,
    },
    {
      field: 'lastname',
      headerName: 'Nom',
      renderCell: (cellValues) => (
        <Link
          component={RouterLink}
          to={`/dashboard/customers/${cellValues.row.customerId}`}
          variant="subtitle2"
        >
          {cellValues.row.lastname}
        </Link>
      ),
      width: 300,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      width: 200,
    },
    {
      field: 'formation_name',
      headerName: 'Nom de la formation',
      renderCell: (cellValues) => (
        (
          <Link
            sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
            component={RouterLink}
            to={`/dashboard/formations/${cellValues.row.formationId}`}
          >
            <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>
              {cellValues.row.formation_name}
            </Typography>
          </Link>
        )
      ),
      width: 400,
    },
    {
      field: 'programNumber',
      headerName: 'N° de programme',
      width: 150
    },
    {
      field: 'numberSession',
      headerName: 'N° de session',
      renderCell: (cellValues) => (
        (
          <Link
            sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
            component={RouterLink}
            to={`/dashboard/session/${cellValues.row.sessionId}`}
          >
            <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>
              {cellValues.row.numberSession}
            </Typography>
          </Link>
        )
      ),
      width: 100,
    },
    {
      field: 'start_date',
      headerName: 'Début de session',
      type: 'dateTime',
      editable: false,
      width: 160,
    },
    {
      field: 'end_date',
      headerName: 'Fin de session',
      type: 'dateTime',
      editable: false,
      width: 160,
    },
    {
      field: 'start_relaunch_date',
      headerName: 'Début relance',
      type: 'dateTime',
      editable: false,
      width: 160,
    },
    {
      field: 'status',
      headerName: 'Statut',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.status === 'Inscrit') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'InscritD') {
          statut = (
            <Label
              color="inscritD"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'Décalage') {
          statut = (
            <Label
              InputLabelProps={{ style: { pointerEvents: 'auto' } }}
              color="secondary"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
          statut = (
            <Label
              color="error"
            >
              {cellValues.row.status}
            </Label>
          );
        }
        return (statut);
      },
      editable: false,
      width: 150,
    },
    {
      field: 'last_connection',
      headerName: 'Dernière connexion',
      type: 'dateTime',
      editable: false,
      width: 160,
    },
    {
      field: 'progression',
      headerName: 'Progression',
      renderCell: renderProgress,
      editable: false,
      width: 160,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.action === 'Relance Tour 1') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.action}
            </Label>
          );
        } else if (cellValues.row.action === 'Relance Tour 2') {
          statut = (
            <Label
              color="warning"
            >
              {cellValues.row.action}
            </Label>
          );
        } else if (cellValues.row.action === 'Décalage') {
          statut = (
            <Label
              InputLabelProps={{ style: { pointerEvents: 'auto' } }}
              color="secondary"
            >
              {cellValues.row.action}
            </Label>
          );
        }
        return (statut);
      },
      editable: false,
      width: 160,
    },
    {
      field: 'first_turn_end_date',
      headerName: 'Fin de tour clé',
      type: 'dateTime',
      editable: false,
      width: 160,
    },
    {
      field: 'step',
      headerName: 'Étape',
      width: 200,
    },
    {
      field: 'duration',
      headerName: 'Durée',
    },
    {
      field: 'real_price',
      headerName: 'Prix',
      valueFormatter: (params) => `${params.value} €`,
      editable: false,
      width: 100,
    },
    {
      field: 'profession',
      headerName: 'Profession',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.profession === 'Infirmier') {
          statut = (
            <Label
              color="profession"
            >
              {cellValues.row.profession}
            </Label>
          );
        } else if (cellValues.row.profession === 'Masseur-kinésithérapeute') {
          statut = (
            <Label
              color="warning"
            >
              {cellValues.row.profession}
            </Label>
          );
        } else if (cellValues.row.profession === 'Sage-Femme') {
          statut = (
            <Label
              InputLabelProps={{ style: { pointerEvents: 'auto' } }}
              color="secondary"
            >
              {cellValues.row.profession}
            </Label>
          );
        } else if (cellValues.row.profession === 'Pédicure-podologue') {
          statut = (
            <Label
              color="inscritD"
            >
              {cellValues.row.profession}
            </Label>
          );
        } else if (cellValues.row.profession === 'Pharmacien') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.profession}
            </Label>
          );
        } else if (cellValues.row.profession === 'Médecin') {
          statut = (
            <Label
              color="error"
            >
              {cellValues.row.profession}
            </Label>
          );
        }
        return (statut);
      },
      width: 200,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 200,
    },
  ];
  // const handleCustomers = (async (pageChoice, limitChoice) => {
  //   try {
  //     setIsLoading(true);
  //     const data = await relaunchApi.getCustomersForFollowUp(localStorage.getItem('accessToken'), pageChoice, limitChoice);
  //     setCustomers(data.customers);
  //     setNumberCustomers(data.numberCustomers.number);
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // });

  // const handlePageChange = (event, newPage) => {
  //   setPage(newPage);
  //   handleCustomers(newPage, limit);
  // };

  // const handleLimitChange = (event) => {
  //   setLimit(parseInt(event.target.value, 10));
  //   setPage(0);
  //   handleCustomers(page, parseInt(event.target.value, 10));
  // };

  return (
    <DataGrid
      density="confortable"
      localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
      initialState={{
        sorting: {
          sortModel: [{ field: 'lastname', sort: 'asc' }],
        },
      }}
      autoHeight
      rowHeight={70}
      rows={rows}
      columns={columns}
      pageSize={25}
      rowsPerPageOptions={[5]}
      components={{ Toolbar: CustomToolbar }}
    />
  );
};

CustomersFollowUpFullScreenTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default CustomersFollowUpFullScreenTable;
