/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Typography, Box, TextField, Link, Skeleton, Divider, Card } from '@material-ui/core';
import { useState, useCallback, useEffect } from 'react';
import useMounted from '../../../hooks/useMounted';
import { shiftApi } from '../../../api/shiftApi';

const ShiftStatistiqueNotFinished = () => {
  const mounted = useMounted();
  const [loading, setLoading] = useState(true);
  const [statsInformations, setStatsInformations] = useState([]);

  const getSessions = useCallback(async () => {
    try {
      const data = await shiftApi.getShiftStatsNotFinished(localStorage.getItem('accessToken'));

      if (mounted.current) {
        setStatsInformations(data);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getSessions();
  }, [getSessions]);

  const rows = [];
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  // turnover et nombre total par statut
  const totalTurnoverByMonth = {};
  const totalNumberByMonth = {};

  months.forEach((month) => {
    // initialisation des turnovers et nombres par mois
    totalTurnoverByMonth[month] = 0;
    totalNumberByMonth[month] = 0;
  });

  statsInformations.forEach((item) => {
    const row = {
      id: item.status,
      statusFinished: item.status,
    };

    row.turnoverTotal = 0;
    row.numberTotal = 0;

    // calcul du turnover par ligne total
    let totalTurnoverByRow = 0;

    months.forEach((month) => {
      totalTurnoverByRow += parseFloat(item[`turnover${month}`]);
    });
    // parcourir months pour faire correspondre ce qui revient de bdd avec les variables dynamiques
    months.forEach((month) => {
      row[`number${month}`] = item[`number${month}`];
      row[`turnover${month}`] = item[`turnover${month}`] === null ? parseFloat(0).toFixed(2) : parseFloat(item[`turnover${month}`]).toFixed(2);
      totalTurnoverByMonth[month] += item[`turnover${month}`] === null ? 0 : item[`turnover${month}`];
      totalNumberByMonth[month] += item[`number${month}`];
      row.numberTotal += item[`number${month}`];
      row.turnoverTotal += item[`turnover${month}`];
    });
    rows.push(row);
  });

  // ajout de la ligne statistique globale
  if (statsInformations.length > 0) {
    const row = {
      id: 'total',
      statusFinished: 'Total',
    };
    // calcul du turnover par ligne total de la ligne statistique
    row.numberTotal = 0;
    row.turnoverTotal = 0;
    // parcourir status pour faire correspondre ce qui revient de bdd avec les variables dynamiques
    months.forEach((month) => {
      row[`number${month}`] = totalNumberByMonth[month];
      row[`turnover${month}`] = parseFloat(totalTurnoverByMonth[month]).toFixed(2);
      row.numberTotal += totalNumberByMonth[month];
      row.turnoverTotal += totalTurnoverByMonth[month];
    });
    rows.push(row);
  }

  // Erreur avec le react/destructuring-assignment donc désactivation d'eslint
  const columns = [
    { field: 'statusFinished',
      headerName: 'Statut',
      renderCell: (cellValues) => (
        <Typography
          style={{ margin: 'auto' }}
          sx={{ whiteSpace: 'pre-line', fontWeight: cellValues.row.statusFinished === 'Total' ? 'bold' : 'normal', fontSize: 14 }}
        >
          <span>{cellValues.row.statusFinished}</span>
        </Typography>
      )
    }
  ];

  months.forEach((month) => {
    columns.push({
      field: `turnover${month}`,
      headerName: month,
      width: 110,
      renderCell: (cellValues) => (
        <Typography
          style={{ margin: 'auto' }}
          sx={{ whiteSpace: 'pre-line', fontWeight: cellValues.row.statusFinished === 'Total' ? 'bold' : 'normal' }}
        >
          <span>{`${cellValues.row[`turnover${month}`]} €\n (${cellValues.row[`number${month}`]})`}</span>
        </Typography>
      ),
      sortComparator: (v1, v2) => v1 - v2
    });
  });
  columns.push({ field: ('turnoverTotal'),
    headerName: 'Total',
    width: 130,
    renderCell: (cellValues) => (
      <Typography
        style={{ margin: 'auto' }}
        sx={{ whiteSpace: 'pre-line', fontWeight: 'bold' }}
      >
        <span>{`${parseFloat(cellValues.row.turnoverTotal).toFixed(2)} €\n (${cellValues.row.numberTotal})`}</span>
      </Typography>
    ),
    sortComparator: (v1, v2) => v1 - v2 });

  return (
    <Card sx={{ mt: 1 }}>
      <Typography
        color="textSecondary"
        variant="h4"
        sx={{ ml: 3, p: 1 }}
      >
        Détails autres statuts
      </Typography>
      <Divider />
      {!loading ? (
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
          autoHeight
          rowHeight={70}
          rows={rows}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[50, 100]}
          components={{ Toolbar: GridToolbar }}
        />
      ) : (
        <>
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
        </>
      )}
    </Card>
  );
};

export default ShiftStatistiqueNotFinished;
