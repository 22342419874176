/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  createFilterOptions,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  TableCell,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DataGrid, GridActionsCellItem, frFR, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import * as Yup from 'yup';
import { Formik } from 'formik';
import * as moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import toast from 'react-hot-toast';
import { pdf } from '@react-pdf/renderer';
import PDFMerger from 'pdf-merger-js';
import * as XLSX from 'xlsx';
import useAuth from '../../hooks/useAuth';
import { sessionApi } from '../../api/sessionApi';
import AttestationPDF from '../../components/AttestationPDF';
import Label from '../../components/Label';
import { ProgressBar } from '../../components/dashboard/session';
import { dashboardApi } from '../../api/dashboardApi';
import { shiftApi } from '../../api/shiftApi';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ListItemButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import numeral from 'numeral';

import ErrorIcon from '@mui/icons-material/Error';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WarningIcon from '@mui/icons-material/Warning';

const detailStyles = {
  borderTop: '2px solid',
  borderTopColor: 'primary.main',
  pt: 2,
  pb: 2,
  whiteSpace: 'pre-line',
};

function renderProgress(params) {
  return <ProgressBar value={Number(params.value)} />;
}

const ShiftedLearnersTable = () => {
  const [customersList, setCustomersList] = useState([]);
  const [session, setSession] = useState({});
  const [customers, setCustomers] = useState([]);
  const [rows, setRows] = useState([]);
  const [exporting, setExporting] = useState(false);
  const [show, setShow] = useState(false);
  const [showDec, setShowDec] = useState(false);
  const [currentIdDec, setCurrentIdDec] = useState(0);
  const { user } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const [sources, setSources] = useState([]);
  const [amounts, setAmounts] = useState([]);

  const [clickedId, setClickedId] = useState(-1);

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  const filterOptions = createFilterOptions({
    limit: 100,
    stringify: (option) => `${option.email} ${option.firstname} ${option.lastname}`,
  });

  const handleShow = () => {
    setShow(!show);
  };

  // initialisation de la liste d'apprenants
  useEffect(async () => {
    console.clear();
    const data = await dashboardApi.getInfos(localStorage.getItem('accessToken'));
    setSources(data.sources);
  }, []);

  // initialisation de la liste d'apprenants
  useEffect(async () => {
    const data = await shiftApi.getShiftCustomersList(localStorage.getItem('accessToken'));
    setRows(data[0].customers.map((item) => ({
      id: item.learner_session_id,
      customerId: item.customer_id,
      customer: item.customer,
      email: item.email,
      adeli_rpps: item.adeli_rpps,
      profession: item.profession,
      firstname: item.firstname,
      lastname: item.lastname,
      source: item.source,
      status: item.status,
      sessionId: item.session_id,
      primary_connection: item.primary_connection !== null ? moment(item.primary_connection).format('DD/MM/YYYY') : '',
      last_connection: item.last_connection !== null ? moment(item.last_connection).format('DD/MM/YYYY') : '',
      start_date: item.start_date !== null ? moment(item.start_date).format('DD/MM/YYYY') : '',
      end_date: item.end_date !== null ? moment(item.end_date).format('DD/MM/YYYY') : '',
      progression: Math.round(item.totalProgress),
      program_number: item.program_number,
      formation_name: item.formation_name,
      formation_id: item.formation_id,
      real_price: item.real_price,
      sourceId: item.source_id,
    })));

    // // rcupération des chiffres par status
    setAmounts(data[1].amounts[0]);
    console.clear();
    // console.log(amounts);
  }, [customers]);

  const handleChange = async (params) => {
    if (params.field === 'primary_connection' || params.field === 'last_connection') {
      params.value = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
    }
    if (params.field === 'real_price') {
      params.value = parseFloat(params.value).toFixed(3);
    }
    if (params.value === 'Décalage') {
      // On met la modale du choix de la session décalée à true
      setShowDec(true);
      setCurrentIdDec(params.id);
    } else {
      try {
        await sessionApi.editCustomer(2, params.id, params.value, localStorage.getItem('accessToken'), params.field);
      } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de la modification !');
      }
    }
  };

  const columns = [
    {
      field: '+',
      // eslint-disable-next-line no-unused-expressions
      renderCell: (cellValues) => (<IconButton onClick={() => { clickedId === cellValues.row.id ? setClickedId(-1) : setClickedId(cellValues.row.id); }}>{cellValues.row.id === clickedId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>),
      width: 60,
      sortable: false
    },
    {
      field: 'firstname',
      headerName: 'Prénom et Nom',
      renderCell: (cellValues) => (
        <Tooltip
          followCursor
          enterDelay={500}
          placement="top"
        >
          <Link
            component={RouterLink}
            to={`/dashboard/customers/${cellValues.row.customerId}`}
            variant="subtitle2"
          >
            {`${cellValues.row.firstname} ${cellValues.row.lastname}`}
          </Link>
        </Tooltip>
      ),
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Statut',
      type: 'singleSelect',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.status === 'Inscrit') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'En attente' || cellValues.row.status === 'À transférer') {
          statut = (
            <Label
              color="warning"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'InscritD') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={500}
              color="inscritD"
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  color="inscritD"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === 'Décalage') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={500}
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                  color="secondary"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === '- de 5 personnes' || cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
          statut = (
            <Label
              color="error"
            >
              {cellValues.row.status}
            </Label>
          );
        }
        return (statut);
      },
      editable: true,
      valueOptions: ['Inscrit', 'En attente', 'Désinscrit', 'Décalage', '- de 5 personnes', 'Annulation', 'À transférer'],
      width: 150,
    },
    {
      field: 'start_date',
      headerName: 'Début de session',
      type: 'dateTime',
      editable: user.access_level === 0,
      width: 160,
    },
    {
      field: 'end_date',
      headerName: 'Fin de session',
      type: 'dateTime',
      editable: user.access_level === 0,
      width: 160,
    },
    {
      field: 'progression',
      headerName: 'Progression',
      renderCell: renderProgress,
      editable: user.access_level === 0,
      width: 160,
    },
    {
      field: 'real_price',
      headerName: 'Prix',
      valueFormatter: (params) => `${params.value} €`,
      editable: user.access_level === 0,
      width: 100,
    },
    {
      field: 'formation_name',
      headerName: 'Détails',
      type: 'singleSelect',
      renderCell: (cellValues) => (
        (
          <div>
            <div>
              <Link
                sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
                component={RouterLink}
                to={`/dashboard/formations/${cellValues.row.id}`}
              >
                <Typography style={{ fontWeight: 'normal' }}>
                  {cellValues.row.formation_name}
                </Typography>
              </Link>
            </div>
            <Collapse in={cellValues.id === clickedId}>
              <Box sx={detailStyles}>
                <span style={{ fontWeight: 'bold' }}>
                  Numéro du programme :
                  {' '}
                  {cellValues.row.program_number}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Source :
                  {' '}
                  {cellValues.row.source}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Date de première connexion :
                  {' '}
                  {cellValues.row.primary_connection}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Date de dernière connexion :
                  {' '}
                  {cellValues.row.last_connection}
                  {' '}
                </span>
                <br />
              </Box>
            </Collapse>
          </div>
        )
      ),
      width: 400,
    },
    // {
    //   field: 'formation_name',
    //   headerName: 'Formation',
    //   renderCell: (cellValues) => (
    //     <Box
    //       sx={{
    //         alignItems: 'center',
    //         display: 'flex',
    //       }}
    //     >
    //       <Tooltip
    //         followCursor
    //         enterDelay={500}
    //       >
    //         <Link
    //           sx={{ whiteSpace: 'normal', width: 450, fontWeight: 600, fontSize: 15 }}
    //           component={RouterLink}
    //           to={`/dashboard/formations/${cellValues.row.formation_id}`}
    //           variant="subtitle2"
    //         >
    //           {cellValues.row.formation_name}
    //         </Link>
    //       </Tooltip>
    //     </Box>
    //   ),
    //   width: 500,
    //   sx: {
    //     alignItems: 'center',
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     flexWrap: 'wrap'
    //   },
    // },
    // {
    //   field: 'program_number',
    //   headerName: 'Numéro du Programme',
    //   type: 'singleSelect',
    //   width: 200,
    // },
    // {
    //   field: 'primary_connection',
    //   headerName: 'Première connexion',
    //   editable: user.access_level === 0,
    //   type: 'dateTime',
    //   width: 160,
    // },
    // {
    //   field: 'last_connection',
    //   headerName: 'Dernière connexion',
    //   type: 'dateTime',
    //   editable: user.access_level === 0,
    //   width: 160,
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => [
        <TableCell align="right">
          <IconButton
            component={RouterLink}
            to={`/dashboard/session/${params.row.sessionId}`}
          >
            <ArrowRightAltIcon fontSize="small" />
          </IconButton>
        </TableCell>
      ],
    },
  ];

  return (
    <>
      {amounts !== null && (
        <>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: 'transparent',
                      mr: 1
                    }}
                    variant="rounded"
                  >
                    <Box
                      sx={{
                        animation: 'pulse ease 750ms infinite',
                        borderRadius: '50%',
                        p: 0.5,
                        '@keyframes pulse': {
                          '0%': {
                            boxShadow: 'none'
                          },
                          '100%': {
                            boxShadow: (theme) => `0px 0px 0px 6px ${alpha(theme.palette.primary.main, 0.1)}`
                          }
                        }
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'primary.main',
                          borderRadius: '50%',
                          height: 12,
                          width: 12
                        }}
                      />
                    </Box>
                  </Avatar>
                  <Typography variant="h5">
                    Inscrits :
                    {' '}
                    {numeral(amounts.Inscrit).format('0,000,000.00').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                      color: 'primary.main',
                      mr: 1
                    }}
                  >
                    <TimelapseIcon fontSize="small" />
                  </Avatar>
                  <Typography variant="h5">
                    InscritD :
                    {' '}
                    {numeral(amounts.InscritD).format('0,000,000.00').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1),
                      color: 'warning.main',
                      mr: 1
                    }}
                  >
                    <ErrorIcon fontSize="small" />
                  </Avatar>
                  <Typography variant="h5">
                    Désinscrits :
                    {' '}
                    {numeral(amounts.Désinscrit).format('0,000,000.00').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
                      color: 'error.main',
                      mr: 1
                    }}
                  >
                    <WarningIcon fontSize="small" />
                  </Avatar>
                  <Typography variant="h5">
                    Annulation :
                    {' '}
                    {numeral(amounts.Annulation).format('0,000,000.00').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      <Card>
        <Box>
          <DataGrid
            density="confortable"
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            initialState={{
              sorting: {
                sortModel: [{ field: 'customer', sort: 'asc' }],
              },
            }}
            autoHeight
            rowHeight={70}
            // eslint-disable-next-line consistent-return
            getRowHeight={({ id, densityFactor }) => {
              if (id === clickedId) {
                return 250 * densityFactor;
              }
            }}
            rows={rows}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5]}
            onCellEditCommit={handleChange}
            components={{ Toolbar: CustomToolbar }}
          />
        </Box>
      </Card>
    </>
  );
};

export default ShiftedLearnersTable;
