class LogApi {
//   async saveLogAllLogs(accessToken) {
//     const urlSession = `${process.env.REACT_APP_API_URL}/api/session/saveLogAllLogs`;

  //     const resp = await fetch(urlSession, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${accessToken}`
  //       }
  //     });

  //     const json = await resp.json();

  //     return json;
  //   }

  async saveLogAllLogs(sessionId, accessToken) {
    const urlSession = `${process.env.REACT_APP_API_URL}/api/sessions/saveLogAllLogs`;
    const resp = await fetch(urlSession, {
      method: 'POST',
      body: JSON.stringify({ sessionId }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getAllLogs(sort = null, user, category, type, customer, limit = 25, page = 0, accessToken) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/all/logs`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.customer = customer;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getLogsInfos(accessToken) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/logs/infos`;

    const resp = await fetch(urlLogs, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getLogs(accessToken, sort = null, startDate, endDate, user, category, type, customer, limit = 25, page = 0) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/all/logs/filtered`;
    const values = {};
    values.sort = sort;
    values.startDate = startDate;
    values.endDate = endDate;
    values.user = user;
    values.category = category;
    values.type = type;
    values.customer = customer;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getValuesFilters(accessToken, user, category, type, customer, startDate, endDate) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/logs/filters/values`;
    const values = {};
    values.user = user;
    values.category = category;
    values.type = type;
    values.customer = customer;
    values.startDate = startDate;
    values.endDate = endDate;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }
}

export const logApi = new LogApi();
