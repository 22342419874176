/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import {
  Box,
  TableCell,
  TableHead,
  Typography,
  TableBody,
  Divider,
  ThemeProvider,
  Link
} from '@material-ui/core';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { withStyles } from '@material-ui/core/styles';
import useMounted from '../../../hooks/useMounted';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import * as moment from 'moment';
import { relaunchApi } from '../../../api/relaunchApi';

const RelaunchRecallsList = () => {
  const { endDateRelaunch, state } = useParams();
  const mounted = useMounted();
  const [rows, setRows] = useState([]);

  const getRelaunchInfo = useCallback(async () => {
    try {
      const data = await relaunchApi.getRelaunchInfo(endDateRelaunch, state, localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }
      if (mounted.current) {
        setRows(data.recalls);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getRelaunchInfo();
  }, [getRelaunchInfo]);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.Navy,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 16,
    },
  }))(TableCell);
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  return (
    <ThemeProvider>
      <TableContainer
        sx={{ maxHeight: 355 }}
        component={Paper}
      >
        <Typography
          variant="h5"
          sx={{ ml: 3, p: 1 }}
        >
          Rendez-vous téléphonique
        </Typography>
        <Divider />
        <Table
          sx={{ minWidth: 700 }}
          aria-label="simple table"
        >
          <TableHead>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            />
            <TableRow>
              <StyledTableCell style={{ fontWeight: 700 }}>Par:</StyledTableCell>
              <StyledTableCell
                style={{ color: 'green', fontWeight: 700 }}
              >
                Nom
              </StyledTableCell>
              <StyledTableCell
                style={{ color: 'Teal', fontWeight: 700 }}
              >
                Commentaire
              </StyledTableCell>
              <StyledTableCell
                style={{ color: 'red', fontWeight: 700 }}
              >
                Date de rappel
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, color: moment(row.recall_date).unix() < moment().unix() ? 'GrayText' : '' }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                />
                <StyledTableCell
                  style={{ fontWeight: 600 }}
                >
                  <Link
                    component={RouterLink}
                    to={`/dashboard/customers/${row.id}`}
                  >
                    {row.firstname}
                    {' '}
                    {row.lastname}
                  </Link>
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontWeight: 600 }}
                >
                  {row.comment}
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontWeight: 600 }}
                  variant="h8"
                >
                  {moment(row.recall_date).format('DD/MM/YYYY, HH:mm:ss')}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default RelaunchRecallsList;
