/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Button, Dialog, Tooltip,
  DialogActions,
  DialogContent,
  Link,
  DialogContentText,
  CircularProgress,
  Box, } from '@material-ui/core';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { ProgressBar } from '../dashboard/session';
import { Link as RouterLink } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import useAuth from '../../hooks/useAuth';
import { useTheme } from '@material-ui/core/styles';

function renderProgress(params) {
  return <ProgressBar value={Number(params.value)} />;
}
const CampaignResultTable = (props) => {
  const { infoCampaign, setInfoCampaign, handleDeleteCampaign, campaignDelete, setCampaignDelete, setOpenDelete1, openDelete1, isLoading, ...other } = props;
  const { user } = useAuth();
  const theme = useTheme();

  const dialogDelete = (index) => {
    setOpenDelete1(true);
    setCampaignDelete(index);
  };

  const rows = [];

  infoCampaign.forEach((item, index) => {
    rows.push({ id: item.id,
      name: item.name,
      progression: parseFloat(item.progression, 10),
      start_date: item.start_date,
      end_date: item.end_date,
      commercials: item.commercials,
      type: item.type,
      indexId: index,

    });
  });
  // Erreur avec le react/destructuring-assignment donc désactivation d'eslint sur les lignes concernées
  const columns = [
    { field: 'name',
      headerName: 'Nom',
      cellClassName: 'super-app-theme--cell',
      width: 435,
      renderCell: (cellValues) => (
        <Link
          component={RouterLink}
          to={`/dashboard/campaign/${cellValues.row.id}`}
          sx={{ whiteSpace: 'normal',
            width: 310,
            fontWeight: 550,
          }}
        >
          {cellValues.row.name}
        </Link>
      ) },
    { field: 'type',
      headerName: 'Type',
      cellClassName: 'super-app-theme--cell',
      width: 150,
      renderCell: (cellValues) => (
        <Typography
          sx={{ whiteSpace: 'normal',
            width: 310,
            fontWeight: 550,
          }}
        >
          {cellValues.row.type}
        </Typography>
      ) },
    { field: 'progression', headerName: 'Progression', cellClassName: 'super-app-theme--cell', width: 170, renderCell: renderProgress },
    { field: 'start_date', headerName: 'Date de début', cellClassName: 'super-app-theme--cell', width: 160, renderCell: (cellValues) => (<Typography>{cellValues.row.start_date !== null ? moment(cellValues.row.start_date).format('DD/MM/YYYY') : ''}</Typography>) },
    { field: 'end_date', headerName: 'Date de fin', cellClassName: 'super-app-theme--cell', width: 160, renderCell: (cellValues) => (<Typography>{cellValues.row.end_date !== null ? moment(cellValues.row.end_date).format('DD/MM/YYYY') : ''}</Typography>) },
    {
      field: 'commercials',
      headerName: 'Commerciaux',
      cellClassName: 'super-app-theme--cell',
      textOverflow: 'ellipsis',
      width: 370,
      renderCell: (cellValues) => (
        <Tooltip
          followCursor
          enterDelay={500}
          placement="top"
          title={(
            <span style={{ whiteSpace: 'pre-line' }}>
              {' '}
              {cellValues.row.commercials}
            </span>
        )}
        >
          <Typography
            sx={{
              overflow: 'hidden', textOverflow: 'ellipsis', width: '20rem'
            }}

          >
            {cellValues.row.commercials}
          </Typography>
        </Tooltip>
      ),
    },
    { field: 'indexId',
      headerName: 'Actions',
      cellClassName: 'super-app-theme--cell',
      width: 100,
      renderCell: (cellValues) => (
        <Typography>
          <IconButton
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            disabled={user.access_level !== 0}
            onClick={() => dialogDelete(cellValues.row.indexId)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Typography>
      ) },
  ];
  return (

    <Card
      sx={{
        '& .super-app-theme--campagne': {
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: theme.palette.mode === 'light' ? 'white' : 'grey'
        },
        '& .super-app-theme--réinjection': {
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: theme.palette.mode === 'light' ? '#e7fff9' : '#2c3152'
        }
      }}
    >
      <Dialog
        open={openDelete1}
        onClose={() => setOpenDelete1(false)}
      >
        <DialogContent>
          <DialogContentText>
            {`Souhaitez-vous vraiment supprimer la campagne ${infoCampaign[campaignDelete] !== undefined ? infoCampaign[campaignDelete].name : ''} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteCampaign(campaignDelete)}
            color="primary"
          >
            Supprimer
          </Button>
          <Button
            onClick={() => setOpenDelete1(false)}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      {!isLoading ? (
        <DataGrid
          {... other}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          autoHeight
          rowHeight={80}
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[30, 100]}
          getRowClassName={(params) => `super-app-theme--${params.row.type}`}
          components={{ Toolbar: GridToolbar }}
        />
      ) : (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Card>
  );
};
CampaignResultTable.propTypes = {
  infoCampaign: PropTypes.object.isRequired,
  setInfoCampaign: PropTypes.func.isRequired,
  handleDeleteCampaign: PropTypes.func.isRequired,
  campaignDelete: PropTypes.object.isRequired,
  setCampaignDelete: PropTypes.func.isRequired,
  openDelete1: PropTypes.object.isRequired,
  setOpenDelete1: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default CampaignResultTable;
