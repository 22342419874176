/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useState, useCallback, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import professions from './professions';
import {
  Autocomplete,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import useMounted from '../../../hooks/useMounted';
import { customerApi } from '../../../api/customerApi';
import { useParams } from 'react-router';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import toast from 'react-hot-toast';

const CustomerProInfos = (props) => {
  const { profession, specialite, modeOfExercise, adeliRpps, numSecu, mdpMondpc, editing, mdpFifpl, remplacant, notCall, siret, etablissement, startRelaunchDate,
    // eslint-disable-next-line react/prop-types
    errors, handleBlur, handleChange, touched, values, setFieldValue, callTrack, callTrackRelance, callTrackRelanceList } = props;
  const modeOfExerciseLabel = ['Libéral', 'Salarié en centre de santé', 'Salarié en hôpital', 'Autre'];
  const callTrackLabel = ['Pas répondu', 'À rappeler', 'Numéro incorrect', 'Vente', 'Non - Déjà fait', 'Non - Pas intéressé', 'Attente'];
  const callTrackRelanceLabel = ['Pas répondu', 'À rappeler', 'Numéro incorrect', 'Va se connecter', 'Suivi - Téléphone', 'Suivi - Mail', 'Suivi - SMS', 'Décalage', 'Annulation'];
  const callLabel = ['Ami', 'Retraite', 'Autre'];
  const defaultProfession = { profession };
  const defaultSpecialite = specialite !== undefined ? specialite : '';
  const [specialiteDisabled, setSpecialiteDisabled] = useState(false);
  const [specialiteTab, setSpecialiteTab] = useState(['']);
  const { user } = useAuth();
  const startRelaunchDateToFr = startRelaunchDate !== null ? moment(startRelaunchDate).format('DD/MM/YYYY') : '';
  const mounted = useMounted();
  const [displayPasswords, setDisplayPasswords] = useState(false);
  // const { customerId } = useParams();
const [showCopyAdely, setShowAdeli] = useState(false);
  const getDisplayPasswordsCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getDisplayPasswordsCustomer(localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setDisplayPasswords(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getDisplayPasswordsCustomer();
  }, [getDisplayPasswordsCustomer]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Profession
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <Autocomplete
                  getOptionLabel={(option) => option.profession}
                  options={professions}
                  onChange={(e, value) => {
                    setFieldValue(
                      'profession',
                      value !== null ? value.profession : defaultProfession.profession
                    );
                    if (value !== null) {
                      if (value.specialites !== undefined) {
                        setSpecialiteTab(value.specialites);
                        setSpecialiteDisabled(false);
                      } else {
                        setSpecialiteTab(['']);
                        setFieldValue(
                          'specialite',
                          ''
                        );
                        setSpecialiteDisabled(true);
                      }
                    }
                  }}
                  defaultValue={defaultProfession}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.profession && errors.profession)}
                      fullWidth
                      helperText={touched.profession && errors.profession}
                      label="Profession"
                      name="profession"
                      required
                      variant="outlined"
                    />
                  )}
                />
              ) : profession}
            </Typography>
          </TableCell>
          <TableCell> </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Spécialité
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <Autocomplete
                  options={specialiteTab}
                  disabled={specialiteDisabled}
                  onChange={(e, value) => {
                    setFieldValue(
                      'specialite',
                      value !== null ? value : defaultSpecialite
                    );
                  }}
                  name="specialite"
                  defaultValue={defaultSpecialite}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.specialite && errors.specialite)}
                      fullWidth
                      helperText={touched.specialite && errors.specialite ? errors.specialite : 'Lorsque ce champ est grisé, sa valeur est vide.'}
                      label="Spécialité"
                      variant="outlined"
                    />
                  )}
                />
              ) : specialite}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Mode d&apos;exercice
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <Autocomplete
                  options={modeOfExerciseLabel}
                  onChange={(e, value) => {
                    setFieldValue(
                      'modeOfExercise',
                      value !== null ? value : modeOfExercise
                    );
                  }}
                  value={values.modeOfExercise}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.modeOfExercise && errors.modeOfExercise)}
                      fullWidth
                      helperText={touched.modeOfExercise && errors.modeOfExercise}
                      label="Mode d'exercise"
                      name="modeOfExercise"
                      variant="outlined"
                    />
                  )}
                />
              ) : modeOfExercise}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow onMouseEnter={() => {setShowAdeli(true);}} onMouseLeave={() => { setShowAdeli(false);}}>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Adeli / Rpps
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <TextField
                  error={Boolean(touched.adeliRpps && errors.adeliRpps)}
                  fullWidth
                  helperText={touched.adeliRpps && errors.adeliRpps}
                  label="Adeli / Rpps"
                  name="adeliRpps"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.adeliRpps}
                  variant="outlined"
                />
              ) : adeliRpps}
            </Typography>
          </TableCell>
          <TableCell> <IconButton style={{margin: '0px', padding: '0px', opacity: adeliRpps && showCopyAdely ? '1' : '0'}} onClick={() => {navigator.clipboard.writeText(adeliRpps); toast.success('Adeli / Rpps a été copié avec succès');}}>
                <ContentCopyIcon fontSize="small" style={{color: 'grey'}} />
                </IconButton></TableCell>
        </TableRow>
        {displayPasswords && (
        <>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Mot de passe MonDPC
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.mdpMondpc && errors.mdpMondpc)}
                    fullWidth
                    helperText={touched.mdpMondpc && errors.mdpMondpc}
                    label="Mot de passe MonDPC"
                    name="mdpMondpc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mdpMondpc}
                    variant="outlined"
                  />
                ) : mdpMondpc}
              </Typography>
            </TableCell>
            <TableCell> </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                Mot de passe FIFPL
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {editing ? (
                  <TextField
                    error={Boolean(touched.mdpFifpl && errors.mdpFifpl)}
                    fullWidth
                    helperText={touched.mdpFifpl && errors.mdpFifpl}
                    label="Mot de passe FIFPL"
                    name="mdpFifpl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mdpFifpl}
                    variant="outlined"
                  />
                ) : mdpFifpl}
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </>
        )}
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Remplaçant
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <Switch
                  defaultChecked
                  error={Boolean(touched.remplacant && errors.remplacant)}
                  helperText={touched.remplacant && errors.remplacant}
                  checked={values.remplacant}
                  onChange={handleChange}
                  name="remplacant"
                />
              ) : remplacant ? 'Oui' : 'Non'}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        {user.access_level === 0 && (
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Ne pas appeler
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <Autocomplete
                  options={callLabel}
                  onChange={(e, value) => {
                    setFieldValue(
                      'notCall',
                      value !== null ? value : ''
                    );
                  }}
                  value={values.notCall}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.notCall && errors.notCall)}
                      fullWidth
                      helperText={touched.notCall && errors.notCall}
                      name="notCall"
                      variant="outlined"
                    />
                  )}
                />
              ) : notCall}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        )}
        {user.access_level !== 0 && (
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Ne pas appeler
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {notCall}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        )}
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Siret
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <TextField
                  error={Boolean(touched.siret && errors.siret)}
                  fullWidth
                  helperText={touched.siret && errors.siret}
                  label="siret"
                  name="siret"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.siret}
                  variant="outlined"
                />
              ) : siret}
            </Typography>
          </TableCell>
   <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Établissement
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {etablissement}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Date de début de relance
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {editing ? (
                <TextField
                  error={Boolean(touched.startRelaunchDate && errors.startRelaunchDate)}
                  fullWidth
                  helperText={touched.startRelaunchDate && errors.startRelaunchDate}
                  label="Début de relance"
                  name="startRelaunchDate"
                  type="date"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.startRelaunchDate}
                  variant="outlined"
                  // pour éviter la superposition de jj/mm/aaaa et date de naissance dans le champ
                  InputLabelProps={{ shrink: true }}
                />
              ) : startRelaunchDateToFr}
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

CustomerProInfos.propTypes = {
  profession: PropTypes.string.isRequired,
  specialite: PropTypes.string.isRequired,
  modeOfExercise: PropTypes.string.isRequired,
  adeliRpps: PropTypes.string,
  numSecu: PropTypes.string,
  mdpMondpc: PropTypes.string,
  callTrack: PropTypes.string,
  callTrackRelance: PropTypes.string,
  callTrackRelanceList: PropTypes.array,
  editing: PropTypes.bool,
  touched: PropTypes.object,
  errors: PropTypes.object,
  values: PropTypes.object,
  mdpFifpl: PropTypes.string,
  remplacant: PropTypes.bool,
  notCall: PropTypes.string,
  siret: PropTypes.string,
  etablissement: PropTypes.string,
  startRelaunchDate: PropTypes.string,
};

export default CustomerProInfos;
