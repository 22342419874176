/* eslint-disable consistent-return */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import { Alert, Avatar, Box, Divider, Grid, Typography, Skeleton } from '@material-ui/core';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TimelineIcon from '@mui/icons-material/Timeline';
import numeral from 'numeral';
import { green, red } from '@mui/material/colors';
import { width } from '@material-ui/system';

const nullicon = <TimelineIcon sx={{ color: green[500], fontSize: 40 }} />;
const upicon = <TrendingUpIcon sx={{ color: green[500], fontSize: 40 }} />;
const downicon = <TrendingDownIcon sx={{ color: red[500], fontSize: 40 }} />;

// eslint-disable-next-line no-mixed-operators
const percentageVariation = (current, last) => {
  let res = 0;
  if (last > 0) res = parseFloat(((current - last) / last) * 100);
  else if (last === current) res = 0;
  else if (last === 0) res = 100;
  return (res % 2 ? res.toFixed(2) : res);
};

// eslint-disable-next-line react/prop-types
const StatsWidget = ({ loading, title, sales, lastSales, amount, icon, description }) => (
  <>
    <Box
      display="grid"
      gridTemplateColumns="repeat(12, 1fr)"
    >
      <Box gridColumn="span 12">
        {!loading ? (
          <Typography
            color="textSecondary"
            variant="overline"
          >
            {title}
          </Typography>
        ) : (
          <Skeleton
            variant="text"
            animation="wave"
            width={100}
          />
        )}
      </Box>
      <Box gridColumn="span 6">
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {!loading ? (
            <>
              <Typography
                color="textPrimary"
                variant="h5"
                mb={1}
              >
                {sales && sales}
              </Typography>
              &nbsp;
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Vente(s) comptabilisées
              </Typography>
            </>
          ) : (
            <Skeleton
              variant="text"
              animation="wave"
              width={100}
            />
          )}
        </Grid>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {!loading ? (
            <>
              <Typography
                color="textPrimary"
                variant="h6"
                xs={{ display: 'flex' }}
              >
                {amount && amount}
                &nbsp;
                €
              </Typography>
            </>
          ) : (
            <Skeleton
              variant="text"
              animation="wave"
              width={100}
            />
          )}
        </Grid>
      </Box>
      <Box
        gridColumn="span 6"
        justifyContent="right"
      >
        {!loading ? (
          <Grid
            container
            rowSpacing={1}
            mx={4}
            justifyContent="right"
          >
            <Grid
              container
              xs={12}
              sx={{ width: '100%' }}
            >
              <Typography
                color="textPrimary"
                noWrap
                variant="h4"
                justifyContent="right"
              >
                {percentageVariation(sales, lastSales) > 0
                  ? percentageVariation(sales, lastSales)
                  : (
                    percentageVariation(sales, lastSales) < 0
                      ? (-1 * percentageVariation(sales, lastSales))
                      : 0
                  )}
                %
                &nbsp;
              </Typography>
              {icon === 'upicon' && upicon}
              {icon === 'downicon' && downicon }
              {icon === 'nullicon' && nullicon }
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ width: '100%' }}
            >
              {/* <Alert icon={false} severity="success" sx={{ padding: '1px 5px' }}> */}
              <Typography
                color="textsecondary"
                variant="body2"
                display="block"
              >
                {description}
              </Typography>
              {/* </Alert> */}
            </Grid>
          </Grid>
        ) : (
          <Skeleton
            variant="text"
            animation="wave"
            width={100}
          />
        )}
      </Box>
    </Box>
  </>
);

export default StatsWidget;
