/* eslint-disable react/no-array-index-key */
import { Box, Card, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import Slide from "@mui/material/Slide"
import Stack from "@mui/material/Stack"
import { NavigateBefore, NavigateBefore as NavigateBeforeIcon, NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import TicketMiniCard from './TicketMiniCard'
import { set } from 'lodash';

function TicketsCarousel(props) {
    const { tickets, update, setUpdate, handleDelete } = props
    const [cards, setCards] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [slideDirection, setSlideDirection] = useState("left");
    const cardsPerPage = 4

    const handleNextPage = () => {
        setSlideDirection("left");
        setCurrentPage((prevPage) => prevPage + 1);
    }

    const handlePrevPage = () => {
        setSlideDirection("right");
        setCurrentPage((prevPage) => prevPage - 1);
    }

    const duplicateCards = Array.from(
        tickets,
        (_, i) => <TicketMiniCard 
                    key={i}  
                    ticketId={_.id} 
                    reference={_.reference}
                    title={_.category} 
                    description={_.description}
                    createdAt={_.created_at}
                    ticketState={_.state} 
                    handleDelete={handleDelete}
                />
    )

    useEffect(() => {
        setCards(duplicateCards)
        
        // if (update) {
        //     if ((cards.length + 1) % cardsPerPage) {
        //         handleNextPage()
        //     }
        // }
    }, [tickets])
    
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                height: "300px"
            }}
        >
            <IconButton
                onClick={handlePrevPage}
                sx={{
                    // margin: 5,
                    mr: 2,
                    display: currentPage === 0 && "none"
                }}
                disabled={currentPage === 0}
            >
                <NavigateBeforeIcon />
            </IconButton>
                {cards.map((card, index) => (
                    <Box
                        key={`card-${index}`}
                        sx={{
                            width: "100%",
                            height: "100%",
                            display: currentPage === index ? "block" : "none"
                        }}
                    >
                    <Slide direction={slideDirection} in={currentPage === index}>
                        <Stack
                            spacing={2}
                            direction="row"
                        >
                            {cards.slice(
                                index * cardsPerPage,
                                index * cardsPerPage + cardsPerPage
                            )}
                        </Stack>
                    </Slide>
                    </Box>
                ))}
            <IconButton
                onClick={handleNextPage}
                sx={{
                    ml: 2,
                    display: currentPage >= Math.ceil((cards.length || 0) / cardsPerPage) - 1 && "none"
                }}
            >
                <NavigateNextIcon />
            </IconButton>
        </Box>
    )
}

export default TicketsCarousel;

