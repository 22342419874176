/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Box, Card, CardHeader, Divider, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const CustomdetailsTab = ({ details, criteres, description }) => (
  <>
    <Box
      sx={{
        my: 3,
        px: 1,
      }}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            // mx: 'auto'
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CardHeader title="Informations Complémentaires" />
            <Divider />
            <List disablePadding>
              {details && details.map((item, i) => (
                <ListItem
                  divider={i < details.length - 1}
                  key={item.label}
                >
                  <ListItemText
                    primary={item.label}
                    primaryTypographyProps={{
                    }}
                  />
                  <Typography sx={{ width: '60%', textAlign: 'right' }}>
                    {Array.isArray(item.value)
                      ? item.value.join(', ')
                      : item.value}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            // mx: 'auto'
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pb: 2
            }}
          >
            <CardHeader title="Critères de sélection" />
            <Divider />
            <List disablePadding>
              {criteres && criteres.map((c) => (
                <ListItem>
                  { c.label }
                  {c.type === 'min_max' && ` ${c.values.min}  et  ${c.values.max}` }
                  {c.type === 'periode' && ` du ${c.values.startDate && moment(c.values.startDate).format('DD/MM/YYYY')}  au  ${c.values.endDate && moment(c.values.endDate).format('DD/MM/YYYY')}` }
                  {c.type === 'formation' && ` ${c.values.formation_name ? c.values.formation_name : '...................................'} ` }
                  {(c.type === 'session' && c.values.sessions.length > 0) && ` : ${c.values.sessions.join(' - ')} de la formation ${c.values.formation_name}` }
                  {(c.type === 'profession' || c.type === 'specialite' || c.type === 'region') && `  : ${c.values.join(', ')}` }
                  {(c.type === 'source') && `  : ${c.values.map((s) => `${s.firstname} ${s.lastname}`).join(', ')}` }
                </ListItem>
              ))}
            </List>
          </Card>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
              mt: 2
            }}
          >
            <CardHeader title="Description" />
            <Divider />
            <List disablePadding>
              <ListItem>
                { description || 'Non renseignée' }
              </ListItem>
            </List>
          </Card>
        </Grid>
      </Grid>
    </Box>
  </>
);

export default CustomdetailsTab;
