import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  Link,
  Rating,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const FormationPerformanceCard = (props) => {
  const { formation, ...other } = props;
  const theme = useTheme();
  const shortenTitle = (str, limit) => {
    const splitArr = str.split(" ");
    if (splitArr.length > limit) {
      let newStr = ``;
      for (let i = 0; i <= splitArr.length; i++) {
        if (limit > i) {
          newStr += `${splitArr[i]} `;
        }
        if (limit === i) {
          newStr += `${splitArr[i]} ...`;
        }
      }
      return newStr;
    }
    return str;
  }

  return (
    <Card {...other} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box sx={{ p: 3 }}>
        <CardMedia
          image={formation.image}
          sx={{
            backgroundColor: 'background.default',
            height: 200
          }}
        />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mt: 2
          }}
        >
          <Box sx={{ ml: 2 }}>
            <Link
              color="textPrimary"
              component={RouterLink}
              // to={`/dashboard/formations/${formation.course_id}?origin=performances`}
              to={{
                pathname: `/dashboard/formations/${formation.course_id}`,
                search: "?origin=performances"
              }}
              variant="h6"
            >
              {formation.name}
              {/* {shortenTitle(formation.name, 10)} */}
            </Link>
          </Box>
        </Box>
      </Box>
      <Box>

      <Box
        sx={{
          pb: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* <Typography
          color="textSecondary"
          variant="body2"
        >
          <Typography>{`${parseInt((Number(formation.global_average) / 20).toFixed(1), 10)} / 5`}</Typography>
          <Rating name="read-only" precision={0.1} value={parseInt((Number(formation.global_average) / 20).toFixed(1), 10)} readOnly />
        </Typography> */}
        <Typography sx={{ whiteSpace: 'normal', fontWeight: 600 }}>Taux global de progression :</Typography>
        <Typography sx={{ whiteSpace: 'normal', fontWeight: 600, }} style={{color: theme.palette.performanceGreen }}>{formation.learning_progress}%</Typography>
      </Box>
      <Box
        sx={{
          pb: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {/* <Typography
          color="textSecondary"
          variant="body2"
        >
          <Typography>{`${parseInt((Number(formation.global_average) / 20).toFixed(1), 10)} / 5`}</Typography>
          <Rating name="read-only" precision={0.1} value={parseInt((Number(formation.global_average) / 20).toFixed(1), 10)} readOnly />
        </Typography> */}
      <Typography sx={{ whiteSpace: 'normal', fontWeight: 600 }}>
              Nombre d&apos;apprenants inscrits :
            </Typography> 
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {formation.completed_learner}
            </Typography>
            
      </Box>
      <Divider />
      <Box
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {formation.pretest_scores}%
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Score préTest
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {formation.posttest_scores}%
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Score postTest
            </Typography>
          </Grid>
          {/* <Grid item>
            
          </Grid> */}
        </Grid>
      </Box>


      </Box>
    </Card>
  );
};

FormationPerformanceCard.propTypes = {
  formation: PropTypes.object.isRequired
};

export default FormationPerformanceCard;
