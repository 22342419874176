import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Grid,
  Link,
  Rating,
  Typography
} from '@material-ui/core';

const FormationSatisfactionCard = (props) => {
  const { formation, ...other } = props;

  const shortenTitle = (str, limit) => {
    const splitArr = str.split(" ");
    if (splitArr.length > limit) {
      let newStr = ``;
      for (let i = 0; i <= splitArr.length; i++) {
        if (limit > i) {
          newStr += `${splitArr[i]} `;
        }
        if (limit === i) {
          newStr += `${splitArr[i]} ...`;
        }
      }
      return newStr;
    }
    return str;
  }

  return (
    <Card {...other} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box sx={{ p: 3 }}>
        <CardMedia
          image={formation.image}
          sx={{
            backgroundColor: 'background.default',
            height: 200
          }}
        />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mt: 2
          }}
        >
          <Box>
            <Link
              color="textPrimary"
              component={RouterLink}
              // to={`/dashboard/formations/${formation.course_id}?origin=satisfactions`}
              to={{
                pathname: `/dashboard/formations/${formation.course_id}`,
                search: "?origin=satisfactions"
              }}
              variant="h6"
            >
              {formation.name}
              {/* {shortenTitle(formation.name, 10)} */}
            </Link>
          </Box>
        </Box>
      </Box>
      <Box>

      <Box
        sx={{
          pb: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography
          color="textSecondary"
          variant="body2"
        >
          <Typography>{`${(Number(formation.global_average) / 20).toFixed(1)} / 5`}</Typography>
          <Rating name="read-only" precision={0.1} value={(Number(formation.global_average) / 20).toFixed(1)} readOnly />
        </Typography>
        <Typography>
          <Typography sx={{ whiteSpace: 'normal', fontWeight: 600, textAlign: 'center' }}>{formation.global_average}%</Typography>
          <Typography
                color="textSecondary"
                variant="body2"
              >
                Moyenne globale
          </Typography>
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {formation.scientific_content}%
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Contenu scientifique
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {formation.platform_access}%
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Accès à la plateforme
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              {formation.support}%
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Accompagnement
            </Typography>
          </Grid>
        </Grid>
      </Box>


      </Box>
    </Card>
  );
};

FormationSatisfactionCard.propTypes = {
  formation: PropTypes.object.isRequired
};

export default FormationSatisfactionCard;
