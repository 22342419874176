/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import { Avatar, Box, Breadcrumbs, Button, Card, Typography, Grid, Link, CardContent, CardHeader, Divider, LinearProgress,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMounted from '../../../hooks/useMounted';
import { useCallback, useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import ChevronRightIcon from '../../../icons/ChevronRight';
import * as moment from 'moment';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import GroupsIcon from '@mui/icons-material/Groups';
import StackedLineChartIcon from '@material-ui/icons/StackedLineChart';
import { relaunchApi } from '../../../api/relaunchApi';
import RelaunchRecallsList from './RelaunchRecallsList';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import RelaunchLearners from './RelaunchLearners';
import RelaunchCustomersList from './RelaunchCustomersList';

const RelaunchInfo = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const mounted = useMounted();
  const { endDateRelaunch, state } = useParams();
  const [progressionProspects, setProgressionProspects] = useState(0);
  const [numberProspects, setNumberProspects] = useState([]);
  const [numberProspectsSession, setNumberProspectsSession] = useState([]);
  const [numberProspectsTreated, setNumberProspectsTreated] = useState(0);
  const [showStats, setShowStats] = useState(true);
  const [idNextCustomer, setIdNextCustomer] = useState(1);
  const [countCustomersByWeekAndQualification, setCountCustomersByWeekAndQualification] = useState([]);
  const [countCustomersList, setCountCustomersList] = useState([]);

  const getRelaunchInfo = useCallback(async () => {
    try {
      const data = await relaunchApi.getRelaunchInfo(endDateRelaunch, state, localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setNumberProspects(data.numberProspects);
        setNumberProspectsSession(data.numberProspectsSession);
        setProgressionProspects((data.numberProspectsTreated / (data.numberProspectsSession.number === 0 ? 1 : data.numberProspectsSession.number)) * 100);
        setNumberProspectsTreated(data.numberProspectsTreated);
        setIdNextCustomer(data.idNextCustomer);
        setCountCustomersByWeekAndQualification(data.countCustomersByWeekAndQualification);
        setCountCustomersList(data.countCustomersList);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getRelaunchInfo();
  }, [getRelaunchInfo]);

  const handleTabsChange = () => {
    setShowStats(!showStats);
  };

  const rows = {};
  // vérifier si countCustomersByWeekAndQualification objet est vide
  if (Object.keys(countCustomersByWeekAndQualification).length !== 0) {
    Object.keys(countCustomersByWeekAndQualification).map((el) => {
      let totalDecalage = 0;
      rows[el] = [];
      let totalSuiviTel = 0;
      let totalAnnulation = 0;
      let totalPasRep = 0;
      let totalARappeler = 0;
      let totalNumInc = 0;
      let totalVaSeCo = 0;
      let totalSuiviMail = 0;
      let totalSuiviSMS = 0;

      Object.keys(countCustomersByWeekAndQualification[el]).map((itemRow) => {
        const row = {
          Annulation: countCustomersByWeekAndQualification[el][itemRow].Annulation === null ? 0 : countCustomersByWeekAndQualification[el][itemRow].Annulation.called_nb,
          'Pas répondu': countCustomersByWeekAndQualification[el][itemRow]['Pas répondu'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Pas répondu'].called_nb,
          'À rappeler': countCustomersByWeekAndQualification[el][itemRow]['À rappeler'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['À rappeler'].called_nb,
          'Numéro incorrect': countCustomersByWeekAndQualification[el][itemRow]['Numéro incorrect'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Numéro incorrect'].called_nb,
          'Va se connecter': countCustomersByWeekAndQualification[el][itemRow]['Va se connecter'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Va se connecter'].called_nb,
          'Suivi - Téléphone': countCustomersByWeekAndQualification[el][itemRow]['Suivi - Téléphone'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Suivi - Téléphone'].called_nb,
          Décalage: countCustomersByWeekAndQualification[el][itemRow].Décalage === null ? 0 : countCustomersByWeekAndQualification[el][itemRow].Décalage.called_nb,
          'Suivi - Mail': countCustomersByWeekAndQualification[el][itemRow]['Suivi - Mail'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Suivi - Mail'].called_nb,
          'Suivi - SMS': countCustomersByWeekAndQualification[el][itemRow]['Suivi - SMS'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Suivi - SMS'].called_nb,
          id: parseInt(itemRow, 10)
        };
        totalDecalage += countCustomersByWeekAndQualification[el][itemRow].Décalage === null ? 0 : countCustomersByWeekAndQualification[el][itemRow].Décalage.called_nb;
        totalSuiviTel += countCustomersByWeekAndQualification[el][itemRow]['Suivi - Téléphone'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Suivi - Téléphone'].called_nb;
        totalAnnulation += countCustomersByWeekAndQualification[el][itemRow].Annulation === null ? 0 : countCustomersByWeekAndQualification[el][itemRow].Annulation.called_nb;
        totalPasRep += countCustomersByWeekAndQualification[el][itemRow]['Pas répondu'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Pas répondu'].called_nb;
        totalARappeler += countCustomersByWeekAndQualification[el][itemRow]['À rappeler'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['À rappeler'].called_nb;
        totalNumInc += countCustomersByWeekAndQualification[el][itemRow]['Numéro incorrect'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Numéro incorrect'].called_nb;
        totalVaSeCo += countCustomersByWeekAndQualification[el][itemRow]['Va se connecter'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Va se connecter'].called_nb;
        totalSuiviMail += countCustomersByWeekAndQualification[el][itemRow]['Suivi - Mail'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Suivi - Mail'].called_nb;
        totalSuiviSMS += countCustomersByWeekAndQualification[el][itemRow]['Suivi - SMS'] === null ? 0 : countCustomersByWeekAndQualification[el][itemRow]['Suivi - SMS'].called_nb;
        rows[el].push(row);
      });
      // ligne total
      const rowTotal = { id: 'Total' };
      rowTotal.Décalage = totalDecalage;
      rowTotal['Suivi - Téléphone'] = totalSuiviTel;
      rowTotal.Annulation = totalAnnulation;
      rowTotal['Suivi - SMS'] = totalSuiviSMS;
      rowTotal['Suivi - Mail'] = totalSuiviMail;
      rowTotal['Pas répondu'] = totalPasRep;
      rowTotal['À rappeler'] = totalARappeler;
      rowTotal['Numéro incorrect'] = totalNumInc;
      rowTotal['Va se connecter'] = totalVaSeCo;
      rows[el].push(rowTotal);
    });
  }

  const columns = [
    { field: 'id',
      headerName: 'N° semaine',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row.id}</Typography>),
      width: 157
    },
    { field: 'Annulation',
      headerName: 'Annulation',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row.Annulation}</Typography>),
      width: 157
    },
    { field: 'Pas répondu',
      headerName: 'Pas répondu',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row['Pas répondu']}</Typography>),
      width: 157
    },
    { field: 'À rappeler',
      headerName: 'À rappeler',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row['À rappeler']}</Typography>),
      width: 157
    },
    { field: 'Numéro incorrect',
      headerName: 'Numéro incorrect',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row['Numéro incorrect']}</Typography>),
      width: 157
    },
    { field: 'Va se connecter',
      headerName: 'Va se connecter',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row['Va se connecter']}</Typography>),
      width: 157
    },
    { field: 'Suivi - Téléphone',
      headerName: 'Suivi - Téléphone',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row['Suivi - Téléphone']}</Typography>),
      width: 157
    },
    { field: 'Suivi - Mail',
      headerName: 'Suivi - Mail',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row['Suivi - Mail']}</Typography>),
      width: 157
    },
    { field: 'Suivi - SMS',
      headerName: 'Suivi - SMS',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row['Suivi - SMS']}</Typography>),
      width: 157
    },
    { field: 'Décalage',
      headerName: 'Décalage',
      headerAlign: 'center',
      renderCell: (cellValues) => (<Typography style={{ margin: 'auto', fontSize: 14 }}>{cellValues.row.Décalage}</Typography>),
      width: 157
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        p: 2
      }}
    >
      <Grid>
        <Grid container>
          <Grid
            item
            md={10}
            xs={12}
            sx={{
              pt: 2, pl: 3
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              spacing={0}
              sx={{
                pl: 1,
              }}
            >
              <Typography
                color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                variant="h4"
              >
                Statistiques de la relance
              </Typography>
              {user.access_level === 0 && (
              <Button
                sx={{ mt: 2 }}
                variant="outlined"
                startIcon={<StackedLineChartIcon fontSize="small" />}
                indicatorColor="primary"
                onClick={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
              >
                {showStats === true ? 'Apprenants' : 'Statistiques'}
              </Button>
              )}
              {idNextCustomer !== null && user.access_level !== 0 && numberProspectsSession.number > 0 && idNextCustomer.called_nb === 0 && (
              <Button
                component={RouterLink}
                to={`/dashboard/relaunch/${state}/${endDateRelaunch}/customer/${idNextCustomer.id}`}
                color="primary"
                variant="contained"
                sx={{ mt: 1, mb: 3 }}
              >
                {
                  numberProspectsTreated !== 0 ? 'Continuer la relance' : 'Commencer la relance'
                }
              </Button>
              )}
            </Grid>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<ChevronRightIcon fontSize="small" />}
              sx={{ mt: 1 }}
            >
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard"
                variant="subtitle2"
              >
                Tableau de bord
              </Link>
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard/relaunch"
                variant="subtitle2"
              >
                Relance
              </Link>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                Informations
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          spacing={0}
          sx={{
            pl: 1,
          }}
        >
          <Typography
            color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
            variant="h3"
            sx={{
              pb: 1, pl: 3,
            }}
          >
            {moment(endDateRelaunch).format('DD/MM/YYYY')}
          </Typography>
        </Grid>
      </Grid>
      {showStats === true && (
        <>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{ p: 1 }}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Grid
                container
                justifyContent="space-between"
                spacing={2}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                  sx={{ pb: 2 }}
                >
                  <Card
                    sx={{ p: 3, height: 170, }}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            width: 60,
                            height: 60
                          }}
                        >
                          <GroupsIcon
                            fontSize="small"
                            sx={{
                              width: 30,
                              height: 50
                            }}
                          />
                        </Avatar>
                        <Box sx={{ ml: 3 }}>
                          <Typography
                            color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                            noWrap
                            variant="body1"
                          >
                            Nombre de prospects total
                          </Typography>
                          <Typography
                            color="textPrimary"
                            sx={{ fontWeight: 'fontWeightBold' }}
                            variant="h4"
                          >
                            {numberProspects.number}
                          </Typography>
                          <Typography
                            color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                            noWrap
                            variant="body1"
                          >
                            Nombre de prospects en cours
                          </Typography>
                          <Typography
                            color="textPrimary"
                            sx={{ fontWeight: 'fontWeightBold' }}
                            variant="h4"
                          >
                            {numberProspectsSession.number}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Card
                    sx={{ p: 3, height: 170, pt: 7 }}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap'
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText',
                            width: 60,
                            height: 60
                          }}
                        >
                          <SafetyDividerIcon
                            fontSize="small"
                            sx={{
                              width: 30,
                              height: 50
                            }}
                          />
                        </Avatar>
                        <Box sx={{ ml: 3 }}>
                          <Typography
                            color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
                            noWrap
                            variant="body1"
                          >
                            Nombre traité
                          </Typography>
                          <Typography
                            color="textPrimary"
                            sx={{ fontWeight: 'fontWeightBold' }}
                            variant="h4"
                          >
                            {numberProspectsTreated}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
              <Box sx={{ pt: 1 }}>
                <Card sx={{ p: 1 }}>
                  <CardHeader title="Progression sur la semaine en cours" />
                  <Divider />
                  <CardContent>
                    <LinearProgress
                      value={progressionProspects < 100 ? progressionProspects : 100}
                      variant="determinate"
                    />
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        color={theme.palette.mode === 'light' ? '#7EB627' : 'white'}
                        variant="subtitle2"
                      >
                        {Number.isNaN(progressionProspects) ? 0 : progressionProspects.toFixed(2) < 100 ? progressionProspects.toFixed(2) : 100}
                        %
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            {user.id_service === 4 && (
            <Grid
              item
              md={6}
              xs={12}
            >
              <RelaunchRecallsList />
            </Grid>
            )}
          </Grid>
        </>
      )}
      {showStats === false && (
        <>
          {user.access_level === 0 && (
          <Grid
            sx={{
              backgroundColor: 'background.default',
              p: 1
            }}
          >
            <RelaunchLearners />
          </Grid>
          )}
        </>
      )}
      {showStats && (
        Object.keys(countCustomersByWeekAndQualification).map((el) => (
          <Box sx={{ mt: 2, ml: 1 }}>
            <Card>
              <Typography
                color="textSecondary"
                variant="h4"
                sx={{ ml: 1, p: 1 }}
              >
                {`Nombre de commentaires pour date de début ${moment(el).format('DD/MM/YYYY')} par statut`}
              </Typography>
              <DataGrid
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'id', sort: 'desc' }],
                  },
                }}
                autoHeight
                rowHeight={50}
                rows={rows[el]}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50, 100]}
                components={{ Toolbar: GridToolbar }}
              />
            </Card>
          </Box>
        ))
      )}
      {user.id_service === 4 && showStats && (
      <Grid
        item
        p={2}
      >
        <RelaunchCustomersList
          countCustomersList={countCustomersList}
        />
      </Grid>
      )}
    </Box>
  );
};

export default RelaunchInfo;
