class RelaunchApi {
  async getRelaunch(state, accessToken) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/relaunchStats/${state}`;

    const resp = await fetch(urlRelaunch, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getRelaunchInfo(endDateRelaunch, state, accessToken) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/relaunchStatsEndDate`;
    const values = {};
    values.endDateRelaunch = endDateRelaunch;
    values.state = state;

    const resp = await fetch(urlRelaunch, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getRelaunchLearner(endDateRelaunch, state, accessToken, query = null, callTrackRelaunch = null, startSessions = null, formation = null, relaunch = null, profession = null, page = 0, limit = 25) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/relaunchLearners`;
    const values = {};
    values.endDateRelaunch = endDateRelaunch;
    values.query = query;
    values.callTrackRelaunch = callTrackRelaunch;
    values.startSessions = startSessions;
    values.formation = formation;
    values.profession = profession;
    values.page = page;
    values.limit = limit;
    values.relaunch = relaunch;
    values.state = state;

    const resp = await fetch(urlRelaunch, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCustomersRelaunch(endDateRelaunch, state, accessToken) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/relaunchCustomersId`;
    const values = {};
    values.endDateRelaunch = endDateRelaunch;
    values.state = state;

    const resp = await fetch(urlRelaunch, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCustomersForFollowUp(accessToken, page = 0, limit = 10000) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/getCustomersForFollowUp`;
    const values = {};
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlRelaunch, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCustomersWithoutSource(accessToken, page = 0, limit = 10000) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/getCustomersWithoutSource`;
    const values = {};
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlRelaunch, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCustomersWithoutInscriptionDate(accessToken, page = 0, limit = 10000) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/getCustomersWithoutInscriptionDate`;
    const values = {};
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlRelaunch, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCustomersWithNonFinishedInscriptions(accessToken, page = 0, limit = 10000) {
    const urlRelaunch = `${process.env.REACT_APP_API_URL}/api/getCustomersWithNonFinishedInscriptions`;
    const values = {};
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlRelaunch, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }
}

export const relaunchApi = new RelaunchApi();
