/* eslint-disable no-unused-vars */
import {
  Box,
  Autocomplete,
  Card,
  Divider,
  TablePagination,
  TextField,
  Table,
  TableBody,
  Button,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  Checkbox,
  Tab,
  Tabs,
  Typography,
  Link,
  Avatar
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuth from '../../hooks/useAuth';
import { campaignApi } from '../../api/campaignApi';
import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Scrollbar from '../Scrollbar';
import useMounted from '../../hooks/useMounted';
import SearchIcon from '../../icons/Search';
import toast from 'react-hot-toast';

const tabs = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Médecins',
    value: 'Médecin'
  },
  {
    label: 'Infirmiers',
    value: 'Infirmier'
  },
  {
    label: 'Kinés',
    value: 'Masseur-kinésithérapeute'
  },
  {
    label: 'Podologues',
    value: 'Pédicure-podologue'
  },
  {
    label: 'Pharmaciens',
    value: 'Pharmacien'
  },
  {
    label: 'Sages-Femmes',
    value: 'Sage-Femme'
  }
];

const newCallTrackOptions = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Sans statut',
    value: 'empty'
  },
  {
    label: 'Vente 1 formation',
    value: 'Vente 1 formation'
  },
  {
    label: 'Vente 2 formation',
    value: 'Vente 2 formation'
  },
  {
    label: 'Vente 3 formation',
    value: 'Vente 3 formation'
  },
  {
    label: 'Déjà validé',
    value: 'Déjà validé'
  },
  {
    label: 'Souhaite du présentiel',
    value: 'Souhaite du présentiel'
  },
  {
    label: 'Aucun thème voulu',
    value: 'Aucun thème voulu'
  },
  {
    label: 'Date de session trop proche',
    value: 'Date de session trop proche'
  },
  {
    label: 'Préfère un autre organisme',
    value: 'Préfère un autre organisme'
  },
  {
    label: 'Retraite',
    value: 'Retraite'
  },
  {
    label: 'Numéro incorrect',
    value: 'Numéro incorrect'
  },
  {
    label: 'Non professionnel de santé',
    value: 'Non professionnel de santé'
  },
  {
    label: 'Souhaite un email',
    value: 'Souhaite un email'
  },
  {
    label: 'Rappel',
    value: 'Rappel'
  },
  {
    label: 'Attente',
    value: 'Attente'
  },
  {
    label: 'Appel entrant',
    value: 'Appel entrant'
  },
  {
    label: 'Pas répondu',
    value: 'Pas répondu'
  },
];

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const applyFilters = (customers, query, filters, campaign, newCallTrack) => customers
  .filter((customer) => {
    const matches = true;

    return matches;
  });

const sortOptions = [
  {
    label: 'Nom (A - Z)',
    value: 'lastname|asc'
  },
  {
    label: 'Nom (Z - A)',
    value: 'lastname|desc'
  },
  {
    label: 'Code postal (A - Z)',
    value: 'zip|asc'
  },
  {
    label: 'Code postal (Z - A)',
    value: 'zip|desc'
  }
];

const CampaignAddLearner = () => {
  const mounted = useMounted();
  const { idCampaign } = useParams();
  const [page, setPage] = useState(0);
  const [choiceTabProfession, setChoiceTabProfession] = useState('');
  const [choiceSort, setChoiceSort] = useState('');
  const { user } = useAuth();
  const [query, setQuery] = useState('');
  const [limit, setLimit] = useState(25);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [addCustomer, setAddCustomer] = useState([]);
  const [currentTab, setCurrentTab] = useState('all');
  const [usersCampaign, setUsersCampaign] = useState([]);
  const [numberProspects, setNumberProspects] = useState(0);
  const [numberProspectsTreated, setNumberProspectsTreated] = useState(0);
  const [numberCustomer, setNumberCustomer] = useState(0);

  const getCampaignAddCustomer = useCallback(async () => {
    try {
      const data = await campaignApi.getCampaignAddCustomer(localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setAddCustomer(data.addCustomer);
        setUsersCampaign(data.usersCampaign);
        setNumberCustomer(data.numberCustomer);
        setChoiceTabProfession(window.localStorage.getItem('choiceTabProfession').replaceAll('"', ''));
        let tempChoiceSort = window.localStorage.getItem('choiceSort');
        tempChoiceSort = tempChoiceSort === null ? '' : window.localStorage.getItem('choiceSort').replaceAll('"', '');
        setChoiceSort(tempChoiceSort);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCampaignAddCustomer();
  }, [getCampaignAddCustomer]);

  function initCampaign() {
    const camp = {};
    usersCampaign.forEach((element) => {
      camp[element.id] = null;
    });
    camp.empty = null;
    return camp;
  }

  const [campaign, setCampaign] = useState(initCampaign);
  const [newCallTrack, setNewCallTrack] = useState({
    empty: null,
    'Vente 1 formation': null,
    'Vente 2 formation': null,
    'Vente 3 formation': null,
    'Déjà validé': null,
    'Souhaite du présentiel': null,
    'Aucun thème voulu': null,
    'Date de session trop proche': null,
    'Préfère un autre organisme': null,
    Retraite: null,
    'Numéro incorrect': null,
    'Non professionnel de santé': null,
    'Souhaite un email': null,
    Rappel: null,
    Attente: null,
    'Appel entrant': null,
    'Pas répondu': null
  });
  const [currentNewCallTrack, setCurrentNewCallTrack] = useState('all');
  const [currentCampaign, setCurrentCampaign] = useState('all');

  const usersCampaignList = [...usersCampaign];
  usersCampaignList.unshift({ firstname: 'Tous', lastname: '', id: 'all' });

  const [filters, setFilters] = useState({
    Médecin: null,
    Infirmier: null,
    'Masseur-kinésithérapeute': null,
    'Pédicure-podologue': null,
    Pharmacien: null,
    'Sage-Femme': null
  });

  const handleCustomers = (async (querySearch, campaignChange, newCallTrackChange, tabProfession, pageChoice, limitChoice) => {
    try {
      const data = await campaignApi.getCampaignAddCustomer(localStorage.getItem('accessToken'), querySearch, campaignChange, newCallTrackChange, tabProfession, pageChoice, limitChoice);
      setAddCustomer(data.addCustomer);
      setNumberProspects(data.numberProspects.number);
    } catch (err) {
      console.error(err);
    }
  });

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    // pour que la recherche dans le champ "chercher un apprenant" ne se fasse qu'à partir de 3 caractères tapés
    if (event.target.value.length >= 3) {
      handleCustomers(event.target.value, campaign, newCallTrack, filters, page, limit);
    }
    // pour que la recherche dans le champ "chercher un apprenant" se réinitialise à 0 caractère
    if (event.target.value.length === 0) {
      handleCustomers('', campaign, newCallTrack, filters, page, limit);
    }
    // pour remettre à la première page de résultats quand on fait une recherche
    setPage(0);
  };

  const handleNewCallTrackChange = (event, value) => {
    const updatedNewCallTrack = {
      ...newCallTrack,
      empty: null,
      'Vente 1 formation': null,
      'Vente 2 formation': null,
      'Vente 3 formation': null,
      'Déjà validé': null,
      'Souhaite du présentiel': null,
      'Aucun thème voulu': null,
      'Date de session trop proche': null,
      'Préfère un autre organisme': null,
      Retraite: null,
      'Numéro incorrect': null,
      'Non professionnel de santé': null,
      'Souhaite un email': null,
      Rappel: null,
      Attente: null,
      'Appel entrant': null,
      'Pas répondu': null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedNewCallTrack[value] = true;
      }

      setNewCallTrack(updatedNewCallTrack);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(value);
    } else {
      if (event.target.value !== 'all') {
        updatedNewCallTrack[event.target.value] = true;
      }
      setNewCallTrack(event.target.value);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(event.target.value);
    }
    handleCustomers(query, campaign, event.target.value, filters, page, limit);
    setPage(0);
  };

  const handleCampaignChange = (event, value) => {
    const tempCampaign = {};
    usersCampaign.forEach((element) => {
      tempCampaign[element.id] = null;
    });
    const updatedCampaign = {
      ...campaign,
      ...tempCampaign,
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCampaign[value] = true;
      }

      setCampaign(updatedCampaign);
      setSelectedCustomers([]);
      setCurrentCampaign(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCampaign[event.target.value] = true;
      }
      setCampaign(event.target.value);
      setSelectedCustomers([]);
      setCurrentCampaign(event.target.value);
    }
    setPage(0);
    handleCustomers(query, event.target.value, newCallTrack, filters, page, limit);
  };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      Médecin: null,
      Infirmier: null,
      'Masseur-kinésithérapeute': null,
      'Pédicure-podologue': null,
      Pharmacien: null,
      'Sage-Femme': null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }
    setFilters(value);
    setSelectedCustomers([]);
    setCurrentTab(value);
    setPage(0);
    handleCustomers(query, campaign, newCallTrack, value, page, limit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleCustomers(query, campaign, newCallTrack, filters, newPage, limit);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    handleCustomers(query, campaign, newCallTrack, filters, page, parseInt(event.target.value, 10));
  };

  const filteredCustomers = applyFilters(addCustomer, query, filters, campaign, newCallTrack);
  const sortedCustomers = applySort(filteredCustomers, sort);
  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers = selectedCustomers.length > 0
    && selectedCustomers.length < sortedCustomers.length;
  const selectedAllCustomers = selectedCustomers.length === sortedCustomers.length;

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(event.target.checked
      ? sortedCustomers.map((customer) => customer.id)
      : []);
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedCustomers.includes(customerId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  return (
    <Formik
      initialValues={{
        customersId: null,
        submit: ''
      }}
      enableReinitialize
      validationSchema={Yup
        .object()
        .shape({
          customer: Yup.number().nullable()
        })}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          await campaignApi.assignCustomersCampaign(values.customersId, selectedCustomers, localStorage.getItem('accessToken'));
          setStatus({ success: true });
          setSubmitting(false);
          toast.success('Apprenants assignés !');
          selectedCustomers.forEach((id) => {
            const foundCustomer = campaign.find((customer) => customer.id === id);
            foundCustomer.caller_id = values.callerId;
          });
        } catch (err) {
          console.error(err);
          toast.error('Il y a eu un souci lors de l\'assignation !');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting, touched, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              backgroundColor: 'background.default',
            }}
          >
            <Card>
              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={currentTab}
                variant="scrollable"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </Tabs>
              <Divider />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap',
                  m: -1,
                  p: 2
                }}
              >
                <Box
                  sx={{
                    m: 1,
                    maxWidth: '100%',
                    width: 500
                  }}
                >
                  <TextField
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      )
                    }}
                    onChange={handleQueryChange}
                    placeholder="Chercher un apprenant"
                    value={query}
                    variant="outlined"
                  />
                </Box>
              </Box>

              {enableBulkActions && (
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    backgroundColor: 'background.paper',
                    mt: '6px',
                    position: 'absolute',
                    px: '4px',
                    width: '100%',
                    zIndex: 2
                  }}
                >
                  <Checkbox
                    checked={selectedAllCustomers}
                    color="primary"
                    indeterminate={selectedSomeCustomers}
                    onChange={handleSelectAllCustomers}
                  />
                  <Autocomplete
                    getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                    options={usersCampaign}
                    sx={{ ml: 2, width: 200 }}
                    onChange={(e, value) => {
                      setFieldValue(
                        'customersId',
                        value !== null ? value.id : null
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.usersCampaign && errors.usersCampaign)}
                        fullWidth
                        helperText={touched.usersCampaign && errors.usersCampaign}
                        key="customersId"
                        label="Utilisateur à assigner"
                        name="customersId"
                        variant="outlined"
                      />
                    )}
                  />
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    sx={{ ml: 2 }}
                    variant="outlined"
                    type="submit"
                  >
                    Ajouter
                  </Button>
                </Box>
              </Box>
              )}
              <Scrollbar>
                <Box sx={{ minWidth: 900 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedAllCustomers}
                            color="primary"
                            indeterminate={selectedSomeCustomers}
                            onChange={handleSelectAllCustomers}
                          />
                        </TableCell>
                        <TableCell>
                          Nom Prénom
                        </TableCell>
                        <TableCell>
                          Profession
                        </TableCell>
                        <TableCell>
                          Lieu
                        </TableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {addCustomer.map((link) => {
                        const isCustomerSelected = selectedCustomers.includes(link.id);
                        return (
                          <TableRow
                            key={link.id}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isCustomerSelected}
                                color="primary"
                                onChange={(event) => handleSelectOneCustomer(event, link.id)}
                                value={isCustomerSelected}
                              />
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Avatar
                                  src={link.avatar}
                                  sx={{
                                    height: 42,
                                    width: 42
                                  }}
                                  {...stringAvatar(`${link.firstname.replace(/ /g, '')} ${link.lastname.replace(/ /g, '')}`)}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <Link
                                    color={link.treated === 1 ? '#7EB627' : 'inherit'}
                                    component={RouterLink}
                                    to={`/dashboard/customers/${link.id}`}
                                    variant="subtitle2"
                                  >
                                    {link.firstname}
                                    {' '}
                                    {link.lastname}
                                  </Link>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {link.email}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Typography>
                                  {link.profession}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {link.city}
                              {', '}
                              {link.department}
                              {', '}
                              {link.region}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
              <TablePagination
                component="div"
                count={numberCustomer.number}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[15, 25, 50, 100]}
              />
            </Card>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CampaignAddLearner;
