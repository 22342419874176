/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
import React from 'react';
import { partnerApi } from '../../api/partnerApi';
import partnerSVG from '../../assets/partner.svg';
import PartnerForm from './PartnerForm';

export default function AddPartnerForm(props) {
  const {
    open,
    handleClickOpen,
    handleCancel,
    isImageDownloaded,
    isDragActive,
    getRootProps,
    getInputProps,
    files,
    handleChange,
    name,
    siret,
    startContract,
    setStartContract,
    endContract,
    setEndContract,
    partners,
    setPartners,
  } = props;

  const handleSubmit = async () => {
    const newPartner = {
      name,
      siret,
      start_contract: startContract,
      end_contract: endContract,
      img_address: partnerSVG,
    };
    const returnValues = await partnerApi
      .createPartner(window.localStorage.getItem('accessToken'), newPartner)
      .catch((e) => console.log(e));
    setPartners([...partners, { ...newPartner, id: returnValues.id }]);
    handleCancel();
  };

  return (
    <PartnerForm
      open={open}
      handleClickOpen={handleClickOpen}
      handleCancel={handleCancel}
      isImageDownloaded={isImageDownloaded}
      isDragActive={isDragActive}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      files={files}
      handleChange={handleChange}
      name={name}
      siret={siret}
      startContract={startContract}
      setStartContract={setStartContract}
      endContract={endContract}
      setEndContract={setEndContract}
      handleSubmit={handleSubmit}
    />
  );
}
