import { useState } from 'react';
import { Box, Card, Chip, Divider, Input } from '@material-ui/core';
import SearchIcon from '../../../icons/Search';
import MultiSelect from '../../MultiSelect';
import * as moment from 'moment';

const SessionFormationsFilter = (props) => {
  // eslint-disable-next-line react/prop-types
  const { setFilters, startDates, endDates } = props;
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState([]);
  const selectOptions = [
    {
      label: 'Statut',
      options: ['Active', 'Offerte', 'Annulée']
    },
    {
      label: 'Date de début',
      // eslint-disable-next-line react/prop-types
      options: startDates.map((el) => moment(el.start_date).format('DD/MM/YYYY'))
    },
    {
      label: 'Date de fin',
      // eslint-disable-next-line react/prop-types
      options: endDates.map((el) => moment(el.end_date).format('DD/MM/YYYY'))
    },
  ];

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyup = (event) => {
    if (event.code === 'Enter' && inputValue) {
      if (!chips.includes(inputValue)) {
        setChips((prevChips) => [...prevChips, inputValue]);
        setInputValue('');
        setFilters((prevChips) => [...prevChips, inputValue]);
      }
    }
  };

  const handleChipDelete = (chip) => {
    setChips((prevChips) => prevChips.filter((prevChip) => chip !== prevChip));
    setFilters((prevChips) => prevChips.filter((prevChip) => chip !== prevChip));
  };

  const handleMultiSelectChange = (value) => {
    setChips(value);
    setFilters(value);
  };

  return (
    <Card {...props}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <SearchIcon fontSize="small" />
        <Box
          sx={{
            flexGrow: 1,
            ml: 3
          }}
        >
          <Input
            disableUnderline
            fullWidth
            onChange={handleInputChange}
            onKeyUp={handleInputKeyup}
            placeholder="Entrer un mot clé"
            value={inputValue}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          p: 2
        }}
      >
        {chips.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={() => handleChipDelete(chip)}
            sx={{ m: 1 }}
            variant="outlined"
          />
        ))}
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          p: 1
        }}
      >
        {selectOptions.map((option) => (
          <MultiSelect
            key={option.label}
            label={option.label}
            onChange={handleMultiSelectChange}
            options={option.options}
            value={chips}
          />
        ))}
      </Box>
    </Card>
  );
};

export default SessionFormationsFilter;
