/* eslint-disable jsx-quotes */
/* eslint-disable no-unused-vars */
/* eslint linebreak-style: ["error", "windows"] */
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Avatar, Box, Paper, Typography,Divider } from '@material-ui/core';


const CustomerQualityComment = (props) => {
  const { comment } = props;
  return (
    <Box
     sx={{ display: 'flex', mb: 0, mt: 0, pr: 2 }}
     >
      <>
        <Avatar src={comment?.memberAvatar} />
        <Box sx={{ ml: 2, flexGrow: 1, }}>
        <a style={{textDecoration: 'none', decoration: 'none'}} href={`${process.env.REACT_APP_API}/dashboard/user/${comment.user_id}`} target='_blank'> 
          <Typography color="textPrimary" variant="subtitle2">{comment.memberName}</Typography> </a>
          <Paper sx={{ backgroundColor: 'background.default', mt: 1, p: 2 }} variant="outlined">
            <Typography color="textPrimary" variant="body2">{comment.comment}</Typography>
          </Paper>
          <Typography color="textSecondary" component="p" sx={{ mt: 1 }}>
            {format(new Date(comment.createdAt), "dd/MM/yyyy 'à' HH:mm")}
          </Typography>
        </Box>
      </>
    </Box>
  );
};

CustomerQualityComment.propTypes = {
  comment: PropTypes.array.isRequired,
};

export default CustomerQualityComment;
