/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Link,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import SchoolIcon from "@material-ui/icons/School";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import UsersIcon from "../../icons/Users";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import useSettings from "../../hooks/useSettings";
import LogoBlanc from "../LogoBlanc";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import BusinessIcon from "@mui/icons-material/Business";
import GroupsIcon from "@mui/icons-material/Groups";
import CampaignIcon from "@mui/icons-material/Campaign";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DifferenceIcon from "@mui/icons-material/Difference";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import FeedIcon from "@mui/icons-material/Feed";
import MenuIcon from "@mui/icons-material/Menu";
// import CheckIcon from "@mui/icons-material/Check";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SpeedIcon from '@mui/icons-material/Speed';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

const sections3 = [
  {
    title: "Général",
    items: [
      {
        title: "Tableau de bord",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Qualité",
    items: [
      {
        title: "Tickets d'incidents",
        path: "/dashboard/tickets",
        icon: <ConfirmationNumberIcon fontSize="small" />,
      },
      {
        title: "Réclamations",
        path: "/dashboard/complaint",
        icon: <PriorityHighIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Gestion",
    items: [
      {
        title: "Apprenants",
        path: "/dashboard/customers",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Prospects",
        path: "/dashboard/prospects",
        icon: <SafetyDividerIcon fontSize="small" />,
      },
      {
        title: "Campagne",
        path: "/dashboard/campaign",
        icon: <CampaignIcon fontSize="small" />,
      },
      // {
      //   title: "Qualité",
      //   path: "/dashboard/quality",
      //   icon: <CheckIcon fontSize="small" />,
      // },
    ],
  },
  {
    title: "Pédagogie",
    items: [
      {
        title: "Formations",
        path: "/dashboard/formations",
        icon: <SchoolIcon fontSize="small" />,
      },
      {
        title: "Sessions",
        path: "/dashboard/formationSession",
        icon: <FilterNoneIcon fontSize="small" />,
      },
    ],
  },
];

const sections2 = [
  {
    title: "Général",
    items: [
      {
        title: "Tableau de bord",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Qualité",
    items: [
      {
        title: "Tickets d'incidents",
        path: "/dashboard/tickets",
        icon: <ConfirmationNumberIcon fontSize="small" />,
      },
      {
        title: "Réclamations",
        path: "/dashboard/complaint",
        icon: <PriorityHighIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Gestion",
    items: [
      {
        title: "Apprenants",
        path: "/dashboard/customers",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Prospects",
        path: "/dashboard/prospects",
        icon: <SafetyDividerIcon fontSize="small" />,
      },
      {
        title: "Campagne",
        path: "/dashboard/campaign",
        icon: <CampaignIcon fontSize="small" />,
      },
      {
        title: "Relance",
        path: "/dashboard/relaunch",
        icon: <ArrowCircleUpIcon fontSize="small" />,
      },
      // {
      //   title: "Qualité",
      //   path: "/dashboard/quality",
      //   icon: <CheckIcon fontSize="small" />,
      // },
    ],
  },
  {
    title: "Pédagogie",
    items: [
      {
        title: "Formations",
        path: "/dashboard/formations",
        icon: <SchoolIcon fontSize="small" />,
      },
      {
        title: "Sessions",
        path: "/dashboard/formationSession",
        icon: <FilterNoneIcon fontSize="small" />,
      },
    ],
  },
];

const sectionsAdmin = [
  {
    items: [
      {
        title: "Tableau de bord",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Commercial",
    items: [
      {
        title: "Apprenants",
        path: "/dashboard/customers",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Prospects",
        path: "/dashboard/prospects",
        icon: <SafetyDividerIcon fontSize="small" />,
      },
      {
        title: "Établissement",
        path: "/dashboard/etablissements",
        icon: <BusinessIcon fontSize="small" />,
      },
      {
        title: "Groupement",
        // path: '/dashboard/groupements',
        icon: <GroupsIcon fontSize="small" />,
      },
      {
        title: "Campagne",
        path: "/dashboard/campaign",
        icon: <CampaignIcon fontSize="small" />,
      },
      {
        title: 'Relance',
        path: '/dashboard/relaunch',
        icon: <ArrowCircleUpIcon fontSize="small" />
      }
    ]
  },
  {
    title: "Qualité",
    items: [
      {
        title: "Tickets d'incidents",
        path: "/dashboard/tickets",
        icon: <ConfirmationNumberIcon fontSize="small" />,
      },
      {
        title: "Réclamations",
        path: "/dashboard/complaint",
        icon: <PriorityHighIcon fontSize="small" />,
      },
      {
        title: "Satisfaction",
        path: "/dashboard/satisfactions",
        icon: <SentimentSatisfiedAltIcon fontSize="small" />,
      },
      {
        title: "Performance",
        path: "/dashboard/performances",
        icon: <SpeedIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Gestion",
    items: [
      {
        title: "Partenaires",
        path: "/dashboard/partners",
        icon: <GroupWorkIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Pédagogie",
    items: [
      {
        title: "Formations",
        path: "/dashboard/formations",
        icon: <SchoolIcon fontSize="small" />,
      },
      {
        title: "Sessions",
        path: "/dashboard/formationSession",
        icon: <FilterNoneIcon fontSize="small" />,
      },
      {
        title: "Concepteur",
        path: "",
        icon: <PersonOutlineIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Rapport",
    items: [
      {
        title: "Analyse",
        path: "/dashboard/analysis",
        icon: <AnalyticsIcon />,
      },
      {
        title: "Rapports personnalisés",
        path: "custom-reports",
        icon: <AppRegistrationIcon fontSize="small" />,
      },
      {
        title: "Suivi des apprenants",
        path: "/dashboard/shift",
        icon: <DifferenceIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Ressources humaines",
    items: [
      {
        title: "Salarié",
        path: "",
        icon: <GroupsIcon fontSize="small" />,
      },
      {
        title: "Services",
        path: "",
        icon: <CoPresentIcon fontSize="small" />,
      },
      {
        title: "Présences",
        path: "",
        icon: <FingerprintIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Paramètres",
    items: [
      {
        title: "Imports",
        path: "/dashboard/imports",
        icon: <UploadFileIcon fontSize="small" />,
      },
      {
        title: "Activités",
        path: "/dashboard/logs",
        icon: <FeedIcon fontSize="small" />,
      },
      {
        title: "Utilisateurs",
        path: "/dashboard/users",
        icon: <ManageAccountsIcon fontSize="small" />,
      },
      {
        title: "Droits",
        path: "",
        icon: <MiscellaneousServicesIcon fontSize="small" />,
      },
      {
        title: "Multi-Établissements",
        path: "",
        icon: <LocationCityIcon fontSize="small" />,
      },
    ],
  },
];

const sections4 = [
  {
    title: "Général",
    items: [
      {
        title: "Tableau de bord",
        path: "/dashboard",
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Qualité",
    items: [
      {
        title: "Tickets d'incidents",
        path: "/dashboard/tickets",
        icon: <ConfirmationNumberIcon fontSize="small" />,
      },
      {
        title: "Réclamations",
        path: "/dashboard/complaint",
        icon: <PriorityHighIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Gestion",
    items: [
      {
        title: "Apprenants",
        path: "/dashboard/customers",
        icon: <UsersIcon fontSize="small" />,
      },
      {
        title: "Prospects",
        path: "/dashboard/prospects",
        icon: <SafetyDividerIcon fontSize="small" />,
      },
      {
        title: "Relance",
        path: "/dashboard/relaunch",
        icon: <ArrowCircleUpIcon fontSize="small" />,
      },
      // {
      //   title: "Qualité",
      //   path: "/dashboard/quality",
      //   icon: <CheckIcon fontSize="small" />,
      // },
    ],
  },
  {
    title: "Pédagogie",
    items: [
      {
        title: "Formations",
        path: "/dashboard/formations",
        icon: <SchoolIcon fontSize="small" />,
      },
      {
        title: "Sessions",
        path: "/dashboard/formationSession",
        icon: <FilterNoneIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Rapport",
    items: [
      {
        title: "Suivi des apprenants",
        path: "/dashboard/shift",
        icon: <DifferenceIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile, drawerOpen, setDrawerOpen } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  // pour récupérer le thème du site : clair ou sombre
  const settings = useSettings();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    localStorage.setItem("drawerOpen", JSON.stringify(!drawerOpen));
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  useEffect(() => {
    const storedDrawerOpen = localStorage.getItem("drawerOpen");
    if (storedDrawerOpen) {
      setDrawerOpen(JSON.parse(storedDrawerOpen));
    }
  }, []);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            {settings.settings.theme === "LIGHT" && (
              <Logo
                sx={{
                  height: 140,
                  width: 140,
                }}
              />
            )}
            {settings.settings.theme === "DARK" && (
              <LogoBlanc
                sx={{
                  height: 140,
                  width: 140,
                }}
              />
            )}
          </RouterLink>
        </Box>
        <Divider />
        {lgUp ? (
          <IconButton
            onClick={toggleDrawer}
            sx={{
              marginLeft: drawerOpen ? "40%" : "15px",
              marginTop: drawerOpen ? "15px" : "15px",
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        ) : (
          ""
        )}
        {drawerOpen || openMobile ? (
          <>
            <Box>
              <Box
                sx={{
                  alignItems: "center",
                  backgroundColor: "background.default",
                  borderRadius: 1,
                  display: "flex",
                  overflow: "hidden",
                  p: 2,
                }}
              >
                <RouterLink to="/dashboard/account">
                  <Avatar
                    src={user.avatar}
                    sx={{
                      cursor: "pointer",
                      height: 48,
                      width: 48,
                    }}
                  />
                </RouterLink>
                <Box sx={{ ml: 2 }}>
                  <Typography color="textPrimary" variant="subtitle2">
                    {user.firstname} {user.lastname}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {user.functions}
                    <Link color="primary" component={RouterLink} to="/pricing">
                      {user.plan}
                    </Link>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          ""
        )}
        <Box sx={{ p: 2 }}>
          {user.access_level === 0
            ? sectionsAdmin.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 1,
                    },
                  }}
                  {...section}
                  drawerOpen={drawerOpen}
                  openMobile={openMobile}
                />
              ))
            : user.id_service === 3
            ? sections3.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                  drawerOpen={drawerOpen}
                  openMobile={openMobile}
                />
              ))
            : user.id_service === 4
            ? sections4.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                  drawerOpen={drawerOpen}
                  openMobile={openMobile}
                />
              ))
            : sections2.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                  drawerOpen={drawerOpen}
                  openMobile={openMobile}
                />
              ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Box>
        <Drawer
          anchor="left"
          onClose={toggleDrawer}
          open={drawerOpen}
          PaperProps={{
            sx: {
              backgroundColor: "background.paper",
              height: "calc(100% - 64px) !important",
              top: "64px !Important",
              width: drawerOpen ? "15%" : "4%",
            },
          }}
          variant="permanent"
        >
          {content}
        </Drawer>
      </Box>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  setDrawerOpen: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

export default DashboardSidebar;
