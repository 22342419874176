import { Box, Card, Link, CardHeader, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { dashboardApi } from '../../../api/dashboardApi';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useMounted from '../../../hooks/useMounted';
import useAuth from '../../../hooks/useAuth';

const applyPagination = (statut) => statut
  .slice();

const FinanceSalesByContinent = () => {
  const [selectedCustomers] = useState([]);
  const [statut, setStatut] = useState([]);
  const { user } = useAuth();
  const mounted = useMounted();
  const sortedCustomers = (statut);
  const paginatedCustomers = applyPagination(sortedCustomers);

  const getStatus = useCallback(async () => {
    try {
      const data = await dashboardApi.getStatus(localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setStatut(data.statut);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  useEffect(() => {
    getStatus();
  }, [getStatus]);

  return (
    <Card>
      <CardHeader title="Clients à relancer" />
      <Box sx={{ minWidth: 700 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Nom et Commentaire
              </TableCell>
              {user.id_service !== 4 && (
              <TableCell>
                Statut
              </TableCell>
              )}
              <TableCell>
                Téléphone
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedCustomers.map((customer) => {
              const isCustomerSelected = selectedCustomers.includes(customer.id);

              return (
                <TableRow
                  hover
                  key={customer.id}
                  selected={isCustomerSelected}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Box sx={{ ml: 1 }}>
                        <Link
                          color="inherit"
                          component={RouterLink}
                          to={`/dashboard/customers/${customer.id}`}
                          variant="subtitle2"
                        >
                          {customer.firstname}
                          {' '}
                          {customer.lastname}
                        </Link>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                        >
                          {user.id_service !== 4 ? customer.comments : customer.comment}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  {user.id_service !== 4 && (
                  <TableCell
                    color="textSecondary"
                    variant="body2"
                  >
                    {customer.call_track}
                  </TableCell>
                  )}
                  <TableCell
                    color="textSecondary"
                    variant="body2"
                  >
                    {customer.phone}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default FinanceSalesByContinent;
