import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem } from '@material-ui/core';
import ChevronDownIcon from '../icons/ChevronDown';
import ChevronRightIcon from '../icons/ChevronRight';
import { Tooltip } from '@mui/material';

const NavItem = (props) => {
  const { active, children, depth, icon, info, open: openProp, path, title, drawerOpen, openMobile, ...other } = props;
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={!open ? <ChevronRightIcon fontSize="small" />
            : <ChevronDownIcon fontSize="small" />}
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0
      }}
    >
      {(drawerOpen || openMobile) ? (
        <>
          <Button
            component={path && RouterLink}
            startIcon={icon}
            sx={{
              color: 'text.secondary',
              fontWeight: 'fontWeightMedium',
              justifyContent: 'flex-start',
              textAlign: 'left',
              pl: `${paddingLeft}px`,
              pr: '8px',
              py: '12px',
              textTransform: 'none',
              width: '100%',
              ...(active && {
                color: 'primary.main',
                fontWeight: 'fontWeightBold',
                '& svg': {
                  color: 'primary.main'
                }
              })
            }}
            variant="text"
            to={path}
          >
            <Box sx={{ flexGrow: 1 }}>
              {title}
            </Box>
            {info}
          </Button>
        </>
      )
        : (
          <>
            <Tooltip title={title}>
              <Button
                component={path && RouterLink}
                startIcon={icon}
                sx={{
                  color: 'text.secondary',
                  fontWeight: 'fontWeightMedium',
                  justifyContent: 'flex-start',
                  textAlign: 'left',
                  pl: `${paddingLeft}px`,
                  pr: '8px',
                  py: '12px',
                  textTransform: 'none',
                  width: '100%',
                  ...(active && {
                    color: 'primary.main',
                    fontWeight: 'fontWeightBold',
                    '& svg': {
                      color: 'primary.main'
                    }
                  })
                }}
                variant="text"
                to={path}
              />
            </Tooltip>

          </>
        )}
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  drawerOpen: PropTypes.bool,
  openMobile: PropTypes.bool
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
