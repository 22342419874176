import { useState, useCallback, useEffect } from 'react';
import { Box, Card, Chip, Divider, Input, TextField } from '@material-ui/core';
import { formationApi } from '../../../api/formationApi';
import useMounted from '../../../hooks/useMounted';

const FormationsFilterSatisfactionPerformance = (props) => {
  // eslint-disable-next-line react/prop-types
  const { setFilters } = props;
  const mounted = useMounted();
  const [chips, setChips] = useState([]);

  const [dropdownOptions, setDropDownOptions] = useState([]);
  const [currentSelectedYear, setCurrentSelectedYear] = useState('Toutes');

  const getInformationsFilters = useCallback(async () => {
    try {
      const dataInfos = await formationApi.getAllInfosFiltersFormations(localStorage.getItem('accessToken'));
      if (mounted.current) {
        setDropDownOptions(['Toutes', ...dataInfos?.year]);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getInformationsFilters();
  }, [getInformationsFilters]);


  // const handleChipDelete = (chip) => {
  //   setChips((prevChips) => prevChips.filter((prevChip) => chip !== prevChip));
  //   setFilters((prevChips) => prevChips.filter((prevChip) => chip !== prevChip));
  //   if (chips?.length <= 1) {
  //     setCurrentSelectedYear('Toutes');
  //   }
  // };

  const handleMultiSelectChange = (value) => {
    if (value?.target?.value) {
      setCurrentSelectedYear(value?.target?.value); 
      if (value?.target?.value === "Toutes") {
        // GET ALL OPTIONS
        // setChips(['Toutes']);
        setFilters([]);
      }
      else {
          // GET FILTERED OPTIONS
          const filteredData = [...chips, value?.target?.value].filter(chip => chip !== 'Toutes');
          // setChips(filteredData);
          setFilters(filteredData);
        
      }
      // setFilters(value);
    }

  };

  return (
    <Card {...props}>

      {/* {chips.filter(c => c !== 'Toutes').length > 0 && (
        <>
              <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                p: 2
              }}
            >
              {chips.filter(c => c !== 'Toutes').map((chip) => (
                <Chip
                  key={chip}
                  label={chip}
                  onDelete={() => handleChipDelete(chip)}
                  sx={{ m: 1 }}
                  variant="outlined"
                />
              ))}
            </Box>
            <Divider />
        </>
      )} */}
      <TextField
        label="Afficher par année"
        name="année"
        onChange={handleMultiSelectChange}
        defaultValue="Toutes"
        value={currentSelectedYear}
        select
        SelectProps={{ native: true }}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        sx={{m: 2, width: 150}}
        // fullWidth
      >
        {dropdownOptions.map((option) => (
          <option
            key={option}
            value={option}
          >
            {option}
          </option>
        ))}
      </TextField>
    </Card>
  );
};

export default FormationsFilterSatisfactionPerformance;
