/* eslint-disable no-nested-ternary */
import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, frFR } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import Label from '../../Label';
import useAuth from '../../../hooks/useAuth';
import { ticketApi } from '../../../api/ticketApi';
import toast from 'react-hot-toast';
import {
  Grid,Backdrop, CircularProgress
} from '@material-ui/core';
import ShowTicketManager from './incidents/show_ticket_manager';
import ShowTicketReferent from './incidents/show_ticket_referent';

import TicketDialogManager from './incidents/TicketDialogManager';
import TicketDialogReferent from './incidents/TicketDialogReferent';
import { ClickAwayListener } from '@mui/base';
import { withStyles } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/styles';
import moment from 'moment';

const theme = createTheme({
  palette: {
    light: {
      main: '#04ba37',
      contrastText: '#fff'
    },
    moderate: {
      main: '#fabd05',
      contrastText: '#fff'
    },
    severe: {
      main: '#ff7700',
      contrastText: '#fff'
    },
    critical: {
      main: '#ba0404',
      contrastText: '#fff'
    },
    red: {
      main: 'red',
      contrastText: '#fff'
    },
    grey: {
      main: 'grey',
      contrastText: '#fff'
    },
    orange: {
      main: 'orange',
      contrastText: '#fff'
    },
    green: {
      main: 'green',
      contrastText: '#fff'
    },
    suspendu: {
      main: '#f44336',
      contrastText: '#fff'
    }
  },
});
const LimitedBackdrop = withStyles({root: { zIndex: 1,}})(Backdrop);
const capitalizeFirstLetter = (string) => string?.charAt(0).toUpperCase() + string?.slice(1);

const severities = [
  {color: 'light', value: 'Mineure'},
  {color: 'moderate', value: 'Modérée'},
  {color: 'severe', value: 'Grave'},
  {color: 'critical', value: 'Critique'},
];

const columnsScheme = [
  {
    field: 'reference',
    headerName: 'Référence',
    flex: 1
  },
  {
    field: 'category',
    headerName: 'Catégorie de l\'incident',
    flex: 1
  },
  {
    field: 'severity',
    headerName: 'Gravité',
    flex: 0.8,
    renderCell: (ticket) => (
      <ThemeProvider theme={theme}>
        <Label
          color={severities[ticket.row.severity].color}
        >
          {severities[ticket.row.severity].value}
        </Label>
      </ThemeProvider>
    )
  },
  {
    field: 'state',
    headerName: 'Etat',
    flex: 0.8,
    renderCell: (params) => (
     <ThemeProvider theme={theme}>
      <Label 
          color={
            params.row.state === 'Ouvert' ? 'grey' : 
            params.row.state === 'Suspendu' ? 'suspendu' :
            params.row.state === 'En cours' ? 'orange' :
            'green'
          }
        >
         {`${capitalizeFirstLetter(params.row.state)}`} 
      </Label>
      </ThemeProvider>
    )
  },
  {
    field: 'target',
    headerName: 'Cible',
    type: 'string',
    flex: 1,
    valueGetter: (params) => {
      let target = '';
      if (params.row.customer == null) target = 'Interne';
      else {
        target = `${params.row.customer.firstname} ${params.row.customer.lastname}`;
      }
      // eslint-disable-next-line consistent-return
      return `${capitalizeFirstLetter(target)}`;
    },
  },
  {
    field: 'referent',
    headerName: 'Référent',
    type: 'string',
    flex: 1,
    valueGetter: (params) => {
      let usernameReferent = '';
      if (params.row.user_in_charge) {
        usernameReferent = `${params.row.user_in_charge.firstname} ${params.row.user_in_charge.lastname}`;
      } else {
        usernameReferent = 'Aucun';
      }
      // eslint-disable-next-line consistent-return
      return `${usernameReferent}`;
    }
  },
  {
    field: 'normal_delay',
    headerName: 'Délai théorique',
    type: 'number',
    flex: 1,
    valueGetter: (params) => `${params.row.normal_delay} jour${params.row.normal_delay >= 2 ? 's' : ''}`
  },
  {
    field: 'created_at',
    headerName: 'Date de création',
    type: 'dateTime',
    flex: 1,
    valueGetter: (params) => {
      if (params.row.created_at) {
        return moment(params.row.created_at).format('DD/MM/YYYY HH:mm');
      }
      return '-';
    }
  },
  {
    field: 'take_charge_date',
    headerName: 'Date de prise en charge',
    type: 'dateTime',
    flex: 1.2,
    valueGetter: (params) => {
      if (params.row.take_charge_date) {
        return moment(params.row.take_charge_date).format('DD/MM/YYYY HH:mm');
      }
      return '-';
    }
  },
  {
    field: 'approx_resolution_date',
    headerName: 'Date approx. de résolution',
    type: 'dateTime',
    valueGetter: (params) => {
      if (params.row.approx_resolution_date) {
        return moment(params.row.approx_resolution_date).format('DD/MM/YYYY HH:mm');
      }
      return '-';
    },
    flex: 1
  },
  {
    field: 'effective_resolution_date',
    headerName: 'Date effective de résolution',
    type: 'dateTime',
    valueGetter: (params) => {
      if (params.row.effective_resolution_date) {
        return moment(params.row.effective_resolution_date).format('DD/MM/YYYY HH:mm');
      }
      return '-';
    },
    flex: 1
  },
];
const columnsSchemeComplaint = [
  {
    field: 'reference',
    headerName: 'Référence',
    flex: 1
  },
  {
    field: 'category',
    headerName: 'Catégorie de la réclamtion',
    flex: 1
  },
  {
    field: 'severity',
    headerName: 'Gravité',
    flex: 0.8,
    renderCell: (ticket) => (
      <ThemeProvider theme={theme}>
        <Label
          color={severities[ticket.row.severity].color}
        >
          {severities[ticket.row.severity].value}
        </Label>
      </ThemeProvider>
    )
  },
  {
    field: 'state',
    headerName: 'Etat',
    flex: 0.8,
    renderCell: (params) => (
     <ThemeProvider theme={theme}>
      <Label 
          color={
            params.row.state === 'Ouvert' ? 'grey' : 
            params.row.state === 'Suspendu' ? 'suspendu' :
            params.row.state === 'En cours' ? 'orange' :
            'green'
          }
        >
         {`${capitalizeFirstLetter(params.row.state)}`} 
      </Label>
      </ThemeProvider>
    )
  },
  {
    field: 'target',
    headerName: 'Cible',
    type: 'string',
    flex: 1,
    valueGetter: (params) => {
      let target = '';
      if (params.row.customer == null) target = 'Interne';
      else {
        target = `${params.row.customer.firstname} ${params.row.customer.lastname}`;
      }
      // eslint-disable-next-line consistent-return
      return `${capitalizeFirstLetter(target)}`;
    },
  },
  {
    field: 'referent',
    headerName: 'Référent',
    type: 'string',
    flex: 1,
    valueGetter: (params) => {
      let usernameReferent = '';
      if (params.row.user_in_charge) {
        usernameReferent = `${params.row.user_in_charge.firstname} ${params.row.user_in_charge.lastname}`;
      } else {
        usernameReferent = 'Aucun';
      }
      // eslint-disable-next-line consistent-return
      return `${usernameReferent}`;
    }
  },
  {
    field: 'normal_delay',
    headerName: 'Délai théorique',
    type: 'number',
    flex: 1,
    valueGetter: (params) => `${params.row.normal_delay} jour${params.row.normal_delay >= 2 ? 's' : ''}`
  },
  {
    field: 'created_at',
    headerName: 'Date de création',
    type: 'dateTime',
    flex: 1,
    valueGetter: (params) => {
      if (params.row.created_at) {
        return new Date(params.row.created_at);
      }
      return '-';
    }
  },
  {
    field: 'take_charge_date',
    headerName: 'Date de prise en charge',
    type: 'dateTime',
    flex: 1.2,
    valueGetter: (params) => {
      if (params.row.take_charge_date) {
        return new Date(params.row.take_charge_date);
      }
      return '-';
    }
  },
  {
    field: 'approx_resolution_date',
    headerName: 'Date approx. de résolution',
    type: 'dateTime',
    valueGetter: (params) => {
      if (params.row.approx_resolution_date) {
        return new Date(params.row.approx_resolution_date);
      }
      return '-';
    },
    flex: 1
  },
  {
    field: 'effective_resolution_date',
    headerName: 'Date effective de résolution',
    type: 'dateTime',
    valueGetter: (params) => {
      if (params.row.effective_resolution_date) {
        return new Date(params.row.effective_resolution_date);
      }
      return '-';
    },
    flex: 1
  },
];
const serviceObject = {
  field: 'service',
  headerName: 'Service',
  renderCell: (params) => (
      <Box>
        {params.row.service}
      </Box>
    ),
  flex: 1
};

export default function TicketList(props) {
  const { tickets, customerPage, isLoading, update, setUpdate, getTickets, type } = props;
  const { user } = useAuth();
  const [columns, setColumns] = useState(columnsScheme);
  const [selectionModel, setSelectionModel] = useState([]);
  const [ticketComment, setTicketComment] = useState(null);
  const [show, setShow] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const [infoTicket, setInfoTicket] = useState([]);
  const [ticketService, setTicketService] = useState(null);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [ticketSeverity, setTicketSeverity] = useState(null);
  const [ticketReferentId, setTicketReferentId] = useState(null);
  const [ticketReferentName, setTicketReferentName] = useState(null);
  const [activateFolliwTicketStatus, setActivateFolliwTicketStatus] = useState(true);
  const [ticketSelectedId, setTicketSelectedId] = useState(null);
  const [ticketLog, setTicketLog] = useState(null);

  const handleShow = async () => { setShow(!show); };

  const [ticketSolution, setTicketSolution] = useState(null);
  const [aiNotSolvedButtonColor, setAiNotSolvedButtonColor] = useState('black');
  const [ticketState, setTicketState] = useState('suspendu');
  const [listServices, setListServices] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listMembers, setListMembers] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [ticketDescription, setTicketDescription] = useState(null);


   // to compare values in the log when modifying the ticket info by the a manager /////////////////
   const [oldTicketService, setOldTicketService] = useState(null);
   const [oldTicketSeverity, setOldTicketSeverity] = useState(null);
   const [oldTicketReferentId, setOldTicketReferentId] = useState(null);
   const [oldTicketTracking, setOldTicketTracking] = useState(null);
   const [oldTicketSolution, setOldTicketSolution] = useState(null);
   const [oldTicketState, setOldTicketState] = useState(null);
   const [oldTicketMajorIncident, setOldTicketMajorIncident] = useState(null);
   const [oldTicketCategory, setOldTicketCategory] = useState(null);
   const [oldTicketDescription, setOldTicketDescription] = useState(null);
   const [oldTicketAcceptTracking, setOldTicketAcceptTracking] = useState(null);
   const [oldTicketDRA, setOldTicketDRA] = useState(null); // date res approx
   const [oldTicketDREff, setOldTicketDREff] = useState(null); // date res eff
   const [oldTicketDStart, setOldTicketDStart] = useState(null); // date prise en charge
   const [oldTicketCause, setOldTicketCause] = useState(null); // date res eff
   const [oldTicketCorrectiveActions, setOldTicketCorrectiveActions] = useState();
 // New values //////////////////
 const [newTicketService, setNewTicketService] = useState(null);
 const [newTicketSeverity, setNewTicketSeverity] = useState(null);
 const [newTicketReferentId, setNewTicketReferentId] = useState(null);
 const [newTicketTracking, setNewTicketTracking] = useState(null);
 const [newTicketSolution, setNewTicketSolution] = useState(null);
 const [newTicketState, setNewTicketState] = useState(null);
 const [newTicketMajorIncident, setNewTicketMajorIncident] = useState(null);
 const [newTicketCategory, setNewTicketCategory] = useState(null);
 const [newTicketDescription, setNewTicketDescription] = useState(null);
 const [newTicketAcceptTracking, setNewTicketAcceptTracking] = useState(null);
 const [newTicketDRA, setNewTicketDRA] = useState(null); // date res approx
 const [newTicketDREff, setNewTicketDREff] = useState(null); // date res eff
 const [newTicketDStart, setNewTicketDStart] = useState(null); // date prise en charge
 const [newTicketCause, setNewTicketCause] = useState(null); // date res eff
 const [ newTicketCorrectiveActions, setNewTicketCorrectiveActions] = useState();
 const [ticketFiles, setTicketFiles] = useState(null);
 const [majorIncident, setMajorIncident] = useState(null);
 const [loading, setLoading] = useState(false); 
   
  const getCategories = async (serviceId) => {
    try {
      const categorieList = await ticketApi.getCategoriesMemberTicket(localStorage.getItem('accessToken'), serviceId, type);
      setListCategories(categorieList);
    } catch (err) {
      console.log(err);
    }
  };
  const getServiceMembers = async (serviceId) => {
    try {
      const memberList = await ticketApi.getServiceMembersMemberTicket(localStorage.getItem('accessToken'), serviceId);
      setListMembers(memberList);
    } catch (err) {
      console.log(err);
    }
  };  
  const getTicketComments = async (ticketId) => {
    try {
      const listComments = await ticketApi.getTicketComments(localStorage.getItem('accessToken'), ticketId);
      setCommentList(listComments);
    } catch (err) {
      console.log(err);
    }
  }; 
  
  const getServices = async () => {
    try {
      const serviceList = await ticketApi.getServicesMemberTicket(localStorage.getItem('accessToken'));
      setListServices(serviceList);
    } catch (err) {
      console.log(err);
    }
  };
  const handleClick = async (selectedRowID) => {
    setTicketSelectedId(selectedRowID);
    await getTicketComments(selectedRowID);
    handleShow();
  };
  useEffect(() => {
    if (customerPage && type === 'ticket') {
      const newColumns = [...columnsScheme];
      newColumns[4] = serviceObject;
      
    } 

    else  if (type !== 'ticket') {setColumns(columnsSchemeComplaint);}
  }, [customerPage, type]);
  useEffect(() => {
    getServices();
  }, []);
 
  const validateTicket = async (ticketId) => {
    try {
      await ticketApi.validateTicket(localStorage.getItem('accessToken'), ticketId, user.id, oldTicketAcceptTracking, newTicketAcceptTracking, newTicketDescription);
      toast.success('Ticket validé avec succès!');
      getTickets(type);
    } catch (err) {toast.error("Erreur lors de la validation du ticket!");
      console.log(err);
    }
  };
  const openTicket = async (ticketId) => {
    try {
      await ticketApi.openTicket(localStorage.getItem('accessToken'), ticketId, user.id, newTicketAcceptTracking, newTicketDescription, infoTicket.ref, infoTicket.learnerId, infoTicket.date_creation, infoTicket.creator_id, newTicketState);
      
      if (type === 'ticket') {
        toast.success('Ticket ouvert avec succès!');
      } else {toast.success('réclamation ouvert avec succès!'); }
      getTickets(type);
    } catch (err) {toast.error("Erreur lors de l'ouverture du ticket!");
      console.log(err);
    }
  };
  const deleteTicket = async (ticketId) => {
    try {
      await ticketApi.deleteTicket(localStorage.getItem('accessToken'), ticketId, user.id);
      if (type === 'ticket') {
        toast.success('Ticket supprimé avec succès!');
      } else {toast.success('réclamation supprimé avec succès!'); }
      // getTickets(type);
      // toast.success('Ticket supprimé avec succès!');
      getTickets(type);
    } catch (err) {toast.error("Erreur lors de la suppression du ticket!");
      console.log(err);
    }
  };
 const updateTicketInfo = async (userId, ticketId) => {
    try {
      await ticketApi.updateTicketInfo(
        localStorage.getItem('accessToken'),
        ticketId,
        
        newTicketService,
        newTicketSeverity,
        newTicketReferentId,
        newTicketTracking,
        newTicketSolution,
        newTicketState,
        newTicketMajorIncident,
        newTicketCategory,
        newTicketAcceptTracking,
        newTicketDescription,
        newTicketDStart,
        newTicketDRA,
        newTicketDREff,
        newTicketCause,
        
        oldTicketService, 
        oldTicketSeverity, 
        oldTicketReferentId, 
        oldTicketTracking, 
        oldTicketSolution, 
        oldTicketState, 
        oldTicketMajorIncident, 
        oldTicketCategory,
        oldTicketAcceptTracking, 
        oldTicketDescription,
        oldTicketDStart,
        oldTicketDRA, 
        oldTicketDREff,
        oldTicketCause,

        userId,
        infoTicket.ref, 
        infoTicket.learnerId,);
      // getTickets(type);
      // toast.success('Ticket modifié avec succès!');
      if (type === 'ticket') {
        toast.success('Ticket modifié avec succès!');
      } else {toast.success('réclamation modifié avec succès!'); }
      getTickets(type);
    } catch (err) {toast.error("Erreur lors de la modification du ticket!");
      console.log(err);
    }
  };
 
const getTicketLog = async (ticketId) => {
    try {
      const log = await ticketApi.getTicketLog(localStorage.getItem('accessToken'), ticketId);
      setTicketLog(log);
    } catch (err) { 
      console.log(err);
    }
  };

  const addNewComment = async (Comment, userId, ticketId) => {
    try {
      await ticketApi.addNewComment(localStorage.getItem('accessToken'), Comment, ticketId, userId);
      getTicketComments(ticketId);
      getTicketLog(ticketId);
      toast.success('commentaire ajouté avec succès!');
    } catch (err) {toast.error("erreur lors de l'ajout du commentaire!");
      console.log(err);
    }
  }; 

  const getTicketDocuments = async (ticketId) => {
    try {
      const Doc = await ticketApi.getTicketDocuments(localStorage.getItem('accessToken'), ticketId);
      setTicketFiles(Doc);
      
    } catch (err) { 
      console.log(err);
    }
  };
  const deleteTicketDocument = async (docId, ticketId) => {
    try {
      const Doc = await ticketApi.deleteTicketDocument(localStorage.getItem('accessToken'), docId, ticketId);
      setTicketFiles(Doc);
    } catch (err) { toast.error("erreur lors de la suppression du document!");
      console.log(err);
    }
  };
  const getTicketCorrectiveActions = async (ticketId) => {
    const correctiveActions = await ticketApi.fetchCorrectiveActionsByTicketId(localStorage.getItem('accessToken'), ticketId)
console.log(correctiveActions);
    setOldTicketCorrectiveActions(correctiveActions)
  }
  const getTicketInfo = async (ticketId) => {
   setLoading(true); 
   try {
      const ticketInfoG = await ticketApi.getTicketInfo(localStorage.getItem('accessToken'), ticketId);
      const ticketInfo = ticketInfoG.info;
      
      setInfoTicket(ticketInfo[0]);
      
      if (ticketInfoG.incident_id) {setMajorIncident(ticketInfoG.incident_id[0]);}
      
      getTicketDocuments(ticketId);
      
      getTicketLog(ticketId);
      
      setSelectedService(ticketInfo[0]?.service); 
      
      getCategories(ticketInfo[0]?.service);
      getTicketCorrectiveActions(ticketId);
      if (ticketInfo[0]?.service === 1) {
         getServiceMembers(0);
      } else {
         getServiceMembers(ticketInfo[0].service);
      }
      
      // to compare values in the log when modifying the ticket info by the a manager ///////////////////////
      setOldTicketService(ticketInfo[0].service);
      setOldTicketSeverity(ticketInfo[0].severity);
      setOldTicketReferentId(ticketInfo[0].referent_id);
      setOldTicketTracking(ticketInfo[0].tracking);
      setOldTicketSolution(ticketInfo[0].solution);
      setOldTicketState(ticketInfo[0].state);
      setOldTicketMajorIncident(ticketInfo[0].majorIncident_id);
      setOldTicketCategory(ticketInfo[0].category_id);
      setOldTicketDescription(ticketInfo[0].description);
      setOldTicketCause(ticketInfo[0].causes);
      setOldTicketDStart(ticketInfo[0].date_take_charge);
      setOldTicketDRA(ticketInfo[0].date_res_approx);
      setOldTicketDREff(ticketInfo[0].date_res_eff);
      // for modifying ////////////////////////////////
      setNewTicketService(ticketInfo[0].service);
      setNewTicketSeverity(ticketInfo[0].severity);
      setNewTicketReferentId(ticketInfo[0].referent_id);
      setNewTicketTracking(ticketInfo[0].tracking);
      setNewTicketSolution(ticketInfo[0].solution);
      setNewTicketState(ticketInfo[0].state);
      setNewTicketMajorIncident(ticketInfo[0].majorIncident_id);
      setNewTicketCategory(ticketInfo[0].category_id);
      setNewTicketDescription(ticketInfo[0].description);
      setNewTicketCause(ticketInfo[0].causes);
      setNewTicketDStart(ticketInfo[0].date_take_charge)
      setNewTicketDRA(ticketInfo[0].date_res_approx);
      setNewTicketDREff(ticketInfo[0].date_res_eff);
      if (ticketInfo[0].tracking === 1) {
        setOldTicketAcceptTracking(true);
     } else {
      setOldTicketAcceptTracking(false);
     }
    
      if (ticketInfo[0].tracking === 1) {
         setNewTicketAcceptTracking(true);
      } else {
        setNewTicketAcceptTracking(false);
      }
     
      // setOldTicketTCD(ticketInfo[0].date_take_charge);
      setOldTicketDRA(ticketInfo[0].date_res_approx);
      setNewTicketDRA(ticketInfo[0].date_res_approx);
      // setOldTicketDRE(ticketInfo[0].date_res_eff);
      setLoading(false); 
    } catch (err) { 
      console.log(err);
      setLoading(false); 
    }
  };

  const handleClose = () => {
    setTicketService(null);
    setTicketSeverity(null);
    setTicketReferentId(null);
    setTicketReferentName(null);
    setTicketDescription(null);
    setActivateFolliwTicketStatus(false);
    setTicketSolution(null);
    setTicketComment(null);
    setTicketState('Ouvert');
    setAiNotSolvedButtonColor('black');
  };
  const handleKeyUp = (e) => {
    if (e.code === 'Enter' && !e.shiftKey && ticketComment) {
      addNewComment(ticketComment, user.id, ticketSelectedId);
      
      setTicketComment('');
    
     } };
    const handleTicketCommentChange = (event) => {
      setTicketComment(event.target.value);
    };
   
  const CustomToolbar = () => (user.id_service === 0
    ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
        <GridToolbarExport />
      </GridToolbarContainer>
    )
    : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {/* <GridToolbarDensitySelector /> */}
      </GridToolbarContainer>
    ));

  return (
    <Box>
       <LimitedBackdrop
                  sx={{ color: '#fff', zIndex: () => theme.zIndex.drawer + 1 , height: '100%'}}
                  open={loading}
                >
                  <CircularProgress 
                  color="primary"
                   />
      </LimitedBackdrop>
    <Box sx={{ height: 650, width: '100%' }}>
      <ClickAwayListener onClickAway={() => setSelectionModel([])}>
      <DataGrid   
        rows={tickets}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal'
          },
          '& .MuiDataGrid-columnHeader': {
            // '!important' passe outre le style en ligne (inline style)
            height: 'unset !important'
          },
          '& .MuiDataGrid-columnHeaders': {
            // '!important' passe outre le style en ligne (inline style)
            maxHeight: '250px !important'
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            whiteSpace: 'normal !important',
            wordWrap: 'break-word !important'
          },
          '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
            // backgroundColor: 'green',
            // color: 'white'
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
            },
        }}
        getRowHeight={() => 'auto'}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        components={{ Toolbar: CustomToolbar }}
        density="comfortable"
        loading={isLoading}
        selectionModel={selectionModel}
        onSelectionModelChange={(selectMod) => setSelectionModel(selectMod)}
        onRowClick={async (params) => {
          await setTicketSelectedId(params.row.id);
          await getTicketInfo(params.row.id); 
          await handleClick(params.row.id);
        }}
      />
      </ClickAwayListener>
        {show && (
        <Grid
          item
          md={4}//
          xs={1}
        > 
        {(user.service_manager && user.id_service === infoTicket.service) || (user.service_manager && user.id_service === 0 && infoTicket.service === 1) || infoTicket.creator_id === user.id || (user.id_service === 0 && user.access_level === 0) ? (
        <ShowTicketManager
       
            type={type}
            show={show}
            handleClose={handleClose}
            infoTicket={infoTicket}
            commentList={commentList}
            user={user}
            ticketComment={ticketComment}
            handleTicketCommentChange={handleTicketCommentChange}
            handleKeyUp={handleKeyUp}
            setCommentList={setCommentList}
            setInfoTicket={setInfoTicket}
            setShow={setShow}
            setListServices={setListServices}
            listServices={listServices}
            listCategories={listCategories}
            listMembers={listMembers}
            getCategories={getCategories}
            getServiceMembers={getServiceMembers}
            setTicketService={setTicketService}
            setTicketCategory={setTicketCategory}
            setTicketReferentId={setTicketReferentId}
            setTicketReferentName={setTicketReferentName}
            setTicketSeverity={setTicketSeverity}
            setTicketSolution={setTicketSolution}
            getTicketDocuments={getTicketDocuments}
            handleShow={handleShow}
            ticketService={ticketService}
            ticketSeverity={ticketSeverity}
            ticketDescription={ticketDescription}
            ticketLog={ticketLog}
            ticketFiles={ticketFiles}
            setTicketFiles={setTicketFiles}
            setListMembers={setListMembers}
            majorIncident={majorIncident}
            setOldTicketCorrectiveActions={setOldTicketCorrectiveActions}
            // for modifying
            setNewTicketService={setNewTicketService}
            setNewTicketSeverity={setNewTicketSeverity}
            setNewTicketReferentId={setNewTicketReferentId}
            setNewTicketTracking={setNewTicketTracking}
            setNewTicketSolution={setNewTicketSolution}
            setNewTicketState={setNewTicketState}
            setNewTicketMajorIncident={setNewTicketMajorIncident}
            setNewTicketCategory={setNewTicketCategory}
            setNewTicketDRA={setNewTicketDRA}
            setListCategories={setListCategories}
            setNewTicketAcceptTracking={setNewTicketAcceptTracking}
            setNewTicketDescription={setNewTicketDescription}
            setNewTicketCause={setNewTicketCause}
            setNewTicketDStart={setNewTicketDStart}
            setNewTicketDREff={setNewTicketDREff}
            setNewTicketCorrectiveActions={setNewTicketCorrectiveActions}

            newTicketService={newTicketService}
            newTicketSeverity={newTicketSeverity}
            newTicketReferentId={newTicketReferentId}
            newTicketTracking={newTicketTracking}
            newTicketSolution={newTicketSolution}
            newTicketState={newTicketState}
            newTicketMajorIncident={newTicketMajorIncident}
            newTicketCategory={newTicketCategory}
            newTicketDRA={newTicketDRA}
            newTicketDescription={newTicketDescription}
            newTicketAcceptTracking={newTicketAcceptTracking}
            newTicketCause={newTicketCause}
            newTicketDStart={newTicketDStart}
            newTicketDREff={newTicketDREff}
            newTicketCorrectiveActions={newTicketCorrectiveActions}
         
          // old values /////////////////
            oldTicketService={oldTicketService}
            oldTicketSeverity={oldTicketSeverity}
            oldTicketReferentId={oldTicketReferentId}
            oldTicketTracking={oldTicketTracking}
            oldTicketSolution={oldTicketSolution}
            oldTicketState={oldTicketState}
            oldTicketMajorIncident={oldTicketMajorIncident}
            oldTicketCategory={oldTicketCategory}
            oldTicketDRA={oldTicketDRA}
            oldTicketDescription={oldTicketDescription}
            oldTicketAcceptTracking={oldTicketAcceptTracking} 
            oldTicketCause={oldTicketCause}
            oldTicketDStart={oldTicketDStart}
            oldTicketDREff={oldTicketDREff}
            oldTicketCorrectiveActions={oldTicketCorrectiveActions}

          //
          updateTicketInfo={updateTicketInfo}
          validateTicket={validateTicket}
          update={update}
          setUpdate={setUpdate}
          openTicket={openTicket}
          deleteTicket={deleteTicket}
          getTickets={getTickets}
          deleteTicketDocument={deleteTicketDocument}
          getTicketCorrectiveActions={getTicketCorrectiveActions}
          />
           ) : 
           <ShowTicketReferent
          //  params ticket
            show={show}
            type={type}
            user={user}
            majorIncident={majorIncident}
            ticketComment={ticketComment}
            infoTicket={infoTicket}
            commentList={commentList}
            listServices={listServices}
            listCategories={listCategories}
            listMembers={listMembers}
            ticketService={ticketService}
            ticketSeverity={ticketSeverity}
            ticketDescription={ticketDescription}
            ticketLog={ticketLog}
            ticketFiles={ticketFiles}
            // functions
            handleClose={handleClose}
            handleTicketCommentChange={handleTicketCommentChange}
            handleShow={handleShow}           
            handleKeyUp={handleKeyUp}           
            updateTicketInfo={updateTicketInfo}
            validateTicket={validateTicket}
            update={update}
            setUpdate={setUpdate}
            getTickets={getTickets}    
            getCategories={getCategories}
            getServiceMembers={getServiceMembers}
            getTicketDocuments={getTicketDocuments}
            getTicketCorrectiveActions={getTicketCorrectiveActions}
            // set params
            setCommentList={setCommentList}
            setInfoTicket={setInfoTicket}
            setShow={setShow}
            setListServices={setListServices}
            setTicketService={setTicketService}
            setTicketCategory={setTicketCategory}
            setTicketReferentId={setTicketReferentId}
            setTicketReferentName={setTicketReferentName}
            setTicketSeverity={setTicketSeverity}
            setTicketSolution={setTicketSolution}
            setTicketFiles={setTicketFiles}
            setOldTicketCorrectiveActions={setOldTicketCorrectiveActions}
            // set new values
            setNewTicketAcceptTracking={setNewTicketAcceptTracking}
            setNewTicketService={setNewTicketService}
            setNewTicketSeverity={setNewTicketSeverity}
            setNewTicketReferentId={setNewTicketReferentId}
            setNewTicketTracking={setNewTicketTracking}
            setNewTicketSolution={setNewTicketSolution}
            setNewTicketState={setNewTicketState}
            setNewTicketMajorIncident={setNewTicketMajorIncident}
            setNewTicketCategory={setNewTicketCategory}
            setNewTicketCause={setNewTicketCause}
            setNewTicketDRA={setNewTicketDRA}
            setNewTicketDStart={setNewTicketDStart}
            setNewTicketDREff={setNewTicketDREff}
            setNewTicketCorrectiveActions={setNewTicketCorrectiveActions}
            //  new values
            newTicketService={newTicketService}
            newTicketSeverity={newTicketSeverity}
            newTicketReferentId={newTicketReferentId}
            newTicketTracking={newTicketTracking}
            newTicketSolution={newTicketSolution}
            newTicketState={newTicketState}
            newTicketMajorIncident={newTicketMajorIncident}
            newTicketCategory={newTicketCategory}
            newTicketDRA={newTicketDRA}
            newTicketAcceptTracking={newTicketAcceptTracking}
            newTicketCause={newTicketCause}
            newTicketDStart={newTicketDStart}
            newTicketDREff={newTicketDREff}
            newTicketCorrectiveActions={newTicketCorrectiveActions}
            // old values
              oldTicketService={oldTicketService}
              oldTicketSeverity={oldTicketSeverity}
              oldTicketReferentId={oldTicketReferentId}
              oldTicketTracking={oldTicketTracking}
              oldTicketSolution={oldTicketSolution}
              oldTicketState={oldTicketState}
              oldTicketMajorIncident={oldTicketMajorIncident}
              oldTicketCategory={oldTicketCategory}
              oldTicketDRA={oldTicketDRA}
              oldTicketAcceptTracking={oldTicketAcceptTracking}
              oldTicketCause={oldTicketCause}
              oldTicketDStart={oldTicketDStart}
              oldTicketDREff={oldTicketDREff}
              oldTicketCorrectiveActions={oldTicketCorrectiveActions}
          //

          />
           }
          

        </Grid>
      )}
    </Box>
    </Box>
  );
}

TicketList.propTypes = {
  tickets: PropTypes.array.isRequired,
  customerPage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
type: PropTypes.string.isRequired,
};
