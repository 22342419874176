/* eslint-disable no-unused-vars */
class CustomReportApi {

}

const api = new CustomReportApi();
export const specialitesList = api.getSpecialitesList;
export const professionsList = api.getProfessionsList;
export const regionsList = api.getRegionsList;
export const formationsList = api.getFormationsList;
export const sessionsList = api.getSessionsList;
export const campaignsList = api.getCampaignsList;
export const sourcesList = api.getsourcesList;

export const ITEM_HEIGHT = 45;

export const ITEM_PADDING_TOP = 2;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const orderOptions = [
  { label: 'Croissant', value: 'ASC' },
  { label: 'Descandant', value: 'DESC' },
];

export const reportTypes = [
  { label: 'Aprennants', value: 'customers', alias: 'c' },
  { label: 'Avancements', value: 'learner_sesions', alias: 'ls' },
  { label: 'Campagnes', value: 'campaign', alias: 'c' },
  { label: 'Commerciaux', table: 'utilisateurs', alias: 'u' },
  { label: 'Décalages', value: 'learner_session', alias: 'ls' },
  { label: 'Formations', value: 'formations', alias: 'f' },
  { label: 'Professions', value: 'customers', alias: 'p' },
  { label: 'Relances', value: 'learner_session', alias: 'ls' },
  { label: 'Sessions', value: 'sessions', alias: 's' },
  { label: 'Ventes', value: 'learner_session', alias: 'ls' },
];

export const agregateOptions = [
  { label: 'Somme', value: 'SUM' },
  { label: 'Moyenne', value: 'AVG' },
  { label: 'Valeur Unique', value: 'DISTINCT' },
  { label: 'Minimum', value: 'MIN' },
  { label: 'Maximum', value: 'MAX' },
  { label: 'Arrondi', value: 'ROUND' },
];

export const dbTables = [
  { label: 'Apprenants', table: 'customers', alias: 'cs' },
  { label: 'Apprenants du jour', table: 'learner_session_day', alias: 'lsd' },
  { label: 'Apprenants en session', table: 'learner_session', alias: 'ls' },
  { label: 'Campagnes', table: 'campaign', alias: 'cp' },
  { label: 'Commerciaux', table: 'utilisateurs', alias: 'u' },
  { label: 'Etablissements', table: 'etablissements', alias: 'e' },
  { label: 'Facturation', table: 'factu_sessions', alias: 'fs' },
  { label: 'Formateurs', table: 'formateurs', alias: 'ft' },
  { label: 'Formations', table: 'formations', alias: 'fm' },
  { label: 'Prospects en campagnes', table: 'learner_campaign', alias: 'lc' },
  { label: 'Orientations DPC', table: 'orientations_dpc', alias: 'od' },
  { label: 'Prestataires', table: 'prestataires', alias: 'p' },
  { label: 'Rappels', table: 'recalls', alias: 'r' },
  { label: 'Relances', table: 'recalls_relance', alias: 'rr' },
  { label: 'Sessions', table: 'sessions', alias: 's' },
];

export const criteresList = [
  'Appartenir à la branche',
  'Ne pas appartenir à la branche',
  'Appartenir à un groupe',
  'Ne pas appartenir à un groupe',
  'Avoir le cours',
  "N'a pas le cours",
  'Ont été inscrits à un cours sur une période de temps',
  'Ont terminé le cours',
  "N'ont pas terminé le cours",
  'A terminé un cours dans une période de temps',
  "N'ont pas terminé un cours dans une période de temps",
  'Ont commencé mais pas terminé un cours',
  'Le cours a expiré',
  "N'ont pas terminé un cours qui expire dans une période de temps",
  'Avoir un certificat pour suivre le cours',
  'Avoir un certificat expiré pour le cours',
  'Avoir un certificat expirant dans un certain temps',
  'Avoir obtenu un certificat dans un certain délai',
  "S'est connecté(e) dans une période de temps",
  "Ne s'est pas connecté(e) dans une période de temps",
  "S'est inscrit dans une période de temps",
  "Avoir le type d'utilisateur",
  'Saisir une valeur spécifique dans un champ personnalisé',
  'Sont actifs',
  'Sont inactifs',
  'Avoir au moins X points',
];

export const statusList = [
  '- de 5 personnes',
  'Annulation',
  'Décalage',
  'Désinscrit',
  'En attente',
  'Inscrit',
  'InscritD',
];

export const getLimits = [10, 25, 50, 100, 500];
