/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, frFR } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link, Card, Avatar, Box, IconButton, DialogContentText, DialogActions, DialogContent, Dialog, Button, Rating } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import { formationApi } from '../../../api/formationApi';
import toast from 'react-hot-toast';
import { useState } from 'react';

const FormationResultTable = (props) => {
  const { formations, setFormations, currentPage, setCurrentPage, ...other } = props;
  const rows = [];
  const [currentIdFormation, setCurrentIdFormation] = useState(0);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const CustomToolbar = () => (user.id_service === 0
    ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
    : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    ));

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  formations.filter((el) => el.status !== 'Supprimée').forEach((item) => {
    rows.push({ id: item.id,
      name: item.name,
      image: item.image,
      format: item.format,
      duration: item.duration,
      actionType: item.action_type,
      numberOfParticipants: item.number_of_participants,
      public: item.public,
      programNumber: item.program_number,
      satisfaction: (Number(item.global_average) / 20).toFixed(1),
    });
  });

  const handleDelete = async (id) => {
    try {
      await formationApi.deleteFormation(id, localStorage.getItem('accessToken'));
      const tempFormations = [...formations];
      tempFormations[formations.map((el) => el.id).indexOf(id)].status = 'Supprimée';
      setFormations(tempFormations);
      toast.success('Formation supprimée !');
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la suppression !');
    }
  };

  // Erreur avec le react/destructuring-assignment donc désactivation d'eslint sur les lignes concernées
  const columns = [
    { field: 'name',
      headerName: 'Nom',
      sx: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      },
      width: 470,
      renderCell: (cellValues) => (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Avatar
            src={cellValues.row.image}
            sx={{
              height: 50,
              width: 50,
            }}
            {...stringAvatar(`${cellValues.row.name.replace(/ /g, '')} ${cellValues.row.name.replace(/ /g, '')}`)}
          />
          <Box sx={{ ml: 1 }}>
            <Link
              sx={{ whiteSpace: 'normal', width: 450, fontWeight: 600, fontSize: 15 }}
              component={RouterLink}
              to={`/dashboard/formations/${cellValues.row.id}`}
              variant="subtitle2"
            >
              {cellValues.row.name}
            </Link>
          </Box>
        </Box>
      ) },
    { field: 'format', headerName: 'Format', width: 120, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 150 }}>{cellValues.row.format}</Typography>) },
    { field: 'duration', headerName: 'Durée', width: 80, renderCell: (cellValues) => (<Typography>{cellValues.row.duration}</Typography>) },
    { field: 'actionType', headerName: 'Action', width: 170, renderCell: (cellValues) => (<Typography>{cellValues.row.actionType}</Typography>) },
    { field: 'numberOfParticipants', headerName: 'Participants', width: 120, renderCell: (cellValues) => (<Typography>{cellValues.row.numberOfParticipants}</Typography>) },
    { field: 'public', headerName: 'Profession', width: 350, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.public}</Typography>) },
    { field: 'programNumber', headerName: 'N° de programme', width: 150, renderCell: (cellValues) => (<Typography>{cellValues.row.programNumber}</Typography>) },
    { field: 'satisfaction', headerName: 'Satisfaction', width: 150, renderCell: (cellValues) => (<><Box display="flex" flexDirection="column" alignItems="center"><Rating name="read-only" precision={0.1} value={cellValues.row.satisfaction} readOnly /><Typography>{`${cellValues.row.satisfaction} / 5`}</Typography></Box></>) },
    {
      width: 40,
      renderCell: (cellValues) => (
        <IconButton
          onClick={() => { setCurrentIdFormation(cellValues.row.id); handleClickOpen(); }}
          disabled={user.id_service !== 0 && user.access_level !== 0}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      ) },
  ];
  return (
    <Card {...other}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            Souhaitez-vous vraiment supprimer la formation ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete(currentIdFormation)}>Supprimer</Button>
          <Button
            onClick={handleClose}
            autoFocus
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        sx={{
          backgroundColor: '',
        }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        autoHeight
        rowHeight={80}
        rows={rows}
        columns={columns}
        page={currentPage}
        pageSize={20}
        rowsPerPageOptions={[30, 100]}
        components={{ Toolbar: CustomToolbar }}
        onPageChange={(newPage) => { setCurrentPage(newPage); }}
      />
    </Card>
  );
};
FormationResultTable.propTypes = {
  formations: PropTypes.object.isRequired,
  setFormations: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired
};

export default FormationResultTable;
