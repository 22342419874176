/* eslint-disable react/no-danger */
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ThemeProvider } from '@material-ui/core';
import { React, useState, useEffect } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { ticketApi } from '../../../api/ticketApi';
import PropTypes from 'prop-types';
import moment from 'moment';
import Scrollbar from '../../Scrollbar';
import useSettings from '../../../hooks/useSettings';
import { createCustomTheme } from '../../../theme';

function LogMajorIncident(props) {
  const { majorIncidentId } = props;
  const [show, setShow] = useState(false);
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { settings } = useSettings();
  const theme = createCustomTheme({
    ...settings
  });

  const handleShow = () => {
    // e.stopPropagation();
    setShow(false);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') { setShow(false); }
  };

  const getMajorIncidentLogs = async () => {
    try {
      const logsData = await ticketApi.getMajorIncidentLogs(localStorage.getItem('accessToken'), majorIncidentId);
      setLogs(logsData.data);
      setIsLoading(false)
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (show) {
      setIsLoading(true);
      getMajorIncidentLogs();
    }
  }, [show]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setShow(true)
          }}
        >
          <HistoryIcon fontSize="small" />

        </IconButton>
        {show && (
          <Dialog
            open={show}
            fullWidth="md"
            onClose={handleClose}
          >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center', color: theme.palette.primary.main }}>
              Historique de modifications
            </DialogTitle>
            {isLoading ? 
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <CircularProgress />
              </Box> 
              : 
            <Scrollbar options={{ suppressScrollX: true }}>
              <DialogContent>
                <DialogContentText>
                  <List>
                    {logs?.map((log) => (
                      <ListItem>
                        <p>
                          <span style={{ marginRight: '1rem' }}>
                            [
                            {moment(log.created_at).format('DD/MM/YYYY HH:mm:ss')}
                            ]:
                          </span>
                          <span
                            dangerouslySetInnerHTML={{ __html: log.description }}
                            style={{ color: theme.palette.text.primary}}
                          />
                        </p>
                      </ListItem>
                    ))}
                  </List>

                </DialogContentText>
              </DialogContent>
            </Scrollbar>
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mx: 3, mb: 2 }}>
              <Button
                size="large"
                onClick={handleShow}
              >
                Fermer
              </Button>
            </Box>
          </Dialog>
        )}
      </ThemeProvider>
    </>
  );
}

export default LogMajorIncident;

LogMajorIncident.propTypes = {
  majorIncidentId: PropTypes.number.isRequired,
};
