/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { reportsApi } from '../../../../api/reportsApi';
import SelectField from './SelectField';
import { Alert, Autocomplete, Box, Grid, TextField } from '@material-ui/core';
import { MobileDatePicker } from '@material-ui/lab';
import moment from 'moment';

const RenderAdditionalFields = ({ type, index, data, values, setFieldValue, currentState, setCurrentState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reportTitle, setReportTitle] = useState('');
  const [reportType, setReportType] = useState('');
  const [criteres, setCriteres] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [selectedFormation, setSelectedFormation] = useState(null);
  const [selectedFormationName, setSelectedFormationName] = useState('');
  const [specialites, setSpecialites] = useState([]);
  const [regions, setRegions] = useState([]);
  const [sources, setSources] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  // used only for displaying string in the form field
  const [sessions, setSessions] = useState([]);
  // used to store all selected sessions
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const isComponentMounted = useRef();

  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  // Mise à jour de la liste des sessions pour une formation sélectionnée
  const loadSessions = useCallback(async (formationId) => {
    // console.clear();
    setSelectedSessions([]); // Réinitialisation de la liste des sessions lorsque la formation change
    const res = await reportsApi.loadSessionsByFormationId(formationId, localStorage.getItem('accessToken'));
    setSessionList(res.sessions); // utilisé uniquement pour l'affichage
    setSelectedSessions([]); // utilisé pour enregistrer les sessions sélectionnées
    console.log(res.sessions, res.sessions.length);
  }, [selectedFormation]);

  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setStatusList(typeof value === 'string' ? value.split(', ') : value);
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values = statusList;
      setCurrentState(state);
    }
  }, [statusList]);

  const handleChangeProfessions = (event) => {
    const {
      target: { value },
    } = event;
    setProfessions(typeof value === 'string' ? value.split(', ') : value);
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values = professions;
      setCurrentState(state);
    }
  }, [professions]);

  const handleChangeSpecialites = (event) => {
    const {
      target: { value },
    } = event;
    setSpecialites(typeof value === 'string' ? value.split(', ') : value);
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values = specialites;
      setCurrentState(state);
    }
  }, [specialites]);

  const handleChangeSession = (event) => {
    const {
      target: { value },
    } = event;
    setSessions(typeof value === 'string' ? value.split(', ') : value);
    value.length > 0
      ? setSelectedSessions(value.map((s) => parseInt(s.slice(-1), 16)))
      : setSelectedSessions([]);
  };

  const handleChangeSelectedFormation = (event) => {
    console.log('event.target.value', event);
    const {
      target: { value },
    } = event;
    setSelectedFormation(value);
    loadSessions(value);
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values = {
        formation: selectedFormation,
        formation_name: selectedFormationName,
      };
      setCurrentState(state);
    }
  }, [selectedFormation]);

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values.sessions = selectedSessions;
      setCurrentState(state);
    }
  }, [selectedSessions]);

  const handleChangeSources = (event) => {
    const {
      target: { value },
    } = event;
    setSources(typeof value === 'string' ? value.split(', ') : value);
    console.log(data.sources);
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      const res = [];
      sources.forEach((item) => {
        const temp = data.sources.filter((s) => `${s.firstname} ${s.lastname}` === item);
        res.push(...temp);
      });
      // console.log('res => ', res);
      state[index].values = res;
      setCurrentState(state);
    }
  }, [sources]);

  const handleChangeRegions = (event) => {
    const {
      target: { value },
    } = event;
    setRegions(typeof value === 'string' ? value.split(', ') : value);
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values = regions;
      setCurrentState(state);
    }
  }, [regions]);

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values === undefined
        ? state[index].values = { startDate: startDate !== null && moment(startDate).format('YYYY-MM-DD') }
        : state[index].values.startDate = startDate !== null && moment(startDate).format('YYYY-MM-DD');
      setCurrentState(state);
    }
  }, [startDate]);

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values === undefined
        ? state[index].values = { endDate: endDate !== null && moment(endDate).format('YYYY-MM-DD') }
        : state[index].values.endDate = endDate !== null && moment(endDate).format('YYYY-MM-DD');
      setCurrentState(state);
    }
  }, [endDate]);

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values === undefined
        ? state[index].values = { min }
        : state[index].values.min = min;
      setCurrentState(state);
    }
  }, [min]);

  useEffect(() => {
    if (isComponentMounted.current) {
      const state = [...currentState];
      state[index].values === undefined
        ? state[index].values = { max }
        : state[index].values.max = max;
      setCurrentState(state);
    }
  }, [max]);

  const handleChangeCampaigns = (event) => {
    const {
      target: { value },
    } = event;
    setCampaigns(typeof value === 'string' ? value.split(', ') : value);
  };

  const handleChangeCriteres = (event) => {
    const {
      target: { value },
    } = event;
    setCriteres(typeof value === 'string' ? value.split(', ') : value);
  };

  switch (type) {
    case 'profession':
      return (
        <>
          <SelectField
            name="professions"
            id="professions"
            list={data && data.professions.map((p) => p.profession)}
            handleChange={handleChangeProfessions}
            _state={professions}
          />
        </>
      );
    case 'specialite':
      return (
        <>
          <SelectField
            name="specialites"
            id="specialites"
            multiple
            list={data && data.specialites.map((s) => s.specialite)}
            handleChange={handleChangeSpecialites}
            _state={specialites}
          />
        </>
      );
    case 'region':
      return (
        <>
          <SelectField
            name="regions"
            id="regions"
            list={data && data.regions.map((r) => r.region)}
            handleChange={handleChangeRegions}
            _state={regions}
          />
        </>
      );
    case 'statut':
      return (
        <>
          <SelectField
            name="status"
            id="status"
            list={data && data.statusList.map((s) => s.status)}
            handleChange={handleChangeStatus}
            _state={statusList}
          />
        </>
      );
    case 'source':
      return (
        <>
          <SelectField
            name="sources"
            id="sources"
            list={data && (data.sources.map((c) => `${c.firstname} ${c.lastname}`))}
            handleChange={handleChangeSources}
            _state={sources}
          />
        </>
      );
    case 'periode':
      return (
        <>
          <Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                lg={6}
              >
                <MobileDatePicker
                  label="Début"
                  onChange={(newDate) => setStartDate(newDate)}
                  renderInput={(inputProps) => (
                    <TextField
                      size="small"
                      variant="outlined"
                      {...inputProps}
                      fullWidth
                    />
                  )}
                  value={startDate}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={6}
              >
                <MobileDatePicker
                  label="Fin"
                  onChange={(newDate) => setEndDate(newDate)}
                  renderInput={(inputProps) => (
                    <TextField
                      size="small"
                      variant="outlined"
                      {...inputProps}
                      fullWidth
                    />
                  )}
                  value={endDate}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      );
    case 'min_max':
      return (
        <>
          <Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                lg={6}
              >
                <TextField
                  size="small"
                  fullWidth
                  required
                  type="number"
                  name="min"
                  id="min"
                  placeholder="Exemple : 10"
                  onChange={(e) => setMin(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={6}
              >
                <TextField
                  size="small"
                  fullWidth
                  required
                  type="number"
                  name="max"
                  id="max"
                  placeholder="Exemple : 100"
                  onChange={(e) => setMax(e.target.value)}
                />
              </Grid>

            </Grid>
          </Box>
        </>
      );
    case 'formation':
      return (
        <>
          <Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                lg={8}
              >
                <Autocomplete
                  id="disable-clearable"
                  disableClearable
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={data.formations}
                  onChange={(e, value) => {
                    setSelectedFormation(value.id);
                    setSelectedFormationName(value.name);
                    loadSessions(value.id);
                  }}
                  // onChange={handleChangeSelectedFormation}
                  size="small"
                  disabled={selectedSessions.length > 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      key={params.id}
                      name="selectedFormation"
                      variant="outlined"
                      label=""
                    >
                      {params.name}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
              >
                {sessionList.length > 0 && (
                <SelectField
                  name="sessions"
                  id="sessions"
                  list={sessionList && sessionList.map((s) => `Session ${s.number}`)}
                  handleChange={handleChangeSession}
                  _state={sessions}
                />
                )}
              </Grid>
            </Grid>
          </Box>

        </>
      );
    case 'session':
      return (
        <>
          <Box>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                lg={8}
              >
                <Autocomplete
                  id="disable-clearable"
                  disableClearable
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={data.formations}
                  onChange={(e, value) => {
                    setSelectedFormation(value.id);
                    setSelectedFormationName(value.name);
                    loadSessions(value.id);
                  }}
                  // onChange={handleChangeSelectedFormation}
                  size="small"
                  disabled={selectedSessions.length > 0}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      key={params.id}
                      name="selectedFormation"
                      variant="outlined"
                      label=""
                    >
                      {params.name}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
              >
                {sessionList.length > 0 && (
                <SelectField
                  name="sessions"
                  id="sessions"
                  list={sessionList && sessionList.map((s) => `Session ${s.number}`)}
                  handleChange={handleChangeSession}
                  _state={sessions}
                />
                )}
              </Grid>
            </Grid>
          </Box>

        </>
      );
    case 'undefined':
      return (
        <Alert
          sx={{ py: 0 }}
          variant="outlined"
          severity="info"
        >
          Ce type de critère n'est pas encore pris en charge !
        </Alert>
      );
      // case 'campagne':
      //   return (
      //     <>
      //       <SelectField
      //         name="campagnes"
      //         id="campagnes"
      //         list={data && data.campagnes.map((cp) => cp.name)}
      //         handleChange={handleChangeCampaigns}
      //         _state={campaigns}
      //       />
      //     </>
      //   );

    default:
      return null;
  }
};

export default RenderAdditionalFields;
