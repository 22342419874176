/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const CustomReportDetailsTimeLineChart = ({ chartSeries }) => {
  const theme = useTheme();
  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: ['#ffb547', '#7783DB', '#F44336'],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    grid: {
      borderColor: theme.palette.divider
    },
    markers: {
      strokeColors: theme.palette.background.paper,
      size: 1,
      radius: 2,
      shape: 'circle',
      strokeWidth: 0
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    }
  };
  // const chartSeries = [
  //   {
  //     name: 'New Customers',
  //     data: [31, 40, 28, 51, 42, 109, 100, 120, 80, 42, 90, 140]
  //   },
  //   {
  //     name: 'Up/Cross-Selling',
  //     data: [11, 32, 45, 32, 34, 52, 41, 80, 96, 140, 30, 100]
  //   },
  //   {
  //     name: 'New ....',
  //     data: [11, 32, 45, 32, 34, 109, 100, 120, 80, 42, 90, 140]
  //   }
  // ];
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <Card>
        <CardHeader
          title="Variation mensuelle du chiffre d'affaire sur la période"
          style={{ textAlign: 'left' }}
        />
        <CardContent>
          <Chart
            height="400"
            options={chartOptions}
            series={chartSeries}
            type="area"
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomReportDetailsTimeLineChart;
