import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')``;

const LogoBlanc = (props) => (
  <LogoRoot
    height="200"
    version="1.1"
    viewBox="0 0 599.29038 191.66707"
    width="700"
    {...props}
  >
    <title>Logo Apteed</title>
    <defs>
      <linearGradient
        id="Dégradé_sans_nom_11"
        x1="479.39562"
        y1="71.29244"
        x2="479.39562"
        y2="191.66707"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.3866"
          stopColor="#a2c614"
        />
        <stop
          offset="1"
          stopColor="#169a39"
        />
      </linearGradient>
    </defs>
    <path
      d="M388.48358,155.31365,479.25957.005H509.805l89.48534,155.30868H560.14054l-64.349-111.129a1.19728,1.19728,0,0,0-2.07621.00693L430.35813,155.31365Z"
      fill="#fff"
    />
    <path
      d="M484.79141,116.37893l22.694-38.18185s4.30218-6.56082,9.78746-6.77593c4.22892-.16584,20.67242-.13279,27.983-.10895a9.51781,9.51781,0,0,1,6.97438,3.03951,40.146,40.146,0,0,1,5.78486,9.32562s-7.18529-11.39574-12.88568-1.17807L485.867,184.28166s-4.40973,6.95519-10.43278,7.38541H457.79523a14.31235,14.31235,0,0,1-10.11012-6.56083L415.095,129.02577a6.58616,6.58616,0,0,0-2.7292-2.64049c-2.48622-1.22538-6.81885-1.29879-11.58966,7.89712a47.84455,47.84455,0,0,1,6.942-10.77752,13.765,13.765,0,0,1,10.30439-4.51349l26.48975.0764s5.27017.21511,9.03458,6.023L465.515,146.09718a1.15335,1.15335,0,0,0,1.99928.0086Z"
      fill="url(#Dégradé_sans_nom_11)"
    />
    <path
      d="M0,107.91992H33.06326c2.3287,18.30567,13.73719,25.543,37.48671,25.543,17.23024,0,32.36358-5.53418,32.36358-19.583,0-14.90039-15.832-17.87988-41.21157-23.20215C31.89891,84.50488,4.6574,77.26758,4.6574,45.55176,4.6574,15.53809,31.43317,0,68.45414,0c37.48672,0,61.702,16.81543,64.26249,47.46777H100.35305c-1.863-15.32617-14.90154-22.98925-32.13178-22.98925-18.16065,0-30.03594,7.02441-30.03594,17.88086,0,12.3457,11.64242,15.96386,36.32236,20.85937,34.22761,6.81152,62.16773,14.26172,62.16773,47.043,0,30.86426-27.24151,48.10547-64.72823,48.10547C26.77684,158.36719,1.16435,140.06152,0,107.91992Z"
      fill="#fff"
    />
    <path
      d="M155.60006,167.94629h11.17668c12.57284,0,17.69491-5.96,17.69491-14.90039,0-5.96-2.32763-12.98438-9.07979-29.58692L143.26009,45.76465h33.29505l17.696,49.1709c3.72592,10.0039,7.68364,23.83984,7.68364,23.83984h.46574a242.33333,242.33333,0,0,1,6.985-23.83984L226.15,45.76465h31.8989L216.37056,157.72949c-9.08086,24.26563-17.92778,32.99317-42.14305,32.99317H155.60006Z"
      fill="#fff"
    />
    <path
      d="M299.7209,60.665h.69861c8.14938-11.707,19.0932-17.88086,35.392-17.88086,24.67994,0,41.21156,17.0293,41.21156,40.86914v71.30859H345.357V87.91113c0-11.707-7.45077-20.00879-20.72222-20.00879-13.97006,0-24.21527,10.2168-24.21527,25.11719v61.94238h-31.665V45.76465H299.7209Z"
      fill="#fff"
    />
  </LogoRoot>
);

export default LogoBlanc;
