class UsersApi {
  async getUsers(accessToken) {
    const urlUsers = `${process.env.REACT_APP_API_URL}/api/users`;

    const resp = await fetch(urlUsers, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async deleteUser(userId, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/users/delete-user`;
    const resp = await fetch(URL_DELETE, {
      method: 'POST',
      body: JSON.stringify({ id: userId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getUserInfos(accessToken, userId) {
    const URL_INFOS = `${process.env.REACT_APP_API_URL}/api/users/user-infos`;
    const resp = await fetch(URL_INFOS, {
      method: 'POST',
      body: JSON.stringify({ id: userId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async updateUser(accessToken, values, idUser) {
    const URL_UPDATE = `${process.env.REACT_APP_API_URL}/api/users/update-user`;
    const resp = await fetch(URL_UPDATE, {
      method: 'POST',
      body: JSON.stringify({
        id: idUser,
        adress: values.adress === '' ? null : values.adress,
        birthday: values.birthday === '' ? null : values.birthday,
        city: values.city === '' ? null : values.city,
        country: values.country === '' ? null : values.country,
        email: values.email === '' ? null : values.email,
        firstname: values.firstname === '' ? null : values.firstname,
        lastname: values.lastname === '' ? null : values.lastname,
        personal_email: values.personal_email === '' ? null : values.personal_email,
        phone: values.phone === '' ? null : values.phone,
        region: values.region === '' ? null : values.region,
        zip: values.zip === '' ? null : values.zip
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }
}

export const usersApi = new UsersApi();
