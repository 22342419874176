/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import { Box, Grid } from '@mui/material';
import React, { useState, useContext, useCallback, useEffect } from 'react';
import PartnerCard from './PartnerCard';
import PartnerFormDialog from './PartnerFormDialog';
import { partnerApi } from '../../api/partnerApi';
import { DeliveriesProvider, PartnersContext } from '../../contexts/PartnerContext';
import { CircularProgress, Typography } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';

export default function PartnersList() {
  const [isLoading, setIsLoading] = useState(false);
  const { partners, setPartners } = useContext(PartnersContext);
  const { user } = useAuth();

  const getPartners = useCallback(async () => {
    setIsLoading(true);
    const partners = await partnerApi.getPartners(window.localStorage.getItem('accessToken'));
    setPartners(partners.data);
  }, []);

  useEffect(() => {
    getPartners();
    setIsLoading(false);
  }, []);

  return (
    <>
      <Grid
        container
        sx={{ p: 3 }}
      >
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        )
          : user.id_service === 0 && user.access_level === 0
            ? (
              <>
                <Typography
                  color="textPrimary"
                  variant="h5"
                  sx={{ mt: 3 }}
                >
                  Partenaires
                </Typography>

                <PartnerFormDialog
                  partners={partners}
                  setPartners={setPartners}
                />
                <Grid
                  container
                  sx={{ mt: 1 }}
                  rowSpacing={3}
                  columnSpacing={3}
                >
                  {partners.map((partner) => (
                    <DeliveriesProvider>
                      <PartnerCard
                        key={partner.id}
                        partner={partner}
                      />
                    </DeliveriesProvider>
                  ))}
                </Grid>
              </>
            ) : (<p>Vous n&lsquo;avez pas les droits pour accéder à cette page.</p>)}
      </Grid>
    </>
  );
}
