/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Backdrop, Box, Breadcrumbs, CircularProgress, Container, Grid, Link, Typography } from '@material-ui/core';
import { customerApi } from '../../../api/customerApi';
import useMounted from '../../../hooks/useMounted';
import ChevronRightIcon from '../../../icons/ChevronRight';
import useSettings from '../../../hooks/useSettings';
import gtm from '../../../lib/gtm';
import useAuth from '../../../hooks/useAuth';
import CustomerNewProspectsListTable from './CustomerNewProspectsListTable';

const CustomerNewProspects = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [numberCustomers, setNumberCustomers] = useState(0);
  const [usersCampaign, setUsersCampaign] = useState([]);
  const [usersRelance, setUsersRelance] = useState([]);
  const [relance, setRelance] = useState(false);
  const [endSessions, setEndSessions] = useState([]);
  const [customersEndSessions, setCustomersEndSessions] = useState([]);
  const [choiceTabProfession, setChoiceTabProfession] = useState('');
  const [choiceSort, setChoiceSort] = useState('');
  const [choiceSource, setChoiceSource] = useState('');
  const [choiceCampaign, setChoiceCampaign] = useState('');
  const [choiceCallTrack, setChoiceCallTrack] = useState('');
  const [choiceNewCallTrack, setChoiceNewCallTrack] = useState('');
  const [choiceCallTrackRelance, setChoiceCallTrackRelance] = useState('');
  const [choiceAvancement, setChoiceAvancement] = useState('');
  const [choiceEndSession, setChoiceEndSession] = useState('');

  function initRelance() {
    const rel = {};
    usersRelance.forEach((element) => {
      rel[element.id] = null;
    });
    rel.empty = null;
    return rel;
  }

  const [choiceRelance, setChoiceRelance] = useState(initRelance);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const location = useLocation();

  const getProspect = useCallback(async () => {
    try {
      let data = [];
      let tempChoiceProfession = window.localStorage.getItem('choiceTabProfession');
      tempChoiceProfession = tempChoiceProfession === null ? 'all' : window.localStorage.getItem('choiceTabProfession').replaceAll('"', '');
      // let tempChoiceSource = window.localStorage.getItem('choiceSource');
      // tempChoiceSource = tempChoiceSource === null ? 'all' : window.localStorage.getItem('choiceSource').replaceAll('"', '');
      // let tempChoiceCampaign = window.localStorage.getItem('choiceCampaign');
      // tempChoiceCampaign = tempChoiceCampaign === null ? 'all' : window.localStorage.getItem('choiceCampaign').replaceAll('"', '');
      // let tempChoiceCallTrack = window.localStorage.getItem('choiceCallTrack');
      // tempChoiceCallTrack = tempChoiceCallTrack === null ? 'all' : window.localStorage.getItem('choiceCallTrack').replaceAll('"', '');
      // let tempChoiceNewCallTrack = window.localStorage.getItem('choiceNewCallTrack');
      // tempChoiceNewCallTrack = tempChoiceNewCallTrack === null ? 'all' : window.localStorage.getItem('choiceNewCallTrack').replaceAll('"', '');
      // let tempChoiceCallTrackRelance = window.localStorage.getItem('choiceCallTrackRelance');
      // tempChoiceCallTrackRelance = tempChoiceCallTrackRelance === null ? 'all' : window.localStorage.getItem('choiceCallTrackRelance').replaceAll('"', '');
      // let tempChoiceAvancement = window.localStorage.getItem('choiceAvancement');
      // tempChoiceAvancement = tempChoiceAvancement === null ? 'all' : window.localStorage.getItem('choiceAvancement').replaceAll('"', '');
      // let tempChoiceEndSession = window.localStorage.getItem('choiceEndSession');
      // tempChoiceEndSession = tempChoiceEndSession === null ? 'all' : window.localStorage.getItem('choiceEndSession').replaceAll('"', '');
      // let tempChoiceRelance = window.localStorage.getItem('choiceRelance');
      // tempChoiceRelance = tempChoiceRelance === null ? 'all' : window.localStorage.getItem('choiceRelance').replaceAll('"', '');
      setLoading(true);
      if (location.pathname === '/dashboard/prospects') {
        mounted.current = true;
        if (query === '' && tempChoiceProfession === 'all' && user.access_level === 0) {
          // aucun résultat retourné
          setNumberCustomers({ numberCustomers: 0 });
          setUsersCampaign([]);
          setUsersRelance([]);
        } else {
          data = await customerApi.getProspect(window.localStorage.getItem('accessToken'), query, choiceSort, choiceSource, choiceCampaign, choiceCallTrack,
            choiceNewCallTrack, choiceCallTrackRelance, choiceAvancement, endSessions, choiceRelance, choiceTabProfession, limit, page);
          setNumberCustomers(data.numberCustomers);
        }
      } else if (location.pathname === '/dashboard/campagne') {
        mounted.current = true;
        data = await customerApi.getCustomersCampagne(window.localStorage.getItem('accessToken'), query, choiceSort, choiceSource, choiceCampaign, choiceCallTrack,
          choiceNewCallTrack, choiceCallTrackRelance, choiceAvancement, endSessions, choiceRelance, choiceTabProfession, limit, page);
      }

      if (mounted.current) {
        if (location.pathname === '/dashboard/prospects') {
          if (query === '' && tempChoiceProfession === 'all' && user.access_level === 0) {
            // aucun résultat retourné
            setNumberCustomers({ numberCustomers: 0 });
            setUsersCampaign([]);
            setUsersRelance([]);
          } else {
            setCustomers(data.customers);
            setNumberCustomers(data.numberCustomers);
            setUsersCampaign(data.usersCampaign);
            setUsersRelance(data.usersRelance);
          }

          setCustomersEndSessions(data.customers_end_sessions);
        } else if (location.pathname === '/dashboard/campagne') {
          setCustomers(data.customers);
        }
        setEndSessions(data.end_sessions);
        setLoading(false);
        setLoadingFilter(false);

        if (user.id_service === 4) {
          setRelance(true);
        }
        // Les choice permettent de garder les choix de filtre même en changeant de page
        // setChoiceTabProfession(window.localStorage.getItem('choiceTabProfession').replaceAll('"', ''));
        // let tempChoiceSort = window.localStorage.getItem('choiceSort');
        // tempChoiceSort = tempChoiceSort === null ? '' : window.localStorage.getItem('choiceSort').replaceAll('"', '');
        // setChoiceSort(tempChoiceSort);
        // let tempChoiceSource1 = window.localStorage.getItem('choiceSource');
        // tempChoiceSource1 = tempChoiceSource1 === null ? '' : window.localStorage.getItem('choiceSource').replaceAll('"', '');
        // setChoiceSource(tempChoiceSource1);
        // let tempChoiceCampaign1 = window.localStorage.getItem('choiceCampaign');
        // tempChoiceCampaign1 = tempChoiceCampaign1 === null ? '' : window.localStorage.getItem('choiceCampaign').replaceAll('"', '');
        // setChoiceCampaign(tempChoiceCampaign1);
        // let tempChoiceCallTrack1 = window.localStorage.getItem('choiceCallTrack');
        // tempChoiceCallTrack1 = tempChoiceCallTrack1 === null ? '' : window.localStorage.getItem('choiceCallTrack').replaceAll('"', '');
        // setChoiceCallTrack(tempChoiceCallTrack1);
        // let tempChoiceNewCallTrack1 = window.localStorage.getItem('choiceNewCallTrack');
        // tempChoiceNewCallTrack1 = tempChoiceNewCallTrack1 === null ? '' : window.localStorage.getItem('choiceNewCallTrack').replaceAll('"', '');
        // setChoiceNewCallTrack(tempChoiceNewCallTrack1);
        // let tempChoiceCallTrackRelance1 = window.localStorage.getItem('choiceCallTrackRelance');
        // tempChoiceCallTrackRelance1 = tempChoiceCallTrackRelance1 === null ? '' : window.localStorage.getItem('choiceCallTrackRelance').replaceAll('"', '');
        // setChoiceCallTrackRelance(tempChoiceCallTrackRelance1);
        // let tempChoiceAvancement1 = window.localStorage.getItem('choiceAvancement');
        // tempChoiceAvancement1 = tempChoiceAvancement1 === null ? '' : window.localStorage.getItem('choiceAvancement').replaceAll('"', '');
        // setChoiceAvancement(tempChoiceAvancement1);
        // let tempChoiceEndSession1 = window.localStorage.getItem('choiceEndSession');
        // tempChoiceEndSession1 = tempChoiceEndSession1 === null ? '' : window.localStorage.getItem('choiceEndSession').replaceAll('"', '');
        // setChoiceEndSession(tempChoiceEndSession1);
        // Fin des choice
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted, location]);

  useEffect(() => {
    getProspect();
  }, [getProspect]);

  useEffect(() => {
    console.log(customers);
  }, [customers]);

  return (
    <>
      <Helmet>
        <title>Tableau de bord: Liste des prospects | Apteed</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {user.id_service === 0 ? 'Liste des nouveaux prospects' : 'Liste des prospects'}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Gestion
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {user.id_service === 0 ? 'Nouveaux prospects' : 'Prospects'}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <CustomerNewProspectsListTable
              customers={customers} // Liste des apprenants
              usersCampaign={usersCampaign} // Utilisateurs à assigner en Campagne
              isRelance={relance} // Permet de savoir si l'utilisateur fait partie exclusivement du service Relance
              usersRelance={usersRelance} // Utilisateurs à assigner du service relance
              endSessions={endSessions} // Date de toutes les fins de sessions en bdd
              customersEndSessions={customersEndSessions} // Tableau de fins de sessions des apprenants
              choiceTabProfession={choiceTabProfession} // si préfixe choice, permet de garder en mémoire le changement de filtre
              choiceSort={choiceSort}
              choiceSource={choiceSource}
              choiceCampaign={choiceCampaign}
              choiceCallTrack={choiceCallTrack}
              choiceNewCallTrack={choiceNewCallTrack}
              choiceCallTrackRelance={choiceCallTrackRelance}
              choiceAvancement={choiceAvancement}
              choiceEndSession={choiceEndSession}
              query={query}
              setQuery={setQuery}
              setCustomers={setCustomers}
              numberCustomers={numberCustomers.numberCustomers}
              setNumberCustomers={setNumberCustomers}
              loading={loading}
              loadingFilter={loadingFilter}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              relance={choiceRelance}
              setRelance={setChoiceRelance}
            />
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerNewProspects;
