  import {React, useState, useEffect } from 'react';
  import PropTypes from 'prop-types';
  import {Box, Grid, Pagination, ToggleButton, ToggleButtonGroup, Typography} from '@material-ui/core';
  import ViewModuleIcon from '@material-ui/icons/ViewModule';
  import ViewListIcon from '@material-ui/icons/ViewList';
  import FormationPerformanceCard from './FormationPerformanceCard';
  import FormationResultTablePerformance from './FormationResultTablePerformance';

  const FormationsResultsPerformance = (props) => {
    const { formations, setFormations, filters, loading, ...other } = props;
    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageGrid, setCurrentPageGrid] = useState(1);
    const [mode, setMode] = useState('list');
    const formationsPerGridPage = 15;
    const startIndex = (currentPageGrid - 1) * formationsPerGridPage;
    const endIndex = startIndex + formationsPerGridPage;
  
    const handleModeChange = (event, value) => {setMode(value);};
    const filteredFormations = formations;
    // Filtrer les formations en fonction de la page actuelle pour les grid
    const gridFilteredFormations = filteredFormations.slice(startIndex, endIndex);
  
    useEffect(() => {setCurrentPage(0);}, [filters]);
    useEffect(() => {setCurrentPageGrid(1);}, [filters]);
    const handleGridPageChange = (event, page) => {setCurrentPageGrid(page);};
  
    return (
      <div {...other}>
        <Box sx={{alignItems: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', mb: 2}}>
          <Typography color="textPrimary"sx={{position: 'relative','&:after': {backgroundColor: 'primary.main', bottom: '-8px', content: '" "', height: '3px', left: 0, position: 'absolute', width: '48px'}}} variant="h5">
            Il y a{' '}{filteredFormations.length}{' '}formations
          </Typography>
          <Box sx={{alignItems: 'center', display: 'flex',}} spacing={2}>
            <ToggleButtonGroup sx={{ ml: 3 }} exclusive onChange={handleModeChange} size="small" value={mode}>
              <ToggleButton style={{ color: mode === 'list' ? '#7EB627' : 'inherit' }} value="list">
                <ViewListIcon fontSize="small" />
              </ToggleButton>
              <ToggleButton style={{ color: mode === 'grid' ? '#7EB627' : 'inherit' }} value="grid">
                <ViewModuleIcon fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        {mode !== 'list' && (
          <>
            <Grid container spacing={3} sx={{ p: 1 }}>
              {gridFilteredFormations.sort((a, b) => {return b.learning_progress - a.learning_progress;}).map((formation) => (
                <Grid item key={formation.course_id} md={mode === 'grid' ? 4 : 12} sm={mode === 'grid' ? 6 : 12} xs={12}>
                  <FormationPerformanceCard formation={formation} />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6}}>
              <Pagination
                count={Math.ceil(filteredFormations.length / formationsPerGridPage)}
                page={currentPageGrid}
                onChange={handleGridPageChange}
              />
            </Box>
          </>
        )}
        {mode === 'list' && (
          <>
            <FormationResultTablePerformance
              formations={filteredFormations}
              setFormations={setFormations}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              loading={loading}
            />
          </>
        )}
      </div>
    );
  };
  
  FormationsResultsPerformance.propTypes = {
    formations: PropTypes.array.isRequired,
    setFormations: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired, 
    loading: PropTypes.bool.isRequired,
  };
  
  export default FormationsResultsPerformance;
