/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import { React, useEffect, useState } from 'react';
import { createCustomTheme } from '../../../../theme';
import useSettings from '../../../../hooks/useSettings';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { THEMES } from '../../../../constants';
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import { MobileDateTimePicker, LocalizationProvider } from '@material-ui/lab';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, frFR } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Typography,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Avatar,
  Tooltip,
  Switch,
  IconButton, Card, CircularProgress, Backdrop, Tabs,
  Tab

} from '@material-ui/core';
import { ticketApi } from '../../../../api/ticketApi';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';
import TicketFileDropzone from '../TicketFileDropZone';
import TicketCorrectiveActionsFileDropZone from '../TicketCorrectiveActionsFileDropZone';
import { withStyles } from '@material-ui/core/styles';
import { height, maxHeight, minHeight } from '@material-ui/system';
import { v4 as uuidv4 } from 'uuid';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

// import { withStyles } from '@material-ui/styles';
// import { CircularProgress } from '@mui/material';
// import CustomerQualityComment from './CustumerQualityComments';
// import CustomerQualityCommentAdd from './CustomerQualityCommentAdd';



const tabs = [
    { label: 'Vue rapide', value: 'general' },
    { label: 'Actions correctives', value: 'actions' },
    { label: 'Commentaires', value: 'comments' },
    { label: 'Historique', value: 'log' },
  
  ];
  


const severityList = [
  {
    code: '0',
    value: 'Mineur'
  },
  {
    code: '1',
    value: 'Modéré'
  },
  {
    code: '2',
    value: 'Grave'
  },
  {
    code: '3',
    value: 'Critique '
  },
];

// comment add
const LimitedBackdrop = withStyles({root: { zIndex: 1,}})(Backdrop);
const CreateTicketsLearner = (props) => {
  const [show, setShow] = useState(false);
  const { customer, user, update, setUpdate, getTickets, type } = props;


  const [AIOptions, setAIOptions] = useState([]); 
  const [problemId, setProblemId] = useState(null);
  const [suggestionId, setSuggestionId] = useState(null);
  const [suggestion, setSuggestion] = useState(null);
  const [category, setCategory] = useState(null);
  const [membre, setMembre] = useState(null);
  // ***dead code ***
  // const [loading, setLoading] = useState(false);
  // const [addComment, setAddComment] = useState(true);
  // const [ticketAcceptTracking, setTicketAcceptTracking] = useState(1);
  // ****************************************************************

  const [ticketTitle, setTicketTitle] = useState(null);
  const [ticketReferentName, setTicketReferentName] = useState(null);
  const [activateFolliwTicketStatus, setActivateFolliwTicketStatus] = useState(false);
  const [AiConfirm, setAiConfirm] = useState(false);
  const [aiNotSolvedButtonColor, setAiNotSolvedButtonColor] = useState('primary');
  const [actionsDetails, setActionsDetails] = useState(null);
  const [selectedActionId, setSelectedActionId] = useState(null);
  
  const [lastId, setLastId] = useState(null);
  const [listServices, setListServices] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listMembers, setListMembers] = useState([]);
  const [load, setLoad] = useState(null);
  const [open, setOpen] = useState(false);
  const [fileValues, setFileValues] = useState([]);
  const [arrayTmp, setArrayTmp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('general');

  const { settings } = useSettings();
  const theme = createCustomTheme({ ...settings});
  const [spaceCounter, setSpaceCounter] = useState(0);

// **************** les infos du tickets***************
  const [ticketReference, setTicketReference] = useState('');
  const [ticketService, setTicketService] = useState(null);
  const [ticketCategory, setTicketCategory] = useState(null);
  const [ticketSeverity, setTicketSeverity] = useState(null);
  const [ticketReferentId, setTicketReferentId] = useState(null);
  const [ticketConstat, setTicketConstat] = useState(null);
  const [ticketCause, setTicketCause] = useState(null);
  const [ticketComment, setTicketComment] = useState(null);
  const [ticketSolution, setTicketSolution] = useState(null);
  const [ticketState, setTicketState] = useState('Ouvert');
  const [ticketStartDate, setTicketStartDate] = useState(null);
  const [ticketApproxDate, setTicketApproxDate] = useState(null);
  const [ticketEffectiveDate, setTicketEffectiveDate] = useState(null);
  const [ticketAcceptTracking, setTicketAcceptTracking] = useState(0);

  // *** les actions correctives ***
  const [new_CA_Desc, setNew_CA_Desc] = useState(null);
  const [new_CA_Date, setNew_CA_Date] = useState(null);
  const [new_CA_Temp_files, setNew_CA_Temp_files] = useState([]);
  const [arrayTmp_CA_Temp_files, setArrayTmp_CA_Temp_files] = useState([]);

  const [arrayTmp_CA_file_ref, setArrayTmp_CA_file_ref] = useState(null);
  const [new_CA_Temp_Desc, setNew_CA_Temp_Desc] = useState(null);
  const [new_CA_Temp_Date, setNew_CA_Temp_Date] = useState(null);
  const [ticketActionArray, setTicketActionArray]  = useState([
      // {id:1, ref: uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: [] },
      // {id:2, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
      // {id:3, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
      // {id:4, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
      // {id:5, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
    ]);

    const deleteCorrectiveAction = async (index) => {
      setActionsDetails(null); 
      setSelectedActionId(null);
      setNew_CA_Temp_Date(null);
      setNew_CA_Temp_Desc(null);
      
  setTicketActionArray((prevTicketActionArray) => {
      const updatedTicketActionArray = [...prevTicketActionArray];
       updatedTicketActionArray.splice(prevTicketActionArray.indexOf(prevTicketActionArray.find((e) => e.id === index)), 1);
return updatedTicketActionArray;
    });
      
   }

    const columns = [
      // {
      //   field: 'id',
      //   headerName: 'id',
      //   flex: 0.3
      // },
      {
        field: 'corrective_action',
        headerName: 'Description',
        flex:0.8
      },
      {
        field: 'correction_date',
        headerName: "Date",
        flex: 0.4
      },{
        field: 'user_name',
        headerName: "Intervenant",
        flex: 0.4
      },
      { width: 40,
        renderCell: (cellValues) => (
          <IconButton
            onClick={() => {
              deleteCorrectiveAction(cellValues.row.id); 
              
             }}
            disabled={user.id_service !== 0 && user.access_level !== 0}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        ) },
    ];
// ***** handlers *****
  const handleTabsChange = (event, value) => {
    setSelectedTab(value);
  };
  const handleCloseImportDialog = () => {
    setOpen(false);
    setFileValues([]);
    setArrayTmp([]);
  };
  const SaveFiles = () => {
    setOpen(false);
   };
  
  const handleChange = (index) => (event) => {
  event.preventDefault();
  const newArr = [...fileValues];
  newArr[index] = { ...newArr[index], [event.target.name]: event.target.value };
  setFileValues(newArr);
  };
  
  const handleFileDelete = (indexToDelete) => {
  setArrayTmp((prevArrayTmp) => {
    const updatedArrayTmp = [...prevArrayTmp];
    updatedArrayTmp.splice(indexToDelete, 1);
    return updatedArrayTmp;
  });
  setFileValues((prevFileValues) => {
    const updatedFileValues = [...prevFileValues];
    updatedFileValues.splice(indexToDelete, 1);
    return updatedFileValues;
  });
  };
  
  const handleDrop = async (acceptedFile) => {
     if ( acceptedFile.length > 0) { acceptedFile.forEach((file) => {
      
        arrayTmp.push({ name: file.name, type: '', file });
      });
        setFileValues(arrayTmp);
       setOpen(true);
      } else {setOpen(false); toast.error("impossible d'importer plus que 5 fichiers !")}
    };
   
  const handleShow = () => { setShow(!show); };
  
  const handleticketConstat = (event) => {
    setTicketConstat(event.target.value);
  };
  const handleticketCause = (event) => {
    setTicketCause(event.target.value);
  };

  const handleTicketCommentChange = (event) => {
    setTicketComment(event.target.value);
  };
  const handleAddActionButton = () => {
    setArrayTmp_CA_file_ref(uuidv4());
    setActionsDetails('new');
    setNew_CA_Temp_files([]);
    setNew_CA_Desc();
    setNew_CA_Date(null);
  };
  const showActionDocs = (id) => {
    if (actionsDetails === id) {
     setActionsDetails(null); 
    }
    else  {
      setActionsDetails(id);
    }
    // console.log(actionsDetails);
  };
// *** actions correctives ***
// ajouter une pièce jointe avant de créer un action corrective
const handleDropNewActionFiles = async (acceptedFile) => {
  if ( acceptedFile.length > 0) { 
    setNew_CA_Temp_files((prevArrayTmp_CA_Temp_files) => {
     const updatedArrayTmp_CA_Temp_files = [...prevArrayTmp_CA_Temp_files];
     acceptedFile.forEach((file) => { updatedArrayTmp_CA_Temp_files.push({ name: file.name, type: '', file }); });
     return updatedArrayTmp_CA_Temp_files;
   }); } 
 };
 // ajouter une pièce jointe à un ticket dans le tableau
 const handleDropSelectedActionFiles = async (acceptedFile) => {
    if ( acceptedFile.length > 0) { acceptedFile.forEach((file) => {
     
     arrayTmp_CA_Temp_files.push({ name: file.name, type: '', file });
      ticketActionArray.find((e) => e.id === selectedActionId).files.push({ name: file.name, type: '', file });
     });
     setNew_CA_Temp_files(arrayTmp_CA_Temp_files);
     setArrayTmp_CA_Temp_files([]);
     } 
   };
   //  supprimer des pièces jointes dans le ticket à créer (avant la création)
 const handleFileDeleteNewActionFiles = (indexToDelete) => {
   setArrayTmp_CA_Temp_files((prevArrayTmp_CA_Temp_files) => {
     const updatedArrayTmp_CA_Temp_files = [...prevArrayTmp_CA_Temp_files];
     updatedArrayTmp_CA_Temp_files.splice(indexToDelete, 1);
     return updatedArrayTmp_CA_Temp_files;
   });
   setNew_CA_Temp_files((prevFileValues_CA_Temp_files) => {
     const updatedFileValues_CA_Temp_files = [...prevFileValues_CA_Temp_files];
     updatedFileValues_CA_Temp_files.splice(indexToDelete, 1);
     return updatedFileValues_CA_Temp_files;
   });
   };
     //  ajouter l'action corrective
   const add_new_corrective_action = async () => {
    if ((new_CA_Desc && new_CA_Desc !== '') || (new_CA_Date) ) {
        setTicketActionArray( [...ticketActionArray, { 
              id: ticketActionArray.length > 0 ? ticketActionArray[ticketActionArray.length - 1].id + 1 : 1 ,
              corrective_action: new_CA_Desc,
              correction_date : new_CA_Date,
              user_id : user.id,
              user_name : `${user.firstname} ${user.lastname}`,
              files: arrayTmp_CA_Temp_files,
              ref: arrayTmp_CA_file_ref
            }]) 
             // décommenter pour tester le résultat
            // console.log([...ticketActionArray, { 
            //   id: ticketActionArray.length > 0 ? ticketActionArray[ticketActionArray.length - 1].id + 1 : 1 ,
            //   corrective_action: new_CA_Desc,
            //   correction_date : new_CA_Date,
            //   user_id : user.id,
            //   user_name : `${user.firstname} ${user.lastname}`,
            //   files: arrayTmp_CA_Temp_files, ref: arrayTmp_CA_file_ref
            // }]);
            setNew_CA_Date(null);
            setArrayTmp_CA_Temp_files([]);
            setNew_CA_Desc('');
            setNew_CA_Temp_files([]);
            setArrayTmp_CA_file_ref(uuidv4());
            setArrayTmp_CA_Temp_files([]);
    } 
    else {
      toast.error("Veuillez entrer une description et une date de l'action");
    }
   };
  //  supprimer une pièce jointe d'une action corrective
   const deleteActionFile = (index) => {
    setTicketActionArray((prevticketActionArray) => {
       const updatedArrayTmp_CA_files = [...prevticketActionArray];
       updatedArrayTmp_CA_files.find((e) => e.id === selectedActionId).files.splice(index, 1);
       return updatedArrayTmp_CA_files;
     });    
     setTicketActionArray(ticketActionArray);
   }; 

// **** functions ********************************
const generateReferenceCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const codeLength = 8;
  let referenceCode = '';

  for (let i = 0; i < codeLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    referenceCode += characters.charAt(randomIndex);
  }
  referenceCode += lastId;
  setTicketReference(referenceCode);
}; 
 const handleClose = () => {
  setShow(false);
  setTicketActionArray( 
   [
    //  {id:1, ref: uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: [] },
    // {id:2, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
    // {id:3, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
    // {id:4, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
    // {id:5, ref:uuidv4(),  corrective_action 'création des tickets', correction_date : '11/06/2024', user_name : 'imad-eddine',  user_id: 62,files: []},
  ]); 
  setSelectedTab('general');
  setTicketEffectiveDate(null);
  setTicketApproxDate(null);
  setTicketStartDate(null);
  setListCategories([]);
  setListServices([]);
  setListMembers([]);
  setArrayTmp([]);
  setTicketTitle(null);
  setTicketService(null);
  setTicketSeverity(null);
  setTicketReferentId(null);
  setTicketReferentName(null);
  setTicketConstat(null);
  setActivateFolliwTicketStatus(false);
  setFileValues([]);
  setLastId(null);
  setTicketSolution(null);
  setTicketComment(null);
  setTicketState('Ouvert');
  setAiNotSolvedButtonColor('primary');
  setLoad(null);
  setAIOptions(null);
  setProblemId(null);
  setSuggestionId(null);
  setSuggestion(null);
  setCategory(null);
  setMembre(null);
  setSpaceCounter(0);
  };
//*********** API functions */
  const sendFeedback = async (prob, solution) => {
      try {
       const urlTicket = `${process.env.REACT_APP_API_IA}/api/updatepert`;
           const values = {};
           values.suggestion_id = prob;
           values.solution_id = solution;
           const resp = await fetch(urlTicket, {
             method: 'POST',
             body: JSON.stringify(values),
             headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`            }
           });
           const json = await resp.json();
     } catch {
       setLoad(null);
     }
   };
  const sendSolutionToAI = async (solution) => {
      try {
       const urlTicket = `${process.env.REACT_APP_API_IA}/api/addsolution`;
           const values = {};
           values.suggestion_id = problemId;
           values.suggestion_solution = solution;
           const resp = await fetch(urlTicket, {
             method: 'POST',
             body: JSON.stringify(values),
             headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`            }
           });
           const json = await resp.json();
     } catch {
       setLoad(null);
     }
   };
  const getSuggestions = async () => {
    if (ticketService === 2 && ticketConstat) {
      setAIOptions(null);
  setLoad(true);
      // const timer = setTimeout(async () => {
  try {
        const urlTicket = `${process.env.REACT_APP_API_IA}/api/suggestion`;
            const values = {};
            values.text = ticketConstat;
            // console.log(JSON.stringify(values));
            // console.log(values);
            const resp = await fetch(urlTicket, {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_API_IA_TOKEN}`
              }
            });
            const json = await resp.json();
            setLoad(false);
            if (json.id) {
              setProblemId(json.id);
              setAIOptions(json.solutions);
            }
            else setLoad(null);
            // return Promise.resolve(json.data[0]);
      } catch {
        setLoad(null);
      }
      // }, 100);
    } };
  const handleSubmit = async (tiketId) => {
    await ticketApi.sendDoc(tiketId, user.id, fileValues, ticketReference,  localStorage.getItem('accessToken'));
 };
 const handleSubmit_correctiv_actions_docs = async (tiketId) => {
  const docs = [];
  ticketActionArray.forEach((action) => {
    if (action.files.length > 0) {
      action.files.forEach((file) => {
        file.ref = action.ref
        docs.push(file);
      });
    }
  })
  console.log(docs);
  try {
      await ticketApi.sendDoc_corrective_action(tiketId, user.id, docs,  localStorage.getItem('accessToken'));
  } catch (err) {console.log(err);
 }}
  const getLastTicketIdMemberTicket = async () => {
    try {
      const TicketLastId = await ticketApi.getLastTicketIdMemberTicket(localStorage.getItem('accessToken'));
      setLastId(parseInt(TicketLastId[0].max_ticket_id, 10) + 1);
    } catch (err) { 
      console.log(err);
    }
  };
  const getServicesMemberTicket = async () => {
    try {
      
      if (type === 'ticket') {
        const serviceList = await ticketApi.getServicesMemberTicket(localStorage.getItem('accessToken'));
      setListServices(serviceList); 
      }
      else {
         setListServices(null); 
      }
    } catch (err) { 
      console.log(err);
    }
  };
  const getCategoriesMemberTicket = async (serviceId) => {
    try {
      if (type === 'ticket') {
        const categorieList = await ticketApi.getCategoriesMemberTicket(localStorage.getItem('accessToken'), serviceId, type);
        setListCategories(categorieList);
      }
      else {
         const categorieList = await ticketApi.getCategoriesMemberTicket(localStorage.getItem('accessToken'), null , type);
         setListCategories(categorieList); 
      }     
    } catch (err) {
      console.log(err);
    }
  };
  const getServiceMembersMemberTicket = async (serviceId) => {
    try {
      const memberList = await ticketApi.getServiceMembersMemberTicket(localStorage.getItem('accessToken'), serviceId);
      setListMembers(memberList);
    } catch (err) {
      console.log(err);
    }
  };  
  const addLearnerTicket = async () => {
    try {
      const ticketId =  await ticketApi.addLearnerTicket(localStorage.getItem('accessToken'), 
      ticketService, ticketCategory, ticketSeverity, ticketReferentId,
       ticketConstat,ticketCause, ticketAcceptTracking, parseInt(customer, 10) , 
       user.id, ticketReference, ticketState, ticketComment, type, 
       ticketStartDate,ticketApproxDate,ticketEffectiveDate, ticketActionArray);
     {type === 'ticket' ? toast.success('Ticket ajouté avec succès!') : toast.success('Réclamation ajoutée avec succès!')}
     
      // console.log(ticketId);
      handleSubmit(ticketId.ticketId);
      handleSubmit_correctiv_actions_docs(ticketId.ticketId);
    } catch (err) {
      console.log(err);
      toast.error('Il y a eu un souci lors de la création !');
    }
  };
// ****************************
const handleAdd =   async () => {
    getLastTicketIdMemberTicket();
    generateReferenceCode();
    await addLearnerTicket();
    if (suggestionId !== null) {
      sendFeedback(problemId, suggestionId);
    }
    if (suggestion === null && ticketSolution !== null) {
      sendSolutionToAI(ticketSolution);
    }
    setUpdate(!update);
    handleClose();
  }; 
  const handleClick = async () => {setLoading(true); 
    if (type === 'ticket') {getServicesMemberTicket();}
    //  else {
      if (type !== 'ticket')
     { getCategoriesMemberTicket(null); getServiceMembersMemberTicket(null);}
    // } 
     setLoading(false); handleShow();
    };
    const CustomToolbar = () => (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <Tooltip title="Ajouter une action corrective">
          <Button size="small" onClick={handleAddActionButton}><AddIcon color="primary" />  Ajouter une action</Button>
        </Tooltip>

      </GridToolbarContainer>
   );
  useEffect(() => {  if (type !== 'ticket' && listServices?.length === 0) { getServicesMemberTicket();  }});
  return (
    <>
     <LimitedBackdrop
                  sx={{ color: '#fff', zIndex: () => theme.zIndex.drawer + 1 , height: '100%'}}
                  open={loading}
                >
                  <CircularProgress 
                  color="primary"
                   />
      </LimitedBackdrop>
      <Button name="b1" color="primary" startIcon={<AddIcon fontSize="small" />} variant="contained" onClick={handleClick}> {type === 'ticket' ? 'Créer un ticket' : 'Créer une réclamation' }</Button>
      <Dialog open={open} onClose={handleCloseImportDialog}>
        <DialogTitle>Téléchargement de documents</DialogTitle>
        <DialogContent>
          <DialogContentText> Confirmer le nom et le type de chaque fichier à importer. </DialogContentText>
          {fileValues.map((value, index) => (
            <div style={{alignContent: 'center', alignItems: 'center', display: 'flex'}}>
              {index + 1}
              {'. '}
              <TextField
                variant="outlined"
                sx={{ m: '20px' }}
                name="name"
                label="Nom du fichier"
                onChange={handleChange(index)}
                value={value.name}
              />
              <IconButton onClick={() => handleFileDelete(index)}>
                <CloseIcon />
              </IconButton>
              <br />
            </div>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={SaveFiles} color="primary"> Enregistrer</Button>
          <Button onClick={handleCloseImportDialog} color="primary"> Annuler</Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleClose} open={show} fullWidth="lg" maxWidth="lg">
        <DialogTitle>
          <row style={{ display: 'flex' }}>
            <div style={{ margin: '0px', width: '57%', color: theme.palette.primary.main, fontWeight: 'bold' }}>{type === 'ticket' ? 'Créer un nouveau ticket interne' : ' Créer une nouvelle réclamation'}</div>
            <row style={{ float: 'right', display: 'flex', padding: '0px' }}> </row>
          </row>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           
                {/* service, catégorie, référent et gravité */}
                <row style={{ display: 'flex' }}>
                 {type === 'ticket' && <div style={{ width: '20%' }}>
                    {/* choisir le service */}
                    <Autocomplete getOptionLabel={(option) => `${option.service_name} `} options={listServices}
                      onChange={(e, value) => {
                        if (value !== null) {
                          getCategoriesMemberTicket(value.service_id);
                          getServiceMembersMemberTicket(value.num_service);
                          setTicketService(value.service_id);
                          getLastTicketIdMemberTicket();
                          generateReferenceCode();
                          setCategory(null);
                          setMembre(null);
                        }
                        else { setTicketService(null); setCategory(null); setMembre(null);}
                      }}
                      renderInput={(params) => ( <TextField {...params} fullWidth label="Service *" name="service" variant="outlined" style={{ width: '100%', marginLeft: '0%' }} /> )}
                    />
                  </div>}
                  <div style={{ width: '30%' }}>
                    {/* choisir la catégorie */}
                    <Autocomplete getOptionLabel={(option) => `${option.category_title} `} options={listCategories} value={category}
                      onChange={(e, value) => { if (value !== null) { setTicketCategory(value.category_id); setCategory(value);} else { setTicketCategory(null);}}}
                      renderInput={(params) => (<TextField {...params} fullWidth label="Catégorie *" name="Category" variant="outlined" style={{ width: '100%', marginLeft: type === "ticket" ? '15%' : '0%' }} />)} />
                  </div>
                  {/* choisir le référent */}

                 {type !== 'ticket' ? <Autocomplete  style={{ width: '30%' }}  getOptionLabel={(option) => `${option.name} `} 
                   options={listMembers?.sort((a, b) => { const order = ["Direction", "Informatique", "Commercial", "Administratif"]; const indexA = order.indexOf(a.service_name);  const indexB = order.indexOf(b.service_name);
                      if (indexA > indexB) { return 1;} if (indexA < indexB) { return -1;} return 0;})}
                    groupBy={(option) => option.service_name} value={membre}
                    onChange={(e, value) => {
                      if (value !== null) {setTicketReferentId(value.member_id); setTicketReferentName(value.name); setMembre(value);} 
                      else { setTicketReferentId(null);  setTicketReferentName(null); setMembre(null); }
                    }}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Référent" name="referent" variant="outlined" style={{ width: '80%', marginLeft: '20%', marginTop: '0px' }} />)}
                  /> : <Autocomplete style={{ width: '30%' }}  getOptionLabel={(option) => `${option.name} `}
                   options={listMembers?.sort((a, b) => {
                      if (a.service_name === "Direction" && b.service_name !== "Direction") { return 1;} 
                      if (a.service_name !== "Direction" && b.service_name === "Direction") { return -1; }  else { return 0;  } 
                     })}
                    groupBy={(option) => option.service_name} value={membre} onChange={(e, value) => { if (value !== null) { console.log(type); setTicketReferentId(value.member_id); setTicketReferentName(value.name); setMembre(value);} else {setTicketReferentId(null); setTicketReferentName(null); setMembre(null); }  }}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Référent" name="referent" variant="outlined" style={{ width: '80%', marginLeft: '20%', marginTop: '0px' }} />  )}
                  />}

                  {/* choisir le degré de gravité */}
                  <Autocomplete style={{ width: '20%' }} getOptionLabel={(option) => `${option.value} `} 
                    options={severityList} onChange={(e, value) => { if (value !== null) { setTicketSeverity(value.code); getLastTicketIdMemberTicket(); generateReferenceCode(); } else { setTicketSeverity(null); }}}
                    renderInput={(params) => ( <TextField {...params} fullWidth label="Gravité *" name="gravity" variant="outlined" style={{ width: '80%', marginLeft: type === "ticket" ? '10%' : '20%' }} /> )}
                  />
                </row>
                {/* les date de prise en charge, résomution approx et effective */}
              <row style={{display:'flex',marginTop: '20px', marginBottom: '20px',}}>
              <div style={{ width: '20%' }}>
              <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                inputFormat="DD/MM/YYYY à HH:mm "
                                cancelText="Annuler"
                                showTodayButton
                                okText="OK"
                                //  disabled={infoTicket.date_res_eff !== null}
                                onChange={(e) => {
                                  setTicketStartDate(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                }}
                                 label="Date de prise en charge "
                                name="dateprisecharge"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={ticketStartDate}
                              /></LocalizationProvider>
                            </div>
              <div style={{ width: '20%',marginLeft:'5% '}}>
              <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                inputFormat="DD/MM/YYYY à HH:mm "
                                cancelText="Annuler"
                                showTodayButton
                                okText="OK"
                                //  disabled={infoTicket.date_res_eff !== null}
                                onChange={(e) => {
                                  setTicketApproxDate(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                }}
                                 label="Date de résolution approximative "
                                name="dateResApprx"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={ticketApproxDate}
                              /></LocalizationProvider>
                            </div>
              <div style={{ width: '20%',marginLeft:'5% ' }}>
              <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                inputFormat="DD/MM/YYYY à HH:mm "
                                cancelText="Annuler"
                                showTodayButton
                                okText="OK"
                                //  disabled={infoTicket.date_res_eff !== null}
                                onChange={(e) => {
                                  setTicketEffectiveDate(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  setTicketState('Résolu');
                                     
                                     if (ticketStartDate == null) {
                                         setTicketStartDate(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                     }
                                     if (ticketApproxDate == null ) {
       setTicketApproxDate(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                     }
                                }}
                                 label="Date de résolution effective "
                                name="dateResEff"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={ticketEffectiveDate}
                              /></LocalizationProvider>
                            </div>
              </row>

<div style={{
width:'100%',
  // border:"1px solid"
  }}>
    {/**** tabs ****/}
              <Tabs
              //  orientation="vertical"
               sx={{alignItems: 'start',}}
                        indicatorColor="primary"
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        textColor="primary"
                        value={selectedTab}
                        variant="scrollable">
                       { tabs.map((tab) => (
                <Tab justifyContent="stretch" textAlign="left" _selected={{ color: 'red', textAlign: 'center', justifyContent: "center"}}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              )) }
                        </Tabs>

<div style={{width:"100% ", paddingLeft: '20px',minWidth: '100%',maxWidth: '100%'}}>
                        {(() => {
                  switch (selectedTab) {
                    // *** vue générale ***
                    case 'general':
                      return <div style={{ width:'100%', overflowY:'auto',height:'100%',maxHeight:'44vh', minHeight:'44vh'}}>
                        <Grid container spacing={1}> 
                          <Grid item xs={8} style={{}}>
                            {/* CONSTAT */}
                                <div style={{ marginTop: '20px', display: 'flex', marginBottom: '10px' }}>
                                  <div style={{ width: '90%' }}>
                                    <TextField multiline rows={2} label="Constat * " placeholder="Constat" style={{ width: '100%' }} 
                                      onChange={(e) => {  handleticketConstat(e);
                                          const numberOfSpaces = e.target.value.split(' ').length - 1;
                                          setSpaceCounter(numberOfSpaces); 
                                        if (e.nativeEvent.data === ' ') {
                                        if (spaceCounter >= 2) {getSuggestions();}} 
                                        if (e.target.value === '' || e.target.value === null) {setSpaceCounter(0);}}} />
                                  </div>
                                </div>
                            {/* CAUSES*/}
                                <div style={{ marginTop: '20px', display: 'flex', marginBottom: '10px' }}>
                                  <div style={{ width: '90%' }}>
                                    <TextField multiline rows={2} label="Causes " placeholder="Causes" style={{ width: '100%' }} 
                                      onChange={(e) => {handleticketCause(e);}} 
                                        />
                                  </div>
                                </div>
                            {/* l'ajout de pièces jointes */}
                              <div style={{ width: '80%'}}> <TicketFileDropzone onDrop={handleDrop} maxFiles={5} /></div>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  {fileValues.map((file, index) => (
                                    <Card style={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
                                      <UploadFileIcon /> {file.name} <IconButton onClick={() =>  handleFileDelete(index)}><CloseIcon /></IconButton>
                                    </Card>
                                  ))}
                                </div>
                          </Grid>
                          <Grid item xs={4} minWidth="25%" style={{ borderLeft: '2px solid' }}>
                            <div style={{height:"100%",width:"100%",}}>
                              {/* Suggestions IA */}
                              <row style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography style={{ textAlign: 'center', fontSize: '18px', color: theme.palette.primary.main, fontWeight: 'bold' }}>  Suggestions </Typography>
                              </row>
                              {load === false ? (<div style={{ maxHeight: '250px', height: '55%', overflowY: 'auto', width: '100%', marginBottom: '0px', marginLeft: '5px'}}>
                                    {AIOptions?.sort((a, b) => b.pertinence - a.pertinence).map((options) => {
                                    let buttonContent;
                                    if (ticketSolution === options.solution) {
                                      buttonContent = <span style={{ color: 'green', margin: '0px', padding: '0px' }}>problème résolu</span>;
                                    } else {
                                      buttonContent = <p style={{ margin: '0px', padding: '0px' }}>problème résolu</p>;
                                    }
                                    return (
                                      <div style={{ display: 'flex' }}>
                                        <div style={{ width: '70%' }}>
                                          <p style={{ fontSize: '12px' }}>{options.solution}</p>
                                        </div>
                                          <Button
                                            style={{ marginLeft: '20px', fontSize: '12px' }}
                                            onClick={() => { setSuggestionId(options.id); setSuggestion(options.solution); setTicketSolution(options.solution); setAiConfirm(true); setAiNotSolvedButtonColor('primary'); setTicketState('Résolu'); }}
                                          >
                                            {buttonContent}
                                          </Button>
                                      </div>
                                    );
                                  })}
                                </div>
                                ) : (
                                    load === true ? (
                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '45%' }}>
                                        <CircularProgress />
                                      </div>
                                    ) : (
                                        <div style={{height: '45%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                          <p>Aucune suggestion</p>
                                        </div>
                                    )
                                    ) 
                                }
                            
                            </div>
               </Grid> 
            </Grid>
            
                      </div>;
                    // *** actions ***
                    case 'actions':
                      return <div style={{width:'100%',textAlign:'center',display:'flex',height:'100%',verticalAlign:'top',maxHeight:'44vh', minHeight:'44vh' }}>
                       <Grid container spacing={0} style={{}}> 
                          <Grid item xs={8} style={{}}>
                            <div style={{minWidth:'100%',minHeight:'100%',maxHeight:'100%'}}>
                            <DataGrid
                             localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                rows={ticketActionArray}
                                columns={columns}
                                components={{ Toolbar: CustomToolbar }}
                                initialState={{
                                  pagination: {
                                    paginationModel: { pageSize:1, },
                                  },
                                }}
                                rowsPerPageOptions={[4]}
                                // getRowHeight={() => 'auto'}
                                autoHeight
                                pageSize={4}
                                onCellClick={async (params) => {
                                  // console.log(params)
                                  if (params.field !== '') {
                                        //  console.log('case 1')
                                    showActionDocs(params.row.id)
                                 setSelectedActionId(params.row.id)
                                 setNew_CA_Temp_Date(ticketActionArray.find((e) => e.id === params.row.id).correction_date)
                                 setNew_CA_Temp_Desc(ticketActionArray.find((e) => e.id === params.row.id).corrective_action)
                                  }
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4}   style={{  }}>
                            <div style={{minWidth:'100%',minHeight:'100%',maxHeight:'44vh',
                              //  border:'1px solid',
                                overflowY:'auto'}}>
                                {(actionsDetails !==  null && actionsDetails !== "new") ? 
                                <div>
                                  <h3 style={{color: theme.palette.primary.main}}>Pièces jointes de l&lsquo;action {actionsDetails}</h3>
                                  <div style={{width:'100%', marginBottom: '20px'}}>
                                    <Button size="small" onClick={
                                    () => { if ( new_CA_Temp_Desc && new_CA_Temp_Desc !== '') {
                                      setTicketActionArray((prevticketActionArray) => {
                                        const updatedArrayTmp_CA_files = [...prevticketActionArray];
                                        updatedArrayTmp_CA_files.find((t) => t.id === selectedActionId).corrective_action = new_CA_Temp_Desc;
                                        updatedArrayTmp_CA_files.find((t) => t.id === selectedActionId).correction_date = new_CA_Temp_Date;
                                        return updatedArrayTmp_CA_files;
                                      });
                                      // setNew_CA_Date(new_CA_Temp_Date ?? ticketActionArray.find((e) => e.id === selectedActionId).correction_date)
                                      // setNew_CA_Temp_Desc((new_CA_Temp_Desc === '' || new_CA_Temp_Desc === null) ? ticketActionArray.find((e) => e.id === selectedActionId).corrective_action : new_CA_Temp_Desc )
                                    } else {
                                      toast.error('La nouvelle description ne peut pas être vide')
                                    }}
                                    }><SaveIcon color="primary" />  Enregistrer les modifications</Button>
                                  </div>
                                  <div>
                                    <TextField multiline rows={2} 
                                    label="Description " placeholder="Description d'action" 
                                     style={{ width: '80%' }} 
                                    // ***** 
                                      onChange={(e) => {
                                        // ticketActionArray.find((t) => t.id === selectedActionId).corrective_action = e.target.value;
                                        //  setNew_CA_Temp_Desc(e.target.value)
                                        setNew_CA_Temp_Desc(e.target.value)
                                        }}
                                      value={new_CA_Temp_Desc} 
                                      

                                       
                                       />
                                  </div>
                                  <div style={{marginTop: '20px',}}>
                                  <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                inputFormat="DD/MM/YYYY à HH:mm "
                                cancelText="Annuler"
                                showTodayButton
                                
                                okText="OK"
                                //  disabled={infoTicket.date_res_eff !== null}
                                onChange={(e) => {
                                  
                                  setNew_CA_Temp_Date(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  
                                }}
                                 label="Date de l'action"
                                name="dateAction"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px', width: '80%' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={new_CA_Temp_Date}
                              /></LocalizationProvider>
                                  </div>
                                  <div style={{ width: '100%', AlignItems: 'center',marginTop:'20px' }}>
                                    <div style={{ width: '80%',margin:'0 auto'}}>
                                       <TicketCorrectiveActionsFileDropZone onDrop={handleDropSelectedActionFiles} maxFiles={5} />
                                    </div>
                                  </div>
                                  <div style={{ width: '100%' ,marginTop:'20px',}}>
                                    <div style={{ width: '80%'  ,margin: '0 auto'}}>
                                      {ticketActionArray.find((e) => e.id === selectedActionId).files.map((file, index) => (
                                        <Card style={{ margin: '10px', display: 'flex', alignItems: 'center', width: '95%' }} onClick={() => {console.log('hello')}}>
                                          <UploadFileIcon /> {file.name} <IconButton onClick={() =>  {
                                            console.log( 'hello');
                                            deleteActionFile(index)}}><CloseIcon /></IconButton>
                                        </Card>
                                      ))}
                                    </div>
                                  </div>
                                </div> : <div></div> }
                                 {(actionsDetails ===  null) ? 
                                <div>
                                   </div> : <div></div> }
                                {(actionsDetails === "new") ? 
                                <div>
                                  <h3  style={{color: theme.palette.primary.main}}>Nouvelle action corrective</h3>
                               {/* Description */}
                               <div style={{ marginTop: '20px',  marginBottom: '10px' }}>
                                  <div>
                                    <TextField multiline rows={2} label="Description " placeholder="Description d'action" style={{ width: '80%' }} 
                                    // ***** modifier on change
                                      onChange={(e) => { setNew_CA_Desc(e.target.value) }}
                                       value={new_CA_Desc} 
                                      //  defaultValue={new_CA_Desc}
                                       />
                                  </div>
                                  <div style={{marginTop: '20px',}}>
                                  <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          locale={moment.locale('fr')}
                 >
                              <MobileDateTimePicker 
                              ampm={false}
                              todayText="Aujourd'hui"
                                inputFormat="DD/MM/YYYY à HH:mm "
                                cancelText="Annuler"
                                showTodayButton
                                okText="OK"
                                //  disabled={infoTicket.date_res_eff !== null}
                                onChange={(e) => {
                                  setNew_CA_Date(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                  // console.log(format(new Date(e), "yyyy-MM-dd HH:mm"));
                                }}
                                 label="Date de l'action"
                                name="dateAction"
                                renderInput={(inputProps) => (
                                <TextField
                                style={{ padding: '0px', margin: '0px', width: '80%' }}
                                  fullWidth
                                  variant="outlined"
                                  {...inputProps}
                                />
                                )}
                                value={new_CA_Date}
                              /></LocalizationProvider>
                                  </div>
                                  {/* ****pièces jointes */}
                                  <div style={{ width: '100%', AlignItems: 'center',marginTop:'20px' }}>
                                    <div style={{ width: '80%',margin:'0 auto'}}>
                                       <TicketCorrectiveActionsFileDropZone onDrop={handleDropNewActionFiles} maxFiles={5} />
                                    </div>
                                  </div>

                                  <div style={{ width: '100%' ,marginTop:'20px',}}>
                                    <div style={{ width: '80%'  ,margin: '0 auto'}}>
                                      {new_CA_Temp_files.map((file, index) => (
                                        <Card style={{ margin: '10px', display: 'flex', alignItems: 'center', width: '95%' }}>
                                          <UploadFileIcon /> {file.name} <IconButton onClick={() =>  handleFileDeleteNewActionFiles(index)}><CloseIcon /></IconButton>
                                        </Card>
                                      ))}
                                    </div>
                                  </div>
                                  <div style={{width:'100%'}}>
                                    <Button size="small" onClick={add_new_corrective_action}><AddIcon color="primary" />  Ajouter l&apos;action</Button>
                                  </div>
                                </div> </div> : <div></div> }
                            </div>
                          </Grid> 
                        </Grid>
                    </div>; // *** commentaires ***
                  
                    // *** commentaires ***
                      case 'comments':
                        return <div style={{width:'100%',maxHeight:'44vh', minHeight:'44vh'}}>
                                  {/* l'ajout des commentaires */}
                                  <div> 
                                    {/* <h4 style={{color: theme.palette.primary.main}}> Commentaires :</h4> */}
                                    <div style={{ width: '95%',paddingTop:'40px' }}>
                                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                        <Avatar sx={{ mr: 2 }} src={user.avatar} />
                                        <TextField fullWidth onChange={handleTicketCommentChange} placeholder="Ajouter un commentaire..." size="small" variant="outlined" multiline rows={2} />
                                      </Box>
                                    </div>
                                  </div>
                                </div>;
                    // *** historique ***
                      case 'log':
                        return <div style={{width:'100%',textAlign:'center',display:'flex',height:'100%',border:'1px solid',justifyContent:'center',alignItems:'center',maxHeight:'44vh', minHeight:'44vh' }}>
                        Aucun élément dans l&apos;historique pour le moment
                      </div>;
                    default:
                      return null;
                  }
            })()}  </div>   </div>                   
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <p style={{margin: '0px', padding: '0px', marginTop: '10px', position: 'absolute', left: '10px', color: theme.palette.primary.main, fontWeight: 'bold'}}>(*) Champ obligatoire </p>
          <Button  color="primary" onClick={() => { if (ticketConstat !== null && ticketSeverity !== null && ticketCategory !== null) { handleShow();
                // Le dialog de suspendre le ticket ou la réclamation
                Swal.fire({
                  title: "<h5>Vous risquez de perdre les informations de ce ticket, cliquez sur Suspendre le ticket pour le sauvegarder comme suspendu</h5>",
                  icon: "question",
                  iconHtml: "!",
                  confirmButtonText: "Suspendre le ticket",
                  cancelButtonText: "Annuler le ticket",
                  confirmButtonColor: theme.palette.primary.main,
                  // denyButtonText: 'Annuler le ticket',
                  showCancelButton: true,
                  showCloseButton: true
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      const ticketId =  ticketApi.addLearnerTicket(localStorage.getItem('accessToken'), 
                      ticketService, ticketCategory, ticketSeverity, ticketReferentId,
                       ticketConstat,ticketCause, ticketAcceptTracking, parseInt(customer, 10) , 
                       user.id, ticketReference, "Suspendu", ticketComment, type, 
                       ticketStartDate,ticketApproxDate,ticketEffectiveDate, ticketActionArray);
                    {type === 'ticket' ? toast.success('Ticket suspendu avec succès!') : toast.success('Réclamation suspendue avec succès!'); }
                      // console.log(ticketId);
                      handleSubmit(ticketId.ticketId);
                    } catch (err) {
                      console.log(err);
                      {toast.error('Il y a eu un souci lors de la création !');}
                    }
                    // setCurrentTab('ticketsPerso');
                    getTickets();
                    setUpdate(!update);
                    handleClose();
                  } else {
                    handleClose();
                  {type === 'ticket' ? toast.error('La création du ticket a été annulée!') : toast.error('La création de la réclamation a été annulée!')}
                  }
                });
              } else {
                
                handleClose();
                {type === 'ticket' ? toast.error('La création du ticket a été annulée!') : toast.error('La création de la réclamation a été annulée!')}
              }
            }}>
            Annuler
          </Button>
          <div title="Veuillez compléter les champs obligatoires ( * ) ">
            <Button
              onClick={handleAdd}
              color="primary"
              type="submit"     
              // title=""
             disabled={
  !(type !== 'ticket' || (type === 'ticket' && ticketService)) || // Checks if it's not a ticket type or it is a ticket type and service is provided
  !ticketSeverity || // Checks if ticketSeverity is provided
  !ticketConstat || ticketConstat === '' || // Checks if ticketConstat is provided
  !ticketCategory // Checks if ticketCategory is provided
}
            >
              {type === 'ticket' ? 'Créer le ticket' : 'Créer la réclamation'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
  </>
  );
};
export default CreateTicketsLearner;
CreateTicketsLearner.propTypes = {
  update: PropTypes.bool.isRequired,
  setUpdate: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isListTickets: PropTypes.bool.isRequired,
  listToggle: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  getTickets: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

