/* eslint-disable no-nested-ternary */
import {
  Box,
  Card,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Alert
} from '@mui/material';
import PropTypes from 'prop-types';
import Label from '../Label';
import * as moment from 'moment';
import Scrollbar from '../Scrollbar';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Tooltip, DialogTitle, TextField, CircularProgress } from '@material-ui/core';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowRightIcon from '../../icons/ArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useEffect, useState } from 'react';
import { campaignApi } from '../../api/campaignApi';
// import { Formik, ErrorMessage } from 'formik';

const CampaignCard = (props) => {
  const { infoCommercial, commercial, setOpenReinjection, setOpenRetrieve, setSelectedCommercialReinjection, setSelectedCommercialRetrieve, idCampaign, numberProspectsAttribuable, ...other } = props;
  const [openDelete, setOpenDelete] = useState(false);
  const [openAttribute, setOpenAttribute] = useState(false);
  const [commercialToDelete, setCommercialToDelete] = useState(null);
  const [commercialToAttribute, setCommercialToAttribute] = useState(null);
  const [nbToAttribute, setNbToAttribute] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setNbToAttribute(numberProspectsAttribuable);
  }, [numberProspectsAttribuable]);
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const dialogDeleteOpener = (callerId) => {
    setOpenDelete(true);
    setCommercialToDelete(callerId);
  };

  const handleCloseDialogDelete = () => {
    setOpenDelete(false);
  };

  const dialogAttributeOpener = (callerId) => {
    setOpenAttribute(true);
    setCommercialToAttribute(callerId);
  };

  const handleCloseDialogAttribute = () => {
    setOpenAttribute(false);
  };

  const handleDeleteCommercial = async () => {
    try {
      setIsLoading(true);
      await campaignApi.deleteCommercial(idCampaign, commercialToDelete, localStorage.getItem('accessToken'));
      setOpenDelete(false);
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const attributeNotAttributedCommercial = async () => {
    try {
      setIsLoading(true);
      await campaignApi.attributeNotAttributedCommercial(idCampaign, commercialToAttribute, nbToAttribute, localStorage.getItem('accessToken'));
      setOpenAttribute(false);
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card {...other}>
      <Dialog
        open={openDelete}
        onClose={handleCloseDialogDelete}
      >
        <DialogContent>
          <DialogContentText>
            Souhaitez-vous vraiment supprimer le commercial de la campagne ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteCommercial()}
            color="primary"
            disabled={isLoading}
          >
            Supprimer
          </Button>
          <Button
            onClick={handleCloseDialogDelete}
            color="primary"
          >
            Annuler
          </Button>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAttribute}
        onClose={handleCloseDialogAttribute}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Attribuer les apprenants non attribués</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Choisissez un nombre à attribuer
          </DialogContentText>
          <TextField
            // label="Nombre d'apprenants"
            type="number"
            autoFocus
            onChange={(event) => {
              setNbToAttribute(parseInt(event.target.value, 10));
            }}
            // value={values.numberToAttribute}
            // defaultValue={numberProspectsAttribuable}
            value={nbToAttribute}
            variant="outlined"
            InputProps={{ inputProps: { max: numberProspectsAttribuable } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => attributeNotAttributedCommercial()}
            color="primary"
            disabled={nbToAttribute > numberProspectsAttribuable || isLoading}
          >
            Attribuer
          </Button>
          <Button
            onClick={handleCloseDialogAttribute}
            color="primary"
          >
            Annuler
          </Button>
          {isLoading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogActions>
      </Dialog>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nom
                </TableCell>
                <TableCell>
                  Traité (Prospects)
                </TableCell>
                <TableCell sx={{ p: 2 }}>
                  Vente
                </TableCell>
                <TableCell align="center">
                  Actions
                </TableCell>
                <TableCell>
                  Moyenne de temps passé
                </TableCell>
                <TableCell>
                  Total de temps passé
                </TableCell>
                <TableCell>
                  Progression
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infoCommercial !== undefined && Object.keys(infoCommercial).map((up) => (
                <TableRow
                  key={up}
                >
                  <TableCell sx={{ mx: 'auto', width: 380 }}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex'
                      }}
                    >
                      <Avatar
                        src={infoCommercial[up].avatar}
                        {...stringAvatar(`${up.split(' ')[0].replace(/ /g, '')} ${up.split(' ')[1].replace(/ /g, '')}`)}
                      />
                      <Typography
                        variant="h6"
                      >
                        {' '}
                        &nbsp;
                        &nbsp;
                        {up}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ mx: 'auto', width: 180 }}>
                    <Alert
                      severity={(infoCommercial[up].treated / infoCommercial[up].general) >= 0.8 ? 'success' : (infoCommercial[up].treated / infoCommercial[up].general) <= 0.3 ? 'error' : 'warning'}
                    >
                      {infoCommercial[up].treated}
                      {' '}
                      (
                      {infoCommercial[up].general}
                      )
                    </Alert>
                  </TableCell>
                  <TableCell sx={{ mx: 'auto', width: 180, p: 3 }}>
                    <Label
                      sx={{ width: 50, fontSize: 18 }}
                      variant="h6"
                      color={infoCommercial[up].vente > 3 ? 'success' : infoCommercial[up].vente === 0 ? 'error' : 'warning'}
                    >
                      {infoCommercial[up].vente}
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex' }}>
                      <Tooltip title="Réinjection">
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mt: 1, mb: 3 }}
                          onClick={() => {
                            setOpenReinjection(true);
                            setSelectedCommercialReinjection(infoCommercial[up].caller_id);
                          }}
                        >
                          <RestartAltIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                      <Box sx={{ ml: 2, mr: 2 }}>
                        <Tooltip title="Attribution non attribués">
                          <Button
                            disabled={numberProspectsAttribuable === 0}
                            color="primary"
                            variant="contained"
                            sx={{ mt: 1, mb: 3 }}
                            onClick={() => {
                              dialogAttributeOpener(infoCommercial[up].caller_id);
                            }}
                          >
                            <ArrowRightIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      </Box>
                      <Box sx={{ mr: 2 }}>
                        <Tooltip title="Rattrapage">
                          <Button
                            color="primary"
                            variant="contained"
                            sx={{ mt: 1, mb: 3 }}
                            onClick={() => {
                              setOpenRetrieve(true);
                              setSelectedCommercialRetrieve(infoCommercial[up].caller_id);
                            }}
                          >
                            <GetAppIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      </Box>
                      <Tooltip title="Suppression commercial">
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{ mt: 1, mb: 3 }}
                          onClick={() => {
                            dialogDeleteOpener(infoCommercial[up].caller_id);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ mx: 'auto', width: 280 }}>
                    <Typography
                      variant="h6"
                    >
                      {Number.isNaN(infoCommercial[up].time !== undefined && infoCommercial[up].totalTreated !== undefined ? parseInt(infoCommercial[up].time, 10) / infoCommercial[up].totalTreated : '') ? '00:00:00' : infoCommercial[up].totalTreated !== 0 ? moment.utc((infoCommercial[up].time !== undefined && infoCommercial[up].totalTreated !== undefined ? parseInt(infoCommercial[up].time, 10) / infoCommercial[up].totalTreated : '') * 1000).format('HH:mm:ss') : '00:00:00'}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ mx: 'auto', width: 280 }}>
                    <Typography variant="h6">
                      {moment.utc(infoCommercial[up].time * 1000).format('HH:mm:ss')}
                    </Typography>

                  </TableCell>
                  <TableCell sx={{ mx: 'auto', width: 380 }}>
                    <LinearProgress
                      value={((infoCommercial[up].treated / infoCommercial[up].general) * 100)}
                      variant="determinate"
                      color={(infoCommercial[up].treated / infoCommercial[up].general) >= 0.8 ? 'success' : (infoCommercial[up].treated / infoCommercial[up].general) <= 0.3 ? 'error' : 'warning'}
                    />
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        mt: 2
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }} />
                      <Typography
                        color="textSecondary"
                        variant="inherit"
                      >
                        {((infoCommercial[up].treated / infoCommercial[up].general) * 100).toFixed(2)}
                        {' '}
                        %
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};
CampaignCard.propTypes = {
  infoCommercial: PropTypes.object.isRequired,
  commercial: PropTypes.object.isRequired,
  setOpenReinjection: PropTypes.func,
  setOpenRetrieve: PropTypes.func,
  setSelectedCommercialReinjection: PropTypes.func,
  setSelectedCommercialRetrieve: PropTypes.func,
  idCampaign: PropTypes.string,
  numberProspectsAttribuable: PropTypes.number
};
export default CampaignCard;
