/* eslint-disable no-unused-vars */
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Scrollbar from '../../Scrollbar';
import * as moment from 'moment';
import { ProgressBar } from '../session';

const CampaignCustomerFormationsListTable = (props) => {
  const { customer, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nom
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  N° session
                </TableCell>
                <TableCell>
                  Date de début
                </TableCell>
                <TableCell>
                  Date de fin
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  Progression
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  Dernière connexion
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  Statut
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customer.formations.map((formation) => (
                <TableRow
                  hover
                  key={formation.id}
                >
                  <TableCell>
                    <Box sx={{ ml: 1 }}>
                      <Link
                        color="inherit"
                        component={RouterLink}
                        to={`/dashboard/session/${formation.session_id}`}
                        variant="subtitle1"
                      >
                        {formation.name}
                      </Link>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {formation.number_session}
                  </TableCell>
                  <TableCell>
                    {moment(formation.start_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {moment(formation.end_date).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <ProgressBar
                      value={formation.progression}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {formation.last_connection !== null ? moment(formation.last_connection).format('DD/MM/YYYY') : '-'}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {formation.status}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

CampaignCustomerFormationsListTable.propTypes = {
  customer: PropTypes.object.isRequired
};

export default CampaignCustomerFormationsListTable;
