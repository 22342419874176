/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, frFR } from '@mui/x-data-grid';
import { Typography, Link, Card, Box, Avatar, IconButton, TableCell } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import useAuth from '../../../hooks/useAuth';
import { formationApi } from '../../../api/formationApi';
import ArrowRightIcon from '../../../icons/ArrowRight';
import toast from 'react-hot-toast';
import Label from '../../Label';

const SessionFormationResultTable = (props) => {
  const { sessionsFormation, setSessionsFormation, ...other } = props;
  const rows = [];
  const { user } = useAuth();

  const handleDelete = async (id) => {
    try {
      await formationApi.deleteSession(id, localStorage.getItem('accessToken'));
      const tempSessions = [...sessionsFormation];
      tempSessions.splice(tempSessions.findIndex((sess) => sess.id === id), 1);
      setSessionsFormation(tempSessions);
      toast.success('Session supprimée !');
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la suppression !');
    }
  };

  const CustomToolbar = () => (user.id_service === 0
    ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
    : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    ));
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
  sessionsFormation.forEach((item) => {
    // eslint-disable-next-line no-unused-expressions
    item.name !== null
      ? rows.push({ id: item.id,
        name: item.name,
        number_session: item.number_session,
        start_date: item.start_date,
        end_date: item.end_date,
        status: item.status,
        nombre: item.nombre,
        image: item.image,
        programNumber: item.program_number,
        idFormation: item.idFormation
      }) : null;
  });

  // Erreur avec le react/destructuring-assignment donc désactivation d'eslint sur les lignes concernées
  const columns = [
    { field: 'name',
      headerName: 'Nom',
      sx: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      },
      width: 500,
      renderCell: (cellValues) => (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Avatar
            src={cellValues.row.image}
            sx={{
              height: 50,
              width: 50,
            }}
            {...stringAvatar(`${cellValues.row.name.replace(/ /g, '')} ${cellValues.row.name.replace(/ /g, '')}`)}
          />
          <Box sx={{ ml: 1 }}>
            <Link
              sx={{ whiteSpace: 'normal', width: 450, fontWeight: 600, fontSize: 15 }}
              component={RouterLink}
              to={`/dashboard/session/${cellValues.row.id}`}
              variant="subtitle2"
            >
              {cellValues.row.name}
            </Link>
          </Box>
        </Box>
      ) },
    { field: 'programNumber', headerName: 'N° Programme' },
    { field: 'numberSession',
      headerName: 'N° session',
      width: 130,
      renderCell: (cellValues) => (
        <Link
          sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 15 }}
          component={RouterLink}
          to={`/dashboard/session/${cellValues.row.id}`}
          variant="subtitle2"
        >
          {cellValues.row.number_session}
        </Link>
      ) },
    { field: 'startDate', headerName: 'Date de début', width: 200, renderCell: (cellValues) => (<Typography>{cellValues.row.start_date}</Typography>) },
    { field: 'endDate', headerName: 'Date de fin', width: 200, renderCell: (cellValues) => (<Typography>{cellValues.row.end_date}</Typography>) },
    // eslint-disable-next-line no-nested-ternary
    { field: 'status', headerName: 'Statut', width: 160, renderCell: (cellValues) => (<Label color={cellValues.row.status === 'Active' ? 'success' : cellValues.row.status === 'Annulée' ? 'warning' : 'inscritD'}>{cellValues.row.status}</Label>) },
    { field: 'nombre', headerName: 'Nombre', width: 230, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.nombre}</Typography>) },
    { field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (cellValues) => (
        <TableCell align="right">
          <IconButton
            component={RouterLink}
            to={`/dashboard/formations/${cellValues.row.idFormation}`}
          >
            <ArrowRightIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(cellValues.row.id)}
            disabled={user.access_level !== 0}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </TableCell>
      ) },
  ];
  return (
    <Card
      {...other}
    >
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        autoHeight
        rowHeight={80}
        rows={rows}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[30, 100]}
        components={{ Toolbar: CustomToolbar }}
      />
    </Card>
  );
};
SessionFormationResultTable.propTypes = {
  sessionsFormation: PropTypes.array.isRequired,
  setSessionsFormation: PropTypes.func.isRequired
};

export default SessionFormationResultTable;
