/* eslint-disable no-unused-vars */
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import CustomerFormationsListTable from './CustomerFormationsListTable';
import { sessionApi } from '../../../api/sessionApi';
import toast from 'react-hot-toast';
import {
  Autocomplete,
  ToggleButton,
  Button,
  DialogActions,
  ToggleButtonGroup,
  Card,
  Grid,
  Box,
  CardContent,
  CardHeader,
  Link,
  InputAdornment,
  List,
  Dialog,
  ListItem,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import AttestationPDF from '../../AttestationPDF';
import SearchIcon from '../../../icons/Search';
import ArrowLeftIcon from '../../../icons/ArrowLeft';
import ViewListIcon from '@material-ui/icons/ViewList';
import * as moment from 'moment';
import Label from '../../Label';

const applyFilters = (formations, query) => formations
  .filter((formation) => {
    let matches = true;

    if (query) {
      const properties = ['name', 'sourceFirstname', 'sourceLastname'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (formation[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }
    return matches;
  });
const sortOptions = [
  {
    label: 'Plus récentes',
    value: 'start_date|desc'
  },
  {
    label: 'Plus anciennes',
    value: 'start_date|asc'
  },
  {
    label: 'Plus avancé',
    value: 'progression|desc'
  },
  {
    label: 'Moins avancé',
    value: 'progression|asc'
  }
];

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (formations, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = formations.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const CustomerFormationsList = (props) => {
  const { formations, setFormations, apprenant, orientations, formationsDec, sources, formationsInformations, ...other } = props;
  const [showStats, setShowStats] = useState(true);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState(sortOptions[0].value);
  const filteredFormations = applyFilters(formations, query);
  const sortedFormations = applySort(filteredFormations, sort);
  const [viewPDF, setViewPDF] = useState(false);
  const [show, setShow] = useState(false);
  const [showDec, setShowDec] = useState(false);
  const [disableChoiceSessions, setDisableChoiceSessions] = useState(true);
  const [idSession, setIdSession] = useState();
  const [indexFormation, setIndexFormation] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [valueIdSession, setValueIdSession] = useState(false);
  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleTabsChange = () => {
    setShowStats(!showStats);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const handleCloseDialog = () => {
    setShowAlert(false);
    setShowDec(false);
  };

  const handleAdd = async () => {
    try {
      await sessionApi.addCustomerFormation(apprenant.id, idSession, localStorage.getItem('accessToken'));
      window.location.reload();
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la modification !');
    }
  };

  return (
    <Card {...other}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 500
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Chercher une formation"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          container
          sx={{
            m: 1,
            width: 240
          }}
        >
          <TextField
            label="Trier par"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>

        <Box>
          <Button
            color="primary"
            sx={{
              ml: 120,
              m: -11,
            }}
            variant="outlined"
            onClick={handleShow}
          >
            + Ajouter dans une formation
          </Button>
        </Box>
        {show && (
        <>
          <Grid
            item
            md={4}
            xs={1}
          >
            <Dialog
              onClose={handleCloseDialog}
              open={show}
            >
              <DialogTitle>
                Ajouter dans une formation
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs
                      width={600}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => `${option.name} ${option.program_number}`}
                        // getOptionLabel={(option) => option.name}
                        options={formationsDec}
                        onChange={(e, value) => {
                          if (value !== null) {
                            setDisableChoiceSessions(false);
                            setIndexFormation(formationsDec.indexOf(value));
                          } else {
                            setDisableChoiceSessions(true);
                            setIndexFormation(0);
                          }
                          setIdSession(null);
                          setValueIdSession(!valueIdSession);
                        }}
                        // value={values.formation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Choisir une formation"
                            name="choiceFormations"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs
                      width={600}
                    >
                      <Autocomplete
                      // pour réinitialiser le autocomplete on lui change la valeur de sa key, quand on change de formation, cela réinitialise le champ
                        key={valueIdSession}
                        getOptionLabel={(option) => `${option.number_session}. Début: ${moment(option.start_date).format('DD/MM/YYYY')}, Fin: ${moment(option.end_date).format('DD/MM/YYYY')}`}
                        options={formationsDec[indexFormation].sessions}
                        onChange={(e, value) => {
                          setIdSession(value.id);
                        }}
                        disabled={disableChoiceSessions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Choisir une session"
                            name="choiceFormations"
                            variant="outlined"
                            disabled={disableChoiceSessions}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleShow}
                  color="primary"
                >
                  Annuler
                </Button>
                <Button
                  onClick={handleAdd}
                  color="primary"
                  type="submit"
                >
                  Enregistrer
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </>
        )}
        <ToggleButtonGroup
          exclusive
          sx={{ ml: 70 }}
        >
          <ToggleButton
            style={{ color: showStats === false ? '#7EB627' : 'inherit' }}
            value="grid"
            onClick={handleTabsChange}
          >
            <ViewListIcon
              fontSize="small"
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {sortedFormations.map((formation) => (
        <>
          {showStats === false && (
          <Card
            sx="margin:20px;"
          >
            <CardHeader
              style={{ paddingBottom: 0 }}
              title={(
                <Link
                  component={RouterLink}
                  to={`/dashboard/session/${formation.session_id}`}
                  variant="subtitle"
                >
                  {formation.name}
                </Link>
        )}
            />
            <CardContent sx={{ pt: 0 }}>
              <List>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Attestation DPC
                  </Typography>
                  <Grid item>
                    <Box sx={{ m: -1 }}>
                      <Button
                        color="primary"
                        disabled
                        onClick={() => setViewPDF(true)}
                        sx={{ m: 1 }}
                        variant="outlined"
                      >
                        Prévisualiser
                      </Button>
                      <PDFDownloadLink
                        document={(
                          <AttestationPDF
                            customer={apprenant}
                            formation={formation}
                            orientations={orientations}
                          />
)}
                        fileName={['Attestation DPC', apprenant.firstname, apprenant.lastname]}
                        style={{ textDecoration: 'none' }}
                      >
                        <Button
                          disabled
                          document={(
                            <AttestationPDF
                              customer={apprenant}
                              formation={formation}
                              orientations={orientations}
                            />
)}
                          sx={{ m: 1 }}
                          variant="outlined"
                          color="primary"
                        >
                          Télécharger
                        </Button>
                      </PDFDownloadLink>
                    </Box>
                  </Grid>
                </ListItem>
                <Dialog
                  fullScreen
                  open={viewPDF}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'background.default',
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        startIcon={<ArrowLeftIcon fontSize="small" />}
                        onClick={() => setViewPDF(false)}
                        variant="contained"
                      >
                        Back
                      </Button>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                      <PDFViewer
                        height="100%"
                        style={{ border: 'none' }}
                        width="100%"
                      >
                        <AttestationPDF
                          apprenant={apprenant}
                          formation={formation}
                        />
                      </PDFViewer>
                    </Box>
                  </Box>
                </Dialog>

                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Numéro de programme
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {formation.programNumber}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Numéro de session
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {formation.number_session}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Date de début
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {moment(formation.start_date).format('DD/MM/YYYY')}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Date de fin
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {moment(formation.end_date).format('DD/MM/YYYY')}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Progression
                  </Typography>
                  <Typography
                  // eslint-disable-next-line no-nested-ternary
                    color={formation.progression < 30 ? '#dd0000' : formation.progression > 70 ? '#00dd00' : '#bbbb00'}
                    variant="body2"
                  >
                    {formation.progression}
                    {' %'}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Dernière connexion
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {formation.last_connection !== null ? moment(formation.last_connection).format('DD/MM/YYYY HH:mm:ss') : ''}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Durée totale
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {formation.duration}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Statut
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {formation.status === 'Inscrit' && (
                    <Label
                      color="success"
                    >
                      {formation.status}
                    </Label>
                    )}
                    {(formation.status === 'En attente' || formation.status === 'À transférer') && (
                    <Label
                      color="warning"
                    >
                      {formation.status}
                    </Label>
                    )}
                    {(formation.status === 'InscritD') && (
                    <Label
                      color="inscritD"
                    >
                      {formation.status}
                    </Label>
                    )}
                    {(formation.status === 'Décalage') && (
                    <Label
                      color="secondary"
                    >
                      {formation.status}
                    </Label>
                    )}
                    {(formation.status === '- de 5 personnes' || formation.status === 'Annulation' || formation.status === 'Désinscrit') && (
                    <Label
                      color="error"
                    >
                      {formation.status}
                    </Label>
                    )}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Prix
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {formation.real_price}
                    {' €'}
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Prise en charge
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    DPC
                  </Typography>
                </ListItem>
                <ListItem
                  disableGutters
                  sx={{
                    justifyContent: 'space-between',
                    padding: 1
                  }}
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    Source
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {formation.sourceFirstname}
                    {' '}
                    {formation.sourceLastname}
                  </Typography>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          )}
        </>
      ))}
      {showStats === true && (
      <Grid
        sx={{
          backgroundColor: 'FFFFFF',
          p: 2
        }}
      >
        <CustomerFormationsListTable />
      </Grid>
      )}
    </Card>
  );
};

CustomerFormationsList.propTypes = {
  formations: PropTypes.array.isRequired,
  setFormations: PropTypes.func.isRequired,
  apprenant: PropTypes.array.isRequired,
  orientations: PropTypes.array.isRequired,
  formationsDec: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
  formationsInformations: PropTypes.array.isRequired
};
export default CustomerFormationsList;
