/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Typography, Card, Divider, IconButton, Box } from '@material-ui/core';
import { useState, useEffect } from 'react';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import * as moment from 'moment';

const CampaignTotal = (props) => {
  const { occupationAndSource, professions } = props;
  const [open, setOpen] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (professions !== undefined) {
      if (professions.length > 0) {
        const tempRows = [];
        const total = { id: 1000, name: 'Total' };
        professions.forEach((prof) => {
          total[prof.profession] = { treated: { treated: 0 }, general: 0 };
        });
        let subTotalHRowGeneral = 0;
        let subTotalHRowTreated = 0;
        // eslint-disable-next-line array-callback-return
        Object.keys(occupationAndSource).map((source) => {
          let row = { id: source, name: source };
          const totalHRow = { general: 0, treated: 0 };
          professions.forEach((profession) => {
            row[profession.profession] = occupationAndSource[source][profession.profession];
            total[profession.profession].general += occupationAndSource[source][profession.profession].general;
            total[profession.profession].treated.treated += occupationAndSource[source][profession.profession].treated.treated;
            totalHRow.general += occupationAndSource[source][profession.profession].general;
            totalHRow.treated += occupationAndSource[source][profession.profession].treated.treated;
          });
          subTotalHRowGeneral += totalHRow.general;
          subTotalHRowTreated += totalHRow.treated;
          // partie historique
          const historic = {};
          Object.keys(occupationAndSource[source].historique).forEach((date) => {
            historic[date] = occupationAndSource[source]['historique'][date];
            let totalTreated = 0;
            let totalProspects = 0;
            Object.keys(occupationAndSource[source]['historique'][date]).forEach((data) => {
              totalTreated += occupationAndSource[source]['historique'][date][data].general;
              totalProspects += occupationAndSource[source]['historique'][date][data].treated.treated;
            });
            historic[date].total = { treated: { treated: totalProspects }, general: totalTreated };
          });

          row = { ...row, totalH: totalHRow, historic };

          tempRows.push(row);
        });
        total.totalH = { general: subTotalHRowGeneral, treated: subTotalHRowTreated };
        tempRows.push(total);

        setRows(tempRows);
      }
      const tempOpen = [];
      Object.keys(occupationAndSource).forEach(() => tempOpen.push(false));
      tempOpen.push(false);
      setOpen(tempOpen);
    }
  }, [professions]);

  const handleOpen = (index) => {
    const tempOpen = [...open];
    tempOpen[index] = !tempOpen[index];
    setOpen(tempOpen);
  };

  return (
    <Card>
      <Typography
        variant="h6"
        sx={{ p: 2 }}
      >
        Nombre de prospects par profession et par source
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="collapsible table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sx={{ width: 200 }}>Nom</TableCell>
              {professions.map((profession) => <TableCell align="right">{profession.profession}</TableCell>)}
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <>
                <TableRow
                  key={row.name}
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                >
                  <TableCell>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleOpen(index)}
                    >
                      {open[index] && row.name !== 'Total' ? <KeyboardArrowUpIcon /> : !open[index] && row.name !== 'Total' ? <KeyboardArrowDownIcon /> : ''}
                    </IconButton>
                  </TableCell>
                  {open[index] ? (
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <Typography
                        fontSize={24}
                        fontWeight="bold"
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell
                      component="th"
                      scope="row"
                    >
                      <Typography>{row.name}</Typography>
                    </TableCell>
                  )}
                  {professions.map((profession) => <TableCell align="right"><Typography color={row[profession.profession].treated.treated === row[profession.profession].general ? 'green' : 'inherit'}>{`${row[profession.profession].treated === null ? 0 : row[profession.profession].treated.treated} (${row[profession.profession].general})`}</Typography></TableCell>)}
                  <TableCell align="right"><Typography color={row.totalH.treated === row.totalH.general ? 'green' : 'inherit'}>{`${row.totalH.treated === undefined ? 0 : row.totalH.treated} (${row.totalH === undefined ? 0 : row.totalH.general})`}</Typography></TableCell>
                </TableRow>
                <TableRow>
                  {row.name !== 'Total' && (
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={12}
                  >
                    <Collapse
                      in={open[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          component="div"
                        >
                          Historique
                        </Typography>
                        <Table
                          size="small"
                          aria-label="purchases"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Niveau de réinjection</TableCell>
                              {professions.map((profession) => <TableCell align="right">{profession.profession}</TableCell>)}
                              <TableCell align="right">Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.historic !== undefined && (
                              Object.keys(row.historic).map((histoData) => (
                                <TableRow>
                                  <TableCell align="right">{histoData}</TableCell>
                                  {Object.keys(row.historic[histoData]).map((profession) => (
                                    <TableCell align="right">{`${row.historic[histoData][profession] === undefined && row.historic[histoData][profession].treated === null ? 0 : row.historic[histoData][profession].treated.treated} (${row.historic[histoData][profession].general})`}</TableCell>
                                  ))}
                                </TableRow>
                              )))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                  )}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

CampaignTotal.propTypes = {
  occupationAndSource: PropTypes.array.isRequired,
  professions: PropTypes.array.isRequired,
};

export default CampaignTotal;
