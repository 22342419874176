/* eslint-disable react/jsx-no-bind */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  Grid,
  Typography,
  Button,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { deliveryApi } from '../../api/partnerApi';
import AddIcon from '@mui/icons-material/Add';
import { getTodayDate, getCurrentYear, getYearFromDateString } from '../../utils/dateFormatting';
import { DeliveryGrid } from './DeliveryGrid';

const PartnerDeliveries = (props) => {
  const {
    partnerId,
    partnerName,
    startContract,
    endContract,
    deliveries,
    setDeliveries,
    filteredDeliveries,
    setFilteredDeliveries,
    yearFilter,
    setYearFilter,
    dates,
    updateDeliveriesFormValues,
    ...other
  } = props;
    // const mounted = useMounted();

  // const [modifyText, setModifyText] = useState("");
  async function handleAdd() {
    const changeDeliveries = deliveries?.length > 0 ? [...deliveries] : [];
    const deliveryAdd = {
      name: '',
      price: 0,
      start_delivery:
          yearFilter === 'all' || yearFilter === getCurrentYear()
            ? getTodayDate()
            : `${yearFilter}-01-01`,
      end_delivery: yearFilter === 'all' || yearFilter === getCurrentYear()
        ? getTodayDate()
        : `${yearFilter}-01-01`,
      destination: true,
      partner_id: partnerId,
    };
    const returnValues = await deliveryApi
      .addDelivery(window.localStorage.getItem('accessToken'), deliveryAdd, partnerId)
      .catch((err) => {
        console.log(err);
      });
    changeDeliveries.push({ ...deliveryAdd, id: returnValues.id });
    setDeliveries(changeDeliveries);
  }

  async function handleDelete(deliveryId) {
    const changeDeliveries = [...deliveries];
    const index = changeDeliveries
      .map((delivery) => delivery.id)
      .indexOf(deliveryId);

    changeDeliveries.splice(index, 1);
    setDeliveries(changeDeliveries);
    await deliveryApi.deleteDelivery(window.localStorage.getItem('accessToken'), deliveryId).catch((err) => {
      console.log(err);
    });
  }

  async function handleModify(field, deliveryId, data = null) {
    const changeDeliveries = [...deliveries];
    const index = changeDeliveries
      .map((delivery) => delivery.id)
      .indexOf(deliveryId);

    if (field === 'destination') {
      data = !changeDeliveries[index][field];
      changeDeliveries[index][field] = data;
      setDeliveries(changeDeliveries);
    } else if (field === 'start_delivery') {
      const yearData = getYearFromDateString(data);
      changeDeliveries[index][field] = data;
      setDeliveries(changeDeliveries);

      if (yearData !== yearFilter && yearFilter !== 'all') setYearFilter(yearData);
    } else {
      changeDeliveries[index][field] = data;
      setDeliveries(changeDeliveries);
    }

    await deliveryApi.modifyDelivery(window.localStorage.getItem('accessToken'), changeDeliveries[index].id, field, data);
  }

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        p: 3,
        pt: 1
      }}
    >
      <Card sx={{ p: 3, m: 2 }}>
        <Grid
          container
          spacing={2}
          marginBottom={3}
        >
          <Button
            variant="contained"
            aria-label="split button"
            onClick={() => handleAdd()}
          >
            <AddIcon />
            <Typography>Ajouter une prestation</Typography>
          </Button>
        </Grid>
        {filteredDeliveries.map((delivery, index) => (index < filteredDeliveries.length - 1 ? (
          <>
            <DeliveryGrid
              deliveryId={delivery.id}
              name={delivery.name}
              startContract={startContract}
              endContract={endContract}
              price={delivery.price}
              startDelivery={delivery.start_delivery}
              endDelivery={delivery.end_delivery}
              destination={delivery.destination}
              partnerName={partnerName}
              handleModify={handleModify}
              handleDelete={handleDelete}
              yearFilter={yearFilter}
              updateDeliveriesFormValues={updateDeliveriesFormValues}
            />
            <Divider />
          </>
        ) : (
          <DeliveryGrid
            deliveryId={delivery.id}
            name={delivery.name}
            startContract={startContract}
            endContract={endContract}
            price={delivery.price}
            startDelivery={delivery.start_delivery}
            endDelivery={delivery.end_delivery}
            destination={delivery.destination}
            partnerName={partnerName}
            handleModify={handleModify}
            handleDelete={handleDelete}
            updateDeliveriesFormValues={updateDeliveriesFormValues}
          />
        )))}
      </Card>
    </Box>
  );
};

/* PartnerDeliveries.propTypes = {
    partner: PropTypes.object.isRequired,
  }; */

export default PartnerDeliveries;
