/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import Chart from 'react-apexcharts';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import ArrowRightIcon from '../../icons/ArrowRight';
import { campaignApi } from '../../api/campaignApi';
import { useCallback, useState, useEffect } from 'react';
import useMounted from '../../hooks/useMounted';
import * as moment from 'moment';
import useAuth from '../../hooks/useAuth';
import DeleteIcon from '@material-ui/icons/Delete';
import toast from 'react-hot-toast';
import Paper from '@mui/material/Paper';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import FormationResultTable from '../dashboard/formation/FormationResultTable';
import CampaignResultTable from './CampaignResultTable';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';

const professions = [
  'Médecin', 'Pédicure-podologue', 'Sage-Femme', 'Infirmier', 'Masseur-kinésithérapeute', 'Pharmacien'
];

const sortOptions = [
  {
    label: 'Pas de tri',
    value: 'default'
  },
  {
    label: 'Nom (A - Z)',
    value: 'name|asc'
  },
  {
    label: 'Nom (Z - A)',
    value: 'name|desc'
  },
  {
    label: 'Progression croissant',
    value: 'progression|asc'
  },
  {
    label: 'Progression décroissant',
    value: 'progression|desc'
  },
  {
    label: 'Date de début croissant',
    value: 'startDate|asc'
  },
  {
    label: 'Date de début décroissant',
    value: 'startDate|desc'
  },
  {
    label: 'Date de fin croissant',
    value: 'endDate|asc'
  },
  {
    label: 'Date de fin décroissant',
    value: 'endDate|desc'
  }
];

const typeOptions = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Campagnes',
    value: 'campaigns'
  },
  {
    label: 'Réinjections',
    value: 'reinjections'
  },
];

const CampaignStats = () => {
  const theme = useTheme();
  const mounted = useMounted();
  const { user } = useAuth();
  const [infoCampaign, setInfoCampaign] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDelete1, setOpenDelete1] = useState(false);
  const [campaignDelete, setCampaignDelete] = useState('');
  const [region, setRegion] = useState('all');
  const [profession, setProfession] = useState('Tous');
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState([]);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [type, setType] = useState(typeOptions[0].value);
  const [commercial, setCommercial] = useState([]);
  const [selectedCommercial, setSelectedCommercial] = useState([]);
  const [names, setNames] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [mode, setMode] = useState('list');
  const [isLoading, setIsLoading] = useState(true);

  const handleModeChange = (event, value) => {
    if (value !== null) {
      setMode(value);
    }
  };

  const dialogDelete = (index) => {
    setOpenDelete(true);
    setCampaignDelete(index);
  };

  const handleDeleteCampaign = async (index) => {
    try {
      await campaignApi.deleteCampaign(infoCampaign[index] !== undefined ? infoCampaign[campaignDelete].id : '', localStorage.getItem('accessToken'));
      const tempInfoCampaign = [...infoCampaign];
      tempInfoCampaign.splice(index, 1);
      setInfoCampaign(tempInfoCampaign);
      toast.success('Campagne supprimée !');
      setOpenDelete(false);
      setOpenDelete1(false);
    } catch (err) {
      console.error(err);
      toast.error('Erreur lors de la suppression de la campagne.');
    }
  };

  const getCampaign = useCallback(async () => {
    try {
      const data = await campaignApi.getCampaign(region, department, profession, sort, commercial, type, localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('La campagne n\'existe pas');
      }

      if (mounted.current) {
        setInfoCampaign(data.infoCampaign);
        setRegions(data.regionsList);
        setNames(data.names);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCampaign();
  }, [getCampaign]);

  const handleCampaigns = useCallback(async (regionChange, departmentChange, professionChange, sortChange, commercialChange, typeChange) => {
    try {
      setIsLoading(true);
      const data = await campaignApi.getCampaign(regionChange, departmentChange, professionChange, sortChange, commercialChange, typeChange, localStorage.getItem('accessToken'));
      setInfoCampaign(data.infoCampaign);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  });

  const handleRegion = (value) => {
    setRegion(value);
    handleCampaigns(value, department, profession, sort, commercial, type);
  };

  const handleProfession = (value) => {
    setProfession(value);
    handleCampaigns(region, department, value, sort, commercial, type);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
    handleCampaigns(region, department, profession, event.target.value, commercial, type);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
    handleCampaigns(region, department, profession, sort, commercial, event.target.value);
  };
  const handleCommercial = (value) => {
    setCommercial(value);
    handleCampaigns(region, department, profession, sort, value, type);
  };

  const handleDepartement = (value) => {
    setDepartment(value);
    handleCampaigns(region, value, profession, sort, commercial, type);
  };

  const getDepartments = async () => {
    const urlDepartments = 'https://geo.api.gouv.fr/departements';
    try {
      await (await fetch(urlDepartments)).json().then((value) => {
        setDepartments(value);
      });
      return true;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    getDepartments();
  }, []);

  const reinitializeFilters = () => {
    // on réinitialise les filtres
    setSort('default');
    setSelectedCommercial([]);
    setSelectedDepartments([]);
    setSelectedProfession([]);
    setSelectedRegion([]);
    setRegion([]);
    setDepartment([]);
    setProfession([]);
    setCommercial([]);
    setType('all');
    // on affiche les résultats réinitialisés
    handleCampaigns('all', [], 'Tous', 'default', [], 'all');
  };

  return (
    <Box>
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
      >
        <DialogContent>
          <DialogContentText>
            {`Souhaitez-vous vraiment supprimer la campagne ${infoCampaign[campaignDelete] !== undefined ? infoCampaign[campaignDelete].name : ''} ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteCampaign(campaignDelete)}
            color="primary"
          >
            Supprimer
          </Button>
          <Button
            onClick={() => setOpenDelete(false)}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 2,
        }}
      >
        <Grid
          container
        >
          <Grid
            item
            mt={2}
            xs={12}
            display="flex"
          >
            <Autocomplete
              fullWidth
              multiple
              id="tags-standard-region"
              options={regions}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                <Chip
                  label={option.region}
                  {...getTagProps({ index })}
                />
              ))}
              onChange={(event, newValue) => {
                handleRegion(newValue);
                setSelectedRegion([
                  ...newValue,
                ]);
              }}
              onUpdateInput={
              () => {
                handleCampaigns('all', department, profession, sort, commercial, type);
              }
            }
              getOptionLabel={(option) => option.region}
              value={selectedRegion}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Régions"
                  width={300}
                />
              )}
            />
            <Autocomplete
              sx={{ ml: 2 }}
              fullWidth
              multiple
              id="tags-standard-department"
              options={departments}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                <Chip
                  label={option.nom}
                  {...getTagProps({ index })}
                />
              ))}
              onChange={(event, newValue) => {
                handleDepartement(newValue);
                setSelectedDepartments([
                  ...newValue,
                ]);
              }}
              onUpdateInput={
              () => {
                handleCampaigns(region, 'Tous', profession, sort, commercial, type);
              }
            }
              getOptionLabel={(option) => `${option.code} - ${option.nom}`}
              value={selectedDepartments}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Départements"
                  width={300}
                />
              )}
            />
            <Autocomplete
              sx={{ ml: 2 }}
              fullWidth
              multiple
              id="tags-standard-profession"
              options={professions}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                />
              ))}
              onChange={(event, newValue) => {
                handleProfession(newValue);
                setSelectedProfession([
                  ...newValue,
                ]);
              }}
              onUpdateInput={
              () => {
                handleCampaigns(region, department, 'Tous', sort, commercial, type);
              }
            }
              getOptionLabel={(option) => option}
              value={selectedProfession}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Professions"
                  width={300}
                />
              )}
            />
          </Grid>
          <Grid
            item
            mt={4}
            xs={12}
            display="flex"
          >
            {user.access_level === 0 && (
            <Autocomplete
              sx={{ mr: 2 }}
              fullWidth
              multiple
              id="tags-standard-commercial"
              options={names}
              renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                <Chip
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))}
              onChange={(event, newValue) => {
                handleCommercial([
                  ...newValue,
                ]);
                setSelectedCommercial([
                  ...newValue,
                ]);
              }}
              onUpdateInput={
              () => {
                handleCampaigns(region, department, profession, sort, 'Tous', type);
              }
            }
              getOptionLabel={(option) => option.name}
              value={selectedCommercial}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Commerciaux"
                  width={300}
                />
              )}
            />

            )}

            <Grid
              container
              mb={2}
            >
              <Button
                onClick={reinitializeFilters}
                color="primary"
                sx={{ ml: 2 }}
                variant="outlined"
              >
                Réinitialiser les filtres
              </Button>
            </Grid>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
              spacing={2}
            >
              <ToggleButtonGroup
                sx={{ ml: 3 }}
                exclusive
                onChange={handleModeChange}
                size="small"
                value={mode}
              >
                <ToggleButton
                  style={{ color: mode === 'list' ? '#7EB627' : 'inherit' }}
                  value="list"
                >
                  <ViewListIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton
                  style={{ color: mode === 'grid' ? '#7EB627' : 'inherit' }}
                  value="grid"
                >
                  <ViewModuleIcon fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
          {mode !== 'list' && (
            <>
              {infoCampaign.map((info, index) => {
                const chartOptions = {
                  chart: {
                    background: 'transparent',
                    stacked: false,
                    toolbar: {
                      show: false
                    }
                  },
                  colors: [info.progression > 0 ? '#7EB627' : 'grey'],
                  labels: [`${info.progression === null ? 0 : info.progression} %`],
                  plotOptions: {
                    radialBar: {
                      dataLabels: {
                        value: {
                          show: false
                        }
                      },
                      hollow: {
                        size: '60%'
                      },
                      track: {
                        background: [info.progression > 0 ? theme.palette.background.default : 'grey']
                      }
                    }
                  },
                  theme: {
                    mode: theme.palette.mode
                  }
                };
                const chartSeries = [info.progression === null ? 0 : info.progression];
                let borderColor = '#7EB627';
                if (new Date() < new Date(info.start_date)) {
                  borderColor = '#87CEEB';
                } else if (new Date() > new Date(info.end_date)) {
                  borderColor = 'grey';
                }
                return (
                  <Grid
                    item
                    sx={{
                      backgroundColor: 'background.default',
                      p: 1,
                      py: 2.5
                    }}
                  >
                    <Paper
                      sx={{
                        border: `2px solid ${borderColor}`,
                        backgroundColor: info.type === 'réinjection' && theme.palette.mode === 'light' ? '#e7fff9' : info.type === 'réinjection' && theme.palette.mode === 'dark' ? '#2c3152'/* '#303557' */ : info.type === 'campagne' && theme.palette.mode === 'light' ? 'white' : 'grey'
                      }}
                    >
                      <CardContent
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Grid item>
                          <Box sx={{ width: 210, height: 100, marginBottom: 4 }}>
                            <Chart
                              options={chartOptions}
                              series={chartSeries}
                              type="radialBar"
                            />
                          </Box>
                        </Grid>

                        <Box>
                          <Box sx={{ width: '250px' }}>
                            <Typography
                              color="primary"
                              variant="h5"
                              gutterBottom
                            >
                              {info.name}
                            </Typography>
                            <Typography
                              color={theme.palette.mode === 'light' ? '#7EB627' : 'white'}
                              sx={{ mt: 1 }}
                              variant="subtitle2"
                            >
                              Début :
                              {' '}
                              {moment(new Date(info.start_date)).format('DD/MM/YYYY')}
                              <br />
                              Fin :
                              {' '}
                              {moment(new Date(info.end_date)).format('DD/MM/YYYY')}
                            </Typography>
                          </Box>
                          <Box sx={{ flexGrow: 1 }} />
                        </Box>
                      </CardContent>
                      <Typography
                        sx={{ width: 480, p: 1 }}
                        color="primary"
                        variant="subtitle2"
                      >
                        {user.access_level === 0 ? `Commerciaux : ${info.commercials}` : ''}
                      </Typography>
                      <Divider />
                      <CardActions
                        disableSpacing
                        sx={{ display: 'flex', justifyContent: 'space-between', color: `2px solid ${borderColor}` }}
                      >
                        <Button
                          component={RouterLink}
                          to={`/dashboard/campaign/${info.id}`}
                          color="primary"
                          endIcon={<ArrowRightIcon fontSize="small" />}
                          variant="text"
                        >
                          Plus d&apos;informations
                        </Button>
                        <IconButton
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                          disabled={user.access_level !== 0}
                          onClick={() => dialogDelete(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </CardActions>
                    </Paper>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
        {mode === 'list' && (
        <Grid>
          <CampaignResultTable
            infoCampaign={infoCampaign}
            setInfoCampaign={setInfoCampaign}
            handleDeleteCampaign={handleDeleteCampaign}
            campaignDelete={campaignDelete}
            setCampaignDelete={setCampaignDelete}
            openDelete1={openDelete1}
            setOpenDelete1={setOpenDelete1}
            isLoading={isLoading}
          />
        </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CampaignStats;
