/* eslint-disable linebreak-style */
import { Helmet } from 'react-helmet-async';
import PartnersList from '../../components/partners/PartnersList';
import { PartnersProvider } from '../../contexts/PartnerContext';

function Partners() {
  return (
    <>
      <Helmet>
        <title>Partenaires | APTEED</title>
      </Helmet>
      <PartnersProvider>
        <PartnersList />
      </PartnersProvider>
    </>
  );
}

export default Partners;
