import { Link as RouterLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
  Breadcrumbs,
  Container
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import countries from '../account/countries';
import { Helmet } from 'react-helmet-async';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../icons/ChevronRight';
import { useLocation } from 'react-router';
import useMounted from '../../../hooks/useMounted';
import { useCallback, useEffect, useState } from 'react';
import { usersApi } from '../../../api/usersApi';

const UserGeneralSettings = (props) => {
  const path = useLocation();
  const userId = path.pathname.split('/')[3];
  const [userToUpdate, setUserToUpdate] = useState({});
  const { user } = useAuth();
  const { settings } = useSettings();
  const mounted = useMounted();

  const getUserInfos = useCallback(async () => {
    try {
      const data = await usersApi.getUserInfos(window.localStorage.getItem('accessToken'), userId);
      if (mounted.current) {
        setUserToUpdate(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getUserInfos();
  }, [getUserInfos]);

  return (
    <>
      <Helmet>
        <title>Tableau de bord: Profil de l&apos;utilisateur | Apteed</title>
      </Helmet>
      {user.id_service === 0 && (
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {`Profil de l'utilisateur ${userToUpdate.firstname} ${userToUpdate.lastname}`}
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Gestion
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Utilisateurs
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            sx={{ mt: 1 }}
            {...props}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        p: 1,
                        border: (theme) => `1px dashed ${theme.palette.divider}`,
                        borderRadius: '50%'
                      }}
                    >
                      <Avatar
                        src={userToUpdate.avatar}
                        sx={{
                          height: 140,
                          width: 140
                        }}
                      />
                    </Box>
                    <Typography
                      color="textPrimary"
                      sx={{ mt: 1 }}
                      variant="subtitle2"
                    >
                      {userToUpdate.firstname}
                      {' '}
                      {userToUpdate.lastname}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                    >
                      {userToUpdate.functions}
                      <Link
                        color="primary"
                        component={RouterLink}
                        to="/dashboard/account"
                      >
                        {/* {user.plan} */}
                      </Link>
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    fullWidth
                    variant="text"
                  >
                    Changer de photo
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xl={9}
              xs={12}
            >
              <Formik
                enableReinitialize
                initialValues={{
                  city: userToUpdate.city || '',
                  country: userToUpdate.country || 'FR',
                  email: userToUpdate.email || '',
                  firstname: userToUpdate.firstname || '',
                  lastname: userToUpdate.lastname || '',
                  phone: userToUpdate.phone || '',
                  region: userToUpdate.region || '',
                  adress: userToUpdate.adress || '',
                  zip: userToUpdate.zip || '',
                  birthday: userToUpdate.birthday || '',
                  personal_email: userToUpdate.personal_email || '',
                  submit: null
                }}
                validationSchema={Yup
                  .object()
                  .shape({
                    city: Yup.string().max(255),
                    country: Yup.string().max(255).nullable(),
                    email: Yup
                      .string()
                      .email('Doit être une adresse email valide')
                      .max(255)
                      .required('L\'email est requis'),
                    firstname: Yup
                      .string()
                      .max(255)
                      .required('Le prénom est requis'),
                    lastname: Yup
                      .string()
                      .max(255)
                      .required('Le nom est requis'),
                    phone: Yup.string().max(20).required('Le numéro de téléphone est requis'),
                    region: Yup.string(),
                    adress: Yup.string(),
                    zip: Yup.string(),
                    personal_email: Yup
                      .string()
                      .email('Doit être une adresse email valide')
                      .max(255),
                    birthday: Yup.date()
                  })}
                onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                  try {
                    await usersApi.updateUser(window.localStorage.getItem('accessToken'), values, userId);
                    const data = await usersApi.getUserInfos(window.localStorage.getItem('accessToken'), userId);
                    setUserToUpdate(data.data);
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    toast.success('Profil mis à jour !');
                  } catch (err) {
                    console.error(err);
                    toast.error('Erreur lors de la mise à jour');
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardHeader title="Profil" />
                      <Divider />
                      <CardContent>
                        <Grid
                          container
                          spacing={4}
                        >
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.firstname && errors.firstname)}
                              fullWidth
                              helperText={touched.firstname && errors.firstname}
                              label="Prénom"
                              name="firstname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.firstname}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.lastname && errors.lastname)}
                              fullWidth
                              helperText={touched.lastname && errors.lastname}
                              label="Nom"
                              name="lastname"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastname}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.email && errors.email)}
                              fullWidth
                              helperText={touched.email && errors.email
                                ? errors.email
                                : 'Ceci est l\'adresse email de contact.'}
                              label="Adresse Email"
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              required
                              type="email"
                              value={values.email}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.personal_email && errors.personal_email)}
                              fullWidth
                              helperText={touched.personal_email && errors.personal_email}
                              label="Adresse Email perso."
                              name="personal_email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.personal_email}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.phone && errors.phone)}
                              fullWidth
                              helperText={touched.phone && errors.phone}
                              label="Téléphone"
                              name="phone"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.phone}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.birthday && errors.birthday)}
                              fullWidth
                              helperText={touched.birthday && errors.birthday}
                              label="Date de naissance"
                              name="birthday"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.birthday}
                              type="date"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.adress && errors.adress)}
                              fullWidth
                              helperText={touched.adress && errors.adress}
                              label="Adresse"
                              name="adress"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.adress}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.zip && errors.zip)}
                              fullWidth
                              helperText={touched.zip && errors.zip}
                              label="Code postal"
                              name="zip"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.zip}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.city && errors.city)}
                              fullWidth
                              helperText={touched.city && errors.city}
                              label="Ville"
                              name="city"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.city}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.region && errors.region)}
                              fullWidth
                              helperText={touched.region && errors.region}
                              label="Région"
                              name="region"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.region}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                          >
                            <Autocomplete
                              getOptionLabel={(option) => option.text}
                              options={countries}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  label="Pays"
                                  name="country"
                                  onChange={handleChange}
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  {...params}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                        )}
                      </CardContent>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          p: 2
                        }}
                      >
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                          Enregistrer les modifications
                        </Button>
                      </Box>
                    </Card>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Container>
      </Box>
      )}
      <Box sx={{ mt: 2 }}>
        {user.id_service !== 0 && ('Vous n\'avez pas le droit de voir cet utilisateur')}
      </Box>
    </>
  );
};

export default UserGeneralSettings;
