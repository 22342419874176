/* eslint-disable no-unused-vars */
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import useSettings from '../../hooks/useSettings';
import * as React from 'react';
import { CampaignStats } from '../../components/campagne';
import { useTheme } from '@material-ui/core/styles';
import useAuth from '../../hooks/useAuth';

const Campagnes = () => {
  const { settings } = useSettings();
  const theme = useTheme();
  const { user } = useAuth();

  return (
    <Box
      // sx={{
      //   py: 3,
      //   p: 5
      // }}
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 8
      }}
    >
      <Container maxWidth={settings.compact ? 'xl' : false}>
        <Grid
          container
          justifyContent="space-between"
          spacing={1}
        >
          <Grid item>
            <Typography
          // color={theme.palette.mode === 'light' ? 'TextSecondary' : 'white'}
          // variant="h3"
              color="textPrimary"
              variant="h5"
            >
              Campagnes
            </Typography>
          </Grid>
          {user.access_level === 0 && (
            <Grid item>
              <Button
                component={RouterLink}
                to="/dashboard/campaign/create"
                color="primary"
                variant="contained"
              >
                Nouvelle campagne
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid>

          <CampaignStats />
        </Grid>
      </Container>
    </Box>
  );
};

export default Campagnes;
