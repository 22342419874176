/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  Divider,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Avatar,
  CircularProgress,
  Grid,
  Button,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Collapse
} from '@material-ui/core';
// import useAuth from '../../hooks/useAuth';
import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import useMounted from '../../../hooks/useMounted';
import * as moment from 'moment';
import { relaunchApi } from '../../../api/relaunchApi';
import { ProgressBar } from '../session';
import ErrorIcon from '@mui/icons-material/Error';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import WarningIcon from '@mui/icons-material/Warning';
import { alpha } from '@mui/material/styles';
import numeral from 'numeral';
import Label from '../../Label';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, frFR } from '@mui/x-data-grid';
import CustomersFollowUpFullScreenTable from './CustomersFollowUpFullScreenTable';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function renderProgress(params) {
  return <ProgressBar value={Number(params.value)} />;
}

// pour supprimer le padding horizontal et vertical autour du datagrid full screen
// const useStyles = makeStyles({
//   dialogContent: {
//     paddingTop: 0,
//     paddingLeft: 0,
//     paddingRight: 0,
//     paddingBottom: 0,
//     position: 'relative'
//   },
//   dataGridContainer: {
//     position: 'absolute', // Positionnement absolu pour couvrir tout l'espace du DialogContent
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     padding: 0
//   },
// });

// const useStylesDivider = makeStyles(() => ({
//   thickDivider: {
//     borderBottomWidth: '3px'
//   },
// }));

const detailStyles = {
  borderTop: '2px solid',
  borderTopColor: 'primary.main',
  mt: 1,
  pt: 2,
  pb: 2,
  whiteSpace: 'pre-line',
};

const CustomersFollowUpTable = () => {
  const mounted = useMounted();
  const [page, setPage] = useState(0);
  //   const { user } = useAuth();
  const [limit, setLimit] = useState(5);
  const [customers, setCustomers] = useState([]);
  const [numberCustomers, setNumberCustomers] = useState(0);
  const [amounts, setAmounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [openFullScreen, setOpenFullScreen] = useState(false);
  // const classes = useStyles();
  const [clickedId, setClickedId] = useState(-1);

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );

  const getCustomersForFollowUp = useCallback(async () => {
    try {
      const data = await relaunchApi.getCustomersForFollowUp(localStorage.getItem('accessToken'));
      console.log(data);
      if (data.length === 0) {
        throw new Error('Aucun apprenant dans le suivi');
      }
      if (mounted.current) {
        setCustomers(data.customers);
        setNumberCustomers(data.numberCustomers.number);
        setAmounts(data.amounts);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCustomersForFollowUp();
  }, [getCustomersForFollowUp]);

  useEffect(async () => {
    setRows(customers.map((item) => ({
      id: item.email + item.name,
      customerId: item.id,
      formationId: item.formation_id,
      email: item.email,
      phone: item.phone,
      profession: item.profession,
      firstname: item.firstname,
      lastname: item.lastname,
      source: item.source,
      status: item.status,
      last_connection: item.last_connection !== null ? moment(item.last_connection).format('DD/MM/YYYY HH:mm') : '',
      start_date: item.start_date !== null ? moment(item.start_date).format('DD/MM/YYYY') : '',
      end_date: item.end_date !== null ? moment(item.end_date).format('DD/MM/YYYY') : '',
      start_relaunch_date: item.start_relaunch_date !== null ? moment(item.start_relaunch_date).format('DD/MM/YYYY') : '',
      first_turn_end_date: item.first_turn_end_date !== null ? moment(item.first_turn_end_date).format('DD/MM/YYYY') : '',
      progression: Math.round(item.progression),
      formation_name: item.name,
      real_price: item.real_price,
      civility: item.civility,
      action: item.action,
      step: item.step,
      duration: item.duration,
      sessionId: item.session_id,
      numberSession: item.number_session,
      programNumber: item.program_number
    })));
  }, [customers]);

  const columns = [
    {
      field: '+',
      // eslint-disable-next-line no-unused-expressions
      renderCell: (cellValues) => (<IconButton onClick={() => { clickedId === cellValues.row.id ? setClickedId(-1) : setClickedId(cellValues.row.id); }}>{cellValues.row.id === clickedId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>),
      width: 60
    },
    {
      field: 'lastname',
      headerName: 'Apprenant',
      type: 'singleSelect',
      renderCell: (cellValues) => (
        (
          <div>
            <div>
              <Link
                sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
                component={RouterLink}
                to={`/dashboard/customers/${cellValues.row.customerId}`}
              >
                <Typography style={{ fontWeight: 'normal' }}>
                  {`${cellValues.row.firstname} ${cellValues.row.lastname}`}
                </Typography>
              </Link>
              {cellValues.row.profession === 'Infirmier' && (
              <Label
                color="profession"
              >
                {cellValues.row.profession}
              </Label>
              )}
              {cellValues.row.profession === 'Masseur-kinésithérapeute' && (
              <Label
                color="warning"
              >
                {cellValues.row.profession}
              </Label>
              )}
              {cellValues.row.profession === 'Sage-Femme' && (
              <Label
                color="secondary"
              >
                {cellValues.row.profession}
              </Label>
              )}
              {cellValues.row.profession === 'Pédicure-podologue' && (
              <Label
                color="inscritD"
              >
                {cellValues.row.profession}
              </Label>
              )}
              {cellValues.row.profession === 'Pharmacien' && (
              <Label
                color="success"
              >
                {cellValues.row.profession}
              </Label>
              )}
              {cellValues.row.profession === 'Médecin' && (
              <Label
                color="error"
              >
                {cellValues.row.profession}
              </Label>
              )}
            </div>
            <Collapse in={cellValues.id === clickedId}>
              <Box sx={detailStyles}>
                <span style={{ fontWeight: 'bold' }}>
                  Civilité :
                  {' '}
                  {cellValues.row.civility}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Téléphone :
                  {' '}
                  {cellValues.row.phone}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Email :
                  {' '}
                  {cellValues.row.email}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Date de dernière connexion :
                  {' '}
                  {cellValues.row.last_connection}
                  {' '}
                </span>
                <br />
              </Box>
            </Collapse>
          </div>
        )
      ),
      width: 400,
    },
    // {
    //   field: 'civility',
    //   headerName: 'Civilité'
    // },
    // {
    //   field: 'firstname',
    //   headerName: 'Prénom',
    //   renderCell: (cellValues) => (
    //     <Link
    //       component={RouterLink}
    //       to={`/dashboard/customers/${cellValues.row.customerId}`}
    //       variant="subtitle2"
    //     >
    //       {cellValues.row.firstname}
    //     </Link>
    //   ),
    //   width: 300,
    // },
    // {
    //   field: 'lastname',
    //   headerName: 'Nom',
    //   renderCell: (cellValues) => (
    //     <Link
    //       component={RouterLink}
    //       to={`/dashboard/customers/${cellValues.row.customerId}`}
    //       variant="subtitle2"
    //     >
    //       {cellValues.row.lastname}
    //     </Link>
    //   ),
    //   width: 300,
    // },
    // {
    //   field: 'email',
    //   headerName: 'Email',
    //   width: 300,
    // },
    // {
    //   field: 'phone',
    //   headerName: 'Téléphone',
    //   width: 200,
    // },
    {
      field: 'formation_name',
      headerName: 'Nom de la formation',
      renderCell: (cellValues) => (
        (
          <div>
            <Link
              sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
              component={RouterLink}
              to={`/dashboard/formations/${cellValues.row.formationId}`}
            >
              <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>
                {cellValues.row.formation_name}
              </Typography>
            </Link>
            <Link
              sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
              component={RouterLink}
              to={`/dashboard/session/${cellValues.row.sessionId}`}
            >
              <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>
                Session n° :
                {' '}
                {cellValues.row.numberSession}
              </Typography>
            </Link>
            <Collapse in={cellValues.id === clickedId}>
              <Box sx={detailStyles}>
                {/* <Link
                  sx={{ whiteSpace: 'normal', width: 430, fontWeight: 'bold' }}
                  component={RouterLink}
                  to={`/dashboard/session/${cellValues.row.sessionId}`}
                >
                  <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>
                    Session n° :
                    {' '}
                    {cellValues.row.numberSession}
                  </Typography>
                </Link> */}
                <span style={{ fontWeight: 'bold' }}>
                  Source :
                  {' '}
                  {cellValues.row.source}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Prix :
                  {' '}
                  {cellValues.row.real_price}
                  {' '}
                  €
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Étape :
                  {' '}
                  {cellValues.row.step}
                  {' '}
                </span>
                <br />
                <span style={{ fontWeight: 'bold' }}>
                  Durée :
                  {' '}
                  {cellValues.row.duration}
                  {' '}
                </span>
                <br />
              </Box>
            </Collapse>
          </div>
        )
      ),
      width: 350,
    },
    // {
    //   field: 'numberSession',
    //   headerName: 'N° de session',
    //   renderCell: (cellValues) => (
    //     (
    //       <Link
    //         sx={{ whiteSpace: 'normal', width: 430, fontWeight: 600 }}
    //         component={RouterLink}
    //         to={`/dashboard/session/${cellValues.row.sessionId}`}
    //       >
    //         <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>
    //           {cellValues.row.numberSession}
    //         </Typography>
    //       </Link>
    //     )
    //   ),
    //   width: 100,
    // },
    {
      field: 'start_date',
      headerName: 'Dates de session',
      type: 'dateTime',
      editable: false,
      renderCell: (cellValues) => (
        (
          <div>
            <span>
              {/* {`Date de début de relance : ${cellValues.row.start_relaunch_date}`} */}
              Début de session :
              <br />
              {cellValues.row.start_date}
            </span>
            <br />
            {/* <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>
              {`Date de fin d'unité clé : ${cellValues.row.first_turn_end_date}`}
            </Typography> */}
            <span>
              {/* {`Date de début de relance : ${cellValues.row.start_relaunch_date}`} */}
              Fin de session :
              <br />
              {cellValues.row.end_date}
            </span>
            {/* <Collapse in={cellValues.id === clickedId}>
              <Box sx={detailStyles}>
                <span style={{ fontWeight: 'bold' }}>
                  Date de fin d&apos;unité clé :
                  {' '}
                  {cellValues.row.first_turn_end_date}
                  {' '}
                </span>
                <br />
              </Box>
            </Collapse> */}
          </div>
          // <div>
          //   <Typography style={{ fontWeight: 'normal', fontSize: 14 }}>
          //     {cellValues.row.start_date}
          //   </Typography>
          //   <Collapse in={cellValues.id === clickedId}>
          //     <Box sx={detailStyles}>
          //       <span style={{ fontWeight: 'bold' }}>
          //         Date de fin de session :
          //         {' '}
          //         {cellValues.row.end_date}
          //         {' '}
          //       </span>
          //       <br />
          //     </Box>
          //   </Collapse>
          // </div>
        )),
      width: 150,
    },
    // {
    //   field: 'end_date',
    //   headerName: 'Fin de session',
    //   type: 'dateTime',
    //   editable: false,
    //   width: 150,
    // },
    {
      field: 'start_relaunch_date',
      headerName: 'Dates importantes',
      // type: 'dateTime',
      editable: false,
      width: 160,
      renderCell: (cellValues) => (
        (
          <div>
            <span>
              Début de relance :
              <br />
              {cellValues.row.start_relaunch_date === null || cellValues.row.start_relaunch_date === '' ? '-' : cellValues.row.start_relaunch_date}
            </span>
            <br />
            <span>
              Fin d&apos;unité clé :
              <br />
              {cellValues.row.first_turn_end_date === null || cellValues.row.first_turn_end_date === '' ? '-' : cellValues.row.first_turn_end_date}
            </span>
          </div>
        )
      ),
    },
    {
      field: 'status',
      headerName: 'Statut',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.status === 'Inscrit') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'InscritD') {
          statut = (
            <Label
              color="inscritD"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'Décalage') {
          statut = (
            <Label
              InputLabelProps={{ style: { pointerEvents: 'auto' } }}
              color="secondary"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
          statut = (
            <Label
              color="error"
            >
              {cellValues.row.status}
            </Label>
          );
        }
        return (statut);
      },
      editable: false,
      width: 120,
    },
    // {
    //   field: 'last_connection',
    //   headerName: 'Dernière connexion',
    //   type: 'dateTime',
    //   editable: false,
    //   width: 160,
    // },
    {
      field: 'progression',
      headerName: 'Progression',
      renderCell: renderProgress,
      editable: false,
      width: 120,
    },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.action === 'Relance Tour 1') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.action}
            </Label>
          );
        } else if (cellValues.row.action === 'Relance Tour 2') {
          statut = (
            <Label
              color="warning"
            >
              {cellValues.row.action}
            </Label>
          );
        } else if (cellValues.row.action === 'Décalage') {
          statut = (
            <Label
              InputLabelProps={{ style: { pointerEvents: 'auto' } }}
              color="secondary"
            >
              {cellValues.row.action}
            </Label>
          );
        }
        return (statut);
      },
      editable: false,
      width: 130,
    },
    // {
    //   field: 'first_turn_end_date',
    //   headerName: 'Fin de tour clé',
    //   type: 'dateTime',
    //   editable: false,
    //   width: 160,
    // },
    // {
    //   field: 'profession',
    //   headerName: 'Profession',
    //   renderCell: (cellValues) => {
    //     let statut = '';
    //     if (cellValues.row.profession === 'Infirmier') {
    //       statut = (
    //         <Label
    //           color="profession"
    //         >
    //           {cellValues.row.profession}
    //         </Label>
    //       );
    //     } else if (cellValues.row.profession === 'Masseur-kinésithérapeute') {
    //       statut = (
    //         <Label
    //           color="warning"
    //         >
    //           Kiné
    //         </Label>
    //       );
    //     } else if (cellValues.row.profession === 'Sage-Femme') {
    //       statut = (
    //         <Label
    //           InputLabelProps={{ style: { pointerEvents: 'auto' } }}
    //           color="secondary"
    //         >
    //           {cellValues.row.profession}
    //         </Label>
    //       );
    //     } else if (cellValues.row.profession === 'Pédicure-podologue') {
    //       statut = (
    //         <Label
    //           color="inscritD"
    //         >
    //           Podologue
    //         </Label>
    //       );
    //     } else if (cellValues.row.profession === 'Pharmacien') {
    //       statut = (
    //         <Label
    //           color="success"
    //         >
    //           {cellValues.row.profession}
    //         </Label>
    //       );
    //     } else if (cellValues.row.profession === 'Médecin') {
    //       statut = (
    //         <Label
    //           color="error"
    //         >
    //           {cellValues.row.profession}
    //         </Label>
    //       );
    //     }
    //     return (statut);
    //   },
    //   width: 100,
    // },
    // {
    //   field: 'step',
    //   headerName: 'Étape',
    //   width: 200,
    // },
    // {
    //   field: 'duration',
    //   headerName: 'Durée',
    // },
    // {
    //   field: 'real_price',
    //   headerName: 'Prix',
    //   valueFormatter: (params) => `${params.value} €`,
    //   editable: false,
    //   width: 100,
    // },
    // {
    //   field: 'source',
    //   headerName: 'Source',
    //   width: 200,
    // },
  ];

  const handleCustomers = (async (pageChoice, limitChoice) => {
    try {
      setIsLoading(true);
      const data = await relaunchApi.getCustomersForFollowUp(localStorage.getItem('accessToken'), pageChoice, limitChoice);
      setCustomers(data.customers);
      setNumberCustomers(data.numberCustomers.number);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleCustomers(newPage, limit);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    handleCustomers(page, parseInt(event.target.value, 10));
  };

  const handleCloseFullScreen = () => {
    setOpenFullScreen(false);
  };

  // const classesDivider = useStylesDivider();

  return (
    <>
      {amounts !== null && (
        <>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: 'transparent',
                      mr: 1
                    }}
                    variant="rounded"
                  >
                    <Box
                      sx={{
                        animation: 'pulse ease 750ms infinite',
                        borderRadius: '50%',
                        p: 0.5,
                        '@keyframes pulse': {
                          '0%': {
                            boxShadow: 'none'
                          },
                          '100%': {
                            boxShadow: (theme) => `0px 0px 0px 6px ${alpha(theme.palette.primary.main, 0.1)}`
                          }
                        }
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: 'primary.main',
                          borderRadius: '50%',
                          height: 12,
                          width: 12
                        }}
                      />
                    </Box>
                  </Avatar>
                  <Typography variant="h5">
                    Inscrits :
                    {' '}
                    {numeral(amounts.Inscrit).format('0,000,000.00').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                      color: 'primary.main',
                      mr: 1
                    }}
                  >
                    <TimelapseIcon fontSize="small" />
                  </Avatar>
                  <Typography variant="h5">
                    InscritD :
                    {' '}
                    {numeral(amounts.InscritD).format('0,000,000').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.1),
                      color: 'warning.main',
                      mr: 1
                    }}
                  >
                    <ErrorIcon fontSize="small" />
                  </Avatar>
                  <Typography variant="h5">
                    Désinscrits :
                    {' '}
                    {numeral(amounts.Désinscrit).format('0,000,000').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              lg={3}
              md={6}
              xs={12}
              mb={2}
            >
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    mb: 1
                  }}
                >
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
                      color: 'error.main',
                      mr: 1
                    }}
                  >
                    <WarningIcon fontSize="small" />
                  </Avatar>
                  <Typography variant="h5">
                    Annulation :
                    {' '}
                    {numeral(amounts.Annulation).format('0,000,000').replaceAll(',', ' ')}
                    {' '}
                    €
                  </Typography>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
      <Dialog
        fullScreen
        open={openFullScreen}
        onClose={handleCloseFullScreen}
      >
        {/* <DialogTitle>Orientations DPC</DialogTitle> */}
        {/* pour supprimer le padding horizontal et vertical autour du datagrid full screen */}
        {/* <DialogContent className={classes.dialogContent}> */}
        <DialogContent>
          {/* <Box className={classes.dataGridContainer}> */}
          <CustomersFollowUpFullScreenTable rows={isLoading ? [] : rows} />
          {/* </Box> */}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={handleCloseFullScreen}
            color="primary"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          backgroundColor: 'background.default',

        }}
      >
        <Card>
          <Button
            onClick={() => setOpenFullScreen(true)}
            sx={{ m: 1 }}
            color="primary"
            variant="contained"
          >
            Vue détaillée
          </Button>
          <Box>
            <DataGrid
              density="confortable"
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'lastname', sort: 'asc' }],
                },
              }}
              autoHeight
              rowHeight={90}
                    // eslint-disable-next-line consistent-return
              getRowHeight={({ id, densityFactor }) => {
                if (id === clickedId) {
                  return 250 * densityFactor;
                }
              }}
              rows={rows}
              columns={columns}
              pageSize={25}
              rowsPerPageOptions={[5]}
              // onCellEditCommit={handleChange}
              components={{ Toolbar: CustomToolbar }}
            />
          </Box>
          {/* <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            m: -1,
            p: 2
          }}
        > */}
          {/* <Box
            sx={{
              m: 1,
              maxWidth: '100%',
              width: 500
            }}
          >
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder="Chercher un apprenant"
              value={query}
              variant="outlined"
            />
          </Box> */}
          {/* <Box
            sx={{
              m: 1,
              width: 240
            }}
          >
            <TextField
              label="Nouveau statut d'appel"
              name="newCallTrack"
              onChange={handleNewCallTrackChange}
              select
              SelectProps={{ native: true }}
              value={currentNewCallTrack}
              variant="outlined"
            >
              {newCallTrackOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </Box> */}
        </Card>
      </Box>
    </>
  );
};

export default CustomersFollowUpTable;
