/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
} from "@material-ui/core";
import ArrowCircleDownIcon from "@material-ui/icons/ArrowCircleDown";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "../../../icons/Search";
import Scrollbar from "../../Scrollbar";
import FileDropzone from "../../FileDropzone";
import { customerApi } from "../../../api/customerApi";
import useAuth from "../../../hooks/useAuth";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as moment from "moment";

const tabs = [
  {
    label: "Tous",
    value: "all",
  },
  {
    label: "Attestations",
    value: "Attestation DPC",
  },
  {
    label: "Conventions",
    value: "Convention",
  },
  {
    label: "Convocations",
    value: "Convocation",
  },
  {
    label: "Factures",
    value: "Facture",
  },
];

const typesDocuments = [
  "Attestation DPC",
  "Attestation sur honneur",
  "Attestation de logs",
  "Convention",
  "Convocation",
  "Facture",
];

const sortOptions = [
  {
    label: "Plus récent",
    value: "created_at|desc",
  },
  {
    label: "Plus ancien",
    value: "created_at|asc",
  },
  {
    label: "Type (A - Z)",
    value: "type_doc|asc",
  },
  {
    label: "Type (Z - A)",
    value: "type_doc|desc",
  },
];

const applyFilters = (docs, query, filters) =>
  docs.filter((doc) => {
    let matches = true;

    if (query) {
      const properties = ["name", "sourceLastname", "sourceFirstname"];
      let containsQuery = false;

      properties.forEach((property) => {
        if (doc[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && doc[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (docs, page, limit) =>
  docs.slice(page * limit, page * limit + limit);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

// eslint-disable-next-line no-confusing-arrow
const getComparator = (order, orderBy) =>
  order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const applySort = (docs, sort) => {
  const [orderBy, order] = sort.split("|");
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = docs.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const CustomerDocs = (props) => {
  const { customerId, documents, ...other } = props;
  const docs = documents;
  const [currentTab, setCurrentTab] = useState("all");
  const [selecteddocs, setSelecteddocs] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState(sortOptions[0].value);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState([]);
  const { user } = useAuth();

  const [filters, setFilters] = useState({
    type: null,
  });

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      type: null,
    };

    if (value !== "all") {
      updatedFilters.type = value;
    }

    setFilters(updatedFilters);
    setSelecteddocs([]);
    setCurrentTab(value);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAlldocs = (event) => {
    setSelecteddocs(event.target.checked ? docs.map((doc) => doc.id) : []);
  };

  const handleSelectOnedoc = (event, docId) => {
    if (!selecteddocs.includes(docId)) {
      setSelecteddocs((prevSelected) => [...prevSelected, docId]);
    } else {
      setSelecteddocs((prevSelected) =>
        prevSelected.filter((id) => id !== docId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handleDrop = async (acceptedFile) => {
    setOpen(true);
    const arrayTmp = [];
    acceptedFile.forEach((file) => {
      arrayTmp.push({ name: file.name, type: "", file });
    });
    setValues(arrayTmp);
  };

  const handleClose = () => {
    setOpen(false);
    setValues([]);
  };

  const handleChange = (index) => (event) => {
    event.preventDefault();
    const newArr = [...values];
    newArr[index] = {
      ...newArr[index],
      [event.target.name]: event.target.value,
    };
    setValues(newArr);
  };

  const handleSubmit = async () => {
    await customerApi.sendDoc(
      customerId,
      user.id,
      values,
      localStorage.getItem("accessToken")
    );
    handleClose();
    window.location.reload();
  };

  const handleDelete = async (linkDoc, idDoc) => {
    try {
      const docDelete = { link: linkDoc, id: idDoc };
      await customerApi.deleteDoc(
        docDelete,
        localStorage.getItem("accessToken")
      );
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const filtereddocs = applyFilters(docs, query, filters);
  const sorteddocs = applySort(filtereddocs, sort);
  const paginateddocs = applyPagination(sorteddocs, page, limit);
  const enableBulkActions = selecteddocs.length > 0;
  const selectedSomedocs =
    selecteddocs.length > 0 && selecteddocs.length < docs.length;
  const selectedAlldocs = selecteddocs.length === docs.length;

  return (
    <Card {...other}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Téléchargement de documents</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirmer le nom et le type de chaque fichier à importer.
          </DialogContentText>
          {values.map((value, index) => (
            <>
              <>
                {index + 1}
                {". "}
                <TextField
                  variant="outlined"
                  sx={{ m: "20px" }}
                  name="name"
                  label="Nom du fichier"
                  onChange={handleChange(index)}
                  value={value.name}
                />
                <Select
                  variant="outlined"
                  onChange={handleChange(index)}
                  name="type"
                  value={value.type}
                  label="Type"
                  sx={{ m: "20px", minWidth: "150px" }}
                >
                  {typesDocuments.map((typeDoc) => (
                    <MenuItem value={typeDoc}>{typeDoc}</MenuItem>
                  ))}
                </Select>
              </>
              <br />
            </>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            Enregistrer
          </Button>
          <Button onClick={handleClose} color="primary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <CardContent>
          <FileDropzone onDrop={handleDrop} maxFiles={5} />
        </CardContent>
      </Card>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Chercher un document"
            value={query}
            variant="outlined"
          />
        </Box>
        <Box
          sx={{
            m: 1,
            width: 240,
          }}
        >
          <TextField
            label="Trier par"
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>
      {enableBulkActions && (
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              backgroundColor: "background.paper",
              mt: "6px",
              position: "absolute",
              px: "4px",
              width: "100%",
              zIndex: 2,
            }}
          >
            <Checkbox
              checked={selectedAlldocs}
              color="primary"
              indeterminate={selectedSomedocs}
              onChange={handleSelectAlldocs}
            />
            <Button color="primary" sx={{ ml: 2 }} variant="outlined">
              Delete
            </Button>
            <Button color="primary" sx={{ ml: 2 }} variant="outlined">
              Edit
            </Button>
          </Box>
        </Box>
      )}
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAlldocs}
                    color="primary"
                    indeterminate={selectedSomedocs}
                    onChange={handleSelectAlldocs}
                  />
                </TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Date de dépôt</TableCell>
                <TableCell>Téléchargé par</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginateddocs.map((doc, indexo) => {
                const isdocselected = selecteddocs.includes(doc.id);

                return (
                  <TableRow hover key={doc.id} selected={isdocselected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isdocselected}
                        color="primary"
                        onChange={(event) => handleSelectOnedoc(event, doc.id)}
                        value={isdocselected}
                      />
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <a
                            href={doc.link}
                            style={{ color: "inherit", fontWeight: "bold" }}
                          >
                            {doc.name}
                          </a>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>{doc.type_doc}</TableCell>
                    <TableCell>
                      {moment(doc.created_at).format("DD/MM/YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell>
                      {doc.sourceFirstname} {doc.sourceLastname}
                    </TableCell>
                    <TableCell align="right">
                      <a href={doc.link} download>
                        <ArrowCircleDownIcon fontSize="small" />
                      </a>
                      <IconButton onClick={() => handleDelete(doc.link, doc.id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filtereddocs.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

CustomerDocs.propTypes = {
  documents: PropTypes.array,
  customerId: PropTypes.number,
};

export default CustomerDocs;
