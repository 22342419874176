/* eslint-disable linebreak-style */
import { useEffect, useState } from 'react';
import {
  // Avatar,
  // Box,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
// import 'react-quill/dist/quill.snow.css';
import { ListItemButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ticketApi } from '../../../api/ticketApi';
import moment from 'moment';
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';

const TicketSearchSection = (props) => {
  const { serviceId, placeholder, majorIncidentId, tickets, setTickets, updateTicketList, setUpdateTicketList, handleClick, ticketsFetched, setTicketsFetched } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const { settings } = useSettings();

  const handleSearch = async (value) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const searchParams = {
          serviceId,
          ticketsId: tickets.map((ticket) => ticket.id),
          searchQuery
        };

        const newTicketsFetched = await ticketApi.searchTicketsMajorIncident(localStorage.getItem('accessToken'), searchParams);

        setTicketsFetched(newTicketsFetched.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [searchQuery]);

  const customStyle = {
    fontWeight: settings.theme === 'DARK' && 'bold'
  };

  return (
    <>
      <TextField
        label={!placeholder ? "Chercher un ticket concerné par l'incident majeur" : placeholder}
        name="tickets"
        value={searchQuery}
        onChange={(event) => handleSearch(event.target.value)}
        variant="outlined"
        // sx={{ width: '400px' }}
        fullWidth="xl"
      />
      <List sx={{ mb: searchQuery.length > 0 && 3 }}>
        {searchQuery !== '' && (ticketsFetched.length === 0 ? (
          <ListItem sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
            <Typography>Aucun ticket trouvé</Typography>
          </ListItem>
        ) : ticketsFetched?.map((ticket) => (
          <ListItemButton
            key={ticket.id}
            onClick={() => handleClick(ticket)}
            sx={{ boxShadow: settings.theme === 'DARK' && 3 }}
          >
            <ListItem>
              <ListItemText
                primary={`${moment(ticket.created_at).format('l')} - ${ticket.reference}`}
                secondary={`${ticket.category} - ${ticket.description}`}
                secondaryTypographyProps={{ style: customStyle }}
              />
            </ListItem>
          </ListItemButton>
        )))}
      </List>
    </>
  );
};

export default TicketSearchSection;

TicketSearchSection.propTypes = {
  serviceId: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  majorIncidentId: PropTypes.number.isRequired,
  tickets: PropTypes.array.isRequired,
  setTickets: PropTypes.func.isRequired
};
