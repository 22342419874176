import SearchIcon from '../../../icons/Search';
import { Link as RouterLink } from 'react-router-dom';
import {
  TextField,
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Avatar,
  Link,
  Typography,
  Checkbox,
  IconButton,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useEffect, useState } from 'react';
import Scrollbar from '../../Scrollbar';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { usersApi } from '../../../api/usersApi';

const sortOptions = [
  {
    label: 'Nom (A - Z)',
    value: 'lastname|asc'
  },
  {
    label: 'Nom (Z - A)',
    value: 'lastname|desc'
  }
];

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

// eslint-disable-next-line no-unused-vars
const applyFilters = (customers, query, functionUser) => customers
  .filter((customer) => {
    let matches = true;
    customer.firstlastname = `${customer.firstname} ${customer.lastname}`;

    if (query) {
      const properties = ['firstlastname', 'functions'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (customer[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }
    const functionUserKeys = Object.keys(functionUser);
    functionUserKeys.forEach((key) => {
      const value = functionUser[key];
      if (value && customer.functions !== key) {
        matches = false;
      }
    });
    return matches;
  });

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const applyPagination = (customers, page, limit) => customers
  .slice(page * limit, page * limit + limit);
const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const UsersListTable = (props) => {
  const { users } = props;
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(sortOptions[0].value);
  const [limit, setLimit] = useState(25);
  const functions = [];
  // eslint-disable-next-line array-callback-return
  users.map((user) => {
    if (!functions.find((el) => el === user.functions)) {
      functions.push(user.functions);
    }
  });

  function initFunctionUser() {
    const functionUserObj = {};

    functions.forEach((functionElt) => {
      functionUserObj[functionElt] = null;
    });
    functions.Toutes = null;
    return functionUserObj;
  }

  const [functionUser, setFunctionUser] = useState(initFunctionUser);

  const filteredUsers = applyFilters(users, query, functionUser);
  const sortedUsers = applySort(filteredUsers, sort);
  const paginatedUsers = applyPagination(sortedUsers, page, limit);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const selectedSomeUsers = selectedUsers.length > 0;
  const selectedAllUsers = selectedUsers.length === paginatedUsers.length;
  const [currentFunctionUser, setCurrentFunctionUser] = useState('all');
  const [open, setOpen] = useState(false);
  const [userDelete, setUserDelete] = useState({});

  const functionUserOptions = [
    {
      label: 'Toutes',
      value: 'all'
    },
  ];
  // eslint-disable-next-line array-callback-return
  functions.map((functionElt) => {
    functionUserOptions.push({ label: functionElt, value: functionElt });
  });

  useEffect(() => {
    setFunctionUser(functionUser);
  }, []);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    // pour remettre à la première page de résultats quand on fait une recherche
    setPage(0);
  };

  const handleSortChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSort(value);
    } else {
      setSort(event.target.value);
      window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllUsers = (event) => {
    setSelectedUsers(event.target.checked
      ? paginatedUsers.map((user) => user.id)
      : []);
  };

  const handleFunctionUserChange = (event, value) => {
    const tempFunctionUser = initFunctionUser();
    const updatedFunctionUser = {
      ...tempFunctionUser
    };
    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedFunctionUser[value] = true;
      }
      setFunctionUser(updatedFunctionUser);
      setSelectedUsers([]);
      setCurrentFunctionUser(value);
    } else {
      if (event.target.value !== 'all') {
        updatedFunctionUser[event.target.value] = true;
      }

      setFunctionUser(updatedFunctionUser);
      setSelectedUsers([]);
      setCurrentFunctionUser(event.target.value);
    }
  };
  const dialogOpener = (index) => {
    setOpen(true);
    setUserDelete({ firstname: paginatedUsers[index].firstname, lastname: paginatedUsers[index].lastname, id: paginatedUsers[index].id });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await usersApi.deleteUser(userId, localStorage.getItem('accessToken'));
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <DialogContentText>
            Souhaitez-vous vraiment supprimer l&apos;utilisateur
            {' '}
            {userDelete.firstname}
            {' '}
            {userDelete.lastname}
            {' ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteUser(userDelete.id)}
            color="primary"
          >
            Supprimer
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          m: -1,
          p: 2
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: '100%',
            width: 250
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Chercher un utilisateur"
            value={query}
            variant="outlined"
          />
        </Box>

        <>
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              label="Trier par"
              name="sort"
              onChange={handleSortChange}
              select
              SelectProps={{ native: true }}
              value={sort}
              variant="outlined"
            >
              {sortOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              m: 1
            }}
          >
            <TextField
              label="Fonction"
              name="functionUser"
              onChange={handleFunctionUserChange}
              select
              SelectProps={{ native: true }}
              value={currentFunctionUser}
              variant="outlined"
            >
              {functionUserOptions.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </Box>
        </>

      </Box>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedAllUsers}
                    color="primary"
                    indeterminate={selectedSomeUsers}
                    onChange={handleSelectAllUsers}
                  />
                </TableCell> */}
                <TableCell>
                  Nom et Email
                </TableCell>
                <TableCell align="center">
                  Fonction
                </TableCell>
                <TableCell align="center">
                  Niveau d&apos;accès
                </TableCell>
                <TableCell align="center">
                  Id Service
                </TableCell>
                <TableCell align="center">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* eslint-disable-next-line no-unused-vars */}
              {paginatedUsers.map((user, index) => {
                const isUserSelected = selectedUsers.includes(user.id);

                return (
                  <TableRow
                    hover
                    key={user.id}
                    selected={isUserSelected}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Avatar
                          src={user.avatar}
                          sx={{
                            height: 42,
                            width: 42
                          }}
                          {...stringAvatar(`${user.firstname.replace(/ /g, '')} ${user.lastname.replace(/ /g, '')}`)}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Link
                            component={RouterLink}
                            to={`/dashboard/user/${user.id}`}
                            variant="subtitle2"
                          >
                            {user.firstname}
                            {' '}
                            {user.lastname}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {user.email}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {user.functions}
                    </TableCell>
                    <TableCell align="center">
                      {user.access_level}
                    </TableCell>
                    <TableCell align="center">
                      {user.id_service}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => dialogOpener(index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredUsers.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[15, 25, 50]}
      />
    </Box>
  );
};

UsersListTable.propTypes = {
  users: PropTypes.array.isRequired
};

export default UsersListTable;
