/* eslint-disable no-nested-ternary */
/* eslint-disable linebreak-style */
import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Link,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import moment from 'moment';
import { createTheme } from '@mui/material/styles';
import Label from '../../Label';
import useSettings from '../../../hooks/useSettings';
// import { createCustomTheme } from '../../../theme';

const theme = createTheme({
  palette: {
    grey: {
      main: 'grey',
      contrastText: '#fff'
    },
    orange: {
      main: 'orange',
      contrastText: '#fff'
    },
    green: {
      main: 'green',
      contrastText: '#fff'
    }
  },
});

const TicketMiniCard = (props) => {
  const { ticketId, reference, title, description, handleDelete, createdAt, ticketState, ...other } = props;

  const { settings } = useSettings();

  // const globalTheme = createCustomTheme({
  //   ...settings
  // })

  const maxLength = 35;
  // const shortDescription = description.length > maxLength ? `${description.substring(0, maxLength)}...` : description;
  const shortTitle = title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;  

  const formattedDate = moment(createdAt).format('DD/MM/YYYY à HH:mm');

  return (
    <Card
      {...other}
      sx={settings.theme === 'DARK' ? 
        { 
          borderStyle: 'solid', 
          borderColor: 'primary', 
          borderWidth: '0.5px', 
          height: 250 
        } : { 
          boxShadow: 5,
          // border: `2px solid ${globalTheme.palette.primary.main}`
        }}
    >
      <CardContent>
        <Link
          align="center"
          color="textPrimary"
          display="flex"
          justifyContent="space-between"
          to="#"
          underline="none"
          // variant="h6"
          sx={{ mb: -3 }}
        >
          {reference}
          <Box sx={{ textAlign: 'end', mt: -1.5 }}>
            <IconButton onClick={() => handleDelete(ticketId)}>
              <Clear />
            </IconButton>
          </Box>
        </Link>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ m: -0.5 }}>
          <Typography sx={{ height: 80}}>{shortTitle}</Typography>
          {/* <Typography
            variant="body2"
            sx={{ fontStyle: 'italic', marginTop: '0.5rem', height: 30 }}
          >
            {shortDescription}
          </Typography> */}
          <Typography
            variant="body2"
            sx={{ marginTop: '0.5rem', textAlign: 'end' }}
          >
            <ThemeProvider theme={theme}>
              <Label
                color={
            ticketState === 'Ouvert' ? 'grey'
              : ticketState === 'Suspendu' ? 'error'
                : ticketState === 'En cours' ? 'orange'
                  : 'green'
          }
              >
                {ticketState}
              </Label>
            </ThemeProvider>
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography
            variant="body2"
            sx={{ fontStyle: 'italic', marginTop: '0.5rem' }}
          >
            Créé le :
            {' '}
            {(formattedDate)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

TicketMiniCard.propTypes = {
  reference: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ticketId: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  createdAt: PropTypes.string.isRequired,
  ticketState: PropTypes.string.isRequired
};

export default TicketMiniCard;
