import { Typography, Box, Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import gtm from '../../../lib/gtm';
import * as React from 'react';

const FinanceHeur = () => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', };
  const [temps, setTemps] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    gtm.push({ event: 'page_view' });

    const interval = setInterval(() => setTemps(new Date().toLocaleTimeString()), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      <Grid item>
        <Typography
          color="textSecondary"
          variant="h3"
        >
          Tableau de bord
        </Typography>
      </Grid>
      <Grid
        container
        sx={{ mt: 1, ml: 1, mb: 2 }}
      >
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {new Date().toLocaleDateString('fr-FR', options)}
        </Typography>

        <Typography
          color="textPrimary"
          variant="h5"
          sx={{ paddingLeft: 4 }}
        >
          {temps}
        </Typography>
      </Grid>
    </Box>
  );
};

export default FinanceHeur;
