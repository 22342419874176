/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  Tooltip
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { sessionApi } from '../../../api/sessionApi';
import * as moment from 'moment';
import toast from 'react-hot-toast';
import useMounted from '../../../hooks/useMounted';

const SessionDesinscriptions = (props) => {
  const { sessionId, mode, ...other } = props;
  const [rows, setRows] = useState([]);
  const mounted = useMounted();
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation

  const getUnsubscribed = useCallback(async () => {
    try {
      const data = await sessionApi.getUnsubscribed(sessionId, localStorage.getItem('accessToken'));

      if (mounted.current) {
        let status = '';
        if (mode === 'annulation') {
          status = 'Annulation';
        } else if (mode === 'desinscriptions') {
          status = 'Désinscrit';
        }
        setRows(data.data.unsubscribed.filter((el) => el.status === status).map((customer) => ({ id: customer.id, firstname: customer.customerFirstname, lastname: customer.customerLastname, source: customer.source, unsubscribed_at: customer.unsubscribed_at !== null ? customer.unsubscribed_at : '', unsubscribed_comment: customer.unsubscribed_comment })));
      }
    } catch (err) {
      console.error(err);
      navigate('/404', { replace: true });
    }
  }, [mounted]);

  useEffect(() => {
    getUnsubscribed();
  }, [getUnsubscribed]);

  const handleChange = useCallback(
    async (params) => {
      try {
        await sessionApi.editCustomer(3, params.id, params.field === 'unsubscribed_at' ? moment(params.value).format('YYYY-MM-DD') : params.value, localStorage.getItem('accessToken'), params.field);
      } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de la modification !');
      }
    }
  );

  const columns = [
    {
      field: 'firstname',
      headerName: 'Prénom',
      width: 300,
    },
    {
      field: 'lastname',
      headerName: 'Nom',
      width: 300,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 300,
    },
    {
      field: 'unsubscribed_at',
      headerName: 'Date',
      type: 'date',
      renderCell: (params) => moment(params.value).format('DD/MM/YYYY'),
      width: 300,
      editable: true
    },
    {
      field: 'unsubscribed_comment',
      headerName: 'Motif',
      width: 400,
      renderCell: (params) => (
        <Tooltip
          followCursor
          enterDelay={500}
          placement="top"
          title={(<span style={{ whiteSpace: 'pre-line' }}>{params.value}</span>)}
        >
          <span>{params.value}</span>
        </Tooltip>
      ),
      editable: true,
    },
  ];

  return (
    <Card {...other}>
      <Box height={700}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[5]}
          onCellEditCommit={handleChange}
        />
      </Box>
    </Card>
  );
};

SessionDesinscriptions.propTypes = {
  sessionId: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired
};

export default SessionDesinscriptions;
