/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef, Fragment } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core';
import ArrowRightIcon from '../../../icons/ArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '../../../icons/Search';
import Scrollbar from '../../Scrollbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { customerApi } from '../../../api/customerApi';
import * as Yup from 'yup';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth';
import { CSVLink } from 'react-csv';
import * as moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/styles';

const tabs = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Médecins',
    value: 'Médecin'
  },
  {
    label: 'Infirmiers',
    value: 'Infirmier'
  },
  {
    label: 'Kinés',
    value: 'Masseur-kinésithérapeute'
  },
  {
    label: 'Podologues',
    value: 'Pédicure-podologue'
  },
  {
    label: 'Pharmaciens',
    value: 'Pharmacien'
  },
  {
    label: 'Sages-Femmes',
    value: 'Sage-Femme'
  }
];

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name) => ({
  sx: {
    bgcolor: stringToColor(name),
  },
  children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
});

const newCallTrackOptions = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Sans statut',
    value: 'empty'
  },
  {
    label: 'Vente 1 formation',
    value: 'Vente 1 formation'
  },
  {
    label: 'Vente 2 formation',
    value: 'Vente 2 formation'
  },
  {
    label: 'Vente 3 formation',
    value: 'Vente 3 formation'
  },
  {
    label: 'Déjà validé',
    value: 'Déjà validé'
  },
  {
    label: 'Souhaite du présentiel',
    value: 'Souhaite du présentiel'
  },
  {
    label: 'Aucun thème voulu',
    value: 'Aucun thème voulu'
  },
  {
    label: 'Date de session trop proche',
    value: 'Date de session trop proche'
  },
  {
    label: 'Préfère un autre organisme',
    value: 'Préfère un autre organisme'
  },
  {
    label: 'Retraite',
    value: 'Retraite'
  },
  {
    label: 'Numéro incorrect',
    value: 'Numéro incorrect'
  },
  {
    label: 'Non professionnel de santé',
    value: 'Non professionnel de santé'
  },
  {
    label: 'Souhaite un email',
    value: 'Souhaite un email'
  },
  {
    label: 'Rappel',
    value: 'Rappel'
  },
  {
    label: 'Attente',
    value: 'Attente'
  },
  {
    label: 'Appel entrant',
    value: 'Appel entrant'
  },
  {
    label: 'Pas répondu',
    value: 'Pas répondu'
  },
];

const callTrackRelanceOptions = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Sans statut',
    value: 'empty'
  },
  {
    label: 'Pas répondu',
    value: 'Pas répondu'
  },
  {
    label: 'À rappeler',
    value: 'À rappeler'
  },
  {
    label: 'Numéro incorrect',
    value: 'Numéro incorrect'
  },
  {
    label: 'Va se connecter',
    value: 'Va se connecter'
  },
  {
    label: 'Suivi - Téléphone',
    value: 'Suivi - Téléphone'
  },
  {
    label: 'Suivi - Mail',
    value: 'Suivi - Mail'
  },
  {
    label: 'Suivi - SMS',
    value: 'Suivi - SMS'
  },
  {
    label: 'Décalage',
    value: 'Décalage'
  },
  {
    label: 'Annulation',
    value: 'Annulation'
  }
];

const sortOptions = [
  {
    label: 'Fin de session plus proche',
    value: 'minSession|asc'
  },
  {
    label: 'Fin de session plus éloignée',
    value: 'minSession|desc'
  },
  {
    label: 'Nom (A - Z)',
    value: 'lastname|asc'
  },
  {
    label: 'Nom (Z - A)',
    value: 'lastname|desc'
  },
  {
    label: 'Code postal (plus petit)',
    value: 'zip|asc'
  },
  {
    label: 'Code postal (plus grand)',
    value: 'zip|desc'
  }
];

const sourceOptions = [
  {
    label: 'Toutes',
    value: 'all'
  },
  {
    label: 'Apteed',
    value: 0
  },
  {
    label: 'Nouveaux prospects',
    value: 998
  },
  {
    label: 'Prospects qualifiers',
    value: 999
  }
];

const avancementOptions = [
  {
    label: 'Tous',
    value: 'all'
  },
  {
    label: 'Pas commencé',
    value: 'Pas commencé'
  },
  {
    label: 'En cours',
    value: 'En cours'
  },
  {
    label: 'Terminé',
    value: 'Terminé'
  },
];

const applyFilters = (customers, query, filters, source, campaign, callTrack, newCallTrack, callTrackRelance, avancement, relance, finSessions,
  customersEndSessions, isRelance, modeRelance) => customers
  .filter((customer) => {
    const matches = true;

    return matches;
  });

const applyPagination = (customers, page, limit) => customers;

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (customers, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const handleDeleteCustomer = async (customerId) => {
  try {
    await customerApi.deleteCustomer(customerId, localStorage.getItem('accessToken'));
    window.location.reload();
  } catch (err) {
    console.log(err);
  }
};

const CustomerListTable = (props) => {
  const { customers, usersCampaign, modeRelance, isRelance, usersRelance, endSessions, customersEndSessions, choiceTabProfession,
    choiceSort, choiceSource, choiceCampaign, choiceCallTrack, choiceNewCallTrack, choiceCallTrackRelance, choiceAvancement, choiceEndSession, query, setQuery, setCustomers,
    numberCustomers, setNumberCustomers, loading, loadingFilter, page, setPage, limit, setLimit, relance, setRelance, ...other } = props;
  const { user } = useAuth();
  let tempChoiceTab = window.localStorage.getItem('choiceTabProfession');
  tempChoiceTab = tempChoiceTab === null ? 'all' : window.localStorage.getItem('choiceTabProfession').replaceAll('"', '');
  const [currentTab, setCurrentTab] = useState(tempChoiceTab);
  let tempChoiceSource = window.localStorage.getItem('choiceSource');
  tempChoiceSource = tempChoiceSource === null ? 'all' : window.localStorage.getItem('choiceSource').replaceAll('"', '');
  const [currentSource, setCurrentSource] = useState(tempChoiceSource);
  let tempChoiceCampaign = window.localStorage.getItem('choiceCampaign');
  tempChoiceCampaign = tempChoiceCampaign === null ? 'all' : window.localStorage.getItem('choiceCampaign').replaceAll('"', '');
  const [currentCampaign, setCurrentCampaign] = useState(tempChoiceCampaign);
  let tempChoiceCallTrack = window.localStorage.getItem('choiceCallTrack');
  tempChoiceCallTrack = tempChoiceCallTrack === null ? 'all' : window.localStorage.getItem('choiceCallTrack').replaceAll('"', '');
  const [currentCallTrack, setCurrentCallTrack] = useState(tempChoiceCallTrack);
  let tempChoiceNewCallTrack = window.localStorage.getItem('choiceNewCallTrack');
  tempChoiceNewCallTrack = tempChoiceNewCallTrack === null ? 'all' : window.localStorage.getItem('choiceNewCallTrack').replaceAll('"', '');
  const [currentNewCallTrack, setCurrentNewCallTrack] = useState(tempChoiceNewCallTrack);
  let tempChoiceNewCallTrackRelance = window.localStorage.getItem('choiceNewCallTrackRelance');
  tempChoiceNewCallTrackRelance = tempChoiceNewCallTrackRelance === null ? 'all' : window.localStorage.getItem('choiceNewCallTrackRelance').replaceAll('"', '');
  const [currentCallTrackRelance, setCurrentCallTrackRelance] = useState(tempChoiceNewCallTrackRelance);
  const tempChoiceEndSession = window.localStorage.getItem('choiceEndSession');
  const [currentEndSessions, setCurrentEndSessions] = useState(tempChoiceEndSession === null ? 'all' : window.localStorage.getItem('choiceEndSession').replaceAll('"', ''));
  const tempChoiceRelance = window.localStorage.getItem('choiceRelance');
  const [currentRelance, setCurrentRelance] = useState(tempChoiceRelance === null ? 'all' : window.localStorage.getItem('choiceRelance').replaceAll('"', ''));
  let tempChoiceAvancement = window.localStorage.getItem('choiceAvancement');
  tempChoiceAvancement = tempChoiceAvancement === null ? 'all' : window.localStorage.getItem('choiceAvancement').replaceAll('"', '');
  const [currentAvancement, setCurrentAvancement] = useState(tempChoiceAvancement);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [customerDelete, setCustomerDelete] = useState({});
  // partie autocomplete chercher un apprenant

  const [loadingAutocomplete, setLoadingAutocomplete] = useState(false);
  const [openAutoComplete, setOpenAutocomplete] = useState(false);
  const [optionsSearch, setOptionsSearch] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [isSearching, setIsSearching] = useState(false);

  const [finSessions, setFinSessions] = useState({ '2022-02-28': null });

  // pour que tous les ordis puissent fonctionner sans l'ancien filtre statut d'appel
  useEffect(() => {
    setCurrentCallTrack('all');
    window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
  }, []);

  const [source, setSource] = useState({
    0: null,
    998: null,
    999: null
  });

  const [avancement, setAvancement] = useState({
    'Pas commencé': null,
    'En cours': null,
    Terminé: null
  });

  function initCampaign() {
    const camp = {};
    usersCampaign.forEach((element) => {
      camp[element.id] = null;
    });
    camp.empty = null;
    return camp;
  }

  const [campaign, setCampaign] = useState(initCampaign);

  let tempChoiceSort = window.localStorage.getItem('choiceSort');
  tempChoiceSort = tempChoiceSort === null ? 'lastname|asc' : window.localStorage.getItem('choiceSort').replaceAll('"', '');
  const [sort, setSort] = useState(tempChoiceSort);
  const [callTrack, setCallTrack] = useState({
    empty: null,
    'Pas répondu': null,
    'À rappeler': null,
    'Numéro incorrect': null,
    Vente: null,
    'Non - Déjà fait': null,
    'Non - Pas intéressé': null,
    Attente: null
  });
  const [newCallTrack, setNewCallTrack] = useState({
    empty: null,
    'Vente 1 formation': null,
    'Vente 2 formation': null,
    'Vente 3 formation': null,
    'Déjà validé': null,
    'Souhaite du présentiel': null,
    'Aucun thème voulu': null,
    'Date de session trop proche': null,
    'Préfère un autre organisme': null,
    Retraite: null,
    'Numéro incorrect': null,
    'Non professionnel de santé': null,
    'Souhaite un email': null,
    Rappel: null,
    Attente: null,
    'Appel entrant': null,
    'Pas répondu': null
  });
  const [callTrackRelance, setCallTrackRelance] = useState({
    empty: null,
    'Pas répondu': null,
    'À rappeler': null,
    'Numéro incorrect': null,
    'Va se connecter': null,
    Annulation: null,
    'Suivi - Téléphone': null,
    'Suivi - Mail': null,
    'Suivi - SMS': null,
    Décalage: null,
  });
  const [filters, setFilters] = useState({
    Médecin: null,
    Infirmier: null,
    'Masseur-kinésithérapeute': null,
    'Pédicure-podologue': null,
    Pharmacien: null,
    'Sage-Femme': null
  });

  const usersCampaignList = [...usersCampaign];
  usersCampaignList.unshift({ firstname: 'Tous', lastname: '', id: 'all' });
  usersCampaignList.push({ firstname: 'Non ', lastname: 'assignés', id: 0 });

  const usersRelanceList = [...usersRelance];
  usersRelanceList.unshift({ firstname: 'Tous', lastname: '', id: 'all' });
  usersRelanceList.push({ firstname: 'Non ', lastname: 'assignés', id: 0 });

  const location = useLocation();

  const handleCustomers = (async (querySearch, sortChange, sourceChange, campaignChange, callTrackChange, newCallTrackChange, callTrackRelanceChange,
    avancementChange, finSessionsChange, relanceChange, tabProfession, limitChoice, pageChoice, modeRelanceChange, isBigSearch = true, displaySpinnerTable = false) => {
    try {
      // si le spinner de la page n'est pas actif (loading), on déclenche le spinner de la table des apprenants au chargement des données
      if (!loading && displaySpinnerTable) {
        setIsLoading(true);
      }
      setIsSearching(true);
      let data = [];
      if (location.pathname === '/dashboard/customers') {
        const dataCustomers = await customerApi.getCustomersInfos(window.localStorage.getItem('accessToken'), querySearch, sortChange, sourceChange, campaignChange, callTrackChange,
          newCallTrackChange, callTrackRelanceChange, avancementChange, finSessionsChange, relanceChange, tabProfession, limitChoice, pageChoice, modeRelanceChange);
        setNumberCustomers(dataCustomers.numberCustomers);
        data = await customerApi.getCustomers(localStorage.getItem('accessToken'), querySearch, sortChange, sourceChange, campaignChange, callTrackChange,
          newCallTrackChange, callTrackRelanceChange, avancementChange, finSessionsChange, relanceChange, tabProfession, limitChoice, pageChoice, modeRelanceChange, isBigSearch);
      } else if (location.pathname === '/dashboard/campagne') {
        data = await customerApi.getCustomersCampagne(window.localStorage.getItem('accessToken'), querySearch, sortChange, sourceChange, campaignChange, callTrackChange,
          newCallTrackChange, callTrackRelanceChange, avancementChange, finSessionsChange, relanceChange, tabProfession, limitChoice, pageChoice, modeRelanceChange, isBigSearch);
        setNumberCustomers(data.numberCustomers);
      }
      if (isBigSearch) {
        setCustomers(data.customers);
      }
      setOptionsSearch(data.customers);
      setIsLoading(false);
      setIsSearching(false);
      setLoadingAutocomplete(false);
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    // Réinitialisation des sélections
    setSelectedCustomers([]);

    // Réinitialisation du filtre des fin de sessions
    if (!(user.id_service === 4)) {
      setCurrentEndSessions('all');
      !loading ? window.localStorage.setItem('choiceEndSession', JSON.stringify('all')) : '';
    }

    // Réinitialisation du filtre des sources
    const updatedSource = {
      ...source,
      0: null,
      998: null,
      999: null
    };
    !loading ? window.localStorage.setItem('choiceSource', JSON.stringify('all')) : '';
    !loading ? setCurrentSource('all') : '';

    // Réinitialisation du filtre statut d'avancement
    const updatedAvancement = {
      ...avancement,
      'Pas commencé': null,
      'En cours': null,
      Terminé: null
    };
    if (!(user.id_service === 4)) {
      setCurrentAvancement('all');
      !loading ? window.localStorage.setItem('choiceAvancement', JSON.stringify('all')) : '';
    }

    // Réinitialisation du filtre statut d'appel
    const updatedCallTrack = {
      ...callTrack,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      Vente: null,
      'Non - Déjà fait': null,
      'Non - Pas intéressé': null,
      Attente: null
    };
    !loading ? window.localStorage.setItem('choiceCallTrack', JSON.stringify('all')) : '';
    !loading ? setCurrentCallTrack('all') : '';

    // Réinitialisation du filtre nouveau statut d'appel
    const updatedNewCallTrack = {
      ...newCallTrack,
      empty: null,
      'Vente 1 formation': null,
      'Vente 2 formation': null,
      'Vente 3 formation': null,
      'Déjà validé': null,
      'Souhaite du présentiel': null,
      'Aucun thème voulu': null,
      'Date de session trop proche': null,
      'Préfère un autre organisme': null,
      Retraite: null,
      'Numéro incorrect': null,
      'Non professionnel de santé': null,
      'Souhaite un email': null,
      Rappel: null,
      Attente: null,
      'Appel entrant': null,
      'Pas répondu': null
    };
    !loading ? window.localStorage.setItem('choiceNewCallTrack', JSON.stringify('all')) : '';
    !loading ? setCurrentNewCallTrack('all') : '';

    // Réinitialisation du filtre statut d'appel relance
    const updatedCallTrackRelance = {
      ...callTrackRelance,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      'Va se connecter': null,
      Annulation: null,
      'Suivi - Téléphone': null,
      'Suivi - Mail': null,
      'Suivi - SMS': null,
      Décalage: null,
    };
    if (!(user.id_service === 4)) {
      setCurrentCallTrackRelance('all');
      !loading ? window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all')) : '';
    }
    // Réinitialisation du filtre des utilisateurs commerciaux de campagnes
    const tempCampaign = {};
    usersCampaign.forEach((element) => {
      tempCampaign[element.id] = null;
    });
    const updatedCampaign = {
      ...campaign,
      ...tempCampaign,
      0: null
    };
    !loading ? window.localStorage.setItem('choiceCampaign', JSON.stringify('all')) : '';
    !loading ? setCurrentCampaign('all') : '';

    // Réinitialisation du filtre des utilisateurs à la relance
    const tempRelance = {};
    usersRelance.forEach((element) => {
      tempRelance[element.id] = null;
    });
    const updatedRelance = {
      ...relance,
      ...tempRelance,
      0: null
    };
    if (!(user.id_service === 4)) {
      setCurrentRelance('all');
      !loading ? window.localStorage.setItem('choiceRelance', JSON.stringify('all')) : '';
    }

    // réinitialisation du filtre fin de session à la relance
    setCurrentEndSessions('all');
    !loading ? window.localStorage.setItem('choiceEndDate', JSON.stringify('all')) : '';

    // requête en bdd par rapport mode commercial/relance avec réinitialisation des valeurs des filtres
    !loading ? handleCustomers(query, 'minSession|asc', 'all', 'all', 'all', 'all', 'all', 'all', 'all', 'all', filters, limit, page, modeRelance, true, true) : '';
  }, [modeRelance]);

  const handleSourceChange = (event, value) => {
    const updatedSource = {
      ...source,
      0: null,
      998: null,
      999: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedSource[value] = true;
      }
      setSource(updatedSource);
      setSelectedCustomers([]);
      setCurrentSource(value);
    } else {
      if (event.target.value !== 'all') {
        updatedSource[event.target.value] = true;
      }
      setSource(event.target.value);
      setSelectedCustomers([]);
      setCurrentSource(event.target.value);
      window.localStorage.setItem('choiceSource', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, event.target.value, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0, modeRelance, true, true);
  };

  const handleEndSessionsChange = (event) => {
    const updatedEndSessions = {
      ...finSessions,
      ...endSessions.map((es) => es.end_date).reduce((ac, a) => ({ ...ac, [a]: null }), {})
    };

    if (event.target.value !== 'all') {
      updatedEndSessions[event.target.value] = true;
    }

    setFinSessions(event.target.value);
    setSelectedCustomers([]);
    setCurrentEndSessions(event.target.value);
    window.localStorage.setItem('choiceEndSession', JSON.stringify(event.target.value));
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, event.target.value, relance, filters, limit, 0, modeRelance, true, true);
  };

  const handleAvancementChange = (event, value) => {
    const updatedAvancement = {
      ...avancement,
      'Pas commencé': null,
      'En cours': null,
      Terminé: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedAvancement[value] = true;
      }

      setAvancement(updatedAvancement);
      setSelectedCustomers([]);
      setCurrentAvancement(value);
    } else {
      if (event.target.value !== 'all') {
        updatedAvancement[event.target.value] = true;
      }

      setAvancement(event.target.value);
      setSelectedCustomers([]);
      setCurrentAvancement(event.target.value);
      window.localStorage.setItem('choiceAvancement', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, event.target.value, currentEndSessions, relance, filters, limit, 0, modeRelance, true, true);
  };

  const handleCallTrackChange = (event, value) => {
    const updatedCallTrack = {
      ...callTrack,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      Vente: null,
      'Non - Déjà fait': null,
      'Non - Pas intéressé': null,
      Attente: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCallTrack[value] = true;
      }

      setCallTrack(updatedCallTrack);
      setSelectedCustomers([]);
      setCurrentCallTrack(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCallTrack[event.target.value] = true;
      }

      setCallTrack(event.target.value);
      setSelectedCustomers([]);
      setCurrentCallTrack(event.target.value);
      window.localStorage.setItem('choiceCallTrack', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, event.target.value, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0, modeRelance, true, true);
  };

  // nouveau système de statut d'appel
  const handleNewCallTrackChange = (event, value) => {
    const updatedNewCallTrack = {
      ...newCallTrack,
      empty: null,
      'Vente 1 formation': null,
      'Vente 2 formation': null,
      'Vente 3 formation': null,
      'Déjà validé': null,
      'Souhaite du présentiel': null,
      'Aucun thème voulu': null,
      'Date de session trop proche': null,
      'Préfère un autre organisme': null,
      Retraite: null,
      'Numéro incorrect': null,
      'Non professionnel de santé': null,
      'Souhaite un email': null,
      Rappel: null,
      Attente: null,
      'Appel entrant': null,
      'Pas répondu': null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedNewCallTrack[value] = true;
      }

      setNewCallTrack(updatedNewCallTrack);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(value);
    } else {
      if (event.target.value !== 'all') {
        updatedNewCallTrack[event.target.value] = true;
      }

      setNewCallTrack(event.target.value);
      setSelectedCustomers([]);
      setCurrentNewCallTrack(event.target.value);
      window.localStorage.setItem('choiceNewCallTrack', JSON.stringify(event.target.value));
    }
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, event.target.value, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0, modeRelance, true, true);
    setPage(0);
  };

  const handleCallTrackRelanceChange = (event, value) => {
    const updatedCallTrackRelance = {
      ...callTrackRelance,
      empty: null,
      'Pas répondu': null,
      'À rappeler': null,
      'Numéro incorrect': null,
      'Va se connecter': null,
      Annulation: null,
      'Suivi - Téléphone': null,
      'Suivi - Mail': null,
      'Suivi - SMS': null,
      Décalage: null,
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCallTrackRelance[value] = true;
      }

      setCallTrackRelance(updatedCallTrackRelance);
      setSelectedCustomers([]);
      setCurrentCallTrackRelance(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCallTrackRelance[event.target.value] = true;
      }

      setCallTrackRelance(event.target.value);
      setSelectedCustomers([]);
      setCurrentCallTrackRelance(event.target.value);
      window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, event.target.value, avancement, currentEndSessions, relance, filters, limit, 0, modeRelance, true, true);
  };

  const handleCampaignChange = (event, value) => {
    const tempCampaign = {};
    usersCampaign.forEach((element) => {
      tempCampaign[element.id] = null;
    });
    const updatedCampaign = {
      ...campaign,
      ...tempCampaign,
      0: null
    };

    if (typeof event.target === 'undefined') {
      if (value !== 'all') {
        updatedCampaign[value] = true;
      }

      setCampaign(updatedCampaign);
      setSelectedCustomers([]);
      setCurrentCampaign(value);
    } else {
      if (event.target.value !== 'all') {
        updatedCampaign[event.target.value] = true;
      }
      setCampaign(event.target.value);
      setSelectedCustomers([]);
      setCurrentCampaign(event.target.value);
      window.localStorage.setItem('choiceCampaign', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, sort, currentSource, event.target.value, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0, modeRelance, true, true);
  };

  const handleRelanceChange = (event) => {
    const tempRelance = {};
    usersRelance.forEach((element) => {
      tempRelance[element.id] = null;
    });
    const updatedRelance = {
      ...relance,
      ...tempRelance,
      0: null
    };

    if (event.target.value !== 'all') {
      updatedRelance[event.target.value] = true;
    }

    setRelance(event.target.value);
    setSelectedCustomers([]);
    setCurrentRelance(event.target.value);
    window.localStorage.setItem('choiceRelance', JSON.stringify(event.target.value));
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, event.target.value, filters, limit, 0, modeRelance, true, true);
  };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      Médecin: null,
      Infirmier: null,
      'Masseur-kinésithérapeute': null,
      'Pédicure-podologue': null,
      Pharmacien: null,
      'Sage-Femme': null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }
    setFilters(value);
    setSelectedCustomers([]);
    setCurrentTab(value);
    window.localStorage.setItem('choiceTabProfession', JSON.stringify(value));
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, currentCallTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, value, limit, 0, modeRelance, true, true);
  };

  const handleSortChange = (event, value) => {
    if (typeof event.target === 'undefined') {
      setSort(value);
    } else {
      setSort(event.target.value);
      window.localStorage.setItem('choiceSort', JSON.stringify(event.target.value));
    }
    setPage(0);
    handleCustomers(query, event.target.value, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, 0, modeRelance, true, true);
  };

  const reinitializeFilters = () => {
    // on met à jour le localstorage
    window.localStorage.setItem('choiceTabProfession', JSON.stringify('all'));
    window.localStorage.setItem('choiceRelance', JSON.stringify('all'));
    window.localStorage.setItem('choiceCampaign', JSON.stringify('all'));
    window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all'));
    window.localStorage.setItem('choiceNewCallTrack', JSON.stringify('all'));
    window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
    window.localStorage.setItem('choiceAvancement', JSON.stringify('all'));
    window.localStorage.setItem('choiceEndSession', JSON.stringify('all'));
    window.localStorage.setItem('choiceSource', JSON.stringify('all'));
    // on réinitialise les filtres
    setCurrentTab('all');
    setCurrentRelance('all');
    setCurrentCampaign('all');
    setCurrentCallTrackRelance('all');
    setCurrentNewCallTrack('all');
    setCurrentCallTrack('all');
    setCurrentAvancement('all');
    setCurrentEndSessions('all');
    setCurrentSource('all');
    // on affiche les résultats réinitialisés
    handleCustomers('', 'lastname|asc', 'all', 'all', 'all', 'all', 'all', 'all', 'all', 'all', 'all', limit, page, modeRelance, true, true);
  };

  useEffect(() => {
    if (choiceTabProfession !== '' && choiceTabProfession !== 'undefined') {
      !loading ? handleTabsChange((event) => event, choiceTabProfession) : '';
    }
  }, [choiceTabProfession]);

  useEffect(() => {
    if (choiceSort !== '' && choiceSort !== 'undefined' && typeof choiceSort !== 'string') {
      handleSortChange((event) => event, choiceSort);
    }
    if (choiceSource !== '' && choiceSource !== 'undefined' && typeof choiceSource !== 'string') {
      handleSourceChange((event) => event, choiceSource);
    }
    if (choiceCampaign !== '' && choiceCampaign !== 'undefined' && typeof choiceCampaign !== 'string') {
      handleCampaignChange((event) => event, choiceCampaign);
    }
    if (choiceCallTrack !== '' && choiceCallTrack !== 'undefined' && typeof choiceCallTrack !== 'string') {
      handleCallTrackChange((event) => event, choiceCallTrack);
    }
    if (choiceNewCallTrack !== '' && choiceNewCallTrack !== 'undefined' && typeof choiceNewCallTrack !== 'string') {
      handleNewCallTrackChange((event) => event, choiceNewCallTrack);
    }
    if (choiceCallTrackRelance !== '' && choiceCallTrackRelance !== 'undefined' && typeof choiceCallTrackRelance !== 'string') {
      handleCallTrackRelanceChange((event) => event, choiceCallTrackRelance);
    }
    if (choiceAvancement !== '' && choiceAvancement !== 'undefined' && typeof choiceAvancement !== 'string') {
      handleAvancementChange((event) => event, choiceAvancement);
    }
    if (choiceEndSession !== '' && choiceEndSession !== 'undefined' && typeof choiceEndSession !== 'string') {
      handleEndSessionsChange((event) => event, choiceEndSession);
    }
  }, [choiceSort, choiceSource, choiceCampaign, choiceCallTrack, choiceNewCallTrack, choiceCallTrackRelance, choiceAvancement, choiceEndSession]);

  const handleQueryChange = (event) => {
    // pour remettre à la première page de résultats quand on fait une recherche
    setPage(0);
    setQuery(event.target.value);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let active = false;
    if (query.length >= 3) {
      active = true;
      setLoadingAutocomplete(true);
    } else {
      setOptionsSearch([]);
      setCustomers([]);
    }

    if (active) {
      handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, page, modeRelance, false);
    }
  }, [query]);

  useEffect(() => {
    if (!openAutoComplete) {
      setOptionsSearch([]);
    }
  }, [openAutoComplete]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, newPage, modeRelance, true, true);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
    handleCustomers(query, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, parseInt(event.target.value, 10), 0, modeRelance, true, true);
  };

  const filteredCustomers = applyFilters(customers, query, filters, source, campaign, callTrack, newCallTrack, callTrackRelance,
    avancement, relance, finSessions, customersEndSessions, isRelance, modeRelance);
  const sortedCustomers = applySort(filteredCustomers, sort);
  const paginatedCustomers = applyPagination(sortedCustomers, page, limit);
  const enableBulkActions = selectedCustomers.length > 0;
  const selectedSomeCustomers = selectedCustomers.length > 0
    && selectedCustomers.length < paginatedCustomers.length;
  const selectedAllCustomers = selectedCustomers.length === paginatedCustomers.length;

  const handleSelectAllCustomers = (event) => {
    setSelectedCustomers(event.target.checked
      ? paginatedCustomers.map((customer) => customer.id)
      : []);
  };

  const handleSelectOneCustomer = (event, customerId) => {
    if (!selectedCustomers.includes(customerId)) {
      setSelectedCustomers((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedCustomers((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const dialogOpener = (index) => {
    setOpen(true);
    setCustomerDelete({ civility: paginatedCustomers[index].civility, firstname: paginatedCustomers[index].firstname, lastname: paginatedCustomers[index].lastname, id: paginatedCustomers[index].id });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // pour afficher le loader uniquement dans le tableau
  const StyledPaper = withStyles({
    root: {
      position: 'relative'
    }
  })(Paper);
  const LimitedBackdrop = withStyles({
    root: {
      position: 'absolute',
      zIndex: 1
    }
  })(Backdrop);

  return (
    <Formik
      initialValues={{ userAssigned: null }}
      enableReinitialize
      validationSchema={Yup
        .object()
        .shape({
          addCustomer: Yup.number().nullable()
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (!modeRelance) {
            await customerApi.assignCampaign(values.userAssigned, selectedCustomers, localStorage.getItem('accessToken'));
            setStatus({ success: true });
            setSubmitting(false);
            toast.success('Apprenants assignés !');
            selectedCustomers.forEach((id) => {
              const foundCustomer = customers.find((customer) => customer.id === id);
              foundCustomer.campaign_source_id = values.userAssigned;
              foundCustomer.userCampaignFirstname = usersCampaign.find((userCamp) => userCamp.id === values.userAssigned).firstname;
              foundCustomer.userCampaignLastname = usersCampaign.find((userCamp) => userCamp.id === values.userAssigned).lastname;
            });
          } else {
            await customerApi.assignRelance(values.userAssigned, selectedCustomers, localStorage.getItem('accessToken'));
            setStatus({ success: true });
            setSubmitting(false);
            toast.success('Apprenants assignés !');
            selectedCustomers.forEach((id) => {
              const foundCustomer = customers.find((customer) => customer.id === id);
              foundCustomer.relance_user_id = values.userAssigned;
              foundCustomer.userRelanceFirstname = usersRelance.find((userRel) => userRel.id === values.userAssigned).firstname;
              foundCustomer.userRelanceLastname = usersRelance.find((userRel) => userRel.id === values.userAssigned).lastname;
            });
          }
        } catch (err) {
          console.error(err);
          toast.error('Il y a eu un souci lors de l\'assignation !');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleSubmit, isSubmitting, touched, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Card {...other}>
            <Dialog
              open={open}
              onClose={handleCloseDialog}
            >
              <DialogContent>
                <DialogContentText>
                  Souhaitez-vous vraiment supprimer l&apos;
                  {customerDelete.civility === 'Madame' ? 'apprenante' : 'apprenant'}
                  {' '}
                  {customerDelete.firstname}
                  {' '}
                  {customerDelete.lastname}
                  {' ?'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleDeleteCustomer(customerDelete.id)}
                  color="primary"
                >
                  Supprimer
                </Button>
                <Button
                  onClick={handleCloseDialog}
                  color="primary"
                >
                  Annuler
                </Button>
              </DialogActions>
            </Dialog>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="primary"
              value={currentTab}
              variant="scrollable"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
            <Divider />
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                m: -1,
                p: 2
              }}
            >
              <Box
                sx={{
                  m: 1,
                  maxWidth: '100%',
                  width: 500
                }}
              >
                <Autocomplete
                  id="asynchronous-demo"
                  open={openAutoComplete}
                  onOpen={() => {
                    setOpenAutocomplete(true);
                  }}
                  onClose={() => {
                    setOpenAutocomplete(false);
                  }}
                  getOptionLabel={(option) => `${option.id.toString()}`}
                  renderOption={(properties, option) => (
                    <Link
                      color="inherit"
                      component={RouterLink}
                      to={`/dashboard/customers/${option.id}`}
                      variant="subtitle2"
                      style={{ textDecoration: 'none' }}
                    >
                      <li
                        key={option.id}
                        {...properties}
                      >
                        <span style={{ whiteSpace: 'pre-line' }}>
                          {`${option.firstname} ${option.lastname}\n`}
                          <span style={{ color: 'grey' }}>{option.email}</span>
                        </span>
                      </li>
                    </Link>
                  )}
                  options={optionsSearch}
                  loading={loadingAutocomplete}
                  clearOnBlur={false}
                  onInputChange={handleQueryChange}
                  filterOptions={(options) => options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Recherche"
                      placeholder="Chercher un apprenant"
                      value={query}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingAutocomplete ? (
                              <CircularProgress
                                color="inherit"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        ),
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                            handleCustomers(e.target.value, sort, currentSource, currentCampaign, callTrack, currentNewCallTrack, currentCallTrackRelance, avancement, currentEndSessions, relance, filters, limit, page, modeRelance, true, true);
                            setOpenAutocomplete(false);
                          }
                        }
                      }}
                    />
                  )}
                />
              </Box>
              {!modeRelance && !isRelance && (
                <>
                  <Box
                    sx={{
                      m: 1
                    }}
                  >
                    <TextField
                      key="sort"
                      label="Trier par"
                      name="sort"
                      onChange={handleSortChange}
                      select
                      SelectProps={{ native: true }}
                      value={sort}
                      variant="outlined"
                      disabled={isSearching}
                    >
                      {sortOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  {user.access_level === 0 && (
                  <Box
                    sx={{
                      m: 1
                    }}
                  >
                    <TextField
                      key="source"
                      label="Source"
                      name="source"
                      onChange={handleSourceChange}
                      select
                      SelectProps={{ native: true }}
                      value={currentSource}
                      variant="outlined"
                      disabled={isSearching}
                    >
                      {sourceOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  )}
                </>
              )}
              {user.access_level === 0 && !modeRelance && (
                <>
                  <Box
                    sx={{
                      m: 1
                    }}
                  >
                    <TextField
                      key="campaign"
                      label="Campagne"
                      name="campaign"
                      onChange={handleCampaignChange}
                      select
                      SelectProps={{ native: true }}
                      value={currentCampaign}
                      variant="outlined"
                      disabled={isSearching}
                    >
                      {usersCampaignList.map((option) => (
                        <option
                          key={option.id}
                          value={option.id}
                        >
                          {`${option.firstname} ${option.lastname}`}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                </>
              )}
              {!modeRelance && !isRelance && (
                <Box
                  sx={{
                    m: 1,
                    width: 240
                  }}
                >
                  <TextField
                    key="newCallTrack"
                    label="Statut d'appel"
                    name="newCallTrack"
                    onChange={handleNewCallTrackChange}
                    select
                    SelectProps={{ native: true }}
                    value={currentNewCallTrack}
                    variant="outlined"
                    disabled={isSearching}
                  >
                    {newCallTrackOptions.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Box>
              )}
              {(modeRelance || isRelance) && (
                <>
                  <Box
                    sx={{
                      m: 1
                    }}
                  >
                    <TextField
                      key="sort"
                      label="Trier par"
                      name="sort"
                      onChange={handleSortChange}
                      select
                      SelectProps={{ native: true }}
                      value={sort}
                      variant="outlined"
                      disabled={isSearching}
                    >
                      {sortOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      m: 1,
                    }}
                  >
                    <TextField
                      key="callTrackRelance"
                      label="Statut d'appel (Relance)"
                      name="callTrackRelance"
                      onChange={handleCallTrackRelanceChange}
                      select
                      SelectProps={{ native: true }}
                      value={currentCallTrackRelance}
                      variant="outlined"
                      disabled={isSearching}
                    >
                      {callTrackRelanceOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      m: 1
                    }}
                  >
                    <TextField
                      key="avancement"
                      label="Statut d'avancement"
                      name="avancement"
                      onChange={handleAvancementChange}
                      select
                      SelectProps={{ native: true }}
                      value={currentAvancement}
                      variant="outlined"
                      disabled={isSearching}
                    >
                      {avancementOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  <Box
                    sx={{
                      m: 1
                    }}
                  >
                    <TextField
                      key="end_session"
                      label="Fin de session"
                      name="end_session"
                      onChange={handleEndSessionsChange}
                      select
                      SelectProps={{ native: true }}
                      value={currentEndSessions}
                      variant="outlined"
                      disabled={isSearching}
                    >
                      <option
                        key="all"
                        value="all"
                      >
                        Toutes
                      </option>
                      {endSessions.map((option) => (
                        <option
                          key={option.end_date}
                          value={option.end_date}
                        >
                          {moment(option.end_date).format('DD/MM/YYYY')}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                </>
              )}
              {user.access_level === 0 && modeRelance && (
                <Box
                  sx={{
                    m: 1,
                    width: 240
                  }}
                >
                  <TextField
                    key="relance"
                    label="Relance"
                    name="relance"
                    onChange={handleRelanceChange}
                    select
                    SelectProps={{ native: true }}
                    value={currentRelance}
                    variant="outlined"
                    disabled={isSearching}
                  >
                    {usersRelanceList.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                      >
                        {`${option.firstname} ${option.lastname}`}
                      </option>
                    ))}
                  </TextField>
                </Box>
              )}
              <Button
                onClick={reinitializeFilters}
                color="primary"
                sx={{ ml: 2 }}
                variant="outlined"
              >
                Réinitialiser les filtres
              </Button>
            </Box>

            {enableBulkActions && (
              <Box sx={{ position: 'relative' }}>
                <Box
                  sx={{
                    backgroundColor: 'background.paper',
                    mt: '6px',
                    position: 'absolute',
                    px: '4px',
                    width: '100%',
                    zIndex: 2
                  }}
                >
                  <Checkbox
                    checked={selectedAllCustomers}
                    color="primary"
                    indeterminate={selectedSomeCustomers}
                    onChange={handleSelectAllCustomers}
                  />
                  {!modeRelance && user.access_level === 0 && (
                    <Autocomplete
                      getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                      options={usersCampaign}
                      sx={{ ml: 2, width: 200 }}
                      onChange={(e, value) => {
                        setFieldValue(
                          'userAssigned',
                          value !== null ? value.id : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.usersCampaign && errors.usersCampaign)}
                          fullWidth
                          helperText={touched.usersCampaign && errors.usersCampaign}
                          key="userAssigned"
                          label="Utilisateur à assigner"
                          name="userAssigned"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                  {modeRelance && user.access_level === 0 && (
                    <Autocomplete
                      getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
                      options={usersRelance}
                      sx={{ ml: 2, width: 200 }}
                      onChange={(e, value) => {
                        setFieldValue(
                          'userAssigned',
                          value !== null ? value.id : null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.usersRelance && errors.usersRelance)}
                          fullWidth
                          helperText={touched.usersRelance && errors.usersRelance}
                          key="userAssigned"
                          label="Utilisateur à assigner"
                          name="userAssigned"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                  {user.access_level === 0 && (
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      sx={{ ml: 2 }}
                      variant="outlined"
                      type="submit"
                    >
                      Assigner
                    </Button>
                  )}
                  <CSVLink
                    style={{ textDecoration: 'none' }}
                    data={[customers.filter((customer) => selectedCustomers.includes(customer.id)).map((cus) => cus.email)]}
                    enclosingCharacter=""
                  >
                    <Button
                      color="primary"
                      sx={{ ml: 2 }}
                      variant="outlined"
                    >
                      Exporter les emails
                    </Button>
                  </CSVLink>
                </Box>
              </Box>
            )}
            <StyledPaper>
              <Scrollbar>

                <Box sx={{ minWidth: 700 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedAllCustomers}
                            color="primary"
                            indeterminate={selectedSomeCustomers}
                            onChange={handleSelectAllCustomers}
                          />
                        </TableCell>
                        <TableCell>
                          Nom et Email
                        </TableCell>
                        <TableCell>
                          Lieu
                        </TableCell>
                        <TableCell>
                          Profession
                        </TableCell>
                        {user.access_level === 0 && !modeRelance && (
                          <>
                            <TableCell>
                              Campagne
                            </TableCell>
                          </>
                        )}
                        {user.access_level === 0 && modeRelance && (
                          <>
                            <TableCell>
                              Relance
                            </TableCell>
                          </>
                        )}
                        <TableCell align="right">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {paginatedCustomers.map((customer, index) => {
                        const isCustomerSelected = selectedCustomers.includes(customer.id);

                        return (
                          <TableRow
                            hover
                            key={customer.id}
                            selected={isCustomerSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isCustomerSelected}
                                color="primary"
                                onChange={(event) => handleSelectOneCustomer(event, customer.id)}
                                value={isCustomerSelected}
                              />
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Avatar
                                  src={customer.avatar}
                                  sx={{
                                    height: 42,
                                    width: 42
                                  }}
                                  {...stringAvatar(`${customer.firstname.replace(/ /g, '')} ${customer.lastname.replace(/ /g, '')}`)}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <Link
                                    color={user.id === customer.first_source_id ? '#b32d00' : 'inherit'}
                                    component={RouterLink}
                                    to={`/dashboard/customers/${customer.id}`}
                                    variant="subtitle2"
                                  >
                                    {customer.firstname}
                                    {' '}
                                    {customer.lastname}
                                  </Link>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {customer.email}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {customer.city}
                              {', '}
                              {customer.department}
                              {', '}
                              {customer.region}
                            </TableCell>
                            <TableCell>
                              {customer.profession}
                            </TableCell>
                            {user.access_level === 0 && !modeRelance && (
                              <>
                                <TableCell>
                                  {customer.userCampaignFirstname}
                                  {' '}
                                  {customer.userCampaignLastname}
                                </TableCell>
                              </>
                            )}
                            {user.access_level === 0 && modeRelance && (
                              <>
                                <TableCell>
                                  {customer.userRelanceFirstname}
                                  {' '}
                                  {customer.userRelanceLastname}
                                </TableCell>
                              </>
                            )}
                            <TableCell align="right">
                              <IconButton
                                component={RouterLink}
                                to={`/dashboard/customers/${customer.id}`}
                              >
                                <ArrowRightIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => dialogOpener(index)}
                                disabled={user.access_level !== 0}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <LimitedBackdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                      >
                        <CircularProgress color="inherit" />
                      </LimitedBackdrop>
                    </TableBody>
                  </Table>
                </Box>
              </Scrollbar>
            </StyledPaper>
            <TablePagination
              component="div"
              count={numberCustomers}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              // voir si on laisse que l'option 25
              rowsPerPageOptions={[15, 25, 50]}
              SelectProps={{
                disabled: isSearching
              }}
              backIconButtonProps={
                isSearching
                  ? { disabled: isSearching } : undefined
              }
              nextIconButtonProps={
                isSearching
                  ? { disabled: isSearching } : undefined
              }
            />
          </Card>
        </form>
      )}
    </Formik>
  );
};

CustomerListTable.propTypes = {
  customers: PropTypes.array.isRequired,
  usersCampaign: PropTypes.array.isRequired,
  modeRelance: PropTypes.bool.isRequired,
  isRelance: PropTypes.bool.isRequired,
  usersRelance: PropTypes.array.isRequired,
  endSessions: PropTypes.array,
  customersEndSessions: PropTypes.array,
  choiceTabProfession: PropTypes.string,
  choiceSort: PropTypes.string,
  choiceSource: PropTypes.string,
  choiceCampaign: PropTypes.string,
  choiceCallTrack: PropTypes.string,
  choiceNewCallTrack: PropTypes.string,
  choiceCallTrackRelance: PropTypes.string,
  choiceAvancement: PropTypes.string,
  choiceEndSession: PropTypes.string,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  setCustomers: PropTypes.func,
  numberCustomers: PropTypes.number,
  setNumberCustomers: PropTypes.func,
  loading: PropTypes.bool,
  loadingFilter: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
  setPage: PropTypes.func,
  setLimit: PropTypes.func,
  relance: PropTypes.object,
  setRelance: PropTypes.func

};

export default CustomerListTable;
