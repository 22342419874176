class CampaignApi {
  async getCampaign(region, department, profession, sort, commercial, type, accessToken) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/campaignStats`;
    const values = {};
    values.region = region;
    values.department = department;
    values.profession = profession;
    values.sort = sort;
    values.commercial = commercial;
    values.type = type;

    const resp = await fetch(urlCampaign, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getRegions(campaignId, accessToken) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/campaign/regions`;
    const values = {};
    values.campaignId = campaignId;

    const resp = await fetch(urlCampaign, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getStatusReinjection(campaignId, accessToken) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/campaign/status`;
    const values = {};
    values.campaignId = campaignId;

    const resp = await fetch(urlCampaign, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCustomersCampaign(campaignId, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/campaign/customers`;
    const values = {};
    values.campaignId = campaignId;
    const resp = await fetch(URL_ADD, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }

  async getCustomersCampaignReinjectionByStatus(campaignId, status, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/campaign/customers/status`;
    const values = {};
    values.campaignId = campaignId;
    values.status = status;
    const resp = await fetch(URL_ADD, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }

  async getProspects(professions, departments, accessToken) {
    const URL_PROSPECTS = `${process.env.REACT_APP_API_URL}/api/campaign/filtered-prospects`;
    const values = {};
    values.professions = professions;
    values.departments = departments;
    const resp = await fetch(URL_PROSPECTS, {

      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getCampaignInfos(campaignId, accessToken) {
    const urlCampaigns = `${process.env.REACT_APP_API_URL}/api/campaign/infos`;

    const values = {};
    values.campaignId = campaignId;
    const resp = await fetch(urlCampaigns, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }

  async getProfessionsList(accessToken) {
    const urlCampaigns = `${process.env.REACT_APP_API_URL}/api/campaign/get-professions-list`;

    const resp = await fetch(urlCampaigns, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }

  async getCustomerTime(customerId, campaignId, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/campaign/get-time`;
    const values = {};
    values.campaignId = campaignId;
    values.customerId = customerId;
    const resp = await fetch(URL_ADD, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCustomerTimeReinjection(customerId, campaignId, accessToken) {
    const URL_ADD = `${process.env.REACT_APP_API_URL}/api/campaign/get-time-reinjection`;
    const values = {};
    values.campaignId = campaignId;
    values.customerId = customerId;
    const resp = await fetch(URL_ADD, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCampaignInfo(campaignId, accessToken, query = null, campaign = null, newCallTrack = null, profession = null, page = 0, limit = 25) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/campaignStatsId`;
    const values = {};
    values.campaignId = campaignId;
    values.query = query;
    values.campaign = campaign;
    values.newCallTrack = newCallTrack;
    values.profession = profession;
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlCampaign, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getCampaignInfoReinj(campaignId, accessToken, query = null, campaign = null, newCallTrack = null, profession = null, page = 0, limit = 25) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/campaignStatsIdReinj`;
    const values = {};
    values.campaignId = campaignId;
    values.query = query;
    values.campaign = campaign;
    values.newCallTrack = newCallTrack;
    values.profession = profession;
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlCampaign, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getReinjectionInfo(campaignId, accessToken, query = null, campaign = null, newCallTrack = null, profession = null, page = 0, limit = 25) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/getReinjectionInfo`;
    const values = {};
    values.campaignId = campaignId;
    values.query = query;
    values.campaign = campaign;
    values.newCallTrack = newCallTrack;
    values.profession = profession;
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlCampaign, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async saveTime(customerId, campaignId, time, accessToken) {
    const URL_ASSIGN = `${process.env.REACT_APP_API_URL}/api/campaign/save-time`;
    const values = {};
    values.customerId = customerId;
    values.campaignId = campaignId;
    values.time = time;
    const resp = await fetch(URL_ASSIGN, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async saveTimeReinjection(customerId, campaignId, time, accessToken) {
    const URL_ASSIGN = `${process.env.REACT_APP_API_URL}/api/campaign/save-time-reinjection`;
    const values = {};
    values.customerId = customerId;
    values.campaignId = campaignId;
    values.time = time;
    const resp = await fetch(URL_ASSIGN, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async createCampaign(professions, departments, commercials, usersCampaign, importNames, infos, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/create-campaign`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ professions, departments, commercials, usersCampaign, importNames, infos }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async deleteCampaign(id, accessToken) {
    const URL_DELETE = `${process.env.REACT_APP_API_URL}/api/campaign/delete-campaign`;
    const resp = await fetch(URL_DELETE, {
      method: 'POST',
      body: JSON.stringify(id),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async editCampaign(field, value, campaignId, accessToken) {
    const URL_EDIT = `${process.env.REACT_APP_API_URL}/api/campaign/edit-campaign`;
    const resp = await fetch(URL_EDIT, {
      method: 'POST',
      body: JSON.stringify({ field, value, campaignId }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getCampaignAddCustomer(accessToken, query = null, campaign = null, newCallTrack = null, profession = null, page = 0, limit = 25) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/add-customer`;
    const values = {};
    values.query = query;
    values.campaign = campaign;
    values.newCallTrack = newCallTrack;
    values.profession = profession;
    values.page = page;
    values.limit = limit;

    const resp = await fetch(urlCampaign, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async assignCustomersCampaign(selectedCustomers, campaign, callerId, accessToken) {
    const URL_ASSIGN = `${process.env.REACT_APP_API_URL}/api/assign-customers`;
    const values = {};
    values.selectedCustomers = selectedCustomers;
    values.campaign = campaign;
    values.callerId = callerId;
    const resp = await fetch(URL_ASSIGN, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getQualificationsReinj(accessToken) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/campaign/getQualificationsReinj`;

    const resp = await fetch(urlCampaign, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getTypeCampaign(campaignId, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/getTypeCampaign`;

    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getCommercialsForAdding(campaignId, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/getCommercialsForAdding`;

    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getCommercialsCampaign(campaignId, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/getCommercialsCampaign`;

    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async createReinjection(campaignId, typeCampaign, status, customInfos, commercials, commercialsSelected, commercialSelectedId, infos, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/create-reinjection`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId, typeCampaign, status, customInfos, commercials, commercialsSelected, commercialSelectedId, infos }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async addCommercialToCampaign(campaignId, commercial, commercials, commercialsEquitable, typeCampaign, infos, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/addCommercialToCampaign`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId, commercial, commercials, commercialsEquitable, typeCampaign, infos }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async manageCommercials(campaignId, infos, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/manageCommercials`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId, infos }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async addCommercialsToCampaign(campaignId, commercialsToAdd, commercials, commercialsEquitable, typeCampaign, infos, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/addCommercialsToCampaign`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId, commercialsToAdd, commercials, commercialsEquitable, typeCampaign, infos, accessToken }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getCommercialsCampaignManage(campaignId, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/getCommercialsCampaignManage`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async deleteCommercial(idCampaign, commercialToDelete, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/deleteCommercial`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ idCampaign, commercialToDelete }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async attributeNotAttributedCommercial(idCampaign, commercialToAttribute, nbToAttribute, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/attributeNotAttributedCommercial`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ idCampaign, commercialToAttribute, nbToAttribute }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async retrieveCustomers(campaignId, status, commercialSelectedId, accessToken) {
    const URL_CAMPAIGN = `${process.env.REACT_APP_API_URL}/api/campaign/retrieve-customers`;
    const resp = await fetch(URL_CAMPAIGN, {
      method: 'POST',
      body: JSON.stringify({ campaignId, status, commercialSelectedId }),

      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getCampaignLogs(campaignId, sort = null, user, category, type, limit = 25, page = 0, accessToken) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/campaign/logs`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.campaignId = campaignId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getCampaignLogsInfos(campaignId, accessToken) {
    const urlCustomer = `${process.env.REACT_APP_API_URL}/api/campaign/logs/infos/${campaignId}`;

    const resp = await fetch(urlCustomer, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getLogs(accessToken, campaignId, sort = null, user, category, type, limit = 25, page = 0) {
    const urlLogs = `${process.env.REACT_APP_API_URL}/api/campaign/logs/filtered`;
    const values = {};
    values.sort = sort;
    values.user = user;
    values.category = category;
    values.type = type;
    values.campaignId = campaignId;
    values.limit = limit;
    values.page = page;
    const resp = await fetch(urlLogs, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return Promise.resolve(json.data);
  }

  async getProgressAddCommercials(campaignId, accessToken) {
    const urlCampaign = `${process.env.REACT_APP_API_URL}/api/progressFollowUp/getProgressAddCommercials/${campaignId}`;
    const resp = await fetch(urlCampaign, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json;
  }

  async getImportNamesList(accessToken) {
    const urlCampaigns = `${process.env.REACT_APP_API_URL}/api/campaign/getImportNames`;

    const resp = await fetch(urlCampaigns, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();

    return json.data;
  }
}

export const campaignApi = new CampaignApi();
