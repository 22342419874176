/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import * as moment from 'moment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PanToolIcon from '@mui/icons-material/PanTool';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PencilAltIcon from '../../../icons/PencilAlt';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CancelIcon from '@material-ui/icons/Cancel';
import HistoryIcon from '@mui/icons-material/History';
import useAuth from '../../../hooks/useAuth';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { customerApi } from '../../../api/customerApi';
import toast from 'react-hot-toast';
import useMounted from '../../../hooks/useMounted';
import CampaignCustomerCommentDetails from './CampaignCustomerCommentDetails';
import CampaignCustomerFormationsListTable from './CampaignCustomerFormationsListTable';

const CampaignCustomerCommercialCommentReinj = (props) => {
  const { comment_commercial, comment_relance, customer, formations, customerId, campaignId, setIsTreated, saveTimeReinjection, ...other } = props;
  const { user } = useAuth();
  const [commenting, setCommenting] = useState(false);
  const [commentCommercial, setCommentCommercial] = useState(comment_commercial);

  // partie affichage du commentaire commercial
  const [showHistory, setShowHistory] = useState(false);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  let qualification = '';
  let reason = '';
  let recallDate = '';
  let createdAt = '';
  let comment = '';
  let nameFormation1 = '';
  let nameFormation2 = '';
  let nameFormation3 = '';
  let nameOfferedFormation = '';
  let nameFormation1Transf = '';
  let nameFormation2Transf = '';
  let nameFormation3Transf = '';
  let nameOfferedFormationTransf = '';
  if (commentCommercial) {
    qualification = commentCommercial.qualification;
    reason = commentCommercial.reason;
    recallDate = commentCommercial.recall_date;
    createdAt = commentCommercial.createdAt;
    comment = commentCommercial.comment;
    nameFormation1 = commentCommercial.nameFormation1;
    nameFormation2 = commentCommercial.nameFormation2;
    nameFormation3 = commentCommercial.nameFormation3;
    nameOfferedFormation = commentCommercial.nameOfferedFormation;
    nameFormation1Transf = '';
    nameFormation2Transf = '';
    nameFormation3Transf = '';
    nameOfferedFormationTransf = '';
    if (nameFormation1 !== null && nameFormation1 !== undefined) {
      if (nameFormation1.length > 50) {
        nameFormation1Transf = `${nameFormation1.substring(0, 50)}...`;
      } else {
        nameFormation1Transf = nameFormation1;
      }
    }
    if (nameFormation2 !== null && nameFormation2 !== undefined) {
      if (nameFormation2.length > 50) {
        nameFormation2Transf = `${nameFormation2.substring(0, 50)}...`;
      } else {
        nameFormation2Transf = nameFormation2;
      }
    }
    if (nameFormation3 !== null && nameFormation3 !== undefined) {
      if (nameFormation3.length > 50) {
        nameFormation3Transf = `${nameFormation3.substring(0, 50)}...`;
      } else {
        nameFormation3Transf = nameFormation3;
      }
    }
    if (nameOfferedFormation !== null && nameOfferedFormation !== undefined) {
      if (nameOfferedFormation.length > 40) {
        nameOfferedFormationTransf = `${nameOfferedFormation.substring(0, 40)}...`;
      } else {
        nameOfferedFormationTransf = nameOfferedFormation;
      }
    }
  }

  // partie poster un commentaire commercial
  let qualificationLabel = [];
  if (customer.apprenant.profession === 'Masseur-kinésithérapeute' || customer.apprenant.profession === 'Infirmier' || customer.apprenant.profession === 'Pharmacien') {
    qualificationLabel = ['Vente 1 formation', 'Vente 2 formation', 'Refus', 'Erreur', 'Souhaite un email', 'Rappel', 'Attente', 'Appel entrant', 'Pas répondu'];
  } else {
    qualificationLabel = ['Vente 1 formation', 'Vente 2 formation', 'Vente 3 formation', 'Refus', 'Erreur', 'Souhaite un email', 'Rappel', 'Attente', 'Appel entrant', 'Pas répondu'];
  }
  const qualificationRejectionLabel = ['Déjà validé', 'Souhaite du présentiel', 'Aucun thème voulu', 'Date de session trop proche', 'Préfère un autre organisme'];
  const qualificationErrorLabel = ['Retraite', 'Numéro incorrect', 'Non professionnel de santé'];
  // pour récupérer les formations liées au métier de l'apprenant
  const [freeFormationsLabel, setFreeFormationsLabel] = useState([]);
  const [choiceQualification, setChoiceQualification] = useState(null);
  const [choiceQualificationError, setChoiceQualificationError] = useState(null);
  const [choiceQualificationRejection, setChoiceQualificationRejection] = useState(null);
  const [choiceQualificationRecall, setChoiceQualificationRecall] = useState(null);
  const [choiceFormation1, setChoiceFormation1] = useState(null);
  const [choiceFormation2, setChoiceFormation2] = useState(null);
  const [choiceFormation3, setChoiceFormation3] = useState(null);
  const [choiceFreeFormation, setChoiceFreeFormation] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const [displayRejection, setDisplayRejection] = useState(false);
  const [displayDateRecall, setDisplayDateRecall] = useState(false);
  const [displayFreeFormation, setDisplayFreeFormation] = useState(false);
  const [displayFormation1, setDisplayFormation1] = useState(false);
  const [displayFormation2, setDisplayFormation2] = useState(false);
  const [displayFormation3, setDisplayFormation3] = useState(false);

  useEffect(() => {
    const tempFreeFormations = [];
    formations.forEach((formation) => {
      const publicForFormation = formation.public.split(' ');
      publicForFormation.forEach((el) => {
        if (el === customer.apprenant.profession) {
          tempFreeFormations.push({ name: formation.name, id: formation.id, public: formation.public });
        }
      });
    });
    setFreeFormationsLabel(tempFreeFormations);
  }, [formations]);

  const formations1 = freeFormationsLabel.filter((el) => el !== choiceFormation3 && el !== choiceFormation2 && el !== choiceFreeFormation);
  const formations2 = freeFormationsLabel.filter((el) => el !== choiceFormation3 && el !== choiceFormation1 && el !== choiceFreeFormation);
  const formations3 = freeFormationsLabel.filter((el) => el !== choiceFormation1 && el !== choiceFormation2 && el !== choiceFreeFormation);
  const formationsFree = freeFormationsLabel.filter((el) => el !== choiceFormation1 && el !== choiceFormation2 && el !== choiceFormation3);

  const handleChangeQualification = (value) => {
    setChoiceQualification(value);
    switch (value) {
      case 'Refus':
        setDisplayRejection(true);
        setDisplayError(false);
        setDisplayDateRecall(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        break;
      case 'Erreur':
        setDisplayError(true);
        setDisplayRejection(false);
        setDisplayDateRecall(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        break;
      case 'Rappel':
        setDisplayDateRecall(true);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        break;
      case 'Vente 1 formation':
        setDisplayDateRecall(false);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(true);
        setDisplayFormation1(true);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        break;
      case 'Vente 2 formation':
        setDisplayDateRecall(false);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(true);
        setDisplayFormation1(true);
        setDisplayFormation2(true);
        setDisplayFormation3(false);
        break;
      case 'Vente 3 formation':
        setDisplayDateRecall(false);
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayFreeFormation(true);
        setDisplayFormation1(true);
        setDisplayFormation2(true);
        setDisplayFormation3(true);
        break;
      default:
        setDisplayError(false);
        setDisplayRejection(false);
        setDisplayDateRecall(false);
        setDisplayFreeFormation(false);
        setDisplayFormation1(false);
        setDisplayFormation2(false);
        setDisplayFormation3(false);
        break;
    }
  };
  const handleChangeQualificationError = (value) => {
    setChoiceQualificationError(value);
  };
  const handleChangeQualificationRejection = (value) => {
    setChoiceQualificationRejection(value);
  };

  const [isFormFilled, setIsFormFilled] = useState(true);

  useEffect(() => {
    if ((choiceQualification !== null && choiceQualification !== 'Refus')
    && (choiceQualification !== null && choiceQualification !== 'Erreur')
    && (choiceQualification !== null && choiceQualification !== 'Rappel')
    && (choiceQualification !== null && choiceQualification !== 'Vente 1 formation')
    && (choiceQualification !== null && choiceQualification !== 'Vente 2 formation')
    && (choiceQualification !== null && choiceQualification !== 'Vente 3 formation')) {
      setIsFormFilled(false);
    } else {
      setIsFormFilled(true);
    }
    if ((choiceQualification !== null && choiceQualification === 'Refus' && choiceQualificationRejection !== null)
    || (choiceQualification !== null && choiceQualification === 'Erreur' && choiceQualificationError !== null)
    || (choiceQualification !== null && choiceQualification === 'Rappel' && choiceQualificationRecall !== null)
    || (choiceQualification !== null && choiceQualification === 'Vente 1 formation' && choiceFormation1 !== null)
    || (choiceQualification !== null && choiceQualification === 'Vente 2 formation' && choiceFormation2 !== null)
    || (choiceQualification !== null && choiceQualification === 'Vente 3 formation' && choiceFormation3 !== null)) {
      setIsFormFilled(false);
    }
  });
  const formikRef = useRef();
  useEffect(() => {
    setChoiceQualificationError(null);
    setChoiceQualificationRejection(null);
    setChoiceQualificationRecall(null);
    setChoiceFreeFormation(null);
    setChoiceFormation1(null);
    setChoiceFormation2(null);
    setChoiceFormation3(null);
    if (formikRef.current) {
      // réinitialiser la value des selects au changement du select de qualification
      formikRef.current.values.choiceQualificationError = null;
      formikRef.current.values.choiceQualificationRecall = null;
      formikRef.current.values.choiceQualificationRejection = null;
      formikRef.current.values.choiceFormation1 = null;
      formikRef.current.values.choiceFormation2 = null;
      formikRef.current.values.choiceFormation3 = null;
      formikRef.current.values.choiceFreeFormation = null;
    }
  }, [choiceQualification]);

  // partie modal

  const [commentsCommercialUser, setCommentsCommercialUser] = useState([]);
  const mounted = useMounted();
  const userId = user.id;

  const getComments = useCallback(async () => {
    try {
      const data = await customerApi.getCommentsCommercialUser(customerId, userId, localStorage.getItem('accessToken'));

      if (mounted.current) {
        setCommentsCommercialUser(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  const handleCloseDialog = () => {
    setShowHistory(false);
  };

  // partie modal formations
  const [showFormations, setShowFormations] = useState(false);
  const handleCloseDialogFormations = () => {
    setShowFormations(false);
  };

  return (
    <div>
      <Dialog
        open={showFormations}
        onClose={handleCloseDialogFormations}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          Formations
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ mt: 3 }}>
              <CampaignCustomerFormationsListTable
                customer={customer}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialogFormations}
            color="primary"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showHistory}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Historique de vos commentaires
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={{ mt: 3 }}>
              {commentsCommercialUser.map((commentCommercialModal) => (
                <CampaignCustomerCommentDetails
                  id={commentCommercialModal.id}
                  createdAt={commentCommercialModal.createdAt}
                  source={`${commentCommercialModal.firstname} ${commentCommercialModal.lastname}`}
                  comment={commentCommercialModal.comment}
                  qualification={commentCommercialModal.qualification}
                  reason={commentCommercialModal.reason}
                  recallDate={commentCommercialModal.recall_date}
                  formation1={commentCommercialModal.formation1}
                  nameFormation1={commentCommercialModal.nameFormation1}
                  formation2={commentCommercialModal.formation2}
                  nameFormation2={commentCommercialModal.nameFormation2}
                  formation3={commentCommercialModal.formation3}
                  nameFormation3={commentCommercialModal.nameFormation3}
                  offeredFormation={commentCommercialModal.offered_formation}
                  nameOfferedFormation={commentCommercialModal.nameOfferedFormation}
                />
              ))}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        {commenting && (
        <Formik
        // pour pouvoir recupérer values de formik dans le useEffect
          innerRef={formikRef}
          initialValues={{ commentCommercial: null,
            choiceQualification: null,
            choiceQualificationError: null,
            choiceQualificationRejection: null,
            choiceQualificationRecall: null,
            choiceFormation1: null,
            choiceFormation2: null,
            choiceFormation3: null,
            choiceFreeFormation: null }}
          enableReinitialize
          validationSchema={Yup
            .object()
            .shape({
              commentCommercial: Yup.string().nullable(),
              choiceQualification: Yup.string().nullable(),
              choiceQualificationError: Yup.string().nullable(),
              choiceQualificationRejection: Yup.string().nullable(),
              choiceFormation1: Yup.object().shape({
                id: Yup.number().nullable(),
                name: Yup.string().nullable()
              }).nullable(),
              choiceFormation2: Yup.object().shape({
                id: Yup.number().nullable(),
                name: Yup.string().nullable()
              }).nullable(),
              choiceFormation3: Yup.object().shape({
                id: Yup.number().nullable(),
                name: Yup.string().nullable()
              }).nullable(),
              choiceFreeFormation: Yup.object().shape({
                id: Yup.number().nullable(),
                name: Yup.string().nullable()
              }).nullable(),
              choiceQualificationRecall: Yup.date().min(Date(), 'Vous ne pouvez pas sélectionner une date passée').nullable()
            })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
              const jsonAnswer = await customerApi.addCommentCommercial(customerId, user.id, values, localStorage.getItem('accessToken'));
              setStatus({ success: true });
              resetForm({});
              setSubmitting(false);
              toast.success('Commentaire du commercial enregistré !');
              setCommenting(!commenting);
              const tempComments = [...commentsCommercialUser];
              tempComments.unshift({ id: jsonAnswer.data.comment_id,
                comment: jsonAnswer.data.comment,
                createdAt: jsonAnswer.data.created_at,
                firstname: user.firstname,
                lastname: user.lastname,
                qualification: jsonAnswer.data.qualification,
                reason: jsonAnswer.data.reason,
                recall_date: choiceQualificationRecall,
                offered_formation: choiceFreeFormation,
                formation1: choiceFormation1,
                formation2: choiceFormation2,
                formation3: choiceFormation3
              });
              setCommentsCommercialUser(tempComments);
              setCommentCommercial({ id: jsonAnswer.data.comment_id,
                comment: jsonAnswer.data.comment,
                createdAt: jsonAnswer.data.created_at,
                firstname: user.firstname,
                lastname: user.lastname,
                qualification: jsonAnswer.data.qualification,
                reason: jsonAnswer.data.reason,
                recall_date: choiceQualificationRecall,
                offered_formation: choiceFreeFormation,
                formation1: choiceFormation1,
                formation2: choiceFormation2,
                formation3: choiceFormation3
              });
              // pour réinitialiser le formulaire
              setChoiceQualificationError(null);
              setChoiceQualificationRejection(null);
              setChoiceQualificationRecall(null);
              setChoiceQualification(null);
              setChoiceFreeFormation(null);
              setChoiceFormation1(null);
              setChoiceFormation2(null);
              setChoiceFormation3(null);
              setDisplayDateRecall(false);
              setDisplayError(false);
              setDisplayRejection(false);
              setDisplayFreeFormation(false);
              setDisplayFormation1(false);
              setDisplayFormation2(false);
              setDisplayFormation3(false);
              // enregistrement en bdd learner_campaign de l'id du commentaire
              await customerApi.saveCommentCommercialIdReinj(jsonAnswer.comment_id, customerId, campaignId, user.id, localStorage.getItem('accessToken'));
              setIsTreated(true);
            } catch (err) {
              console.error(err);
              toast.error('Il y a eu un souci lors de l\'enregistrement !');
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleSubmit, handleBlur, handleChange, setFieldValue, touched, values }) => (
            <form
              onSubmit={handleSubmit}
            >
              {commenting && (
                <>
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      mb={2}
                      ml={2}
                      mt={2}
                    >
                      <Typography
                        color="textPrimary"
                        variant="h6"
                      >
                        Laisser un commentaire
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      ml={2}
                      mt={2}
                      // style={{ width: '250px' }}
                    >
                      <Autocomplete
                        options={qualificationLabel}
                        onChange={(e, value) => {
                          handleChangeQualification(value);
                          setFieldValue(
                            'choiceQualification',
                            value !== null ? value : choiceQualification
                          );
                        }}
                        value={values.choiceQualification}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Qualification"
                            name="choiceQualification"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    {displayError && (
                    <Grid
                      item
                      xs={4}
                      ml={4}
                      mt={2}
                    >
                      <Autocomplete
                        options={qualificationErrorLabel}
                        onChange={(e, value) => {
                          handleChangeQualificationError(value);
                          setFieldValue(
                            'choiceQualificationError',
                            value !== null ? value : choiceQualificationError
                          );
                        }}
                        value={values.choiceQualificationError}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Raison"
                            name="choiceQualificationError"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    )}
                    {displayRejection && (
                    <Grid
                      item
                      xs={4}
                      ml={4}
                      mt={2}
                    >
                      <Autocomplete
                        options={qualificationRejectionLabel}
                        onChange={(e, value) => {
                          handleChangeQualificationRejection(value);
                          setFieldValue(
                            'choiceQualificationRejection',
                            value !== null ? value : choiceQualificationRejection
                          );
                        }}
                        value={values.choiceQualificationRejection}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Raison"
                            name="choiceQualificationRejection"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    )}
                    {displayFormation1 && (
                    <Grid
                      item
                      xs={4}
                      ml={4}
                      mt={2}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={formations1}
                        onChange={(e, value) => {
                          setChoiceFormation1(value);
                          setFieldValue(
                            'choiceFormation1',
                            value !== null ? value : choiceFormation1
                          );
                        }}
                        value={values.choiceFormation1}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Formation1"
                            name="choiceFormation1"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    )}
                    {displayFormation2 && (
                    <Grid
                      item
                      xs={4}
                      ml={2}
                      mt={2}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={formations2}
                        onChange={(e, value) => {
                          setChoiceFormation2(value);
                          setFieldValue(
                            'choiceFormation2',
                            value !== null ? value : choiceFormation2
                          );
                        }}
                        value={values.choiceFormation2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Formation2"
                            name="choiceFormation2"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    )}
                    {displayFormation3 && (
                    <Grid
                      item
                      xs={4}
                      ml={4}
                      mt={2}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={formations3}
                        onChange={(e, value) => {
                          setChoiceFormation3(value);
                          setFieldValue(
                            'choiceFormation3',
                            value !== null ? value : choiceFormation3
                          );
                        }}
                        value={values.choiceFormation3}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Formation3"
                            name="choiceFormation3"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    )}
                    {displayFreeFormation && (
                    <Grid
                      item
                      xs={4}
                      ml={2}
                      mt={2}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => option.name}
                        options={formationsFree}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, value) => {
                          setChoiceFreeFormation(value);
                          setFieldValue(
                            'choiceFreeFormation',
                            value !== null ? value : choiceFreeFormation
                          );
                        }}
                        value={values.choiceFreeFormation}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Formation offerte"
                            name="choiceFreeFormation"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    )}
                    {displayDateRecall && (
                    <Grid
                      item
                    // xs={2}
                      ml={4}
                      mt={2}
                    >
                      <TextField
                        error={Boolean(touched.choiceQualificationRecall && errors.choiceQualificationRecall)}
                        fullWidth
                        helperText={touched.choiceQualificationRecall && errors.choiceQualificationRecall}
                        label="Date et heure du rappel"
                        name="choiceQualificationRecall"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event);
                          setChoiceQualificationRecall(event.target.value);
                        }}
                        type="datetime-local"
                        value={values.choiceQualificationRecall}
                        variant="outlined"
                    // pour éviter la superposition de jj/mm/aaaa et date de naissance dans le champ
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    )}
                  </Grid>
                  <TextField
                    sx={{ mt: 2, width: 700, ml: 2 }}
                    label="Commentaire"
                    error={Boolean(touched.commentCommercial && errors.commentCommercial)}
                    fullWidth
                    helperText={touched.commentCommercial && errors.commentCommercial}
                    multiline
                    name="commentCommercial"
                    variant="filled"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.commentCommercial}
                    minRows={2}
                  />
                  <Grid
                    container
                    mt={1}
                    mb={1}
                  >
                    <Grid
                      item
                      xs={2}
                      ml={2}
                    >
                      <Button
                        color="primary"
                        startIcon={<SaveAltIcon fontSize="small" />}
                        variant="contained"
                        disabled={isFormFilled}
                        onClick={() => {
                          handleSubmit(); saveTimeReinjection();
                        }}
                      >
                        Enregistrer
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                    >
                      <Button
                        color="primary"
                        startIcon={<CancelIcon fontSize="small" />}
                        variant="contained"
                        onClick={() => { setCommenting(!commenting); }}
                      >
                        Annuler
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </form>
          )}
        </Formik>
        )}

      </div>
      <Card
        sx="margin-top:20px;
        min-height: 195px;"
      >
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="Dernier commentaire" />
          {customer.formations.length > 0 && (
          <Button
            style={{ marginRight: 20, marginTop: 20 }}
            color="primary"
            variant="contained"
            sx={{ mt: 1, mb: 3 }}
            onClick={() => { setShowFormations(true); }}
          >
            Voir les formations
          </Button>
          )}
          <Button
            style={{ marginRight: 20, marginTop: 20 }}
            color="primary"
            startIcon={<HistoryIcon fontSize="small" />}
            sx={{ mt: 1, mb: 3 }}
            variant="contained"
            onClick={() => { setShowHistory(true); }}
          >
            Historique
          </Button>
          {!commenting && (
          <Button
            style={{ marginRight: 20, marginTop: 20 }}
            color="primary"
            startIcon={<PencilAltIcon fontSize="small" />}
            sx={{ mt: 1, mb: 3 }}
            variant="contained"
            onClick={() => { setCommenting(!commenting); }}
          >
            Qualifier l&apos;appel
          </Button>
          )}
        </Box>
        <Divider />
        {(user.access_level === 0 || user.id_service === 3) && (
          <CardContent>
            {commentCommercial && (
            <>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...other}
              >
                <Avatar
                  sx={{
                    color: 'common.white'
                  }}
                >
                  {(qualification === 'Vente 1 formation' || qualification === 'Vente 2 formation' || qualification === 'Vente 3 formation') && (
                  <AttachMoneyIcon style={{ color: '#16B84E' }} />
                  )}
                  {qualification === 'Erreur' && (
                  <ErrorOutlineIcon style={{ color: 'white' }} />
                  )}
                  {qualification === 'Rappel' && (
                  <PhoneIcon style={{ color: 'orange' }} />
                  )}
                  {qualification === 'Souhaite un email' && (
                  <MailOutlineIcon style={{ color: 'purple' }} />
                  )}
                  {qualification === 'Refus' && (
                  <PanToolIcon style={{ color: '#ff5f52' }} />
                  )}
                  {qualification === 'Attente' && (
                  <HourglassEmptyIcon style={{ color: '#ffe436' }} />
                  )}
                  {qualification === 'Appel entrant' && (
                  <PhoneCallbackIcon style={{ color: 'blue' }} />
                  )}
                  {qualification === 'Pas répondu' && (
                  <PhoneMissedIcon style={{ color: 'black' }} />
                  )}
                  {/* <Icon fontSize="small" /> */}
                </Avatar>
                <Typography
                  color="textPrimary"
                  sx={{ ml: 2 }}
                  variant="body2"
                >
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {qualification !== 'Ancien commentaire' ? `${commentCommercial.firstname} ${commentCommercial.lastname}` : ''}
                  </Typography>
                  <Typography
                    style={{ margin: 'auto', padding: 'auto' }}
                    color={(qualification === 'Vente 1 formation' || qualification === 'Vente 2 formation' || qualification === 'Vente 3 formation') ? '#16B84E' : qualification === 'Refus' ? '#ff5f52' : qualification === 'Erreur' ? '#888888' : qualification === 'Rappel' ? 'orange' : qualification === 'Souhaite un email' ? '#9a68af' : qualification === 'Attente' ? '#d7b52c' : qualification === 'Appel entrant' ? '#1E90FF' : qualification === 'Pas répondu' ? '#888888' : 'black'}
                    variant="subtitle2"
                  >
                    {`${qualification} ${reason === null ? '' : reason}${recallDate === undefined || recallDate === null ? '' : moment(recallDate).format('DD/MM/YYYY à H:mm')}`}

                  </Typography>
                  <Typography fontSize={14}>
                    {nameFormation1 !== null && nameFormation1 !== undefined ? '1) ' : ''}
                    {nameFormation1 !== undefined ? nameFormation1Transf : ''}
                  </Typography>
                  <Typography fontSize={14}>
                    {nameFormation2 !== null && nameFormation2 !== undefined ? '2) ' : ''}
                    {nameFormation2 !== undefined ? nameFormation2Transf : ''}
                  </Typography>
                  <Typography fontSize={14}>
                    {nameFormation3 !== null && nameFormation3 !== undefined ? '3) ' : ''}
                    {nameFormation3 !== undefined ? nameFormation3Transf : ''}
                  </Typography>
                  <Typography fontSize={14}>
                    {nameOfferedFormation !== null && nameOfferedFormation !== undefined ? `Offerte : ${nameOfferedFormationTransf}` : ''}
                  </Typography>
                  <Typography
                    fontSize={14}
                    fontStyle="italic"
                  >
                    {comment}
                  </Typography>
                </Typography>

                <Typography
                  color="textPrimary"
                  sx={{ ml: 'auto' }}
                  variant="caption"
                >
                  {new Date(createdAt).toLocaleDateString('fr-FR', options)}
                </Typography>
              </Box>

            </>
            )}
          </CardContent>
        )}
      </Card>
    </div>
  );
};

CampaignCustomerCommercialCommentReinj.propTypes = {
  comment_commercial: PropTypes.object,
  comment_relance: PropTypes.object,
  customer: PropTypes.object,
  formations: PropTypes.array,
  customerId: PropTypes.string,
  campaignId: PropTypes.string,
  setIsTreated: PropTypes.func,
  saveTimeReinjection: PropTypes.func
};

export default CampaignCustomerCommercialCommentReinj;
