/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { Box, Grid, Typography, Card, Skeleton, Divider } from '@material-ui/core';
import { dashboardApi } from '../../../api/dashboardApi';
import { useState, useCallback, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMounted from '../../../hooks/useMounted';
import useAuth from '../../../hooks/useAuth';
import Chart from 'react-apexcharts';
import numeral from 'numeral';
import StatsWidget from './StatsWidget';
import PrimeWidget from './PrimeWidget';

const ChartLine = () => {
  const theme = useTheme();
  const chartOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    colors: ['#7eb627'],
    dataLabels: {
      enabled: false
    },
    grid: {
      show: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    theme: {
      mode: theme.palette.mode
    },
    tooltip: {
      enabled: false
    },
    xaxis: {
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: false
    }
  };

  const chartSeries = [{ data: [29, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30] }];

  return (
    <Chart
      options={chartOptions}
      series={chartSeries}
      type="line"
    />
  );
};

const FinanceOverview = () => {
  const [finishedTraining, setFinishedTraining] = useState([]);
  const [remainsTraining, setRemainsTraining] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [transformationRate, setTransformationRate] = useState(0);
  const mounted = useMounted();
  const { user } = useAuth();

  const getOverview = useCallback(async () => {
    try {
      const donnees = await dashboardApi.getOverview(localStorage.getItem('accessToken'));

      if (donnees.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setData({
          customer: {
            currentYearCA: donnees.customersCurrentYearCA.map((item) => item.currentYearCA),
            currentMonthCA: donnees.customersCurrentMonthCA.map((item) => item.currentMonthCA),
            currentWeekCA: donnees.customersCurrentWeekCA.map((item) => item.currentWeekCA),
            todayCA: donnees.customersTodayCA.map((item) => item.todayCA),
            lastYearCA: donnees.customersLastYearCA.map((item) => item.lastYearCA),
            lastMonthCA: donnees.customersLastMonthCA.map((item) => item.lastMonthCA),
            lastWeekCA: donnees.customersLastWeekCA.map((item) => item.lastWeekCA),
            yesterdayCA: donnees.customersYesterdayCA.map((item) => item.yesterdayCA),
            currentYearSubscribers: donnees.customersCurrentYearSubscribers.map((item) => item.currentYearSubscribers),
            currentMonthSubscribers: donnees.customersCurrentMonthSubscribers.map((item) => item.currentMonthSubscribers),
            currentWeekSubscribers: donnees.customersCurrentWeekSubscribers.map((item) => item.currentWeekSubscribers),
            todaySubscribers: donnees.customersTodaySubscribers.map((item) => item.todaySubscribers),
            lastYearSubscribers: donnees.customersLastYearSubscribers.map((item) => item.lastYearSubscribers),
            lastMonthSubscribers: donnees.customersLastMonthSubscribers.map((item) => item.lastMonthSubscribers),
            lastWeekSubscribers: donnees.customersLastWeekSubscribers.map((item) => item.lastWeekSubscribers),
            yesterdaySubscribers: donnees.customersYesterdaySubscribers.map((item) => item.yesterdaySubscribers),
            currentMonthPalier: donnees.currentMonthPalier,
            lastMonthPalier: donnees.lastMonthPalier,
            nextPalier: donnees.nextPalier,
          },
          sales: {
            actualYearReals: donnees.souscriptionsNumberOfDayReals.map((item) => item.dayReals),
            actualYear: donnees.souscriptionsNumberOfDay.map((item) => item.day1),
            amountOfToday: donnees.amountOfToday.map((item) => item.today),
            lastYearReals: user.access_level === 0 ? donnees.numberoftodyReals[0].todayReals : '',
            lastYear: donnees.numberoftody[0].today,
          },
          profit: {
            actualYearReals: donnees.souscriptionsNumberOfWeekReals.map((item) => item.lastweekReals),
            actualYear: donnees.souscriptionsNumberOfWeek.map((item) => item.lastweek),
            potential: donnees.potential,
            lastYear: 10
          },
          cost: {
            actualYear: donnees.countCustomers.map((item) => item.countcustomerReal),
            lastYear: donnees.countCustomers.map((item) => item.countcustomer),
            // currentYearCA: donnees.currentYearCA.map((item) => item.currentYearCA),
            // customersCA: donnees.customersCA.map((item) => item.currentYearCA),
          },
          gap: {
            today: donnees.numberoftodyRealsInscritD.todayRealsInscritD,
            yesterday: donnees.numberofYesterdayRealsInscritD.yesterdayRealsInscritD,
            total: donnees.totalShifted,
            totalFinishedShifted: donnees.totalFinishedShifted,
            differenceToday: user.access_level === 0 ? ((donnees.numberoftodyRealsInscritD.todayRealsInscritD) - (donnees.numberoftodyReals[0].todayReals)) : '',
            differenceYesterday: user.access_level === 0 ? (donnees.numberofYesterdayRealsInscritD.yesterdayRealsInscritD) - (donnees.souscriptionsNumberOfDayReals[0].dayReals) : '',
          },
        });
        setFinishedTraining(donnees.finishedTraining);
        setRemainsTraining(donnees.remainsTraining);
        setTransformationRate(donnees.transformationRate);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);
  useEffect(() => {
    getOverview();
  }, [getOverview]);

  return (
    <>
      {/* CHIFFRE D'AFFAIRE POUR LE SUPER ADMIN */}
      {user.access_level === 0 && (
        <Card>
          <Grid
            container
          >
            <Grid
              item
              md={2.8}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  md: 'none',
                  xs: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>

                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Chiffre d&apos;affaire d&apos;aujourd&apos;hui
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {numeral(data.sales.lastYearReals).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <>
                    <Typography
                      color="textSecondary"
                      variant="overline"
                    >
                      Hier
                    </Typography>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      {numeral(data.sales.actualYearReals).format('0,000,000.00').replaceAll(',', ' ')}
                      €
                    </Typography>
                  </>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 140
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              md={2.8}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  md: 'none',
                  xs: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>

                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Inscriptions d&apos;aujourd&apos;hui
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {numeral(data.gap.today).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    {`dont décalés ${numeral(data.gap.differenceToday).format('0,000,000.00').replaceAll(',', ' ')} €`}
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h6"
                  >
                    {numeral(data.gap.yesterday).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                    &nbsp;
                    Hier
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    {`dont décalés ${numeral(data.gap.differenceYesterday).format('0,000,000.00').replaceAll(',', ' ')} €`}
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 140
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  md: 'none',
                  xs: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>

                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Chiffre d&apos;affaire total
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
                {!loading ? (
                  <>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      {numeral(data.cost.actualYear).format('0,000,000.00').replaceAll(',', ' ')}
                      €
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                    >
                      {`dont décalés ${numeral(data.gap.total).format('0,000,000.00').replaceAll(',', ' ')} €`}
                    </Typography>

                  </>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h6"
                  >
                    Taux de transformation
                    &nbsp;
                    {numeral(transformationRate).format('0,000,000.00').replaceAll(',', ' ')}
                    %
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 140
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              md={3.4}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  md: 'none',
                  xs: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>

                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Formations terminées
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
                {!loading ? (
                  <>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      {numeral(finishedTraining.finish).format('0,000,000.00').replaceAll(',', ' ')}
                      €
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="caption"
                    >
                      {`dont décalés ${numeral(data.gap.totalFinishedShifted).format('0,000,000.00').replaceAll(',', ' ')} €`}
                    </Typography>

                  </>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >
                    Potentiel
                    &nbsp;
                    {numeral(data.profit.potential).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 170
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Card>
      )}
      {user.id_service !== 0 && (
      <Card>
        <Grid container>
          {user.id_service === 1 && (
          <>
            {/* CHIFFRE D'AFFAIRE POUR L'ADMIN  */}
            <Grid
              item
              md={3}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  md: 'none',
                  xs: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>

                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Inscrits d&apos;aujourd&apos;hui
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {numeral(data.sales.lastYearReals).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h6"
                  >
                    {numeral(data.sales.actualYearReals).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                    &nbsp;
                    Hier
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 177
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  md: 'none',
                  xs: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>

                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Aujourd&apos;hui
                  </Typography>
                ) : (
                  <Skeleton variant="text" />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {numeral(data.gap.today).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    {numeral(data.gap.differenceToday).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                    Différence d&apos;aujourd&apos;hui
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h6"
                  >
                    {numeral(data.gap.yesterday).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                    &nbsp;
                    Hier
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="caption"
                  >
                    {numeral(data.gap.differenceYesterday).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                    &nbsp;
                    Différence d&apos;hier

                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 140
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  xs: `1px solid ${theme.palette.divider}`,
                  md: 'none'
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>
                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Total
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >
                    {numeral(data.cost.actualYear).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}

              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 177
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              <div>
                {!loading ? (
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Derniere Semaine
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
                {!loading ? (
                  <Typography
                    color="textPrimary"
                    variant="h5"
                  >

                    {numeral(data.profit.actualYearReals).format('0,000,000.00').replaceAll(',', ' ')}
                    €
                  </Typography>
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={100}
                  />
                )}
              </div>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: 54,
                  width: 177
                }}
              >
                {!loading ? (
                  <ChartLine />
                ) : (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    width={200}
                    height={100}
                  />
                )}
              </Box>
            </Grid>
          </>
          )}
          {/* CHIFFRE D'AFFAIRE POUR L'ADMIN ET LE NOMBRE DE VENTE POUR LES COMMERCIAUX */}
          {user.access_level !== 0 && (
          <>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`,
                  xs: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              {data.customer && (
              <StatsWidget
                type="stats"
                loading={loading}
                title="Aujourd&apos;hui"
                sales={data.customer.todaySubscribers}
                lastSales={data.customer.yesterdaySubscribers}
                amount={numeral(data.customer.todayCA).format('0,000,000.00').replaceAll(',', ' ')}
                icon={(data.customer.todaySubscribers - data.customer.yesterdaySubscribers) === 0
                  ? 'nullicon'
                  : (
                    (data.customer.todaySubscribers - data.customer.yesterdaySubscribers) > 0
                      ? 'upicon'
                      : 'downicon'
                  )}
                description="par rapport à hier"
              />
              )}
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  xs: `1px solid ${theme.palette.divider}`,
                  md: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              {data.customer && (
              <StatsWidget
                type="stats"
                loading={loading}
                title="Semaine en cours"
                sales={data.customer.currentWeekSubscribers}
                lastSales={data.customer.lastWeekSubscribers}
                amount={numeral(data.customer.currentWeekCA).format('0,000,000.00').replaceAll(',', ' ')}
                icon={(data.customer.currentWeekSubscribers - data.customer.lastWeekSubscribers) > 0 ? 'upicon' : 'downicon'}
                description="par rapport à la semaine dernière"
              />
              )}
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  xs: `1px solid ${theme.palette.divider}`,
                  md: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              {data.customer && (
              <StatsWidget
                type="stats"
                loading={loading}
                title="Mois en cours"
                sales={data.customer.currentMonthSubscribers}
                lastSales={data.customer.lastMonthSubscribers}
                amount={numeral(data.customer.currentMonthCA).format('0,000,000.00').replaceAll(',', ' ')}
                icon={(data.customer.currentMonthSubscribers - data.customer.lastMonthSubscribers) === 0
                  ? 'nullicon'
                  : (
                    (data.customer.currentMonthSubscribers - data.customer.lastMonthSubscribers) > 0
                      ? 'upicon' : 'downicon'
                  )}
                description="par rapport au mois dernier"
              />
              )}
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              sx={{
                alignItems: 'center',
                borderRight: (theme) => ({
                  md: `1px solid ${theme.palette.divider}`
                }),
                borderBottom: (theme) => ({
                  xs: `1px solid ${theme.palette.divider}`,
                  md: `1px solid ${theme.palette.divider}`
                }),
                display: 'flex',
                justifyContent: 'space-between',
                p: 3
              }}
            >
              {data.customer && (
              <StatsWidget
                type="stats"
                loading={loading}
                title="Année en cours"
                sales={data.customer.currentYearSubscribers}
                lastSales={data.customer.lastYearSubscribers}
                amount={numeral(data.customer.currentYearCA).format('0,000,000.00').replaceAll(',', ' ')}
                icon={(data.customer.currentYearSubscribers - data.customer.lastYearSubscribers) === 0
                  ? 'nullicon'
                  : (
                    (data.customer.currentYearSubscribers - data.customer.lastYearSubscribers) > 0
                      ? 'upicon'
                      : 'downicon'
                  )}
                description="par rapport à l'année dernière"
              />
              )}
            </Grid>
            {data.customer && (data.customer.currentMonthPalier || data.customer.lastMonthPalier) && (
              <>
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{
                    alignItems: 'center',
                    borderRight: (theme) => ({
                      md: `1px solid ${theme.palette.divider}`
                    }),
                    borderBottom: (theme) => ({
                      xs: `1px solid ${theme.palette.divider}`,
                      md: `1px solid ${theme.palette.divider}`
                    }),
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 3
                  }}
                >
                  <PrimeWidget
                    type="primes"
                    loading={loading}
                    title="Primes de ce mois"
                    salesAmount={data.customer.currentMonthCA}
                    lastSalesAmount={data.customer.lastMonthCA}
                    palierPercentage={data.customer.currentMonthPalier.percent}
                    lastMonthPalierPercentage={data.customer.lastMonthPalier.percent}
                    palier={data.customer.currentMonthPalier}
                  />
                </Grid>
                {/* <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{
                    alignItems: 'center',
                    borderRight: (theme) => ({
                      md: `1px solid ${theme.palette.divider}`
                    }),
                    borderBottom: (theme) => ({
                      xs: `1px solid ${theme.palette.divider}`,
                      md: `1px solid ${theme.palette.divider}`
                    }),
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 3
                  }}
                >
                  <PrimeWidget
                    type="next"
                    loading={loading}
                    title="Prochain palier à atteindre"
                    palier={data.customer.nextPalier ?? data.customer.nextPalier}
                  />
                </Grid> */}
              </>
            )}
          </>
          )}
        </Grid>
      </Card>
      )}

    </>

  );
};

export default FinanceOverview;
