/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
import { Breadcrumbs, Container, Link, useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import ChevronRightIcon from '../../../icons/ChevronRight';
import CustomReportForm from './components/CustomReportForm';
import { Link as RouterLink } from 'react-router-dom';
import gtm from '../../../lib/gtm';

const CustomReportCreate = () => {
  const theme = useTheme();
  const { settings } = useSettings();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <>
      <Helmet>
        <title>Créer un rapport personnalisé | Apteed</title>
      </Helmet>
      <Box
        sx={{
          // backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Créer un nouveau rapport
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/custom-reports"
                  variant="subtitle2"
                >
                  Rapports personnalisés
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Créer un rapport
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box mt={3}>
            <CustomReportForm />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomReportCreate;
