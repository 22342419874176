/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Typography, Link, Tooltip, Dialog, Card, DialogTitle, Grid,
  DialogContentText, DialogContent, Button, DialogActions, TextField, Autocomplete, IconButton, } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ProgressBar } from '../session';
import Label from '../../Label';
import * as moment from 'moment';
import { sessionApi } from '../../../api/sessionApi';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth';
import DeleteIcon from '@material-ui/icons/Delete';
import { customerApi } from '../../../api/customerApi';
import useMounted from '../../../hooks/useMounted';

function renderProgress(params) {
  return <ProgressBar value={Number(parseInt(params.value, 10))} />;
}
const CustomerFormationsListTable = () => {
  const [showDec, setShowDec] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [formations, setFormations] = useState([]);
  const [formationsDec, setFormationsDec] = useState([]);
  const [sources, setSources] = useState([]);
  const { user } = useAuth();
  const [disableChoiceSession, setDisableChoiceSession] = useState(true);
  const [indexFormationDec, setIndexFormationDec] = useState(0);
  const [alertMessage] = useState('');
  const [currentIdDec, setCurrentIdDec] = useState(0);
  let sessionDec = 0;
  const [valueIdSession, setValueIdSession] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteLearnerSessionId, setDeleteLearnerSessionId] = useState(-1);
  const [rows, setRows] = useState([]);
  const mounted = useMounted();
  const { customerId } = useParams();

  const getCustomer = useCallback(async () => {
    try {
      const data = await customerApi.getCustomer(customerId, localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setFormations(data.formations);
        setFormationsDec(data.formationsDec);
        setSources(data.sources);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  const handleChange = async (params) => {
    if (params.field === 'primary_connection' || params.field === 'last_connection') {
      params.value = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
    }
    if (params.field === 'real_price') {
      params.value = parseFloat(params.value).toFixed(3);
    }
    if (params.value === 'Décalage') {
      // On met la modale du choix de la session décalée à true
      setShowDec(true);
      setCurrentIdDec(params.id);
    } else {
      try {
        await sessionApi.editCustomer(2, params.id, params.value, localStorage.getItem('accessToken'), params.field);
      } catch (err) {
        console.error(err);
        toast.error('Il y a eu un souci lors de la modification !');
      }
    }
  };
  const handleCloseDialog = () => {
    setShowAlert(false);
    setShowDec(false);
  };

  const handleDeleteFormation = async (id) => {
    try {
      await customerApi.deleteCustomerFormation(id, localStorage.getItem('accessToken'));
      const tempFormations = [...formations];
      tempFormations.splice(tempFormations.findIndex((formation) => formation.id === id), 1);
      setFormations(tempFormations);
      toast.success('Apprenant supprimé de la formation !');
      setOpenDelete(false);
    } catch (err) {
      console.error(err);
      toast.error('Erreur lors de la suppression de l\'apprenant de la formation.');
    }
  };
  useEffect(() => {
    setRows(formations.map((item) => ({ id: item.id,
      name: item.name,
      progression: item.progression,
      status: item.status,
      startDate: item.start_date,
      endDate: item.end_date,
      programNumber: item.programNumber,
      real_price: item.real_price,
      sourceFirstname: item.sourceFirstname,
      source_id: item.source_id,
      sourceLastname: item.sourceLastname,
      numberSession: item.number_session,
      lastConnection: item.last_connection,
      duration: item.duration,
      sessionId: item.session_id,
      decalages: item.decalages,
      totalProgress: item.totalProgress
    })));
  }, [formations]);

  const handleDecalage = async () => {
    try {
      await sessionApi.decalage(currentIdDec, sessionDec, localStorage.getItem('accessToken'));
      toast.success('Apprenant décalé !');
      handleCloseDialog();
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la modification !');
    }
  };

  const dialogDelete = (id) => {
    setOpenDelete(true);
    setDeleteLearnerSessionId(id);
  };
  // Erreur avec le react/destructuring-assignment donc désactivation d'eslint sur les lignes concernées
  const columns = [
    { field: 'name',
      headerName: 'Nom',
      width: 390,
      renderCell: (cellValues) => (
        <Link
          sx={{ whiteSpace: 'normal', width: 310, fontWeight: 550 }}
          component={RouterLink}
          to={`/dashboard/session/${cellValues.row.sessionId}`}
        >
          {cellValues.row.name}
        </Link>
      ) },
    { field: 'programNumber', headerName: 'N° Programme', width: 110 },
    { field: 'numberSession', headerName: 'N° Session', width: 100, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.numberSession}</Typography>) },
    // { field: 'progression', headerName: 'Progression', width: 130, renderCell: renderProgress },
    { field: 'totalProgress', headerName: 'Progression', width: 130, renderCell: renderProgress },
    { field: 'status',
      headerName: 'Statut',
      width: 120,
      type: 'singleSelect',
      renderCell: (cellValues) => {
        let statut = '';
        if (cellValues.row.status === 'Inscrit') {
          statut = (
            <Label
              color="success"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'En attente' || cellValues.row.status === 'À transférer') {
          statut = (
            <Label
              color="warning"
            >
              {cellValues.row.status}
            </Label>
          );
        } else if (cellValues.row.status === 'InscritD') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={500}
              color="inscritD"
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  color="inscritD"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === 'Décalage') {
          statut = (
            <Tooltip
              followCursor
              enterDelay={700}
              placement="top"
              title={cellValues.row.decalages !== undefined ? cellValues.row.decalages.map((dec) => (
                <span style={{ whiteSpace: 'pre-line' }}>
                  {`${dec.name} session: ${dec.number_session}`}
                  {'\n'}
                </span>
              )) : null}
            >
              <span>
                <Label
                  InputLabelProps={{ style: { pointerEvents: 'auto' } }}
                  color="secondary"
                >
                  {cellValues.row.status}
                </Label>
              </span>
            </Tooltip>
          );
        } else if (cellValues.row.status === '- de 5 personnes' || cellValues.row.status === 'Annulation' || cellValues.row.status === 'Désinscrit') {
          statut = (
            <Label
              color="error"
            >
              {cellValues.row.status}
            </Label>
          );
        }
        return (statut);
      },
      editable: true,
      valueOptions: ['Inscrit', 'En attente', 'Désinscrit', 'Décalage', '- de 5 personnes', 'Annulation', 'À transférer'],
    },
    { field: 'startDate', headerName: 'Date de début', width: 150, renderCell: (cellValues) => (<Typography>{cellValues.row.startDate !== null ? moment(cellValues.row.startDate).format('DD/MM/YYYY') : ''}</Typography>) },
    { field: 'endDate', headerName: 'Date de fin', width: 120, renderCell: (cellValues) => (<Typography>{cellValues.row.endDate !== null ? moment(cellValues.row.endDate).format('DD/MM/YYYY') : ''}</Typography>) },
    { field: 'lastConnection', headerName: 'Dernière connexion', width: 170, renderCell: (cellValues) => (<Typography>{cellValues.row.lastConnection !== null ? moment(cellValues.row.lastConnection).format('DD/MM/YYYY HH:mm:ss') : ''}</Typography>) },
    { field: 'duration', headerName: 'Durée totale', editable: user.access_level === 0, width: 130, renderCell: (cellValues) => (<Typography sx={{ whiteSpace: 'normal', width: 340 }}>{cellValues.row.duration}</Typography>) },
    { field: 'real_price',
      headerName: 'Prix',
      editable: user.access_level === 0,
      width: 90,
      renderCell: (cellValues) => (
        <Typography sx={{ whiteSpace: 'normal', width: 340 }}>
          {cellValues.row.real_price}
          {' '}
          €
        </Typography>
      ) },
    {
      field: 'source_id',
      headerName: 'Source',
      type: 'singleSelect',
      valueOptions: sources.map((source) => source.id),
      valueFormatter: (params) => (sources.find((source) => source.id === params.value) ? `${sources.find((source) => source.id === params.value).firstname} ${sources.find((source) => source.id === params.value).lastname}` : ''),
      editable: user.access_level === 0,
      width: 120,
    },
    { field: 'indexId',
      headerName: 'Actions',
      cellClassName: 'super-app-theme--cell',
      width: 100,
      renderCell: (cellValues) => (
        <Typography>
          <IconButton
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
            disabled={user.access_level !== 0}
            onClick={() => dialogDelete(cellValues.row.id)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Typography>
      ) }
  ];
  return (
    <Formik
      initialValues={{ addCustomer: null }}
      enableReinitialize
      validationSchema={Yup
        .object()
        .shape({
          addCustomer: Yup.number().nullable()
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (values.addCustomer !== null) {
            const jsonAnswer = await sessionApi.addCustomer(values, localStorage.getItem('accessToken'));
            setStatus({ success: true });
            setSubmitting(false);
            toast.success('Apprenant inscrit !');
            const tempCustomers = [...customers];
            tempCustomers.unshift(jsonAnswer.data);
            setCustomers(tempCustomers);
            values.addCustomer = null;
          }
        } catch (err) {
          console.error(err);
          toast.error('Il y a eu un souci lors de l\'inscription !');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Card>
            <Dialog
              open={showDec}
              onClose={handleCloseDialog}
            >
              <DialogTitle>
                Décalage
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs
                      width={600}
                    >
                      <Autocomplete
                        getOptionLabel={(option) => `${option.name} ${option.program_number}`}
                        options={formationsDec}
                        onChange={(e, value) => {
                          if (value !== null) {
                            setDisableChoiceSession(false);
                            setIndexFormationDec(formationsDec.indexOf(value));
                          } else {
                            setDisableChoiceSession(true);
                            setIndexFormationDec(0);
                          }
                          sessionDec = 0;
                          setValueIdSession(!valueIdSession);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Choisir une formation"
                            name="choiceFormation"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs
                      width={600}
                    >
                      <Autocomplete
                        // pour réinitialiser le autocomplete on lui change la valeur de sa key, quand on change de formation, cela réinitialise le champ
                        key={valueIdSession}
                        getOptionLabel={(option) => `${option.number_session}. Début: ${moment(option.start_date).format('DD/MM/YYYY')}, Fin: ${moment(option.end_date).format('DD/MM/YYYY')}`}
                        options={formationsDec.length === 0 ? [] : formationsDec[indexFormationDec].sessions}
                        onChange={(e, value) => {
                          sessionDec = value.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Choisir une session"
                            name="choiceFormation"
                            variant="outlined"
                            disabled={disableChoiceSession}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  color="primary"
                >
                  Annuler
                </Button>
                <Button
                  onClick={handleDecalage}
                  color="primary"
                >
                  Enregistrer
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={showAlert}
              onClose={handleCloseDialog}
            >
              <DialogTitle>
                Différence de dates
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {alertMessage}
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDialog}
                  color="primary"
                >
                  Annuler
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDelete}
              onClose={() => setOpenDelete(false)}
            >
              <DialogContent>
                <DialogContentText>
                  Souhaitez-vous vraiment supprimer l&apos;apprenant de la formation ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleDeleteFormation(deleteLearnerSessionId)}
                  color="primary"
                >
                  Supprimer
                </Button>
                <Button
                  onClick={() => setOpenDelete(false)}
                  color="primary"
                >
                  Annuler
                </Button>
              </DialogActions>
            </Dialog>
            <DataGrid
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'startDate', sort: 'asc' }],
                },
              }}
              autoHeight
              rowHeight={80}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 100]}
              onCellEditCommit={handleChange}
              components={{ Toolbar: GridToolbar }}
            />
          </Card>
        </form>
      )}
    </Formik>
  );
};
CustomerFormationsListTable.propTypes = {
  formations: PropTypes.array.isRequired,
  setFormations: PropTypes.func.isRequired,
  formationsDec: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired
};

export default CustomerFormationsListTable;
