import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography, Card } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import {
  FinanceStatistiqueFormationsSuperAdmin,
} from '../../components/dashboard/finance';
import useSettings from '../../hooks/useSettings';
import { dashboardApi } from '../../api/dashboardApi';
import useMounted from '../../hooks/useMounted';
import * as React from 'react';
import gtm from '../../lib/gtm';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';

const Analysis = () => {
  const { settings } = useSettings();
  const { user } = useAuth();
  const mounted = useMounted();
  const [formationsInfos, setFormationsInfos] = useState([]);
  const [sourcesInfos, setSourcesInfos] = useState([]);
  const [relanceInfos, setRelanceInfos] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [sessionsEndDate, setSessionsEndDate] = useState([]);

  const [tabs, setTabs] = React.useState('analysis');

  const handleChange = (event, tab) => {
    setTabs(tab);
  };
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);
  const getProfessions = useCallback(async () => {
    try {
      const data = await dashboardApi.getInfos(localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setSessionsEndDate(data.sessionsEndDate);
        setFormationsInfos(data.formations);
        setSourcesInfos(data.sources);
        setRelanceInfos(data.relance);
        setProfessions(data.professions);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getProfessions();
  }, [getProfessions]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Analyse | APTEED</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Analyse
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {user.id_service === 0 && (
          <Box sx={{ mt: 3 }}>
            <Grid
              container
              // spacing={3}
            >
              {user.id_service === 0 && (
              <>
                <Box sx={{ width: '100%' }}>
                  <TabContext value={tabs}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          label="Analyses"
                          value="analysis"
                        />
                        <Tab
                          label="Power Bi"
                          value="power-bi"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="analysis">
                      <Grid
                        item
                        // xs={12}
                      >
                        <Card sx={{ marginTop: '5px' }}>
                          <FinanceStatistiqueFormationsSuperAdmin
                            sessionsEndDate={sessionsEndDate}
                            formationsInfos={formationsInfos}
                            sourcesInfos={sourcesInfos}
                            relanceInfos={relanceInfos}
                            professions={professions}
                          />
                        </Card>
                      </Grid>
                    </TabPanel>

                    <TabPanel
                      value="power-bi"
                      sx={{
                        height: '300%',
                        textAlign: 'center',
                        px: 0
                      }}
                    >
                      <iframe
                        title="SYNA_CRM_DASHBOARD"
                        style={{ height: '100%', width: '100%', margin: '0' }}
                        src="https://app.powerbi.com/reportEmbed?reportId=d2145beb-eb39-4ccc-a17f-bd7d0f4420e2&autoAuth=true&ctid=fa3275f0-e320-4358-b729-c0a9880b875c"
                        frameBorder="0"
                        allowFullScreen="true"
                      />
                    </TabPanel>
                  </TabContext>
                </Box>

              </>
              )}
            </Grid>
          </Box>
          )}
          {user.id_service !== 0 && ('Vous n\'avez pas les droits pour voir l\'analyse')}
        </Container>
      </Box>
    </>
  );
};

export default Analysis;
