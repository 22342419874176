const professions = [
  { id: 1,
    profession: 'Aide-soignant',
  },
  { id: 2,
    profession: 'Assistant dentaire'
  },
  { id: 3,
    profession: 'Audioprothésiste'
  },
  { id: 4,
    profession: 'Auxiliaire de puériculture'
  },
  { id: 5,
    profession: 'Biologiste'
  },
  { id: 6,
    profession: 'Chirurgien-dentiste',
    specialites: [
      'Chirurgie dentaire',
      'Chirurgie dentaire (specialiste Orthopédie Dento_Faciale)',
      'Chirurgie dentiste spécialisé en chirurgie orale',
      'Chirurgie dentiste spécialisé en médecine bucco dentaire'
    ]
  },
  { id: 7,
    profession: 'Diététicien'
  },
  { id: 8,
    profession: 'Epithésiste'
  },
  { id: 9,
    profession: 'Ergothérapeute'
  },
  { id: 10,
    profession: 'Infirmier',
    specialites: [
      'Infirmier Anesthésiste Diplômé d\'Etat (IADE)',
      'Infirmier Diplômé d\'Etat (IDE)',
      'Infirmier Puéricultrice Diplômée d\'Etat',
      'Infirmier de Bloc Opératoire Diplômé d\'Etat (IBODE)',
      'Infirmier en pratique avancée (IPA)'
    ]
  },
  { id: 11,
    profession: 'Manipulateur d\'électroradiologie médicale'
  },
  { id: 12,
    profession: 'Masseur-kinésithérapeute'
  },
  { id: 13,
    profession: 'Médecin',
    specialites: [
      'Anatomie-cytologie-pathologique',
      'Anesthésiologie - Réanimation chirurgicale',
      'Cardiologie et maladies vasculaires / Pathologies cardio-vasculaire',
      'Chirurgie de la face et du cou',
      'Chirurgie générale',
      'Chirurgie infantile',
      'Chirurgie maxillo-faciale',
      'Chirurgie maxillo-faciale et stomatologie',
      'Chirurgie orale',
      'Chirurgie orthopédique et traumatologique',
      'Chirurgie plastique, reconstructrice  et esthétique',
      'Chirurgie pédiatrique',
      'Chirurgie thoracique et cardio-vasculaire',
      'Chirurgie urologique',
      'Chirurgie vasculaire',
      'Chirurgie viscérale et digestive',
      'Dermatologie et venerologie',
      'Endocrinologie et métabolismes',
      'Endocrinologie, diabétologie et maladies métaboliques',
      'Endocrinologie, diabétologie et nutrition',
      'Gastro-entérologie et hépatologie',
      'Gynécologie médicale',
      'Gynécologie médicale et obstétrique',
      'Gynécologie obstétrique / Obstétrique',
      'Génétique médicale',
      'Gériatrie / Gérontologie',
      'Hématologie',
      'Hépato-gastro-entérologie',
      'Maladie infectieuse et tropicale',
      'Médecin spécialisé en allergologie',
      'Médecine cardiovasculaire',
      'Médecine d\'urgence',
      'Médecine du travail',
      'Médecine générale',
      'Médecine intensive et réanimation',
      'Médecine interne',
      'Médecine légale et expertises médicale',
      'Médecine nucléaire',
      'Médecine physique et de réadaptation',
      'Médecine vasculaire',
      'Neurochirurgie',
      'Neurologie',
      'Neuropsychiatrie',
      'Néphrologie',
      'Oncologie médicale',
      'Oncologie radiothérapique',
      'Ophtalmologie',
      'Oto-rhino-laryngologie et chirurgie cervico-faciale',
      'Pneumologie',
      'Psychiatrie de l\'enfant et de l\'adolescent',
      'Psychiatrie générale',
      'Pédiatrie',
      'Radiodiagnostic et imagerie médicale',
      'Radiologie et imagerie médicale',
      'Radiothérapie',
      'Rhumatologie',
      'Réanimation médicale',
      'Sante publique et médecine sociale',
      'Santé publique',
      'Stomatologie',
      'Urologie'
    ]
  },
  { id: 14,
    profession: 'Oculariste'
  },
  { id: 15,
    profession: 'Opticien-lunetier'
  },
  { id: 16,
    profession: 'Ortophoniste'
  },
  { id: 17,
    profession: 'Orthoprothésiste'
  },
  { id: 18,
    profession: 'Orthoptiste'
  },
  { id: 19,
    profession: 'Orthopédiste-Orthésiste'
  },
  { id: 20,
    profession: 'Orthésiste'
  },
  { id: 21,
    profession: 'Pharmacien',
    specialites: [
      'Pharmacien adjoint d\'officine',
      'Pharmacien distributeurs et dispensateurs de gaz',
      'Pharmacien hospitalier',
      'Pharmacien titulaire d\'officine',
      'Pharmacien industriel/répartiteur'
    ]
  },
  { id: 22,
    profession: 'Physicien Médical'
  },
  { id: 23,
    profession: 'Podo-orthésiste'
  },
  { id: 24,
    profession: 'Prothésiste'
  },
  { id: 25,
    profession: 'Préparateur en pharmacie',
    specialites: [
      'Préparateur en pharmacie',
      'Préparateur en pharmacie hospitalière'
    ]
  },
  { id: 26,
    profession: 'Psychomotricien'
  },
  { id: 27,
    profession: 'Pédicure-podologue'
  },
  { id: 28,
    profession: 'Sage-Femme'
  },
  { id: 29,
    profession: 'Technicien de laboratoire médical'
  }
];
export default professions;
