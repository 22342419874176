import { Card, CardContent, CardHeader, Button, DialogActions, Dialog, Skeleton, DialogTitle } from '@material-ui/core';
import StackedLineChartIcon from '@material-ui/icons/StackedLineChart';
import { useState, useEffect, useCallback } from 'react';
import { dashboardApi } from '../../../api/dashboardApi';
import { useTheme } from '@material-ui/core/styles';
import useMounted from '../../../hooks/useMounted';
import useAuth from '../../../hooks/useAuth';
import Chart from 'react-apexcharts';
import moment from 'moment';
import 'moment/locale/fr';

const FinanceSalesRevenue = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const mounted = useMounted();
  moment.locale('fr');
  const [loading, setLoading] = useState(true);
  const [souscriptionsNumberMonth, setSouscriptionsNumberMonth] = useState({});
  const [customersweek, setCustomersweek] = useState([]);
  const [openSouscriptions, setOpenSouscriptions] = useState(false);
  const [souscriptionsNumberDay, setSouscriptionsNumberDay] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);

  const getSalesRevenue = useCallback(async () => {
    try {
      const data = await dashboardApi.getSalesRevenue(localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setSouscriptionsNumberMonth(data.souscriptionsNumberMonth);
        setSouscriptionsNumberDay(data.souscriptionsNumberDay[0]);
        setCustomersweek(data.souscriptionsNumberWeek[0]);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getSalesRevenue();
  }, [getSalesRevenue]);

  useEffect(() => {
    const list = ['Inscrit', 'InscritD', 'Désinscrit', 'Annulation', 'Chiffre d\'affaire', 'Terminés'];
    const newChartSeries = list.map((ls) => ({
      name: ls,
      data: souscriptionsNumberMonth[ls]
    }));

    setChartSeries(newChartSeries);
  }, [souscriptionsNumberMonth]);

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: true
      }
    },
    colors: ['#5F6889', '#00760E', '#DC3D1E', '#F89B1D', '#2D98DA', '#7EB627'],
    dataLabels: {
      enabled: false
    },
    fill: {
      type: 'solid',
      opacity: 0
    },
    grid: {
      borderColor: theme.palette.divider
    },
    markers: {
      strokeColors: theme.palette.background.paper,
      size: 6
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true
      },
      categories: [
        moment(moment.now()).add(-11, 'M').format('MMM'),
        moment(moment.now()).add(-10, 'M').format('MMM'),
        moment(moment.now()).add(-9, 'M').format('MMM'),
        moment(moment.now()).add(-8, 'M').format('MMM'),
        moment(moment.now()).add(-7, 'M').format('MMM'),
        moment(moment.now()).add(-6, 'M').format('MMM'),
        moment(moment.now()).add(-5, 'M').format('MMM'),
        moment(moment.now()).add(-4, 'M').format('MMM'),
        moment(moment.now()).add(-3, 'M').format('MMM'),
        moment(moment.now()).add(-2, 'M').format('MMM'),
        moment(moment.now()).add(-1, 'M').format('MMM'),
        moment(moment.now()).format('MMM')
      ]
    }
  };
  let chartSeriesDays = [];
  let chartOptionsDays = [];
  let chartOptionsWeek = [];
  let chartSeriesWeek = [];
  if (user.access_level === 0) {
    chartSeriesDays = [
      { name: 'Nouveaux Apprenants',
        data: [souscriptionsNumberDay.numberday1, souscriptionsNumberDay.numberday2, souscriptionsNumberDay.numberday3, souscriptionsNumberDay.numberday4, souscriptionsNumberDay.numberday5,
          souscriptionsNumberDay.numberday6] },
    ];
    const options = { weekday: 'long' };
    chartOptionsDays = {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        }
      },
      colors: ['#7EB627', '#1B2445', 'red', '#ffb547'],
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'solid',
        opacity: 0
      },
      grid: {
        borderColor: theme.palette.divider
      },
      markers: {
        strokeColors: theme.palette.background.paper,
        size: 6
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      theme: {
        mode: theme.palette.mode
      },
      xaxis: {
        axisBorder: {
          color: theme.palette.divider,
          show: true
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true
        },
        categories: [
          new Date(Date.now() - 864e5 * 6).toLocaleDateString('fr-FR', options),
          new Date(Date.now() - 864e5 * 5).toLocaleDateString('fr-FR', options),
          new Date(Date.now() - 864e5 * 4).toLocaleDateString('fr-FR', options),
          new Date(Date.now() - 864e5 * 3).toLocaleDateString('fr-FR', options),
          new Date(Date.now() - 864e5 * 2).toLocaleDateString('fr-FR', options),
          new Date(Date.now() - 864e5).toLocaleDateString('fr-FR', options),
          new Date().toLocaleDateString('fr-FR', options),
        ]
      }
    };

    chartSeriesWeek = [
      { name: 'Nouveaux Apprenants', data: [customersweek.numberweek1, customersweek.numberweek2, customersweek.numberweek3, customersweek.numberweek4] },
    ];
    chartOptionsWeek = {
      chart: {
        background: 'transparent',
        stacked: false,
        toolbar: {
          show: false
        }
      },
      colors: ['#7EB627', '#1B2445', 'red', '#ffb547'],
      dataLabels: {
        enabled: false
      },
      fill: {
        type: 'solid',
        opacity: 0
      },
      grid: {
        borderColor: theme.palette.divider
      },
      markers: {
        strokeColors: theme.palette.background.paper,
        size: 6
      },
      stroke: {
        curve: 'straight',
        width: 2
      },
      theme: {
        mode: theme.palette.mode
      },
      xaxis: {
        axisBorder: {
          color: theme.palette.divider,
          show: true
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true
        },
        categories: [
          moment([]).day(0).format('4'),
          moment([]).day(1).format('3'),
          moment([]).day(2).format('2'),
          moment([]).day(3).format('1'),
        ]
      }
    };
  }
  const handleCloseSouscriptions = () => {
    setOpenSouscriptions(false);
  };
  return (
    <Card>
      {!loading ? (
        <CardHeader
          // eslint-disable-next-line no-nested-ternary
          title={user.access_level === 0 ? 'Chiffres d\'affaire par statut et par mois' : user.id_service === 3 ? 'Nombre d\'inscriptions par mois' : 'Progression moyenne par session'}
          action={user.access_level === 0 && (
          <>
            <Button
              onClick={() => setOpenSouscriptions(true)}
              startIcon={<StackedLineChartIcon fontSize="small" />}
              sx={{ m: 1, width: 70 }}
            />
          </>
          )}
        />
      ) : (
        <Skeleton
          variant="text"
          sx={{ ml: 4 }}
          animation="wave"
          width={150}
          height={40}
        />
      )}
      <Dialog
        fullScreen
        open={openSouscriptions}
        onClose={handleCloseSouscriptions}
      >
        <DialogTitle>Par Jour</DialogTitle>
        <DialogActions>
          <Button
            onClick={handleCloseSouscriptions}
            color="primary"
          >
            Annuler
          </Button>
        </DialogActions>
        <CardContent>
          <Chart
            height="400"
            options={chartOptionsDays}
            series={chartSeriesDays}
            type="area"
          />
        </CardContent>
        <DialogTitle>Par Semaine</DialogTitle>
        <CardContent>
          <Chart
            height="400"
            options={chartOptionsWeek}
            series={chartSeriesWeek}
            type="area"
          />
        </CardContent>
      </Dialog>
      {user.id_service !== 4 && (
      <CardContent>
        {!loading ? (
          <Chart
            height="400"
            options={chartOptions}
            series={chartSeries}
            type="area"
          />
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            // width={100}
            height={400}
          />
        )}
      </CardContent>
      )}
    </Card>
  );
};

export default FinanceSalesRevenue;
