/* eslint-disable no-unused-vars */
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import BlogLayout from './components/blog/BlogLayout';
import CampaignLearner from './components/campagne/CampaignLearner';
import CampaignInfo from './components/campagne/CampaignInfo';
// import BrowseLayout from './components/BrowseLayout';
import DashboardLayout from './components/dashboard/DashboardLayout';
import { SessionStats } from './components/dashboard/session';
// import UserGeneralSettings from './components/dashboard/users/UserGeneralSettings';
import DocsLayout from './components/docs/DocsLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import Form from './forme_contact';
import AccountAdmin from './pages/dashboard/AccountAdmin';
import Analysis from './pages/dashboard/Analysis';
import Campagnes from './pages/dashboard/Campagnes';
import Reminders from './pages/dashboard/Reminders';
// import CampaignCustomerDetails from './pages/dashboard/CampaignCustomerDetails';
// import CampaignList from './pages/dashboard/CampaignList';
import UsersList from './pages/dashboard/UsersList';
import RelaunchInfo from './components/dashboard/relaunch/RelaunchInfo';
import RelaunchCustomerDetails from './pages/dashboard/RelaunchCustomerDetails';
import Shift from './pages/dashboard/Shift';
import CustomerNewProspects from './components/dashboard/customer/CustomerNewProspects';
import SessionFormationList from './components/dashboard/session/SessionFormationList';
import CampaignReinjectionCustomerDetails from './pages/dashboard/CampaignReinjectionCustomerDetails';
import CustomReports from './pages/dashboard/custom-repports/CustomReports';
import CustomReportCreate from './pages/dashboard/custom-repports/CustomReportCreate';
import CustomReportDetails from './pages/dashboard/custom-repports/CustomReportDetails';
import Logs from './pages/dashboard/Logs';
import CustomReportFullDetails from './pages/dashboard/custom-repports/components/CustomReportFullDetails';
import Partners from './pages/dashboard/Partners';
import useAuth from './hooks/useAuth';
import QualityPage from './pages/dashboard/QualityPage';
import { DrawerProvider } from './contexts/DrawerContext';
import Imports from './pages/dashboard/Imports';
import FormationsSatisfaction from './pages/dashboard/formationSatisfaction/FormationsSatisfaction';
import FormationsPerformance from './pages/dashboard/formationPerformance/FormationPerformance';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Browse pages

/* const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography'))); */

// Authentication pages
const Formulaire = Loadable(lazy(() => Form));
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Presence = Loadable(lazy(() => import('./pages/dashboard/Presence')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerCreate = Loadable(lazy(() => import('./pages/dashboard/CustomerCreate')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));
const CampaignCustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CampaignCustomerDetails')));

// Docs pages

const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Formations pages

const Formations = Loadable(lazy(() => import('./pages/dashboard/Formations')));
const FormationDetails = Loadable(lazy(() => import('./pages/dashboard/FormationDetails')));
const FormationCreate = Loadable(lazy(() => import('./pages/dashboard/FormationCreate')));

// Projects pages

const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// Etablissements
const EtablissementCreate = Loadable(lazy(() => import('./pages/dashboard/EtablissementCreate')));
const EtablissementDetails = Loadable(lazy(() => import('./pages/dashboard/EtablissementDetails')));
const EtablissementList = Loadable(lazy(() => import('./pages/dashboard/EtablissementListe')));
const EtablissementEdit = Loadable(lazy(() => import('./pages/dashboard/EtablissementEdit')));

// Groupement
const GroupementCreate = Loadable(lazy(() => import('./pages/dashboard/GroupementCreate')));
const GroupementDetails = Loadable(lazy(() => import('./pages/dashboard/GroupementDetails')));
const GroupementList = Loadable(lazy(() => import('./pages/dashboard/GroupementListe')));
const GroupementEdit = Loadable(lazy(() => import('./pages/dashboard/GroupementEdit')));

// Etablissements
const SessionCreate = Loadable(lazy(() => import('./pages/dashboard/SessionCreate')));
const SessionDetails = Loadable(lazy(() => import('./pages/dashboard/SessionDetails')));
const SessionEdit = Loadable(lazy(() => import('./pages/dashboard/SessionEdit')));
const SessionCreatCustomer = Loadable(lazy(() => import('./pages/dashboard/SessionCreatCustomer')));

// Social pages

const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Campaign pages

const CampaignDetails = Loadable(lazy(() => import('./pages/dashboard/CampaignDetails')));
const CampaignCreate = Loadable(lazy(() => import('./components/dashboard/campaign/CampaignCreate')));

// Other pages

const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

// Campagne
// const CampagneDetails = Loadable(lazy(() => import('./pages/dashboard/CampagneDetails')));
// const CampagneList = Loadable(lazy(() => import('./pages/dashboard/CampagneListe')));
// const CampagneStats = Loadable(lazy(() => import('./')
const AppRoutes = () => {
  const isAuthenticated = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      const enteredHostname = new URL(window.location.href).hostname;
      const enteredPathname = new URL(window.location.href).pathname;
      if ((enteredHostname === 'syna.apteed.fr' && enteredPathname === '/') || (enteredHostname === 'localhost' && enteredPathname === '/')) {
        // Effectue la redirection vers "/dashboard"
        navigate('/dashboard');
      }
    }
  }, [isAuthenticated, navigate]);
  const routes = [
    {
      path: '/',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: '/',
          element: <Login />
        },
        {
          path: 'password-recovery',
          element: <PasswordRecovery />
        },
        {
          path: 'password-reset',
          element: <PasswordReset />
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        {
          path: 'register-unguarded',
          element: <Register />
        },
        {
          path: 'verify-code',
          element: <VerifyCode />
        }
      ]
    },
    {
      path: 'blog',
      element: <BlogLayout />,
      children: [
        {
          path: '',
          element: <BlogPostList />
        },
        {
          path: 'new',
          element: <BlogPostCreate />
        },
        {
          path: ':postId',
          element: <BlogPostDetails />
        }
      ]
    },
    {
      path: 'contact',
      element: <Contact />
    },
    { path: 'formulaire', element: <Formulaire /> },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '',
          element: <Finance />
        },
        {
          path: 'account',
          element: <Account />
        },
        {
          path: 'analytics',
          element: <Analytics />
        },
        {
          path: 'calendar',
          element: <Calendar />
        },
        {
          path: 'presence',
          element: <Presence />
        },
        {
          path: 'logs',
          element: <Logs />
        },
        {
          path: 'imports',
          element: <Imports />
        },
        {
          path: 'campaign',
          children: [
            {
              path: '',
              element: <Campagnes />,
            },
            {
              path: 'create',
              element: <CampaignCreate />,
            },
            {
              path: ':idCampaign',
              element: <CampaignInfo />,
            },
            {
              path: 'learner',
              element: <CampaignLearner />,
            },
            {
              path: ':campaignId/customer/:customerId',
              element: <CampaignCustomerDetails />
            },
            {
              path: ':campaignId/customer/:customerId/status/:status',
              element: <CampaignReinjectionCustomerDetails />
            },

          ] },
        {
          path: 'relaunch',
          children: [
            {
              path: '',
              element: <Reminders />,
            },
            {
              path: ':state/:endDateRelaunch',
              element: <RelaunchInfo />,
            },
            {
              path: ':state/:endDateRelaunch/customer/:customerId',
              element: <RelaunchCustomerDetails />
            },

          ]
        },
        {
          path: 'custom-reports',
          children: [
            {
              path: '',
              element: <CustomReports />
            },
            {
              path: 'create',
              element: <CustomReportCreate />
            },
            {
              path: ':customReportId',
              element: <CustomReportDetails />
            },
            {
              path: 'edit/:customReportId',
              element: <CustomReportCreate />
            },
            {
              path: 'download/:customReportId',
              element: <CustomReportFullDetails />
            },
          ]
        },
        {
          path: 'shift',
          element: <Shift />
        },
        {
          path: 'tickets',
          element: <QualityPage type="ticket" />
        },{
          path: 'complaint',
          element: <QualityPage type="complaint" />
        },
         {
          path: 'satisfactions',
          element: <FormationsSatisfaction />
        },
        {
          path: 'performances',
          element: <FormationsPerformance />
        },
        {
          path: 'chat',
          children: [
            {
              path: '',
              element: <Chat />
            },
            {
              path: 'new',
              element: <Chat />
            },
            {
              path: ':threadKey',
              element: <Chat />
            }
          ]
        },
        {
          path: 'customers',
          children: [
            {
              path: '',
              element: <CustomerList />
            },
            {
              path: ':customerId',
              element: <CustomerDetails />
            },
            {
              path: 'create',
              element: <CustomerCreate />
            },
          ]
        },
        {
          path: 'prospects',
          children: [
            {
              path: '',
              element: <CustomerNewProspects />
            },
          ]
        },
        {
          path: 'partners',
          element: <Partners />
        },
        {
          path: 'customercreate',
          children: [
            {
              path: ':customerId',
              element: <CustomerDetails />
            },
          ]
        },
        // {
        //   path: 'customerrecall',
        //   children: [
        //     {
        //       path: ':customerId',
        //       element: <CustomerDetails />
        //     },
        //   ]
        // },
        // {
        //   path: 'customersession',
        //   children: [
        //     {
        //       path: ':customerId',
        //       element: <CustomerDetails />
        //     },
        //   ]
        // },
        {
          path: 'invoices',
          children: [
            {
              path: '',
              element: <InvoiceList />
            },
            {
              path: ':invoiceId',
              element: <InvoiceDetails />
            }
          ]
        },
        {
          path: 'kanban',
          element: <Kanban />
        },
        {
          path: 'mail',
          children: [
            {
              path: '',
              element: (
                <Navigate
                  to="/dashboard/mail/all"
                  replace
                />
              )
            },
            {
              path: 'label/:customLabel',
              element: <Mail />
            },
            {
              path: 'label/:customLabel/:emailId',
              element: <Mail />
            },
            {
              path: ':systemLabel',
              element: <Mail />
            },
            {
              path: ':systemLabel/:emailId',
              element: <Mail />
            }
          ]
        },
        {
          path: 'orders',
          children: [
            {
              path: '',
              element: <OrderList />
            },
            {
              path: ':orderId',
              element: <OrderDetails />
            }
          ]
        },
        {
          path: 'formations',
          children: [
            {
              path: '',
              element: <Formations />
            },
            {
              path: ':formationId',
              element: <FormationDetails />
            },
            {
              path: 'create',
              element: <FormationCreate />
            }
          ]
        },
        {
          path: 'formationSession',
          children: [
            {
              path: '',
              element: <SessionFormationList />
            },
          ]
        },
        {
          path: 'products',
          children: [
            {
              path: '',
              element: <ProductList />
            },
            {
              path: 'new',
              element: <ProductCreate />
            }
          ]
        },
        {
          path: 'projects',
          children: [
            {
              path: 'browse',
              element: <ProjectBrowse />
            },
            {
              path: 'new',
              element: <ProjectCreate />
            },
            {
              path: ':projectId',
              element: <ProjectDetails />
            }
          ]
        },
        {
          path: 'etablissements',
          children: [
            {
              path: '',
              element: <EtablissementList />
            },
            {
              path: ':etablissementID',
              element: <EtablissementDetails />
            },
            {
              path: 'create',
              element: <EtablissementCreate />
            },
            {
              path: ':edit',
              element: <EtablissementEdit />
            }
          ]
        },
        {
          path: 'session',
          children: [
            {
              path: ':sessionID',
              element: <SessionDetails />
            },
            {
              path: 'create/:idFormation',
              element: <SessionCreate />
            },
            // {
            //   path: ':edit',
            //   element: <SessionEdit />
            // },
            {
              path: 'creatCustomer',
              element: <SessionCreatCustomer />
            },
            {
              path: 'sessionStats',
              element: <SessionStats />
            }
          ]
        },
        {
          path: 'campaign',
          children: [
            {
              path: '',
              element: <Campagnes />
            },
            {
              path: ':idCampaign',
              element: <CampaignInfo />
            },
          ]
        },
        {
          path: 'groupements',
          children: [
            {
              path: '',
              element: <GroupementList />
            },
            {
              path: ':groupementID',
              element: <GroupementDetails />
            },
            {
              path: 'create',
              element: <GroupementCreate />
            },
            {
              path: ':edit',
              element: <GroupementEdit />
            }
          ]
        },
        {
          path: 'campagne',
          children: [
            {
              path: '',
              element: <CustomerList />
            },
            {
              path: ':customerId',
              element: <CustomerDetails />
            },
          ]
        },
        {
          path: 'campaign',
          children: [
            {
              path: 'create',
              element: <CampaignDetails />
            },
          ]
        },
        {
          path: 'users',
          children: [
            {
              path: '',
              element: <UsersList />
            },
          // {
          //   path: ':customerId',
          //   element: <CustomerDetails />
          // },
          ]
        },
        {
          path: 'user',
          children: [
          // {
          //   path: '/',
          //   element: <UsersList />
          // },
            {
              path: ':userId',
              element: <AccountAdmin />
            },
          ]
        },
        {
          path: 'analysis',
          children: [
            {
              path: '',
              element: <Analysis />
            },
          // {
          //   path: ':customerId',
          //   element: <CustomerDetails />
          // },
          ]
        },
        {
          path: 'social',
          children: [
            {
              path: 'feed',
              element: <SocialFeed />
            },
            {
              path: 'profile',
              element: <SocialProfile />
            }
          ]
        }
      ]
    },
    {
      path: 'docs',
      element: <DocsLayout />,
      children: [
        {
          path: '',
          element: (
            <Navigate
              to="/docs/overview/welcome"
              replace
            />
          )
        },
        {
          path: '*',
          element: <Docs />
        }
      ]
    },
    {
      path: '*',
      element: <MainLayout />,
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'checkout',
          element: <Checkout />
        },
        {
          path: 'pricing',
          element: <Pricing />
        },
        {
          path: '401',
          element: <AuthorizationRequired />
        },
        {
          path: '404',
          element: <NotFound />
        },
        {
          path: '500',
          element: <ServerError />
        },
        {
          path: '*',
          element: <NotFound />
        }
      ]
    }
  ];

  return useRoutes(routes);
};
export default AppRoutes;
