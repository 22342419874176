/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { Typography, Skeleton, Divider, Card } from '@material-ui/core';
import { useState, useCallback, useEffect } from 'react';
import useMounted from '../../../hooks/useMounted';
import { shiftApi } from '../../../api/shiftApi';

const ShiftStatistiqueFinished = () => {
  const mounted = useMounted();
  const [loading, setLoading] = useState(true);
  const [statsInformations, setStatsInformations] = useState([]);
  const [statsInformationsDetails, setStatsInformationsDetails] = useState([]);
  const [otherStatus, setOtherStatus] = useState([]);
  const [transformRate, setTransformRate] = useState([]);

  const getSessions = useCallback(async () => {
    try {
      const data = await shiftApi.getShiftStatsFinished(localStorage.getItem('accessToken'));

      if (mounted.current) {
        setStatsInformations(data.shift);
        setStatsInformationsDetails(data.shiftDetails);
        setOtherStatus(data.otherStatus);
        setTransformRate(data.transformRate);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getSessions();
  }, [getSessions]);

  const rows = [];
  const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  // turnover et nombre total par statut
  const totalTurnoverByMonth = {};
  const totalNumberByMonth = {};

  months.forEach((month) => {
    // initialisation des turnovers et nombres par mois
    totalTurnoverByMonth[month] = 0;
    totalNumberByMonth[month] = 0;
  });

  statsInformations.forEach((item) => {
    const row = {
      id: item.status,
      statusFinished: item.status,
    };

    row.turnoverTotal = 0;
    row.numberTotal = 0;

    // calcul du turnover par ligne total
    let totalTurnoverByRow = 0;

    months.forEach((month) => {
      totalTurnoverByRow += parseFloat(item[`turnover${month}`]);
    });
    // parcourir months pour faire correspondre ce qui revient de bdd avec les variables dynamiques
    months.forEach((month) => {
      row[`number${month}`] = item[`number${month}`];
      row[`turnover${month}`] = item[`turnover${month}`] === null ? parseFloat(0).toFixed(2) : parseFloat(item[`turnover${month}`]).toFixed(2);
      totalTurnoverByMonth[month] += item[`turnover${month}`] === null ? 0 : item[`turnover${month}`];
      totalNumberByMonth[month] += item[`number${month}`];
      row.numberTotal += item[`number${month}`];
      row.turnoverTotal += item[`turnover${month}`];
    });
    rows.push(row);
  });

  // calcul de la somme pour la ligne statistique globale
  statsInformations.forEach((item) => {
  });

  // ajout de la ligne statistique globale
  if (statsInformations.length > 0) {
    const row = {
      id: 'sous-total',
      statusFinished: 'Sous-total',
    };

    // calcul du turnover par ligne total de la ligne statistique
    row.numberTotal = 0;
    row.turnoverTotal = 0;

    // parcourir status pour faire correspondre ce qui revient de bdd avec les variables dynamiques
    months.forEach((month) => {
      row[`number${month}`] = totalNumberByMonth[month];
      row[`turnover${month}`] = parseFloat(totalTurnoverByMonth[month]).toFixed(2);
      row.numberTotal += totalNumberByMonth[month];
      row.turnoverTotal += totalTurnoverByMonth[month];
    });
    rows.push(row);
  }

  statsInformationsDetails.forEach((item) => {
    const row = {
      id: item.status,
      statusFinished: item.status,
    };

    row.turnoverTotal = 0;
    row.numberTotal = 0;

    // calcul du turnover par ligne total
    let totalTurnoverByRow = 0;

    months.forEach((month) => {
      totalTurnoverByRow += parseFloat(item[`turnover${month}`]);
    });
    // parcourir months pour faire correspondre ce qui revient de bdd avec les variables dynamiques
    months.forEach((month) => {
      row[`number${month}`] = item[`number${month}`];
      row[`turnover${month}`] = item[`turnover${month}`] === null ? parseFloat(0).toFixed(2) : parseFloat(item[`turnover${month}`]).toFixed(2);
      totalTurnoverByMonth[month] += item[`turnover${month}`] === null ? 0 : item[`turnover${month}`];
      totalNumberByMonth[month] += item[`number${month}`];
      row.numberTotal += item[`number${month}`];
      row.turnoverTotal += item[`turnover${month}`];
    });
    rows.push(row);
  });

  const row = {
    id: otherStatus.status,
    statusFinished: otherStatus.status,
  };

  row.turnoverTotal = 0;
  row.numberTotal = 0;

  // calcul du turnover par ligne total
  let totalTurnoverByRow = 0;

  months.forEach((month) => {
    totalTurnoverByRow += parseFloat(otherStatus[`turnover${month}`]);
    // totalTurnoverByMonth[month] = 0;
  });
  // parcourir months pour faire correspondre ce qui revient de bdd avec les variables dynamiques
  months.forEach((month) => {
    row[`number${month}`] = otherStatus[`number${month}`];
    row[`turnover${month}`] = otherStatus[`turnover${month}`] === null ? parseFloat(0).toFixed(2) : parseFloat(otherStatus[`turnover${month}`]).toFixed(2);
    totalTurnoverByMonth[month] += otherStatus[`turnover${month}`] === null ? 0 : otherStatus[`turnover${month}`];
    totalNumberByMonth[month] += otherStatus[`number${month}`];
    row.numberTotal += otherStatus[`number${month}`];
    row.turnoverTotal += otherStatus[`turnover${month}`];
  });
  rows.push(row);

  const rowTransformRate = {
    id: transformRate.status,
    statusFinished: transformRate.status,
  };

  rowTransformRate.turnoverTotal = (transformRate.turnoverYear / transformRate.turnoverYearTotal) * 100;

  months.forEach((month) => {
  });
  // parcourir months pour faire correspondre ce qui revient de bdd avec les variables dynamiques
  months.forEach((month) => {
    rowTransformRate[`turnover${month}`] = transformRate[`turnover${month}`] === null ? parseFloat(0).toFixed(2) : parseFloat(transformRate[`turnover${month}`]).toFixed(2);
  });
  rows.push(rowTransformRate);

  // Erreur avec le react/destructuring-assignment donc désactivation d'eslint
  const columns = [
    { field: 'statusFinished',
      headerName: 'Statut',
      width: 115,
      renderCell: (cellValues) => (
        <Typography sx={{ fontWeight: cellValues.row.statusFinished === 'Sous-total' || cellValues.row.statusFinished === 'Taux transform.' ? 'bold' : 'normal', fontSize: 14 }}>
          {cellValues.row.statusFinished}
        </Typography>

      ) }
  ];

  months.forEach((month) => {
    columns.push({
      field: `turnover${month}`,
      headerName: month,
      width: 110,
      renderCell: (cellValues) => (
        <Typography
          style={{ margin: 'auto' }}
          sx={{ whiteSpace: 'pre-line', fontWeight: cellValues.row.statusFinished === 'Sous-total' || cellValues.row.statusFinished === 'Taux transform.' /* || cellValues.row[`turnover${month}`] > 10000 */ ? 'bold' : 'normal', color: cellValues.row[`turnover${month}`] > 10000 ? 'green' : cellValues.row[`turnover${month}`] < 1 && cellValues.row.statusFinished !== 'Décalage' ? 'red' : 'inherit' }}
        >
          <span>{cellValues.row.statusFinished === 'Taux transform.' ? `${cellValues.row[`turnover${month}`]} %` : `${cellValues.row[`turnover${month}`]} €\n (${cellValues.row[`number${month}`]})`}</span>
        </Typography>
      ),
      sortComparator: (v1, v2) => v1 - v2
    });
  });
  columns.push({ field: 'turnoverTotal',
    headerName: 'Total',
    width: 130,
    renderCell: (cellValues) => (
      <Typography
        style={{ margin: 'auto' }}
        sx={{ whiteSpace: 'pre-line', fontWeight: 'bold' }}
      >
        <span>{cellValues.row.statusFinished === 'Taux transform.' ? `${parseFloat(cellValues.row.turnoverTotal).toFixed(2)} %` : `${parseFloat(cellValues.row.turnoverTotal).toFixed(2)} €\n (${/* (parseFloat(cellValues.row.turnoverNotBegun) + parseFloat(cellValues.row.turnoverBegun) + parseFloat(cellValues.row.turnoverFinished)).toFixed(2) */cellValues.row.numberTotal})`}</span>
      </Typography>
    ),
    sortComparator: (v1, v2) => v1 - v2 });

  return (
    <Card sx={{ mt: 1 }}>
      <Typography
        color="textSecondary"
        variant="h4"
        sx={{ ml: 3, p: 1 }}
      >
        Chiffre d&apos;affaire pour les inscrits et les inscritsD
      </Typography>
      <Divider />
      {!loading ? (
        <DataGrid
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'asc' }],
            },
          }}
          autoHeight
          rowHeight={70}
          rows={rows}
          columns={columns}
          pageSize={50}
          rowsPerPageOptions={[50, 100]}
          components={{ Toolbar: GridToolbar }}
        />
      ) : (
        <>
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
          <Skeleton
            variant="text"
            sx={{ ml: 4 }}
            animation="wave"
            width={1500}
            height={40}
          />
        </>
      )}
    </Card>
  );
};

export default ShiftStatistiqueFinished;
