/* eslint-disable no-unused-vars */
import { useState, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,

} from '@material-ui/core';
import { formationApi } from '../../../api/formationApi';
import DeleteIcon from '@material-ui/icons/Delete';
import Scrollbar from '../../Scrollbar';
import * as moment from 'moment';
import useAuth from '../../../hooks/useAuth';
import toast from 'react-hot-toast';

const sortOptions = [
  {
    label: 'Status (A - Z)',
    value: 'status|asc'
  },
  {
    label: 'Status (Z - A)',
    value: 'status|desc'
  },
];

const applyFilters = (sessions, query, filters) => sessions
  .filter((session) => {
    let matches = true;

    if (query) {
      const properties = ['Status', 'numberSession'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (session[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && session[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (sessions, page, limit) => sessions
  .slice(page * limit, page * limit + limit);

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const applySort = (sessions, sort) => {
  const [orderBy, order] = sort.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = sessions.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const SessionListTable = (props) => {
  const { sessions, setSessions, ...other } = props;
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [query, setQuery] = useState('');
  const [sort, setSort] = useState('');
  const [filters, setFilters] = useState({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null
  });
  const { user } = useAuth();

  const handleDelete = async (id) => {
    try {
      await formationApi.deleteSession(id, localStorage.getItem('accessToken'));
      const tempSessions = [...sessions];
      tempSessions.splice(tempSessions.findIndex((sess) => sess.id === id), 1);
      setSessions(tempSessions);
      toast.success('Session supprimée !');
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la suppression !');
    }
  };

  const handleTabsChange = (event, value) => {
    const updatedFilters = {
      ...filters,
      hasAcceptedMarketing: null,
      isProspect: null,
      isReturning: null
    };

    if (value !== 'all') {
      updatedFilters[value] = true;
    }

    setFilters(updatedFilters);
    setSelectedSessions([]);
  };

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleSelectAllSessions = (event) => {
    setSelectedSessions(event.target.checked
      ? sessions.map((session) => session.id)
      : []);
  };

  const handleSelectOneSession = (event, sessionId) => {
    if (!selectedSessions.includes(sessionId)) {
      setSelectedSessions((prevSelected) => [...prevSelected, sessionId]);
    } else {
      setSelectedSessions((prevSelected) => prevSelected.filter((id) => id !== sessionId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const filteredSessions = applyFilters(sessions, query, filters);
  const sortedSessions = applySort(filteredSessions, sort);
  const paginatedSessions = applyPagination(sortedSessions, page, limit);
  const selectedSomeSessions = selectedSessions.length > 0
    && selectedSessions.length < sessions.length;
  const selectedAllSessions = selectedSessions.length === sessions.length;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Status et N° de session
                </TableCell>
                <TableCell>
                  Date de début
                </TableCell>
                <TableCell>
                  Date de fin
                </TableCell>
                <TableCell>
                  Nombre d&apos;apprenants
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSessions.map((session) => {
                const isSessionSelected = selectedSessions.includes(session.id);

                return (
                  <TableRow
                    hover
                    key={session.id}
                    selected={isSessionSelected}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex'
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Link
                            color="inherit"
                            component={RouterLink}
                            to={`/dashboard/session/${session.id}`}
                            variant="subtitle2"
                          >
                            Session n°
                            {session.number_session}
                          </Link>
                          <Typography
                            color="textSecondary"
                            variant="body2"
                          >
                            {session.status}
                            {' - '}
                            {// eslint-disable-next-line no-nested-ternary
                            moment(moment.now()).isBefore(session.start_date) ? 'Non commencée' : moment(moment.now()).isBefore(session.end_date) ? 'En cours' : 'Terminée'
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      {moment(session.start_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(session.end_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      {session.nbInscrits}
                    </TableCell>
                    <TableCell align="left">
                      <IconButton
                        onClick={() => handleDelete(session.id)}
                        disabled={user.id_service !== 0}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <TablePagination
        component="div"
        count={filteredSessions.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

SessionListTable.propTypes = {
  sessions: PropTypes.array.isRequired,
  setSessions: PropTypes.func.isRequired
};

export default SessionListTable;
