import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@material-ui/core';
import { sessionApi } from '../../../api/sessionApi';
import useMounted from '../../../hooks/useMounted';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../icons/ChevronRight';
import SessionFormationsFilter from './SessionFormationsFilter';
import gtm from '../../../lib/gtm';
import SessionFormationsResults from './SessionFormationsResults';

const SessionFormationList = () => {
  const mounted = useMounted();
  const { settings } = useSettings();
  const [filters, setFilters] = useState([]);
  const [sessionsFormation, setSessionsFormation] = useState([]);
  const [endDates, setEndDates] = useState([]);
  const [startDates, setStartDates] = useState([]);

  // remet filtres à zéro de src/components/dashboard/customer/CustomerListTable.js
  window.localStorage.setItem('choiceTabProfession', JSON.stringify('all'));
  window.localStorage.setItem('choiceSort', JSON.stringify('lastname|asc'));
  window.localStorage.setItem('choiceSource', JSON.stringify('all'));
  window.localStorage.setItem('choiceCampaign', JSON.stringify('all'));
  window.localStorage.setItem('choiceCallTrack', JSON.stringify('all'));
  window.localStorage.setItem('choiceCallTrackRelance', JSON.stringify('all'));
  window.localStorage.setItem('choiceAvancement', JSON.stringify('all'));

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getSessionFormations = useCallback(async () => {
    try {
      const data = await sessionApi.getSessionFormations(localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'session n\'existe pas');
      }

      if (mounted.current) {
        setSessionsFormation(data.sessionsFormation);
        setStartDates(data.startDates);
        setEndDates(data.endDates);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getSessionFormations();
  }, [getSessionFormations]);

  return (
    <>
      <Helmet>
        <title>Formations Sessions | Apteed</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            // alignItems="center"
            container
            justifyContent="space-between"
            spacing={1}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Liste des sessions
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  Tableau de bord
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/formationSession"
                  variant="subtitle2"
                >
                  Sessions
                </Link>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  Liste
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <SessionFormationsFilter
              endDates={endDates}
              startDates={startDates}
              setFilters={setFilters}
            />
          </Box>
          <Box sx={{ mt: 6 }}>
            <SessionFormationsResults
              sessionsFormation={sessionsFormation}
              filters={filters}
              setSessionsFormation={setSessionsFormation}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SessionFormationList;
