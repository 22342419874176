/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Avatar
} from '@material-ui/core';
import { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Scrollbar from '../../Scrollbar';
import useMounted from '../../../hooks/useMounted';
import BusinessIcon from '@material-ui/icons/Business';
import toast from 'react-hot-toast';
import { etablissementApi } from '../../../api/etablissementApi';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const CustomerEtablissementProfessionnel = () => {
  const mounted = useMounted();
  const { customerId } = useParams();
  const [etablissement, setEtablissement] = useState([]);
  const [etablissements, setEtablissements] = useState([]);
  const [etablissementsBelongsProfessional, setEtablissementsBelongsProfessional] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createdEstablishmentId, setCreatedEstablishmentId] = useState([]);

  const getCustomersEtablissementProfessionnel = useCallback(async () => {
    try {
      const data = await etablissementApi.getCustomersEtablissementProfessionnel(customerId, localStorage.getItem('accessToken'));
      if (data.length === 0) {
        throw new Error('L\'apprenant n\'existe pas');
      }

      if (mounted.current) {
        setEtablissement(data.etablissementProfessional);
        setEtablissements(data.etablissements);
        setEtablissementsBelongsProfessional(data.etablissementsBelongsProfessional);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  const createEtablissement = async (index) => {
    const establishment = { name: `${etablissements[index].firstname} ${etablissements[index].lastname}`,
      email: etablissements[index].email,
      adress: etablissements[index].adress,
      zip: etablissements[index].zip,
      city: etablissements[index].city,
      department: etablissements[index].department,
      region: etablissements[index].region,
      phone: etablissements[index].phone,
      secteur: '',
      type: '',
      website: '',
      linkedin: '',
      facebook: '',
      siret: '' };
    try {
      const etablissementId = await etablissementApi.createEtablissement(establishment, localStorage.getItem('accessToken'));
      await etablissementApi.addCustomer({ customerId: etablissements[index].id, institutionId: etablissementId.data.etablissement_id }, localStorage.getItem('accessToken'));
      const tempCreatedEstablishmentId = [...createdEstablishmentId];
      tempCreatedEstablishmentId.push(index);
      setCreatedEstablishmentId(tempCreatedEstablishmentId);
      toast.success('Établissement créé avec succès !');
    } catch (err) {
      console.error(err);
      toast.error('Il y a eu un souci lors de la mise à jour !');
    }
  };

  useEffect(() => {
    getCustomersEtablissementProfessionnel();
  }, [getCustomersEtablissementProfessionnel]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      {!loading ? (
        <>
          {etablissement.idEtablissements === null ? (
            <Typography
              variant="h5"
              sx={{ p: 1 }}
              color="inherit"
            >

              Cette personne n&apos;appartient à aucun établissement

            </Typography>
          ) : (
            <Typography>
              <Link
                color="inherit"
                component={RouterLink}
                to={`/dashboard/etablissements/${etablissement.idEtablissements}`}
                variant="h4"
                sx={{ p: 1 }}
              >
                Etablissement :
                { ' ' }
                {etablissement.etablissementsName}
              </Link>
            </Typography>
          )}
          <Grid sx={{ mt: 1 }}>
            <Card>
              {etablissementsBelongsProfessional.length === 0 ? (
                ''
              ) : (
                <Scrollbar>
                  <Box sx={{ minWidth: 900 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Nom et Email
                          </TableCell>
                          <TableCell>
                            Profession
                          </TableCell>
                          <TableCell>
                            Téléphone
                          </TableCell>
                          <TableCell>
                            Adress
                          </TableCell>
                          <TableCell>
                            Lieu
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {etablissementsBelongsProfessional.map((link) => (
                          <TableRow
                            key={link.id}
                          >
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Avatar
                                  src={link.avatar}
                                  sx={{
                                    height: 42,
                                    width: 42
                                  }}
                                  {...stringAvatar(`${link.firstname.replace(/ /g, '')} ${link.lastname.replace(/ /g, '')}`)}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <Link
                                    color={link.treated === 1 ? '#7EB627' : 'inherit'}
                                    component={RouterLink}
                                    to={`/dashboard/customers/${link.id}`}
                                    variant="subtitle2"
                                  >
                                    {link.firstname}
                                    {' '}
                                    {link.lastname}
                                  </Link>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {link.email}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Typography>
                                  {link.profession}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Typography>
                                  {link.phone}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {link.city}
                              {', '}
                              {link.department}
                              {', '}
                              {link.region}
                            </TableCell>
                            <TableCell>
                              {link.etablissementsAdress}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Scrollbar>
              ) }
            </Card>
          </Grid>
          {etablissements.length === 0 ? ('') : (
            <Grid sx={{ mt: 1 }}>
              <Typography
                color="inherit"
                variant="h4"
                sx={{ p: 1 }}
              >
                Numéros et adresses identiques
              </Typography>
              <Card sx={{ mt: 1 }}>
                <Scrollbar>
                  <Box sx={{ minWidth: 900 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Nom et Email
                          </TableCell>
                          <TableCell>
                            Profession
                          </TableCell>
                          <TableCell>
                            Téléphone
                          </TableCell>
                          <TableCell>
                            Adresse
                          </TableCell>
                          <TableCell>
                            Lieu
                          </TableCell>
                          <TableCell>
                            Etablissement
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {etablissements.map((link, index) => (
                          <TableRow
                            key={link.id}
                          >
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Avatar
                                  src={link.avatar}
                                  sx={{
                                    height: 42,
                                    width: 42
                                  }}
                                  {...stringAvatar(`${link.firstname.replace(/ /g, '')} ${link.lastname.replace(/ /g, '')}`)}
                                />
                                <Box sx={{ ml: 1 }}>
                                  <Link
                                    color={link.treated === 1 ? '#7EB627' : 'inherit'}
                                    component={RouterLink}
                                    to={`/dashboard/customers/${link.id}`}
                                    variant="subtitle2"
                                  >
                                    {link.firstname}
                                    {' '}
                                    {link.lastname}
                                  </Link>
                                  <Typography
                                    color="textSecondary"
                                    variant="body2"
                                  >
                                    {link.email}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Typography>
                                  {link.profession}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex'
                                }}
                              >
                                <Typography>
                                  {link.phone}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {link.adress}
                            </TableCell>
                            <TableCell>
                              {link.city}
                              {', '}
                              {link.department}
                              {', '}
                              {link.region}
                            </TableCell>
                            <TableCell>
                              {link.etablissementsName !== null ? link.etablissementsName : (
                                <Button
                                  color="primary"
                                  startIcon={<BusinessIcon fontSize="small" />}
                                  sx={{ m: 1 }}
                                  onClick={() => createEtablissement(index)}
                                  variant="contained"
                                  disabled={createdEstablishmentId.includes(index)}
                                >
                                  Créer un établissement
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Scrollbar>
              </Card>
            </Grid>
          )}
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            m: 30
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default CustomerEtablissementProfessionnel;
