import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Autocomplete, Box, Button, Card, Grid, FormHelperText, TextField } from '@material-ui/core';
import { sessionApi } from '../../../api/sessionApi';
import { useNavigate, useParams } from 'react-router-dom';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import * as moment from 'moment';

const SessionCreateForm = (props) => {
  const { ...other } = props;
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation
  const { idFormation } = useParams();
  const statusLabel = ['Active', 'Offerte', 'Annulée'];

  return (
    <Formik
      initialValues={{
        numberSession: '',
        status: '',
        startDate: '',
        endDate: '',
        numberSessionDpc: '',
        submit: ''
      }}
      validationSchema={Yup
        .object()
        .shape({
          numberSession: Yup
            .number()
            .max(255)
            .required('Le N° de session est requis'),
          status: Yup
            .string()
            .max(255)
            .required('Le statut est requis'),
          startDate: Yup.string().max(255),
          endDate: Yup.string().max(255),
          numberSessionDpc: Yup.number()
        },)}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          values.endDate = moment(values.endDate).format('YYYY-MM-DD');
          values.startDate = moment(values.startDate).format('YYYY-MM-DD');
          values.idFormation = idFormation;
          const jsonAnswer = await sessionApi.createSession(values, localStorage.getItem('accessToken'));
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          navigate(`/dashboard/session/${jsonAnswer.data.session_id}`, { replace: true });
        } catch (err) {
          console.error(err);
          toast.error('Il y a eu un souci lors de l\'ajout !');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, }) => (
        <form
          onSubmit={handleSubmit}
          {...other}
        >
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={2.3}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.numberSession && errors.numberSession)}
                    fullWidth
                    helperText={touched.numberSession && errors.numberSession}
                    label="N° de session"
                    name="numberSession"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.numberSession}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={2.3}
                  xs={12}
                >
                  <Autocomplete
                    options={statusLabel}
                    onChange={(e, value) => {
                      setFieldValue(
                        'status',
                        value !== null ? value : values.status
                      );
                    }}
                    value={values.status}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.status && errors.status)}
                        fullWidth
                        helperText={touched.status && errors.status}
                        label="Statut"
                        name="status"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={2.3}
                  xs={12}
                >
                  <Box>
                    <MobileDatePicker
                      error={Boolean(touched.startDate && errors.startDate)}
                      fullWidth
                      inputFormat="dd/MM/yyyy"
                      helperText={touched.startDate && errors.startDate}
                      label="Date de début"
                      name="startDate"
                      onBlur={handleBlur}
                      onChange={(date) => setFieldValue('startDate', (date))}
                      renderInput={(inputProps) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                      value={values.startDate}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  md={2.3}
                  xs={12}
                >
                  <Box>
                    <MobileDatePicker
                      error={Boolean(touched.endDate && errors.endDate)}
                      fullWidth
                      inputFormat="dd/MM/yyyy"
                      helperText={touched.endDate && errors.endDate}
                      label="Date de fin"
                      name="endDate"
                      onBlur={handleBlur}
                      onChange={(date) => setFieldValue('endDate', (date))}
                      renderInput={(inputProps) => (
                        <TextField
                          fullWidth
                          required
                          variant="outlined"
                          {...inputProps}
                        />
                      )}
                      value={values.endDate}
                    />
                  </Box>
                  {Boolean(touched.endDate && errors.endDate) && (
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>
                      {errors.endDate}
                    </FormHelperText>
                  </Box>
                  )}
                </Grid>
                <Grid
                  item
                  md={2.3}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.numberSessionDpc && errors.numberSessionDpc)}
                    fullWidth
                    helperText={touched.numberSessionDpc && errors.numberSessionDpc}
                    label="Numéro de session dpc"
                    name="numberSessionDpc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.numberSessionDpc}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Ajouter la session
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

SessionCreateForm.propTypes = {
  session: PropTypes.object.isRequired
};

export default SessionCreateForm;
