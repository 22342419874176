/* eslint-disable no-bitwise */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, frFR } from '@mui/x-data-grid';
import { Link as RouterLink } from 'react-router-dom';
import { tableCellClasses, Typography, Link, Card, Avatar, Box, IconButton, DialogContentText, DialogActions, DialogContent, Dialog, Button, Rating, Tooltip, Collapse, TableRow, TableCell } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileDownloadOutlinedIcon from '@material-ui/icons/FileDownloadOutlined';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import { formationApi } from '../../../api/formationApi';
import toast from 'react-hot-toast';
import { useRef, useState } from 'react';
import ScrollBar from 'react-perfect-scrollbar';
import ReactExport from "react-export-excel";
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { format } from 'date-fns';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FormationResultTableSatisfaction = ({ formations, setFormations, currentPage, setCurrentPage, loading }) => {
//   const { formations, setFormations, currentPage, setCurrentPage, ...other } = props;
  const rows = [];
  const [currentIdFormation, setCurrentIdFormation] = useState(0);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const downloadFormationsRef = useRef(null);
  const [clickedIndex, setClickedIndex] = useState(-1);

  const handleClose = () => {
    setOpen(false);
  };
  const CustomToolbar = () => (user.id_service === 0
    ? (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        <Button
          aria-label="expand row"
          size="small"
          onClick={() => {
            downloadFormationsRef?.current.click();
          }}
            ><FileDownloadOutlinedIcon fontSize="small" /> Exporter sous format Excel </Button>
      </GridToolbarContainer>
    )
    : (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    ));

  function stringToColor(string) {
    let hash = 0;
    let i;

 
    for (i = 0; i < string.length; i += 1) { hash = string.charCodeAt(i) + ((hash << 5) - hash);}
    let color = '#';
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);}
    return color;
  }
  function stringAvatar(name) {
    return { sx: {bgcolor: stringToColor(name),},
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  formations.filter((el) => el.status !== 'Supprimée').forEach((item) => {
    rows.push({ 
      id: item.course_id,
      name: item.name,
      image: item.image,
      global_average: item.global_average,
      scientific_content: item.scientific_content,
      platform_access: item.platform_access,
      support: item.support,
      survey_participant: item.survey_participant,
      most_recommended_course: item.most_recommended_course,
      profession: item.profession,
      year: item.year,
      satisfaction: (Number(item.global_average) / 20).toFixed(1),
      session:item.satisfaction_sessions,
    });
  });



  
  const columns = [
    {field: "",  width: 10, headerName: "",sx: {alignItems: 'start',display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap'},
    renderCell: (cellValues) => {
      return (<Box>
      {cellValues.row.session.length > 0 && <Tooltip title="Voir les sessions">
      <IconButton style={{margin: '0px', padding: '0px'}} onClick={() => {clickedIndex === cellValues.id ? setClickedIndex(-1) : setClickedIndex(cellValues.id);}}>
        {cellValues.id === clickedIndex   ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon /> }</IconButton>
        </Tooltip>}
        {cellValues.id === clickedIndex && <Box> 
     <Collapse in={cellValues.id === clickedIndex}>
      <Box>
    {cellValues.row.session.map((row) => (
    <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
       <TableCell align="right"> <Box sx={{ alignItems: 'center', display: 'flex',paddingLeft: '60px'}}>
        <Box sx={{alignItems: 'center', display: 'flex', }} key={row.course_session}></Box>
        </Box> </TableCell>
    <TableCell align="right" sx={{ whiteSpace: 'nowrap', opacity: '0'}}> ( A ) </TableCell>
    </TableRow>
    ))}
      </Box></Collapse></Box>}
        </Box>
        )
    },
   
  },
    {field: 'name', headerName: 'Nom', width: 500, sx: {alignItems: 'center',display: 'flex', justifyContent: 'space-between',flexWrap: 'wrap'},
    renderCell: (cellValues) => ( <Box>
      <Box sx={{ alignItems: 'center', display: 'flex',minHeight: 50, maxHeight: 50 ,  }}>
       <Avatar src={cellValues.row.image} sx={{ height: 50, width: 50,}}{...stringAvatar(`${cellValues.row.name.replace(/ /g, '')} ${cellValues.row.name.replace(/ /g, '')}`)} />
        <Box sx={{ ml: 1 }}>
        <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13, }} component={RouterLink} to={{
  pathname: `/dashboard/formations/${cellValues.row.id}`,
  search: "?origin=satisfactions"
}}>{cellValues.row.name}</Link>
          {/* <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13, }} component={RouterLink} to={{pathname: `/dashboard/formations/${cellValues.row.id}?origin=satisfactions`}}  variant="subtitle2">
            {cellValues.row.name}
          </Link> */}
        </Box>
      </Box> 
      {cellValues.id === clickedIndex && <Box> 
        <Collapse in={cellValues.id === clickedIndex}>
        <Box>
        {cellValues.row.session.map((row) => (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
           <TableCell align="right"> <Box sx={{ alignItems: 'center', display: 'flex',paddingLeft: '120px'}}>
         
          <Box sx={{alignItems: 'center', display: 'flex', }} key={row.course_session}>
          <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13, }} component={RouterLink} to={{
  pathname: `/dashboard/session/${row.course_session}`,
  search: "?origin=satisfactions"
}}> Session N° {row.number_session}</Link>
          {/* <Link sx={{ whiteSpace: 'normal', fontWeight: 600, fontSize: 13 }} component={RouterLink} to={{pathname: `/dashboard/session/${row.course_session}?origin=satisfactions`}}  variant="subtitle2">
          Session N° {row.number_session}
        </Link> */}
        </Box>
        </Box>
        </TableCell>
        <TableCell align="right"> ( Du  {format(new Date(row.startDate), "dd/MM/yyyy")} au {format(new Date(row.endDate), "dd/MM/yyyy")} )</TableCell>
          </TableRow>
        ))}
        </Box></Collapse></Box>}
</Box>
     
  )},
  {field: 'year', headerName: "Année", width: 75, renderCell: (cellValues) => (
    <Box>
     <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
    <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.year}</Typography> </TableCell></TableRow>
    {cellValues.id === clickedIndex && <Box> 
      <Collapse in={cellValues.id === clickedIndex}>
        <Box>
        {cellValues.row.session.map((row) => (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
           <TableCell align="right"> <Box sx={{ alignItems: 'center', display: 'flex',paddingLeft: '60px'}}>
          <Box sx={{alignItems: 'center', display: 'flex', }} key={row.course_session}> </Box>
        </Box> </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap', opacity: '0'}}> ( A ) </TableCell></TableRow>
        ))}
        </Box></Collapse></Box>}
    </Box>)},

    { field: 'scientific_content', headerName: 'Contenu scientifique', width: 150,  renderCell: (cellValues) => (
  <Box>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}> 
       <Typography sx={{ whiteSpace: 'normal' }}>{cellValues.row.scientific_content}%</Typography>
       </TableCell></TableRow>
       { cellValues.id === clickedIndex && <Collapse in={cellValues.id === clickedIndex}>
        <Box>
         {cellValues.row.session.map((row) => (
           <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
           <TableCell key={row.course_session} 
           sx={{}}>
               {row.scientific_content}%
               </TableCell>
           </TableRow>))}
           </Box></Collapse>}
      </Box>
    ) },
    { field: 'platform_access', headerName: 'Accès à la plateforme', width: 150,  renderCell: (cellValues) => (
    <Box>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}> 
     <Typography sx={{ whiteSpace: 'normal' }}>{cellValues.row.platform_access}%</Typography>
       </TableCell></TableRow>
       { cellValues.id === clickedIndex && <Collapse in={cellValues.id === clickedIndex}>
        <Box>
         {cellValues.row.session.map((row) => (
           <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
           <TableCell key={row.course_session} 
           sx={{}}>
               {row.platform_access}%
               </TableCell>
           </TableRow>))}
           </Box></Collapse>}
      </Box>
    ) },
    { field: 'support', headerName: 'Accompagnement', width: 130,  renderCell: (cellValues) => (
    <Box>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}> 
     <Typography sx={{ whiteSpace: 'normal' }}>{cellValues.row.support}%</Typography>
       </TableCell></TableRow>
       { cellValues.id === clickedIndex && <Collapse in={cellValues.id === clickedIndex}>
        <Box>
         {cellValues.row.session.map((row) => (
           <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
           <TableCell key={row.course_session} 
           sx={{}}>
               {row.support}%
               </TableCell>
           </TableRow>))}
           </Box></Collapse>}
      </Box>
    ) },
    { field: 'most_recommended_course', headerName: 'La formation la plus demandée', width: 300,  renderCell: (cellValues) => (
    <Box>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
     <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.most_recommended_course}</Typography> </TableCell></TableRow>
       { cellValues.id === clickedIndex && <Collapse in={cellValues.id === clickedIndex}>
        <Box>
         {cellValues.row.session.map((row) => (
           <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
           <TableCell key={row.course_session} 
           sx={{}}>
               {row.most_recommended_course}
               </TableCell>
           </TableRow>))}
           </Box></Collapse>}
      </Box>
    ) },
    { field: 'global_average', headerName: 'Note globale',  width: 130, renderCell: (cellValues) => (
      <Box>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
   <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}>  <Typography style={{margin: 'auto'}}>{cellValues.row.global_average}%</Typography> </TableCell></TableRow>
     { cellValues.id === clickedIndex && <Collapse in={cellValues.id === clickedIndex}>
      <Box>
       {cellValues.row.session.map((row) => (
         <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
         <TableCell key={row.course_session} 
         sx={{}}>
             {row.global_average}%
             </TableCell>
         </TableRow>))}
         </Box></Collapse>}
    </Box>
    ) },
    { field: 'satisfaction', headerName: 'Satisfaction', width: 250, renderCell: (cellValues) => (
      <Box>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}>
   <TableCell sx={{ alignItems: 'center', display: 'flex',minHeight: 50,   }}> 
   <Box display="flex" alignItems="center"
   ><Rating style={{marginRight: '20px'}} name="read-only" precision={0.1} value={cellValues.row.satisfaction} readOnly />
   <Typography>{`${cellValues.row.satisfaction} / 5`}</Typography></Box>
     </TableCell></TableRow>
     { cellValues.id === clickedIndex && <Collapse in={cellValues.id === clickedIndex}>
      <Box>
       {cellValues.row.session.map((row) => (
         <TableRow sx={{ '& > *': { borderBottom: 'unset' }, [`& .${tableCellClasses.root}`]: { borderBottom: 'none'} }}> 
         <TableCell key={row.course_session} 
         sx={{}}>
             <Box display="flex"  alignItems="center"
   >
    <Rating style={{marginRight: '20px'}} name="read-only" precision={0.1} value={(Number(row.global_average) / 20).toFixed(1)} readOnly />
   <Typography>{`${(Number(row.global_average) / 20).toFixed(1)} / 5`}</Typography></Box>
             </TableCell>
         </TableRow>))}
         </Box></Collapse>}
    </Box>
    
   ) },

  ];
  return (
    <Card>
        <ExcelFile filename="export_satisfactions" element={<IconButton ref={downloadFormationsRef} size="medium" sx={{ display: 'none' }}><DownloadIcon />
        </IconButton>}>
          <ExcelSheet data={formations} name="Questions satisfaction">
              <ExcelColumn label="Formation" value="name" />
              <ExcelColumn label="Profession" value="profession" />
              <ExcelColumn label="Moyenne globale" value="global_average" />
              <ExcelColumn label="Moyenne contenu scientifique" value="scientific_content" />
              <ExcelColumn label="Moyenne accès à la plateforme" value="platform_access" />
              <ExcelColumn label="Accompagnement" value="support" />
              <ExcelColumn label="Total d'apprenants inscrits" value="registered_learner" />
              <ExcelColumn label="Total d'apprenants ayant participé au questionnaire satisfaction" value="survey_participant" />
              <ExcelColumn label="Pourcentage d'apprenants ayant participé au questionnaire satisfaction" value="participation" />
              <ExcelColumn label="Formation la plus recommendée" value="most_recommended_course" />
          </ExcelSheet>
        </ExcelFile> 
        <Box sx={{ height: 740, width: '100%' }}>
              <DataGrid        
                sx={{ background: '', overflowY: 'auto', 
            '& .MuiDataGrid-columnHeaderTitle': {  whiteSpace: 'pre-wrap', lineHeight: 1, margin: 'auto', display: 'flex',},
              // '& .MuiDataGrid-columnHeader': { whiteSpace: 'break-spaces', lineHeight: 1,},
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': { pl: 1,},
            "& .MuiDataGrid-renderingZone": {
              //  maxHeight: "none !important",
                minHeight: 85 },
            "& .MuiDataGrid-cell": {
              // lineHeight: "unset !important",
              maxHeight: "none !important", whiteSpace: "normal", minHeight: 85},
            "& .MuiDataGrid-row": { maxHeight: "none !important", minHeight: 85}
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          initialState={{ sorting: {sortModel: [{ field: 'name', sort: 'asc' }],},
            pagination: {paginationModel: {pageSize: 5,},},
          }}
          rows={rows}
          columns={columns}
          page={currentPage}
          pageSize={10}
          rowsPerPageOptions={[30, 100]}
          components={{ Toolbar: CustomToolbar }}
          onPageChange={(newPage) => { setCurrentPage(newPage); }}
          loading={loading}
        /></Box>
    </Card>
  );
};
FormationResultTableSatisfaction.propTypes = {
  formations: PropTypes.array.isRequired,
  setFormations: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default FormationResultTableSatisfaction;
