/* eslint-disable linebreak-style */
export const deliveryApi = {
  addDelivery: async (accessToken, deliveryAdd, partnerId) => {
    const urlPostDelivery = `${process.env.REACT_APP_API_URL}/api/deliveries`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ ...deliveryAdd, partner_id: partnerId }),
    };

    const response = await fetch(urlPostDelivery, options);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },

  getDeliveriesByPartner: async (accessToken, partnerId) => {
    const urlDeliveriesByPartner = `${process.env.REACT_APP_API_URL}/api/deliveries/partner/${partnerId}`;
    const response = await fetch(urlDeliveriesByPartner, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },

  getCurrentYearTotals: async (accessToken, partnerId) => {
    const urlTotalsByYear = `${process.env.REACT_APP_API_URL}/api/deliveries/current-totals/${partnerId}`;
    const response = await fetch(urlTotalsByYear, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },

  deleteDelivery: async (accessToken, deliveryId) => {
    const urlDeleteDelivery = `${process.env.REACT_APP_API_URL}/api/deliveries/delete`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ id: deliveryId })
    };
    const response = await fetch(urlDeleteDelivery, options);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },

  modifyDelivery: async (accessToken, deliveryId, deliveryField, value) => {
    const urlEditDelivery = `${process.env.REACT_APP_API_URL}/api/deliveries/edit`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ id: deliveryId, [deliveryField]: value }),
    };

    const response = await fetch(urlEditDelivery, options);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },
};

export const partnerApi = {
  getPartners: async (accessToken) => {
    const urlPartners = `${process.env.REACT_APP_API_URL}/api/partners`;
    const response = await fetch(urlPartners, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },

  createPartner: async (accessToken, newPartner) => {
    const urlPostPartner = `${process.env.REACT_APP_API_URL}/api/partners`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(newPartner),
    };

    const response = await fetch(urlPostPartner, options);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },

  updatePartner: async (accessToken, updatedPartner) => {
    const urlPutPartner = `${process.env.REACT_APP_API_URL}/api/partners/update`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(updatedPartner),
    };

    const response = await fetch(urlPutPartner, options);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },

  deletePartner: async (accessToken, partnerId) => {
    const urlDeletePartner = `${process.env.REACT_APP_API_URL}/api/partners/delete`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ id: partnerId })
    };

    const response = await fetch(urlDeletePartner, options);

    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      return new Error(message);
    }

    return response.json();
  },
};
