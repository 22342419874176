import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme
});

const SettingsDrawer = (props) => {
  const { settings, saveSettings } = useSettings();
  // eslint-disable-next-line react/prop-types
  const { openDrawer, setOpenDrawer } = props;
  const [values, setValues] = useState(getValues(settings));

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpenDrawer(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={openDrawer}
        PaperProps={{
          sx: {
            p: 2,
            width: 320
          }
        }}
      >
        <Typography
          color="textPrimary"
          variant="h6"
        >
          Paramètres
        </Typography>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Thème"
            name="theme"
            onChange={(event) => handleChange('theme', event.target.value)}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {theme
                  .split('_')
                  .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                  .join(' ') === 'Light' ? 'Clair' : theme
                    .split('_')
                    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                    .join(' ') === 'Dark' ? 'Sombre'
                    : theme.split('_')
                      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                      .join(' ')}
              </option>
            ))}
          </TextField>
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.direction === 'rtl'}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event) => handleChange('direction', event.target.checked
                  ? 'rtl'
                  : 'ltr')}
              />
            )}
            label={(
              <div>
                RTL
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Changer la direction du texte
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.responsiveFontSizes}
                color="primary"
                edge="start"
                name="direction"
                onChange={(event) => handleChange('responsiveFontSizes', event.target.checked)}
              />
            )}
            label={(
              <div>
                Taille de la police réactive
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Ajuster la police de caractères pour les petits appareils
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.compact}
                color="primary"
                edge="start"
                name="compact"
                onChange={(event) => handleChange('compact', event.target.checked)}
              />
            )}
            label={(
              <div>
                Compacte
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Largeur fixe sur certains écrans
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.roundedCorners}
                color="primary"
                edge="start"
                name="roundedCorners"
                onChange={(event) => handleChange('roundedCorners', event.target.checked)}
              />
            )}
            label={(
              <div>
                Coins ronds
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  Pour avoir des coins ronds
                </Typography>
              </div>
            )}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleSave}
            variant="contained"
          >
            Enregistrer
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default SettingsDrawer;
