class ShiftApi {
  async getShiftStatsFinished(accessToken) {
    const urlShift = `${process.env.REACT_APP_API_URL}/api/shiftStatsFinished`;

    const resp = await fetch(urlShift, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getShiftStatsNotFinished(accessToken) {
    const urlShift = `${process.env.REACT_APP_API_URL}/api/shiftStatsNotFinished`;

    const resp = await fetch(urlShift, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }

  async getShiftCustomersList(accessToken) {
    const urlShift = `${process.env.REACT_APP_API_URL}/api/shiftCustomersList`;

    const resp = await fetch(urlShift, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve([{ customers: json.data }, { amounts: json.totalAmounts }]);
  }

  async getShiftReportsList(accessToken) {
    const urlShift = `${process.env.REACT_APP_API_URL}/api/customReports`;

    const resp = await fetch(urlShift, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    });

    const json = await resp.json();
    return Promise.resolve(json.data);
  }
}

export const shiftApi = new ShiftApi();
