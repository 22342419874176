/* eslint-disable no-unused-vars */
import { useState, useEffect, useCallback } from 'react';
import useMounted from '../../../hooks/useMounted';
import { dashboardApi } from '../../../api/dashboardApi';
import { Alert, Box, Button, IconButton, Link, Snackbar, Typography } from '@material-ui/core';
import gtm from '../../../lib/gtm';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink } from 'react-router-dom';
import * as moment from 'moment';
import Slide from '@mui/material/Slide';
import { useIncomingSessionContext } from '../../../contexts/IncomingSessionContext';

function SlideTransition(props) {
  return (
    <Slide
      {...props}
      direction="down"
    />
  );
}

const SessionIncomingAlert = () => {
  const { mounted } = useMounted();
  const [incommingSessions, setIncommingSessions] = useState([]);

  const [state, setState] = useState({
    open: true,
    vertical: 'top',
    horizontal: 'center',
  });

  const { vertical, horizontal, open } = state;

  const initIsAlertShown = useCallback(() => {
    const isAlertShown = localStorage.getItem('isAlertShown');
    if (isAlertShown === undefined ) {
      // localStorage.setItem('isAlertShown', true);
    } 
  });
  
  // useEffect(() => {
  //   console.log('the state is changing...')
  // }, [state]);

  const getIncomingSessions = useCallback(async () => {
    try {
      const data = await dashboardApi.notifyIncommingSessions(localStorage.getItem('accessToken'));
      if (data && data.length !== 0) {
        setIncommingSessions(data);
        // initIsAlertShown();
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    // initIsAlertShown();
    getIncomingSessions();
  }, []);
  
  const handleClose = () => {
    setState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
    localStorage.setItem('isAlertShown', false);
  }

  return (
    <>
      {localStorage.getItem('isAlertShown') === null  && incommingSessions && incommingSessions.length !== 0 && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
                // onClose={handleClose}
          key={vertical + horizontal}
          sx={{ width: '60%' }}
          TransitionComponent={SlideTransition}
        >
          <Alert
            severity="info"
            action={(
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          >
            {incommingSessions.map((session) => (
              <>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {`Demain ${moment(session.meeting_start_date).format('DD/MM/YYYY')} aura lieu la prochaine virtuelle/présentielle de la `}
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/dashboard/session/${session.session_id}`}
                    variant="subtitle2"
                    target="blank"
                  >
                    {`session n° ${session.number_session}`}
                  </Link>
                  {' sur le thème : '}
                  <Link
                    color="inherit"
                    component={RouterLink}
                    to={`/dashboard/formations/${session.f_id}`}
                    variant="subtitle2"
                  >
                    {`${session.f_name}`}
                  </Link>
                </Typography>
              </>
            ))}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SessionIncomingAlert;
