import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Autocomplete, Box, Button, Card, Grid, TextField } from '@material-ui/core';
import { customerApi } from '../../../api/customerApi';
import professions from './professions';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const CustomerCreateForm = (props) => {
  const { user } = useAuth();
  const { ...other } = props;
  const civilityLabel = ['Monsieur', 'Madame', 'Autre'];
  const modeOfExerciseLabel = ['Libéral', 'Salarié en centre de santé', 'Salarié en hôpital', 'Autre'];
  const [specialiteTab, setSpecialiteTab] = useState(['']);
  const [specialiteDisabled, setSpecialiteDisabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();// On utilise navigate afin d'utliser les fonctions de navigation
  const searchPlace = async (value) => {
    const urlPlace = `https://geo.api.gouv.fr/communes?codePostal=${value}&fields=nom,departement,region&format=json&geometry=centre`;
    return (await fetch(urlPlace)).json();
  };

  return (
    <Formik
      initialValues={{
        birthday: null,
        profession: '',
        specialite: '',
        modeOfExercise: '',
        adeliRpps: '',
        numSecu: '',
        mdpMondpc: '',
        civility: '',
        lastname: '',
        firstname: '',
        adress: '',
        zip: '',
        city: '',
        email: '',
        phone: '',
        phoneFix: '',
        department: '',
        region: '',
        submit: ''
      }}
      validationSchema={Yup
        .object()
        .shape({
          adress: Yup.string().max(255),
          birthday: Yup.string().max(10).nullable(),
          profession: Yup.string().max(255),
          specialite: Yup.string().max(255),
          modeOfExercise: Yup.string().max(255),
          adeliRpps: Yup.string().max(11).nullable(),
          numSecu: Yup.string().max(13).nullable(),
          mdpMondpc: Yup.string().max(255).nullable(),
          civility: Yup.string().max(8),
          lastname: Yup
            .string()
            .max(255)
            .required('Le prénom est requis'),
          firstname: Yup
            .string()
            .max(255)
            .required('Le nom est requis'),
          zip: Yup.string().max(5),
          city: Yup.string().max(255),
          email: Yup
            .string()
            .email('Doit être une adresse email')
            .max(255)
            .required('L\'adresse email est requise'),
          phone: Yup.string().max(20, 'Il y a trop de numéros.').min(10).nullable()
            .when('phoneFix', {
              is: (phoneFix) => !phoneFix || phoneFix.length === 0,
              then: Yup.string()
                .required('Un des deux champs de téléphone est requis.'),
            }),
          phoneFix: Yup.string().max(20, 'Il y a trop de numéros.').min(10).nullable()
            .when('phone', {
              is: (phone) => !phone || phone.length === 0,
              then: Yup.string()
                .required('Un des deux champs de téléphone est requis.'),
            }),
          department: Yup.string().max(255),
          region: Yup.string().max(255)
        }, [['phone', 'phoneFix']])}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
        try {
          await searchPlace(values.zip).then((value) => {
            if (value.length !== 0) {
              values.city = value[0].nom;
              values.department = value[0].departement.nom;
              values.region = value[0].region.nom;
            } else {
              values.city = '';
              values.department = '';
              values.region = '';
            }
          });
          values.firstSourceId = user.id;
          const jsonAnswer = await customerApi.createCustomer(values, localStorage.getItem('accessToken'));
          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          navigate(`/dashboard/customercreate/${jsonAnswer.data.customer_id}`, { replace: true });
        } catch (err) {
          console.error(err);
          toast.error('Il y a eu un souci lors de l\'ajout !');
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
          {...other}
        >
          <Card>
            <Box sx={{ p: 3 }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <Autocomplete
                    options={civilityLabel}
                    onChange={(e, value) => {
                      setFieldValue(
                        'civility',
                        value !== null ? value : values.civility
                      );
                    }}
                    value={values.civility}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.civility && errors.civility)}
                        fullWidth
                        helperText={touched.civility && errors.civility}
                        label="Civilité"
                        name="civility"
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.lastname && errors.lastname)}
                    fullWidth
                    helperText={touched.lastname && errors.lastname}
                    label="Nom"
                    name="lastname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.lastname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.firstname && errors.firstname)}
                    fullWidth
                    helperText={touched.firstname && errors.firstname}
                    label="Prénom"
                    name="firstname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.firstname}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.birthday && errors.birthday)}
                    fullWidth
                    helperText={touched.birthday && errors.birthday}
                    label="Date de naissance"
                    name="birthday"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="date"
                    value={values.birthday}
                    variant="outlined"
                    // pour éviter la superposition de jj/mm/aaaa et date de naissance dans le champ
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Adresse Email"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone}
                    label="Téléphone portable"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.phoneFix && errors.phoneFix)}
                    fullWidth
                    helperText={touched.phoneFix && errors.phoneFix}
                    label="Téléphone fixe"
                    name="phoneFix"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneFix}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.adress && errors.adress)}
                    fullWidth
                    helperText={touched.adress && errors.adress}
                    label="Adresse"
                    name="adress"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.zip && errors.zip)}
                    fullWidth
                    helperText={touched.zip && errors.zip}
                    label="Code postal"
                    name="zip"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.zip}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <Autocomplete
                    getOptionLabel={(option) => option.profession}
                    options={professions}
                    onChange={(e, value) => {
                      setFieldValue(
                        'profession',
                        value !== null ? value.profession : ''
                      );
                      if (value !== null) {
                        if (value.specialites !== undefined) {
                          setSpecialiteTab(value.specialites);
                        } else {
                          setSpecialiteTab(['']);
                          setFieldValue(
                            'specialite',
                            ''
                          );
                          setSpecialiteDisabled(true);
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.profession && errors.profession)}
                        fullWidth
                        helperText={touched.profession && errors.profession}
                        label="Profession"
                        name="profession"
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <Autocomplete
                    options={specialiteTab}
                    disabled={specialiteDisabled}
                    onChange={(e, value) => {
                      setFieldValue(
                        'specialite',
                        value !== null ? value : ''
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.specialite && errors.specialite)}
                        fullWidth
                        helperText={touched.specialite && errors.specialite ? errors.specialite : 'Lorsque ce champ est grisé, sa valeur est vide.'}
                        label="Spécialité"
                        name="specialite"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <Autocomplete
                    options={modeOfExerciseLabel}
                    onChange={(e, value) => {
                      setFieldValue(
                        'modeOfExercise',
                        value !== null ? value : values.modeOfExercise
                      );
                    }}
                    value={values.modeOfExercise}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.modeOfExercise && errors.modeOfExercise)}
                        fullWidth
                        helperText={touched.modeOfExercise && errors.modeOfExercise}
                        label="Mode d'exercise"
                        name="modeOfExercise"
                        required
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.adeliRpps && errors.adeliRpps)}
                    fullWidth
                    helperText={touched.adeliRpps && errors.adeliRpps}
                    label="Adeli / RPPS"
                    name="adeliRpps"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.adeliRpps}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.mdpMondpc && errors.mdpMondpc)}
                    fullWidth
                    helperText={touched.mdpMondpc && errors.mdpMondpc}
                    label="Mot de passe MonDPC"
                    name="mdpMondpc"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mdpMondpc}
                    variant="outlined"
                  />
                </Grid>
                <Grid item />
              </Grid>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Ajouter l&apos;apprenant
                </Button>
              </Box>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

CustomerCreateForm.propTypes = {
  customer: PropTypes.object.isRequired
};

export default CustomerCreateForm;
