import Chart from 'react-apexcharts';
import numeral from 'numeral';
import { Box, Card, CardContent, CardHeader, FormControlLabel, Grid, Skeleton, Switch, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useAuth from '../../../hooks/useAuth';
import { useState, useCallback, useEffect } from 'react';
import { dashboardApi } from '../../../api/dashboardApi';
import useMounted from '../../../hooks/useMounted';

const FinanceCostBreakdown = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const mounted = useMounted();
  const colorsPie = ['#FFB547', '#FF5F52', '#7783DB', '#7BC67E', '#A935FF', '#002984'];
  const [loading, setLoading] = useState(true);
  const [professionsNumber, setProfessionsNumber] = useState([]);
  const [finished, setFinished] = useState(false);

  const getCostBreakdown = useCallback(async (fini) => {
    try {
      const data = await dashboardApi.getCostBreakdown(fini, localStorage.getItem('accessToken'));

      if (data.length === 0) {
        throw new Error('Impossible de récupérer les données.');
      }

      if (mounted.current) {
        setProfessionsNumber(data.professionsNumber);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [mounted]);

  useEffect(() => {
    getCostBreakdown(false);
  }, [getCostBreakdown]);

  useEffect(() => {
    getCostBreakdown(finished);
  }, [finished]);

  //  CHIFFRE D'AFFAIRE PAR PROFESSION POUR L'ADMIN
  const dataPro = {
    series: professionsNumber.map((profession, index) => ({ color: colorsPie[index], dataPro: profession.somme, label: `${profession.profession} (${profession.countCourses} thèmes, ${profession.countLearners} apprenants)` }))
  };
  //  NOMBRE DE VENTE PAR PROFESSION POUR LES COMMERCIAUX
  const data = {
    series: professionsNumber.map((profession, index) => ({ color: colorsPie[index], data: profession.countCourses, label: `${profession.profession} (${profession.countCourses} thèmes, ${profession.countLearners} apprenants)` }))
  };

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: colorsPie,
    dataLabels: {
      enabled: false
    },
    labels: data.series.map((item) => item.label),
    legend: {
      show: false
    },
    stroke: {
      colors: [theme.palette.background.paper],
      width: 1
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  const chartOptionsPro = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: dataPro.series.map((item) => item.color),
    dataLabels: {
      enabled: true
    },
    labels: dataPro.series.map((item) => item.label),
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65',
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              formatter(val) {
                return `${val} €`;
              },
            },
            total: {
              show: true,
              showAlways: true,
              fontSize: '12px',
              formatter(w) {
                return `${numeral(w.globals.seriesTotals.reduce((a, b) => a + b, 0)).format('€0,000,000.00').replaceAll(',', ' ')} €`;
              },
            }
          }
        }
      }
    },
    stroke: {
      colors: [theme.palette.background.paper],
      width: 1
    },
    theme: {
      mode: theme.palette.mode
    }
  };
  const chartSeries = data.series.map((item) => item.data);
  const chartSeriesPro = dataPro.series.map((item) => item.dataPro);
  return (
    <Card>
      {!loading ? (
        <Grid container>
          <Grid
            item
            xs={8}
          >
            {finished ? (<CardHeader title="Terminés par profession" />) : (<CardHeader title="CA par profession" />)}
          </Grid>
          <Grid
            item
            xs={4}
            p={1}
          >
            <FormControlLabel
              control={<Switch />}
              onChange={() => setFinished(!finished)}
              label="CA/Terminés"
            />
          </Grid>
        </Grid>
      ) : (
        <Skeleton
          variant="text"
          animation="wave"
          sx={{ ml: 2 }}
          width={150}
          height={40}
        />
      )}
      <CardContent>
        {!loading ? (
          <Chart
            height="250"
            options={user.access_level === 0 ? chartOptionsPro : chartOptions}
            series={user.access_level === 0 ? chartSeriesPro : chartSeries}
            type="donut"
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="circular"
            sx={{ ml: 20 }}
            width={200}
            height={200}
          />
        )}
        {/* L'AFFICHAGE DE NOMBRE DE VENTE PAR PROFESSION POUR L'ADMIN */}
        {user.access_level !== 0 && data.series.map((item) => (
          <>
            {!loading ? (
              <Box
                key={item.label}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 1
                }}
              >
                <>
                  <Box
                    sx={{
                      backgroundColor: item.color,
                      borderRadius: '0%',
                      height: 8,
                      width: 8
                    }}
                  />
                  <Typography
                    color="textPrimary"
                    sx={{ ml: 3 }}
                    variant="subtitle2"
                  >
                    {item.label}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                  >
                    {numeral(item.data).format('€0,000,000.00').replaceAll(',', ' ')}
                  </Typography>
                </>
              </Box>
            ) : (
              <Skeleton
                animation="wave"
                variant="text"
                width={100}
                height={40}
              />
            )}
          </>
        ))}
        {/* L'AFFICHAGE DE CHIFFRE D'AFFAIRE PAR PROFESSION POUR L'ADMIN */}
        {user.access_level === 0 && dataPro.series.map((item) => (
          <Box
            key={item.label}
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 1
            }}
          >
            {!loading ? (
              <>
                <Box
                  sx={{
                    backgroundColor: item.color,
                    borderRadius: '0%',
                    height: 8,
                    width: 8
                  }}
                />
                <Typography
                  color="textPrimary"
                  sx={{ ml: 3 }}
                  variant="subtitle2"
                >
                  {item.label}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
              </>
            ) : (
              <Skeleton
                variant="text"
                animation="wave"
                width={100}
                height={20}
              />
            )}
            {!loading ? (
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                {numeral(item.dataPro).format('€0,000,000.00').replaceAll(',', ' ')}
                €
              </Typography>
            ) : (
              <Skeleton
                animation="wave"
                sx={{ ml: 40 }}
                width={30}
                height={25}
              />
            )}
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

export default FinanceCostBreakdown;
