import Chart from 'react-apexcharts';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Container, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const SessionStats = (props) => {
  const { customers, formation, customersFixed, ...other } = props;
  const [data, setData] = useState({ series: [{
    color: '#FFB547',
    data: 0,
    label: 'Inscrit'
  },
  {
    color: '#ff5f52',
    data: 0,
    label: 'En attente'
  },
  {
    color: '#7783DB',
    data: 0,
    label: 'Désinscription'
  }] });
  const [dataProgress, setDataProgress] = useState({ seriesProgress: [{
    color: '#FFB547',
    data: 0,
    label: 'Terminé',
    count: 0
  },
  {
    color: '#ff5f52',
    data: 0,
    label: 'En cours',
    count: 0
  },
  {
    color: '#7783DB',
    data: 0,
    label: 'Pas commencé',
    count: 0
  }] });
  const [total, setTotal] = useState(0);
  const theme = useTheme();

  const chartOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: data.series.map((item) => item.color),
    dataLabels: {
      enabled: false
    },
    labels: data.series.map((item) => item.label),
    legend: {
      show: false
    },
    stroke: {
      colors: [theme.palette.background.paper],
      width: 1
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  const chartProgressOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false
      }
    },
    colors: dataProgress.seriesProgress.map((itemProgress) => itemProgress.color),
    dataLabels: {
      enabled: false
    },
    labels: dataProgress.seriesProgress.map((itemProgress) => itemProgress.label),
    legend: {
      show: false
    },
    stroke: {
      colors: [theme.palette.background.paper],
      width: 1
    },
    theme: {
      mode: theme.palette.mode
    }
  };

  useEffect(() => {
    const prices = {};
    prices['Médecin'] = formation?.cost_med;
    prices.Infirmier = formation?.cost_idel;
    prices['Masseur-kinésithérapeute'] = formation?.cost_kine;
    prices.Pharmacien = formation?.cost_pha;
    prices['Pédicure-podologue'] = formation?.cost_podo;
    prices['Sage-Femme'] = formation?.cost_sage;

    let totalInscrit = 0;
    let totalEnAttente = 0;
    let totalDésinscrit = 0;
    let tempTotal = 0;

    customers.forEach((customer) => {
      if (customer.status === 'Inscrit') {
        totalInscrit += customer.real_price !== null ? customer.real_price : prices[customer.profession];
        tempTotal += customer.real_price !== null ? customer.real_price : prices[customer.profession];
      } else
      if (customer.status === 'En attente') {
        totalEnAttente += customer.real_price !== null ? customer.real_price : prices[customer.profession];
        tempTotal += customer.real_price !== null ? customer.real_price : prices[customer.profession];
      } else
      if (customer.status === 'Désinscrit') {
        totalDésinscrit += customer.real_price !== null ? customer.real_price : prices[customer.profession];
      }
    });
    let countCustomersFinished = 0;
    let countCustomersBegun = 0;
    let countCustomersNotBegun = 0;
    customersFixed.forEach((customer) => {
      if (customer.progression === 100) {
        countCustomersFinished++;
      } else if (customer.progression < 100 && customer.progression > 0) {
        countCustomersBegun++;
      } else if (customer.progression === 0) {
        countCustomersNotBegun++;
      }
    });
    setTotal(tempTotal);
    setData({ series: [{
      color: '#FFB547',
      data: totalInscrit,
      label: 'Inscrit'
    },
    {
      color: '#ff5f52',
      data: totalEnAttente,
      label: 'En attente'
    },
    {
      color: '#7783DB',
      data: totalDésinscrit,
      label: 'Désinscription'
    }] });
    setDataProgress({ seriesProgress: [{
      color: '#16B84E',
      data: Math.round((countCustomersFinished / customersFixed.length) * 100 * 100) / 100,
      label: 'Terminé',
      count: countCustomersFinished
    },
    {
      color: '#FFCF39',
      data: Math.round((countCustomersBegun / customersFixed.length) * 100 * 100) / 100,
      label: 'En cours',
      count: countCustomersBegun
    },
    {
      color: '#ff5f52',
      data: Math.round((countCustomersNotBegun / customersFixed.length) * 100 * 100) / 100,
      label: 'Pas commencé',
      count: countCustomersNotBegun
    }] });
  }, [customers]);

  const chartSeries = data.series.map((item) => item.data);
  const chartProgressSeries = dataProgress.seriesProgress.map((itemProgress) => itemProgress.data);

  return (
    <Box
      {...other}
      sx={{
        backgroundColor: 'background.default',
        p: 3
      }}
    >
      <Container
        sx="display:flex;"
      >
        <Card sx="width:1050px;">
          <CardHeader
            title="Session"
            subheader={`Total potentiel ${numeral(total.toFixed(2)).format('0,000,000').replaceAll(',', ' ')} €`}
          />
          <CardContent>
            <Chart
              height="260"
              options={chartOptions}
              series={chartSeries}
              type="pie"
            />
            {data.series.map((item) => (
              <Box
                key={item.label}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 1
                }}
              >
                <Box
                  sx={{
                    backgroundColor: item.color,
                    borderRadius: '50%',
                    height: 8,
                    width: 8
                  }}
                />
                <Typography
                  color="textPrimary"
                  sx={{ ml: 2 }}
                  variant="subtitle2"
                >
                  {item.label}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {numeral(item.data).format('0,000,000').replaceAll(',', ' ')}
                  €
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>

        <Card sx="width:1050px; margin-left:20px;">
          <CardHeader
            title="Répartition de l'avancée des apprenants"
          />
          <CardContent>
            <Chart
              height="260"
              options={chartProgressOptions}
              series={chartProgressSeries}
              type="pie"
            />
            {dataProgress.seriesProgress.map((itemProgress) => (
              <Box
                key={itemProgress.label}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 1
                }}
              >
                <Box
                  sx={{
                    backgroundColor: itemProgress.color,
                    borderRadius: '50%',
                    height: 8,
                    width: 8
                  }}
                />
                <Typography
                  color="textPrimary"
                  sx={{ ml: 2 }}
                  variant="subtitle2"
                >
                  {itemProgress.label}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                >
                  {`${numeral(itemProgress.data).format('0,000,000').replaceAll(',', ' ')}% (${itemProgress.count} au total)`}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

SessionStats.propTypes = {
  customers: PropTypes.array.isRequired,
  formation: PropTypes.array.isRequired,
  customersFixed: PropTypes.array.isRequired
};

export default SessionStats;
